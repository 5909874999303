import React, {useMemo} from "react";
import {AsideMenuListD} from "./AsideMenuListD";
import {AsideMenuListS} from "./AsideMenuListS";
import {AsideMenuListDO} from "./AsideMenuListDO";
import {AsideMenuListA} from "./AsideMenuListA";
import {AsideMenuListDefault} from "./AsideMenuListDefault";
import {useHtmlClassService} from "../../../../_core";
import { useSelector } from "react-redux";

export const renderSwitch = (user, layoutProps) => {
  if(user.accounting){
    switch(user.role) {
      case 'Director':
        return <AsideMenuListD layoutProps={layoutProps} />;
      case 'Gerente':
        return <AsideMenuListS layoutProps={layoutProps} />;
      case 'Coordinador':
        return <AsideMenuListDO layoutProps={layoutProps} />;
      case 'Agente':
        return <AsideMenuListA layoutProps={layoutProps} />;
      default:
        return <AsideMenuListDefault layoutProps={layoutProps} />;
    }
  }
};

export function AsideMenu({disableScroll}) {
  const { user } = useSelector(state => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {renderSwitch(user, layoutProps)}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
