import React from "react";
import {useSubheader} from "../../_metronic/_core";
import Lesson from "../modulesSchool/Lesson"
import {useSelector} from "react-redux";

export const LessonPage = (props) => {
    const suhbeader = useSubheader();
    const dataLesson = useSelector(state => state.auth.dataLesson);
    suhbeader.setTitle(`Lección - ${dataLesson.name}`);
    return (<Lesson/>)
}
