import React from "react";
import {useSubheader} from "../../_metronic/_core";
import CountriesTable from "../modulesAdmin/Countries/CountriesTable"

export const CountriesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Countries");

  return (<><CountriesTable/></>);

};