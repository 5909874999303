import React from "react";
import {useSubheader} from "../../_metronic/_core";
import Evaluation from "../modulesWiyu/Evaluation"
import { useIntl } from "react-intl";

export const EvaluationLessonPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(`${int1.formatMessage({id: "LEARNING_ASSESSMENT"})}`);
    return (<Evaluation/>)
}