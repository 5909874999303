import React from "react";
import {useSubheader} from "../../_metronic/_core";

import EditInvoice from "../modulesAccounting/Invoices/EditInvoice"

export const EditInvoicePage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Invoice");

  return (<><EditInvoice/></>);
};