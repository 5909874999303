import React, {useMemo} from "react";
import {AsideMenuListDefault} from "./AsideMenuListDefault";
import {AsideMenuListD} from "./AsideMenuListD";
import {AsideMenuListM} from "./AsideMenuListM";
import {AsideMenuListS} from "./AsideMenuListS";
import {useHtmlClassService} from "../../../../_core";
import { useSelector } from "react-redux";
// import { NavLink } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage } from "react-intl";

import { renderSwitch as renderSwitchSettings } from "../../../../layoutWiyu copy/components/aside/aside-menu/AsideMenu";


export function AsideMenu({disableScroll}) {
  const { user } = useSelector(state => state.auth);
  const uiService = useHtmlClassService();

  let is_active = false;

  if(user.is_active === 1){
    is_active = true;
  }

  const [open, setOpen] = React.useState(!is_active);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  const renderSwitch = (role, owner) => {
    let school_menu = null
    if(owner){
      school_menu = <AsideMenuListD layoutProps={layoutProps} />;
    }else{
      switch(role) {
        case 'Director':
          school_menu = <AsideMenuListD layoutProps={layoutProps} />;
          break;
        case 'Marketeer':
          school_menu = <AsideMenuListM layoutProps={layoutProps} />;
          break;
        case 'Soporte':
          school_menu = <AsideMenuListS layoutProps={layoutProps} />;
          break;
        default:
          school_menu = <AsideMenuListDefault layoutProps={layoutProps} />;
          break;
      }
    }

    return <>
    {school_menu}
    {
      user.owner ?
      renderSwitchSettings(user, layoutProps)
        :
      <></>
    }
    {/* {
      user.billing === 1 && (
        <div className="billing">
          <h4>Plan Inicial</h4>
          <span>Usuarios ilimitados</span>
          <span>0 de 3 cursos creados</span>
          <span>0 de 5 registros de pagos</span>
          <NavLink to="/billing">
            <span className="access-billing">Modificar mi plan</span>
          </NavLink>
        </div>
        // <AsideMenuListMyPlan layoutProps={layoutProps} />
      )
    } */}
    <Snackbar 
      open={open} 
      autoHideDuration={60000} 
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity="info">
        <FormattedMessage id="GENERAL_MESSAGE.ACCOUNT_NOT_CONFIRMED"/>
      </Alert>
    </Snackbar>
    </>;


  };

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {renderSwitch(user.role, user.owner)}
        {/* {
        user.billing === 1 && (
          <div className="billing">
            <h4>Plan Inicial</h4>
            <span>Usuarios ilimitados</span>
            <span>0 de 3 cursos creados</span>
            <NavLink to="/billing">
              <span className="access-billing">Modificar mi plan</span>
            </NavLink>
          </div>
          )
        } */}
        
      </div>
      {/* end::Menu Container */}
      <Snackbar 
        open={open} 
        autoHideDuration={60000} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="info">
          <FormattedMessage id="GENERAL_MESSAGE.ACCOUNT_NOT_CONFIRMED"/>
        </Alert>
      </Snackbar>
    </>
  );
}
