//BasePageWiyu
import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/_core";
import {ContentRoute} from "../_metronic/layoutWiyu";

import {DataUserPage} from "./pages/DataUserPage";
import {TutorialsSectionPage} from "./pages/TutorialsSectionPage";
import { ReportPaymentPage } from "./pages/ReportPaymentPage";

import {BuilderPage} from "./pagesWiyu/BuilderPage";
import {MyPage} from "./pagesWiyu/MyPage";
import {InicioPage} from "./pagesWiyu/InicioPage";
import {DashboardPageWiyu} from "./pagesWiyu/DashboardPage";
import {RankingPage} from "./pagesWiyu/RankingPage";

// import { AgentPage } from "./pagesWiyu/AgentPage";
import { ReportsPage1 } from "./pagesWiyu/ReportsPage";
import { LessonPage } from './pagesWiyu/LessonPage';
import { EvaluationLessonEditPage } from './pagesWiyu/EvaluationLessonEditPage';
import { EvaluationLessonPage } from './pagesWiyu/EvaluationLessonPage';
// import { CourseAssignmentPage } from "./pagesWiyu/CourseAssignmentPage";
import { CoursesPage } from "./pagesWiyu/CoursesPage";
import { DataAgencyPage } from "./pagesWiyu/DataAgencyPage";
import { DataOfficePage } from "./pagesWiyu/DataOfficePage";
import { ScriptEdvisorPage } from './pagesAccounting/ScriptEdvisorPage';
import { InvoicesPage } from './pagesAccounting/InvoicesPage';
import { EditInvoicePage } from './pagesAccounting/EditInvoicePage';
import { ViewOptionsPage } from './pagesAccounting/ViewOptionsPage';
import { ExchangesPage } from './pagesAccounting/ExchangesPage';
import { ExchangesRatesPublicPage } from './pagesAccounting/ExchangesRatesPublicPage';
import { AccountsPage } from './pagesAccounting/AccountsPage';
import { ExchangeOfficesPage } from './pagesAccounting/ExchangeOfficesPage';
import { AgentPaymentsPage } from './pagesAccounting/AgentPaymentsPage';
import { AdminPaymentsPage } from './pagesAccounting/AdminPaymentsPage';
import { TransfersPage } from './pagesAccounting/TransfersPage';
import { PaymentTransfersPage } from './pagesAccounting/PaymentTransfersPage';
import { CommissionsPage } from './pagesAccounting/CommissionsPage';
import { AnalyticsPage } from './pagesAccounting/AnalyticsPage';
import { AgencyCommissionsPage } from './pagesAccounting/AgencyCommissionsPage';
// import { DataAgencyPage } from "./pagesAgency/DataAgencyPage";

// import { PruebaPage } from "./pagesWiyu/PruebaPage";


import { useSelector } from "react-redux";
import { MovementsAndInvoicesBillingPage } from "./pages/MovementsAndInvoicesBillingPage";
import { ToPayBillingPage } from "./pages/ToPayBillingPage";
import { UpgradeBillingPage } from "./pages/UpgradeBillingPage";
import { StudentsPage } from "./pagesWiyu/StudentsPage";
import { EventsPage } from "./pagesWiyu/EventsPage";


const GoogleMaterialPage = lazy(() =>
  import("./modulesAdmin/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modulesAdmin/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modulesAdmin/ECommerce/pages/eCommercePage")

);

export default function BasePageWiyu() {
  const { user } = useSelector(state => state.auth);
  const id_agente = 64;
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const renderSwitchRedirect = (user) => {
    switch(user.role) {
      case 'Director':
        if(user.billing === 2){
          return <Redirect exact from="/" to="/billing"/>;
        }else{
          if(user.tutorials === 0){
            return <Redirect exact from="/" to="/inicio"/>;
          }else{
            return <Redirect exact from="/" to="/tutorial"/>;
          }
        }
      case 'Gerente':
        if(user.billing === 2){
          return <Redirect exact from="/" to="/billing"/>;
        }else{
          if(user.tutorials === 0){
            return <Redirect exact from="/" to="/inicio"/>;
          }else{
            return <Redirect exact from="/" to="/tutorial"/>;
          }
        }
      case 'Coordinador':
        if(user.billing === 2){
          return <Redirect exact from="/" to="/billing"/>;
        }else{
          if(user.tutorials === 0){
            return <Redirect exact from="/" to="/inicio"/>;
          }else{
            return <Redirect exact from="/" to="/tutorial"/>;
          }
        }
      default:
        if(user.billing === 2){
          return <Redirect exact from="/" to="/billing"/>;
        }else{
          if(user.tutorials === 0){
            return <Redirect exact from="/" to="/inicio"/>;
          }else{
            return <Redirect exact from="/" to="/tutorial"/>;
          }
        }
    }
  };
  
  const renderSwitchRoute = (user) => {
      if(user.owner){
        return (
          <Switch>
            {/* <ContentRoute path="/prueba2" component={PruebaPage}/> */}

            {
                /* Redirect from root URL to /dashboard. */
                user.billing === 2 ? <Redirect exact from="/" to="/billing"/> : user.tutorials === 0 ? <Redirect exact from="/" to="/inicio"/> : <Redirect exact from="/" to="/tutorial"/>
            }
            <ContentRoute path="/inicio" component={InicioPage}/>
            <ContentRoute path="/analytics" component={DashboardPageWiyu}/>
            <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage}/>
            <ContentRoute path="/tutorial/course/lesson" component={LessonPage}/>
            <ContentRoute path='/get-agency' component={DataAgencyPage}/>

            {/* <ContentRoute path="/tutorial/course" component={CoursePage}/> */}
            <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>
            {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage}/>}
            {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage}/>}
            {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage}/>}
            {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage}/>}
            {/* {user.tutorials && <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>} */}
            {user.learning && <ContentRoute path="/get-ranking" component={RankingPage}/>}
            {user.learning && <ContentRoute path="/builder" component={BuilderPage}/>}
            {user.learning && <ContentRoute path="/my-page" component={MyPage}/>}
            {user.learning && <ContentRoute path="/get-invoices" component={InvoicesPage}/>}

            {/* {user.learning && <ContentRoute path="/get-agents" component={AgentPage}/>} */}
            {
              (user.fk_id_role !== id_agente && user.learning) && (
                <ContentRoute path="/get-reports" component={ReportsPage1}/>
                )
            }
            {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation-edit" component={EvaluationLessonEditPage}/>}
            {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage}/>}
            {user.learning && <ContentRoute path="/get-courses/course/lesson" component={LessonPage}/>}
            {/* {user.learning && <ContentRoute path="/get-courses/course" component={CoursePage}/>} */}
            {/* {user.learning && <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage}/>} */}
            {user.learning && <ContentRoute path="/get-courses" component={CoursesPage}/>}
            {user.learning && <ContentRoute path="/get-agency" component={DataAgencyPage}/>}
            {user.learning && <ContentRoute path="/get-offices" component={DataOfficePage}/>}
            {user.accounting && <ContentRoute path="/get-script" component={ScriptEdvisorPage}/>}
            {user.accounting && <ContentRoute path="/get-invoices" component={InvoicesPage}/>}
            {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage}/>}
            
            {user.accounting && <ContentRoute path="/get-exchangesPublic" component={ExchangesRatesPublicPage}/>}

            {user.accounting && <ContentRoute path="/get-view-options" component={ViewOptionsPage}/>}
            {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage}/>}
            {user.accounting && <ContentRoute path="/get-accounts" component={AccountsPage}/>}
            {user.accounting && <ContentRoute path="/get-exchangeOffices" component={ExchangeOfficesPage}/>}
            {user.accounting && <ContentRoute path="/get-agent-payments" component={AgentPaymentsPage}/>}
            {user.accounting && <ContentRoute path="/get-admin-payments" component={AdminPaymentsPage}/>}
            {user.accounting && <ContentRoute path="/get-transfers" component={TransfersPage}/>}
            {user.accounting && <ContentRoute path="/get-payment-transfers" component={PaymentTransfersPage}/>}
            {user.accounting && <ContentRoute path="/get-commissions" component={CommissionsPage}/>}
            {user.accounting && <ContentRoute path="/get-analytics" component={AnalyticsPage}/>}
            {user.accounting && <ContentRoute path="/get-exchangeOffices" component={ExchangeOfficesPage}/>}
            {user.accounting && <ContentRoute path="/get-agencyCommissions" component={AgencyCommissionsPage}/>}

            {/* {user.accounting && <ContentRoute path="/prueba-s" component={ PruebaPage } />} */}



  
            <ContentRoute path="/my-profile" component={DataUserPage}/>
  
            <Route path="/google-material" component={GoogleMaterialPage}/>
            <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
            <Route path="/e-commerce" component={ECommercePage}/>
            <Redirect to="error/error-v1"/>
          </Switch>
        );
      }else{
        switch(user.role) {
          case 'Director':
            return (
              <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    renderSwitchRedirect(user)
                }
                <ContentRoute path="/inicio" component={InicioPage}/>
                <ContentRoute path="/analytics" component={DashboardPageWiyu}/>
                <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage}/>
                <ContentRoute path="/tutorial/course/lesson" component={LessonPage}/>
                {/* <ContentRoute path="/tutorial/course" component={CoursePage}/> */}
                <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage}/>}
                {user.tutorials && <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>}
                {user.learning && <ContentRoute path="/get-ranking" component={RankingPage}/>}
                {user.learning && <ContentRoute path="/builder" component={BuilderPage}/>}
                {user.learning && <ContentRoute path="/my-page" component={MyPage}/>}

                {user.wiyu && <ContentRoute path="/wiyu-students" component={StudentsPage} />}
                {user.wiyu && <ContentRoute path="/wiyu-events" component={EventsPage} />}

                {/* {user.learning && <ContentRoute path="/get-agents" component={AgentPage}/>} */}
                {user.learning && <ContentRoute path="/get-reports" component={ReportsPage1}/>}
                {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation-edit" component={EvaluationLessonEditPage}/>}
                {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage}/>}
                {user.learning && <ContentRoute path="/get-courses/course/lesson" component={LessonPage}/>}
                {/* {user.learning && <ContentRoute path="/get-courses/course" component={CoursePage}/>} */}
                {/* {user.learning && <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage}/>} */}
                {user.learning && <ContentRoute path="/get-courses" component={CoursesPage}/>}
                {user.learning && <ContentRoute path="/get-agency" component={DataAgencyPage}/>}
                {user.learning && <ContentRoute path="/get-offices" component={DataOfficePage}/>}
                {user.accounting && <ContentRoute path="/get-script" component={ScriptEdvisorPage}/>}
                {user.accounting && <ContentRoute path="/get-invoices" component={InvoicesPage}/>}
                {user.accounting && <ContentRoute path="/get-view-options" component={ViewOptionsPage}/>}
                {user.accounting && <ContentRoute path="/get-edit-invoice" component={EditInvoicePage}/>}
                {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage}/>}

                {/* Wiyu */}
                {user.accounting && <ContentRoute path="/get-exchangesPublic" component={ExchangesRatesPublicPage}/>}\
                {user.accounting && <ContentRoute path="/get-accounts" component={AccountsPage}/>}
                {user.accounting && <ContentRoute path="/get-exchangeOffices" component={ExchangeOfficesPage}/>}
                {user.accounting && <ContentRoute path="/get-agencyCommissions" component={AgencyCommissionsPage}/>}
                {user.accounting && <ContentRoute path="/get-agent-payments" component={AgentPaymentsPage}/>}
                {user.accounting && <ContentRoute path="/get-admin-payments" component={AdminPaymentsPage}/>}
                {user.accounting && <ContentRoute path="/get-transfers" component={TransfersPage}/>}
                {user.accounting && <ContentRoute path="/get-payment-transfers" component={PaymentTransfersPage}/>}
                {user.accounting && <ContentRoute path="/get-commissions" component={CommissionsPage}/>}
                {user.accounting && <ContentRoute path="/get-analytics" component={AnalyticsPage}/>}

                <ContentRoute path="/my-profile" component={DataUserPage}/>

                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
              </Switch>
            );
          default:
            return (
              <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    renderSwitchRedirect(user)
                }
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                
    
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
              </Switch>
            );
          }
        }
  };

  return (
    <Suspense fallback={<LayoutSplashScreen/>}>
      {renderSwitchRoute(user)}
    </Suspense>
  );
}
