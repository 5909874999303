export default function ServerURL(){

let ServerURL = process.env.REACT_APP_SERVER_URL;

/* if (process.env.NODE_ENV === 'development'){
    ServerURL = 'http://localhost:3333'
} else {
    //ServerURL = 'https://api.wissify.com'
    ServerURL = 'https://apitest.wissify.com'
} */    

return ServerURL;

}

