//* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
//import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css'

export function AsideMenuListD({ layoutProps }) {
  //const int1 = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text text-uppercase"><FormattedMessage id="ACCOUNTING_GENEREAL.ACCOUNTING" /></span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base"> ANALYTICS </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              <li className="menu-section">
                <li
                  className={`menu-item ${getMenuItemActive("/get-analytics", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-analytics">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
                    </span>
                    <span className="menu-text"> Dashboard </span>
                  </NavLink>
                </li>

              </li>

              {/* AGENCY PROFIT */}
              <li
                className={`menu-item ${getMenuItemActive("/get-agency-profit", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-agency-profit">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
                  </span>
                  <span className="menu-text">Profit</span>
                </NavLink>
              </li>

              {/* Usuarios */}
              {/* begin::section */}
              <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base"> Administration </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}


              <li
                className={`menu-item ${getMenuItemActive("/get-invoices", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-invoices">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.INVOICES" /></span>
                </NavLink>
              </li>



              <li
                className={`menu-item ${getMenuItemActive("/get-admin-payments", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-admin-payments">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.PAYMENTS" /></span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/get-transfers", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-transfers">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.TRANSFERS" /></span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/get-commissions", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-commissions">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale1.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.COMMISSIONS" /></span>
                </NavLink>
              </li>

              {/*end::1 Level*/}

              {/* TOOLS */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base"> TOOLS</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              <li
                className={`menu-item ${getMenuItemActive("/get-exchanges", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-exchanges">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Exchange.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.EXCHANGE_RATES" /></span>
                </NavLink>
              </li>



              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/get-invoices-management", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-invoices-management">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                  </span>
                  <span className="menu-text">Delete Invoices</span>
                </NavLink>
              </li>

              {/* <li
                      className={`menu-item ${getMenuItemActive("/my-page", false)}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/my-page">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}/>
                    </span>
                      <span className="menu-text">Agency incentives</span>
                    </NavLink>
                </li>*/}

              <li
                className={`menu-item ${getMenuItemActive("/get-script", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-script">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.SCRIPT_EDVISOR" /></span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/get-invoices-legacy", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-invoices-legacy">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.SCRIPT_EDVISOR" /> Legacy</span>
                </NavLink>
              </li>

              {/* Catalogs */}
              <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base"> CATALOGS </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/get-agencyCommissions", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-agencyCommissions">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale1.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.AGENCY_COMMISSION" /></span>
                </NavLink>
              </li>

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/get-accounts", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-accounts">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")} />
                  </span>
                  <span className="menu-text">Bank Accounts</span>
                </NavLink>
              </li>


              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/get-exchangeOffices", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-exchangeOffices">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.EXCHANGE_OFFICES" /></span>
                </NavLink>
              </li>


              {/*end::1 Level*/}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
