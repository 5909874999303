import React from "react";
import {useSubheader} from "../../_metronic/_core";
import AgreementsTable from "../modulesAdmin/Agreements/AgreementsTable"

export const AgreementsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Agreements");

  return (<><AgreementsTable/></>);

};