// AsideMenuListDG - Agency
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
//import { useIntl } from "react-intl";

export function AsideMenuListDG({ layoutProps }) {
  //const int1 = useIntl();
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  const id_agente = 64;
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text text-uppercase"><FormattedMessage id="LEARNING_GENERAL.LEARNING" /></span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/*begin::1 Level*/}
              {
                user.tutorials === 0 ?
                  <li
                    className={`menu-item ${getMenuItemActive("/inicio", false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/inicio">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                      </span>
                      <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.HOME" /></span>
                    </NavLink>
                  </li>
                  :
                  <>
                    <li className="menu-section">
                      <h4 className="menu-text text-white-alpha-90 text-base">START</h4>
                      <i className="menu-icon flaticon-more-v2"></i>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive("/tutorial", false)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/tutorial">
                        <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                        </span>
                        <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.TUTORIALS" /></span>
                      </NavLink>
                    </li>
                  </>

              }

              <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base">ANALYTICS</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/analytics", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/analytics">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
                  </span>
                  <span className="menu-text">Dashboard</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/get-ranking", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-ranking">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.RANKING" /></span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              {/* Usuarios */}
              {/* begin::section */}
              <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base">TEAM</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              {/*begin::1 Level*/}
              {/* <li
                    className={`menu-item ${getMenuItemActive("/get-agents", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-agents">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                  </span>
                    <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.AGENT_LIST"/></span>
                  </NavLink>
                </li> */}
              <li
                className={`menu-item ${getMenuItemActive("/get-team", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-team">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.MY_TEAM" /></span>
                </NavLink>
              </li>
              {
                user.fk_id_role !== id_agente && (
                  <li
                    className={`menu-item ${getMenuItemActive("/get-reports", false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/get-reports">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg")} />
                      </span>
                      <span className="menu-text">Performance</span>
                    </NavLink>
                  </li>
                )
              }
              {/*end::1 Level*/}

              {/* Cursos */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base"><FormattedMessage id="LEARNING_GENERAL.COURSES" /></h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/get-courses", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to="/get-courses">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.COURSE_LIST" /></span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              {/* Configuración */}
              {/* begin::section */}
              {/* <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base"><FormattedMessage id="LEARNING_GENERAL.SETTING" /></h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li> */}
              {/* end:: section */}

              {/*begin::1 Level*/}
              {/* <li
                    className={`menu-item ${getMenuItemActive("/get-agency", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-agency">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
                  </span>
                    <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.AGENCY_DATA"/></span>
                  </NavLink>
                </li> */}
              {/*end::1 Level*/}

            </ul>
          </div>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
