// AnalyticsRates
import React, { Component } from 'react';
import axios from 'axios'
import ServerURL from '../../../ServerURL';
import { Redirect } from 'react-router-dom';

import { FormattedMessage, injectIntl } from 'react-intl';
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate';

// import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import moment from 'moment';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'; 

import * as auth from '../../modulesAdmin/Auth/_redux/authRedux'
import { connect } from 'react-redux';
import { head } from 'lodash';

const url = ServerURL()

class AnalyticsRates extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rates: null,
            layout: 'list',

            sortKey: {rates: null},
            sortOrder: null,
            sortField: null,
            
            customerText: getTextCampusTranslate(),
            paginatorText: getTextPaginatorTranslate(),
            textTranslate: getGeneralTextTranslate()
        }

        this.optionsTypes = [
            {label: 'School', value: 'school'},
            {label: 'Agency', value: 'agency'},      
            {label: 'All', value: 'all'},      

        ]

        this.itemTemplate = this.itemTemplate.bind(this)
        this.ratingBodyTemplate = this.ratingBodyTemplate.bind(this)
    }

    componentDidMount() {
        this.getRates()
    }

    renderListCustomer(data) {
        return (
            <div className='p-col-9 '>
                <div className='course-list-item'>
                    <div className='course-list-detail'>
                        <div className='course-name'>{data.question}</div>
                        <div>Enterprise: {data.enterprise_name}</div>
                        {/* {this.ratingBodyTemplate(data)} */}

                        {/* <h6>Rates</h6> */}
                    </div>


                    <div className='course-list-action'>
                        <Rating value={data.Promedio} readonly cancel={false} />
                        {this.ratingBodyTemplate(data)}
                    </div>

                    {/* <div className='course-grid-item-top'>

                    </div>
                    <div className='course-grid-item-content'>
                        {this.ratingBodyTemplate(data)}

                        <div> Rated at: {moment.utc(data.rated_at).format('DD/MM/YYYY')} </div>
                    </div>
                    <div className='course-grid-item-bottom'>
                        {this.ratingBodyTemplate(data)}
                    </div> */}
                </div>
            </div>
        )
    }

    ratingBodyTemplate(rowData) {
        // let round = Math.round(rowData.Promedio)
        let rateShow =  []

        if(rowData.Promedio === 5) {
            rateShow.push(
                <>
                    {/* <i className='pi pi-users'/> */}
                    <div>
                        {/* <div style={{ alignSelf: 'flex-end'}}>
                            <Rating value={round} readonly cancel={false} />
                        </div> */}
                        <div className='course-grid-item-bottom'>
                            <span>Rating: Excellent</span>
                        </div>
                    </div>
                </>            
            )
        } else if (rowData.Promedio === 4 ) {
            rateShow.push(
                <>
                    <div>
                        {/* <div style={{ alignSelf: 'flex-end'}}>
                            <Rating value={round} readonly cancel={false} />
                        </div> */}
                        <div className='course-grid-item-bottom'>
                            <span>Rating: Good</span>
                        </div>
                    </div>
                </>            
            )
        } else if (rowData.Promedio === 3 ) {
            rateShow.push(
                <>
                        {/* <div style={{ alignSelf: 'flex-end'}}>
                            <Rating value={round} readonly cancel={false} />
                        </div> */}
                        <div className='course-grid-item-bottom'>
                            <span>Rating: Okay</span>
                        </div>
                </>            
            )
        } else if (rowData.Promedio === 2 ) {
            rateShow.push(
                <>
                    <div>
                        {/* <div className='course-grid-item-bottom'>
                            <Rating value={round} readonly cancel={false} />
                        </div> */}
                        <div className='course-grid-item-bottom'>
                            <span>Rating: Bad</span>
                        </div>
                    </div>
                </>            
            )
        } else if (rowData.Promedio === 1 ) {
            rateShow.push(
                <>
                    <div>
                        {/* <div className='course-grid-item-bottom'>
                            <Rating value={round} readonly cancel={false} />
                        </div> */}
                        <div className='course-grid-item-bottom'>
                            <span>Rating: Terrible</span>
                        </div>
                    </div>
                </>            
            )
        }

        return rateShow
    }

    getRates = async () => {
        try {
            await axios.get(`${url}/api/public/wiyu-rates`)
            .then(res => {
                const rates = res.data

                console.log(rates);

                // let sumAverageRates = 0
                // rates.forEach(rate => {
                //     sumAverageRates += rate.averageRates
                // });

                // const rateAverage = sumAverageRates/rates.length

                // this.setState({ rates, rateAverage})
                this.setState({rates})
            })
        } catch (error) {
            console.log(error);
        }
    }

    itemTemplate(customer, layout) {
        if(!this.getRates) {
            return;
        }

        if( layout === 'list') {
            return this.renderListCustomer(customer)
        }
    }
    

    renderHeaderRates() {
        return (
            <div className="p-grid p-nogutter">
                <div className="p-col-12" style={{display:"flex", justifyContent:"space-between"}}>
                    <div  style={{marginTop:"auto", marginBottom:"auto"}}>
                    {/* <FormattedMessage id="GENERAL_MESSAGE.SELECT_TYPE_ENTERPRISE">
                    {(message) => <Dropdown style={{minWidth:"min-content"}} className="mr-2" options={this.optionsTypes} value={this.state.sortKey["rates"]} placeholder={message} onChange={e => this.changeType(e, "rates", "enterprise")}/>}
                    </FormattedMessage> */}
                    {/* <Dropdown style={{minWidth:"min-content"}} className="mr-2" options={this.optionsType} value={this.state.sortKey["courses_show"]} placeholder="Seleccionar tipo de empresa" onChange={e => this.changeType(e, "courses", "enterprise")}/> */}
                    {/* <Dropdown style={{minWidth:"min-content"}} options={this.state.optionsProgramType} value={this.state.programTypes["courses_show"]} placeholder="Seleccionar tipo de programa" onChange={e => this.changeType(e, "courses")}/> */}
                    </div>
                    {/* <div style={{display:"grid"}}>
                    <span style={{color:"darkgray"}}><FormattedMessage id="GENERAL_MESSAGE.SEARCH_BY_NAME_CREATOR_COMPANY"/></span>
                    <span style={{justifySelf:"center"}} className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <FormattedMessage id="GENERAL.SEARCH">
                            {
                                message => <InputText onChange={e => this.onChangeSearch(e.target.value, "rates")} placeholder={message} />
                            }
                            </FormattedMessage>
                        </span>
                    </div> */}
                </div>
            </div>
        )
    }

    render(data) {

        const headerRates = this.renderHeaderRates()

        // let footer = null
        // if(this.state.rateAverage === 0 || this.state.rateAverage) {
        //     footer = 'Calificacion promedio: ' + this.state.rateAverage.toFixed(2)
        // }

        return (

            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className='dataview mb-5'>
                    <div className='card'>
                        <DataView value={this.state.rates} header={headerRates} layout={this.state.layout} itemTemplate={this.itemTemplate} />
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(AnalyticsRates)

// export default AnalyticsRates