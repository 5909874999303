import React, {Component} from 'react'
import axios from 'axios'
import ServerURL from '../../../ServerURL'

 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { InputNumber } from 'primereact/inputnumber';

import 'primeflex/primeflex.css';


import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";

const url = ServerURL();


class ExchangesPulic extends Component {

    emptyExchange = {
    fk_id_currency: null,
    rate:null,
    }

    emptyCurrency= {
        code: null,
        symbol:null,
        }

    emptyEnterprise = {
        fk_id_currencies: '',
        additionalPercentage:'',
        }

    constructor(props) {
        super(props)
      
      
        this.state = { 
                          
            originalFk_id_baseCurrency: this.props.user.fk_id_baseCurrency,
            originalCodeCurrency: this.props.user.codeCurrency,
            originalFK_id_currencies: this.props.user.fk_id_currencies,

    
            exchange: this.emptyExchange,
            currency: this.emptyCurrency,
            enterprise: this.emptyEnterprise,
    
            exchanges:[],
            currencies:[],
            currenciesShow:[],
            enterprises:[],

            submitted: false,
            currencyDialog: false, 
            resetDateExchange:null,
            
            selectedBaseCurrency: null,
            globalFilter: null,
            selectedUsers: null,
            saveDialog: false,   
            
            disabledConfig: true, 
            user: { id: this.props.user.id,
                    fk_id_baseCurrency: this.props.user.fk_id_baseCurrency,
                    codeCurrency:   this.props.user.codeCurrency,
                    fk_id_currencies:   this.props.user.fk_id_currencies},
            
        
            campusText: getTextCampusTranslate(),
            paginatorText: getTextPaginatorTranslate(),
            textTraslate: getGeneralTextTranslate()

            
        }
        this.onCurrencyChange = this.onCurrencyChange.bind(this);
        this.hideBaseCurrencyDialog = this.hideBaseCurrencyDialog.bind(this); 
        this.onInputChange = this.onInputChange.bind(this);
        


        
    }   

            componentDidMount() {
              this.getExchangeRates()
            }  


            getCode = async (e) =>{ 
                let code
              try {
                await axios.get(`${url}/api/auth/currency/${e.value}`)
                .then(res => {
                  code = res.data.code;
                  });                     
                }catch(error){
            }
            return code               
            }


                                  
                getExchangeRates = async () =>{                 
                  try {
                      let currenciesShow=[];
                      let aux=0;
                      let res
                    

                    
                        res = axios.get(`${url}/api/public/getExchangeRatesPublic/${95}`)
                      
                      
                      res.then(res => {
                        const exchangeRates = res.data;   
                        this.codeSymbolExchange(exchangeRates) 

                        if( typeof this.props.user.fk_id_currencies==='string'){
                        this.props.user.fk_id_currencies =   this.props.user.fk_id_currencies.split(',')    
                        this.props.user.fk_id_currencies =   this.props.user.fk_id_currencies.map(Number);  
                        }

                        for(let i=0; i<Object.keys(exchangeRates).length; i++){ 
                            for(let j=1; j<Object.keys(this.props.user.fk_id_currencies).length; j++){
                              if(exchangeRates[i].fk_id_currency===this.props.user.fk_id_currencies[j]){
                                currenciesShow[aux]=exchangeRates[i]  
                                aux++ 
                              }
                            }
                        }
                        this.setState({ exchangeRates,currenciesShow })
                      });                     
                        console.log(res.data)
                      }catch(error){
                  }
                }

                codeSymbolExchange(e){            
                  e.map((exchangeRates) => {
                    exchangeRates.codeSymbol=exchangeRates.code+' '+exchangeRates.symbol;
                   return exchangeRates
          
                 });
                }
         

            Date(exchange){
             
               let ts = exchange.timestamp;

               // convert unix timestamp to milliseconds
               let ts_ms = ts * 1000;
               
               // initialize new Date object
               let date_ob = new Date(ts_ms);
               
               // year as 4 digits (YYYY)
               let year = date_ob.getFullYear();
               
               // month as 2 digits (MM)
               let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
               
               // date as 2 digits (DD)
               var date = ("0" + date_ob.getDate()).slice(-2);

               let new_date = date +'/' + month + '/'+year

               exchange.date= new_date 

 
               return exchange
            
            }

            Today(){
              let date = new Date()
              let y = date.getFullYear();
              let m = date.getMonth()+1;
              let d = date.getDate();
              if (d < 10) {
                d = '0' + d;
              }
              if (m < 10) {
                m = '0' + m;
              }
              let today = d +'/' + m + '/'+ y

              return today
            }

            DateToOE(e){
        
              let year = e.getFullYear();
              let month = e.getMonth()+1;
              let day = e.getDate();
   
              if (day < 10) {
                day = '0' + day;
              }
              if (month < 10) {
                month = '0' + month;
              }
           
              let new_date= day +'/' + month + '/'+ year
   
              return new_date
   
         }
  

            
            onCurrencyChange(e,) {
              let enterprise = {...this.state.enterprise};   
      
      
               if( typeof enterprise.fk_id_currencies ==='string'){
      
                enterprise.fk_id_currencies = enterprise.fk_id_currencies.split(',')    
                enterprise.fk_id_currencies = enterprise.fk_id_currencies.map(Number);                     
                        
                        if (e.checked) {       
                                      
                        enterprise['fk_id_currencies'].push(e.value);
                        console.log("HOLA",enterprise.fk_id_currencies)
                        console.log("FINAL",enterprise)
      
                        }else {
                          for (let i = 0; i < enterprise.fk_id_currencies.length+1; i++) {
                              if (enterprise.fk_id_currencies[i] === e.value) {
                                  console.log(enterprise.fk_id_currencies[i], e.value)
                                  enterprise.fk_id_currencies.splice(i, 1);
                                  break;
                              }
                          }  
                        } 
                        this.setState({enterprise});
                        console.log("ENTERPRISE:",enterprise)
      
      
              }else{
                      if (e.checked) {       
                                      
                        enterprise['fk_id_currencies'].push(e.value);
                        console.log("HOLA",enterprise.fk_id_currencies)
                        console.log("FINAL",enterprise)
                        
                      }else {
                          for (let i = 0; i < enterprise.fk_id_currencies.length+1; i++) {
      
              
                              if (enterprise.fk_id_currencies[i] === e.value) {
                                  console.log(enterprise.fk_id_currencies[i], e.value)
      
                                  enterprise.fk_id_currencies.splice(i, 1);
                                  break;
                              }
                          }  
                        } 
                        this.setState({enterprise});
                        console.log("ENTERPRISE:",enterprise)
                }        
          }


            Check(val) {
              let a= {...this.state.enterprise};  
              console.log("A:",a) 
      
              if( typeof a.fk_id_currencies ==='string'){
                    a.fk_id_currencies = a.fk_id_currencies.split(',')
                    a.fk_id_currencies = a.fk_id_currencies.map(Number); 
                 
                return a.fk_id_currencies.some(arrVal => val === arrVal); 
                         
              }else{
      
                return a.fk_id_currencies.some(arrVal => val === arrVal);
      
              }
            }
              
              onInputChange(e) {
                const val = (e.target && e.target.value) || '';
                let enterprise = {...this.state.enterprise}
                enterprise['additionalPercentage'] = val;
                this.setState({ enterprise });
              }
             

        
              codeSymbol(e){            
                e.map((currency) => {
                //console.log("E:",agreement.updated_at)
                currency.codeSymbol=currency.code+' '+currency.symbol;
                 return currency
        
               });
              }
        

              hideBaseCurrencyDialog() {
                this.setState({
                    submitted: false,
                    currencyDialog: false,
              })  
            } 

          
            actionBodyTemplate(rowData) {
                return (
                    <>
                        <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editAgreement(rowData)} />
                        <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteAgreement(rowData)} />
                    </>
                )
              }   
              
              
            additionalPercentage=()=>{
            let enterprise = {...this.state.enterprises}
            let show = []

              if(this.props.user.fk_id_role===58){

                show.push(
                  <>
                  <div className="form-group row">
                  <div className="col-lg-6">
                  <span className="p-float-label">   
                  <InputNumber
                  locale="en-US" 
                  minFractionDigits={2}   
                  min="0"
                  id="additionalPercentage"
                  variant="outlined"
                  margin="dense"
                  value={enterprise.additionalPercentage}             
                  onValueChange={(e) => this.onInputChange(e)} 
                  />
                  <label htmlFor="additionalPercentaje">{this.state.campusText.AdditionalPercentage}</label>
                  </span>
              </div>
              </div>
                  </>
                )
              }
              return show;
            } 

    render(){
            return(  
           <div>       
            <div>
            {/*SHOW EXCHANGES*/}
            <div className= "card-header flex-wrap border-0 pt-6 pb-0">
                         <DataTable 
                            ef={(el) => this.dt = el} value={this.state.currenciesShow} rows={10}ref={(el) => { this.dt = el; }}
                            loading={this.state.loading}
                            dataKey="id">
                              <Column field="codeSymbol" style={{width:10}}></Column>
                              <Column field="rate" style={{width:10}}></Column>
                        </DataTable>
                    </div>
            </div>     
        </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
  });
  
export default connect(mapStateToProps, auth.actions)(ExchangesPulic);


