import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const url = ServerURL();



class RolesTable extends Component {
            

  
  emptyRole = {
    fk_id_role_type: '',
    role: '',
    description: '',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    fk_id_role_type: '',
    name: '',
    description: '',

    /**
       * Aux messages
       */
      fk_id_role_typeMessage: '',
      roleMessage: '', 
      descriptionMessage: '', 

      
    role: this.emptyRole,
    roles:null,
    ids:null,
    globalFilter: null,
    selectedRoles: null,
    roleDialog: false,
    selectedRoleType: null,
    resetRoleType:null,
    deleteRoleDialog: false,
    deleteRolesDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveRole = this.saveRole.bind(this);
  this.deleteRole = this.deleteRole.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editRole = this.editRole.bind(this);
  this.confirmDeleteRole= this.confirmDeleteRole.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedRoles = this.deleteSelectedRoles.bind(this);
  this.hideDeleteRoleDialog = this.hideDeleteRoleDialog.bind(this);
  this.hideDeleteRolesDialog = this.hideDeleteRolesDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
//  this.onRoleChange = this.onRoleChange.bind(this);
  this.onRoleTypeChange = this.onRoleTypeChange.bind(this);
  }


    
      componentDidMount() {
        this.getRoles()

      }

      isValid(){
        const { fk_id_role_type,role, description } = this.state.role
              const fk_id_role_typeMessage = this.validateFK_Id_Role_Type()
              const roleMessage = this.validateRole()
              const descriptionMessage = this.validateDescription()

        
              if(fk_id_role_type && role && description ){
                if(!fk_id_role_typeMessage && !roleMessage && !descriptionMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
      validateFK_Id_Role_Type() {
              const role = this.state.role
              
              let fk_id_role_typeMessage;
              if (!role.fk_id_role_type) {
                fk_id_role_typeMessage = 'Requerido';
              } else {
                fk_id_role_typeMessage = ''
              }
              this.setState({ fk_id_role_typeMessage })
              return fk_id_role_typeMessage
            }

            validateRole() {
              const role= this.state.role
              
              let roleMessage;
              if (!role.role) {
                roleMessage = 'Requerido';
              } else {
                roleMessage = ''
              }
              this.setState({ roleMessage })
              return roleMessage
            }



       validateDescription() {
              const role = this.state.role
              
              let descriptionMessage;
              if (!role.description) {
                descriptionMessage = 'Requerido';
              } else {
                descriptionMessage = ''
              }
              this.setState({ descriptionMessage })
              return descriptionMessage
            }



        getTextAuxFK_Id_Role_Type(){
              const message = this.state.fk_id_role_typeMessage
              return message
            }
        
            getTextAuxRole(){
              const message = this.state.roleMessage
               return message
             }
      
        getTextAuxDescription(){
             const message = this.state.descriptionMessage
              return message
            }


      getRoleTypes = async () =>{
         
        try {
           
            let res = axios.get(`${url}/api/auth/role_types`)
            .then(res => {
              const role_types= res.data;
              this.setState({ role_types })
            });                     
              console.log(res.data)
            }catch(error){
        }
      }

      getRoles = async () =>{
         
          try {
             
              let res = axios.get(`${url}/api/auth/roles`)
              .then(res => {
                const roles = res.data;            
                this.setState({ roles })
              });                     
                console.log(res.data)
              }catch(error){

         }
      }

      deleteRole(){
        let data = {...this.state.role};

        try {
        let res = axios.delete(`${url}/api/auth/role/${data.id}`)
          .then(res => {
          this.getRoles()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteRoleDialog: false,
            role: this.emptyRole
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveRole() {
        let state = { submitted: true };

        const decision = this.isValid();

        if (decision) {


       // if (this.state.role.role.trim()) {       
          let data = {...this.state.role};


        if (this.state.role.id) {

            try {
              let res = axios.put(`${url}/api/auth/role/${data.id}`,data)
              .then(res =>{
              this.getRoles()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/role`,data)
            
             .then(res => {
                 this.getRoles()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          roleDialog: false,
          role: this.emptyRole,
          selectedRoleType: null,
          resetRoleType:null,
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.getRoleTypes()
        this.setState({
            role: this.emptyRole,
            submitted: false,
            roleDialog: true,
            selectedRoleType: null,
            resetRoleType:"Role Type",
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let role = {...this.state.role};
        role[`${name}`] = val;

        this.setState({ role });
      }

      /*onRoleChange(e) { 
        let role = {...this.state.role};
        role['role'] = e.value;
        this.setState({ role });
      }*/

      onRoleTypeChange(e) {
        let role = {...this.state.role};
        role['fk_id_role_type'] = e.value;
        this.setState({ selectedRoleType: e.value, role});      
      }   


      hideDialog() {
          this.setState({
              submitted: false,
              roleDialog: false,
              role_typeMessage: '',
              roleMessage:'',
              descriptionMessage: '',
        })  
      } 


      editRole(role) {
          this.getRoleTypes()
          this.setState({
              role: { ...role },
              selectedRoleType: null,
              resetRoleType:null,  
              roleDialog: true
          })

      }

      confirmDeleteRole(role) {
        this.setState({
            role,
            deleteRoleDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteRolesDialog: true })
      }

      deleteSelectedRoles() {
        let roles = this.state.roles.filter(val => !this.state.selectedRoles.includes(val));
        let data = this.state.roles.filter(val => this.state.selectedRoles.includes(val));

        try {
        let res = axios.delete(`${url}/api/auth/roles`,{data})
          .then(res => {
          this.getRoles()
        })
        if (!res.error) {
          this.setState({
            roles,
            deleteRolesDialog: false,
            selectedRoles: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Roles Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteRoleDialog() {
        this.setState({ deleteRoleDialog: false })
      }
        
      hideDeleteRolesDialog() {
        this.setState({ deleteRolesDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
      

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editRole(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteRole(rowData)} />
            </>
        )
      }
      
      




      render(){
       const deleteRoleDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteRoleDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteRole} />
        </>
       );

       const roleDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveRole} />}
            </FormattedMessage>
        </>
       );

       const deleteRolesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteRolesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedRoles} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		      	<FormattedMessage id="ADMIN_ROLE.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_ROLE.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedRoles || !this.state.selectedRoles.length} />}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_ROLE">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div>  

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>
          
          <div>
           {/*SHOW ROLE */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.roles} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Role found." loading={this.state.loading}
                        selection={this.state.selectedRoles} onSelectionChange={e => this.setState({ selectedRoles: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="role_type" header={this.state.campusText.RoleType}  filter filterPlaceholder={this.state.campusText.SearchByRoleType} sortable></Column>
                        <Column field="role" header={this.state.campusText.Role}  filter filterPlaceholder={this.state.campusText.SearchByRole} sortable></Column>
                        <Column field="description" header={this.state.campusText.Description}  filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE ROLE */}
          <Dialog visible={this.state.deleteRoleDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteRoleDialogFooter} onHide={this.hideDeleteRoleDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.role && <span> Are you sure you want to delete <b>{this.state.role.name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteRolesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteRolesDialogFooter} onHide={this.hideDeleteRolesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.role && <span>Are you sure you want to delete the selected roles?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE ROLE & CREATE ROLE*/}
          <Dialog visible={this.state.roleDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"270px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_ROLE"/>} modal footer={roleDialogFooter} onHide={this.hideDialog}>
              <br></br>
              <div className="form-group row">
               {/* Role Type */}
              <div className="col-lg-6">      
              <span className="p-float-label">          
              <Dropdown
                  style={{ width: 250, height:33 }}
                  id="role_type"
                  variant="outlined"
                  placeholder= { this.state.resetRoleType || this.state.role.role_type}
                  margin="dense"
                  value={this.state.selectedRoleType || "role_type"}   
                  options ={this.state.role_types} 
                  onChange={this.onRoleTypeChange} 
                  optionLabel="role_type" 
                  optionValue="id"
                  />
                 <label htmlFor="role_type">Role Type</label>
              </span>    
             <small id="role_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFK_Id_Role_Type()}</small>
              </div>
               {/* Role */}
               <div className="col-lg-6">
              <span className="p-float-label">
                  <InputText
                  id="role"
                  variant="outlined"
                  type="role"
                  margin="dense"
                  value={this.state.role.role}             
                  onChange={(e) => this.onInputChange(e, 'role')}
                  keyfilter="alpha" 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.role.role  })}
                  />
                  <label htmlFor="role">Role</label>
              </span>
              <small id="frist_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxRole(this.state)}</small>    
              </div>
              </div>
              <br></br>
              <div className="form-group row">
              {/* Description */}
              <div className="col-lg-6">
              <span className="p-float-label">   
                <InputText
                  id="description"
                  variant="outlined"
                  margin="dense"
                  value={this.state.role.description}             
                  onChange={(e) => this.onInputChange(e, 'description')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.description  })}
                  />
                  <label htmlFor="description">Description</label>
                  </span>
                  <small id="frist_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription(this.state)}</small>
                  </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(RolesTable);