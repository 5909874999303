import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";

//Traduccion
import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

//Componentes
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';

//Conexion con API
import axios from 'axios'
import ServerURL from '../../../ServerURL'

// Redux
import * as auth from '../../modulesAdmin/Auth/_redux/authRedux'

//Funciones extras
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Moment from 'moment'

//PrimeReact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const url = ServerURL();

class InsuranceInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            insurance: this.props.dataStudent.insurance,
            insurance_provider: this.props.dataStudent.insurance_provider,
            insurance_type: this.props.dataStudent.insurance_type,
            insurance_start_date: this.props.dataStudent.insurance_start_date,
            insurance_end_date: this.props.dataStudent.insurance_end_date,
            emergency_number_student: this.props.dataStudent.emergency_number_student,

            redirect: false,
            submitted: false,
        }

        this.updateStudent = this.updateStudent.bind(this)
        this.changePage = this.changePage.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
    }

    changePage(){
        this.setState({redirect: true})
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let student = {...this.state.student}
        student[`${name}`] = val
        this.setState({ student })
    }

    async updateStudent() {
        let state ={ submitted: true }
        let data = {
            name: this.state.insurance,
            insurance_provider: this.state.insurance_provider,
            insurance_type: this.state.insurance_type,
            insurance_start_date: Moment.utc(this.state.insurance_start_date).format('YYYY-MM-DD'),
            insurance_end_date: Moment.utc(this.state.insurance_end_date).format('YYYY-MM-DD'),
            emergency_number_student: this.state.emergency_number_student,

        }
        // console.log("Data :", data);

        try{
            await axios.put(`${url}/api/public/students-insurance/${this.props.dataStudent.id}`, data)
            .then(res => {
                const dataStudent = {
                    ...this.props.dataStudent,
                    insurance: data.name,
                    insurance_provider: data.insurance_provider,
                    insurance_type: data.insurance_type,
                    insurance_start_date: data.insurance_start_date,
                    insurance_end_date: data.insurance_end_date,
                    emergency_number_student: data.emergency_number_student,
                };
                console.log(dataStudent);
                this.props.updatedStudent(dataStudent)
                console.log(res);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Student updated', life: 3000})
            })
        } catch (error){
            console.log(error);
        }
        console.log("Data :", data);
        console.log("State: ", state);
        this.setState({...state, data})

    }

    render(){

        if(this.state.redirect){
            return <Redirect to={{
                pathname: '/wiyu-students'
            }} />
        }

        return(
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className='d-flex justify-content-between mb-2'>
                    <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" icon="pi pi-arrow-left" onClick={this.changePage} variant='contained'/>
                </div>

                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <h5 className='card-label'> Insurance info
                        <span className="d-block text-muted pt-2 font-size-sm" >Update insurance information</span>
                    </h5>
                </div>

                <div>
                    <div className='card'>
                        <br/>
                        <br/>

                    <div className='form-group row' style={{ marginLeft: 3, marginRight: 5}}>
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-heart'></i>
                                </span>
                                <span className='p-float-label'>
                                    <InputText
                                    placeholder={this.props.dataStudent.insurance_provider}
                                    value={this.state.insurance_provider}
                                    id='insurance_provider'
                                    variant='outlined'
                                    margin='dense'
                                    onChange={(e) => this.setState({ insurance_provider: e.target.value})}
                                    />
                                <label htmlFor='insurance_provider'>Insurance provider</label>
                            </span>
                            </div>
                            <small className='p-d-block' id='insurance_provider-help'>This is the insurance provider</small>
                        </div>
                    </div>

                    <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-heart'></i>
                            </span>
                            {/* Insurance */}
                            <span className='p-float-label'>
                                <InputText 
                                // placeholder={this.props.dataStudent.insurance || 'Insurance'}
                                value={this.state.insurance}                            
                                id="insurance"
                                variant="outlined"
                                margin="dense"
                                onChange={(e) => this.setState({insurance: e.target.value})}
                                />
                                <label htmlFor='insurance'>Insurance name</label>
                            </span>                        
                        </div>
                        <small className='p-d-block' id='insurance-help'>Enter the insurance name</small>
                    </div>

                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-heart'></i>
                            </span>
                            {/* Insurance type */}
                            <span className='p-float-label'>
                                <InputText
                                // placeholder={this.props.dataStudent.type_insurance || 'Insurance type'}
                                value={this.state.insurance_type}                            
                                id="insurance_type"
                                variant="outlined"
                                margin="dense"
                                onChange={(e) => this.setState({insurance_type: e.target.value})}
                                />
                                <label htmlFor='insurance_type'>Insurance type</label>    
                            </span>
                        </div>
                        <small className='p-d-block' id='insurance_type-help'>Enter the insurance type.</small>
                    </div>
                </div>

                <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-calendar-plus'></i>
                            </span>
                            {/* Insurance start date */}
                            {
                                this.props.dataStudent.insurance_start_date === null ?
                                <span className='p-float-label'>
                                    <Calendar 
                                        id='insurance_start_date'
                                        value={this.state.insurance_start_date}
                                        // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                        onChange={(e) => this.setState({ insurance_start_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // readOnlyInput
                                        placeholder= {this.props.dataStudent.insurance_start_date}

                                    />
                                    <label htmlFor='insurance_start_date'>Insurance start date</label>
                                </span>
                            :
                            this.props.dataStudent.insurance_start_date === 'Invalid date' ?
                                <span className='p-float-label'>
                                    <Calendar 
                                        id='insurance_start_date'
                                        value={this.state.insurance_start_date}
                                        // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                        onChange={(e) => this.setState({ insurance_start_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // readOnlyInput
                                        // placeholder= {this.props.dataStudent.insurance_start_date}
                                    />
                                    <label htmlFor='insurance_start_date'>Insurance start date</label>
                                </span>
                            :
                                <span className='p-float-label'>
                                    <Calendar 
                                        id='insurance_start_date'
                                        value={this.state.insurance_start_date}
                                        // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                        onChange={(e) => this.setState({ insurance_start_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // readOnlyInput
                                        placeholder= {Moment.utc(this.props.dataStudent.insurance_start_date).format("YYYY-MM-DD")}

                                    />
                                    <label htmlFor='insurance_start_date'>Insurance start date</label>
                                </span>
                            }
                        </div>
                        <small className='p-d-block' id='start_date_insurance-help'>Enter the start date of the insurance. Format YYYY-MM-DD</small>
                    </div>

                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-calendar-times'></i>
                            </span>
                            {/* Insurance end date */}
                            {
                                this.props.dataStudent.insurance_end_date === null ?
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='insurance_end_date'
                                            value={this.state.insurance_end_date}
                                            onChange={(e) => this.setState({ insurance_end_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // placeholder= {Moment.utc(this.props.dataStudent.insurance_end_date).format("YYYY-MM-DD")}

                                        />
                                        <label htmlFor='insurance_end_date'>Insurance start date</label>
                                    </span>
                                :
                                this.props.dataStudent.insurance_end_date === 'Invalid date' ?
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='insurance_end_date'
                                            value={this.state.insurance_end_date}
                                            onChange={(e) => this.setState({ insurance_end_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // placeholder= {Moment.utc(this.props.dataStudent.insurance_end_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='insurance_end_date'>Insurance start date</label>
                                    </span>
                                :
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='insurance_end_date'
                                            value={this.state.insurance_end_date}
                                            onChange={(e) => this.setState({ insurance_end_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            placeholder= {Moment.utc(this.props.dataStudent.insurance_end_date).format("YYYY-MM-DD")}

                                        />
                                        <label htmlFor='insurance_end_date'>Insurance start date</label>
                                    </span>
                            }
                        </div>
                        <small className='p-d-block' id='end_date_insurance-help'>Enter the end date of the insurance. Format YYYY-MM-DD</small>

                    </div>
                </div>
                <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                    <div className='col-lg-6'>
                    <div className='p-inputgroup'>
                        <span className='p-inputgroup-addon'>
                            <i className='pi pi-phone'></i>
                        </span>
                        {/* Insurance end date */}
                        <span className='p-float-label'>
                            <InputText
                            // placeholder={this.props.dataStudent.emergency_number_student || 'Emergency number'}
                            value={this.state.emergency_number_student}                               
                            id="emergency_number_student"
                            variant="outlined"
                            margin="dense"
                            onChange={(e) => this.setState({emergency_number_student: e.target.value})}
                            /> 
                        <label htmlFor='emergency_number_student'>Emergency number</label>
                        </span>  
                    </div>
                    <small className='p-d-block' id='emergency_number_student-help'>Enter any emergency number. Ex. (000) 000-0000</small>
                    </div>

                </div>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end',
                    paddingBottom: 10,
                    marginRight: 5
                }}>
                    {/* Boton cancelar */}
                    <div style={{marginLeft: 10}}>
                        <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" label='Cancelar' icon="pi pi-times" onClick={this.changePage} variant='contained'/>
                    </div>

                    {/* Boton guardar */}
                    <div style={{marginLeft: 10}}>
                        <Button className='p-button-rounded p-button-help p-button-outlined' type="button" label='Guardar' icon="pi pi-check" onClick={this.updateStudent} variant='contained'/>
                    </div>
                </div>
                    </div>
                </div>
                

            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataStudent: state.auth.dataStudent
})

export default injectIntl(
    connect(mapStateToProps, auth.actions)(InsuranceInfo)
)