import React from "react";
import {useSubheader} from "../../_metronic/_core";

import AddTask from "../../app/modulesAdmin/Tasks/AddTask"

export const AddTaskPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Lista de tareas");

  return (<><AddTask /></>);
};