import React from 'react';
import { useSubheader } from "../../_metronic/_core";
import ListAssignmentPrograms from '../modulesWiyu/ListAssignmentPrograms';
import { useIntl } from 'react-intl';

export const ProgramsAssignmentPage = () => {
    const subheader = useSubheader();
    const int1 = useIntl();

    subheader.setTitle("Asignación de programas")
    return(<ListAssignmentPrograms />)
    
}