import React from "react";
import {useSubheader} from "../../_metronic/_core";

export const DataOfficePage = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Datos de las oficinas");
    return (
        <div>
            <h1>Pagina para los datos de las oficinas</h1>
        </div>
    )
}