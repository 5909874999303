import React from "react";
import {useSubheader} from "../../_metronic/_core";

import ExchangesTable from "../modulesAccounting/ExchangeRate/ExchangeTable"

export const ExchangesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Exchanges");

  return (<><ExchangesTable /></>);
};