import React, { Component, Fragment } from 'react';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link }  from "react-router-dom";
import { Redirect }  from "react-router-dom";
import "primeflex/primeflex.css";

import axios from 'axios'
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL'
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

const url = ServerURL();

class ListEvaluation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listEvaluations: null,
      name: "",
      displayConfirmation: false,
      idLessonSelect: null
    };

    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);


  }

  componentDidMount() {
    this.getListEvaluations()
  }

  onHide(name){
    this.setState({
      [`${name}`]: false
    })
  }

  onOpen(){
    this.setState({
      dialog: true
    })
  }

  onClick(name) {
    let state = {
      [`${name}`]: true
    };

    this.setState(state);
  }

  changePage(dataEvaluation) {
    this.props.updatedEvaluation(dataEvaluation)
    this.setState({redirect: true})
  }

  getListEvaluations = async () =>{
    try {
      await axios.get(`${url}/api/auth/course_list_evaluations/${this.props.dataEvaluation.id}`)
      .then(res => {
        const newArrayEvaluations = []

        let index = 0
        res.data.forEach(evaluation => {
          evaluation = { ...evaluation, name: `${this.props.intl.formatMessage({id: 'LEARNING_COURSE_LESSON.ASSESSMENT'})} ${index+1} - ${evaluation.name}` }
          index++;
          newArrayEvaluations.push(evaluation)
        })

        this.setState({ listEvaluations: newArrayEvaluations })
      });
      }catch(error){
        console.log(error)
    }
  }

  actionBodyTemplate(rowData) {
    let template
    if(this.props.dataEvaluation.isAssigned){
      template = (
        <Fragment>
          <Button icon="pi pi-chart-bar" className="p-button-warning" style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px",}} />
          <Button icon="pi pi-pencil" color='primary' style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px",}} />
        </Fragment>
      )
    }else{
      template = (
        <Fragment>
          <Button icon="pi pi-pencil" color='primary' style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px",}} />
        </Fragment>
      )
    }
  return (template);
}

  render() {
    const data = {...this.props.dataEvaluation, listEvaluations: this.state.listEvaluations}

    if(this.state.redirect) {
      return <Redirect to={{
        pathname: "/get-evaluations/evaluations/results",
      }}/>;
    }

    return (
      <div>
        <Link className="mb-5" to={{
            pathname:"/get-evaluations"
        }}>
        <button className="btn"><KeyboardBackspaceSharpIcon/></button>
        </Link>
        <div className="card">
        <FormattedMessage id="LEARNING_GENERAL.COURSE_ASSESSMENT_LIST">
          {message => (
            <DataTable className="p-datatable-lg" value={this.state.listEvaluations} header={message}>
              <Column field="name" header={this.props.intl.formatMessage({id: 'GENERAL.NAME'})}></Column>
              {/* <Column className="d-flex justify-content-center" body={this.actionBodyTemplate} header="Opciones"></Column> */}
            </DataTable>
          )}
        </FormattedMessage>
        {
          this.state.listEvaluations ?
          <></>
          :
          <div className="card">
            <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
          </div>
        }
        </div>
        {
          this.state.listEvaluations && this.props.dataEvaluation.isAssigned ?
          <div className="d-flex justify-content-center pt-8">
              <button className="btn btn-lg btn-warning" onClick={() => this.changePage(data)}><FormattedMessage id="LEARNING_GENERAL.VIEW_COURSE_STATISTICS"/></button>
          </div>
          :
          <></>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  dataEvaluation: state.auth.dataEvaluation
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(ListEvaluation)
)