import React, {Component,/*useCallback*/} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';



import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'



const url = ServerURL();


class EnterprisesTable extends Component {
            

  
  emptyEnterprise = {
    fk_id_role_type: '',
    fk_id_country:'',
    enterprise_name:'',
    logo: '',
    contact: '',
    email: '',
    phone:'',
    website: '',
    fllogo:'',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    fk_id_role_type:'',
    fk_id_country:'',
    name: '',
    logo: '',
    contact: '',
    email: '',
    phone:'',
    website: '',
    fllogo:'',
    file:null,

    /**
       * Aux messages
       */
      fk_id_role_typeMessage: '',
      fk_id_countryMessage: '',
      enterprise_nameMessage: '',
      contactMessage: '',
      emailMessage: '',
      phoneMessage: '',
      websiteMessage: '',

    enterprise: this.emptyEnterprise,
    enterprises:null,
    ids:null,
    globalFilter: null,
    selectedEnterprises: null,
    selectedRoleType:null,
    selectedCountry:null,
    resetRoleType:null,
    resetCountry:null,
    enterpriseDialog: false,
    deleteEnterpriseDialog: false,
    deleteEnterprisesDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveEnterprise = this.saveEnterprise.bind(this);
  this.deleteEnterprise = this.deleteEnterprise.bind(this);
  this.openNew = this.openNew.bind(this);
  this.enterpriseBodyTemplate = this.enterpriseBodyTemplate.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editEnterprise = this.editEnterprise.bind(this);
  this.confirmDeleteEnterprise= this.confirmDeleteEnterprise.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedEnterprises = this.deleteSelectedEnterprises.bind(this);
  this.hideDeleteEnterpriseDialog = this.hideDeleteEnterpriseDialog.bind(this);
  this.hideDeleteEnterprisesDialog = this.hideDeleteEnterprisesDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onRoleTypeChange = this.onRoleTypeChange.bind(this);
  this.onCountryChange = this.onCountryChange.bind(this);
  this.handleChange = this.handleChange.bind(this)


  }



      componentDidMount() {
        this.getEnterprises()

      }


      isValid(){
        const { fk_id_role_type,fk_id_country,enterprise_name,contact, email, phone, website } = this.state.enterprise
              const fk_id_role_typeMessage = this.validateRoleType()
              const fk_id_countryMessage = this.validateCountry()
              const enterprise_nameMessage = this.validateEnterpriseName()
              const contactMessage = this.validateContact()
              const emailMessage = this.validateEmail()
              const phoneMessage = this.validatePhone()
              const websiteMessage = this.validateWebsite()


        
              if(fk_id_role_type && fk_id_country && enterprise_name && contact && email && phone && website ){
                if(!fk_id_role_typeMessage && !fk_id_countryMessage && !enterprise_nameMessage && !contactMessage && !emailMessage && !phoneMessage && !websiteMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
            validateRoleType() {
              const enterprise = this.state.enterprise
              
              let fk_id_role_typeMessage;
              if (!enterprise.fk_id_role_type) {
                fk_id_role_typeMessage = 'Requerid';
              } else {
                fk_id_role_typeMessage = ''
              }
              this.setState({ fk_id_role_typeMessage })
              return fk_id_role_typeMessage
            }      

            validateCountry() {
              const enterprise = this.state.enterprise
              
              let fk_id_countryMessage;
              if (!enterprise.fk_id_country) {
                fk_id_countryMessage = 'Requerid';
              } else {
                fk_id_countryMessage = ''
              }
              this.setState({ fk_id_countryMessage })
              return fk_id_countryMessage
            }      
      
            validateEnterpriseName() {
              const enterprise = this.state.enterprise
              
              let enterprise_nameMessage;
              if (!enterprise.enterprise_name) {
                enterprise_nameMessage = 'Requerid';
              } else {
                enterprise_nameMessage = ''
              }
              this.setState({ enterprise_nameMessage })
              return enterprise_nameMessage
            }

            validateContact() {
              const enterprise= this.state.enterprise
              
              let contactMessage;
              if (!enterprise.contact) {
                contactMessage = 'Requerido';
              } else {
                contactMessage = ''
              }
              this.setState({ contactMessage })
              return contactMessage
            }


            

            validateEmail() {
                    const enterprise = this.state.enterprise
                    
                    let emailMessage;
                    if (!enterprise.email) {
                      emailMessage = 'Requerido';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(enterprise.email)) {
                      emailMessage = 'Correo electronico invalido';
                    } else {
                      emailMessage = ''
                    }
                    this.setState({ emailMessage })
                    return emailMessage
                  }

            validatePhone() {
              const enterprise = this.state.enterprise
              
              let phoneMessage;
              if (!enterprise.phone) {
                phoneMessage = 'Requerido';
              } else if (!/^[A-Z0-9._%+-]{6,12}$/i.test(enterprise.phone)) {
                phoneMessage = 'Telefono invalido';
              } else {
                phoneMessage = ''
              }
              this.setState({ phoneMessage })
              return phoneMessage
            }

            validateWebsite() {
              const enterprise = this.state.enterprise
              
              let websiteMessage;
              if (!enterprise.website) {
                websiteMessage = 'Requerido';
              } else if (!/^[A-Z0-9._%+-]+\.[A-Z]{2,4}$/i.test(enterprise.website)) {
                websiteMessage = 'Website invalido';
              } else {
                websiteMessage = ''
              }
              this.setState({ websiteMessage })
              return websiteMessage
            }


            getTextAuxRoleType(){
              const message = this.state.fk_id_role_typeMessage
              return message
            }

            getTextAuxCountry(){
              const message = this.state.fk_id_countryMessage
              return message
            }

            getTextAuxEnterpriseName(){
              const message = this.state.enterprise_nameMessage
              return message
            }
        
            getTextAuxContact(){
              const message = this.state.contactMessage
               return message
             }
      
            getTextAuxEmail(){
             const message = this.state.emailMessage
              return message
            }

            getTextAuxPhone(){
              const message = this.state.phoneMessage
               return message
             }

             getTextAuxWebsite(){
              const message = this.state.websiteMessage
               return message
             }

             handleChange(event) {
              this.setState({
                file: URL.createObjectURL(event.target.files[0])
              })
            }

      getEnterprises = async () =>{
         
          try {
              let res = axios.get(`${url}/api/auth/enterprises/`)
              .then(res => {     
                const enterprises = res.data;            
                this.setState({ enterprises })
              });                     
                console.log(res.data)
              }catch(error){
         }
      }

      getRoleTypes = async () =>{
         
        try {
           
            let res = axios.get(`${url}/api/auth/role_types`)
            .then(res => {
              const role_types = res.data;
              this.setState({ role_types })
            });                     
              console.log(res.data)
            }catch(error){

        }
      }

      getCountries = async () =>{
         
        try {
           
            let res = axios.get(`${url}/api/auth/countries`)
            .then(res => {
              const countries = res.data;
              this.setState({ countries })
            });                     
              console.log(res.data)
            }catch(error){

        }
      }

      deleteEnterprise(){
        let data = {...this.state.enterprise};

        try {
        let res = axios.delete(`${url}/api/auth/enterprise/${data.id}`)
          .then(res => {
          this.getEnterprises()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteEnterpriseDialog: false,
            user: this.emptyEnterprise
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Enterprise Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveEnterprise() {
        let state = { submitted: true };

        //if (this.state.enterprise.enterprise_name.trim()) {   
          const decision = this.isValid();
          if (decision) {    
          let data = {...this.state.enterprise};

          const formData = new FormData();
          formData.append('fllogo',this.uploadInput.files[0]);
          formData.append('fk_id_role_type',data.fk_id_role_type);
          formData.append('fk_id_country',data.fk_id_country);
          formData.append('enterprise_name',data.enterprise_name);
          formData.append('contact',data.contact);
          formData.append('email',data.email);
          formData.append('phone',data.phone);
          formData.append('website',data.website);


          const config = {
            headers: {
         
           'Content-Type':'multipart/form-data'}
           //,body:JSON.stringify(data)
          }
          
          console.log(formData,config)


        if (this.state.enterprise.id) {

            try {
              let res = axios.put(`${url}/api/auth/enterprise/${data.id}`,formData,config)
              .then(res =>{
              this.getEnterprises()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Enterprise Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/enterprise`,formData, config)
            
             .then(res => {
                 this.getEnterprises()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Enterprise Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          enterpriseDialog: false,
          enterprise: this.emptyEnterprise,
          selectedRoleType:null,
          resetRoleType:null,
          selectedCountry:null,
          resetCountry:null,
          file:null,
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.getRoleTypes()
        this.getCountries()
        this.setState({
            enterprise: this.emptyEnterprise,
            selectedRoleType:null,
            resetRoleType:"Role Type",
            selectedCountry:null,
            resetCountry:"Country",
            submitted: false,
            enterpriseDialog: true,
            file:null,
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let enterprise = {...this.state.enterprise};
        enterprise[`${name}`] = val;

        this.setState({ enterprise});
      }



      onRoleTypeChange(e) {
        let enterprise = {...this.state.enterprise};
        enterprise['fk_id_role_type'] = e.value;
        this.setState({ selectedRoleType: e.value, enterprise});      
      }   

      onCountryChange(e) {
        let enterprise = {...this.state.enterprise};
        enterprise['fk_id_country'] = e.value;
        this.setState({ selectedCountry: e.value, enterprise});      
      }   


      hideDialog() {
          this.setState({
              submitted: false,
              enterpriseDialog: false,
              fk_id_role_typeMessage: '',
              fk_id_countryMessage: '',
              enterprise_nameMessage: '',
              contactMessage: '',
              emailMessage: '',
              phoneMessage: '',
              websiteMessage: ''
        })  
      } 


      editEnterprise(enterprise) {
          this.getRoleTypes()
          this.getCountries()
          this.setState({
              selectedRoleType:null,
              selectedCountry:null,
              file:null,
              resetRoleType:null,
              resetCountry:null,
              enterprise: { ...enterprise },
              enterpriseDialog: true
          })

      }

      confirmDeleteEnterprise(enterprise) {
        this.setState({
            enterprise,
            deleteEnterpriseDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteEnterprisesDialog: true })
      }

      deleteSelectedEnterprises() {
        let enterprises = this.state.enterprises.filter(val => !this.state.selectedEnterprises.includes(val));
        let data = this.state.enterprises.filter(val => this.state.selectedEnterprises.includes(val));
        
        console.log({data})

        try {
        let res = axios.delete(`${url}/api/auth/enterprises`,{data})
          .then(res => {
          this.getEnterprises()
        })
        if (!res.error) {
          this.setState({
            enterprises,
            deleteEnterprisesDialog: false,
            selectedEnterprises: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Enterprises Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteEnterpriseDialog() {
        this.setState({ deleteEnterpriseDialog: false })
      }
        
      hideDeleteEnterprisesDialog() {
        this.setState({ deleteEnterprisesDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
      enterpriseBodyTemplate(rowData) {
          return (
              <>
                  <Avatar alt={rowData.name} src={`${rowData.logo}`} width={32} style={{verticalAlign: 'middle',   margin: "10px", width: "50px", height: "50px",}} />
              </>
          )
      }

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editEnterprise(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteEnterprise(rowData)} />
            </>
        )
      }
      
  
      render(){
       const deleteEnterpriseDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEnterpriseDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteEnterprise} />
        </>
       );

       const EnterpriseDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveEnterprise} />
        </>
       );

       const deleteEnterprisesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEnterprisesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedEnterprises} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		        <FormattedMessage id="ADMIN_ENTERPRISE.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_ENTERPRISE.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedEnterprises || !this.state.selectedEnterprises.length} />}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_ENTERPRISE">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div>

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
            </div>
          </div>

          
          <div>
           {/*SHOW ENTERPRISE */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.enterprises} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Enterprise found." loading={this.state.loading}
                        selection={this.state.selectedEnterprises} onSelectionChange={e => this.setState({ selectedEnterprises: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="logo" body={this.enterpriseBodyTemplate}></Column>
                        <Column field="role_type" header={this.state.campusText.RoleType} sortable></Column>
                        <Column field="enterprise_name" header={this.state.campusText.Enterprise} sortable></Column>
                        <Column field="country_name" header={this.state.campusText.Country} sortable></Column>
                        <Column field="contact" header={this.state.campusText.Contact} sortable></Column>
                        <Column field="email" header={this.state.campusText.Email} sortable></Column>
                        <Column field="phone" header={this.state.campusText.Phone} sortable></Column>
                        <Column field="website" header={this.state.campusText.Website} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE ENTERPRISE */}
          <Dialog visible={this.state.deleteEnterpriseDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEnterpriseDialogFooter} onHide={this.hideDeleteEnterpriseDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.enterprise && <span> Are you sure you want to delete <b>{this.state.enterprise.enterprise_name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteEnterprisesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEnterprisesDialogFooter} onHide={this.hideDeleteEnterprisesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.enterprise && <span>Are you sure you want to delete the selected enterprises?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE ENTERPRISE & CREATE ENTERPRISE*/}
          <Dialog visible={this.state.enterpriseDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"570px", overflow:"auto"}} header="Enterprise Profile" modal footer={EnterpriseDialogFooter} onHide={this.hideDialog}>
          <br></br>
          <div className="form-group row">
          <div className="col-lg-6">  
          
          <Avatar  
              style={{width:"130px", height:"130px"}}
              variant="circle"
              src={this.state.file || this.state.enterprise.logo}
              /><br></br>
              <input 
              type="file" 
              name="avatar"
              ref={(ref) => { this.uploadInput = ref; }}
              multiple= {false} 
              onChange={this.handleChange}
              />
          </div>
          </div>
          <br></br>
          <div className="form-group row">
            {/* RoleType*/}
            <div className="col-lg-6">
            <span className="p-float-label">   
              <Dropdown
                style={{ width: 190, height:33 }}
                id="role_type"
                variant="outlined"
                placeholder= { this.state.resetRoleType || this.state.enterprise.role_type}
                margin="dense"
                value={this.state.selectedRoleType || "role_type"}   
                options ={this.state.role_types} 
                onChange={this.onRoleTypeChange} 
                optionLabel="role_type" 
                optionValue="id"

                />
                <label htmlFor="role_type">Role Type</label>
            </span>
            <small id="role_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxRoleType(this.state)}</small>
            </div>
             {/* Country*/}
             <div className="col-lg-6">
            <span className="p-float-label">   
              <Dropdown
                style={{ width: 190, height:33 }}
                id="country"
                variant="outlined"
                placeholder= { this.state.resetCountry || this.state.enterprise.country_name}
                margin="dense"
                value={this.state.selectedCountry || "country_name"}   
                options ={this.state.countries} 
                onChange={this.onCountryChange} 
                optionLabel="country_name" 
                optionValue="id"
              />
                <label htmlFor="country">Country</label>
            </span>
            <small id="country-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxCountry(this.state)}</small>
            </div>
          </div>
          <br></br>        
          <div className="form-group row">
              {/* Enterprise*/}
              <div className="col-lg-6">  
              <span className="p-float-label">           
                <InputText
                  style={{ width: 250, height:33 }}
                  id="enterprise"
                  variant="outlined"
                  margin="dense"
                  label="Enterprise Name"
                  value={this.state.enterprise.enterprise_name}             
                  onChange={(e) => this.onInputChange(e, 'enterprise_name')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.enterprise.enterprise_name  })}
                  />
              <label htmlFor="enterprise">Enterprise</label>    
              </span>    
              <small id="enterprise-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEnterpriseName(this.state)}</small>
              </div>
              {/* Contact */}
              <div className="col-lg-6">
              <span className="p-float-label">           
                  <InputText
                   style={{ width: 250, height:33 }}
                  id="contact"
                  label="Contact"
                  variant="outlined"
                  margin="dense"
                  value={this.state.enterprise.contact}             
                  onChange={(e) => this.onInputChange(e, 'contact')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.enterprise.contact  })}
                  />
              <label htmlFor="contact">Contact</label>        
              </span>    
              <small id="contact-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxContact(this.state)}</small>
              </div>
              </div>
                  {/* Email*/}
                  <br></br>
                  <div className="form-group row">
                  <div className="col-lg-6">
                  <span className="p-float-label">            
                  <InputText
                   style={{ width: 250, height:33 }}
                  id="email"
                  type="email"
                  variant="outlined"
                  margin="dense"
                  value={this.state.enterprise.email}             
                  onChange={(e) => this.onInputChange(e, 'email')} 
                  keyfilter="email"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.enterprise.email  })}
                  />
              <label htmlFor="email">Email</label>        
              </span>     
              <small id="email-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEmail(this.state)}</small>
              </div>
              <div className="col-lg-6">
              <span className="p-float-label">            
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="phone"
                  type="phone"
                  variant="outlined"
                  margin="dense"
                  value={this.state.enterprise.phone}             
                  onChange={(e) => this.onInputChange(e, 'phone')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.enterprise.phone  })}
                  />
              <label htmlFor="phone">Phone</label>          
              </span>  
              <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPhone(this.state)}</small>
              </div>
              </div>
              <br></br>
              <div className="form-group row">
              <div className="col-lg-6">
              <span className="p-float-label">            
                  <InputText
                   style={{ width: 250, height:33 }}
                  id="website"
                  type="website"
                  variant="outlined"
                  margin="dense"
                  value={this.state.enterprise.website}             
                  onChange={(e) => this.onInputChange(e, 'website')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.enterprise.website  })}
                  />
              <label htmlFor="website">Website</label>          
              </span>    
              <small id="website-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxWebsite(this.state)}</small>
              </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(EnterprisesTable);