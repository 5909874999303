import React from "react";
import {useSubheader} from "../../_metronic/_core";
import UsersTable from "../../app/modulesAdmin/UsersAndRoles/UsersTable"

export const UsersPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Users");

  return (<><UsersTable/></>);

};