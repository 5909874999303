/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import { useIntl } from "react-intl";

export function AsideMenuListA({ layoutProps }) {
  const int1 = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/analytics", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/analytics">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Analytics</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}


          {/* Enterprises */}
          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_ENTERPRISES"})}</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_ENTERPRISES"})}</span>
                </span>
                </li>
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-enterprises", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-enterprises">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ENTERPRISES"})}</span>
                  </NavLink>
                </li>

                {/*end::2 Level*/}
                <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material/inputs", true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Admin Agencies</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                      {/* Grupo de agencias */}
                      {/*begin::2 Level*/}
                      <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                              "/get-agencyGroups", true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                      >
                        <NavLink className="menu-link menu-toggle" to="/get-agencyGroups">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.AGENCYGROUP"})}</span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}

                      {/* Agencias */}
                      {/* begin::2 Level */}
                      <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                              "/get-agencies", true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                      >
                        <NavLink className="menu-link menu-toggle" to="/get-agencies">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.AGENCIES"})}</span>
                        </NavLink>
                      </li>
                      {/* end::2 Level */}
                      {/* Oficinas */}
                      {/* begin::2 Level */}
                      <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                              "/get-offices", true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                      >
                        <NavLink className="menu-link menu-toggle" to="/get-offices">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.OFFICES"})}</span>
                        </NavLink>
                      </li>
                      {/* end::2 Level           */}
                    </ul>
                  </div>
                </li>
                {/* begin::2 Level */}

                {/* end::2 Level */}

                {/* Sedes */}
                {/*begin::2 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-campuses", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="get-campuses">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.CAMPUS"})}</span>
                  </NavLink>
                </li>
                end::2 Level*/}

                {/* Programas */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS"})}</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                {/* Programas */}
                {/*begin::3 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-programs", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-programs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS.PROGRAMS"})}</span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}
                {/* Cursos */}
                {/*begin::3 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-courses", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-courses">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS.COURSES"})}</span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}                      
                    </ul>
                  </div>
                </li>
                {/*end::2 Level*/}
                
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}



          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/get-agreements", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-agreements">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_AGREEMENTS"})}</span>
            </NavLink>
          </li>      
          {/*end::1 Level*/}


          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ROLES_USERS"})}</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ROLES_USERS"})}</span>
                </span>
                </li>
           {/* ROLEs */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-roles", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-roles">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ROLES"})}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/* USUARIOS */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-users", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.USERS"})}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                </ul>
            </div>
          </li>
          
          {/*end::1 Level*/}
           {/*begin::1 Level*/}
         <li
              className={`menu-item ${getMenuItemActive("/get-countries", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-countries">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_COUNTRIES"})}</span>
            </NavLink>
          </li>     

           {/*end::1 Level*/}
           {/*begin::1 Level*/}
         <li
              className={`menu-item ${getMenuItemActive("/get-currencies", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-currencies">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.CURRENCIES"})}</span>
            </NavLink>
          </li>     
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
         <li
              className={`menu-item ${getMenuItemActive("/get-tutorials", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-tutorials">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.TUTORIALS"})}</span>
            </NavLink>
          </li>     
            {/*end::1 Level*/}

               {/*begin::1 Level*/}
         <li
              className={`menu-item ${getMenuItemActive("/get-utilities", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-utilities">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.UTILITIES"})}</span>
            </NavLink>
          </li>     
            {/*end::1 Level*/}  

          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">Scripts Edvisor</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Edvisor Menu</span>
                </span>
                </li>
           {/* Agencias */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-students", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-students">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Load students</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/* Oficinas */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-users", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Load parents</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                </ul>
            </div>
          </li>     
            {/*end::1 Level*/}

                      {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">Tools</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Tools menu</span>
                </span>
                </li>
           {/* INVOICES HEALTH */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-students", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/invoice-health">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Invoices health</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/* Oficinas */}
                {/*begin::2 Level*/}

                {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-users", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Load parents</span>
                  </NavLink>
                </li> */}

                {/*end::2 Level*/}
                </ul>
            </div>
          </li>     
            {/*end::1 Level*/}


        </ul>
        
        {/* end::Menu Nav */}
      </>
  );
}
