import React from "react";
import {useSubheader} from "../../_metronic/_core";

import CommissionsTable from "../modulesAccounting/Commissions/CommissionsTable"

export const CommissionsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Commissions");

  return (<><CommissionsTable /></>);
};