import Axios from "axios";
import ServerURL from "../../../ServerURL";
import React, { Component } from "react";

// Componentes
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const url = ServerURL()


export default class CreateNotification extends Component {

    constructor(props){
        super(props)

        this.state = {
            notifications: null,
            confirmDialog: false,
            submitted: false
        }

        this.createNotification = this.createNotification.bind(this)
        this.confirmDialog = this.confirmDialog.bind(this)
        this.hideDialog = this.hideDialog.bind(this)
    }

    // getNotification = async () => {

    // }

    async createNotification() {
        let state = { submitted: true }

        try {
            await Axios.post(`${url}/api/public/create_notification`)
            .then( res => {
                
            })
        } catch (error) {
            console.log(error);
        }

        state = {
            ...state,
            confirmDialog: false,
        }

        this.setState(state)

    }

    confirmDialog(){
        this.setState({ submitted: false, confirmDialog: true})
    }

    hideDialog(){
        this.setState({ confirmDialog: false })
    }

    render(){

        const confirmDialogFooter = (
            <>
                <Button label="Yes" icon="pi pi-check" onClick={this.createNotification}/>
                <Button label="No" icon="pi pi-times" className="p-button-danger" onClick={this.hideDialog}/>
            </>
        )

        return (
            <div>
                <Dialog visible={this.state.confirmDialog} header="Confirm create notifications" footer={confirmDialogFooter} onHide={this.hideDialog} >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2em'}} />
                        <span>Are you sure you want to create a new set of notifications?</span>
                    </div>
                </Dialog>
                <div className="card-header flex-wrap border-0 pt-9 pb-9">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <div style={{ marginLeft: 10}}>
                            <Button label='Create notifications' icon='pi pi-plus' onClick={this.confirmDialog}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
