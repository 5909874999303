// import RoleTypes from './RoleTypes';
// import Roles from './Roles';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Checkbox } from '@material-ui/core';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from "react-inlinesvg";

const useStyles = makeStyles((theme) => ({
  description: {
    display: "block",
    fontSize: "14px",
    marginBottom: "12px",
    paddingLeft: "12.5px",
    paddingRight: "12.5px",
  },
  box: {
    background: "#DCDCDC",
    border: "1px solid black",
    borderRadius: "7px",
    marginBottom: "20px"
  },
  headBox: {
    fontSize:"16px",
    background: "dimgrey",
    color:"white",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px",
    textTransform: "uppercase"
  },
  contentBox: {
    fontSize: "14px",
    padding:"8px 20px 8px 20px"
  },
  downBox: {
    position: "absolute",
    right: "24px",
    bottom: "8px",
    fontSize: "16px",
    textAlign: "right"
  },
  optionBox: {
    border: "1px solid black",
    margin: "15px",
    background: "white"
  },
  nameOptionBox: {
    margin: 0,
    fontSize: "12px",
    fontWeight: 500
  },
  descriptionOption: {
    textAlign: "center"
  },
  descriptionPlan:{
    display: "block",
    fontSize: "20px"
  },
  pricePlan: {
    fontSize: "18px"
  },
  svg: {
    marginTop: "-20px",
    marginBottom: "-20px",
    width: 60,
    height: "auto",
  },
  buttonPayMode:{
    padding: "10px",
    background: "#e9e9e9",
    borderRadius: "7px",
    marginRight: "10px"
  },
}));


export default function UpgradeBilling() {
  const classes = useStyles();
  // const { user } = useSelector(state => state.auth);

  return (
    <div>
      <div style={{paddingLeft:"13em", paddingRight:"13em"}}>
        <div className="row">
          <NavLink to="/billing">
            <button className="btn"><KeyboardBackspaceSharpIcon/></button>
          </NavLink>
        </div>
        <section className={classes.box}>
          <div className={classes.headBox}>
          Escoge tu plan
          </div>
          <div>
            <div className={classes.optionBox}>
              <div className="container">
                <div className="row">
                  <div className="col-2 pl-0 pr-0 pt-2 pb-2 text-center">
                    <img alt="Wissify plan Learning" style={{maxHeight:40}} src="https://wissify.nyc3.digitaloceanspaces.com/aux_images/WissifyLogoBook.png"/>
                    <p className={classes.nameOptionBox}>Learning Sin Limites</p>
                  </div>
                  <div className="col-8" style={{alignSelf:"center"}}>
                    <div className={classes.descriptionOption}>
                      <span className={classes.descriptionPlan}>
                      ¡Podras crear cursos ilimitados!
                      </span>
                      <span className={classes.pricePlan}>
                      $49.99/mes
                      </span>
                    </div>
                  </div>
                  <div className="col-2" style={{alignSelf: "center", textAlign: "center"}}>
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.optionBox}>
              <div className="container">
                <div className="row">
                  <div className="col-2 pl-0 pr-0 pt-2 pb-2 text-center">
                    <img alt="Wissify plan Learning" style={{maxHeight:40}} src="https://wissify.nyc3.digitaloceanspaces.com/aux_images/WissifyLogoDollar.png"/>
                    <p className={classes.nameOptionBox}>Accounting Sin Limites</p>
                  </div>
                  <div className="col-8" style={{alignSelf:"center"}}>
                    <div className={classes.descriptionOption}>
                      <span className={classes.descriptionPlan}>
                      ¡Podras realizar pagos ilimitados!
                      </span>
                      <span className={classes.pricePlan}>
                      $19.99/mes
                      </span>
                    </div>
                  </div>
                  <div className="col-2" style={{alignSelf: "center", textAlign: "center"}}>
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={classes.box}>
          <div className={classes.headBox}>
            Selecciona un medio de pago
          </div>
          <div className={classes.contentBox}>
            <div>
              <span>Tarjeta de credito/debito</span>
              <div className="pt-2 pb-2">
                <button type="button" className={classes.buttonPayMode}><SVG className={classes.svg} src={toAbsoluteUrl("/media/svg/logos/visa.svg")}/></button>
              </div>
            </div>
            <div>
              <span>Transacción bancaria</span>
              <div className="pt-2 pb-2">
                <button type="button" className={classes.buttonPayMode}>
                  <div>
                    <AccountBalanceIcon/>
                    <span style={{marginLeft:"5px"}}>Transferencia</span>
                  </div>
                </button>
                <button type="button" className={classes.buttonPayMode}>
                  <div>
                    <AccountBalanceIcon/>
                    <span style={{marginLeft:"5px"}}>Deposito</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}