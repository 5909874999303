import React, {Component} from 'react'
import classNames from 'classnames';
import axios from 'axios'
import ServerURL from '../../../ServerURL';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { connect } from "react-redux";
import { MultiSelect } from 'primereact/multiselect';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Checkbox } from 'primereact/checkbox';

// Falta acomodar el codigo de Program_type a GroupAgencies

const url = ServerURL();


class ListGroupAgencies extends Component {
            
  emptyGroupAgencies = {
    id: null,
    fk_id_enterprise: this.props.my_user.fk_id_enterprise,
    name: '',
    agencies: [],
  }

  constructor(props) {
    super(props);  

    this.state = {
      /**
       * Aux messages
       */
      nameMessage: '',
      agenciesMessage: '',

      fk_id_enterprise: this.props.my_user.fk_id_enterprise,
      groups_agencies: null,
      group_agencies: this.emptyGroupAgencies,
      agreements_original: null,
      agreements_show: null,
      program_types:null,
      ids:null,
      globalFilter: null,
      selectedGroupsAgencies: null,
      group_agencieDialog: false,
      deleteGroupAgenciesDialog: false,
      deleteGroupsAgenciesDialog: false,
      submitted: false,
      selectedCountries: [],
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate(),
      searchText: "",
      allAgreementsSelected: false
    }
  
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.saveGroupAgencies = this.saveGroupAgencies.bind(this);
    this.deleteGroupAgencies = this.deleteGroupAgencies.bind(this);
    this.openNew = this.openNew.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.editGroupAgencies = this.editGroupAgencies.bind(this);
    this.confirmDeleteProgramType= this.confirmDeleteProgramType.bind(this);
    this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
    this.deleteSelectedGroupsAgencies = this.deleteSelectedGroupsAgencies.bind(this);
    this.hideDeleteProgramTypeDialog = this.hideDeleteProgramTypeDialog.bind(this);
    this.hideGroupsAgenciesDialog = this.hideGroupsAgenciesDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.countryTemplate = this.countryTemplate.bind(this);
    this.selectedCountriesTemplate = this.selectedCountriesTemplate.bind(this);
    this.changeCheck = this.changeCheck.bind(this);
    this.changeCountry = this.changeCountry.bind(this);

  }
  


  countryTemplate(option) {
    return (
        <div className="data-item">
            <div>{option.country_name}</div>
        </div>
    );
  }
  
  selectedCountriesTemplate(option) {
    if (option) {
        return (
            <div className="country-item data-item-value">
                <div>{option.country_name}</div>
            </div>
        );
    }

    return this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.ALL_COUNTRIES'});
  }
     
  changeCheck(id){
    const arrayAgreementsOriginal = this.state.agreements_original;
    const searchText = this.state.searchText;
    const newArrayAgrementsOriginal = [];
    const newArrayAgrementsShow = [];

    arrayAgreementsOriginal.forEach(agreement => {
      if(agreement.id === id){
        agreement = {...agreement, isCheck: !agreement.isCheck};
      }
      newArrayAgrementsOriginal.push(agreement)

      const foundNameEnterprise = agreement.enterprise_name.indexOf(searchText);
      if(foundNameEnterprise !== -1 || searchText.length === 0){
        if(this.state.selectedCountries.length === 0){
          newArrayAgrementsShow.push(agreement)
        }else{
          this.state.selectedCountries.forEach(country => {
            if(country.code === agreement.code){
              newArrayAgrementsShow.push(agreement)
            }
          })
        }
      }
    })

    const newArrayIds = []
    newArrayAgrementsOriginal.forEach(agreement => {
      if(agreement.isCheck){
        newArrayIds.push(agreement.id)
      }
    })

    let allChecked = true;
    newArrayAgrementsShow.forEach(agreement => {
      if(agreement.isCheck === false){
        allChecked = false;
      }
    })

    const group_agencies = {...this.state.group_agencies, agencies: newArrayIds}
    this.setState({agreements_original:newArrayAgrementsOriginal, agreements_show: newArrayAgrementsShow, group_agencies, allAgreementsSelected: allChecked})
  }

  changeCountry(listCountrys){
    const searchText = this.state.searchText;

    const arrayAgreementsOriginal = this.state.agreements_original
    const newArrayAgrementsShow = []

    arrayAgreementsOriginal.forEach(agreement => {
      const foundNameEnterprise = agreement.enterprise_name.indexOf(searchText);
      if(foundNameEnterprise !== -1){
        if(listCountrys.length === 0){
          newArrayAgrementsShow.push(agreement)
        }else{
          listCountrys.forEach(country => {
            if(country.code === agreement.code){
              newArrayAgrementsShow.push(agreement)
            }
          })
        }
      }
    })

    let allChecked = true;
    newArrayAgrementsShow.forEach(agreement => {
      if(agreement.isCheck === false){
        allChecked = false;
      }
    })
    
    console.log(newArrayAgrementsShow.length)
    if(newArrayAgrementsShow.length === 0){
      allChecked = false;
    }
    
    this.setState({ selectedCountries: listCountrys, agreements_show: newArrayAgrementsShow, allAgreementsSelected: allChecked })
  }

  onChangeSearch(searchText){
    const arrayAgreementsOriginal = this.state.agreements_original
    const newArrayAgrementsShow = []

    arrayAgreementsOriginal.forEach(agreement => {

      const foundNameEnterprise = agreement.enterprise_name.indexOf(searchText);
      if(foundNameEnterprise !== -1){
        if(this.state.selectedCountries.length === 0){
          newArrayAgrementsShow.push(agreement)
        }else{
          this.state.selectedCountries.forEach(country => {
            if(country.code === agreement.code){
              newArrayAgrementsShow.push(agreement)
            }
          })
        }
      }
    })

    let allChecked = true;
    newArrayAgrementsShow.forEach(agreement => {
      if(agreement.isCheck === false){
        allChecked = false;
      }
    })

    if(newArrayAgrementsShow.length === 0){
      allChecked = false;
    }

    this.setState({ searchText, agreements_show: newArrayAgrementsShow, allAgreementsSelected: allChecked })
  }

  changeCheckAll(boolean) {
    console.log(boolean)
    const arrayAgreementsOriginal = this.state.agreements_original;
    const newArrayAgrementsOriginal = [];
    const arrayAgrementsShow = this.state.agreements_show;
    const newArrayAgrementsShow = [];

    const arrayIdsBooleans = []
    arrayAgrementsShow.forEach(agreement => {
      agreement = {...agreement, isCheck: boolean};
      arrayIdsBooleans.push(agreement.id);
      newArrayAgrementsShow.push(agreement)
    })

    arrayAgreementsOriginal.forEach(agreement => {
      const found = arrayIdsBooleans.find(id => id === agreement.id);
      if(found){
        agreement = {...agreement, isCheck: boolean};
      }
      newArrayAgrementsOriginal.push(agreement)
    })

    const newArrayIds = []
    newArrayAgrementsOriginal.forEach(agreement => {
      if(agreement.isCheck){
        newArrayIds.push(agreement.id)
      }
    })
    const group_agencies = {...this.state.group_agencies, agencies: newArrayIds}
    this.setState({agreements_original: newArrayAgrementsOriginal, agreements_show: newArrayAgrementsShow, group_agencies, allAgreementsSelected: boolean})
  }

  componentDidMount() {
    console.log("Actualizo")
    this.getGroupsAgencies()
    this.getAgreements()
    this.getCountries()
  }

  isValid(){
    const { name, agencies } = this.state.group_agencies
    const nameMessage = this.validateName()
    const agenciesMessage = this.validateAgencies()
  

    if(name && agencies ){
      if(!nameMessage && !agenciesMessage){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }
      
  validateName() {
    const group_agencies = this.state.group_agencies
    
    let nameMessage;
    if (!group_agencies.name) {
      nameMessage = this.props.intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED_FIELD'});
    } else {
      nameMessage = ''
    }
    this.setState({ nameMessage })
    return nameMessage
  }

  validateAgencies() {
    const group_agencies = this.state.group_agencies
    
    let agenciesMessage;
    if (!group_agencies.agencies) {
      agenciesMessage = this.props.intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED_FIELD'});
    } else {
      agenciesMessage = ''
    }
    this.setState({ agenciesMessage })
    return agenciesMessage
  }

  getTextAuxName(){
    const message = this.state.nameMessage
    return message
  }
  
  getTextAuxAgencies(){
    const message = this.state.agenciesMessage
    return message
  }

  getGroupsAgencies = async () =>{
    try {
      await axios.get(`${url}/api/auth/groups_agencies/${this.state.fk_id_enterprise}`)
      .then(res => {
        const groups_agencies = res.data;
        this.setState({ groups_agencies })
      });
      }catch(error){
    }
  }

  getCountries = async () =>{
    try {
      await axios.get(`${url}/api/auth/countries`)
      .then(res => {
        const countries = res.data;
        console.log(countries);
        this.setState({ countries })
      });
      }catch(error){
    }
  }

  getAgreements = async () =>{ 
    try {
      await axios.get(`${url}/api/auth/agencies/${this.state.fk_id_enterprise}`)
      .then(res => {
        const agreements_original = res.data;
        console.log(agreements_original)
        this.setState({ agreements_original, agreements_show: agreements_original })
      });
    }catch(error){
    }
  }

  deleteGroupAgencies(){
    let data = {...this.state.group_agencies};

    try {
    let res = axios.delete(`${url}/api/auth/group_agencies/${data.id}`)
      .then(res => {
      this.getGroupsAgencies()
    })
    if (!res.error) {
      this.setState({
        group_agencies: data,
        deleteGroupAgenciesDialog: false
    });
    this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'LEARNING_GROUP_LIST.DELETE_GROUP'}), life: 3000 });
    }
      }catch(error){
      } 
  }      

  saveGroupAgencies() {
    let state = { submitted: true };

    // if (this.state.program_type.program_type.trim()) {     
    const decision = this.isValid();

    if (decision) {  
      let data = {...this.state.group_agencies};

      if (this.state.group_agencies.id) {
        try {
          let res = axios.put(`${url}/api/auth/group_agencies/${data.id}`,data)
            .then(res =>{
              this.getGroupsAgencies()
            })
          if (!res.error) {     
            // this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: 'Program Type Updated', life: 3000 });
          }
        }catch(error){}         
      }else{
        try {
          let res = axios.post(`${url}/api/auth/group_agencies`,data)
          .then(res => {
                this.getGroupsAgencies()
            })
          if (!res.error) {
            // this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: 'Program Type Created', life: 3000 });
          }
        }catch(error){}   
      }
      state = {
        ...state,
        group_agencieDialog: false,
        group_agencies: this.emptyGroupAgencies
      }; 
    }
    this.setState(state);
  }
      
  openNew() {
    const newArrayAgrementsOriginal = []

    this.state.agreements_original.forEach(agreement => {
      agreement.isCheck = false;
      newArrayAgrementsOriginal.push(agreement);
    })
    this.setState({
        group_agencies: this.emptyGroupAgencies,
        submitted: false,
        group_agencieDialog: true,
        selectedCountries: [],
        agreements_original: newArrayAgrementsOriginal,
        agreements_show: newArrayAgrementsOriginal
    })
  }

  onInputChange(e, name) {
    const val = (e.target && e.target.value) || '';
    let group_agencies = {...this.state.group_agencies};
    group_agencies[`${name}`] = val;
    this.setState({ group_agencies} );
  }



  hideDialog() {
      this.setState({
          submitted: false,
          group_agencieDialog: false,
          group_agencieMessage: '',
          agenciesMessage: '',
          group_agencies: this.emptyGroupAgencies,
          allAgreementsSelected: false
    })  
  } 


  editGroupAgencies(group_agencies) {
    const newArrayAgrementsOriginal = []
    let allChecked = true;
    this.state.agreements_original.forEach(agreement => {
      const found = group_agencies.agencies.find(id => id === agreement.id);
      if(found){
        agreement.isCheck = true;
      }else{
        agreement.isCheck = false;
        allChecked = false;
      }
      newArrayAgrementsOriginal.push(agreement);
    })

    if(newArrayAgrementsOriginal.length === 0){
      allChecked = false;
    }

    this.setState({
      group_agencies: { ...group_agencies },
      group_agencieDialog: true,
      selectedCountries: [],
      agreements_original: newArrayAgrementsOriginal,
      agreements_show: newArrayAgrementsOriginal,
      allAgreementsSelected: allChecked
    })

  }

  confirmDeleteProgramType(group_agencies) {
    this.setState({
        group_agencies,
        deleteGroupAgenciesDialog: true
    })
  }

  confirmDeleteSelected() {
    this.setState({ deleteGroupsAgenciesDialog: true })
  }

  async deleteSelectedGroupsAgencies() {
    let groups_agencies = this.state.groups_agencies.filter(val => !this.state.selectedGroupsAgencies.includes(val));
    let data = this.state.groups_agencies.filter(val => this.state.selectedGroupsAgencies.includes(val));

    try {
      await axios.delete(`${url}/api/auth/groups_agencies`, {data})
      .then(res => {
      this.getGroupsAgencies()
      this.setState({
        groups_agencies,
        deleteGroupsAgenciesDialog: false,
        selectedGroupsAgencies: null
      });
      this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'LEARNING_GROUP_LIST.DELETE_GROUP'}), life: 3000 });
    })
    }catch(error){
    }
  }

  hideDeleteProgramTypeDialog() {
    this.setState({ deleteGroupAgenciesDialog: false })
  }
    
  hideGroupsAgenciesDialog() {
    this.setState({ deleteGroupsAgenciesDialog: false });
  }
  
  exportCSV() {
    this.dt.exportCSV();
  }
    
  actionBodyTemplate(rowData) {
    return (
      <>
        <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editGroupAgencies(rowData)} />
        <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteProgramType(rowData)} />
      </>
    )
  }

  renderAgreement(data){
    return(
      <div key={data.id} className="col-3">
        <div className="card m-2 p-4" style={{textAlign:"center"}}>
          <Checkbox inputId={data.enterprise_name} checked={data.isCheck} onChange={e => this.changeCheck(data.id)}></Checkbox>
          <div htmlFor={data.enterprise_name}>
            <div>
              <img style={{borderRadius:"100%", height:"100px"}} alt={data.enterprise_name} src={data.logo}/>
            </div>
            <hr/>
            <span className="p-text-bold" style={{display:"block"}}>{data.enterprise_name}</span>
            <span><FormattedMessage id="ADMIN_GENERAL.WEBSITE"/>: {data.website}</span>
          </div>
        </div>
      </div>
    )
  }

  render(){
    const deleteGroupAgenciesDialogFooter = (
    <>
      <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteProgramTypeDialog} />}
      </FormattedMessage>
      <FormattedMessage id="GENERAL.YES">
        {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.deleteGroupAgencies} />}
      </FormattedMessage>
    </>
    );

    const GroupAgenciesFooter = (
    <>
      <FormattedMessage id="GENERAL.CANCEL">
        {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
      </FormattedMessage>
      <FormattedMessage id="GENERAL.SAVE">
        {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveGroupAgencies} />}
      </FormattedMessage>
    </>
    );

    const groupsAgenciesDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.NO">
          {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideGroupsAgenciesDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedGroupsAgencies} />}
        </FormattedMessage>
      </>
    );


    return(
      <div>
        <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
            <FormattedMessage id="LEARNING_GENERAL.AGENCY_GROUP_LIST"/>
                <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="LEARNING_GROUP_LIST.SUB_TITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
              {/*TOOL BAR */} 
              <div style={{ marginRight: 10}}>
                <FormattedMessage id="GENERAL.EXPORT">
                  {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                </FormattedMessage>
              </div>

              <div style={{ marginRight: 10}}>
                <FormattedMessage id="GENERAL.DELETE">
                  {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedGroupsAgencies || !this.state.selectedGroupsAgencies.length} />}
                </FormattedMessage>
              </div>

              <div style={{ marginRight: 10}}>
                <FormattedMessage id="LEARNING_GROUP_LIST.NEW">
                  {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                </FormattedMessage>
              </div>

              <div style={{ marginRight: 10}}>
                <p>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <FormattedMessage id="GENERAL.SEARCH">
                      {(message) => <InputText type={message} onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />}
                    </FormattedMessage>
                  </span>
                </p>
              </div>                      
            </div>
          </div>

          
          <div>
           {/*SHOW AGREEMENTS */}
            <div className="card">
              <DataTable 
                ef={(el) => this.dt = el} value={this.state.groups_agencies} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                globalFilter={this.state.globalFilter} emptyMessage={this.props.intl.formatMessage({id: 'LEARNING_GROUP_LIST.NO_AGENCY_GROUPS'})} loading={this.state.loading}
                selection={this.state.selectedGroupsAgencies} onSelectionChange={e => this.setState({ selectedGroupsAgencies: e.value })} dataKey="id">
                <Column selectionMode="multiple"></Column>
                <Column field="name" header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SEARCH_BY_NAME'})} filter filterPlaceholder={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SEARCH_BY_NAME'})} sortable></Column>
                <Column body={this.actionBodyTemplate}></Column>
              </DataTable>
            </div>
           </div>
         

          <div> 
            {/*DELETE CAMPUS */}
            <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
              {(message) => (
                <Dialog visible={this.state.deleteGroupAgenciesDialog} style={{ width: '450px' }} header={message} modal footer={deleteGroupAgenciesDialogFooter} onHide={this.hideDeleteProgramTypeDialog}>
                  <div className="confirmation-content">
                      {this.state.group_agencies && <span className="d-flex"><i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', alignSelf: "center" }}/><FormattedMessage id="GENERAL_MESSAGE.DELETE_MESSAGE"/> <b>{this.state.group_agencies.name}</b>?</span>}
                  </div>
                </Dialog>
              )}
            </FormattedMessage>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
            {(message) => (
              <Dialog visible={this.state.deleteGroupsAgenciesDialog} style={{ width: '450px' }} header={message} modal footer={groupsAgenciesDialogFooter} onHide={this.hideGroupsAgenciesDialog}>
                <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                  {this.state.selectedGroupsAgencies && <span><FormattedMessage id="LEARNING_GROUP_LIST.DELETE_MESSAGE_GROUP"/></span>}
                </div>
              </Dialog>
            )}
          </FormattedMessage>


          <div>
          {/*UPDATE PROGRAM TYPE & CREATE PROGRAM TYPE*/}
          <FormattedMessage id="LEARNING_GROUP_LIST.AGREEMENTS_MESSAGE">
            {message => (
              <Dialog visible={this.state.group_agencieDialog} contentStyle={{maxHeight:"1600px", width:"1300px", height:"600px", overflow:"auto"}} header={message} modal footer={GroupAgenciesFooter} onHide={this.hideDialog}>
                <br></br>
                <div className="form-group row">
                  {/* Progrm Type */}
                  <div className="col-lg-6">  
                    <span className="p-float-label">         
                      <InputText
                        style={{ width: 250, height:33 }}
                        id="name_group"
                        variant="outlined"
                        margin="dense"
                        value={this.state.group_agencies.name}             
                        onChange={(e) => this.onInputChange(e, 'name')} 
                        required className={classNames({ 'p-invalid': this.state.submitted && !this.state.group_agencies.name  })}
                      />
                      <label htmlFor="name_group"><FormattedMessage id="LEARNING_GROUP_LIST.GROUP_NAME"/></label>        
                    </span>
                    <small id="program_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxName(this.state)}</small>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group" style={{marginBottom:0}}>
                    {/* Agencias */}
                    <div className="col-lg-6 multiselect-demo">
                      <span className="p-float-label" style={{width:"max-content"}}>
                        <FormattedMessage id="GENERAL_MESSAGE.SELECT_COUNTRY"/>
                      </span>
                      {
                        this.state.countries && (
                          <MultiSelect value={this.state.selectedCountries} options={this.state.countries}  onChange={(e) => this.changeCountry(e.value)} optionLabel="country_name" placeholder="Todos los paises" filter className="multiselect-custom"
                          itemTemplate={this.countryTemplate} selectedItemTemplate={this.selectedCountriesTemplate} />
                        )
                      }
                    </div>
                  </div>
                  <div style={{display:"grid"}}>
                    <span><FormattedMessage id="GENERAL_MESSAGE.SEARCH_BY_AGENCY_NAME"/></span>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <FormattedMessage id="GENERAL.SEARCH">
                          {message => <InputText value={this.state.searchText} style={{width:"300px"}} onChange={e => this.onChangeSearch(e.target.value)} placeholder={message} />}
                        </FormattedMessage>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col mt-6 mb-4">
                    <span className="mr-2"><FormattedMessage id="GENERAL_MESSAGE.ALL_SELECTED"/>:</span>
                    <Checkbox inputId={"allAgreements"} checked={this.state.allAgreementsSelected} onChange={e => this.changeCheckAll(e.checked)}></Checkbox>
                  </div>
                </div>
                <div className="row">
                    {
                      this.state.agreements_show ?
                      this.state.agreements_show.map(agreement => this.renderAgreement(agreement))
                      :
                      <></>
                    }

                </div>
              </Dialog>
            )}
          </FormattedMessage>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}

function mapStateToProps(state) {
  return {
    my_user: state.auth.user
  };
}

export default injectIntl(
  connect(mapStateToProps)(ListGroupAgencies)
)