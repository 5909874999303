import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";


//Traduccion
import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

//Componentes
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

//Conexion con API
import axios from 'axios'
import ServerURL from '../../../ServerURL'

// Redux
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux"

//Funciones extras
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import Moment from 'moment'

//PrimeReact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


const url = ServerURL();

class ProgramInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            program_name: this.props.dataStudent.program_name,
            program_start_date: this.props.dataStudent.program_start_date,
            program_end_date: this.props.dataStudent.program_end_date,
            school_destination: this.props.dataStudent.school_destination,
            accommodation_type: this.props.dataStudent.accommodation_type,
            room_type: this.props.dataStudent.room_type,
            accommodation_start_date: this.props.dataStudent.accommodation_start_date,
            accommodation_end_date: this.props.dataStudent.accommodation_end_date,
            accommodation_address: this.props.dataStudent.accommodation_address,

            submitted: false,

            redirect: false,

            selectedAccommodationType: null,
            resetAccommodationType: null,

            selectedRoomType: null,
            resetRoomType: null,


        }
        this.accommodation_type = [
            {accommodation_type: 'Hotel'},
            {accommodation_type: 'Residence'},
            {accommodation_type: 'Host family'},
        ]

        this.room_type = [
            {room_type: 'Single'},
            {room_type: 'Shared'},
        ]

        this.updateStudent = this.updateStudent.bind(this)
        this.changePage = this.changePage.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
        this.onAccommodationType = this.onAccommodationType.bind(this)
        this.onRoomType = this.onRoomType.bind(this)
    }

    changePage(){
        this.setState({redirect: true})
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let student = {...this.state.student}
        student[`${name}`] = val
        this.setState({ student })
    }

    onAccommodationType(e) {
        let student = {...this.state}
        student['accommodation_type'] = e.value
        this.setState({ selectedAccommodationType: e.value, student})
        console.log("Valor: ", student['accommodation_type']);
        // console.log("State: ", student);

    }

    onRoomType(e) {
        let student = {...this.state}
        student['room_type'] = e.value
        this.setState({ selectedRoomType: e.value, student})
        console.log("Valor: ", student['room_type']);
        // console.log("State: ", student);

    }

    async updateStudent() {
        let state ={ submitted: true }
        let data = {
            name: this.state.program_name,
            program_start_date: Moment.utc(this.state.program_start_date).format('YYYY-MM-DD'),
            program_end_date: Moment.utc(this.state.program_end_date).format('YYYY-MM-DD'),
            school_destination: this.state.school_destination,
            accommodation_type: this.state.accommodation_type ,
            accommodation_address: this.state.accommodation_address,
            room_type: this.state.room_type,
            accommodation_start_date: Moment.utc(this.state.accommodation_start_date).format('YYYY-MM-DD'),
            accommodation_end_date: Moment.utc(this.state.accommodation_end_date).format('YYYY-MM-DD')
        }
        // console.log("Data :", data);

        try{
            await axios.put(`${url}/api/public/students-program/${this.props.dataStudent.id}`, data)
            .then(res => {
                const dataStudent = {
                    ...this.props.dataStudent,
                    program_name: data.name,
                    program_start_date: data.program_start_date,
                    program_end_date: data.program_end_date,
                    school_destination: data.school_destination,
                    accommodation_type: data.accommodation_type,
                    accommodation_address: data.accommodation_address,
                    room_type: data.room_type,
                    accommodation_start_date: data.accommodation_start_date,
                    accommodation_end_date: data.accommodation_end_date
                };
                this.props.updatedStudent(dataStudent)
                console.log(res);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Student updated', life: 3000})
            })
        } catch (error){
            console.log(error);
        }
        console.log("State: ", state);
        console.log("Data: ", data);
        this.setState({...state, data})
    }

    render(){

        if(this.state.redirect){
            return <Redirect to={{
                pathname: '/wiyu-students'
            }} />
        }

        return(
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className='d-flex justify-content-between mb-2'>
                    <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" icon="pi pi-arrow-left" onClick={this.changePage} variant='contained'/>
                </div>
                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <h5 className='card-label'> Program info
                        <span className="d-block text-muted pt-2 font-size-sm" >Update program info</span>
                    </h5>
                </div>
                <div>
                    <div className='card'>
                        <br/>
                        <br/>
                        <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>

                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-globe'></i>
                            </span>
                            {/* Program name */}
                            <span className='p-float-label'>
                                <InputText 
                                // placeholder={this.props.dataStudent.program_name || 'Program name'}
                                value={this.state.program_name}
                                id="program_name"
                                variant="outlined"
                                margin="dense"
                                onChange={(e) => this.setState({program_name: e.target.value})}
                                />                            
                                <label htmlFor='program_name'>Program name</label>
                            </span>
                        </div>
                        <small className='p-d-block' id='program_name-help'>Enter program name</small>
                    </div>

                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-desktop'></i>
                            </span>
                            {/* School program */}
                            <span className='p-float-label'>
                                <InputText 
                                // placeholder={this.props.dataStudent.school_destination || 'School destination'}
                                value={this.state.school_destination}                            
                                id="school_destination"
                                variant="outlined"
                                margin="dense"
                                onChange={(e) => this.setState({school_destination: e.target.value})}
                                />
                                <label htmlFor='school_destination'>School destination</label>
                            </span>                       
                        </div>
                        <small className='p-d-block' id='school_destination-help'>Enter school destination of the program.</small>
                    </div>
                </div>

                <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-calendar-plus'></i>
                            </span>
                            {/* Program start date */}
                            {
                                // this.props.dataStudent.program_start_date === null ?
                                this.props.dataStudent.program_start_date === null ?
                                <span className='p-float-label'>
                                    <Calendar 
                                        id='basic'
                                        name='program_start_date'
                                        value={this.state.program_start_date}
                                        // value={this.props.dataStudent.program_start_date}
                                        onChange={(e) => this.setState({ program_start_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // readOnlyInput
                                        // placeholder= {this.props.dataStudent.insurance_start_date}
                                    />
                                    <label htmlFor='program_start_date'> Program start date</label>
                                </span> 
                                :
                                this.props.dataStudent.program_start_date === 'Invalid date' ?
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='basic'
                                            name='program_start_date'
                                            value={this.state.program_start_date}
                                            onChange={(e) => this.setState({ program_start_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // readOnlyInput
                                            // placeholder= {Moment.utc(this.props.dataStudent.program_start_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='program_start_date'> Program start date</label>
                                    </span>
                                :
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='basic'
                                            name='program_start_date'
                                            value={this.state.program_start_date}
                                            onChange={(e) => this.setState({ program_start_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // readOnlyInput
                                            placeholder= {this.props.dataStudent.program_start_date}
                                        />
                                        <label htmlFor='program_start_date'> Program start date</label>
                                    </span> 
                            }
                        </div>
                        <small className='p-d-block' id='program_start_date-help'>Format YYYY-MM-DD</small>
                    </div>

                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-calendar-times'></i>
                            </span>
                            {/* Program end date */}
                            {
                                this.props.dataStudent.program_end_date === null ?
                                <span className='p-float-label'>
                                    <Calendar 
                                        id='basic'
                                        name='program_end_date'
                                        value={this.state.program_end_date}
                                        // value={this.props.dataStudent.program_start_date}
                                        onChange={(e) => this.setState({ program_end_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // readOnlyInput
                                        // placeholder= {this.props.dataStudent.insurance_start_date}
                                    />
                                    <label htmlFor='program_end_date'> Program end date</label>
                                </span> 
                                :
                                this.props.dataStudent.program_end_date === 'Invalid date' ?
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='basic'
                                            name='program_end_date'
                                            value={this.state.program_end_date}
                                            onChange={(e) => this.setState({ program_end_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // readOnlyInput
                                            // placeholder= {Moment.utc(this.props.dataStudent.program_start_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='program_end_date'> Program end date</label>
                                    </span>
                                :
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='basic'
                                            name='program_end_date'
                                            value={this.state.program_end_date}
                                            onChange={(e) => this.setState({ program_end_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // readOnlyInput
                                            placeholder= {this.props.dataStudent.program_end_date}
                                        />
                                        <label htmlFor='program_end_date'> Program end date</label>
                                    </span>
                            }
                        </div>
                        <small className='p-d-block' id='program_end_date-help'>Format YYYY-MM-DD</small>
                    </div>
                </div>

                <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                    {/* Accommodation */}
                    {/* <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-home'></i>
                            </span>
                            <InputText 
                            placeholder={this.props.dataStudent.accommodation || 'Accommodation'}
                            value={this.state.accommodation}                            
                            id="accommodation"
                            variant="outlined"
                            margin="dense"
                            onChange={(e) => this.setState({accommodation: e.target.value})}
                            required autoFocus
                            className={classNames({'p-invalid': this.state.submitted && !this.state.accommodation})}                             
                            />
                        </div>
                        <small className='p-d-block' id='whatsapp-help'>Hotel, Residence, Host Family</small>
                    </div> */}

                    {/* Accommodation type */}
                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-home'></i>
                            </span>
                            <span className='p-float-label'>
                                <InputText 
                                // placeholder={this.props.dataStudent.accommodation_type || 'Accommodation type'}
                                value={this.state.accommodation_type}                             
                                className='p-d-block'
                                id="accommodation_type"
                                variant="outlined"
                                margin="dense"
                                onChange={(e) => this.setState({accommodation_type: e.target.value})}
                                /> 
                                {/* <Dropdown 
                                    style={{ width: 250, height: 33 }}
                                    id='accommodation_type'
                                    variant='outlined'
                                    placeholder={ this.state.resetAccommodationType || this.props.dataStudent.accommodation_type }
                                    margin='dense'
                                    value={this.state.selectedAccommodationType || "accommodation_type"}
                                    options={this.accommodation_type}
                                    // onChange={(e) => this.setState({selectedAccommodationType: e.target.value})}
                                    onChange={this.onAccommodationType}
                                    optionLabel='accommodation_type'
                                    optionValue='accommodation_type'
                                /> */}
                                <label htmlFor='accommodation_type'>Accommodation type</label>  
                            </span>
                        </div>
                        <small className='p-d-block' id='whatsapp-help'>Hotel, Residence, Host Family </small>
                    </div>

                    {/* Room type */}   
                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className="pi pi-home"></i>
                            </span>
                            <span className='p-float-label'>
                                <InputText 
                                // placeholder={this.props.dataStudent.room_type || 'Room type'}
                                value={this.state.room_type}                             
                                id="room_type"
                                variant="outlined"
                                margin="dense"
                                onChange={(e) => this.setState({room_type: e.target.value})}
                                />
                                {/* <Dropdown 
                                    style={{ width: 250, height: 33 }}
                                    id='room_type'
                                    variant='outlined'
                                    placeholder={ this.state.resetRoomType || this.props.dataStudent.room_type }
                                    margin='dense'
                                    value={this.state.selectedRoomType || "room_type"}
                                    options={this.room_type}
                                    // onChange={(e) => this.setState({selectedRoomType: e.target.value})}
                                    onChange={this.onRoomType}
                                    optionLabel='room_type'
                                    optionValue='room_type'
                                /> */}
                                <label htmlFor='room_type'>Room type</label>                        
                            </span>
                        </div>
                        <small className='p-d-block' id='whatsapp-help'>Single room, Share</small>

                    </div>
                </div>
                
                {/* Accommodation address */}
                <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                    <div className='p-col-12'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-home'></i>
                                </span>
                                <span className='p-float-label'>
                                    <InputText 
                                    // placeholder={this.props.dataStudent.accommodation_type || 'Accommodation type'}
                                    value={this.state.accommodation_address}                             
                                    className='p-d-block'
                                    id="accommodation_address"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={(e) => this.setState({accommodation_address: e.target.value})}
                                    /> 
                                    {/* <Dropdown 
                                        style={{ width: 250, height: 33 }}
                                        id='accommodation_type'
                                        variant='outlined'
                                        placeholder={ this.state.resetAccommodationType || this.props.dataStudent.accommodation_type }
                                        margin='dense'
                                        value={this.state.selectedAccommodationType || "accommodation_type"}
                                        options={this.accommodation_type}
                                        // onChange={(e) => this.setState({selectedAccommodationType: e.target.value})}
                                        onChange={this.onAccommodationType}
                                        optionLabel='accommodation_type'
                                        optionValue='accommodation_type'
                                    /> */}
                                    <label htmlFor='accommodation_address'>Accommodation address</label>  
                                </span>
                            </div>
                            <small className='p-d-block' id='accommodation_address-help'>Type the accommodation address </small>
                        </div>
                </div>

                <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-calendar-plus'></i>
                            </span>
                            {/* Accommodation start date */}
                            {
                                this.props.dataStudent.accommodation_start_date === null ?
                                <>
                                    <span className='p-float-label'>
                                        <Calendar 
                                        id='accommodation_start_date'
                                        value={this.state.accommodation_start_date}
                                        // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                        onChange={(e) => this.setState({ accommodation_start_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // placeholder= {Moment.utc(this.props.dataStudent.accommodation_start_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='accommodation_start_date'>Accommodation start date</label>
                                    </span>
                                </>
                                :
                                this.props.dataStudent.accommodation_start_date === 'Invalid date' ?
                                    <span className='p-float-label'>
                                        <Calendar 
                                        id='accommodation_start_date'
                                        value={this.state.accommodation_start_date}
                                        // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                        onChange={(e) => this.setState({ accommodation_start_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // placeholder= {Moment.utc(this.props.dataStudent.accommodation_start_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='accommodation_start_date'>Accommodation start date</label>
                                    </span>
                                :
                                    <span className='p-float-label'>
                                        <Calendar 
                                        id='accommodation_start_date'
                                        value={this.state.accommodation_start_date}
                                        // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                        onChange={(e) => this.setState({ accommodation_start_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        placeholder= {Moment.utc(this.props.dataStudent.accommodation_start_date).format("DD/MM/YY")}
                                        />
                                        <label htmlFor='accommodation_start_date'>Accommodation start date</label>
                                    </span>
                            }
                        </div>
                        <small className='p-d-block' id='accommodation_start_date-help'>Format YYYY-MM-DD</small>
                    </div>

                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className="pi pi-calendar-times"></i>
                            </span>
                            {/* Accommodation end date */}
                            {
                                this.props.dataStudent.accommodation_end_date === null ?
                                <>
                                    <span className='p-float-label'>
                                    <Calendar 
                                        id='accommodation_end_date'
                                        value={this.state.accommodation_end_date}
                                        // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                        onChange={(e) => this.setState({ accommodation_end_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // placeholder= {Moment.utc(this.props.dataStudent.accommodation_end_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='accommodation_en_date'>Accommodation end date</label>
                                    </span>
                                </>
                                :
                                this.props.dataStudent.accommodation_end_date === 'Invalid date' ?
                                    <span className='p-float-label'>
                                        <Calendar 
                                        id='accommodation_end_date'
                                        value={this.state.accommodation_end_date}
                                        // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                        onChange={(e) => this.setState({ accommodation_end_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // placeholder= {Moment.utc(this.props.dataStudent.accommodation_end_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='accommodation_en_date'>Accommodation end date</label>
                                    </span>
                                :
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='accommodation_end_date'
                                            value={this.state.accommodation_end_date}
                                            // value={Moment(this.state.accommodation_end_date).format("YYYY-MM-DD")}      
                                            onChange={(e) => this.setState({ accommodation_end_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            placeholder= {Moment.utc(this.props.dataStudent.accommodation_end_date).format("DD/MM/YY")}
                                        />
                                        <label htmlFor='accommodation_en_date'>Accommodation end date</label>
                                    </span>
                            }

                        </div>
                        <small className='p-d-block' id='accommodation_end_date-help'>Format YYYY-MM-DD</small>
                    </div>
                </div>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end',
                    paddingBottom: 10,
                    marginRight: 5
                }}>
                    {/* Boton cancelar */}
                    <div style={{marginLeft: 10}}>
                        <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" label='Cancelar' icon="pi pi-times" onClick={this.changePage} variant='contained'/>
                    </div>

                    {/* Boton guardar */}
                    <div style={{marginLeft: 10}}>
                        <Button className='p-button-rounded p-button-help p-button-outlined' type="button" label='Guardar' icon="pi pi-check" onClick={this.updateStudent} variant='contained'/>
                    </div>
                </div>

                    </div>
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataStudent: state.auth.dataStudent
})

export default injectIntl(
    connect(mapStateToProps, auth.actions)(ProgramInfo)
)