import React from "react";
import {useSubheader} from "../../_metronic/_core";
import Reports from "../modulesAgency/Reports"
import { useIntl } from "react-intl";

export const ReportsPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(int1.formatMessage({id: "LEARNING_GENERAL.REPORTS"}));
    return (<Reports/>)
}