import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios'
import Badge from '@material-ui/core/Badge';
// import classNames from 'classnames';
import { connect } from 'react-redux';
import ServerURL from '../../../ServerURL'
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import * as Validation from '../../../Validation';
import { Switch } from '@material-ui/core';
import { Checkbox } from 'primereact/checkbox';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { FormattedMessage, injectIntl } from 'react-intl';


const url = ServerURL();

class DataUser extends Component {

  constructor(props) {
    super(props);
    this.state = {

      /**
       * Aux Messages
       */
      emailMessage: '',
      passwordMessage: '',
      firstNameMessage: '',
      lastNameMessage: '',
      phoneMessage: '',

      enterprise_data: null,
      enterpriseWebsiteEdit: false,
      enterpriseEmailEdit: false,
      disabledConfig: true,
      originalPhoto: this.props.user.photo,
      originalEmail: this.props.user.email,
      originalPassword: this.props.user.password,
      originalFirstName: this.props.user.first_name,
      originalLastName: this.props.user.last_name,
      originalPhone: this.props.user.phone,
      imgFile: null,
      onSubmit: false,
      user: {
        id: this.props.user.id,
        email: this.props.user.email,
        password: this.props.user.password,
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        phone: this.props.user.phone,
        photo: this.props.user.photo,
      },
      tutorials: this.props.user.tutorials
    };

    this.resetData = this.resetData.bind(this);
  }

  changeImage(e) {
    this.setState({ user: { ...this.state.user, photo: URL.createObjectURL(e.target.files[0]) }, imgFile: e.target.files[0] })
  }

  handleChange = (event) => {
    let bool = null;
    switch (event.target.name) {
      case "tutorials":

        if (event.target.checked) {
          bool = 1;
        } else {
          bool = 0;
        }
        console.log(event.target.checked)
        this.switchTutorial(bool);
        break;

      default:
        break;
    }

    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  switchTutorial = async (checked) => {
    try {
      await axios.put(`${url}/api/auth/user_hide_tutorial`)
        .then(res => {
          const user = {
            ...this.props.user,
            tutorials: checked
          }
          this.props.updatedUser(user)
          this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({ id: 'GENERAL.UPDATED' }), life: 3000 });
        })
    } catch (error) {

    }
  }

  resetData() {
    const { user, originalEmail, originalPassword, originalFirstName, originalLastName, disabledConfig, originalPhone, originalPhoto } = this.state;
    this.setState({
      user: {
        id: user.id,
        email: originalEmail,
        password: originalPassword,
        first_name: originalFirstName,
        last_name: originalLastName,
        phone: originalPhone,
        photo: originalPhoto
      },
      emailMessage: '',
      passwordMessage: '',
      firstNameMessage: '',
      lastNameMessage: '',
      phoneMessage: '',
      fkIdRoleMessage: '',
      imgFile: null,
      disabledConfig: !disabledConfig
    })
  }

  onSubmit = async () => {
    if (this.isValid()) {
      this.setState({ onSubmit: true });
      this.saveData();
    }
  }

  isValid() {

    const { email, password, first_name, last_name, phone } = this.state.user


    const emailMessage = Validation.validateEmail(this.state)
    this.setState({ emailMessage })
    const passwordMessage = Validation.validatePassword(this.state)
    this.setState({ passwordMessage })
    const firstNameMessage = Validation.validateFirstName(this.state)
    this.setState({ firstNameMessage })
    const lastNameMessage = Validation.validateLastName(this.state)
    this.setState({ lastNameMessage })
    const phoneMessage = Validation.validatePhone(this.state)
    this.setState({ phoneMessage })

    if (email && password && first_name && last_name && phone) {
      if (!emailMessage && !passwordMessage && !firstNameMessage && !lastNameMessage && !phoneMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  saveData = async () => {
    let data = { ...this.state.user };
    const imgFile = this.state.imgFile
    const formData = new FormData();
    console.log(data);
    formData.append('flphoto', imgFile);
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('phone', data.phone);
    formData.append('photo', data.photo);


    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }

    try {
      await axios.put(`${url}/api/auth/user/${data.id}`, formData, config)
        .then(res => {
          this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({ id: 'GENERAL_MESSAGE.USER_ENABLED' }), life: 6000 });
          this.setState({
            originalEmail: data.email,
            originalPassword: data.password,
            originalFirstName: data.first_name,
            originalLastName: data.last_name,
            originalPhone: data.phone,
            originalPhoto: data.photo,
            disabledConfig: !this.state.disabledConfig,
            imgFile: null,
            onSubmit: false
          })
          const user = {
            ...this.props.user,
            email: data.email,
            password: data.password,
            first_name: data.first_name,
            last_name: data.last_name,
            fullname: data.first_name + ' ' + data.last_name,
            phone: data.phone,
            photo: res.data.photo
          }
          this.props.updatedUser(user)
        }).catch(error => {
          switch (error.message) {
            case 'Network Error':
              this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({ id: 'GENERAL_MESSAGE.CONNECTION_ERROR' }), detail: this.props.intl.formatMessage({ id: 'GENERAL_MESSAGE.CONNECTION_ERROR_EXTRA' }), life: 6000 });
              this.setState({ onSubmit: false });
              break;

            default:
              // this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SERVER_ERROR'}), life: 6000});
              this.setState({ onSubmit: false });
              break;
          }
        })
    } catch (error) {
      this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({ id: 'GENERAL_MESSAGE.SERVER_ERROR' }), life: 6000 });
      this.setState({ onSubmit: false });
    }
  }

  render() {
    const { disabledConfig, user, onSubmit, tutorials } = this.state;

    let bool_tutorials = false;

    if (tutorials === 1 || tutorials === true) {
      bool_tutorials = true;
    }

    // console.log(disabledConfig, onSubmit)
    return (
      <div className='card align-content-center'>
        <Toast ref={(el) => this.toast = el} />
        <div className='grid nested-grid' style={{ marginTop: 10 }}>
          <div className='col-12'>
            <div className='grid'>
              <div className='col-3'>
                <div style={{ textAlign: "-webkit-center" }}>
                  {
                    !disabledConfig && user.photo ?
                      <Badge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        badgeContent={<Button icon="pi pi-times" className="p-button-rounded p-button-secondary" onClick={(e) => this.setState({ user: { ...user, photo: null } })} disabled={onSubmit}></Button>}
                      >
                        <Avatar className="font-size-h1" alt={this.state.originalFirstName} src={`${this.state.user.photo}`} width={40} style={{ margin: "10px", width: "100px", height: "100px", background: "#C9F7F5", color: "#1BC5BD" }} />
                      </Badge>
                      :
                      <Avatar className="font-size-h1" alt={this.state.originalFirstName} src={`${this.state.user.photo}`} width={40} style={{ margin: "10px", width: "100px", height: "100px", background: "#C9F7F5", color: "#1BC5BD" }} />
                  }
                  {
                    !disabledConfig && !onSubmit ?
                      <>
                        <input id="fileInput" type="file" style={{ display: "none" }} onChange={e => this.changeImage(e)} />
                        <input type="button" className="btn btn-secondary mx-auto d-block mt-1" value={this.props.intl.formatMessage({ id: 'GENERAL_MESSAGE.CHANGE_IMAGE' })} onClick={() => document.getElementById('fileInput').click()} />
                      </>
                      :
                      <></>
                  }
                </div>
              </div>

              <div className='col'>
                {/* <div className='col'> */}
                <div className='formgrid grid'>
                  <div className="field col-fixed md:col-6">
                    <h5><FormattedMessage id="AUTH.INPUT.EMAIL" /></h5>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi">@</i>
                      </span>
                      <InputText placeholder={this.props.intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })} value={this.state.user.email} onChange={(e) => this.setState({ user: { ...user, email: e.target.value } })} disabled={(disabledConfig || onSubmit)} />
                    </div>
                    <small className="p-invalid">{Validation.getTextAuxEmail(this.state)}</small>
                  </div>

                  <div className='field col-fixed md:col-6'>
                    <h5><FormattedMessage id="AUTH.INPUT.PASSWORD" /></h5>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-key"></i>
                      </span>
                      <Password placeholder={this.props.intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })} value={this.state.user.password} onChange={(e) => this.setState({ user: { ...user, password: e.target.value } })} disabled={(disabledConfig || onSubmit)} />
                    </div>
                    <small className="p-invalid">{Validation.getTextAuxPassword(this.state)}</small>
                  </div>

                  <div className='field col-7 md:col-6'>
                    <h5><FormattedMessage id="AUTH.INPUT.FIRST_NAME" /></h5>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-user"></i>
                      </span>
                      <InputText placeholder={this.props.intl.formatMessage({ id: 'AUTH.INPUT.FIRST_NAME' })} value={this.state.user.first_name} onChange={(e) => this.setState({ user: { ...user, first_name: e.target.value } })} disabled={(disabledConfig || onSubmit)} />
                    </div>
                    <small className="p-invalid">{Validation.getTextAuxFirstName(this.state)}</small>
                  </div>

                  <div className='field col-5 md:col-6'>
                    <h5><FormattedMessage id="AUTH.INPUT.LAST_NAME" /></h5>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-user"></i>
                      </span>
                      <InputText placeholder={this.props.intl.formatMessage({ id: 'AUTH.INPUT.LAST_NAME' })} value={this.state.user.last_name} onChange={(e) => this.setState({ user: { ...user, last_name: e.target.value } })} disabled={(disabledConfig || onSubmit)} />
                    </div>
                    <small className="p-invalid">{Validation.getTextAuxLastName(this.state)}</small>
                  </div>

                  <div className='field col-fixed md:col-6'>
                    <h5><FormattedMessage id="ADMIN_GENERAL.PHONE" /></h5>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-mobile"></i>
                      </span>
                      <InputText keyfilter={this.props.intl.formatMessage({ id: 'ADMIN_GENERAL.PHONE' })} placeholder="Telefono" value={this.state.user.phone} onChange={(e) => this.setState({ user: { ...user, phone: e.target.value } })} disabled={(disabledConfig || onSubmit)} />
                    </div>
                    <small className="p-invalid">{Validation.getTextAuxPhone(this.state)}</small>
                  </div>

                  <div className='field col-fixed md:col-6'>
                    <h5><FormattedMessage id="ADMIN_GENERAL.ENTERPRISE" /></h5>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-home"></i>
                      </span>
                      <InputText placeholder={this.props.intl.formatMessage({ id: 'ADMIN_GENERAL.ENTERPRISE' })} value={this.props.user.enterprise_name} disabled />
                    </div>
                  </div>

                  <div className='field col-fixed md:col-6'>
                    <h5><FormattedMessage id="ADMIN_GENERAL.ROLE" /></h5>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-sitemap"></i>
                      </span>
                      <InputText placeholder={this.props.intl.formatMessage({ id: 'ADMIN_GENERAL.ROLE' })} value={this.props.user.role} disabled />
                    </div>
                  </div>

                  <div className='field col-fixed md:col-6'>
                    <h5><FormattedMessage id="GENERAL_MESSAGE.BOSS" /></h5>
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="pi pi-users"></i>
                      </span>
                      <InputText placeholder={this.props.intl.formatMessage({ id: 'GENERAL_MESSAGE.BOSS' })} value={this.props.user.boss} disabled />
                    </div>
                  </div>

                </div>

                <div className='col-12'>
                {
                    disabledConfig ?
                      <div className='justify-content-center'>
                        <div className="mb-8 text-center">
                          <Button icon="pi pi-pencil" label={this.props.intl.formatMessage({ id: 'GENERAL.MAKE_CHANGES' })} onClick={(e) => this.setState({ disabledConfig: !disabledConfig })}></Button>
                        </div>
                      </div>

                      :
                      <div className="mb-8 d-flex justify-content-center">
                        <Button className="p-button-danger" icon="pi pi-times" label={this.props.intl.formatMessage({ id: 'GENERAL.CANCEL' })} onClick={(e) => this.resetData()} disabled={onSubmit}></Button>
                        <Button icon="pi pi-check" label={this.props.intl.formatMessage({ id: 'GENERAL.SAVE' })} onClick={(e) => this.onSubmit()} disabled={onSubmit}></Button>
                      </div>
                  }
                </div>
                {/* </div> */}
              </div>

              <div className='col-12'>
                <section className="container mt-4 mb-4 border">
                  <div className="row align-items-center">
                    <div className="col text-center">
                      <span><FormattedMessage id="GENERAL_MESSAGE.SHOW_TUTORIALS" /></span>
                    </div>
                    <div className="col text-center">
                      <Switch
                        checked={bool_tutorials}
                        onChange={this.handleChange}
                        name="tutorials"
                        color="primary"
                      />
                    </div>
                  </div>
                </section>
                {
                  this.props.user.fk_id_role_type === 11 ?
                    <div className="m-3">
                      <h2 className="text-center mb-5"><FormattedMessage id="GENERAL_MESSAGE.ASSIGNED_PLANS" /></h2>
                      <div className="p-grid">
                        {
                          this.props.user.learning ? (
                            <div className="p-col col-sm-12 col-md-6">
                              <div className="p-3 text-center d-flex justify-content-around align-items-center" style={{ border: "1px solid #EBEDF3", height: "100%" }}>
                                <div>
                                  <img alt="Wissify Learning Package" style={{ width: "50px" }} src={toAbsoluteUrl("/media/logos/WissifyLogoBook.png")} />
                                  <h5><FormattedMessage id="LEARNING_GENERAL.LEARNING" /></h5>
                                </div>
                                <div>
                                  <span><FormattedMessage id="GENERAL_MESSAGE.ACCESS_LEARNING" /></span>
                                </div>
                                <div>
                                  <Checkbox checked={true}></Checkbox>
                                </div>
                              </div>
                            </div>
                          )
                            :
                            <></>
                        }
                        {
                          this.props.user.accounting ? (
                            <div className="p-col col-sm-12 col-md-6">
                              <div className="p-3 text-center d-flex justify-content-around align-items-center" style={{ border: "1px solid #EBEDF3", height: "100%" }}>
                                <div>
                                  <img alt="Wissify Accounting Package" style={{ width: "50px" }} src={toAbsoluteUrl("/media/logos/WissifyLogoDollar.png")} />
                                  <h5><FormattedMessage id="ACCOUNTING_GENEREAL.ACCOUNTING" /></h5>
                                </div>
                                <div>
                                  <span>
                                    <FormattedMessage id="GENERAL_MESSAGE.ACCESS_ACCOUNTING" /><br />
                                    <FormattedMessage id="GENERAL_MESSAGE.OFFICE_ACCOUNTING" />: <span style={{ fontWeight: 800 }}>{this.props.user.accounting_office}</span>
                                  </span>
                                </div>
                                <div>
                                  <Checkbox checked={true}></Checkbox>
                                </div>
                              </div>
                            </div>
                          )
                            :
                            <></>
                        }
                      </div>
                    </div>
                    :
                    <></>
                }
              </div>
            </div>
          </div>


        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(DataUser)
)