import React from "react";
import {useSubheader} from "../../_metronic/_core";
import RolesTable from "../modulesAdmin/UsersAndRoles/RolesTable"

export const RolesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Roles");

  return (<><RolesTable/></>);

};