import React from 'react'
import { useSubheader } from '../../_metronic/_core'

import AgencyProfitTable from "../modulesAccounting/AgencyProfit/AgencyProfitTable"

export const AgencyProfitAgencyPage = () => {
    const subheader = useSubheader();
    subheader.setTitle("Agency Profit")

    return (<><AgencyProfitTable/></>)
}