/*import React from "react";
import {DashboardGraphs} from "../modulesSchool/Dashboard/DashboardGraphs";
export function DashboardPage() {
  return <DashboardGraphs />;
}*/


import React from "react";
import DashboardGraphs from "../modulesSchool/Dashboard/DashboardGraphs";

export const DashboardPage = () => {
  return (<><DashboardGraphs/></>);
};