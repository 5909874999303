import React from "react"

import AssignStudents from "./AssignStudents";
import CustomerSuccessItems from "./CustomerSuccessItems";
import AssignGroups from "./AssignGroups";

import { makeStyles, withStyles  } from "@material-ui/core"
import Tabs from '@material-ui/core/Tabs'
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import PropTypes from 'prop-types'
// import { FormattedMessage } from "react-intl"
// import { getGeneralTextTranslate } from "../../../_metronic/i18n/TextTranslate"

function TabContainer(props){
  return (
    <Typography component="div" style={{ padding: 8 * 1}}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: '#1890ff'
  }
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTansform: 'none',
    minWidth: 72,
    fontWeeight: theme.typography.fontWeighRegular,
    marginTight: theme.spacing(4),
    fontFamily : [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  Tabs: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function () {
  const classes = useStyles();
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <div className={classes.root}>
      <div className={classes.Tabs}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Customer success items" />
          <AntTab label="Assign students" />
          <AntTab label="Assign groups"/>
        </AntTabs>
        {value === 0 && <TabContainer><CustomerSuccessItems /></TabContainer>}
        {value === 1 && <TabContainer><AssignStudents/></TabContainer>}
        {value === 2 && <TabContainer><AssignGroups/></TabContainer>}
        <Typography className={classes.padding} />
      </div>
    </div>
  )
}