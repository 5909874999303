/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useIntl } from "react-intl";

export function DropdownMenu1() {
    const int1 = useIntl();
    return (<>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
            <li className="navi-header font-weight-bold py-5">
                <span className="font-size-lg">{int1.formatMessage({id: "DROPDOWNS.MENU1.CHOOSE"})}</span>
                <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right"
                   title="Click to learn more..."></i>
            </li>
            <li className="navi-separator mb-3 opacity-70"></li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-success">{int1.formatMessage({id: "DROPDOWNS.USERS"})}</span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-danger">{int1.formatMessage({id: "DROPDOWNS.PARTNER"})}</span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-warning">{int1.formatMessage({id: "DROPDOWNS.SUPLIER"})}</span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-primary">{int1.formatMessage({id: "DROPDOWNS.MEMBER"})}</span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-dark">{int1.formatMessage({id: "DROPDOWNS.STAFF"})}</span>
            </span>
                </a>
            </li>
            <li className="navi-separator mt-3 opacity-70"></li>
            <li className="navi-footer pt-5 pb-4">
                <a className="btn btn-clean font-weight-bold btn-sm" href="#">
                    <i className="ki ki-plus icon-sm"></i>
                    {int1.formatMessage({id: "DROPDOWNS.MENU1.ADD"})}
                </a>
            </li>
        </ul>
        {/*end::Navigation*/}
    </>);
}
