import React, {Component} from 'react'
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import classNames from 'classnames';
import { TabView, TabPanel } from 'primereact/tabview';
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

import 'primeflex/primeflex.css';

import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const url = ServerURL()

class AgencyProfitTable extends Component {

    emptyGrossInvoice() {

    }

    emptyNetInvoice() {

    }

    emptyGrossItems() {

    }

    emptyNetItems() {

    }

    constructor(props) {
        super(props)

        this.state = {
            grossInvoice: this.emptyGrossInvoice,
            netInvoice: this.emptyNetInvoice,
            grossItem: this.emptyGrossItems,
            netItem: this.emptyNetItems,

            grossInvoices: null,
            netInvoices: null,
            allInvoices: null,
            grossItems: null,
            netItems: null,

            globalFilter: null,
            selectedInvoices: null,
            submitted: false,

            campusText: getTextCampusTranslate(),
            paginatorText:  getTextPaginatorTranslate(),
            textTranslate: getGeneralTextTranslate(),            
        }

        // this.actionBodyTemplate = this.actionBodyTemplate.bind(this)
    }

    componentDidMount() {
        this.getInitialData()
    }

    getInitialData = async () => {
        try {
            axios.get(`${url}/api/auth/get_profit`)
            .then(res => {
                const allInvoices = res.data

                console.log(res.data);

                this.setState({ allInvoices })
            })
        } catch (error) {
            
        }
    }

    render() {
        return (
            <>
                <div>
                    <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                        <h3 className='card-label'>
                            Agency profit
                            <span className='d-block text-muted pt-2 font-size-sm'>
                                Agency profit reports
                            </span>
                        </h3>

                        <DataTable value={this.state.allInvoices}>
                            <Column field='externalId' header="Invoice Id"></Column>
                            <Column field='invoice_name' header="Invoice name"></Column>
                            <Column field='gross_total' header="Total gross"></Column>
                            <Column field='net_total' header="Total net"></Column>
                            <Column field='total_commission' header="Total agent commission"></Column>
                            <Column field='total_profit' header="Total profit"></Column>
                            <Column field='total_profitPercentage' header="Total profit percentage"></Column>
                            {/* <Column field='agency_profit' header="Net"></Column> */}
                            {/* <Column header='Agent commission'></Column> */}
                            {/* <Column field='total_profit' header="Total profit"></Column> */}
                        </DataTable>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps, auth.actions)(AgencyProfitTable)