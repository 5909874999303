import React, { Component } from 'react'
import axios from 'axios'
import ServerURL from '../../../ServerURL'
/*import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';*/


import classNames from 'classnames';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
/*import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';*/

import { MultiSelect } from 'primereact';
import { ToggleButton } from 'primereact';
import { Tooltip } from 'primereact';


import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputTextarea } from 'primereact/inputtextarea';

const url = ServerURL();

class CommissionsTable extends Component {

  emptyCommission = {
    commissionTotal: '',
    commissionBalance: '',
    fk_id_commissionType: '',
    amount: '',
    status: '',
    dimiss: '',
  }

  emptyAuxCommission = {
    fk_id_balanceItem: '',
    status: '',
    dimiss: ''
  }

  emptyPaymentCommission = {
    fk_id_account: '',
    fk_id_paymentCommissionMethod: '',
    paymentDate: '',
    fk_id_commissionType: '',
    fk_id_exchangeRateType: '',
    paymentAmount: '',
    rate: '',
    note: '',
    paymentEquivalentAmount: '',
    status: '',
  }

  emptyTransfer = {
    invoiceId: null,
    fk_id_balanceItem: null,
    fk_id_currency: null,
    fk_id_commissionType: null,
    transferTotal: null,
    commission: null,
    commissionPercentage: null,
    transferBalance: null,
    status: null,
    enable: null,
  }

  constructor(props) {
    super(props);

    this.columns = [
      { field: 'externalId', header: "Invoice Id" },
      // {field: 'created_at', header: 'Created at'},
      { field: 'invoiceName', header: 'Invoice name' },
      { field: 'office', header: 'Office' },
      { field: 'agent', header: 'Agent' },
      { field: 'provider', header: 'Provider' },
      // {field: 'student', header: 'Student'},
      // {field: 'symbolAmount', header: 'Total'},
      // {field: 'statusBalance', header: 'Validate' },
      // {field: <Column body={this.actionBodyTemplate}></Column>}
      // {field: 'agent', header: 'Agent'},
    ]

    this.columnsCommission = [
      { field: 'externalId', header: "Invoice Id" },
      // {field: 'created_at', header: 'Created at'},
      { field: 'item_name', header: 'Invoice name' },
      { field: 'agency', header: 'Office' },
      { field: 'agent', header: 'Agent' },
      { field: 'provider', header: 'Provider' }
      // {field: 'student', header: 'Student'},
      // {field: 'symbolAmount', header: 'Total'},
      // {field: 'statusBalance', header: 'Validate' },
      // {field: <Column body={this.actionBodyTemplate}></Column>}
      // {field: 'agent', header: 'Agent'},
    ]

    this.state = {
      name: '',
      description: '',

      /*
      * Aux messages
      */
      transferTotalMessage: '',
      fk_id_commissionTypeMessage: '',
      fk_id_accountMessage: '',
      fk_id_paymentCommissionMethodMessage: '',
      fk_id_exchangeRateTypeMessage: '',
      paymentDateMessage: '',
      paymentAmountMessage: '',
      amountMessage: '',
      rateMessage: '',
      paymentEquivalentAmountMessage: '',

      statusCommissions: 0,
      dismissCommissions: 0,
      agencyCommissionDialog: false,
      agentCommissionDialog: false,
      paymentCommissionDialog: false,
      scheduleCommissionDialog: false,
      selectedCommissionType: null,
      selectedExchangeRateType: null,
      activeIndex: 0,

      schedulePaymentsDialog: false,
      schedulePaymentValidationDialog: false,
      paymentsDialog: false,
      paymentsValidationDialog: false,
      messageSchedulePayments: '',

      paymentCommission: this.emptyPaymentCommission,
      commission: this.emptyCommission,
      commissions: null,
      payments: null,
      transfer: this.emptyTransfer,
      transfers: null,
      selectedCommissions: null,
      selectedPayments: null,
      ids: null,
      globalFilter: null,
      //selectedRoleTypes: null,
      commissionDialog: false,
      dismissCommissionDialog: false,
      dismissCommissionsDialog: false,
      enableCommissionDialog: false,
      enableCommissionsDialog: false,
      deletePaymentDialog: false,
      deletePaymentsDialog: false,

      submitted: false,
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate(),

      selectedColumns: this.columns,
      selectedColumnsCommission: this.columnsCommission,

      loading: false,
    }

    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.paymentsActionBodyTemplate = this.paymentsActionBodyTemplate.bind(this);

    //this.saveCommission = this.saveCommission.bind(this);
    this.dismissCommission = this.dismissCommission.bind(this);
    this.enableCommission = this.enableCommission.bind(this);
    this.paymentCommission = this.paymentCommission.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.editCommission = this.editCommission.bind(this);
    this.confirmDismissCommission = this.confirmDismissCommission.bind(this);
    this.dismissSelectedCommissions = this.dismissSelectedCommissions.bind(this);

    this.confirmDismissSelected = this.confirmDismissSelected.bind(this);
    this.confirmEnableCommission = this.confirmEnableCommission.bind(this);
    this.confirmEnableSelected = this.confirmEnableSelected.bind(this);
    this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
    this.enableSelectedCommissions = this.enableSelectedCommissions.bind(this);
    this.deletePayment = this.deletePayment.bind(this);

    this.hideDismissCommissionDialog = this.hideDismissCommissionDialog.bind(this);
    this.hideDismissCommissionsDialog = this.hideDismissCommissionsDialog.bind(this);

    this.hideEnableCommissionDialog = this.hideEnableCommissionDialog.bind(this);
    this.hideEnableCommissionsDialog = this.hideEnableCommissionsDialog.bind(this);

    this.hideShowCommissionPaymentsDialog = this.hideShowCommissionPaymentsDialog.bind(this);
    this.hideScheduleCommissionDialog = this.hideScheduleCommissionDialog.bind(this);
    this.hideSchedulePaymentsDialog = this.hideSchedulePaymentsDialog.bind(this);

    this.toolbarButtonED = this.toolbarButtonED.bind(this);
    this.toolbarButtonSchedule = this.toolbarButtonSchedule.bind(this);
    this.toolbarButtonPayments = this.toolbarButtonPayments.bind(this);

    this.columnAgencyCommission = this.columnAgencyCommission.bind(this);
    this.columnAgentCommission = this.columnAgentCommission.bind(this);

    this.hideAgencyCommissionDialog = this.hideAgencyCommissionDialog.bind(this);
    this.hideAgentCommissionDialog = this.hideAgentCommissionDialog.bind(this);
    this.saveAgencyCommission = this.saveAgencyCommission.bind(this);
    this.saveAgentCommission = this.saveAgentCommission.bind(this);

    this.hidePaymentCommissionDialog = this.hidePaymentCommissionDialog.bind(this);
    this.hidePaymentsDialog = this.hidePaymentsDialog.bind(this);
    this.hideSchedulePaymentValidationDialog = this.hideSchedulePaymentValidationDialog.bind(this);
    this.hidePaymentsValidationDialog = this.hidePaymentsValidationDialog.bind(this);
    this.hideDeletePaymentDialog = this.hideDeletePaymentDialog.bind(this);
    this.hideDeletePaymentsDialog = this.hideDeletePaymentsDialog.bind(this);
    this.savePaymentCommission = this.savePaymentCommission.bind(this);

    this.onCommissionTypeChange = this.onCommissionTypeChange.bind(this)
    this.getExchangeRates = this.getExchangeRates.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onDatePayementsChange = this.onDatePayementsChange.bind(this);
    this.onRateChange = this.onRateChange.bind(this);
    this.onMethodChange = this.onMethodChange.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this)
    this.onPaymentAmountChange = this.onPaymentAmountChange.bind(this);
    this.onIndexChange = this.onIndexChange.bind(this);
    this.onExchangeRateTypeChange = this.onExchangeRateTypeChange.bind(this);
    this.openSchedule = this.openSchedule.bind(this);
    this.onScheduleAmountChange = this.onScheduleAmountChange.bind(this);
    this.onScheduleDateChange = this.onScheduleDateChange.bind(this);
    this.createScheduleCommission = this.createScheduleCommission.bind(this);
    this.confirmDeletePayment = this.confirmDeletePayment.bind(this);
    this.deleteSelectedPayments = this.deleteSelectedPayments.bind(this);

    this.schedulePaymentsSelected = this.schedulePaymentsSelected.bind(this);
    this.confirmSchedulePaymentsSelected = this.confirmSchedulePaymentsSelected.bind(this);
    this.confirmSchedulePaymentValidation = this.confirmSchedulePaymentValidation.bind(this);
    this.confirmPaymentsSelected = this.confirmPaymentsSelected.bind(this);
    this.confirmPaymentsValidation = this.confirmPaymentsValidation.bind(this);
    this.onSortPaymentDate = this.onSortPaymentDate.bind(this);
    this.onSortScheduleDate = this.onSortScheduleDate.bind(this);

    this.onColumnToggle = this.onColumnToggle.bind(this)
    this.onColumnCommissionToggle = this.onColumnCommissionToggle.bind(this)


    /*this.agencyCommission = this.agencyCommission.bind(this);
    this.commissionTotal = this.commissionTotal.bind(this);
    this.commissionBalance = this.commissionBalance.bind(this);*/

  }



  componentDidMount() {
    this.getCommissions()
  }

  /*  isValidCommissionTotal(){
      const { commissionTotal } = this.state.commission
            const commissionTotalMessage = this.validateCommissionTotal()
        
            if(commissionTotal){
              if(!commissionTotalMessage){
                return true;
              }else{
                return false;
              } 
            }
            return false;
    }*/

  isValidTransferTotal() {
    const { transferTotal } = this.state.transfer
    const transferTotalMessage = this.validateTransferTotal()

    if (transferTotal) {
      if (!transferTotalMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  validateTransferTotal() {
    const transfer = this.state.transfer
    const balance = this.state.balances

    let transferTotalMessage;
    if (!transfer.transferTotal) {
      transferTotalMessage = 'Requerido'
    } else if (transfer.transferTotal > balance.total) {
      transferTotalMessage = `Transferencia total no puede ser mayor a ${balance.symbolTotalCurrency}`
    } else if (transfer.transferTotal < 0) {
      transferTotalMessage = 'Transferencia total no puede ser menor a 0'
    } else {
      transferTotalMessage = ''
    }
    this.setState({ transferTotalMessage })
    return transferTotalMessage
  }


  getTextAuxTransferTotal() {
    const message = this.state.transferTotalMessage
    return message
  }



  isValidSchedulePayment() {
    const { scheduleDate, paymentEquivalentAmount } = this.state.paymentCommission
    const scheduleDateMessage = this.validateScheduleDate()
    const paymentEquivalentAmountMessage = this.validatePaymentEquivalentAmount()

    if (scheduleDate && paymentEquivalentAmount) {
      if (!scheduleDateMessage && !paymentEquivalentAmountMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }


  validatePaymentEquivalentAmount() {
    const commission = { ...this.state.commission }
    const paymentCommission = { ...this.state.paymentCommission }

    console.log("commission", commission)
    console.log("paymentCommission", paymentCommission)

    let paymentEquivalentAmountMessage;

    if (!paymentCommission.paymentEquivalentAmount) {
      paymentEquivalentAmountMessage = 'Requerido'
    } else if (paymentCommission.paymentEquivalentAmount > commission.commissionBalance) {
      paymentEquivalentAmountMessage = `El monto programado no puede ser mayor a ${commission.commissionBalance}`
    } else if (paymentCommission.paymentEquivalentAmount < 0) {
      paymentEquivalentAmountMessage = 'El monto programado no puede ser menor a 0'
    } else {
      paymentEquivalentAmountMessage = ''
    }
    this.setState({ paymentEquivalentAmountMessage })

    return paymentEquivalentAmountMessage
  }

  getTextAuxPaymentEquivalentAmount() {
    const message = this.state.paymentEquivalentAmountMessage
    return message
  }


  validateScheduleDate() {
    const paymentCommission = this.state.paymentCommission

    let scheduleDateMessage;

    if (!paymentCommission.scheduleDate) {
      scheduleDateMessage = 'Requerido'
    } else {
      scheduleDateMessage = ''
    }
    this.setState({ scheduleDateMessage })

    return scheduleDateMessage
  }

  getTextAuxScheduleDate() {
    const message = this.state.scheduleDateMessage
    return message
  }


  isValidSchedulePayments() {
    const { scheduleDate } = this.state.paymentCommission
    const scheduleDateMessage = this.validateScheduleDate()

    if (scheduleDate) {
      if (!scheduleDateMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }







  isValidAmount() {
    const { amount, fk_id_commissionType } = this.state.commission
    const amountMessage = this.validateAmount()
    const fk_id_commissionTypeMessage = this.validateFk_id_commissionType()

    if (amount && fk_id_commissionType) {
      if (!amountMessage && !fk_id_commissionTypeMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  validateFk_id_commissionType() {
    const commission = this.state.commission

    let fk_id_commissionTypeMessage
    if (!commission.fk_id_commissionType) {
      fk_id_commissionTypeMessage = 'Requerido'
    } else {
      fk_id_commissionTypeMessage = ''
    }
    this.setState({ fk_id_commissionTypeMessage })
    return fk_id_commissionTypeMessage
  }

  getTextAuxFk_id_commissionType() {
    const message = this.state.fk_id_commissionTypeMessage
    return message
  }

  validateAmount() {
    const commission = this.state.commission
    const transfer = { ...this.state.transfer }

    let amountMessage;

    if (commission.fk_id_commissionType === 1 || commission.fk_id_commissionType === 2) {
      console.log("ENTRO A COMMISSION TYPE 1", commission, transfer)

      if (!commission.amount) {
        amountMessage = 'Requerido'
      } else if (commission.commissionTotal > transfer.commission) {
        amountMessage = `La comisón del agente no puede ser mayor a ${transfer.commission} ${commission.code}`
      } else if (commission.commissionTotal < 0) {
        amountMessage = 'La comisión del agente no puede ser menor a 0'
      } else {
        amountMessage = ''
      }

    } else if (commission.fk_id_commissionType === 3) {
      console.log("ENTRO A COMMISSION TYPE 3")

      if (!commission.amount) {
        amountMessage = 'Requerido'
      } else if (commission.commissionTotal > commission.total) {
        amountMessage = `La comisón del agente no puede ser mayor a ${commission.total} ${commission.code}`
      } else if (commission.commissionTotal < 0) {
        amountMessage = 'La comisón del agente no puede ser menor a 0'
      } else {
        amountMessage = ''
      }
    }

    this.setState({ amountMessage })
    return amountMessage
  }


  getTextAuxAmount() {
    const message = this.state.amountMessage
    return message
  }





  isValid() {
    const { fk_id_account, fk_id_paymentCommissionMethod, fk_id_exchangeRateType, paymentDate, paymentAmount, rate } = this.state.paymentCommission
    const fk_id_accountMessage = this.validateFk_id_account()
    const fk_id_paymentCommissionMethodMessage = this.validateFk_id_paymentCommissionMethod()
    const fk_id_exchangeRateTypeMessage = this.validateFk_id_exchangeRateType()
    const paymentDateMessage = this.validatePaymentDate()
    const paymentAmountMessage = this.validatePaymentAmount()
    const rateMessage = this.validateRate()

    if (fk_id_account && fk_id_paymentCommissionMethod && fk_id_exchangeRateType && paymentDate && paymentAmount && rate) {
      if (!fk_id_accountMessage && !fk_id_paymentCommissionMethodMessage && !fk_id_exchangeRateTypeMessage && !paymentDateMessage && !paymentAmountMessage && !rateMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  validateFk_id_account() {
    const paymentCommission = this.state.paymentCommission

    let fk_id_accountMessage
    if (!paymentCommission.fk_id_account) {
      fk_id_accountMessage = 'Requerido'
    } else {
      fk_id_accountMessage = ''
    }
    this.setState({ fk_id_accountMessage })
    return fk_id_accountMessage
  }

  getTextAuxFk_id_account() {
    const message = this.state.fk_id_accountMessage
    return message
  }

  validateFk_id_paymentCommissionMethod() {
    const paymentCommission = this.state.paymentCommission

    let fk_id_paymentCommissionMethodMessage
    if (!paymentCommission.fk_id_paymentCommissionMethod) {
      fk_id_paymentCommissionMethodMessage = 'Requerido'
    } else {
      fk_id_paymentCommissionMethodMessage = ''
    }
    this.setState({ fk_id_paymentCommissionMethodMessage })
    return fk_id_paymentCommissionMethodMessage
  }

  getTextAuxFk_id_paymentCommissionMethod() {
    const message = this.state.fk_id_paymentCommissionMethodMessage
    return message
  }

  validateFk_id_exchangeRateType() {
    const paymentCommission = this.state.paymentCommission

    let fk_id_exchangeRateTypeMessage
    if (!paymentCommission.fk_id_exchangeRateType) {
      fk_id_exchangeRateTypeMessage = 'Requerido'
    } else {
      fk_id_exchangeRateTypeMessage = ''
    }
    this.setState({ fk_id_exchangeRateTypeMessage })
    return fk_id_exchangeRateTypeMessage
  }


  getTextAuxFk_id_exchangeRateType() {
    const message = this.state.fk_id_exchangeRateTypeMessage
    return message
  }

  validatePaymentDate() {
    const paymentCommission = this.state.paymentCommission

    let paymentDateMessage
    if (!paymentCommission.paymentDate) {
      paymentDateMessage = 'Requerido'
    } else {
      paymentDateMessage = ''
    }
    this.setState({ paymentDateMessage })
    return paymentDateMessage
  }

  getTextAuxPaymentDate() {
    const message = this.state.paymentDateMessage
    return message
  }


  validatePaymentAmount() {
    const paymentCommission = this.state.paymentCommission
    const commission = { ...this.state.commission }

    let paymentAmountMessage
    if (!paymentCommission.paymentAmount) {
      paymentAmountMessage = 'Requerido'
    } else if (commission.commissionBalance < (paymentCommission.paymentAmount / paymentCommission.rate)) {
      paymentAmountMessage = `El pago no puede ser mayor a ${commission.symbolCommissionAgentBalanceCurrency}`
    }
    this.setState({ paymentAmountMessage })
    return paymentAmountMessage
  }

  getTextAuxPaymentAmount() {
    const message = this.state.paymentAmountMessage
    return message
  }

  validateRate() {
    const paymentCommission = this.state.paymentCommission

    let rateMessage;
    if (!paymentCommission.rate) {
      rateMessage = 'Requerido'
    } else {
      rateMessage = ''
    }
    this.setState({ rateMessage })
    return rateMessage
  }

  getTextAuxRate() {
    const message = this.state.rateMessage
    return message
  }



  getCommission = async (paymentCommission) => {
    console.log("SÍ ENTRO A GET COMMISSION")
    try {
      this.setState({ loading: true })
      let res = axios.get(`${url}/api/auth/commission/${paymentCommission.fk_id_commission}`)
        .then(res => {
          const data = res.data;
          this.symbolCommissionCurrency(data)
          this.symbolSaleCurrency(data)
          this.symbolCommissionTotalCurrency(data)
          this.symbolCommissionAgentBalanceCurrency(data)
          this.showProvider(data)
          this.showItemName(data)
          let commission = data[0]
          console.log("COMISSION EN SOLO:", commission)
          this.getBalance(commission)
          this.setState({ commission, loading: false })
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  getSchedulesPayments = async () => {
    try {
      this.setState({ loading: true })
      let res = axios.get(`${url}/api/auth/schedulePaymentCommissions`)
        .then(res => {
          const payments = res.data;
          console.log("SCHEDULE PAYMENTS:", payments)
          this.DateSchedule(payments)
          this.symbolEquivalentPaymentsCurrency(payments)
          this.showInvoiceName(payments)
          this.showProvider(payments)
          this.setState({ payments, loading: false })
        });
      console.log(res)
    } catch (error) {
    }
  }


  getPayments = async () => {
    this.setState({ loading: true })
    try {
      let res = axios.get(`${url}/api/auth/paymentCommissions`)
        .then(res => {
          const payments = res.data;
          console.log("PAYMENTS:", payments)
          this.DatePayment(payments)
          this.symbolPaymentsCurrency(payments)
          this.symbolRatesCurrency(payments)
          this.symbolEquivalentPaymentsCurrency(payments)
          this.showInvoiceName(payments)
          this.showProvider(payments)

          this.setState({ payments, loading: false })
        });
      console.log(res)
    } catch (error) {
    }
  }


  symbolPaymentsCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolPaymentsCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].paymentAmount) + ' ' + e[i].code;
    }
    return e
  }

  symbolRatesCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolRatesCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].rate) + ' ' + e[i].code;
    }
    return e
  }

  symbolEquivalentPaymentsCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolEquivalentPaymentsCurrency = e[i].originalSymbol + ' ' + dollarUSLocale.format(e[i].paymentEquivalentAmount) + ' ' + e[i].originalCode;
    }
    return e
  }


  getCommissions = async () => {
    try {
      this.setState({ loading:  true })
      let res = axios.get(`${url}/api/auth/commissionsPending`)
        .then(res => {
          const commissions = res.data;
          this.symbolCommissionCurrency(commissions)
          this.symbolSaleCurrency(commissions)
          this.symbolCommissionTotalCurrency(commissions)
          this.symbolCommissionAgentBalanceCurrency(commissions)
          this.showItemName(commissions)
          this.showProvider(commissions)
          console.log("COMMISSIONS EN PENDDING", commissions)
          this.setState({ commissions, dismissCommission: 0,loading: false })
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  getCommissionsPaid = async () => {
    try {
      this.setState({ loading: true })
      let res = axios.get(`${url}/api/auth/commissionsPaid`)
        .then(res => {
          const commissions = res.data;
          this.symbolCommissionCurrency(commissions)
          this.symbolSaleCurrency(commissions)
          this.symbolCommissionTotalCurrency(commissions)
          this.symbolCommissionAgentBalanceCurrency(commissions)
          this.showItemName(commissions)
          this.showProvider(commissions)
          this.symbolUtilityCurrency(commissions)
          this.setState({ commissions, dismissCommission: 0, loading: false })
        });
      console.log(res.data)
    } catch (error) {
    }
  }


  getCommissionsDismissed = async () => {
    try {
      this.setState({ loading: true })

      let res = axios.get(`${url}/api/auth/commissionsDismissed`)
        .then(res => {
          const commissions = res.data;
          this.symbolCommissionCurrency(commissions)
          this.symbolSaleCurrency(commissions)
          this.symbolCommissionTotalCurrency(commissions)
          this.symbolCommissionAgentBalanceCurrency(commissions)
          this.showItemName(commissions)
          this.showProvider(commissions)
          this.setState({ commissions, dismissCommission: 1, loading: false })
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  symbolCommissionCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      e[i].symbolCommissionCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].agencyCommission) + ' ' + e[i].code;
    }
    return e
  }

  symbolCommissionTotalCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      if (e[i].commissionTotal) {
        e[i].symbolCommissionTotalCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].commissionTotal) + ' ' + e[i].code;
      } else {
        e[i].symbolCommissionTotalCurrency = e[i].symbol + ' ' + 0 + ' ' + e[i].code;
      }
    }
    return e
  }

  symbolCommissionAgentBalanceCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      if (e[i].commissionBalance) {
        e[i].symbolCommissionAgentBalanceCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].commissionBalance) + ' ' + e[i].code;
      } else {
        e[i].symbolCommissionAgentBalanceCurrency = e[i].symbol + ' ' + 0 + ' ' + e[i].code;
      }
    }
    return e
  }

  symbolUtilityCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      e[i].symbolUtilityCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].utility) + ' ' + e[i].code;
    }
    return e
  }


  symbolSaleCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolSaleCurrency = e[i].symbolSale + ' ' + dollarUSLocale.format(e[i].total) + ' ' + e[i].codeSale;
    }
    return e
  }

  getCommissionTypes = async () => {

    try {

      let res = axios.get(`${url}/api/auth/commissionTypes`)
        .then(res => {
          const commissionTypes = res.data;
          this.setState({ commissionTypes })
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  getExchangeRateTypes = async () => {

    try {

      let res = axios.get(`${url}/api/auth/exchangeRateTypes`)
        .then(res => {
          const exchangeRateTypes = res.data;
          console.log("EXCHANGE RATE TYPES:", exchangeRateTypes)
          this.setState({ exchangeRateTypes })
        });
      console.log(res.data)
    } catch (error) {
    }
  }


  getCommissionPayment = async (commission) => {
    this.setState({ loading: true })
    try {

      let res = axios.get(`${url}/api/auth/commissionPayments/${commission.id}`)
        .then(res => {
          const commissionPayments = res.data;
          this.symbolCommissionPaymentCurrency(commissionPayments)
          this.ExchangePayment(commissionPayments)

          this.setState({ commissionPayments, loading: false })
        });
      console.log(res.data)
    } catch (error) {
    }
  }


  symbolCommissionPaymentCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolCommissionPaymentCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].paymentAmount) + ' ' + e[i].code;
    }
    return e
  }

  ExchangePayment(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      let payment
      payment = e[i].paymentAmount / e[i].rate;
      e[i].exchangePayment = payment
      e[i].exchangePayment = dollarUSLocale.format(e[i].exchangePayment) + ' ' + e[i].code
    }
    return e
  }


  getBalance = async (commission) => {

    try {

      let res = axios.get(`${url}/api/auth/balancebyItemId/${commission.fk_id_balanceItem}`)
        .then(res => {
          const balances = res.data[0];
          let dollarUSLocale = Intl.NumberFormat('en-US');

          balances.symbolTotalCurrency = balances.symbol + ' ' + dollarUSLocale.format(balances.total) + ' ' + balances.code;
          balances.symbolBalanceCurrency = balances.symbol + ' ' + dollarUSLocale.format(balances.balance) + ' ' + balances.code;
          console.log("BALANCE:", balances)

          this.setState({ balances })
        });
      console.log(res.data)
    } catch (error) {
    }
  }


  getTransfer = async (commission) => {

    try {

      let res = axios.get(`${url}/api/auth/transferbyBalanceItem/${commission.fk_id_balanceItem}`)
        .then(res => {
          const transfer = res.data[0];
          console.log("TRANSFER:", transfer)
          this.setState({ transfer })
        });
      console.log(res.data)
    } catch (error) {
    }
  }



  dismissSelectedCommissions() {
    let commissions = this.state.commissions.filter(val => !this.state.selectedCommissions.includes(val));
    let data = this.state.commissions.filter(val => this.state.selectedCommissions.includes(val));

    try {
      let res = axios.put(`${url}/api/auth/commissionsDismissAll`, { data })
        .then(res => {
          this.getCommissions()
        })
      if (!res.error) {
        this.setState({
          commissions,
          dismissCommissionsDialog: false,
          selectedCommissions: null,
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Commissions Dismissed', life: 3000 });
      }
    } catch (error) {
    }
  }

  enableSelectedCommissions() {
    let commissions = this.state.commissions.filter(val => !this.state.selectedCommissions.includes(val));
    let data = this.state.commissions.filter(val => this.state.selectedCommissions.includes(val));

    try {
      let res = axios.put(`${url}/api/auth/commissionsEnableAll`, { data })
        .then(res => {
          this.getCommissionsDismissed()
        })
      if (!res.error) {
        this.setState({
          commissions,
          enableCommissionsDialog: false,
          selectedCommissions: null,
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Commissions Enabled', life: 3000 });
      }
    } catch (error) {
    }
  }


  dismissCommission() {
    let data = { ...this.state.commission };

    try {
      let res = axios.put(`${url}/api/auth/commissionDismiss/${data.id}`)
        .then(res => {
          this.getCommissions()
        })
      if (!res.error) {
        this.setState({
          data,
          dismissCommissionDialog: false,
          commission: this.emptyCommission,

        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Commisssion Dismissed', life: 3000 });
      }
    } catch (error) {
    }
  }

  enableCommission() {
    let data = { ...this.state.commission };

    try {
      let res = axios.put(`${url}/api/auth/commissionEnable/${data.id}`)
        .then(res => {
          this.getCommissionsDismissed()
        })
      if (!res.error) {
        this.setState({
          data,
          enableCommissionDialog: false,
          commission: this.emptyCommission,

        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Commisssion Enabled', life: 3000 });
      }
    } catch (error) {
    }
  }


  scheduleCommissionValidation = () => {
    let aux = 0;
    let data = this.state.commissions.filter(val => this.state.selectedCommissions.includes(val));

    for (let i = 0; i < Object.keys(data).length; i++) {
      for (let j = 0; j < Object.keys(data).length; j++) {
        if (data[i].fk_id_currency !== data[j].fk_id_currency) {
          aux++
        }
      }
    }
    if (aux === 0) {
      this.confirmSchedulePaymentsSelected()
    } else {
      this.confirmSchedulePaymentValidation()
    }
  }

  paymentsCommissionValidation = () => {
    let aux = 0;
    let data = this.state.commissions.filter(val => this.state.selectedCommissions.includes(val));

    for (let i = 0; i < Object.keys(data).length; i++) {
      for (let j = 0; j < Object.keys(data).length; j++) {
        if (data[i].fk_id_currency !== data[j].fk_id_currency) {
          aux++
        }
      }
    }
    if (aux === 0) {
      this.confirmPaymentsSelected()
    } else {
      this.confirmPaymentsValidation()
    }
  }

  schedulePaymentsSelected() {
    let state = { submitted: true };
    const decision = this.isValidSchedulePayments();
    if (decision) {


      let paymentCommission = { ...this.state.paymentCommission }
      //let commissions = this.state.commissions.filter(val => !this.state.selectedCommissions.includes(val));
      let data = this.state.commissions.filter(val => this.state.selectedCommissions.includes(val));


      for (let i = 0; i < Object.keys(data).length; i++) {
        data[i].scheduleDate = paymentCommission.scheduleDate
      }

      try {
        axios.post(`${url}/api/auth/scheduleCommissions`, { data })
          .then(res => {
            this.getCommissions()
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Scheduled payments created', life: 3000 });
          })
      } catch (error) {
      }
      state = {
        ...state,
        //commissions,
        schedulePaymentsDialog: false,
        selectedCommissions: null,
      };
    }
    this.setState(state)
  }


  async deletePayment() {
    let data = { ...this.state.commissionPayment };

    try {
      await axios.delete(`${url}/api/auth/paymentCommission/${data.id}`)
        .then(res => {
          console.log(res)
          this.setState({
            data,
            deletePaymentDialog: false,
            commissionPayment: this.emptyCommissionPayment
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Commission Payment Deleted', life: 3000 });
        })

    } catch (error) {
    }
    this.getSchedulesPayments()
  }


  deleteSelectedPayments() {
    let payments = this.state.payments.filter(val => !this.state.selectedPayments.includes(val));
    let data = this.state.payments.filter(val => this.state.selectedPayments.includes(val));

    try {
      axios.delete(`${url}/api/auth/paymentCommissions`, { data })
        .then(res => {
          this.setState({
            payments,
            deletePaymentsDialog: false,
            selectedPayments: null
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payments Deleted', life: 3000 });

        })

    } catch (error) {
    }
    this.getSchedulesPayments()
  }

  getTransfers = async () => {
    try {
      let res = axios.get(`${url}/api/auth/transfersPending`)
        .then(res => {
          const transfers = res.data;

          this.setState({ transfers })
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  saveAgencyCommission() {
    let state = { submitted: true };
    const decision = this.isValidTransferTotal();

    if (decision) {


      let data = { ...this.state.transfer };
      try {
        let res = axios.put(`${url}/api/auth/invoiceTransfer/${data.id}`, data)
          .then(res => {
            this.getCommissions()
          })
        if (!res.error) {
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agency Commission Updated', life: 3000 });
        }
      } catch (error) {
      }
      state = {
        ...state,
        data,
        agencyCommissionDialog: false,
        transfer: this.emptyTransfer,
      };
    }
    this.setState(state);
  }

  saveAgentCommission() {
    let state = { submitted: true };
    const decision = this.isValidAmount();

    if (decision) {
      //if (this.state.role_type.role_type.trim()) {

      let data = { ...this.state.commission };

      console.log("DATA COMMISSION:", data)
      try {
        let res = axios.put(`${url}/api/auth/updateAgentCommission/${data.id}`, data)
          .then(res => {
            this.getCommissions()
          })
        if (!res.error) {
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agent Commission Updated', life: 3000 });
          this.getCommissions()
        }
      } catch (error) {
      }
      state = {
        ...state,
        data,
        agentCommissionDialog: false,
        transfer: this.emptyTransfer,
        commission: this.emptyCommission,
        selectedCommissionType: '',
      };
    }
    this.setState(state);
  }




  savePaymentCommission() {
    let state = { submitted: true };
    const decision = this.isValid();

    if (decision) {

      let data = { ...this.state.paymentCommission };

      console.log("DATA EN SAVE: ", data);

      if (data.status === 0) {
        try {
          let res = axios.put(`${url}/api/auth/paymentCommission/${data.id}`, data)
            .then(res => {
              this.getCommissions()
              this.getSchedulesPayments()

            })
          if (!res.error) {
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Commission Updated', life: 3000 });
            this.getCommissions()

          }
        } catch (error) {
        }
      } else {

        try {
          let res = axios.post(`${url}/api/auth/paymentCommission`, data)
            .then(res => {
              this.getCommissions()
              this.getPayments()
            })
          if (!res.error) {
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Commission Created', life: 3000 });
            this.getCommissions()
          }
        } catch (error) {
        }
      }
      state = {
        ...state,
        data,
        paymentCommissionDialog: false,
        commission: this.emptyCommission,
        paymentCommission: this.emptyPaymentCommission,
        balances: '',
        selectedAccount: '',
        selectedMethod: '',
        selectedCommissionType: '',
        selectedExchangeRateType: '',
      };
    }

    this.setState(state);
    this.getCommissions()
  }

  createScheduleCommission() {
    let state = { submitted: true };
    const decision = this.isValidSchedulePayment();

    if (decision) {
      //if (this.state.role_type.role_type.trim()) {


      let data = { ...this.state.paymentCommission };

      try {
        let res = axios.post(`${url}/api/auth/scheduleCommission`, data)
          .then(res => {
            this.getSchedulesPayments()
          })
        if (!res.error) {
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Shudele Commission Created', life: 3000 });
        }
      } catch (error) {
      }
      state = {
        ...state,
        data,
        scheduleCommissionDialog: false,
        paymentCommission: this.emptyPaymentCommission,
        balances: ''
      };
    }

    this.setState(state);
  }



  DateToOE(e) {

    let year = e.getFullYear();
    let month = e.getMonth() + 1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = day + '/' + month + '/' + year

    return new_date
  }

  getAccounts = async () => {

    try {

      let res = axios.get(`${url}/api/auth/accounts`)
        .then(res => {
          const accounts = res.data;
          this.setState({ accounts })
        });
      console.log(res.data)
    } catch (error) {
    }
  }


  getAccount = async (e) => {
    let accountER
    try {
      await axios.get(`${url}/api/auth/account/${e.value}`)
        .then(res => {
          accountER = res.data;
        });
    } catch (error) {
    }
    return accountER
  }

  getExchangeRates = async (paymentCommission, DateOE) => {
    let balances = { ...this.state.balances }
    let commission = { ...this.state.commission }
    let data = {
      date: null
    }
    data.date = DateOE


    try {
      await axios.post(`${url}/api/auth/getExchangeRates`, data)
        .then(res => {
          let exchanges = res.data;

          if (paymentCommission.status === 0) {

            for (let i = 0; i < Object.keys(exchanges).length; i++) {
              if (balances.id_currency === exchanges[i].fk_id_currency) {
                balances.rate = exchanges[i].rate
              }
            }
            paymentCommission.paymentAmount = paymentCommission.paymentEquivalentAmount * balances.rate
            paymentCommission.rate = balances.rate
          } else {
            for (let i = 0; i < Object.keys(exchanges).length; i++) {
              if (balances.id_currency === exchanges[i].fk_id_currency) {
                balances.rate = exchanges[i].rate
              }
            }
            paymentCommission.paymentAmount = commission.commissionBalance * balances.rate
            paymentCommission.rate = balances.rate
            paymentCommission.paymentEquivalentAmount = paymentCommission.paymentAmount / paymentCommission.rate
          }

          this.setState({ balances, paymentCommission })
        });
    } catch (error) {
    }
  }

  onCommissionTypeChange(e) {
    let commission = { ...this.state.commission };
    commission['fk_id_commissionType'] = e.value;
    this.setState({ selectedCommissionType: e.value, commission });
  }


  onInputChange(e, name) {
    const val = (e.target && e.target.value) || '';
    let transfer = { ...this.state.transfer };
    let balance = { ...this.state.balances };

    if (!val) {

      if (name === 'transferTotal') {
        transfer['commission'] = balance.total
        transfer['transferTotal'] = "0"
      }

    } else {

      if (name === 'transferTotal') {
        transfer['commission'] = balance.total - val
        transfer['transferTotal'] = balance.total - transfer['commission']
      }
    }


    this.setState({ transfer });
  }

  onRateChange(e) {
    const val = (e.target && e.target.value) || '';
    let paymentCommission = { ...this.state.paymentCommission }
    let balances = { ...this.state.balances }
    //let commission = {...this.state.commission}


    if (paymentCommission.fk_id_exchangeRateType === 1) {

      if (paymentCommission.status === 0) {
        paymentCommission['rate'] = val
        paymentCommission.paymentAmount = paymentCommission.paymentEquivalentAmount * paymentCommission.rate

      } else {
        paymentCommission['rate'] = val
        paymentCommission.paymentEquivalentAmount = paymentCommission.paymentAmount / paymentCommission.rate
        //paymentCommission.paymentEquivalentAmount = this.formatCurrency(paymentCommission.paymentEquivalentAmount)


      }

    } else if (paymentCommission.fk_id_exchangeRateType === 2) {

      balances.rate = val

      if (paymentCommission.status === 0) {
        paymentCommission['rate'] = val
        paymentCommission.paymentAmount = paymentCommission.paymentEquivalentAmount * paymentCommission.rate

      } else {
        paymentCommission['rate'] = val
        paymentCommission.paymentEquivalentAmount = paymentCommission.paymentAmount / paymentCommission.rate
        //paymentCommission.paymentEquivalentAmount = this.formatCurrency(paymentCommission.paymentEquivalentAmount)


      }

    }
    this.setState({ paymentCommission });
  }

  onAmountChange(e) {
    const val = (e.target && e.target.value) || '';
    let commission = { ...this.state.commission }


    if (commission.fk_id_commissionType === 1) {
      commission['amount'] = val
      commission['commissionTotal'] = val
      //paymentCommission['paymentEquivalent'] =  paymentCommission['paymentAmount']/paymentCommission['rate']

    } else if (commission.fk_id_commissionType === 2) {
      commission['amount'] = val
      commission['commissionTotal'] = commission.agencyCommission * (commission.amount / 100)
      //paymentCommission['paymentAmount']=paymentCommission.rate*(auxCommission.agencyCommission*(paymentCommission['amount']/100))
      //paymentCommission['paymentEquivalent'] =  paymentCommission['paymentAmount']/paymentCommission['rate']


    } else if (commission.fk_id_commissionType === 3) {
      commission['amount'] = val
      commission['commissionTotal'] = commission.total * (commission.amount / 100)
      //paymentCommission['paymentAmount']=paymentCommission.rate*(auxCommission.total*(paymentCommission['amount']/100))
      //paymentCommission['paymentEquivalent'] =  paymentCommission['paymentAmount']/paymentCommission['rate']
    }

    this.setState({ commission });
  }

  onNoteChange(e, name) {
    const val = (e.target && e.target.value) || '';
    let paymentCommission = { ...this.state.paymentCommission }

    paymentCommission[`${name}`] = val

    this.setState({ paymentCommission })
  }

  onPaymentAmountChange(e) {
    const val = (e.target && e.target.value) || '';
    let paymentCommission = { ...this.state.paymentCommission }
    let balances = { ...this.state.balances }


    if (paymentCommission.fk_id_exchangeRateType === 1) {

      if (paymentCommission.status === 0) {
        paymentCommission.paymentAmount = val
        paymentCommission.rate = paymentCommission.paymentAmount / paymentCommission.paymentEquivalentAmount

      } else {
        paymentCommission.paymentAmount = val
        paymentCommission.paymentEquivalentAmount = paymentCommission.paymentAmount / paymentCommission.rate
        //paymentCommission.paymentEquivalentAmount = this.formatCurrency(paymentCommission.paymentEquivalentAmount)

      }

    } else if (paymentCommission.fk_id_exchangeRateType === 2) {

      if (paymentCommission.status === 0) {
        paymentCommission.paymentAmount = val
        balances.rate = paymentCommission.paymentAmount / paymentCommission.paymentEquivalentAmount

      } else {
        paymentCommission.paymentAmount = val
        paymentCommission.paymentEquivalentAmount = paymentCommission.paymentAmount / paymentCommission.rate
        //paymentCommission.paymentEquivalentAmount = this.formatCurrency(paymentCommission.paymentEquivalentAmount)

      }

    }


    this.setState({ paymentCommission, balances });
  }

  async onAccountChange(e) {
    let account = await this.getAccount(e)

    let paymentCommission = { ...this.state.paymentCommission };
    paymentCommission['fk_id_account'] = e.value;
    paymentCommission['codeAccount'] = account.codeCurrency

    this.setState({ selectedAccount: e.value, paymentCommission });

  }

  onMethodChange(e) {

    let paymentCommission = { ...this.state.paymentCommission };

    paymentCommission['fk_id_paymentCommissionMethod'] = e.value;
    this.setState({ selectedMethod: e.value, paymentCommission });
  }

  onExchangeRateTypeChange(e) {

    let paymentCommission = { ...this.state.paymentCommission };

    /*paymentCommission['paymentEquivalentAmount']=''*/

    paymentCommission['paymentDate'] = ''
    paymentCommission['paymentAmount'] = ''
    paymentCommission['rate'] = ''

    paymentCommission['fk_id_exchangeRateType'] = e.value;
    this.setState({ selectedExchangeRateType: e.value, paymentCommission });
  }

  getMethods = async () => {

    try {

      let res = axios.get(`${url}/api/auth/methods`)
        .then(res => {
          const methods = res.data;
          this.setState({ methods })
        });
      console.log(res.data)

    } catch (error) {
    }
  }

  onDateChange(e, name) {

    const val = this.Date(e.target.value) || ''
    const DateOE = this.DateToOE(e.target.value) || ''
    let paymentCommission = { ...this.state.paymentCommission }
    let commission = { ...this.state.commission }

    console.log("PAYMENT COMMISSION EN DATE CHANGE:", paymentCommission)


    if (paymentCommission.fk_id_exchangeRateType === 1) {

      if (paymentCommission.status === 0) {

        console.log("PAYMENT EQUIVALENT, AVARAGERATE:", paymentCommission.paymentEquivalentAmount, commission.averageRate)

        paymentCommission.paymentAmount = paymentCommission.paymentEquivalentAmount * commission.averageRate
        paymentCommission.rate = commission.averageRate

      } else {
        paymentCommission.paymentAmount = commission.commissionBalance * commission.averageRate
        paymentCommission.rate = commission.averageRate
        paymentCommission.paymentEquivalentAmount = paymentCommission.paymentAmount / paymentCommission.rate
      }

    } else if (paymentCommission.fk_id_exchangeRateType === 2) {
      this.getExchangeRates(paymentCommission, DateOE)
    }

    paymentCommission[`${name}`] = val;

    this.setState({ paymentCommission });
  }


  onDatePayementsChange(e, name) {

    const val = this.Date(e.target.value) || ''
    const DateOE = this.DateToOE(e.target.value) || ''
    let paymentCommission = { ...this.state.paymentCommission }
    let commission = { ...this.state.commission }

    if (paymentCommission.fk_id_exchangeRateType === 1) {

      paymentCommission.paymentAmount = paymentCommission.paymentEquivalentAmount * commission.averageRate
      paymentCommission.rate = commission.averageRate


    } else if (paymentCommission.fk_id_exchangeRateType === 2) {
      this.getExchangeRates(paymentCommission, DateOE)
    }


    paymentCommission[`${name}`] = val;

    this.setState({ paymentCommission });

  }




  onScheduleAmountChange(e) {
    const val = (e.target && e.target.value) || '';
    let paymentCommission = { ...this.state.paymentCommission }

    paymentCommission['paymentEquivalentAmount'] = val

    this.setState({ paymentCommission });
  }

  onScheduleDateChange(e, name) {
    const val = this.Date(e.target.value) || ''
    let paymentCommission = { ...this.state.paymentCommission }

    paymentCommission[`${name}`] = val;

    this.setState({ paymentCommission });
  }



  Date(e) {

    let year = e.getFullYear();
    let month = e.getMonth() + 1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = day + '/' + month + '/' + year

    return new_date

  }


  DateSchedule(e) {

    e.map((payment) => {

      var origin = payment.scheduleDate.split('/')
      var originDate = origin[1] + '/' + origin[0] + '/' + origin[2];

      var date = new Date(originDate).getTime();

      payment.scheduleDate_new = date

      return payment
    });
  }

  DatePayment(e) {

    e.map((payment) => {

      var origin = payment.paymentDate.split('/')
      var originDate = origin[1] + '/' + origin[0] + '/' + origin[2];

      var date = new Date(originDate).getTime();

      payment.paymentDate_new = date

      return payment
    });
  }

  paymentCommission(commission) {
    let paymentCommission = { ...this.state.commission }
    this.getBalance(commission)


    paymentCommission['fk_id_commission'] = commission.id

    this.setState({
      commission: { ...commission },
      paymentCommission,
      submitted: false,
      paymentCommissionDialog: true,
      selectedCommissionType: null,
      selectedAccount: null,
      selectedMethod: null,
    })
    this.getCommissionTypes()
    this.getExchangeRateTypes()
    this.getAccounts()
    this.getMethods()

  }

  editPaymentCommission(paymentCommission) {
    console.log("PAYMENT COMMISSION EN EDIT:", paymentCommission)
    this.getCommission(paymentCommission)


    this.setState({
      paymentCommission: { ...paymentCommission },
      selectedCommissionType: null,
      selectedAccount: null,
      selectedMethod: null,
      submitted: false,
      paymentCommissionDialog: true,
    })
    this.getCommissionTypes()
    this.getExchangeRateTypes()
    this.getAccounts()
    this.getMethods()

  }


  hidePaymentCommissionDialog() {
    this.setState({
      submitted: false,
      paymentCommissionDialog: false,

      commission: this.emptyCommission,
      paymentCommission: this.emptyPaymentCommission,

      selectedAccount: '',
      selectedMethod: '',
      selectedCommissionType: '',
      selectedExchangeRateType: '',

      transferTotalMessage: '',
      fk_id_commissionTypeMessage: '',
      fk_id_accountMessage: '',
      fk_id_paymentCommissionMethodMessage: '',
      fk_id_exchangeRateTypeMessage: '',
      paymentDateMessage: '',
      paymentAmountMessage: '',
      amountMessage: '',
      rateMessage: '',
      paymentEquivalentAmountMessage: ''
    })
  }


  openSchedule(commission) {
    let paymentCommission = { ...this.state.paymentCommission }

    paymentCommission['fk_id_commission'] = commission.id
    paymentCommission['status'] = 0
    paymentCommission['paymentEquivalentAmount'] = commission.commissionBalance
    this.setState({
      commission: { ...commission },
      paymentCommission,
      submitted: false,
      scheduleCommissionDialog: true,
    })
  }


  hideScheduleCommissionDialog() {
    this.setState({
      submitted: false,
      scheduleCommissionDialog: false,
      transfer: this.emptyTransfer,
      paymentCommission: this.emptyPaymentCommission,
      balances: '',
      transferTotalMessage: '',
      fk_id_commissionTypeMessage: '',
      fk_id_accountMessage: '',
      fk_id_paymentCommissionMethodMessage: '',
      fk_id_exchangeRateTypeMessage: '',
      paymentDateMessage: '',
      paymentAmountMessage: '',
      amountMessage: '',
      rateMessage: '',
      paymentEquivalentAmountMessage: ''
    })
  }


  editAgencyCommission(commission) {
    console.log("COMMISSION EN EDIT AGENCY:", commission)
    this.getBalance(commission)
    this.getTransfer(commission)


    this.setState({
      commission: { ...commission },
      agencyCommissionDialog: true,
    })
  }

  editAgentCommission(commission) {
    this.getBalance(commission)
    this.getTransfer(commission)


    this.setState({
      commission: { ...commission },
      agentCommissionDialog: true,
    })
    this.getCommissionTypes()
  }


  hideAgencyCommissionDialog() {
    this.setState({
      submitted: false,
      agencyCommissionDialog: false,
      commission: this.emptyCommission,
      transferTotalMessage: '',
      fk_id_commissionTypeMessage: '',
      fk_id_accountMessage: '',
      fk_id_paymentCommissionMethodMessage: '',
      fk_id_exchangeRateTypeMessage: '',
      paymentDateMessage: '',
      paymentAmountMessage: '',
      amountMessage: '',
      rateMessage: '',
      paymentEquivalentAmountMessage: ''
    })
  }

  hideAgentCommissionDialog() {
    this.setState({
      submitted: false,
      agentCommissionDialog: false,
      commission: this.emptyCommission,
      fk_id_commissionTypeMessage: '',
      amountMessage: '',
      transferTotalMessage: '',
      fk_id_accountMessage: '',
      fk_id_paymentCommissionMethodMessage: '',
      fk_id_exchangeRateTypeMessage: '',
      paymentDateMessage: '',
      paymentAmountMessage: '',
      rateMessage: '',
      paymentEquivalentAmountMessage: ''

    })
  }


  showPayments(commission) {

    this.setState({
      showPaymentsDialog: true,
    })
    this.getCommissionPayment(commission)
  }

  editCommission(commission) {
    this.setState({
      commission: { ...commission },
      commissionDialog: true
    })
  }

  confirmDismissCommission(commission) {
    this.setState({
      commission,
      dismissCommissionDialog: true
    })
  }

  confirmDismissSelected() {
    this.setState({ dismissCommissionsDialog: true })
  }


  hideDismissCommissionDialog() {
    this.setState({ dismissCommissionDialog: false })
  }

  hideDismissCommissionsDialog() {
    this.setState({ dismissCommissionsDialog: false });
  }

  hideShowCommissionPaymentsDialog() {
    this.setState({
      showPaymentsDialog: false,
      transferTotalMessage: '',
      fk_id_commissionTypeMessage: '',
      fk_id_accountMessage: '',
      fk_id_paymentCommissionMethodMessage: '',
      fk_id_exchangeRateTypeMessage: '',
      paymentDateMessage: '',
      paymentAmountMessage: '',
      amountMessage: '',
      rateMessage: '',
      paymentEquivalentAmountMessage: ''
    })
  }

  confirmEnableCommission(commission) {
    this.setState({
      commission,
      enableCommissionDialog: true
    })
  }

  confirmDeletePayment(commissionPayment) {
    this.setState({
      commissionPayment,
      deletePaymentDialog: true
    })
  }

  confirmEnableSelected() {
    this.setState({ enableCommissionsDialog: true })
  }

  confirmSchedulePaymentsSelected() {
    this.setState({ schedulePaymentsDialog: true })
  }

  confirmSchedulePaymentValidation() {
    this.setState({ schedulePaymentValidationDialog: true })
  }

  confirmPaymentsSelected() {

    this.setState({
      paymentsDialog: true,
      submitted: false,
      selectedCommissionType: null,
      selectedAccount: null,
      selectedMethod: null,
    })
    this.getCommissionTypes()
    this.getExchangeRateTypes()
    this.getAccounts()
    this.getMethods()
  }

  confirmPaymentsValidation() {
    this.setState({ paymentsValidationDialog: true })
  }

  confirmDeleteSelected() {
    this.setState({ deletePaymentsDialog: true })
  }

  hideDeletePaymentDialog() {
    this.setState({ deletePaymentDialog: false })
  }

  hideDeletePaymentsDialog() {
    this.setState({ deletePaymentsDialog: false });
  }

  hideEnableCommissionDialog() {
    this.setState({ enableCommissionDialog: false })
  }

  hideEnableCommissionsDialog() {
    this.setState({ enableCommissionsDialog: false });
  }

  hideSchedulePaymentsDialog() {
    this.setState({
      schedulePaymentsDialog: false,
      transferTotalMessage: '',
      fk_id_commissionTypeMessage: '',
      fk_id_accountMessage: '',
      fk_id_paymentCommissionMethodMessage: '',
      fk_id_exchangeRateTypeMessage: '',
      paymentDateMessage: '',
      paymentAmountMessage: '',
      amountMessage: '',
      rateMessage: '',
      paymentEquivalentAmountMessage: ''
    });
  }

  hideSchedulePaymentValidationDialog() {
    this.setState({ schedulePaymentValidationDialog: false });
  }

  hidePaymentsDialog() {
    this.setState({
      paymentsDialog: false,
      transferTotalMessage: '',
      fk_id_commissionTypeMessage: '',
      fk_id_accountMessage: '',
      fk_id_paymentCommissionMethodMessage: '',
      fk_id_exchangeRateTypeMessage: '',
      paymentDateMessage: '',
      paymentAmountMessage: '',
      amountMessage: '',
      rateMessage: '',
      paymentEquivalentAmountMessage: ''
    });
  }

  hidePaymentsValidationDialog() {
    this.setState({ paymentsValidationDialog: false });
  }


  exportCSV() {
    this.dt.exportCSV();
  }


  toolbarButtonED = () => {
    console.log("INDEX:", this.state.activeIndex)
    let EDView = []

    if (this.state.activeIndex === 0) {
      EDView.push(
        <FormattedMessage id="GENERAL.DISMISS">
          {(message) =>
            <Button icon="pi pi-times" label={message} className="p-button-danger" onClick={this.confirmDismissSelected} disabled={!this.state.selectedCommissions || !this.state.selectedCommissions.length} />
          }</FormattedMessage>

      )
    } else if (this.state.activeIndex === 4) {
      EDView.push(
        <FormattedMessage id="GENERAL.ENABLE">
          {(message) =>
            <Button label={message} icon="pi pi-check" className="p-button-danger" onClick={this.confirmEnableSelected} disabled={!this.state.selectedCommissions || !this.state.selectedCommissions.length} />
          }</FormattedMessage>
      )
    } else if (this.state.activeIndex === 1) {
      EDView.push(
        <div style={{ marginRight: 10 }}>
          <FormattedMessage id="GENERAL.DELETE">
            {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedPayments || !this.state.selectedPayments.length} />}
          </FormattedMessage>
        </div>
      )

    }

    return EDView
  }

  toolbarButtonSchedule = () => {
    console.log("INDEX:", this.state.activeIndex)
    let ScheduleView = []

    if (this.state.activeIndex === 0) {
      ScheduleView.push(
        <FormattedMessage id="GENERAL.SCHEDULE_PAYMENT">
          {(message) =>
            <Button icon="pi pi-calendar" label={message} color='primary' onClick={this.scheduleCommissionValidation} disabled={!this.state.selectedCommissions || !this.state.selectedCommissions.length} />
          }</FormattedMessage>

      )
    }
    return ScheduleView
  }

  toolbarButtonPayments = () => {
    console.log("INDEX:", this.state.activeIndex)
    let PaymentsView = []

    if (this.state.activeIndex === 0) {
      PaymentsView.push(
        <FormattedMessage id="GENERAL.PAYMENT">
          {(message) =>
            <Button icon="pi pi-plus" label={message} color='primary' onClick={this.paymentsCommissionValidation} disabled={!this.state.selectedCommissions || !this.state.selectedCommissions.length} />
          }</FormattedMessage>

      )
    }
    return PaymentsView
  }

  columnAgencyCommission(rowData) {
    return (
      <>
        <Button label={rowData.symbolCommissionCurrency} className="p-button-text" onClick={() => this.editAgencyCommission(rowData)} />
      </>
    )
  }

  columnAgentCommission(rowData) {

    return (
      <>
        <Button label={rowData.symbolCommissionTotalCurrency} className="p-button-text" onClick={() => this.editAgentCommission(rowData)} />
      </>
    )
  }

  actionBodyTemplate(rowData) {
    let showButton = []

    if (rowData.status === 0 && rowData.dismiss === 0) {
      showButton.push(
        <>
          <Button tooltip='New commission' tooltipOptions={{ position: 'bottom' }} className='p-button-rounded p-button-outlined' icon="pi pi-plus" color='primary' style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.paymentCommission(rowData)} />
          <Button tooltip='Schedule commission' tooltipOptions={{ position: 'bottom' }} className='p-button-rounded p-button-outlined' icon="pi pi-calendar" color='primary' style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.openSchedule(rowData)} />
          {this.buttonED(rowData)}
        </>
      )
    } else if (rowData.dismiss === 1) {
      showButton.push(
        <>
          {this.buttonED(rowData)}
        </>
      )
    }
    return showButton
  }

  paymentsActionBodyTemplate(rowData) {
    let showButton = []

    if (rowData.status === 0) {
      showButton.push(
        <>
          <Button tooltip='New commission' tooltipOptions={{ position: 'bottom' }} className='p-button-rounded p-button-outlined' icon="pi pi-pencil" color='primary' style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.editPaymentCommission(rowData)} />
          <Button tooltip='Delete commission' tooltipOptions={{ position: 'bottom' }} icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-danger" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.confirmDeletePayment(rowData)} />
        </>
      )
    }
    return showButton
  }

  buttonED = (rowData) => {


    let EDView = []

    if (this.state.dismissCommission === 0) {
      EDView.push(
        <Button tooltip='Dismiss commission' tooltipOptions={{ position: 'bottom' }} icon="pi pi-times" className="p-button-rounded p-button-outlined p-button-danger" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.confirmDismissCommission(rowData)} />
      )
    } else {
      EDView.push(
        <Button tooltip='Enable commission' tooltipOptions={{ position: 'bottom' }} icon="pi pi-check" className="p-button-rounded p-button-outlined p-button-success" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.confirmEnableCommission(rowData)} />
      )
    }

    return EDView
  }

  /*      formatCurrency = (value) => {
          if(value===0 || value===null){  
            return null
          }else{
          return value.toLocaleString('en-US', {mode:"currency", maxFractionDigits:3, minFractionDigits:3});
          }
        }   */

  CurrencyOrPercentage = () => {
    console.log("E EN CURRENCY Y PERCENTAGE", this.state.selectedCommissionType)

    let show = []

    if (this.state.selectedCommissionType === 1) {
      show.push(
        <>
          <span>{this.state.transfer.code}</span>
        </>
      )
    } else if (this.state.selectedCommissionType === 2 || this.state.selectedCommissionType === 3) {
      show.push(
        <><span>%</span></>
      )
    } else {
      show.push()
    }
    return show
  }

  onIndexChange(e) {

    if (e === 0) {
      this.getCommissions()
    } else if (e === 1) {
      this.getSchedulesPayments()
    } else if (e === 2) {
      this.getPayments()
    } else if (e === 3) {
      this.getCommissionsPaid()
    } else if (e === 4) {
      this.getCommissionsDismissed()
    }

    this.setState({ commisions: null, payments: null, activeIndex: e });

  }


  /*    agencyCommission() {
        let total = 0;
        let commissions = {...this.state.commissions}

        for(let i in commissions) {
            total += commissions[i].agencyCommission;
        }

        return total
    }

    commissionTotal() {
      let commissions = {...this.state.commissions}
        let total = 0;
        for(let i in commissions) {
            total += commissions[i].commissionTotal;
        }

        return total
    }

    commissionBalance() {
      let commissions = {...this.state.commissions}
      let total = 0;
      for(let i in commissions) {
          total += commissions[i].commissionBalance;
      }

      return total
  }*/

  onColumnToggle(event) {
    let selectedColumns = event.value
    let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
    this.setState({ selectedColumns: orderedSelectedColumns })
  }

  onColumnCommissionToggle(event) {
    let selectedColumnsCommission = event.value
    console.log("SELECTED COLUMNS COMMISSION: ", selectedColumnsCommission);

    let orderedSelectedColumnsCommissions = this.columnsCommission.filter(col => selectedColumnsCommission.some(
      sCol => sCol.field === col.field
    ))

    this.setState({ selectedColumnsCommission: orderedSelectedColumnsCommissions })


    // let orderedSelectedColumns = this.columnsCommission.filter(col => selectedColumnsCommissions.some( sCol => sCol.field === col.field));
    // this.setState({ selectedColumnsCommissions: orderedSelectedColumns })
  }

  showInvoiceName(e) {
    let invoiceName
    e.map((commission) => {
      if (typeof commission.invoiceName == 'string') {
        invoiceName = commission.invoiceName.slice(0, 20)

        // for ( let i in provider){
        // console.log("PROVIDER: ", i);

        if (invoiceName.length >= 19) {
          // console.log("PROVIDER: ", provider );

          invoiceName += '...'

        }

        commission.invoiceName = invoiceName
        // }

      }

      return commission
    })

  }

  showItemName(e) {
    let item_name
    e.map((commission) => {
      if (typeof commission.item_name == 'string') {
        item_name = commission.item_name.slice(0, 20)

        // for ( let i in provider){
        // console.log("PROVIDER: ", i);

        if (item_name.length >= 19) {
          // console.log("PROVIDER: ", provider );

          item_name += '...'

        }

        commission.item_name = item_name
        // }

      }

      return commission
    })

  }

  showProvider(e) {
    let provider
    e.map((commission) => {
      if (typeof commission.provider == 'string') {
        provider = commission.provider.slice(0, 20)

        // for ( let i in provider){
        // console.log("PROVIDER: ", i);

        if (provider.length >= 19) {
          // console.log("PROVIDER: ", provider );

          provider += '...'

        }

        commission.provider = provider
        // }

      }

      return commission
    })

  }


  tableCommissions() {
    let showCommissions = []

    const header = (
      <div style={{ textAlign: 'left' }}>
        <MultiSelect
          value={this.state.selectedColumnsCommission}
          placeholder='Fields to show...'
          display='chip'
          options={this.columnsCommission}
          optionLabel='header'
          onChange={this.onColumnCommissionToggle}
        // style={{ width: '20em'}}
        />

        {/* <ToggleButton 
              checked={this.state.symbolAmountFrozen} 
              onChange={(e) => this.setState({ symbolAmountFrozen: e.value })} 
              onIcon="pi pi-lock" 
              offIcon='pi pi-lock-open' 
              onLabel='Unfreeze balance' 
              offLabel='Freeze balance' 
              style={{ flexGrow: 1, flexBasis: '12rem', marginLeft: '10px'}}
            /> */}
      </div>
    )

    const columnComponents = this.state.selectedColumnsCommission.map(col => {
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

    showCommissions.push(
      <>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <DataTable
            ef={(el) => this.dt = el}
            value={this.state.commissions}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            resizableColumns
            columnResizeMode='fit'
            responsiveLayout='scroll'
            header={header}
            reorderableColumns
            stripedRows
            currentPageReportTemplate={this.state.paginatorText}
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            ref={(el) => { this.dt = el; }}
            globalFilter={this.state.globalFilter}
            emptyMessage={<FormattedMessage id="ACCOUNTING_COMMISSION.NO_DATA" />}
            loading={this.state.loading}
            selection={this.state.selectedCommissions}
            onSelectionChange={e => this.setState({ selectedCommissions: e.value })}
            dataKey="id">
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
            {columnComponents}
            {/* <Column field="externalId" header={this.state.campusText.InvoiceId}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column>   */}
            {/* <Column field="item_name" header={this.state.campusText.InvoiceName}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceName} filterMatchMode="contains"></Column> */}
            {/* <Column field="provider" header={this.state.campusText.Provider}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Provider} filterMatchMode="contains"></Column> */}
            {/* <Column field="agency" header={this.state.campusText.Office}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
            {/* <Column field="agent" header={this.state.campusText.Agent}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Agent} filterMatchMode="contains"></Column> */}
            <Column field="student" header={this.state.campusText.Student} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column>
            <Column field="symbolCommissionCurrency" body={this.columnAgencyCommission} header={this.state.campusText.AgencyCommission} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.AgencyCommission} filterMatchMode="contains"></Column>
            <Column field="symbolCommissionTotalCurrency" body={this.columnAgentCommission} header={this.state.campusText.AgentCommission} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.AgentCommission} filterMatchMode="contains"></Column>
            <Column field="symbolCommissionAgentBalanceCurrency" header={this.state.campusText.AgentCommissionBalance} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.AgentCommissionBalance} filterMatchMode="contains"></Column>

            <Column body={this.actionBodyTemplate}></Column>
          </DataTable>
        </div>
      </>
    )
    return showCommissions
  }

  tableCommissionsFullyPaid() {
    let showCommissions = []

    const header = (
      <div style={{ textAlign: 'left' }}>
        <MultiSelect
          value={this.state.selectedColumnsCommission}
          placeholder='Fields to show...'
          display='chip'
          options={this.columnsCommission}
          optionLabel='header'
          onChange={this.onColumnCommissionToggle}
        // style={{ width: '20em'}}
        />

        {/* <ToggleButton 
              checked={this.state.symbolAmountFrozen} 
              onChange={(e) => this.setState({ symbolAmountFrozen: e.value })} 
              onIcon="pi pi-lock" 
              offIcon='pi pi-lock-open' 
              onLabel='Unfreeze balance' 
              offLabel='Freeze balance' 
              style={{ flexGrow: 1, flexBasis: '12rem', marginLeft: '10px'}}
            /> */}
      </div>
    )

    const columnComponents = this.state.selectedColumnsCommission.map(col => {
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

    showCommissions.push(
      <>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <DataTable
            ef={(el) => this.dt = el}
            value={this.state.commissions}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            resizableColumns
            columnResizeMode='fit'
            header={header}
            reorderableColumns
            stripedRows
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            ref={(el) => { this.dt = el; }}
            globalFilter={this.state.globalFilter}
            emptyMessage={<FormattedMessage id="ACCOUNTING_COMMISSION.NO_DATA" />}
            loading={this.state.loading}
            selection={this.state.selectedCommissions}
            onSelectionChange={e => this.setState({ selectedCommissions: e.value })}
            dataKey="id">
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
            {columnComponents}
            {/* <Column field="externalId" header={this.state.campusText.InvoiceId}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column>   */}
            {/* <Column field="item_name" header={this.state.campusText.InvoiceName}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceName} filterMatchMode="contains"></Column> */}
            {/* <Column field="provider" header={this.state.campusText.Provider}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Provider} filterMatchMode="contains"></Column> */}
            {/* <Column field="agency" header={this.state.campusText.Office}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
            {/* <Column field="agent" header={this.state.campusText.Agent}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Agent} filterMatchMode="contains"></Column> */}
            <Column field="student" header={this.state.campusText.Student} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column>
            <Column field="symbolCommissionCurrency" body={this.columnAgencyCommission} header={this.state.campusText.AgencyCommission} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.AgencyCommission} filterMatchMode="contains"></Column>
            <Column field="symbolCommissionTotalCurrency" body={this.columnAgentCommission} header={this.state.campusText.AgentCommission} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.AgentCommission} filterMatchMode="contains"></Column>
            <Column field="symbolCommissionAgentBalanceCurrency" header={this.state.campusText.AgentCommissionBalance} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.AgentCommissionBalance} filterMatchMode="contains"></Column>
            <Column field="symbolUtilityCurrency" header={this.state.campusText.Utility} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Utility} filterMatchMode="contains"></Column>
            <Column body={this.actionBodyTemplate}></Column>
          </DataTable>
        </div>
      </>
    )
    return showCommissions
  }


  onSortScheduleDate(event) {
    let data = [...this.state.payments];
    data.sort((data1, data2) => {
      console.log("EVENT:", event)
      const value1 = data1.scheduleDate_new;
      const value2 = data2.scheduleDate_new;

      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2, undefined, { numeric: true });
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });

    return data;
  }

  tableSchedulePayments() {
    let showPayments = []

    const header = (
      <div style={{ textAlign: 'left' }}>
        <MultiSelect
          value={this.state.selectedColumns}
          placeholder='Fields to show...'
          display='chip'
          options={this.columns}
          optionLabel='header'
          onChange={this.onColumnToggle}
        // style={{ width: '20em'}}
        />

        {/* <ToggleButton 
              checked={this.state.symbolAmountFrozen} 
              onChange={(e) => this.setState({ symbolAmountFrozen: e.value })} 
              onIcon="pi pi-lock" 
              offIcon='pi pi-lock-open' 
              onLabel='Unfreeze balance' 
              offLabel='Freeze balance' 
              style={{ flexGrow: 1, flexBasis: '12rem', marginLeft: '10px'}}
            /> */}
      </div>
    )

    const columnComponents = this.state.selectedColumns.map(col => {
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

    showPayments.push(
      <>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <DataTable
            ef={(el) => this.dt = el}
            value={this.state.payments}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            resizableColumns
            columnResizeMode='fit'
            header={header}
            reorderableColumns
            stripedRows
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            ref={(el) => { this.dt = el; }}
            globalFilter={this.state.globalFilter}
            emptyMessage={<FormattedMessage id="ACCOUNTING_COMMISSION.SCHEDULE_PAYEMENT.NO_DATA" />}
            loading={this.state.loading}
            selection={this.state.selectedPayments}
            onSelectionChange={e => this.setState({ selectedPayments: e.value })}
            dataKey="id">
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
            {columnComponents}
            {/* <Column field="externalId" header={this.state.campusText.InvoiceId}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
            {/* <Column field="invoiceName" header={this.state.campusText.InvoiceName}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceName} filterMatchMode="contains"></Column> */}
            {/* <Column field="provider" header={this.state.campusText.Provider}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Provider} filterMatchMode="contains"></Column> */}
            {/* <Column field="office" header={this.state.campusText.Office}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
            {/* <Column field="agent" header={this.state.campusText.Agent}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Agent} filterMatchMode="contains"></Column> */}
            <Column field="student" header={this.state.campusText.Student} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column>
            <Column field="scheduleDate" header={this.state.campusText.ScheduleDate} sortable={true} sortFunction={this.onSortScheduleDate} headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.ScheduleDate} filterMatchMode="contains"></Column>
            <Column field="symbolEquivalentPaymentsCurrency" header={this.state.campusText.Equivalent} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Equivalent} filterMatchMode="contains"></Column>
            <Column body={this.paymentsActionBodyTemplate}></Column>
          </DataTable>
        </div>
      </>
    )
    return showPayments
  }

  onSortPaymentDate(event) {
    let data = [...this.state.payments];
    data.sort((data1, data2) => {
      console.log("EVENT:", event)
      const value1 = data1.paymentDate_new;
      const value2 = data2.paymentDate_new;

      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2, undefined, { numeric: true });
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });

    return data;
  }

  tablePayments() {
    let showPayments = []

    const header = (
      <div style={{ textAlign: 'left' }}>
        <MultiSelect
          value={this.state.selectedColumns}
          placeholder='Fields to show...'
          display='chip'
          options={this.columns}
          optionLabel='header'
          onChange={this.onColumnToggle}
        // style={{ width: '20em'}}
        />

        {/* <ToggleButton 
              checked={this.state.symbolAmountFrozen} 
              onChange={(e) => this.setState({ symbolAmountFrozen: e.value })} 
              onIcon="pi pi-lock" 
              offIcon='pi pi-lock-open' 
              onLabel='Unfreeze balance' 
              offLabel='Freeze balance' 
              style={{ flexGrow: 1, flexBasis: '12rem', marginLeft: '10px'}}
            /> */}
      </div>
    )

    const columnComponents = this.state.selectedColumns.map(col => {
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

    showPayments.push(
      <>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <DataTable
            ef={(el) => this.dt = el}
            value={this.state.payments}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            resizableColumns
            columnResizeMode='fit'
            header={header}
            reorderableColumns
            stripedRows
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            ref={(el) => { this.dt = el; }}
            globalFilter={this.state.globalFilter}
            emptyMessage={<FormattedMessage id="ACCOUNTING_COMMISSION.PAYEMENT.NO_DATA" />}
            loading={this.state.loading}
            selection={this.state.selectedPayments}
            onSelectionChange={e => this.setState({ selectedPayments: e.value })}
            dataKey="id">
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
            {columnComponents}
            {/* <Column field="externalId" header={this.state.campusText.InvoiceId}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
            {/* <Column field="invoiceName" header={this.state.campusText.InvoiceName}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceName} filterMatchMode="contains"></Column> */}
            {/* <Column field="provider" header={this.state.campusText.Provider}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Provider} filterMatchMode="contains"></Column> */}
            {/* <Column field="office" header={this.state.campusText.Office}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
            {/* <Column field="agent" header={this.state.campusText.Agent}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Agent} filterMatchMode="contains"></Column> */}
            <Column field="student" header={this.state.campusText.Student} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column>
            <Column field="paymentDate" header={this.state.campusText.PaymentDate} sortable={true} sortFunction={this.onSortPaymentDate} headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.PaymentDate} filterMatchMode="contains"></Column>
            <Column field="account_name" header={this.state.campusText.Account} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Account} filterMatchMode="contains"></Column>
            <Column field="symbolPaymentsCurrency" header={this.state.campusText.Amount} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Amount} filterMatchMode="contains"></Column>
            <Column field="symbolRatesCurrency" header={this.state.campusText.Rate} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Rate} filterMatchMode="contains"></Column>
            <Column field="symbolEquivalentPaymentsCurrency" header={this.state.campusText.Equivalent} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Equivalent} filterMatchMode="contains"></Column>
            <Column body={this.paymentsActionBodyTemplate}></Column>
          </DataTable>
        </div>
      </>
    )
    return showPayments
  }


  amountNrate = () => {
    let InputAmountView = []
    let paymentCommission = { ...this.state.paymentCommission }

    InputAmountView.push(
      <>
        <div className="form-group row">
          <div className="col-lg-4">
            <span className="p-float-label">
              <InputNumber
                locale="en-US"
                minFractionDigits={2}
                min="0"
                inputStyle={{ width: 190, height: 33 }}
                id="amount"
                variant="outlined"
                margin="dense"
                value={paymentCommission.paymentAmount}
                onValueChange={(e) => this.onPaymentAmountChange(e)}
                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentCommission.paymentAmount })}
              /> {paymentCommission.codeAccount}
              <label htmlFor="amount">{this.state.campusText.Amount}</label>
            </span>
            <small id="amount-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPaymentAmount(this.state)}</small>
          </div>

          <div className="col-lg-1.5">
            <span className="p-float-label">
              <InputNumber
                locale="en-US"
                minFractionDigits={2}
                min="0"
                inputStyle={{ width: 70, height: 33 }}
                id="rate"
                variant="outlined"
                margin="dense"
                value={paymentCommission.rate}
                onValueChange={(e) => this.onRateChange(e)}
                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentCommission.rate })}
              />
              <label htmlFor="rate">{this.state.campusText.Rate}</label>
            </span>
            <small id="rate-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxRate(this.state)}</small>
          </div>
          {/*<label style={{ color: 'gray' }}>suggested: {this.formatCurrency(paymentTransfer.paymentTotal*paymentTransfer.rate)}</label>*/}
          <div className="col-lg-4">
            <span className="p-float-label">
              <InputNumber
                locale="en-US"
                minFractionDigits={2}
                inputstyle={{ width: 190, height: 33, backgroundColor: '#e3e4e5' }}
                id="paymentEquivalent"
                variant="outlined"
                margin="dense"
                readOnly="true"
                value={paymentCommission.paymentEquivalentAmount}
              /> {paymentCommission.code}
              <label htmlFor="Equivalent">{this.state.campusText.Equivalent}</label>
            </span>
          </div>
        </div>

      </>
    )
    return InputAmountView
  }




  render() {




    const dismissCommissionDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDismissCommissionDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.dismissCommission} />
      </>
    );



    const dismissCommissionsDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDismissCommissionsDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.dismissSelectedCommissions} />
      </>
    );

    const enableCommissionDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableCommissionDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.enableCommission} />
      </>
    );


    const enableCommissionsDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableCommissionsDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.enableSelectedCommissions} />
      </>
    );


    const agencyCommissionDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hideAgencyCommissionDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.saveAgencyCommission} />}
        </FormattedMessage>
      </>
    );

    const agentCommissionDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hideAgentCommissionDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.saveAgentCommission} />}
        </FormattedMessage>
      </>
    );

    const paymentCommissionDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hidePaymentCommissionDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.savePaymentCommission} />}
        </FormattedMessage>
      </>
    );

    const scheduleCommissionDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hideScheduleCommissionDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.createScheduleCommission} />}
        </FormattedMessage>
      </>
    );

    const schedulePaymentsDialogFooter = (
      <>
        <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hideSchedulePaymentsDialog} />
        <Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.schedulePaymentsSelected} />
      </>
    )

    const schudelePaymentValidationDialogFooter = (
      <>
        <Button label={this.state.campusText.Ok} icon="pi pi-times" className="p-button-text" onClick={this.hideSchedulePaymentValidationDialog} />
      </>
    );


    const paymentsValidationDialogFooter = (
      <>
        <Button label={this.state.campusText.Ok} icon="pi pi-times" className="p-button-text" onClick={this.hidePaymentsValidationDialog} />
      </>
    );

    const deletePaymentDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeletePaymentDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deletePayment} />
      </>
    );

    const deletePaymentsDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeletePaymentsDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedPayments} />
      </>
    );

    return (
      <div>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />
          <h3 className="card-label" >
            <FormattedMessage id="ACCOUNTING_COMMISSION.TITLE" />
            <span className="d-block text-muted pt-2 font-size-sm">
              <FormattedMessage id="ACCOUNTING_COMMISSION.SUBTITLE" />
            </span>
          </h3>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/*TOOL BAR */}


            <div style={{ marginRight: 10 }}>
              <FormattedMessage id="GENERAL.EXPORT">
                {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
              </FormattedMessage>
            </div>

            <div style={{ marginRight: 10 }}>
              {this.toolbarButtonED()}
            </div>
            <div style={{ marginRight: 10 }}>
              {this.toolbarButtonSchedule()}
            </div>
            {/*} <div style={{ marginRight: 10}}>
                            {this.toolbarButtonPayments()}
                          </div>*/}

            <div style={{ marginRight: 10 }}>
              <p> <span className="p-input-icon-left">
                <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
              </span></p>
            </div>

          </div>
        </div>


        <div>
          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onIndexChange(e.index)} >
            <TabPanel header={this.state.campusText.Commissions}>
              <div>
                {/*SHOW PENDDING COMMISSIONS */}
                {
                  this.state.loading === true ?
                    <div className='flex flex-row flex-wrap'>
                      <ProgressSpinner className='flex align-items-center justify-content-center' style={{ width: '100px', height: '100px' }} strokeWidth="8" fill="var(--surface-ground)" />
                    </div>

                    :
                    <>
                      {this.tableCommissions()}
                    </>
                }
              </div>
            </TabPanel>
            <TabPanel header={this.state.campusText.ScheduleCommissionPayment}>
              <div>
                {/*SHOW SCHEDULE PAYMENTS */}
                {
                  this.state.loading === true ?
                    <div className='flex flex-row flex-wrap'>
                      <ProgressSpinner className='flex align-items-center justify-content-center' style={{ width: '100px', height: '100px' }} strokeWidth="8" fill="var(--surface-ground)" />
                    </div>

                    :
                    <>
                      {this.tableSchedulePayments()}
                    </>
                }
              </div>
            </TabPanel>
            <TabPanel header={this.state.campusText.CommissionPayments}>
              <div>
                {/*SHOW COMMISSION PAYMENTS */}
                {
                  this.state.loading === true ?
                    <div className='flex flex-row flex-wrap'>
                      <ProgressSpinner className='flex align-items-center justify-content-center' style={{ width: '100px', height: '100px' }} strokeWidth="8" fill="var(--surface-ground)" />
                    </div>

                    :
                    <>
                      {this.tablePayments()}
                    </>
                }
              </div>
            </TabPanel>
            <TabPanel header={this.state.campusText.CommissionsFullyPaid}>
              <div>
                {/*SHOW PAID COMMISSIONS */}
                {
                  this.state.loading === true ?
                    <div className='flex flex-row flex-wrap'>
                      <ProgressSpinner className='flex align-items-center justify-content-center' style={{ width: '100px', height: '100px' }} strokeWidth="8" fill="var(--surface-ground)" />
                    </div>

                    :
                    <>
                      {this.tableCommissionsFullyPaid()}
                    </>
                }
              </div>
            </TabPanel>
            <TabPanel header={this.state.campusText.DismissedCommissiones}>
              <div>
                {/*SHOW DISMISSED TRANSFERS */}
                {
                  this.state.loading === true ?
                    <div className='flex flex-row flex-wrap'>
                      <ProgressSpinner className='flex align-items-center justify-content-center' style={{ width: '100px', height: '100px' }} strokeWidth="8" fill="var(--surface-ground)" />
                    </div>

                    :
                    <>
                      {this.tableCommissions()}
                    </>
                }
              </div>
            </TabPanel>
          </TabView>
        </div>




        <div>
          {/*DISMISS COMMISSION */}
          <Dialog visible={this.state.dismissCommissionDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={dismissCommissionDialogFooter} onHide={this.hideDismissCommissionDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {this.state.commission && <span><FormattedMessage id="ACCOUNTING_COMMISSION.DIALOG.DISMISS" /></span>}
            </div>
          </Dialog>
        </div>

        {/*DISMISS WITH TOOL BAR*/}
        <Dialog visible={this.state.dismissCommissionsDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={dismissCommissionsDialogFooter} onHide={this.hideDismissCommissionsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {this.state.commission && <span><FormattedMessage id="ACCOUNTING_COMMISSION.DIALOG_DISMISS_ALL" /></span>}
          </div>
        </Dialog>

        <div>
          {/*ENABLE COMMISSION */}
          <Dialog visible={this.state.enableCommissionDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={enableCommissionDialogFooter} onHide={this.hideEnableCommissionDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {this.state.commission && <span> <FormattedMessage id="ACCOUNTING_COMMISSION.DIALOG.ENABLE" /></span>}
            </div>
          </Dialog>
        </div>

        {/*ENABLE WITH TOOL BAR*/}
        <Dialog visible={this.state.enableCommissionsDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={enableCommissionsDialogFooter} onHide={this.hideEnableCommissionsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {this.state.commission && <span><FormattedMessage id="ACCOUNTING_COMMISSION.DIALOG_ENABLE_ALL" /></span>}
          </div>
        </Dialog>

        {/*DELETE PAYMENT */}
        <Dialog visible={this.state.deletePaymentDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={deletePaymentDialogFooter} onHide={this.hideDeletePaymentDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {<span> <FormattedMessage id="ACCOUNTING_PAYMENT.DIALOG_DELETE" /></span>}
          </div>
        </Dialog>

        {/*DELETE WITH TOOL BAR*/}
        <Dialog visible={this.state.deletePaymentsDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={deletePaymentsDialogFooter} onHide={this.hideDeletePaymentsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {<span><FormattedMessage id="ACCOUNTING_PAYMENT.DIALOG_DELETE_ALL" /></span>}
          </div>
        </Dialog>

        <Dialog visible={this.state.agencyCommissionDialog} contentStyle={{ maxHeight: "300px", width: "800px", height: "180px", overflow: "auto" }} header={this.state.campusText.CommissionAgency} modal footer={agencyCommissionDialogFooter} onHide={this.hideAgencyCommissionDialog}>
          {/* Student*/}
          <div className="form-group row">
            <div className="col-lg-12">
              <h5 className="card-label" >{this.state.campusText.Student}:  {this.state.commission.student}
              </h5>
              <h5 className="card-label" >{this.state.campusText.Office}:  {this.state.commission.agency}
              </h5>
            </div>
          </div>
          <br></br>
          {/* Transfer Total*/}
          <div className="form-group row">
            <div className="col-lg-4">
              <span className="p-float-label">
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  inputStyle={{ width: 190, height: 33 }}
                  id="trasferTotal"
                  variant="outlined"
                  margin="dense"
                  value={this.state.transfer.transferTotal}
                  onValueChange={(e) => this.onInputChange(e, 'transferTotal')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.transfer.transferTotal })}
                /> {this.state.transfer.code}
                <label htmlFor="last_name">{this.state.campusText.TransferTotal}</label>
              </span>
              <small id="transferTotal-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxTransferTotal(this.state)}</small>
            </div>
            {/* Commission*/}
            <div className="col-lg-4">
              <span className="p-float-label">
                <InputText
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ width: 190, height: 33, backgroundColor: '#e3e4e5' }}
                  readOnly='true'
                  id="commission"
                  variant="outlined"
                  margin="dense"
                  value={this.state.transfer.commission}
                /> {this.state.transfer.code}
                <label htmlFor="commission">{this.state.campusText.AgencyCommission}</label>
              </span>
            </div>
            {/* Balance*/}
            <div className="col-lg-4">
              <span className="p-float-label">
                <InputText
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ width: 190, height: 33, backgroundColor: '#e3e4e5' }}
                  readOnly='true'
                  id="commission"
                  variant="outlined"
                  margin="dense"
                  value={this.state.transfer.transferBalance}
                /> {this.state.transfer.code}
                <label htmlFor="commission">{this.state.campusText.TransferBalance}</label>
              </span>
            </div>
          </div>
        </Dialog>

        <Dialog visible={this.state.agentCommissionDialog} contentStyle={{ maxHeight: "800px", width: "900px", height: "300px", overflow: "auto" }} header={this.state.campusText.AgentCommission} modal footer={agentCommissionDialogFooter} onHide={this.hideAgentCommissionDialog}>
          {/* Student*/}
          <div className="form-group row">
            <div className="col-lg-12">
              <h5 className="card-label" >{this.state.campusText.Student}:  {this.state.commission.student}
              </h5>
              <h5 className="card-label" >{this.state.campusText.Office}:  {this.state.commission.agency}
              </h5>
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            {/* Sale*/}
            <div className="col-lg-3">
              <span className="p-float-label">
                <InputText
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ width: 150, height: 33, backgroundColor: '#e3e4e5' }}
                  readOnly='true'
                  id="commission"
                  variant="outlined"
                  margin="dense"
                  value={this.state.commission.total}
                /> {this.state.commission.code}
                <label htmlFor="commission">{this.state.campusText.Sale}</label>
              </span>
            </div>
            {/* Commission*/}
            <div className="col-lg-3">
              <span className="p-float-label">
                <InputText
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ width: 150, height: 33, backgroundColor: '#e3e4e5' }}
                  readOnly='true'
                  id="commission"
                  variant="outlined"
                  margin="dense"
                  value={this.state.transfer.commission}
                /> {this.state.transfer.code}
                <label htmlFor="commission">{this.state.campusText.AgencyCommission}</label>
              </span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4">
              <span className="p-float-label">
                <Dropdown
                  style={{ width: 190, height: 33 }}
                  id="commissionType_name"
                  variant="outlined"
                  margin="dense"
                  value={this.state.selectedCommissionType || this.state.commission.fk_id_commissionType}
                  options={this.state.commissionTypes}
                  onChange={this.onCommissionTypeChange}
                  optionLabel="commissionType_name"
                  optionValue="id"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.commission.fk_id_commissionType })}
                />
                <label htmlFor="commission_type">{this.state.campusText.CommissionType}</label>
              </span>
              <small id="commission_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFk_id_commissionType(this.state)}</small>
            </div>
            <div className="col-lg-4">
              <span className="p-float-label">
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  inputStyle={{ width: 190, height: 33 }}
                  id="amount"
                  variant="outlined"
                  margin="dense"
                  value={this.state.commission.amount}
                  onValueChange={(e) => this.onAmountChange(e)}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.commission.amount })}
                /> {this.CurrencyOrPercentage()}
                <label htmlFor="amount">{this.state.campusText.Amount}</label>
              </span>
              <small id="amount-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAmount(this.state)}</small>
            </div>
            <div className="col-lg-4">
              <span className="p-float-label">
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  inputstyle={{ width: 190, height: 33, backgroundColor: '#e3e4e5' }}
                  id="commissionTotal"
                  variant="outlined"
                  margin="dense"
                  value={this.state.commission.commissionTotal}
                /> {this.state.transfer.code}
                <label htmlFor="commissionTotal">{this.state.campusText.AgentCommission}</label>
              </span>
            </div>
          </div>
        </Dialog>

        <Dialog visible={this.state.paymentCommissionDialog} contentStyle={{ maxHeight: "600px", width: "850px", height: "530px", overflow: "auto" }} header={this.state.campusText.CommissionPayment} modal footer={paymentCommissionDialogFooter} onHide={this.hidePaymentCommissionDialog}>
          {/* Student*/}
          <div className="form-group row">
            <div className="col-lg-12">
              <h5 className="card-label" >{this.state.campusText.Student}:  {this.state.commission.student}
              </h5>
              <h5 className="card-label" >{this.state.campusText.Office}:   {this.state.commission.agency}
              </h5>
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            <div className="col-lg-3">
              <span className="p-float-label">
                <Dropdown
                  style={{ width: 190, height: 33 }}
                  id="account_name"
                  variant="outlined"
                  //placeholder="Account"
                  margin="dense"
                  value={this.state.selectedAccount}
                  options={this.state.accounts}
                  onChange={this.onAccountChange}
                  optionLabel="account_name"
                  optionValue="id"
                />
                <label htmlFor="account_name">{this.state.campusText.Account}</label>
              </span>
              <small id="account_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFk_id_account(this.state)}</small>
            </div>
            <div className="col-lg-3">
              <span className="p-float-label">
                <Dropdown
                  style={{ width: 190, height: 33 }}
                  id="method_name"
                  variant="outlined"
                  //placeholder="Payment Method"
                  margin="dense"
                  value={this.state.selectedMethod}
                  options={this.state.methods}
                  onChange={this.onMethodChange}
                  optionLabel="method_name"
                  optionValue="id"
                />
                <label htmlFor="method_name">{this.state.campusText.PaymentMethod}</label>
              </span>
              <small id="method_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFk_id_paymentCommissionMethod(this.state)}</small>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <span className="p-float-label">
                <Dropdown
                  style={{ width: 190, height: 33 }}
                  id="exchange_rate_type_name"
                  variant="outlined"
                  //placeholder="Payment Method"
                  margin="dense"
                  value={this.state.selectedExchangeRateType}
                  options={this.state.exchangeRateTypes}
                  onChange={this.onExchangeRateTypeChange}
                  optionLabel="exchange_rate_type_name"
                  optionValue="id"
                />
                <label htmlFor="exchange_rate_type_name">{this.state.campusText.ExchangeRateType}</label>
              </span>
              <small id="method_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFk_id_exchangeRateType(this.state)}</small>
            </div>
            <div className="col-lg-3">
              <span className="p-float-label">
                <Calendar
                  style={{ width: 190, height: 33 }}
                  showIcon
                  margin="dense"
                  variant="outlined"
                  readOnlyInput="true"
                  dateFormat="dd/mm/yy"
                  id="payment_date"
                  //placeholder="Payment Date"
                  value={this.state.paymentCommission.paymentDate}
                  onChange={(e) => this.onDateChange(e, 'paymentDate')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentCommission.paymentDate })}
                />
                <label htmlFor="payment_date">{this.state.campusText.PaymentDate}</label>
              </span>
              <small id="payment_date-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPaymentDate(this.state)}</small>
            </div>
          </div>
          {/* Payment Amount*/}
          {this.amountNrate()}

          <div className='form-group row'>
            <div className='col-lg-6'>
              <span className='p-float-label'>
                <InputTextarea
                  style={{ width: 600, height: 80 }}
                  id='note'
                  variant='outlined'
                  margin='dense'
                  value={this.state.paymentCommission.note}
                  onChange={(e) => this.onNoteChange(e, 'note')}
                />
                <label htmlFor='note'>{this.state.campusText.Note}</label>
              </span>
            </div>
          </div>
        </Dialog>

        <Dialog dismissableMask='true' visible={this.state.scheduleCommissionDialog} contentStyle={{ maxHeight: "600px", width: "800px", height: "550px", overflow: "auto" }} header={this.state.campusText.SchedulePayment} footer={scheduleCommissionDialogFooter} onHide={this.hideScheduleCommissionDialog}>
          <br></br>
          <div className="form-group row">
            <div className="col-lg-12">
              <h6 className="card-label" >{this.state.campusText.Student}: {this.state.commission.student}</h6>
              <h6 className="card-label" >{this.state.campusText.Office}:  {this.state.commission.agency}</h6>
            </div>
          </div>
          <br></br>
          <div className="form-group row">
            <div className="col-lg-3">
              <span className="p-float-label">
                <InputText
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ width: 190, height: 33, backgroundColor: '#e3e4e5' }}
                  id="commmission"
                  readOnly="true"
                  variant="outlined"
                  margin="dense"
                  value={this.state.commission.symbolCommissionCurrency}
                />
                <label htmlFor="commission">{this.state.campusText.AgencyCommission}</label>
              </span>
            </div>

            <div className="col-lg-3">
              <span className="p-float-label">
                <InputText
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ width: 190, height: 33, backgroundColor: '#e3e4e5' }}
                  id="toTransfer"
                  readOnly="true"
                  variant="outlined"
                  margin="dense"
                  value={this.state.commission.symbolCommissionTotalCurrency}
                />
                <label htmlFor="toTransfer">{this.state.campusText.AgentCommission}</label>
              </span>
            </div>

            <div className="col-lg-3">
              <span className="p-float-label">
                <InputText
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ width: 190, height: 33, backgroundColor: '#e3e4e5' }}
                  id="balance"
                  readOnly="true"
                  variant="outlined"
                  margin="dense"
                  value={this.state.commission.symbolCommissionAgentBalanceCurrency}
                />
                <label htmlFor="balance">{this.state.campusText.Balance}</label>
              </span>
            </div>
          </div>
          {/* Shudele Date*/}
          <div className="form-group row">
            {/* Schedule Amount*/}
            <div className="col-lg-4">
              <span className="p-float-label">
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  inputStyle={{ width: 190, height: 33 }}
                  id="scheduleAmount"
                  variant="outlined"
                  margin="dense"
                  value={this.state.paymentCommission.paymentEquivalentAmount}
                  onValueChange={(e) => this.onScheduleAmountChange(e)}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentCommission.paymentEquivalentAmount })}
                /> {this.state.transfer.code}
                <label htmlFor="scheduleAmount">{this.state.campusText.Amount}</label>
              </span>
              <small id="transferTotal-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPaymentEquivalentAmount(this.state)}</small>
            </div>
            <div className="col-lg-3">
              <span className="p-float-label">
                <Calendar
                  style={{ width: 190, height: 33 }}
                  showIcon
                  margin="dense"
                  variant="outlined"
                  readOnlyInput="true"
                  dateFormat="dd/mm/yy"
                  id="cheduleDate"
                  value={this.state.paymentCommission.scheduleDate || this.state.campusText.ScheduleDate}
                  onChange={(e) => this.onScheduleDateChange(e, 'scheduleDate')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentCommission.scheduleDate })}
                />
                <label htmlFor="scheduleDate">{this.state.campusText.scheduleDate}</label>
              </span>
              <small id="scheduleDate-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxScheduleDate(this.state)}</small>
            </div>
          </div>
        </Dialog>

        <Dialog visible={this.state.schedulePaymentValidationDialog} style={{ width: '450px' }} header={this.state.campusText.Warning} modal footer={schudelePaymentValidationDialogFooter} onHide={this.hideSchedulePaymentValidationDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {<span><FormattedMessage id="ACCOUNTING_COMMISSION.DIALOG_CURRENCIES_NOT_SAME" /></span>}
          </div>
        </Dialog>

        <Dialog visible={this.state.schedulePaymentsDialog} contentStyle={{ maxHeight: "600px", width: "500px", height: "420px", overflow: "auto" }} header={this.state.campusText.SchedulePayment} modal footer={schedulePaymentsDialogFooter} onHide={this.hideSchedulePaymentsDialog}>
          <h6 className="confirmation-content">
            {<span><FormattedMessage id="ACCOUNTING_COMMISSION.DIALOG_SCHEDULE_PAYMENTS_ALL" /></span>}
          </h6>
          <br></br>
          {/* Shudele Date*/}
          <div className="form-group row">
            <div className="col-lg-3">
              <span className="p-float-label">
                <Calendar
                  style={{ width: 190, height: 33 }}
                  showIcon
                  margin="dense"
                  variant="outlined"
                  readOnlyInput="true"
                  dateFormat="dd/mm/yy"
                  id="scheduleDate"
                  value={this.state.paymentCommission.scheduleDate || this.state.campusText.ScheduleDate}
                  onChange={(e) => this.onScheduleDateChange(e, 'scheduleDate')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentCommission.scheduleDate })}
                />
                <label htmlFor="scheduleDate">{this.state.campusText.scheduleDate}</label>
              </span>
              <small id="scheduleDate-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxScheduleDate(this.state)}</small>
              {this.state.messageSchedulePayments}
            </div>
          </div>
        </Dialog>

        <Dialog visible={this.state.paymentsValidationDialog} style={{ width: '450px' }} header={this.state.campusText.Warning} modal footer={paymentsValidationDialogFooter} onHide={this.hidePaymentsValidationDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {<span><FormattedMessage id="ACCOUNTING_COMMISSION.DIALOG_CURRENCIES_NOT_SAME" /></span>}
          </div>
        </Dialog>



        {/*} <Tooltip title={this.state.campusText.NewCommission} aria-label="add" onClick={this.openNew}>
          <Fab color="secondary">
            <AddIcon />
          </Fab>
                        </Tooltip>*/}
      </div>

    )
  }
}
const mapStateToProps = state => ({
  invoice: state.auth.invoiceId,
  user: state.auth.user,
});
export default connect(mapStateToProps, auth.actions)(CommissionsTable);