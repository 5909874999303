import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ListEvaluations from "../modulesSchool/ListEvaluations"
import { useIntl } from "react-intl";

export const EvaluationListPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(`${int1.formatMessage({id: "LEARNING_ASSESSMENT.COURSE"})}`);
    return (<ListEvaluations/>)
}