import React from "react";
import {useSubheader} from "../../_metronic/_core";
import CustomerSuccess from "../modulesWiyu/CustomerSuccess/CustomerSuccess";

export const CustomerSuccessPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Customer Success");

  return (<><CustomerSuccess/></>);

};