import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ListAssignmentCourses from "../modulesSchool/ListAssignmentCourses"
import { useIntl } from "react-intl";

export const CourseAssignmentPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();
    
    suhbeader.setTitle(int1.formatMessage({id: "LEARNING_GENERAL.COURSE_ASSIGMENT"}));
    return (<ListAssignmentCourses/>)
}