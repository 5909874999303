import React, {Component} from 'react'

import axios from 'axios'

import ServerURL from '../../../ServerURL'

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
// import Tooltip from '@material-ui/core/Tooltip';


 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip'
import { MultiSelect } from 'primereact/multiselect';




import 'primeflex/primeflex.css';

import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";



import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
//import * as Validation from '../../../Validation';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const url = ServerURL();


class PaymentTransfersTable extends Component {
            

  emptyTransfer={
    invoiceId:null,
    fk_id_balanceItem:null,
    fk_id_currency:null,
    fk_id_commissionType:null,
    transferTotal:null,
    commission:null,
    commissionPercentage:null,
    transferBalance:null,
    status:null,
    enable:null,
  }         
  
  emptyPaymentTransfer={
  fk_id_transfer_id:null,
  originAccount:null,
  paymentDate:null,
  fk_id_paymentTransferMethod:null,
  paymentAmount:null,
  rate:null,
  currencyExchangeOffice:null,
  note:null,
  }      

      


constructor(props) {
  super(props)



  this.state = { 
      
    
      
   
    disableTransferDialog: false,
    enableTransferDialog: false,
    disableTransfersDialog: false,
    enableTransfersDialog: false,
    enableTransfer:1,
    
    globalFilter: null,
    selectedTransfers: null,
    transferDialog: false,
   
    
    selectedAccount: null, 
    resetAccount:null, 
    selectedMethod: null, 
    resetMethod:null, 
    selectedExchangeOffice: null, 
    resetExchangeOffice:null, 
    selectedCommissionType:null,
    resetCommissionType:null,
    /*showCheckBox: false,
    CheckedApprove:false,*/

    transfer: this.emptyTransfer,
    transfers:null,
    showTransfer:null,
    paymentTransfer: this.emptyPaymentTransfer,
    paymentTransfers:null,
    
    redirect: false,

    statusTransfers:1,

    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  //this.columnInvoiceTransfer = this.columnInvoiceTransfer.bind(this);
  //this.disableTransfer = this.disableTransfer.bind(this);
  //this.enableTransfer = this.enableTransfer.bind(this);


  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  /*this.confirmDisableTransfer= this.confirmDisableTransfer.bind(this);
  this.confirmDisableSelected = this.confirmDisableSelected.bind(this);
  this.confirmEnableTransfer= this.confirmEnableTransfer.bind(this);
  this.confirmEnableSelected = this.confirmEnableSelected.bind(this);
  this.disableSelectedTransfers = this.disableSelectedTransfers.bind(this);
  this.enableSelectedTransfers = this.enableSelectedTransfers.bind(this);*/
  /*this.hideDisableTransferDialog = this.hideDisableTransferDialog.bind(this);
  this.hideDisableTransfersDialog = this.hideDisableTransfersDialog.bind(this);
  this.hideEnableTransferDialog = this.hideEnableTransferDialog.bind(this);
  this.hideEnableTransfersDialog = this.hideEnableTransfersDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);

  this.hideInvoiceTransferDialog = this.hideInvoiceTransferDialog.bind(this);
  this.hidePaymentTransferDialog = this.hidePaymentTransferDialog.bind(this);

  this.getExchangeRates = this.getExchangeRates.bind(this);
  this.onAccountChange = this.onAccountChange.bind(this);
  this.onDateChange = this.onDateChange.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onMethodChange = this.onMethodChange.bind(this);
  this.saveInvoiceTransfer = this.saveInvoiceTransfer.bind(this);
  this.createPaymentTransfer = this.createPaymentTransfer.bind(this);
  this.onRateChange = this.onRateChange.bind(this);
  this.onAmountChange = this.onAmountChange.bind(this);
  this.onExchangeOfficeChange = this.onExchangeOfficeChange.bind(this);*/

  this.hideDeletePaymentTransferDialog = this.hideDeletePaymentTransferDialog.bind(this)
  this.deletePaymentTransfer = this.deletePaymentTransfer.bind(this)
  this.onDateChange = this.onDateChange.bind(this);
  this.onMethodChange = this.onMethodChange.bind(this);
  this.onAccountChange = this.onAccountChange.bind(this);
  this.editPaymentTransfer = this.editPaymentTransfer.bind(this);

  


  }

  
      componentDidMount() {
        this.getInitialData()
      }

      getInitialData= async () =>{
        let requestOne =  axios.get(`${url}/api/auth/paymentTransferbyTransfer/${this.props.transfer}`)
        let requestTwo =  axios.get(`${url}/api/auth/transfer/${this.props.transfer}`)  
        axios
        .all([requestOne,requestTwo])
        .then(
              axios.spread((...responses) => {
                const transferPayments = responses[0].data;
                const transfers = responses[1].data;

                this.symbolTransferPaymentCurrency(transferPayments)
                this.exchangePayment(transferPayments)
                
                this.symboltoTransfersCurrency(transfers)
                this.symbolBalanceTransfersCurrency(transfers)
                this.symbolCommissionCurrency(transfers)
                this.showProvider(transfers)
                this.showInvoiceName(transfers)

                      this.setState({transferPayments,transfers})
                    })
                  )
                  .catch(errors => {
                    console.error(errors);
                  });                  
              }   

              exchangePayment(e){
                let length = Object.keys(e).length
                    for(let i=0; i<length; i++){ 
                      let payment
                      payment= e[i].paymentAmount/e[i].rate;
                      e[i].exchangePayment = this.formatCurrency(payment)
                      e[i].exchangePayment =  e[i].exchangePayment + ' ' + e[i].codeRate
                    } 
                return e
            }



               symbolTransferPaymentCurrency(e){
                let length = Object.keys(e).length
                    for(let i=0; i<length; i++){ 
                      e[i].symbolTransferPaymentCurrency = e[i].symbol +' '+e[i].paymentAmount+' '+e[i].code;
                        }
                 return e
            }
            
      
               symbolCommissionCurrency(e){
                let length = Object.keys(e).length
            
                    for(let i=0; i<length; i++){ 
                      e[i].symbolCommissionCurrency = e[i].symbol +' '+e[i].commission+' '+e[i].code;
                        }
                 return e
            }
            
              
  
                 symboltoTransfersCurrency(e){
                  let length = Object.keys(e).length            
                      for(let i=0; i<length; i++){ 
                        e[i].symboltoTransferCurrency = e[i].symbol +' '+e[i].tansferTotal+' '+e[i].code;
                          }                     
                   return e
              }
  
              symbolBalanceTransfersCurrency(e){
                let length = Object.keys(e).length
                    for(let i=0; i<length; i++){ 
                      e[i].symbolBalanceTransferCurrency = e[i].symbol +' '+e[i].tansferBalance+' '+e[i].code;
                        }                      
                 return e
            }

            showProvider(e) {
              let provider
              e.map((transfers) => {
                if (typeof transfers.provider == 'string') {
                  provider = transfers.provider.slice(0, 20)
      
                  // for ( let i in provider){
                    // console.log("PROVIDER: ", i);
      
                    if( provider.length >= 19) {
                      // console.log("PROVIDER: ", provider );
      
                      provider += '...'
      
                    }
      
                    transfers.provider = provider 
                  // }
      
                }
      
                return transfers
              })
            }
  
            showInvoiceName(e) {
              let invoiceName
              e.map((transfers) => {
                if (typeof transfers.invoiceName == 'string') {
                  invoiceName = transfers.invoiceName.slice(0, 20)
      
                  // for ( let i in provider){
                    // console.log("PROVIDER: ", i);
      
                    if( invoiceName.length >= 19) {
                      // console.log("PROVIDER: ", provider );
      
                      invoiceName += '...'
      
                    }
      
                    transfers.invoiceName = invoiceName 
                  // }
      
                }
      
                return transfers
              })
            }
           
               
            formatCurrency = (value) => {
              if(value===0 || value===null){  
                return null
              }else{
              return value.toLocaleString('en-US', {mode:"currency", maxFractionDigits:3, minFractionDigits:3});
              }
          }   


          getTransfers = async () =>{
       
            try {
               
                let res = axios.get(`${url}/api/auth/transfer/${this.props.transfer}`)  
                .then(res => {
                  const transfer = res.data;

                  this.symboltoTransfersCurrency(transfer)
                  this.symbolBalanceTransfersCurrency(transfer)
                  this.symbolCommissionCurrency(transfer)

                  console.log("SHOW TRANSFER EN EDIT:", transfer[0])
                  let showTransfer = transfer[0]
                  this.setState({ showTransfer})
                });                     
                  console.log(res.data)
                }catch(error){
           }
          }  


          getAccounts = async () =>{
       
            try {
               
                let res = axios.get(`${url}/api/auth/accounts`)
                .then(res => {
                  const accounts = res.data;
                  this.setState({ accounts})
                });                     
                  console.log(res.data)
                }catch(error){
           }
          }  


          getMethods = async () =>{
    
              try {
                
                  let res = axios.get(`${url}/api/auth/methods`)
                  .then(res => {
                    const methods = res.data;
                    this.setState({ methods})
                  });                     
                    console.log(res.data)
        
                  }catch(error){
            }
          }
        
          getExchangeOffices = async () =>{
               
            try {
               
                let res = axios.get(`${url}/api/auth/exchangeOffices`)
                .then(res => {
                  const exchangeOffices = res.data;
                  console.log("EXCHANGE OFFICES:", exchangeOffices)
                  this.setState({ exchangeOffices})
                });                     
                  console.log(res.data)
                }catch(error){
           }
          } 
                  
          
        
                          
       
        
                            symbolPaymentsCurrency(e){
                              let length = Object.keys(e).length
                            
                                  for(let i=0; i<length; i++){ 
                                    e[i].symbolAmountCurrency = e[i].symbol +' '+e[i].amount+' '+e[i].code;
                                      }                                    
                              return e
                          }
        
        
            getCurrencies = async () =>{
              
              try {
                
                  let res = axios.get(`${url}/api/auth/currencies`)
                  .then(res => {
                    const currencies = res.data;
                    this.setState({ currencies})
                  });                     
                    console.log(res.data)
                  }catch(error){
            }
            }    
        
        
            getMethods = async () =>{
            
              try {
                
                  let res = axios.get(`${url}/api/auth/methods`)
                  .then(res => {
                    const methods = res.data;
                    this.setState({ methods})
                  });                     
                    console.log(res.data)
        
                  }catch(error){
            }
            }


     

          async onAccountChange(e) { 

            let account = await this.getAccount(e)
            console.log("ACCOUNT EN ACCOUNT CHANGE:", account)
        
            let paymentTransfer = {...this.state.paymentTransfer};
            paymentTransfer['originAccount'] = e.value;
            paymentTransfer['code']=account.codeCurrency
            this.setState({ selectedAccount: e.value,paymentTransfer}); 
        
          }
        
        
          onMethodChange(e) {
              
            let paymentTransfer = {...this.state.paymentTransfer};
        
            paymentTransfer['fk_id_paymentTransferMethod'] = e.value;
            this.setState({ selectedMethod: e.value,paymentTransfer});
          }
        
          onExchangeOfficeChange(e){
            let paymentTransfer = {...this.state.paymentTransfer};
            console.log("SELECT EXCHANGE OFFICE:",e.value)
        
            paymentTransfer['currencyExchangeOffice'] = e.value;
            this.setState({ selectedExchangeOffice: e.value,paymentTransfer});
        
          }
        
        
          onDateChange(e,name) {  
        
            const val =  this.Date(e.target.value) || ''
            const DateOE = this.DateToOE(e.target.value) || ''
            let paymentTransfer = {...this.state.paymentTransfer}
        
            this.getExchangeRates(paymentTransfer,DateOE)
        
            paymentTransfer[`${name}`] = val;
           
            this.setState({ paymentTransfer });
        
        
          }
           

      async deletePaymentTransfer(){
        let data = {...this.state.paymentTransfer};
        console.log("DATA EN DELETE:",data)

        try {
       await axios.delete(`${url}/api/auth/paymentTransfer/${data.id}`)
          .then(res => {
          console.log(res)
          this.setState({
            data,
            deletePaymentTransferDialog: false,
            paymentTransfer: this.emptyPaymentTransfer
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Patment Deleted', life: 3000 });
        })      
        
          }catch(error){
          } 
          this.getInitialData()
      }      

  
      confirmDeletePaymentTransfer(paymentTransfer) {
        this.setState({
            paymentTransfer,
            deletePaymentTransferDialog: true
        })
      }

      amountNrate=()=>{
        let InputAmountView=[] 
    
    
        let balance = {...this.state.balances}  
        let paymentTransfer = {...this.state.paymentTransfer}  
        
        console.log("BALANCE EN AMOUNT n RATE:",balance)
                  InputAmountView.push(
                    <>
                        <div className="form-group row">  
                          <div className="col-lg-3">
                          <span className="p-float-label">  
                            <InputText
                              style={{ width: 190, height:33 }}
                              id="amount"
                              variant="outlined"
                              margin="dense"
                               value={this.formatCurrency(paymentTransfer.paymentAmount)}   
                              onChange={(e) => this.onAmountChange(e)}
                              //required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.amount  })}
                              />
                              <label htmlFor="amount">Payment Transfer</label>
                          </span>
                          {/*<small id="payment_date-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxPaymentDate(this.state)}</small>*/}
                          </div>
    
                          <div className="col-lg-3"> 
                                  <span className="p-float-label">  
                                      <InputText
                                      style={{ width: 70, height:33 }}
                                      id="rate"
                                      variant="outlined"
                                      margin="dense"
                                      value={paymentTransfer.rate}   
                                      onChange={(e) => this.onRateChange(e)}
                                      //required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.amount  })}
                                      /> {balance.code} 
                                      <label htmlFor="rate">Rate</label>
                                  </span>  
                               </div>  
                               {/*<label style={{ color: 'gray' }}>suggested: {this.formatCurrency(paymentTransfer.paymentTotal*paymentTransfer.rate)}</label>*/}
                          </div>
    
    
                          <div className="form-group row">  
                          <div className="col-lg-3">  
                                <span className="p-float-label">   
                                  <Dropdown
                                    style={{ width: 190, height:33 }}
                                    id="exchangeOffice_name"
                                    variant="outlined"
                                    margin="dense"
                                    value= { this.state.paymentTransfer.exchangeOffice_name} 
                                    options={this.state.exchangeOffices} 
                                    onChange={this.onExchangeOfficeChange}  
                                    optionLabel="exchangeOffice_name" 
                                    optionValue="id"    
                                    />
                                    <label htmlFor="exchangeOffice_name">Exchange Office</label>
                                </span>
                                {/*<small id="method_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxMethodName(this.state)}</small>*/}
                                </div>
                            </div>    
                    </>
                  )
        return InputAmountView
      }
    


      openNew(transfer) {
        let paymentTransfer = {...this.state.paymentTransfer}
        this.getInvoices(transfer)
        this.getInvoiceItems(transfer)
        this.getBalance(transfer)
        this.getPayments(transfer)
        this.getCommissionTypes()
  
        paymentTransfer['fk_id_transfer_id']= transfer.id
       
        this.setState({
          transfer:{ ...transfer },
          paymentTransfer,
            selectedAccount: null, 
            selectedMethod: null, 
            submitted: false,
            paymentTransferDialog: true,   
  
          
        })
        this.getAccounts()
        this.getMethods()
        this.getExchangeOffices()
  
      }


      editPaymentTransfer(paymentTransfer) {

  
          this.setState({
              paymentTransfer: { ...paymentTransfer },
              selectedAccount: null, 
              selectedMethod: null, 
              paymentTransferDialog: true,
          })
          this.getTransfers()
          this.getAccounts()
          this.getMethods()
          this.getExchangeOffices()
      }

    
  

     

      hideDeletePaymentTransferDialog() {
        this.setState({ deletePaymentTransferDialog: false })
      }


           
        exportCSV() {
          this.dt.exportCSV();
      }

   
    actionBodyTemplate(rowData) {
                return(
                    <>
                        <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeletePaymentTransfer(rowData)} />
                        <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}}  onClick={() => this.editPaymentTransfer(rowData)} />
                    </>
                )
    }

   


      render(){

        

        const paymentTransferDialogFooter = (
          <>
              <FormattedMessage id="GENERAL.CANCEL">
                {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideTransferDialog} />}
              </FormattedMessage>

              <FormattedMessage id="GENERAL.SAVE">
                {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveTransferPayment} />}
              </FormattedMessage>
          </>
         );
       
       const deletePaymentTransferDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeletePaymentTransferDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deletePaymentTransfer} />
        </>
       );

       

       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
           <h3 className="card-label" >Payment Transfers
                    {/* <FormattedMessage id="ADMIN_INVOICE.TITLE"/>*/}
			    </h3>  
          <br></br>
          <br></br>
          </div>       
           <div>
           {/*SHOW PAYMENT TRANSFERS */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.transferPayments}
                        emptyMessage="No payments found." loading={this.state.loading}
                        selection={this.state.selectedPayments} onSelectionChange={e => this.setState({ selectedPayments: e.value })} dataKey="id">
                        <Column field="paymentDate" header='Payment Date'></Column>
                        <Column field="account_name" header='Account'></Column>
                        <Column field="symbolTransferPaymentCurrency" header='Amount'></Column>
                        <Column field="rate" header='Rate'></Column>
                        <Column field="exchangePayment" header="Equivalent"></Column>
                        <Column field="currencyExchangeOffice" header="Office Exchange"></Column>
                        {/*<Column body={this.actionBodyTemplate}></Column>*/}
                    </DataTable>
                </div>
           </div>
          <div> 

          {/*DELETE PAYMENT */}
          <Dialog visible={this.state.deletePaymentTransferDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentTransferDialogFooter} onHide={this.hideDeletePaymentTransferDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {<span> Are you sure you want to delete this payment?</span>}
                    </div>
          </Dialog>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
           <h3 className="card-label" >Transfer Balance
                    {/* <FormattedMessage id="ADMIN_INVOICE.TITLE"/>*/}
			    </h3>  
          <br></br>
          <br></br>
          </div>       
           <div>
           {/*SHOW  BALANCE TRANSFER*/}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.transfers}
                        loading={this.state.loading}>
                        <Column field="symboltoTransferCurrency" header='Transfer Total'></Column>
                        <Column field="symbolCommissionCurrency" header='My Commission'></Column>
                        <Column field="symbolBalanceTransferCurrency" header='Balance'></Column>
                    </DataTable>
                </div>
           </div>
           

           <br></br>
           <br></br>
        <Tooltip title="New Transfer Payment" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
       </Tooltip>


       <div>
          {/*UPDATE & CREATE PAYMENT TRANSFER*/}
          <Dialog visible={this.state.paymentTransferDialog} contentStyle={{maxHeight:"850px", width:"900px", height:"800px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_PAYMENT"/>} modal footer={paymentTransferDialogFooter} onHide={this.hideDialog}>
          <h6 className="card-label" >Invoice: {this.state.transfer.provider} - {this.state.transfer.invoiceName}
                    {/*<FormattedMessage id="ADMIN_INVOICE.TITLE"/>*/}
                  </h6>
                  <br></br>
                  <br></br>
                  <div className="form-group row">
                          <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                          style={{ width: 190, height:33 }}
                          id="commmission"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symbolCommissionCurrency}             
                          />
                          <label htmlFor="commission">My Commission</label>
                      </span>
                      </div>   

                      <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                          style={{ width: 190, height:33 }}
                          id="toTransfer"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symboltoTransferCurrency}             
                          />
                          <label htmlFor="toTransfer">To Transfer</label>
                      </span>
                      </div>   

                      <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                          style={{ width: 190, height:33 }}
                          id="baalance"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symbolBalanceTransferCurrency}             
                          />
                          <label htmlFor="balance">Balance</label>
                      </span>
                      </div>   
              </div>
              <br></br>
              <br></br>

              <div className="form-group row">
              <div className="col-lg-6">  
              <span className="p-float-label">   
                <Dropdown
                  style={{ width: 190, height:33 }}
                  id="account_name"
                  variant="outlined"
                  placeholder="Account"
                  margin="dense"
                  value= { this.state.selectedAccount } 
                  options={this.state.accounts} 
                  onChange={this.onAccountChange}  
                  optionLabel="account_name" 
                  optionValue="id"    
                  />
                  <label htmlFor="account_name">Account</label>
              </span>
              {/*<small id="account_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxRoleType(this.state)}</small>*/}
              </div>  
              </div> 
              <div className="form-group row">
              <div className="col-lg-3">
              <span className="p-float-label">  
                <Calendar 
                style={{ width: 190, height:33 }}
                showIcon
                margin="dense"
                variant="outlined"
                readOnlyInput="true" 
                dateFormat="dd/mm/yy" 
                id="payment_date" 
                placeholder="Payment Date"
                value={this.state.paymentTransfer.paymentDate} 
                onChange={(e) => this.onDateChange(e, 'paymentDate')} />
              <label htmlFor="payment_date">Payment Date</label>
              </span>
              {/*<small id="amount-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxAmount(this.state)}</small>*/}
              </div>

              <div className="col-lg-3">  
              <span className="p-float-label">   
                <Dropdown
                  style={{ width: 190, height:33 }}
                  id="method_name"
                  variant="outlined"
                  placeholder="Payment Method"
                  margin="dense"
                  value= { this.state.selectedMethod } 
                  options={this.state.methods} 
                  onChange={this.onMethodChange}  
                  optionLabel="method_name" 
                  optionValue="id"    
                  />
                  <label htmlFor="method_name">Payment Method</label>
              </span>
              {/*<small id="method_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxMethodName(this.state)}</small>*/}
              </div>
              </div>
              {this.amountNrate()}
   
              </Dialog>
              </div>
   </div>          
    )
  }
}
const mapStateToProps = state => ({
  transfer: state.auth.transferId,
  user: state.auth.user
});
export default connect(mapStateToProps,auth.actions)(PaymentTransfersTable);