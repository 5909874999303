import React, { Component } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import AddIcon from '@material-ui/icons/Add';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect }  from "react-router-dom";
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";

import axios from 'axios'
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL'
import { connect } from 'react-redux';
import { Avatar } from '@material-ui/core';


const url = ServerURL();

class ListCourses extends Component {

  constructor(props) {
    super(props);
    this.state = {
      layout: 'grid',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      courses: null,
      types: null,
      resetType: "Type",
      selectedType: null,
      dialog: false,
      btnDisable: false,
      title: "",
      displayConfirmation: false,
      changeToTemplate: false,
      idCourseSelect: false,
    };

    this.sortOptions = [
      {label: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.COURSES_PUBLISHED'}), value: '!published'},
      {label: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.DRAFTS'}), value: 'published'},
    ];

    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onHideC = this.onHideC.bind(this);
    this.changePage = this.changePage.bind(this);

  }

  componentDidMount() {
    this.getCourses()
    this.getProgramsTypes()
  }

  onClick(name, idCourseSelect) {
    let state = {
      [`${name}`]: true,
      idCourseSelect
    };

    this.setState(state);
  }

  onHide(){
    this.setState({
      dialog: false,
      selectedType: "Type"
    })
  }

  onHideC(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  onOpen(){
    this.setState({
      dialog: true
    })
  }

  onTypeChange(event) {
    this.setState({selectedType: event.target.value})
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value
      });
    }
    else {
      this.setState({
        sortOrder: 1,
        sortField: value,
        sortKey: value
      });
    }
  }
  
  changePage(dataCourse) {
    console.log(this.props)
    console.log(dataCourse)
    this.props.updatedCourse(dataCourse)
    this.setState({redirect: true})
  }

  Date(e){                 
    e.map((course) => {
    //console.log("E:",agreement.updated_at)
    const date = new Date(course.created_at);

     let year = date.getFullYear();
     let month = date.getMonth()+1;
     let dt = date.getDate();

     if (dt < 10) {
       dt = '0' + dt;
     }
     if (month < 10) {
       month = '0' + month;
     }

     let new_date = dt +'/' + month + '/'+year
     //console.log("New Data:",new_date)

     course.created_at = new_date
     return course

   });
  }
                                                                                                                                                  
  getCourses = async () =>{
    try {
      await axios.get(`${url}/api/auth/course/${this.props.user.fk_id_enterprise}`)
      .then(res => {
        const courses = res.data;
        console.log("Antes:", courses);
        this.Date(courses);
        console.log("Despues:", courses);

        this.setState({ courses })
      });
      }catch(error){
    }
  }
                                       
  deleteCourse = async () =>{
    console.log(this.state.idCourseSelect)
    try {
      await axios.delete(`${url}/api/auth/course/${this.state.idCourseSelect}`)
      .then(res => {     
        this.setState({ displayConfirmation: false })
        this.getCourses()
      });
      }catch(error){
    }                                                             
  }        
  templateCourse = async () =>{
    try {
      await axios.put(`${url}/api/auth/templateCourse/${this.state.idCourseSelect}`)
      .then(res => {     
        this.setState({ changeToTemplate: false })
        this.getCourses()
      });
      }catch(error){
    }                                                             
  }  
  

  getProgramsTypes = async () =>{                                      
    try {
      await axios.get(`${url}/api/auth/program_types`)
      .then(res => {     
        const newArrayProgramTypes = [];
        res.data.forEach(program_type => {
          if(program_type.id !== 9){
            newArrayProgramTypes.push(program_type);
          };
        });
        this.setState({ types: newArrayProgramTypes })
      });
      }catch(error){
    }
  }

  async saveCourse(){
    let data = {
      fk_id_enterprise: this.props.user.fk_id_enterprise,
      created: this.props.user.fullname,
      modified: this.props.user.fullname,
      fk_id_program_type: this.state.selectedType,
      course: this.state.title
    }
    if(this.state.selectedType && this.state.title){
      console.log(data)
      try {
        await axios.post(`${url}/api/auth/course`, data)
        .then(res => {
          const { message, message_type } = res.data;

          if(message_type === 'success'){
            this.setState({ dialog: false })
            this.getCourses()
          }

          let type = null;
          if(message_type === 'success'){
            this.setState({ dialog: false, title: "" })
            this.getCourses()
            type = "GENERAL.SUCCESSFUL"
          }else{
            type = "GENERAL_MESSAGE.ERROR"
          }

          this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 3000 });

        });
        }catch(error){
      }
    }else{
      this.toast.show({ severity: 'info', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.ERROR'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LIST.MISSING_DATA'}), life: 3000 });
    }
  }




  textResponsive(text){
    if(text.length >=39){
      return(<div style={{fontSize:"1rem", fontWeight: 700}}>{text}</div>)
    }else{
      return(<div style={{fontSize:"auto", fontWeight: 700}}>{text}</div>)
    }
  }
                        
  renderButtonTemplate(data){
    if(data.resultEvaluation===0  &&  data.published===1){  
      return(
       <Button icon="pi pi-undo" className="p-button-success" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.onClick('changeToTemplate', data.id)}/>
      )
    }
  }                                           

   renderListItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} />
          <div className="course-list-detail">
          {this.textResponsive(data.course)}
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
            <span className="course-category"><FormattedMessage id="GENERAL_MESSAGE.LAST_MODIFICATION"/>: {data.updated_at.substring(0,9)}</span>
          </div>
          <div className="course-list-action">
            <span className="course-price">{data.program_type}</span>
            <div>
              {/* <Link className="menu-link" to={{
                pathname:"/get-courses/course",
                aboutProps:{
                    data
                }
              }}>
                <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} />
              </Link> */}
              <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.changePage(data)}/>
              <Button icon="pi pi-trash" className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onClick('displayConfirmation', data.id)} />
              {this.renderButtonTemplate(data)}
            </div>
            {
              data.published === 0 ?
              <span className="p-tag course-badge text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
              :
              <span className="p-tag course-badge text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
            }
          </div>
        </div>
      </div>
    );
  }

  renderGridItem(data) {
    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card">
          <div className="mb-3">
            <Avatar alt={`${data.enterprise_name} Logo`} src={data.logo} imgProps={{style:{ height:"40px", margin: 0 }}}>{data.enterprise_name[0]}</Avatar>
          </div>
          <div className="course-grid-item-top">
            <div>
              {
                data.published === 0 ?
                <span className="p-tag course-category text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
                :
                <span className="p-tag course-category text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
              }
            </div>
            <span className={`course-badge`}>{data.program_type}</span>
          </div>
          <div className="course-grid-item-content">
            <img style={{width: "100%"}} src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            {this.textResponsive(data.course)}
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
          <div className="course-grid-item-bottom">
            {/* <Link className="menu-link" to={{
                pathname:"/get-courses/course",
                aboutProps:{
                    data
                }
            }}>
              <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}}/>
            </Link> */}
            <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
            <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.onClick('displayConfirmation', data.id)} />
            {this.renderButtonTemplate(data)}
          </div>
        </div>
      </div>
    );
  }

  itemTemplate(course, layout) {
    if (!course) {
      return (<></>);
    }

    if (layout === 'list')
      return this.renderListItem(course);
    else if (layout === 'grid')
      return this.renderGridItem(course);
  }

  renderHeader() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-6" style={{textAlign: 'left'}}>
          <FormattedMessage id="GENERAL_MESSAGE.SELECT_SORT">
            {message => <Dropdown options={this.sortOptions} value={this.state.sortKey} optionLabel="label" placeholder={message} onChange={this.onSortChange}/>}
          </FormattedMessage>
        </div>
        <div className="p-col-6" style={{textAlign: 'right'}}>
          <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value })} />
        </div>
      </div>
    );
  }

  renderFooter(name) {
      return(
        <div>
        <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
        {(message) => <Button label={message} icon="pi pi-check" onClick={() => this.deleteCourse(name)} />}
        </FormattedMessage>
      </div>
      )
    }

  renderFooterTemplate(name) {
    return(
      <div>
      <FormattedMessage id="GENERAL.NO">
      {(message) => <Button label={message} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>}
      </FormattedMessage>
      <FormattedMessage id="GENERAL.YES">
      {(message) => <Button label={message} icon="pi pi-check" onClick={() => this.templateCourse(name)} />}
      </FormattedMessage>
      </div>
    )  
  }



  render() {

    if(this.state.redirect) {
      return <Redirect to={{
        pathname: "/get-courses/course",
      }}/>;
    }

    const header = this.renderHeader();
    const footer = (
      <div>
        <FormattedMessage id="GENERAL.YES">
        {(message) => <Button disabled={this.state.btnDisable} label={message} icon="pi pi-check" onClick={this.saveCourse} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" onClick={this.onHide} />}
        </FormattedMessage>
      </div>
    );

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <button className="m-3 btn btn-primary" onClick={this.onOpen}><AddIcon/> <FormattedMessage id="GENERAL_MESSAGE.ADD_COURSE"/></button>
        <FormattedMessage id="GENERAL_MESSAGE.COMPLETE_INFORMATION">
          {message => (
            <Dialog header={message} footer={footer} visible={this.state.dialog} onHide={this.onHide}>
              <div className="p-grid" style={{marginTop: "4em", marginBottom: "7em"}}>
                <div className="p-col">
                  <span className="p-float-label">
                    <InputText id="title" value={this.state.title} maxlength="39" onChange={(e) => this.setState({title: e.target.value})} />
                    <label htmlhtmlfor="title"><FormattedMessage id="GENERAL_MESSAGE.COURSE_TITLE"/></label>
                  </span>
                </div>
                <div className="p-col">
                  <span className="p-float-label">   
                    <Dropdown
                      style={{ width: 190, height:33 }}
                      id="type"
                      variant="outlined"
                      placeholder= { this.state.resetType}
                      margin="dense"
                      value={this.state.selectedType || "Type"}   
                      options ={this.state.types} 
                      onChange={this.onTypeChange} 
                      optionLabel="program_type" 
                      optionValue="id"
                      
                      />
                        <label htmlFor="type"><FormattedMessage id="GENERAL_MESSAGE.COURSE_TYPE"/></label>
                  </span>
                </div>
              </div>
            </Dialog>
          )}
        </FormattedMessage>
        <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
          {
            (message) => (
              <Dialog header={message} visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter('displayConfirmation')} onHide={() => this.onHideC('displayConfirmation')}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_DELETE_COURSE"/></span>
                </div>
              </Dialog>
            )
          }
        </FormattedMessage>

        <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
          {
            (message) => (
              <Dialog header={message} visible={this.state.changeToTemplate} modal style={{ width: '350px' }} footer={this.renderFooterTemplate('changeToTemplate')} onHide={() => this.onHideC('changeToTemplate')}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_TEMPLATE_COURSE"/></span>
                </div>
              </Dialog>                                                                                                                                                         
            )
          }
        </FormattedMessage>
        <div className="dataview">
          <div className="card">
            {
              this.state.courses ?
              <DataView value={this.state.courses} layout={this.state.layout} header={header}
                itemTemplate={this.itemTemplate} rows={12}
                sortOrder={this.state.sortOrder} sortField={this.state.sortField} emptyMessage={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.NO_COURSES_CREATED'})}
                paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
              :
              <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(ListCourses)
)