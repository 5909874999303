import React, { Component } from 'react'

import axios from 'axios'
import classNames from 'classnames';

import ServerURL from '../../../ServerURL'

import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable'
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import OpenExchangeURL from '../../../OpenExchangeURL'
import OpenExchangeAPI from '../../../OpenExchangeAPI'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

//import { Tag } from 'primereact/tag';

import 'primeflex/primeflex.css';

import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
//import * as Validation from '../../../Validation';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Avatar, InputText } from 'primereact';
// import { number } from 'prop-types';
// import { forEach } from 'lodash';

const url = ServerURL();
const urlOE = OpenExchangeURL();
const apiOE = OpenExchangeAPI();

class AgentsPaymentsTable extends Component {
  emptyBalance = {
    invoiceId: '',
    itemId: '',
    total: '',
    fk_id_currency: '',
    item_name: '',
  }

  emptyAccount = {
    account_name: '',
    fk_id_currency: '',
    code: '',
    description: '',
  }

  emptyPayment = {
    invoiceId: '',
    externalId: '',
    fk_id_balance_item: [],
    rates: [],
    fk_id_account: '',
    amount: 0,
    amount_payable: 0,
    payment_date: '',
    payment_date_show: '',
    fk_id_payment_method: '',
    payment_file_url: '',
    payment_file: null,
    note: '',
    status: 0
  }

  constructor(props) {
    super(props)

    this.state = {

      payment: this.emptyPayment,
      payments: [],
      approvedPayments: [],
      invoices: [],
      paymentItems: [],
      balance: this.emptyBalance,
      balances: [],
      positive: [],
      checked: false,
      someChecked: 0,
      totalPayments: 0,

      /*account: this.emptyAccount,
      accounts:null,*/

      payment_file: null,
      globalFilter: null,

      paymentDialog: false,
      paymentDialogItem: false,
      deletePaymentDialog: false,
      deletePaymentsDialog: false,
      actionInvoiceDialog: false,
      actionCloseInvoiceDialog: false,
      actionOpenInvoiceDialog: false,

      selectedPayments: null,
      selectedAccount: null,
      resetAccount: null,
      selectedMethod: null,
      resetMethod: null,
      CheckedApprove: false,
      edit: null,
      readOnly: false,

      redirect: false,

      submitted: false,
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate(),
      account_nameMessage: '',
      payment_dateMessage: '',
      method_nameMessage: '',
      fk_id_balance_itemMessage: '',
      ratesMessage: '',
      amountMessage: '',

      expandedRows: null
    }

    this.actionBodyTemplatePending = this.actionBodyTemplatePending.bind(this);
    this.actionBodyTemplateApproved = this.actionBodyTemplateApproved.bind(this);
    //this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

    this.changeStatusInvoice = this.changeStatusInvoice.bind(this);
    this.deletePayment = this.deletePayment.bind(this);
    this.openNew = this.openNew.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.confirmDeletePayment = this.confirmDeletePayment.bind(this);
    this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
    this.confirmChangeStatusCloseInvoice = this.confirmChangeStatusCloseInvoice.bind(this);
    this.confirmChangeStatusOpenInvoice = this.confirmChangeStatusOpenInvoice.bind(this);
    this.deleteSelectedPayments = this.deleteSelectedPayments.bind(this);
    this.hideActionInvoiceDialog = this.hideActionInvoiceDialog.bind(this);
    this.hideActionCloseInvoiceDialog = this.hideActionCloseInvoiceDialog.bind(this);
    this.hideActionOpenInvoiceDialog = this.hideActionOpenInvoiceDialog.bind(this);
    this.hideDeletePaymentDialog = this.hideDeletePaymentDialog.bind(this);
    this.hideDeletePaymentsDialog = this.hideDeletePaymentsDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);

    this.Check = this.Check.bind(this);
    this.nameInCheck = this.nameInCheck.bind(this);
    this.readOnlyItem = this.readOnlyItem.bind(this);
    this.styleItem = this.styleItem.bind(this);
    this.InputRate = this.InputRate.bind(this);
    this.onBalanceChange = this.onBalanceChange.bind(this);
    this.getExchangeRates = this.getExchangeRates.bind(this);
    this.getExchangeRateToday = this.getExchangeRateToday.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.onMethodChange = this.onMethodChange.bind(this);
    this.onRateChange = this.onRateChange.bind(this);
    this.savePayment = this.savePayment.bind(this);
    this.fileBodyTemplate = this.fileBodyTemplate.bind(this);
    this.onApproveChange = this.onApproveChange.bind(this);
    this.positiveBalance = this.positiveBalance.bind(this);
    this.historyTotal = this.historyTotal.bind(this)
    this.newPayment = this.newPayment.bind(this);
    this.actionInvoice = this.actionInvoice.bind(this);
    this.exportPdf = this.exportPdf.bind(this);

    // this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this)
    // this.onRowExpand = this.onRowExpand.bind(this)
    // this.onRowCollapse = this.onRowCollapse.bind(this)

    this.colsPayments = [
      { field: 'id', header: 'ID' },
      { field: 'note', header: 'NOTE' },
      { field: 'payment_date', header: 'DATE' },
      { field: 'amountCurrencyFormat', header: 'AMOUNT' },
      // { field: 'balance_items_names', header: 'PAID ITEMS' },
      // { field: 'ratesCurrency', header: 'TC' }
    ];

    this.colsBalance = [
      { field: 'item_name', header: 'INVOICE NAME' },
      { field: 'totalCurrency', header: 'TOTAL' },
      { field: 'paidItemCurrency', header: 'PAID' },
      // { field: 'paidDefaultCurrency', header: 'PAID CLIENT' },
      { field: 'balanceCurrency', header: 'TO PAY' },
      // { field: 'balanceTC', header: 'TC' }
    ];

    this.colsHistory = [
      { field: 'item_name', header: 'INVOICE NAME' },
      {field: 'payment_date', header: 'PAYMENT DATE'},
      // { field: 'symbolAmountCurrency', header: 'TOTAL' },
      { field: 'symbolAmountCurrency', header: 'PAID' },
      // { field: 'paidDefaultCurrency', header: 'PAID CLIENT' },
      // { field: 'balanceCurrency', header: 'TO PAY' },
      { field: 'rate', header: 'TC' },
      { field: 'symbolEquivalentCurrency', header: 'EQUIVALENT'}
    ]

    this.exportPayments = this.colsPayments.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportBalance = this.colsBalance.map(col => ({ title: col.header, dataKey: col.field }));
    this.exportHistory = this.colsHistory.map(col => ({ title: col.header, dataKey: col.field }));

    // this.exportPaymentItems = this.



  }

  componentDidMount() {
    this.getInitialData()
  }

  isValid() {
    let { fk_id_account, payment_date, payment_date_show, fk_id_payment_method, fk_id_balance_item, rates, amount } = this.state.payment
    const account_nameMessage = this.validateAccount_Name()
    const payment_dateMessage = this.validatePayment_Date()
    // const payment_date_showMessage = this.validatePayment_Date()
    const method_nameMessage = this.validateMethod_Name()
    const fk_id_balance_itemMessage = this.validateFk_Id_Balance_Item()
    const ratesMessage = this.validateRates()
    const amountMessage = this.validateAmount()

    payment_date = payment_date_show
    // console.log("PAYMENTS SEND: ", fk_id_account, payment_date, fk_id_payment_method, fk_id_balance_item, rates, amount );



    if (fk_id_account && payment_date && fk_id_payment_method && fk_id_balance_item && rates && amount) {
      // console.log("PAYMENTS SEND: ", fk_id_account, payment_date, fk_id_payment_method, fk_id_balance_item, rates, amount );

      if (!account_nameMessage && !payment_dateMessage && !method_nameMessage && !fk_id_balance_itemMessage && !ratesMessage && !amountMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  validateAccount_Name() {
    const payment = this.state.payment

    let account_nameMessage
    if (!payment.fk_id_account) {
      account_nameMessage = 'Requerido'
    } else {
      account_nameMessage = ''
    }
    this.setState({ account_nameMessage })
    return account_nameMessage
  }

  getTextAuxAccount_Name() {
    const message = this.state.account_nameMessage
    return message
  }

  validatePayment_Date() {
    const payment = this.state.payment

    // console.log('PAYMENT: ', payment);

    payment.payment_date = payment.payment_date_show

    // console.log("paymenmt date: ", payment.payment_date);
    // console.log("paymenmt dat show: ", payment.payment_date_show);


    let payment_dateMessage

    if (!payment.payment_date || !payment.payment_date_show) {
      payment_dateMessage = 'Requerido'
    } else {
      payment_dateMessage = ''
    }

    this.setState({ payment_dateMessage })
    return payment_dateMessage
  }

  getTextAuxPayment_Date() {
    const message = this.state.payment_dateMessage
    return message
  }

  validateMethod_Name() {
    const payment = this.state.payment

    let method_nameMessage
    if (!payment.fk_id_payment_method) {
      method_nameMessage = 'Requerido'
    } else {
      method_nameMessage = ''
    }
    this.setState({ method_nameMessage })
    return method_nameMessage
  }

  getTextAuxMethod_Name() {
    const message = this.state.method_nameMessage
    return message
  }

  validateFk_Id_Balance_Item() {
    const payment = this.state.payment

    let fk_id_balance_itemMessage
    if (!payment.fk_id_balance_item[0]) {
      fk_id_balance_itemMessage = 'Requerido'
    } else {
      fk_id_balance_itemMessage = ''
    }
    this.setState({ fk_id_balance_itemMessage })
    return fk_id_balance_itemMessage
  }

  getTextAuxFK_Id_Balance_Item() {
    const message = this.state.fk_id_balance_itemMessage
    return message
  }

  validateRates() {
    const payment = this.state.payment

    let ratesMessage
    if (!payment.rates) {
      ratesMessage = 'Requerido'
    } else {
      ratesMessage = ''
    }
    this.setState({ ratesMessage })
    return ratesMessage
  }

  getTextAuxRates() {
    const message = this.state.ratesMessage
    return message
  }

  validateAmount() {
    const payment = this.state.payment

    let amountMessage
    if (!payment.amount) {
      amountMessage = 'Requerido'
    } else {
      amountMessage = ''
    }
    this.setState({ amountMessage })
    return amountMessage
  }

  getTextAuxAmount() {
    const message = this.state.amountMessage
    return message
  }


  getInitialData = async () => {
    // let invoice = {...this.state.invoices}
    let data = {
      date: null
    }
    data.date = this.Today()

    let requestOne = await axios.get(`${url}/api/auth/balance/${this.props.invoice}`)
    let requestTwo = await axios.get(`${url}/api/auth/showPaymentItems/${this.props.invoice}`)
    let requestThree = await axios.get(`${url}/api/auth/invoice/${this.props.invoice}`)
    let requestFour = await axios.get(`${url}/api/auth/pendingPayments/${this.props.invoice}`)
    let requestFive = await axios.post(`${url}/api/auth/invoiceItems/${this.props.invoice}`)
    let requestSix = await axios.get(`${url}/api/auth/approvedPayments/${this.props.invoice}`)
    let requestSeven = await axios.post(`${url}/api/auth/getExchangeRates`, data)
    // let requestSeven = axios.get(`${url}/api/auth/showPaymentItems/${this.props.invoice}`)



    axios
      .all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix, requestSeven])
      .then(
        axios.spread((...responses) => {
          const balances = responses[0].data;
          const paymentItems = responses[1].data;
          const invoices = responses[2].data;
          const pendingPayments = responses[3].data;
          const invoiceItems = responses[4].data;
          const approvedPayments = responses[5].data;
          const positiveExchanges = responses[6].data;

          this.DateInvoice(invoices)

          this.symbolPaymentsCurrency(pendingPayments)
          this.symbolPaymentsCurrency(approvedPayments)
          this.symbolPaymentsCurrencyFormat(approvedPayments)
          this.paymentsCurrencyFormat(approvedPayments)
          this.totalPositiveBalance(paymentItems, invoices)
          this.paymentItemsCurrencyFormat(paymentItems, balances, positiveExchanges)
          this.paymentsTotalCurrencyFormat(approvedPayments)

          this.symbolHistoryEquivalentCurrency(balances, paymentItems)

          // this.totalApprovedItems( approvedPayments, balances)
          // this.totalPendingPayments(pendingPayments)

          this.symbolTotalCurrency(balances)
          this.symbolBalanceCurrency(balances)
          this.totalCurrency(balances)
          this.balanceCurrency(balances)
          this.paidDefaultCurrency(balances)
          this.paidItemCurrency(balances)
          this.balanceExchange(balances, positiveExchanges)

          this.symbolPaidCurrency(invoices)
          // this.approvedRates(approvedPayments)
          this.approvedPayments(approvedPayments, balances)
          this.pendingPayments(pendingPayments, balances)
          // this.dispersion(approvedPayments, balances, positiveExchanges)
          // console.log("BALANCE TO PAID:", balances)
          // console.log("PAYMENTS TO BE APPROVED:", pendingPayments)
          // console.log("APPROVED PAYMENTS:", approvedPayments)
          // console.log("PAYMENTS ITEMS:", paymentItems)
          // console.log("BALANCE TO PAID:", balances)
          // console.log("BALANCE TO PAID:", balances)
          // console.log('POSITIVE EXCHANGES: ', positiveExchanges);


          this.setState({
            balances,
            paymentItems,
            pendingPayments,
            approvedPayments,
            invoiceItems,
            invoices,
            positiveExchanges
          })

        })
      )
      .catch(errors => {
        console.error(errors);
      });
  }

  // getPaymentItems = async (externalId) => {
  //   console.log('EXTERNAL ID: ', externalId);
  //   axios.get(`${url}/api/auth/paymentItems/${externalId}`)
  //   .then(res => {
  //     const paymentItems = res.data

  //     // this.setState({ paymentItems })

  //     this.getInitialData()

  //     console.log(paymentItems);
  //   })
  // }

  getExchangeRateToday = async () => {

    let data = {
      date: null
    }
    let exchangeRates
    data.date = this.Today()


    try {
      await axios.post(`${url}/api/auth/getExchangeRates`, data)
        .then(res => {
          exchangeRates = res.data;
        });
    } catch (error) {
    }


    return exchangeRates
  }



  Today() {
    let date = new Date()
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    if (d < 10) {
      d = '0' + d;
    }
    if (m < 10) {
      m = '0' + m;
    }
    let today = d + '/' + m + '/' + y

    return today
  }

  DateInvoice(e) {
    e.map((invoice) => {
      const date = new Date(invoice.created_at)

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate()

      if (dt < 10) {
        dt = '0' + dt
      }
      if (month < 10) {
        month = '0' + month
      }

      let new_date = dt + '/' + month + '/' + year

      invoice.created_at = new_date

      return invoice
    })
  }

  balanceExchange(balances, positiveExchanges) {
    // console.log("BALANCE: ", balances);
    // console.log("POSITIVE EXCHANGE: ", positiveExchanges);

    let balance = Object.keys(balances).length
    let exchanges = Object.keys(positiveExchanges).length
    // let dollarUSLocale = Intl.NumberFormat('en-US')

    for (let balanceIndex = 0; balanceIndex < balance; balanceIndex++) {
      // console.log("BALANCE: ", balances[balanceIndex]);


      for (let exchangesIndex = 0; exchangesIndex < exchanges; exchangesIndex++) {
        // console.log("POSITIVE EXCHANGES: ", positiveExchanges);

        if (balances[balanceIndex].fk_id_currency === positiveExchanges[exchangesIndex].fk_id_currency) {
          // console.log("BALANCE: ", balances[balanceIndex].item_name);
          balances[balanceIndex].balanceTC = /* balances[balanceIndex].symbol +  */'$ ' + positiveExchanges[exchangesIndex].rate + ' MXN'
        }
      }
    }
    return balances
  }

  // approvedRates(approvedPayments) {
  //   console.log("Approved payments: ", approvedPayments);
  //   let payments = Object.keys(approvedPayments).length
  //   let dollarUSLocale = Intl.NumberFormat('en-US')

  //   for ( let i = 0; i < payments; i++) {
  //     if ( typeof approvedPayments[i].rates === 'string') {
  //       console.log("ENTRO ");
  //       approvedPayments[i].ratesCurrencies = approvedPayments[i].rates.split(',')
  //       approvedPayments[i].ratesCurrencies = approvedPayments[i].ratesCurrencies.map(Number)

  //       let ratesCurrency = []

  //       approvedPayments[i].ratesCurrencies.forEach(payment => {
  //         payment = dollarUSLocale.format(payment)
  //         payment = approvedPayments[i].symbol + ' ' + payment + ' ' + approvedPayments[i].code

  //         ratesCurrency.push(<>
  //           {payment} <br/>
  //         </> )
  //       });

  //       approvedPayments[i].ratesCurrency = (
  //         <p> 
  //           {ratesCurrency}
  //         </p>
  //       )
  //     }
  //   }

  //   return approvedPayments
  // }

  approvedPayments(approvedPayments, balances) {
    let payments = Object.keys(approvedPayments).length
    let balance = Object.keys(balances).length
    // let dollarUSLocale = Intl.NumberFormat('en-US')

    for (let i = 0; i < payments; i++) {
      if (typeof approvedPayments[i].fk_id_balance_item === 'string') {
        // console.log('ENTRO');
        approvedPayments[i].balance_items = approvedPayments[i].fk_id_balance_item.split(',')
        approvedPayments[i].balance_items = approvedPayments[i].balance_items.map(Number)

        let balance_items_name = []

        approvedPayments[i].balance_items.forEach(balance_item => {
          // console.log("BALANCE: ", balance_item);
          for (let j = 0; j < balance; j++) {
            if (balance_item === balances[j].id) {
              // console.log("ENTRO ", balances[j].item_name);
              balance_items_name.push(
                <>
                  {balances[j].item_name} <br />
                </>
              )

              // console.log("ITEM NAME: ", balance_items_name);
            }
          }
        })

        approvedPayments[i].balance_items_names = (
          <p>
            {balance_items_name}
          </p>
        )
      }
    }

    return approvedPayments
  }

  pendingPayments(pendingPayments, balances) {
    let payments = Object.keys(pendingPayments).length
    let balance = Object.keys(balances).length
    // let dollarUSLocale = Intl.NumberFormat('en-US')

    for (let i = 0; i < payments; i++) {
      if (typeof pendingPayments[i].fk_id_balance_item === 'string') {
        // console.log('ENTRO');
        pendingPayments[i].balance_items = pendingPayments[i].fk_id_balance_item.split(',')
        pendingPayments[i].balance_items = pendingPayments[i].balance_items.map(Number)

        let balance_items_name = []

        pendingPayments[i].balance_items.forEach(balance_item => {
          // console.log("BALANCE: ", balance_item);
          for (let j = 0; j < balance; j++) {
            if (balance_item === balances[j].id) {
              // console.log("ENTRO ", balances[j].item_name);
              balance_items_name.push(
                <>
                  {balances[j].item_name} <br />
                </>
              )

              // console.log("ITEM NAME: ", balance_items_name);
            }
          }
        })

        pendingPayments[i].balance_items_names = (
          <p>
            {balance_items_name}
          </p>
        )
      }
    }

    return pendingPayments
  }

  // dispersion (approvedPayments, balance, positiveExchanges) {
  //   console.log("BALANCE: ", balance);

  //   let amount
  //   let auxBalance
  //   let negativeBalance

  //   for ( let i in approvedPayments ) {
  //     amount  = approvedPayments[i].amount

  //     console.log("AMOUNT: ", amount);

  //     for (let j in approvedPayments[i].balance_items ) {
  //       console.log("J", approvedPayments[i].balance_items[j]);


  //     }

  //   }

  //   // console.log("CONVERT: ", total_convert);
  //   return approvedPayments
  // }

  // approvedCurrencies(balances, positiveExchanges) {
  //   let payments = Object.keys(approvedPayments).length
  //   let balance = Object.keys
  // }

  symbolBalanceCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      e[i].symbolbalanceFormat = dollarUSLocale.format(e[i].balance)
      if (e[i].balance < 0) {
        e[i].symbolBalanceCurrency = e[i].symbol + ' ' + 0 + ' ' + e[i].code;
      } else {
        e[i].symbolBalanceCurrency = e[i].symbol + ' ' + e[i].symbolbalanceFormat + ' ' + e[i].code;
      }
    }
    return e
  }

  symbolHistoryEquivalentCurrency(e, f) {
    let lengthHistory = Object.keys(f).length
    let lengthBalances = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    
    for (let i = 0; i < lengthHistory; i++) {
      // const element = lengthHistory]

      let equivalent

      equivalent = f[i].amountItem / f[i].rate
      console.log('EQUIVALENT: ', equivalent);

      for (let j = 0; j < lengthBalances; j++) {
        if (f[i].fk_id_balance_item === e[j].id){
          // console.log('HISTORY BALANCE ITEM: ', f[i].item_name);
          // console.log('BALANCE ITEM: ', f[i].item_name);

          f[i].equivalentFormat = (dollarUSLocale.format(equivalent))
          console.log('EQUIVALENT FORMAT: ', f[i].equivalentFormat);

          f[i].symbolEquivalentCurrency = e[j].symbol + ' ' + f[i].equivalentFormat + ' ' + e[j].code
          f[i].code = e[j].code
          // f[i].symbolEquivalentCurrencyFormat = 
        }
      }
    }

    console.log('Balances: ', e);
    console.log('History: ', f);
    return f
  }

  paymentItemsCurrencyFormat( e, f, g) {
    let lengthHistory = Object.keys(e).length
    // let lengthBalances = Object.keys(f).length
    // let lengthRates = Object.keys(g).length
    let dollarUSLocale = Intl.NumberFormat('en-US')

    for (let i = 0; i < lengthHistory; i++) {
    
      e[i].symbolAmountCurrency = dollarUSLocale.format(e[i].amountItem) + ' ' + this.props.user.codeCurrency
    }

    return e
  }


  symbolPaymentsCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      e[i].symbolAmountCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].amount) + ' ' + e[i].code;
    }
    return e
  }

  symbolPaymentsCurrencyFormat(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].amountFormat = dollarUSLocale.format(e[i].amount)
      e[i].symbolAmountCurrencyFormat = e[i].symbol + ' ' + e[i].amountFormat + ' ' + e[i].code;
    }
    return e

  }

  paymentsCurrencyFormat(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].amountFormat = dollarUSLocale.format(e[i].amount)
      e[i].amountCurrencyFormat = e[i].amountFormat + ' ' + e[i].code;
    }

    return e
  }

  async paymentsTotalCurrencyFormat(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    let exchangeRates = await this.getExchangeRateToday()
    let totalAmount = 0
    let totalAmountConvert = 0

    for (let i = 0; i < length; i++) {
      if (e[i].code === this.props.user.codeCurrency) {
        totalAmount = totalAmount + e[i].amount
        totalAmountConvert = totalAmountConvert + e[i].amount

        // console.log(totalAmount);
        // console.log(totalAmountConvert);
      } else {
        for (let j = 0; j < length; j++) {
          if (exchangeRates[j].code === e[i].code) {
            totalAmount = totalAmount + (e[i].amount * exchangeRates[j].rate)
            // totalAmountConvert = (e[i].amount / exchangeRates[j].rate)

            // console.log(totalAmount);
          }
        }
      }
    }


    // console.log("TOTAL AMOUNT  CURRENCY FORMAT:", totalAmount)
    // console.log("TOTAL AMOUNT CONVERT CURRENCY FORMAT:", totalAmountConvert)

    // this.totalPositiveBalance(totalAmount)

    for (let i = 0; i < length; i++) {
      e[i].totalAmountFormat = dollarUSLocale.format(totalAmount)
      e[i].totalAmountCurrencyFormat = e[i].totalAmountFormat + ' ' + this.props.user.codeCurrency;
      // console.log("TOTAL AMOUNT CURRENCY FORMAT:", e[i].totalAmountCurrencyFormat)
      // console.log("TOTAL AMOUNT CONVERT CURRENCY FORMAT:", totalAmountConvert)
    }
    return e
  }

  async totalPositiveBalance(e, f) {
    // let historyPayments = this.state.paymentItems
    // console.log("F: ", f );

    // let exchangeRates = await this.getExchangeRateToday()
    // let balance = this.state.balances
    // let invoice = {...this.state.invoices}

    // console.log("BALANCE: ", balance);
    // console.log("EXCHANGES RATES: ", exchangeRates);
    // console.log("INVOICES: ", invoice[0].code);

    // console.log("Total amount: ", totalAmount);
    let totalAmountConvert = 0
    let rateConvert = 0
    // let balanceConvert = 0
    // console.log("History Payments: ", historyPayments);

    // for ( let i = 0; i < balance.length; i++) {
    //   // console.log("BALANCE: ", balance[i].code);
    //   for ( let j = 0; j < exchangeRates.length; j++ ){
    //     // console.log('EXCHANGE RATE: ', exchangeRates[j]);

    //     if ( exchangeRates[j].code === balance[i].code) {
    //       // console.log("BALANCE: ", balance[i].code);
    //       // console.log('EXCHANGE RATE: ', exchangeRates[j]);
    //     }
    //   }
    // }

    for (let i = 0; i < e.length; i++) {
      // console.log("History Payment amount: ", historyPayments[i].amountItem);
      // console.log("History Payment rate: ", historyPayments[i].rate);
      // console.log(`History Payment convert de : ${i}`, (historyPayments[i].amountItem / historyPayments[i].rate).toFixed(2));

      // totalAmountConvert = totalAmountConvert + (historyPayments[j].amountItem / historyPayments[j].rate)
      // console.log('TOTAL AMOUNT CONVERT: ', totalAmountConvert.toFixed(2));

      if (f[0].currencyId === e[i].fk_id_currency) {
        totalAmountConvert = totalAmountConvert + (e[i].amountItem / e[i].rate)
        // console.log("TOTAL AMOUNT CONVERT: ", totalAmountConvert);
      }

      if (i === e.length - 1) {
        // console.log("History Payments: ", historyPayments[i]);
        // totalAmountConvert = totalAmount / historyPayments[i].rate
        rateConvert = e[i].rate

      }

      // e[i].totalAmountConvert = totalAmountConvert
      // e[i].rateConvert = rateConvert
    }

    for (let i = 0; i < e.length; i++) {
      e[i].totalAmountConvert = totalAmountConvert
      e[i].rateConvert = rateConvert

    }

    // f[0].totalHistory = totalAmountConvert
    // f[0].totalRate = rateConvert

    // console.log('TOTAL AMOUNT CONVERT: ', totalAmountConvert);

    // let balance = {...this.state.balances}

    // for (let i in balance ) {
    //   balance[i].totalAmountConvert = totalAmountConvert
    // }

    // this.positiveBalance(totalAmountConvert, rateConvert)

    // console.log("INVOICE: ", invoice[0]);

    return e
  }

  symbolTotalCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symboltotalFormat = dollarUSLocale.format(e[i].total)
      e[i].symbolTotalCurrency = e[i].symbol + ' ' + e[i].symboltotalFormat + ' ' + e[i].code;
    }
    return e
  }

  totalCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].totalFormat = dollarUSLocale.format(e[i].total)
      e[i].totalCurrency = e[i].totalFormat + ' ' + e[i].code;
    }
    return e
  }


  balanceCurrency(e) {

    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      e[i].balanceFormat = dollarUSLocale.format(e[i].balance)
      e[i].balanceCurrency = e[i].balanceFormat + ' ' + e[i].code;
    }
    return e

  }

  async paidDefaultCurrency(e) {
    let length = Object.keys(e).length
    let exchangeRates = await this.getExchangeRateToday()
    let dollarUSLocale = Intl.NumberFormat('en-US');


    for (let j = 0; j < Object.keys(exchangeRates).length; j++) {
      for (let i = 0; i < length; i++) {
        if (exchangeRates[j].code === e[i].code) {
          e[i].paidDefault = (e[i].paidItem * exchangeRates[j].rate).toFixed(2)
          e[i].paidDefaultFormat = dollarUSLocale.format(e[i].paidDefault)
          e[i].paidDefaultCurrency = e[i].paidDefaultFormat + ' ' + this.props.user.codeCurrency
        }
      }
    }

    return e

  }

  paidItemCurrency(e) {

    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      e[i].paidItemFormat = dollarUSLocale.format(e[i].paidItem)
      e[i].paidItemCurrency = e[i].paidItemFormat + ' ' + e[i].code;
    }
    return e

  }

  symbolPaidCurrency(e) {

    e.map((invoiceItems) => {

      invoiceItems.symbolPaidCurrency = invoiceItems.symbol + ' ' + invoiceItems.paid + ' ' + invoiceItems.code;

      return invoiceItems

    });
  }

  getAccounts = async () => {

    try {

      let res = axios.get(`${url}/api/auth/accounts`)
        .then(res => {
          const accounts = res.data;
          this.setState({ accounts })
        });
      // console.log(res.data)
    } catch (error) {
    }
  }

  getCurrencies = async () => {

    try {

      let res = axios.get(`${url}/api/auth/currencies`)
        .then(res => {
          const currencies = res.data;
          this.setState({ currencies })
        });
      // console.log(res.data)
    } catch (error) {
    }
  }

  getMethods = async () => {

    try {

      let res = axios.get(`${url}/api/auth/methods`)
        .then(res => {
          const methods = res.data;
          this.setState({ methods })
        });
      // console.log(res.data)

    } catch (error) {
    }
  }

  savePayment = async () => {
    let state = { submitted: true };
    let invoice = { ...this.state.invoices }
    const decision = this.isValid();
    // console.log("ENTRO: ");

    if (decision) {
      // console.log("ENTRO A DECISION");

      let data = {
        ...this.state.payment,
        externalId: invoice[0].externalId
      };

      // console.log("DATA ANTES DE SAVE:", data)

      if (!this.state.payment.id) {
        let year = data.payment_date_show.getFullYear();
        let month = data.payment_date_show.getMonth() + 1;
        let day = data.payment_date_show.getDate();

        // console.log('YEAR', year);
        // console.log('MONTH', month);
        // console.log('DAY', day);


        if (day < 10) {
          day = '0' + day;
        }
        if (month < 10) {
          month = '0' + month;
        }

        let new_date = `${day}/${month}/${year}`

        data.payment_date = new_date
      } else {
        // console.log("PAYMENT DATE: ", data.payment_date);
      }


      // console.log("DATA EN SAVE:", data)

      const formData = new FormData();

      const filePayment = this.uploadInput.getFiles()

      if (filePayment.length !== 0) {
        // console.log('FILE: ', filePayment[0]);
        formData.append('payment_file', filePayment[0]);
      } else {
        // console.log('NO HAY ARCHIVO');
      }


      formData.append('invoiceId', data.invoiceId);
      formData.append('externalId', data.externalId)
      formData.append('fk_id_balance_item', data.fk_id_balance_item);
      formData.append('rates', data.rates);
      formData.append('fk_id_account', data.fk_id_account);
      formData.append('amount', data.amount);
      formData.append('payment_date', data.payment_date);
      formData.append('fk_id_payment_method', data.fk_id_payment_method);
      formData.append('note', data.note);
      formData.append('status', data.status);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      if (this.state.payment.id) {
        try {
          await axios.put(`${url}/api/auth/payment/${data.id}`, formData, config)
            .then(res => {
              // if(data.status === 1) {
              //   this.getPaymentItems(data.externalId)
              // }

              this.getInitialData()
            })
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Updated', life: 3000 });
        } catch (error) {
        }
      } else {
        try {
          await axios.post(`${url}/api/auth/payment`, formData, config)
            .then(res => {
              // if(data.status === 1) {
              //   this.getPaymentItems(data.externalId)
              // }
              this.getInitialData()
            })
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Created', life: 3000 });
        } catch (error) {
        }
      }

      state = {
        ...state,
        data,
        payment: this.emptyPayment,
        balance: this.emptyBalance,
        account: this.emptyAccount,
        selectedAcccount: null,
        resetAccount: null,
        selectedMethod: null,
        resetMethod: null,
        paymentDialog: false,
      };
    }
    // console.log("Salto");
    this.setState(state);
  }

  async changeStatusInvoice(e) {
    let invoices = { ...this.state.invoices };
    let invoiceId
    let data = {
      statusBalance: null
    }

    for (let i in invoices) {
      invoiceId = invoices[i].id
      data.statusBalance = e
    }

    try {
      await axios.put(`${url}/api/auth/invoiceChangeStatus/${invoiceId}`, data)
        .then(res => {
          // console.log(res)
          this.getInitialData()

          this.setState({
            invoices,
            actionCloseInvoiceDialog: false,
            actionOpenInvoiceDialog: false,
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice updated', life: 3000 });
        })
    } catch (error) {

    }
  }

  async deletePayment() {
    let data = { ...this.state.payment };

    // console.log("DATA: ", data);

    try {
      await axios.delete(`${url}/api/auth/payment/${data.id}`)
        .then(res => {
          console.log(res)
          this.getInitialData()
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Patment Deleted', life: 3000 });
        })

    } catch (error) {
    }

    this.setState({
      data,
      deletePaymentDialog: false,
      payment: this.emptyPayment
    });

  }

  confirmChangeStatusCloseInvoice() {
    this.setState({
      actionCloseInvoiceDialog: true,
    })
  }

  confirmChangeStatusOpenInvoice() {
    this.setState({
      actionOpenInvoiceDialog: true,
    })
  }

  confirmDeletePayment(payment) {
    this.setState({
      payment,
      deletePaymentDialog: true
    })
  }

  confirmDeleteSelected() {
    this.setState({ deletePaymentsDialog: true })
  }

  deleteSelectedPayments() {
    let pendingPayments = this.state.pendingPayments.filter(val => !this.state.selectedPayments.includes(val));
    let data = this.state.pendingPayments.filter(val => this.state.selectedPayments.includes(val));
    //console.log("delete payments:",data)

    try {
      axios.delete(`${url}/api/auth/payments`, { data })
        .then(res => {
          this.setState({
            pendingPayments,
            deletePaymentsDialog: false,
            selectedPayments: null
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payments Deleted', life: 3000 });
          this.getInitialData()

        })

    } catch (error) {
    }
    this.getInitialData()
  }

  hideActionInvoiceDialog() {
    this.setState({ actionInvoiceDialog: false })
  }

  hideActionCloseInvoiceDialog() {
    this.setState({ actionCloseInvoiceDialog: false })
  }
  hideActionOpenInvoiceDialog() {
    this.setState({ actionOpenInvoiceDialog: false })
  }

  hideDeletePaymentDialog() {
    this.setState({ deletePaymentDialog: false })
  }

  hideDeletePaymentsDialog() {
    this.setState({ deletePaymentsDialog: false });
  }

  exportCSV() {
    this.dt.exportCSV();
  }

  editPayment(payment) {
    this.getMethods()
    this.getAccounts()

    console.log("PAYMENT EDIT:",payment)

    if (typeof payment.payment_date === 'string') {
      // console.log("PAYMENT DATE IS STRING");
      // console.log(`PAYMENT DATE STRING: ${payment.payment_date}`)

      let [day, month, year] = payment.payment_date.split('/')
      payment.payment_date_show = new Date(+year, +month - 1, +day)

      // console.log(`PAYMENT RESULT: ${payment.payment_date_show}`)
    }

    // console.log('PAYMENT STATUS: ', payment.status);

    this.setState({
      payment: { ...payment },
      selectedAccount: null,
      resetAccount: null,
      selectedMethod: null,
      resetMethod: null,
      paymentDialog: true,
      CheckedApprove: false,
      edit: true,
      readOnly: false,
    })

    // console.log("PAYMENT EDIT:", payment)
  }

  showPayment(payment) {
    this.getMethods()
    this.getAccounts()

    //console.log("PAYMENT EDIT:",payment)

    this.setState({
      payment: { ...payment },
      selectedAccount: null,
      resetAccount: null,
      selectedMethod: null,
      resetMethod: null,
      paymentDialog: true,
      CheckedApprove: false,
      edit: true,
      readOnly: true,


    })
  }

  openNew() {
    this.getAccounts()
    this.getMethods()
    let payment = { ...this.state.payment }

    payment.rates = []
    payment.fk_id_balance_item = []
    payment.invoiceId = ''
    payment.externalId = ''
    payment.fk_id_account = ''
    payment.method_name = ''
    payment.account_name = ''
    payment.amount = 0
    payment.amount_payable = 0
    payment.payment_date = ''
    payment.payment_date_show = ''
    payment.fk_id_payment_method = ''
    payment.payment_file_url = ''
    payment.payment_file = null
    payment.note = ''
    payment.code = ''
    payment.status = 0
    payment.symbol = ''
    payment.id = ''
    payment.idCurrency = ''
    payment.created_at = ''
    payment.symbolAmountCurrency = ''
    payment.updated_at = ''
    payment.newPayment = ''
    payment.fk_id_currency_account = ''


    this.setState({
      payment,
      balance: this.emptyBalance,
      submitted: false,
      selectedAccount: null,
      selectedMethod: null,
      paymentDialog: true,
      CheckedApprove: false,
      edit: false,
    })
  }

  hideDialog() {
    let payment = { ...this.state.payment }

    payment.rates = []
    payment.fk_id_balance_item = []
    payment.invoiceId = ''
    payment.externalId = ''
    payment.fk_id_account = ''
    payment.method_name = ''
    payment.account_name = ''
    payment.amount = 0
    payment.amount_payable = 0
    payment.payment_date = ''
    payment.payment_date_show = ''
    payment.fk_id_payment_method = ''
    payment.payment_file_url = ''
    payment.payment_file = null
    payment.note = ''
    payment.code = ''
    payment.status = 0
    payment.symbol = ''
    payment.id = ''
    payment.idCurrency = ''
    payment.created_at = ''
    payment.symbolAmountCurrency = ''
    payment.symbolAmountCurrencyFormat = ''
    payment.updated_at = ''
    payment.newPayment = ''
    payment.fk_id_currency_account = ''

    this.setState({
      payment,
      payments: null,
      submitted: false,
      paymentDialog: false,
      balance: this.emptyBalance,
      account: this.emptyAccount,

      selectedPayments: null,
      selectedAccount: null,
      resetAccount: null,
      selectedMethod: null,
      resetMethod: null,
      CheckedApprove: false,
      readOnly: false,

      /*
       emailMessage: '',
       passwordMessage: '',
       firstNameMessage: '',
       lastNameMessage: '',
       phoneMessage: '',
       fkIdRoleMessage: '',
       enterpriseMessage:'',     */
    })
    // console.log("payment en hide:", payment)
  }


  hideDeletePaymentItemDialog() {
    this.setState({ deletePaymentItemDialog: false })
  }


  getAccount = async (e) => {
    let accountER
    try {
      await axios.get(`${url}/api/auth/account/${e.value}`)
        .then(res => {
          accountER = res.data;
          // console.log("ACCCOUNT ER:", accountER)
        });
    } catch (error) {
    }
    return accountER
  }


  Date(e) {
    try {
      // console.log("e: ", e);
      let payment = { ...this.state.payment }

      let year = e.getFullYear();
      let month = e.getMonth() + 1;
      let day = e.getDate();

      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }

      let new_date = `${day}/${month}/${year}`

      payment.payment_date = new_date

      // console.log("payment date: ", payment.payment_date);

      this.setState({ payment })

      return payment
    } catch (error) {
      // console.log("ERROR: ", error);
    }
  }


  DateToOE(e) {

    let year = e.getFullYear();
    let month = e.getMonth() + 1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = year + '-' + month + '-' + day

    // console.log("NEW DATE: ", new_date);

    return new_date

  }

  onApproveChange(e) {
    let payment = { ...this.state.payment }
    if (e.checked) {
      payment['status'] = 1
      // console.log("YES:", 1)

    } else {
      payment['status'] = 0
      // console.log("NO:", 0)

    }
    // console.log("STATUS:", e.checked, e.value)
    this.setState({ CheckedApprove: e.checked, payment });

  }


  async onAccountChange(e) {

    let account = await this.getAccount(e)

    let payment = { ...this.state.payment };

    payment['fk_id_account'] = e.value;
    payment['code'] = account.codeCurrency
    payment['fk_id_currency_account'] = account.fk_id_currency
    payment['invoiceId'] = this.props.invoice
    // payment['externalId'] = this.props.

    this.setState({ selectedAccount: e.value, payment });

  }







  getExchangeRates = async (DateT, payment, DateOE) => {
    // console.log("DATE T: ", DateT);
    // console.log("PAYMENT: ", payment);
    // console.log("DATE OE: ", DateOE);

    let balance = { ...this.state.balances }
    let data = {
      date: null,
      fk_id_currency_account: null
    }

    data.date = DateT

    let year = DateT.getFullYear();
    let month = DateT.getMonth() + 1;
    let day = DateT.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = `${day}/${month}/${year}`




    data.date = new_date
    data.fk_id_currency_account = payment.fk_id_currency_account
    if (payment.fk_id_currency_account === this.props.user.fk_id_baseCurrency) {
      try {
        await axios.post(`${url}/api/auth/exchangeRatesByAccount`, data)
          .then(res => {
            // console.log("RES: ", res.data);
            let exchanges = res.data;
            for (let i = 0; i < Object.keys(balance).length; i++) {
              for (let j = 0; j < Object.keys(exchanges).length; j++) {
                if (balance[i].id_currency === exchanges[j].fk_id_currency)
                  balance[i].rate = exchanges[j].rate
              }
            }
            // console.log("EXCHANGES: ", exchanges);
            this.setState({ balance })
          });
      } catch (error) {
      }
    } else {
      try {
        await axios.get(`${urlOE}historical/${DateOE}.json?app_id=${apiOE}&base=${payment.code}`)

          .then(res => {
            let exchanges = res.data;
            for (let i = 0; i < Object.keys(balance).length; i++) {
              for (let j = 0; j < Object.keys(exchanges).length; j++) {
                balance[i].rate = (1 / exchanges.rates[`${balance[i].code}`])
              }
            }
            // console.log("EXCHANGE: ", exchanges);
            this.setState({ balance })
          });
      } catch (error) {
      }


    }
  }


  onDateChange(e, name) {
    // const val =  this.Date(e.target.value) || ''
    let DateT
    let DateOE
    let payment = { ...this.state.payment }

    // payment[`${name}`] = val;
    payment[`${name}`] = e.target.value
    DateT = payment[`${name}`]
    DateOE = this.DateToOE(DateT)

    this.Date(payment[`${name}`])
    this.DateToOE(DateT)

    this.getExchangeRates(DateT, payment, DateOE)

    this.setState({ payment });
  }

  onInputChange(e, name) {
    const val = (e.target && e.target.value) || '';
    let payment = { ...this.state.payment };

    payment[`${name}`] = val;

    this.setState({ payment });
  }

  onAmountChange(e) {
    const val = (e.target && e.target.value) || '';
    //console.log("E:",e)

    let payment = { ...this.state.payment }
    let balance = { ...this.state.balances }
    let difference = 0

    payment['amount'] = val;

    if (payment.amount_payable < payment.amount) {
      difference = difference + (payment.amount - payment.amount_payable)
      // console.log('DIFFERENCE: ', difference);
    }

    for (let i = 0; i < Object.keys(balance).length; i++) {
      balance[i].amount = val;
      balance[i].difference = difference
    }
    this.setState({ payment, balance });
  }

  onRateChange(e, id) {
    const val = (e.target && e.target.value) || '';
    let balance = { ...this.state.balances }
    let payment = { ...this.state.payment }


    if (typeof payment.fk_id_balance_item === 'string') {
      payment.fk_id_balance_item = payment.fk_id_balance_item.split(',')
      payment.fk_id_balance_item = payment.fk_id_balance_item.map(Number);

      payment.rates = payment.rates.split(',')
      payment.rates = payment.rates.map(Number);
    }

    for (let n = 0; n < Object.keys(balance).length; n++) {
      if (balance[n].id === id) {
        for (let o = 0; o < Object.keys(payment.fk_id_balance_item).length; o++) {
          if (payment.fk_id_balance_item[o] === id) {
            payment['amount'] = payment['amount'] - (payment.rates[o] * balance[n].balance)
            balance[n].rate = val
            payment.rates[o] = val
            payment['amount'] = payment['amount'] + (balance[n].rate * balance[n].balance)
            break;
          }
        }
      }
    }
    this.setState({ balance, payment });
  }


  onMethodChange(e) {
    // console.log("E: ", e);
    let payment = { ...this.state.payment };

    payment['fk_id_payment_method'] = e.value;

    this.setState({ selectedMethod: e.value, payment });
  }

  FilePayment() {
    let UploadAndShow = []
    let payment = { ...this.state.payment }

    if (payment.payment_file_url) {

      UploadAndShow.push(
        <>
          {/* <div className="col"> */}
          {/* <div className='form-group row'> */}
          <div className='col-fixed'>
            <FileUpload
              type="file"
              name="payment_file"
              ref={(ref) => { this.uploadInput = ref; }}
              //ref={(el) => this.uploadInput = el} 
              chooseOptions={{
                icon: 'pi pi-fw pi-file',
                className: 'p-button-help p-button-rounded p-button-outlined'
              }}
              multiple={false}
              mode="basic"
              chooseLabel={this.state.campusText.PaymentFile}
            />
            {/* </div> */}
          </div>
          <div className="col-2">
            <a href={`${payment.payment_file_url}`} target="_blank" rel="noopener noreferrer"><Button type="button" className='p-button-help p-button-rounded p-button-outlined' icon="pi pi-download" ></Button></a>
          </div>
          {/* </div> */}
        </>

      )
    } else {

      UploadAndShow.push(
        // <div className='for-group row'>
        <div className='col-lg-3'>
          <FileUpload
            type="file"
            name="payment_file"
            ref={(ref) => { this.uploadInput = ref; }}
            // className='p-fileupload'
            chooseOptions={{
              icon: 'pi pi-fw pi-file',
              className: 'p-button-help p-button-rounded p-button-outlined'
            }}
            // accept='image/*'
            maxFileSize={100000000000000000}
            multiple={false}
            mode="basic"
            chooseLabel={this.state.campusText.PaymentFile} />
        </div>
        // </div>
        // <>
        //     <div className="col">
        //       </div>
        // </>

      )

    }


    return UploadAndShow
  }

  onBalanceChange(e, rate, id) {
    let payment = { ...this.state.payment }
    let balance = { ...this.state.balances }

    // console.log('BALANCE CHANGE: ', balance);

    if (typeof payment.fk_id_balance_item === 'string') {

      payment.fk_id_balance_item = payment.fk_id_balance_item.split(',')
      payment.fk_id_balance_item = payment.fk_id_balance_item.map(Number);

      payment.rates = payment.rates.split(',')
      payment.rates = payment.rates.map(Number);


      if (e.checked) {
        payment['fk_id_balance_item'].push(e.value)
        payment['rates'].push(rate)
        for (let n = 0; n < Object.keys(balance).length; n++) {
          if (balance[n].id === id) {
            payment['amount_payable'] = payment['amount_payable'] + (balance[n].rate * balance[n].balance)
            break;
          }
        }
      } else {
        for (let i = 0; i < payment.fk_id_balance_item.length + 1; i++) {
          if (payment.fk_id_balance_item[i] === e.value) {
            payment.fk_id_balance_item.splice(i, 1);
            break;
          }
        }

        for (let i = 0; i < payment.rates.length + 1; i++) {
          if (payment.rates[i] === rate) {
            payment.rates.splice(i, 1);
            break;
          }
        }

        // for (let i = 0; i < payment.fk_id_currency.length+1; i++) {
        //   if( payment.fk_id_currency[i] === fk_id_currency ) {
        //     payment.fk_id_currency.splice(i, 1)
        //     break;
        //   }
        // }

        for (let n = 0; n < Object.keys(balance).length; n++) {
          if (balance[n].id === id) {
            payment['amount_payable'] = payment['amount_payable'] - (balance[n].rate * balance[n].balance)
            break;
          }
        }
      }
      this.setState({ payment });


    } else {

      if (e.checked) {
        payment['fk_id_balance_item'].push(e.value)
        payment['rates'].push(rate)
        for (let n = 0; n < Object.keys(balance).length; n++) {
          if (balance[n].id === id) {
            payment['amount_payable'] = payment['amount_payable'] + (balance[n].rate * balance[n].balance)
            break;
          }
        }
      } else {
        for (let i = 0; i < payment.fk_id_balance_item.length + 1; i++) {
          if (payment.fk_id_balance_item[i] === e.value) {
            payment.fk_id_balance_item.splice(i, 1);
            break;
          }
        }

        for (let i = 0; i < payment.rates.length + 1; i++) {
          if (payment.rates[i] === rate) {
            payment.rates.splice(i, 1);
            break;
          }
        }
        // for (let i = 0; i < payment.fk_id_currency.length + 1; i++ ) {
        //   if ( payment.fk_id_currency[i] === fk_id_currency ) {
        //     payment.fk_id_currency.splice( i,1 )
        //     break;
        //   }
        // }
        for (let n = 0; n < Object.keys(balance).length; n++) {
          if (balance[n].id === id) {
            payment['amount_payable'] = payment['amount_payable'] - (balance[n].rate * balance[n].balance)
            break;
          }
        }

      }
      this.setState({ payment });
    }
  }




  Check(val) {
    let a = { ...this.state.payment };

    // console.log('CHECK: ', a  );

    if (typeof a.fk_id_balance_item === 'string') {

      a.fk_id_balance_item = a.fk_id_balance_item.split(',')
      a.fk_id_balance_item = a.fk_id_balance_item.map(Number);

      return a.fk_id_balance_item.some(arrVal => val === arrVal);

    } else {
      return a.fk_id_balance_item.some(arrVal => val === arrVal);
    }
  }

  nameInCheck(val) {
    let b = { ...this.state.balances };
    // console.log("VAL:", val)
    for (let i = 0; i < Object.keys(b).length; i++) {
      if (b[i].id === val) {
        return b[i].item_name
      }
    }
  }

  readOnlyItem(val) {
    let b = { ...this.state.balances };
    // console.log("VAL:", val)

    for (let i = 0; i < Object.keys(b).length; i++) {
      if (b[i].id === val && b[i].status === 1) {
        return true
      }
    }
  }

  styleItem(val) {
    let b = { ...this.state.balances };
    // console.log("VAL:", val)

    for (let i = 0; i < Object.keys(b).length; i++) {
      if (b[i].id === val && b[i].status === 1) {
        return ({ textDecorationLine: 'line-through', textDecorationStyle: 'solid' })
      }
    }
  }

  InputRate(val, i) {
    let payment = { ...this.state.payment }
    let balance = { ...this.state.balances }

    // console.log("PAYMENT:", payment)
    // //console.log("BALANCES:",b)
    // console.log("VAL:", val)
    // console.log("I:", i)

    if (balance[i].rate) {
      // console.log("BALANCE IN INPUT CHANGE:", balance[i])
      return balance[i].rate

    } else {
      if (typeof payment.fk_id_balance_item === 'string') {
        payment.fk_id_balance_item = payment.fk_id_balance_item.split(',')
        payment.fk_id_balance_item = payment.fk_id_balance_item.map(Number);

        payment.rates = payment.rates.split(',')
        payment.rates = payment.rates.map(Number);

        if (payment['fk_id_balance_item'].length > 1) {

          if (payment['fk_id_balance_item'].some(arrVal => val === arrVal)) {
            // console.log(" RATES 1:", payment.rates)
            return payment.rates[i]
          }
        } else {
          if (payment['fk_id_balance_item'].some(arrVal => val === arrVal)) {
            // console.log(" RATES 1:", payment.rates)
            return payment.rates
          }
        }

      } else {


        if (payment['fk_id_balance_item'].length > 1) {

          if (payment['fk_id_balance_item'].some(arrVal => val === arrVal)) {
            // console.log("RATES 2:", payment.rates)
            return payment.rates[i]
          }
        } else {
          if (payment['fk_id_balance_item'].some(arrVal => val === arrVal)) {
            // console.log("RATES 2:", payment.rates)
            return payment.rates
          }
        }
      }
    }
    //this.setState({payment,balance})
  }

  balance = () => {
    let balanceView = []
    let InputAmountView = []
    let Order = []

    let balance = { ...this.state.balances }
    let payment = { ...this.state.payment }
    if (this.state.edit === true) {
      if (typeof payment.fk_id_balance_item === 'string') {
        payment.fk_id_balance_item = payment.fk_id_balance_item.split(',')
        payment.fk_id_balance_item = payment.fk_id_balance_item.map(Number);

        payment.rates = payment.rates.split(',')
        payment.rates = payment.rates.map(Number);
      }

      for (let i = 0; i < Object.keys(payment.fk_id_balance_item).length; i++) {
        if (payment.fk_id_account && payment.payment_date) {
          balanceView.push(
            <>
              {/* Balance */}
              <div className="grid p-fluid">
                <div className="col">
                  <div className='p-inputgroup'>
                    <span className='p-inputgroup-addon'>
                      <Checkbox
                        style={{ width: 20, height: 20 }}
                        variant="outlined"
                        margin="dense"
                        inputid="id"
                        disabled="true"
                        name="balance"
                        value={payment.fk_id_balance_item[i]}
                        onChange={(e) => this.onBalanceChange(e, payment.rates[i], payment.fk_id_balance_item[i])}
                        checked={this.Check(payment.fk_id_balance_item[i])}
                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.fk_id_balance_item })}
                      />
                    </span>
                    <InputText placeholder={balance[i].item_name} disabled />
                    <span className='p-inputgroup-addon'>{balance[i].code}</span>
                    <small id="fk_id_balance_item-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFK_Id_Balance_Item(this.state)}</small>

                  </div>
                </div>

                <div className='col-fixed'>
                  <div className='p-inputgroup'>
                    <span className='p-inputgroup-addon'>$</span>
                    <InputNumber
                      locale='en-US'
                      minFractionDigits={2}
                      min='0'
                      id='rate'
                      style={{ width: 60 }}
                      value={payment.rates[i]}
                      disabled={!this.Check(payment.fk_id_balance_item[i])}
                      required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.rates })}
                    />
                    <span className='p-inputgroup-addon'>{balance[i].code}</span>
                  </div>
                </div>
              </div>
              <br />
            </>
          )
        }
      }
    } else if (this.state.edit === false) {

      for (let i = 0; i < Object.keys(balance).length; i++) {
        if (payment.fk_id_account && payment.payment_date_show) {
          balanceView.push(
            <>
              {/* Balance */}
              <div className="grid p-fluid">
                <div className='col'>
                  <div className="p-inputgroup">
                    <span className='p-inputgroup-addon'>
                      <Checkbox
                        style={{ width: 20, height: 20 }}
                        variant="outlined"
                        readOnly={this.readOnlyItem(balance[i].id)}
                        margin="dense"
                        inputid="id"
                        name="balance"
                        value={balance[i].id}
                        onChange={(e) => this.onBalanceChange(e, balance[i].rate, balance[i].id)}
                        checked={this.Check(balance[i].id)}
                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.fk_id_balance_item })}
                      />
                    </span>
                    <InputText placeholder={balance[i].item_name} disabled />
                  </div>
                </div>

                <div className='col-fixed'>
                  <div className='p-inputgroup'>
                    <span className='p-inputgroup-addon'>$</span>
                    <InputNumber
                      locale='en-US' style={{ width: 60 }}
                      minFractionDigits={2}
                      min='0'
                      id='rate'
                      value={balance[i].rate}
                      disabled={!this.Check(balance[i].id)}
                      onValueChange={(e) => this.onRateChange(e, balance[i].id)}
                      required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.rates })}
                    />
                    <span className='p-inputgroup-addon'>{balance[i].code}</span>

                  </div>
                </div>
              </div>
              <br />
              {/* <Avatar label={balance[i].item_name} onClick={(e) => this.setState({ click: true})} /> */}

            </>
          )
        }

      }
    }
    if (payment.amount) {
      InputAmountView.push(
        <>
          <div className="grid p-fluid">
            <div className="col-lg-3">
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>$</span>
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  // style={{ width: 190, height:33 }}
                  id="amount"
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={payment.amount_payable}
                  onValueChange={(e) => this.onAmountChange(e)}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.amount })}
                />
                <small id="payment_date-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAmount(this.state)}</small>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>$</span>
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  style={{ width: 60 }}
                  id="amount"
                  variant="outlined"
                  margin="dense"
                  value={payment.amount}
                  onValueChange={(e) => this.onAmountChange(e)}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.amount })}
                />
                <span className='p-inputgroup-addon' htmlFor="amount">{this.state.campusText.AmountPayable}</span>
                <small id="payment_date-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAmount(this.state)}</small>
              </div>
            </div>

            {this.actionBodyApproved()}
          </div>
          <br />
        </>
      )
    } else {
      //payment.amount=totalAmount
      InputAmountView.push(
        <>
          <div className="grid p-fluid">
            <div className="col-lg-4">
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>$</span>
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  style={{ width: 60, height: 33 }}
                  id="amount"
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={(payment.amount_payable).toFixed(2)}
                  onValueChange={(e) => this.onAmountChange(e)}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.amount })}
                />

                {/* <span className="p-float-label">   */}

                <span className='p-inputgroup-addon' htmlFor="amount">Amount to paid</span>
                {/* </span> */}
                <small id="payment_date-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAmount(this.state)}</small>
              </div>
            </div>

            <div className="col-lg-4">
              {/* <div className='p-inputgroup'> */}
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>$</span>
                {/* <span className="p-float-label">   */}
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  style={{ width: 60, height: 33 }}
                  id="amount"
                  variant="outlined"
                  margin="dense"
                  value={payment.amount}
                  onValueChange={(e) => this.onAmountChange(e)}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.amount })}
                />
                <span className='p-inputgroup-addon' htmlFor="amount">{this.state.campusText.AmountPayable}</span>
                {/* </span> */}
                <small id="payment_date-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAmount(this.state)}</small>
              </div>
              {/* </div> */}
            </div>

            {/* <div className='col-lg-3'> */}
            {this.actionBodyApproved()}
            {/* </div> */}
          </div>
          <br />
        </>
      )
    }

    Order[0] = (balanceView)
    Order[1] = (InputAmountView)
    return Order
  }

  newPayment() {
    let balances = { ...this.state.balances }
    let invoiceStatus = 0

    for (let i in balances) {
      invoiceStatus = balances[i].statusInvoice
    }

    if (invoiceStatus === 0) {

      return (
        <>
          {/* <div style={{ marginRight: 10}}> */}
          <FormattedMessage id="OPTION.NEW_PAYMENT">
            {(message) => <Button tooltip={message} tooltipOptions={{ position: 'bottom' }} type='button' className="p-button-rounded p-button-outlined mr-2" icon="pi pi-plus" variant='contained' color='primary' onClick={this.openNew} />}
          </FormattedMessage>
          {/* </div> */}
        </>
      )
    }
  }


  actionInvoice() {
    let invoice = { ...this.state.invoices }
    // console.log("INVOICE EN ACTION INVOICE:", invoice)
    let invoiceStatus
    // let action =[];

    for (let i in invoice) {
      invoiceStatus = invoice[i].statusBalance
    }


    if (invoiceStatus === 0 && this.props.user.role === "Director") {
      // action.push(
      return (
        <>
          {/* <div style={{ marginRight: 10}}> */}
          <FormattedMessage id="OPTION.CLOSE_INVOICE">
            {
              (message) =>
                <Button
                  tooltip={message}
                  tooltipOptions={{ position: 'bottom' }}
                  type="button"
                  onClick={this.confirmChangeStatusCloseInvoice}
                  variant='contained'
                  icon='pi pi-times'
                  className="p-button-danger p-button-rounded p-button-outlined" />
            }
          </FormattedMessage>
          {/* </div> */}
        </>
      )
      // )
    } else if (invoiceStatus === 1 && this.props.user.role === "Director") {
      // action.push(
      return (
        <>
          {/* <div style={{ marginRight: 10}}> */}
          <FormattedMessage id="OPTION.OPEN_INVOICE">
            {
              (message) =>
                <Button style={{ alignContent: 'flex-end' }}
                  tooltip={message}
                  tooltipOptions={{ position: 'bottom' }}
                  type="button"
                  onClick={this.confirmChangeStatusOpenInvoice}
                  variant='contained'
                  icon='pi pi-check'
                  className="p-button-success p-button-rounded p-button-outlined" />
            }
          </FormattedMessage>
          {/* </div> */}
        </>
      )
      // )
    }

    // return action
  }



  fileBodyTemplate(rowData) {
    if (rowData.payment_file_url !== null) {
      return (
        <>
          <a href={`${rowData.payment_file_url}`} target="_blank" rel="noopener noreferrer">
            <Button
              className='p-button-rounded p-button-outlined p-button-help'
              type="button"
              icon="pi pi-download"
              style={{ verticalAlign: 'middle', width: "30px", height: "30px", }}>
            </Button>
          </a>
        </>
      )
    }
  }


  actionBodyApproved = () => {
    let payment = { ...this.state.payment }
    let invoice = { ...this.state.invoices }
    let validateInvoice

    for (let i in invoice) {
      validateInvoice = invoice[i].validateInvoice
    }

    let showApproved = []

    // console.log(payment);
    // console.log(payment.status);

    if (this.props.user.role === "Director") {
      if (validateInvoice === 1) {
        if (payment.status === 0) {
          showApproved.push(
            // <div className="form-group row">
            // <div className="col-lg-2">
            //   <div className="p-float-label" 
            //   style={{ width: 190, height:33, fontSize:18, verticalAlign: 'middle',   margin: "-5px", color:'green' }}  
            //   inputid="approvePayment">{this.state.campusText.PaymentApproved}</div>
            // </div>
            <div className="col-lg-3">
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>
                  <Checkbox
                    inputId="approvePayment"
                    margin="dense"
                    value={payment.status}
                    checked={this.state.CheckedApprove}
                    onChange={this.onApproveChange}
                  />
                </span>
                <InputText disabled placeholder='Payment not approved' />
              </div>
            </div>
          )
        }

        else if (payment.status === 1) {
          // console.log("ENTRO AQUI");
          showApproved.push(
            <div className="col-lg-3">
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>
                  <Checkbox
                    inputId="approvePayment"
                    margin="dense"
                    value={payment.status}
                    checked={this.state.CheckedApprove}
                    onChange={this.onApproveChange}
                  />
                </span>
                <InputText disabled placeholder={this.state.campusText.PaymentApproved} />
              </div>
            </div>
          )
        }
      } else {
        showApproved.push(
          <></>
        )
      }

    }
    else if (payment.status === 1) {
      showApproved.push(
        <div className="col-lg-3">
          <div className='p-inputgroup'>
            <span className='p-inputgroup-addon'>
              <Checkbox
                inputId="approvePayment"
                margin="dense"
                value={payment.status}
                checked={this.state.CheckedApprove}
                onChange={this.onApproveChange}
              />
            </span>
            <InputText disabled placeholder={this.state.campusText.PaymentApproved} />
          </div>
        </div>
      )
    }
    return showApproved
  }


  showStudent = () => {
    let invoice = { ...this.state.invoices }
    let name

    for (let i in invoice) {
      name = invoice[i].student
    }

    let showStudent = []
    showStudent.push(
      <div>
        <label>Student: </label>
        <span className='text-3xl text-900'>{name}</span>
      </div>



      //   <div className="form-group row">
      //     <div className="col-lg-12">
      //       <h3>{name}</h3>            
      //     </div>
      // </div>
    )

    return showStudent
  }



  actionBodyTemplatePending(rowData) {
    return (
      <>
        <Button tooltip='Edit payment' tooltipOptions={{ position: 'top' }} icon="pi pi-pencil" className='p-button-rounded p-button-outlined' color='primary' style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.editPayment(rowData)} />
        <Button tooltip='Delete payment' tooltipOptions={{ position: 'top' }} icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-danger" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.confirmDeletePayment(rowData)} />
      </>
    )
  }

  actionBodyTemplateApproved(rowData) {

    if (this.props.user.role === "Director" || this.props.user.role === "Gerente") {
      return (
        <>
          <Button icon="pi pi-pencil" color='primary'
            className='p-button-rounded p-button-outlined'
            style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }}
            onClick={() => this.editPayment(rowData)}
            tooltip='Edit payment'
            tooltipOptions={{ position: 'top' }} />

          <Button icon="pi pi-trash"
            className="p-button-rounded p-button-outlined p-button-danger"
            style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }}
            onClick={() => this.confirmDeletePayment(rowData)}
            tooltip='Delete payment'
            tooltipOptions={{ position: 'top' }}
          />
        </>
      )
    } else {
      return (
        <>
          <Button icon="pi pi-eye"
            className='p-button-rounded p-button-outlined'
            tooltip='View payment'
            tooltipOptions={{ position: 'top' }}
            color='primary'
            style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }}
            onClick={() => this.showPayment(rowData)} />
        </>
      )

    }

    //return showActions

  }


  // positiveBalance() {
  //   let balance = { ...this.state.balances }
  //   // let invoice = {...this.state.invoices}
  //   let historyPayments = { ...this.state.paymentItems }
  //   let positiveExchanges = this.state.positiveExchanges

  //   let dollarUSLocale = Intl.NumberFormat('en-US')
  //   let positiveBalanceFormat

  //   let positiveBalance = 0
  //   let invoiceStatus = 0
  //   console.log("TC:", positiveExchanges)
  //   console.log("BALANCE: ", balance);
  //   // console.log("History payments: ", historyPayments);

  //   // for (let i in invoice) {
  //   //   console.log("invoice total history: ", invoice[i].totalHistory);
  //   // }

  //   for (let i in balance) {
  //     // console.log("Balance, TC:", balance[i].balance, balance[i].total, balance[i].fk_id_currency, historyPayments[0].totalAmountConvert )
  //     if (balance[i].balance < 0) {
  //       console.log('I: ', i);
  //       // console.log("Balance, TC:", balance[i].balance, balance[i].total, historyPayments[0].totalAmountConvert )
  //       for (let j in positiveExchanges) {
  //         // console.log("Balance, TC:", balance[i].balance,positiveExchanges[j].rate, balance[i].total, historyPayments[0].totalAmountConvert )
  //         if (positiveExchanges[j].fk_id_currency === balance[i].fk_id_currency) {
  //           // console.log("Balance, TC:", balance[i].balance,positiveExchanges[j].rate, balance[i].total, historyPayments[0].totalAmountConvert )
  //           // positiveBalance = (positiveBalance - (positiveExchanges[j].rate * balance[i].balance)).toFixed(2)
  //           positiveBalance = historyPayments[0].totalAmountConvert - balance[i].total
  //           // console.log('POSITIVE BALANCE: ', positiveBalance);
  //           console.log("History payments: ", historyPayments[0].totalAmountConvert);

  //           positiveBalance = (positiveBalance * historyPayments[0].rateConvert).toFixed(2)

  //           balance[i].positiveBalanceFormat = dollarUSLocale.format(positiveBalance)
  //           balance[i].symbolPositiveBalanceFormat = positiveBalance

  //           console.log('2460: ', positiveBalance);
  //           // positiveBalanceFormat = balance[i].symbolPositiveBalanceFormat
  //           positiveBalanceFormat = balance[i].positiveBalanceFormat

  //           console.log("POSITIVE BALANCE:", positiveBalanceFormat)
  //         }
  //       }
  //     } else if (balance[i].balance === 0) {
  //       console.log('I 2466: ', i);
  //       balance[i].positiveBalanceFormat = dollarUSLocale.format(positiveBalance)
  //       balance[i].symbolPositiveBalanceFormat = positiveBalance
  //       // positiveBalanceFormat = balance[i].symbolPositiveBalanceFormat
  //       positiveBalanceFormat = balance[i].positiveBalanceFormat

  //       console.log("POSITIVE BALANCE:", positiveBalanceFormat)
  //     }

  //     invoiceStatus = balance[i].statusInvoice
  //   }

  //   if (invoiceStatus === 1) {
  //     return (
  //       <ColumnGroup>
  //         <Row>
  //           <Column footer={this.state.campusText.PositiveBalance} colSpan={3} footerStyle={{ textAlign: 'right' }} />
  //           <Column footer={'$ ' + positiveBalanceFormat + ' ' + this.props.user.codeCurrency} style={{ color: 'green' }} />
  //         </Row>
  //       </ColumnGroup>
  //     );
  //   }

  // }


  positiveBalance() {
    let balance = { ...this.state.balances }
    let positiveExchanges = this.state.positiveExchanges
    let historyPayments = this.state.paymentItems

    let positiveBalanceAux = 0
    let positiveBalance = 0
    let invoiceStatus = 0
    // console.log("TC:", positiveExchanges)

    for (let i in balance) {
      if (balance[i].balance < 0) {
        for (let j in positiveExchanges) {
          if (positiveExchanges[j].fk_id_currency === balance[i].fk_id_currency) {
            // console.log("Balance, TC:", balance[i].balance,positiveExchanges[j].rate)

            positiveBalanceAux = (positiveBalanceAux - (balance[i].balance)).toFixed(2)

          }
        }
      }
      invoiceStatus = balance[i].statusInvoice
    }

    for (let i in balance) {
      if (balance[i].balance < 0) {
        // console.log("BALANCE: ", balance[i]);
        for (let j = 0; j < historyPayments.length; j++) {
          if (j === historyPayments.length - 1) {
            // console.log('HISTORY PAYMENTS: ', historyPayments[j]);
            positiveBalance = (positiveBalanceAux * historyPayments[j].rate).toFixed(2)
          }
        }
      }
    }

    // console.log("POSITIVE BALANCE AUX:", positiveBalanceAux)
    // console.log("POSITIVE BALANCE:", positiveBalance)




    if (invoiceStatus === 1) {
      return (
        <ColumnGroup>
          <Row>
            <Column footer={this.state.campusText.PositiveBalance} colSpan={3} footerStyle={{ textAlign: 'right' }} />
            <Column footer={positiveBalance + ' ' + this.props.user.codeCurrency} style={{ color: 'green' }} />
          </Row>
        </ColumnGroup>
      );
    }

  }

  historyTotal() {
    let payment_items = { ...this.state.paymentItems }

    let total = 0
    let dollarUSLocale
    let totalFormat
    // let totalFormatCurrency

    // console.log("PAYMENT ITEMS: ", payment_items);

    for (let history in payment_items) {
      total = total + payment_items[history].amountItem
      // console.log("TOTAL: ", total);
    }

    dollarUSLocale = Intl.NumberFormat('en-US')
    totalFormat = dollarUSLocale.format(total)
    // totalFormatCurrency = '$ ' + total + ' MXN'

    return '$ ' + totalFormat + this.props.user.codeCurrency
  }

  exportPdf() {
    let invoice = { ...this.state.invoices }
    let approvedPayments = { ...this.state.approvedPayments }
    let historyPayments = { ...this.state.paymentItems}

    let today = this.Today()
    var logo = new Image();
    logo.src = this.props.user.logo

    var doc = new jsPDF('p', 'pt');
    require('jspdf-autotable');

    doc.setFont("Diodrum Arabic Bold");
    doc.setFontSize(20);
    doc.text(30, 40, 'Balance de pagos')
    doc.addImage(logo, 'JPEG', 425, 20, 125, 100);
    doc.setFontType("bold");
    doc.setFontSize(7);
    doc.text(30, 60, 'Creado:')
    doc.text(30, 70, `${today}`)
    doc.text(30, 90, 'Estudiante:')
    doc.setFontType("normal");
    doc.text(30, 100, `${invoice[0].student}`)
    doc.setFontType("bold");

    doc.setFontSize(10);
    doc.setFontType("bold");
    // doc.text(30, 130, 'Pagos aprobados')  
    doc.text(30, 130, 'Balance de invoice')

    doc.autoTable(this.exportBalance, this.state.balances, {
      startY: doc.autoTableEndPosY() + 140,
      margin: { horizontal: 30 },
      headStyles: { fillColor: 'blue' },
      styles: { overflow: 'linebreak', fontSize: 7, font: 'Diodrum Arabic Bold' },
      bodyStyles: { valign: 'top' },
      columnStyles: { balanceCurrency: { columnWidth: 'wrap', fillColor: [255,255,0], fontStyle: 'bold'} },
      theme: "striped"
    });

    // doc.setFontSize(10);
    // doc.setFontType("bold");
    // doc.text(`TOTAL: ${approvedPayments[0].totalAmountCurrencyFormat}`, 360, doc.autoTableEndPosY() + 10);


    doc.setFontSize(10);
    doc.setFontType("bold");
    // doc.text(30, 130, 'Pagos aprobados')  
    doc.text('Payments history', 30, doc.autoTableEndPosY() + 40);
    doc.autoTable(this.exportHistory, this.state.paymentItems, {
      startY: doc.autoTableEndPosY() + 50,
      margin: { horizontal: 30 },
      headStyles: { fillColor: 'blue' },
      styles: { overflow: 'linebreak', fontSize: 7, font: 'Diodrum Arabic Bold' },
      bodyStyles: { valign: 'top' },
      columnStyles: { email: { columnWidth: 'wrap' } },
      theme: "striped"
    });



    doc.setFontSize(10);
    doc.setFontType("bold");
    // doc.text('Balance de invoice', 30, doc.autoTableEndPosY() + 40);
    doc.text('Pagos aprobados', 30, doc.autoTableEndPosY() + 40);

    doc.autoTable(this.exportPayments, this.state.approvedPayments, {
      startY: doc.autoTableEndPosY() + 50,
      margin: { horizontal: 30 },
      headStyles: { fillColor: 'blue' },
      styles: { overflow: 'linebreak', fontSize: 7, font: 'Diodrum Arabic Bold' },
      bodyStyles: { valign: 'top' },
      columnStyles: { email: { columnWidth: 'wrap' } },
      theme: "striped"
    });


    // doc.autoTable(this.exportBalance, this.state.balances, {
    //   startY: doc.autoTableEndPosY() + 50,
    //   margin: { horizontal: 30 },
    //   headStyles: {fillColor: '#510966' },
    //   styles: { overflow: 'linebreak', fontSize:7, font:'Diodrum Arabic Bold'},
    //   bodyStyles: { valign: 'top'},
    //   columnStyles: { email: { columnWidth: 'wrap' } },
    //   theme: "striped"
    // });

    doc.setFontType("bold");
    doc.setFontSize(7);
    doc.text('*Solo se toman en cuenta los pagos aprobados', 30, doc.autoTableEndPosY() + 20);



    //FOOTER
    doc.setFontType("bold");
    doc.text(30, 700, 'Asesor:')
    doc.setFontType("normal");
    doc.text(30, 710, `${this.props.user.fullname}`)
    doc.setFontType("bold");
    doc.text(30, 730, 'Correo:')
    doc.setFontType("normal");
    doc.text(30, 740, `${this.props.user.email}`)
    doc.setFontType("bold");
    doc.text(30, 760, 'phone:')
    doc.setFontType("normal");
    doc.text(30, 770, `${this.props.user.phone}`)
    doc.setFontType("bold");
    doc.text(30, 790, 'Correo:')
    doc.setFontType("normal");
    doc.text(30, 800, `${this.props.user.accounting_office}`)

    doc.save(`payment-balance@${invoice[0].student}.pdf`);
  }

  tableSalesHistory() {
    let showHistory = []

    const footerGroup = <ColumnGroup>
      <Row>
        <Column footer='Total history' colSpan={5} footerStyle={{ textAlign: 'right' }} />
        <Column footer={this.historyTotal} style={{ color: 'green' }} />
      </Row>
    </ColumnGroup>

    showHistory.push(
      <>
        <DataTable
          exportFunction={(el) => this.dt = el}
          value={this.state.paymentItems}
          resizableColumns
          columnResizeMode='fit'
          stripedRows
          responsiveLayout='scroll'
          loading={this.state.loading}
          footerColumnGroup={footerGroup}
        >
          <Column body={<i className='pi pi-calendar text-blue-500' />} />
          <Column field='item_name' header='Item name' />
          <Column field='payment_date' header={this.state.campusText.PaymentDate} />
          <Column field='rate' header='TC' />
          <Column field='code' header='Moneda' />
          <Column field='symbolAmountCurrency' header='Monto pagado' />
          <Column field='symbolEquivalentCurrency' header='Equivalente' />

        </DataTable>
      </>
    )

    return showHistory
  }

  tableBalances() {
    let showBalance = []

    showBalance.push(
      <>
        <DataTable
          ef={(el) => this.dt = el}
          value={this.state.balances}
          resizableColumns
          columnResizeMode='fit'
          stripedRows
          loading={this.state.loading}
          footerColumnGroup={this.positiveBalance()}
        >
          <Column body={<i className='pi pi-money-bill text-green-500' />} />
          <Column field='item_name' header='Item name' />
          <Column field='symbolTotalCurrency' header={this.state.campusText.Total} />
          <Column field='symbolBalanceCurrency' header={this.state.campusText.Balance} />
        </DataTable>
      </>
    )

    return showBalance
  }

  tablePendingPayment() {
    let showPendingPayments = []

    showPendingPayments.push(
      <>
        <DataTable
          ef={(el) => this.dt = el}
          value={this.state.pendingPayments}
          resizableColumns
          columnResizeMode='fit'
          stripedRows
          loading={this.state.loading}
          emptyMessage={<FormattedMessage id='ACCOUNTING_PAYMENT.NO_PENDING_PAYMENTS' />}
          ref={(el) => { this.dt = el }}
          dataKey='id'
        >
          <Column body={<i className='pi pi-calendar text-blue-500' />} />
          <Column field='payment_date' header={this.state.campusText.PaymentDate} />
          <Column field='account_name' header={this.state.campusText.Account} />
          <Column field='symbolAmountCurrency' header={this.state.campusText.Amount} />
          <Column field='balance_items_names' header='Items paid' />
          <Column body={this.fileBodyTemplate} header={this.state.campusText.File} />
          <Column body={this.actionBodyTemplatePending} />


        </DataTable>
      </>
    )

    return showPendingPayments
  }

  tableApprovedPayments() {
    let invoice = { ...this.state.invoices }
    let invoiceStatus
    let showApprovedPayments = []

    for (let i in invoice) {
      invoiceStatus = invoice[i].statusBalance
    }
    showApprovedPayments.push(
      <>
        <DataTable
          ef={(el) => this.dt = el}
          value={this.state.approvedPayments}
          loading={this.state.loading}
          emptyMessage={<FormattedMessage id='ACCOUNTING_PAYMENT.NO_PAYMENTS_PAID' />}
          dataKey='id'
        >
          <Column body={<i className='pi pi-calendar text-blue-500' />} />
          <Column field='payment_date' header={this.state.campusText.PaymentDate} />
          <Column field='account_name' header={this.state.campusText.Account} />
          <Column field='symbolAmountCurrency' header={this.state.campusText.Amount} />
          <Column field='balance_items_names' header='Items paid' />
          <Column body={this.fileBodyTemplate} header={this.state.campusText.File} />
          {invoiceStatus === 0 && this.props.user.role === 'Director' ?
            <Column body={this.actionBodyTemplateApproved} />
            :
            invoiceStatus === 0 && this.props.user.role === 'Gerente' ?
              <Column body={this.actionBodyTemplateApproved} />
              :
              <></>
          }
        </DataTable>
      </>
    )

    return showApprovedPayments
  }

  // rowExpansionTemplate(data) {
  //   console.log("DATA: ", data);

  //   return (
  //     <div className='orders-subtable'>
  //       <DataTable value={data.items_name}>
  //         <Column field='items_name' header="Item name" sortable></Column>
  //       </DataTable>
  //     </div>
  //   )
  // }
  render() {

    let paymentDialogFooter
    let invoice = { ...this.state.invoices }

    let externalId
    let created_at
    let agency
    let agent
    let agentEmail
    let student
    let studentEmail
    let validateInvoice

    for (let i in invoice) {
      externalId = invoice[i].externalId
      created_at = invoice[i].created_at
      agency = invoice[i].agency
      agent = invoice[i].agent
      agentEmail = invoice[i].agentEmail
      student = invoice[i].student
      studentEmail = invoice[i].studentEmail
      validateInvoice = invoice[i].validateInvoice
    }

    if (this.state.readOnly === false) {
      paymentDialogFooter = (

        <>
          <FormattedMessage id="GENERAL.CANCEL">
            {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
          </FormattedMessage>

          <FormattedMessage id="GENERAL.SAVE">
            {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.savePayment} />}
          </FormattedMessage>
        </>
      );

    }


    const actionCloseInvoiceDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideActionCloseInvoiceDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={() => this.changeStatusInvoice(true)} />
      </>
    );

    const actionOpenInvoiceDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideActionOpenInvoiceDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={() => this.changeStatusInvoice(false)} />
      </>
    );


    const deletePaymentDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-danger p-button-rounded p-button-outlined" onClick={this.hideDeletePaymentDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-rounded p-button-outlined" onClick={this.deletePayment} />
      </>
    );



    const deletePaymentsDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeletePaymentsDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedPayments} />
      </>
    );

    return (
      <>

        <div>
          <div className="surface-section px-4 py-5 md:px-6 lg:px-8">
            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
              <div>
                <div><label>Student:</label> <span className='text-3xl text-900'>{student}</span> {validateInvoice === 1 ? <i className='pi pi-check-circle' style={{ fontSize: '15px', color: '#51FF00' }} /> : <i className='pi pi-exclamation-triangle' style={{ fontSize: '15px', color: '#FFCD00' }} />}</div>
                <div className="flex align-items-center text-700 flex-wrap">
                  <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-calendar mr-2"></i>
                    <span>Created at: {created_at}</span>
                  </div>
                  <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-user mr-2"></i>
                    <span>Created by: {agent}</span>
                  </div>
                  <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-at mr-2"></i>
                    <span>Agent email: {agentEmail}</span>
                  </div>
                  <div className="flex align-items-center mt-3">
                    <i className="pi pi-building mr-2"></i>
                    <span>Office: {agency}</span>
                  </div>
                </div>
              </div>
              <div className="mt-3 lg:mt-0">
                {/* <Button label="Add" className="p-button-outlined mr-2" icon="pi pi-user-plus" onClick={this.openNew} /> */}
                {this.newPayment()}
                {this.actionInvoice()}

                {/* <div style={{ marginRight: 10}}> */}
                {/* <p> */}
                  <Button style={{ marginLeft: 10}} tooltip='Export PDF' tooltipOptions={{ position: 'bottom'}} type="button" className='p-button-rounded p-button-outlined mr-2 p-button-success' icon="pi pi-file-pdf" onClick={this.exportPdf} variant='contained'/>
                {/* </p> */}
                {/* </div>  */}
                {/* <Button label="Save" icon="pi pi-check" /> */}
              </div>
            </div>

            <br />
            {/* <h3>Detalles de la factura</h3> */}
          </div>


          <div className='surface-card p-4 shadow-2 border-round' style={{ margin: 10 }}>
            <h3>Payments History</h3>
            {this.tableSalesHistory()}
          </div>

          {/* <div className='grid'> */}
          <div className='surface-card p-4 shadow-2 border-round' style={{ margin: 10 }}>
            <h3>Invoice balance</h3>
            {this.tableBalances()}
          </div>
          {/* </div> */}

          <div className='surface-card p-4 shadow-2 border-round' style={{ margin: 10 }}>
            <h3>Pending payments</h3>
            {this.tablePendingPayment()}
          </div>

          <div className='surface-card p-4 shadow-2 border-round' style={{ margin: 10 }} >
            <h3>Approved payments</h3>
            {this.tableApprovedPayments()}
          </div>

          {/* EDIT & NEW PAYMENT */}
          <Dialog visible={this.state.paymentDialog} contentStyle={{ maxHeight: "850px", width: "900px", height: "650px", overflow: "auto" }} header={<FormattedMessage id="OPTION.PAYMENT" />} modal footer={paymentDialogFooter} onHide={this.hideDialog}>
            <br></br>
            {/* Account */}
            {this.showStudent()}

            <br></br>
            <div className="form-group row">
              {/* <div className='col-lg-3'> */}
              {this.FilePayment()}
              {/* </div>  */}
            </div>

            <div className="form-group row">
              <div className="col-lg-3">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: 190, height: 33 }}
                    id="account_name"
                    variant="outlined"
                    placeholder={this.state.resetAccount || this.state.payment.account_name}
                    margin="dense"
                    value={this.state.selectedAccount || "account_name"}
                    options={this.state.accounts}
                    onChange={this.onAccountChange}
                    optionLabel="account_name"
                    optionValue="id"
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.fk_id_account })}
                  />
                  <label htmlFor="account_name">{this.state.campusText.Account}</label>
                </span>
                <small id="account_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAccount_Name(this.state)}</small>
              </div>

              <div className="col-lg-3">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: 190, height: 33 }}
                    id="method_name"
                    variant="outlined"
                    placeholder={this.state.resetMethod || this.state.payment.method_name}
                    margin="dense"
                    value={this.state.selectedMethod || "method_name"}
                    options={this.state.methods}
                    onChange={this.onMethodChange}
                    optionLabel="method_name"
                    optionValue="id"
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.fk_id_payment_method })}
                  />
                  <label htmlFor="method_name">{this.state.campusText.PaymentMethod}</label>
                </span>
                <small id="method_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxMethod_Name(this.state)}</small>
              </div>

              <div className="col-lg-3">
                <span className="p-float-label">
                  <Calendar
                    style={{ width: 190, height: 33 }}
                    showIcon
                    margin="dense"
                    variant="outlined"
                    readOnlyInput="true"
                    dateFormat="dd/mm/yy"
                    id="payment_date"
                    placeholder={this.state.payment.payment_date_show || 'Payment Date'}
                    value={this.state.payment.payment_date_show || 'payment_date_show'}
                    onChange={(e) => this.onDateChange(e, 'payment_date_show')}
                    required autoFocus
                    className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.payment_date })}
                  />

                  <label htmlFor="payment_date">{this.state.campusText.PaymentDate}</label>
                </span>
                <small id="amount-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPayment_Date(this.state)}</small>
              </div>

            </div>

            <br></br>
            <br></br>
            {this.balance()}

            <div className="form-group row">
              <div className="col-lg-12">
                <div className="card">
                  <DataTable
                    ef={(el) => this.dt = el} value={this.state.balances}
                    loading={this.state.loading}>
                    <Column field="item_name" header={this.state.campusText.InvoiceName}></Column>
                    <Column field="symbolTotalCurrency" header={this.state.campusText.Total}></Column>
                    <Column field="symbolBalanceCurrency" header={this.state.campusText.Balance}></Column>
                  </DataTable>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-6">
                <span className="p-float-label">
                  <InputTextarea
                    style={{ width: 600, height: 80 }}
                    id="note"
                    variant="outlined"
                    margin="dense"
                    value={this.state.payment.note}
                    onChange={(e) => this.onInputChange(e, 'note')}
                  //required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.note})}
                  />
                  <label htmlFor="note">{this.state.campusText.Note}</label>
                </span>
                {/*<small id="note-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxNote(this.state)}</small>*/}
              </div>
            </div>

            {/* <div>
              <div>
              </div>
            </div> */}
          </Dialog>

          {/* DELETE PAYMENT */}
          <Dialog visible={this.state.deletePaymentDialog} style={{ width: '450px' }} header='Confirm' modal footer={deletePaymentDialogFooter} onHide={this.hideDeletePaymentDialog} >
            <div className='confirmation-content'>
              <i className='pi pi-exclamation-triangle p-mr-3 text-yellow-500' style={{ fontSize: '1em' }} />
              {this.state.payment && <span> <FormattedMessage id='ACCOUNTING_PAYMENT.DIALOG_DELETE_ALL' /></span>}
            </div>
          </Dialog>

          {/*CLOSE INVOICE*/}
          <Dialog visible={this.state.actionCloseInvoiceDialog} style={{ width: '450px' }} header="Confirm" modal footer={actionCloseInvoiceDialogFooter} onHide={this.hideActionCloseInvoiceDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {<span><FormattedMessage id="ACCOUNTING_PAYMENT.DIALOG_ACTION_CLOSE_INVOICE" /></span>}
            </div>
          </Dialog>

          {/*OPEN INVOICE*/}
          <Dialog visible={this.state.actionOpenInvoiceDialog} style={{ width: '450px' }} header="Confirm" modal footer={actionOpenInvoiceDialogFooter} onHide={this.hideActionOpenInvoiceDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {<span><FormattedMessage id="ACCOUNTING_PAYMENT.DIALOG_ACTION_OPEN_INVOICE" /></span>}
            </div>
          </Dialog>
        </div>



      </>
    )
  }
}
const mapStateToProps = state => ({
  invoice: state.auth.invoiceId,
  user: state.auth.user
});
export default connect(mapStateToProps, auth.actions)(AgentsPaymentsTable);