import React, { useMemo } from "react";
import objectPath from "object-path";
/*import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";*/
import { useHtmlClassService } from "../../../_core";
/*import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
/*import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";*/
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuickUserToggler";

import { Button } from 'primereact/button';
import {useSelector,useDispatch} from "react-redux";
import { useHistory } from 'react-router-dom';
import * as auth from "../../../../app/modulesAdmin/Auth/_redux/authRedux";

export function Topbar() {
  const uiService = useHtmlClassService();

  const user = useSelector(state => state.auth.user); 
  const tokenAdmin = useSelector(state => state.auth.authToken_ADMIN); 
  console.log("TOKEN ADMIN EN TOPBAR:",tokenAdmin)


  const dispatch = useDispatch();
  let history = useHistory();
  
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const exitUserView=()=>{


    try {

          const user_ = {
          ...user,
          id:user.id_ADMIN,
          email:user.email_ADMIN,
          password:user.password_ADMIN,
          photo:user.photo_ADMIN,
          phone:user.phone_ADMIN,
          first_name:user.first_name_ADMIN,
          last_name:user.last_name_ADMIN,
          fullname:user.fullname_ADMIN,
          fk_id_role:user.fk_id_role_ADMIN,
          fk_id_enterprise:user.fk_id_enterprise_ADMIN,
          learning:user.learning_ADMIN,
          wiyu:user.wiyu_ADMIN,
          owner:user.owner_ADMIN,
          accounting:user.accounting_ADMIN,
          accounting_office:user.accounting_office_ADMIN,
          fk_id_baseCurrency:user.fk_id_baseCurrency_ADMIN,
          role:user.role_ADMIN,
          fk_id_role_type:user.fk_id_role_type_ADMIN,
          role_type:user.role_type_ADMIN,
          enterprise_name:user.enterprise_name_ADMIN,
          boss:user.boss_ADMIN,
          codeCurrency:user.codeCurrency_ADMIN,
          view:false,
          tutorials:user.tutorials_ADMIN,
          billing:user.billing_ADMIN,
          is_active: user.is_active_ADMIN,
          id_ADMIN:"",
          email_ADMIN:"",
          password_ADMIN:"",
          photo_ADMIN:"",
          phone_ADMIN:"",
          first_name_ADMIN:"",
          last_name_ADMIN:"",
          fullname_ADMIN:"",
          fk_id_role_ADMIN:"",
          fk_id_enterprie_ADMIN:"",
          learning_ADMIN:"",
          wiyu_ADMIN: "",
          owner_ADMIN:"",
          accounting_ADMIN:"",
          accounting_office_ADMIN:"",
          fk_id_baseCurrency_ADMIN:"",
          role_ADMIN:"",
          fk_id_role_type_ADMIN:"",
          role_type_ADMIN:"",
          enterprise_name_ADMIN:"",
          boss_ADMIN:"",
          codeCurrency_ADMIN:"",
          view_ADMIN:"",
          tutorials_ADMIN:"",
          billing_ADMIN:"",
          is_active_ADMIN:""
        }
        dispatch(auth.actions.updatedUser(user_))
        dispatch(auth.actions.updatedAuthToken(tokenAdmin))
        dispatch(auth.actions.getAuthToken_ADMIN(""))


        }catch(error){}  

        history.push('/')
        }
  
  const button=()=>{
    let buttonView=[]

    if(user.view===true){
        buttonView.push(
        // <>
        //   <div style={{width:'800px',margin:20}}>
        //   <Button label="SALIR DE VISTA SOPORTE" className="p-button-danger" onClick={() => exitUserView()} />
        //   </div> 
        // </> 
        <div key="TopBar" style={{width:'800px',margin:20}}>
          <Button label="SALIR DE VISTA SOPORTE" className="p-button-danger" onClick={() => exitUserView()} />
        </div> 
        )
      }    
    return buttonView  
  }

  return (
    <div className="topbar">
      {/*layoutProps.viewSearchDisplay && <SearchDropdown />*/}
      {button()}
      
      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
