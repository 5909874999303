import React, {useMemo} from "react";
// import {AsideMenuListDG} from "./AsideMenuListDG";
// import {AsideMenuListDO} from "./AsideMenuListDO";
import {AsideMenuListDefault} from "./AsideMenuListDefault";
import {useHtmlClassService} from "../../../../_core";
import { useSelector } from "react-redux";
// import { NavLink } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from "react-intl";

 export const renderSwitch = (user, layoutProps) => {
    // if(user.wiyu){
      // switch(user.role) {
        // case 'Director':
          return <AsideMenuListDefault layoutProps={layoutProps} />;
        // case 'Wiyu':
        //   return <AsideMenuListDO layoutProps={layoutProps} />;
        // default:
        //   return <AsideMenuListDefault layoutProps={layoutProps} />;
      // }
    // } 
  };

export function AsideMenu({disableScroll}) {
  const { user } = useSelector(state => state.auth);
  const uiService = useHtmlClassService();

  let is_active = false;

  if(user.is_active === 1){
    is_active = true;
  }

  const [open, setOpen] = React.useState(!is_active);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);



  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {renderSwitch(user)}
      </div>
      {/* end::Menu Container */}
      <Snackbar
      open={open}
      autoHideDuration={60000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleClose} severity="info">
          <FormattedMessage id="GENERAL_MESSAGE.ACCOUNT_NOT_CONFIRMED"/>
        </Alert>
      </Snackbar>
    </>
  );
}
