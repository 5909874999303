import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';

import axios from 'axios';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from "react-intl";

const url = ServerURL();

class TutorialSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };

    this.id_agente = 64;
  }

  componentDidMount() {
    this.getCourses()
  }

  Date(e){                 
    e.map((course) => {
    
    let date = null
    let type_time = 0;
    while (type_time < 2) {
      if(type_time === 0){
        date = new Date(course.created_at); 
      }else{
        date = new Date(course.updated_at);
      }
      let year = date.getFullYear();
      let month = date.getMonth()+1;
      let dt = date.getDate();
  
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
  
      let new_date = dt +'/' + month + '/'+year
      // console.log("New Data ", (type_time+1) ,":",new_date)
  
      if(type_time === 0){
        course.created_at = new_date
      }else{
        course.updated_at = new_date
      }
      type_time++;
    }

     return course

   });
  }

  hideTutorial = async () => {
    try {
      await axios.put(`${url}/api/auth/user_hide_tutorial`)
      .then(res => {
        const user = {
          ...this.props.user,
          tutorials: 0
        }

        this.props.updatedUser(user)
        this.setState({ redirectInicio: true });
      })
    } catch (error) {
      
    }
  }

  changePage(dataCourse) {
    console.log(this.props)
    console.log(dataCourse)
    this.props.updatedCourse(dataCourse)
    this.setState({redirect: true})
  }

  getCourses = async () =>{
    let type_enterprise = null;
    const id_agency = 11;
    try {
      if(this.props.user.fk_id_role_type === id_agency){
        type_enterprise = 2;
      }else{
        type_enterprise = 1;
      }
      await axios.get(`${url}/api/auth/courses_tutorials/${type_enterprise}`)
      .then(res => {
        const courses = res.data;
        this.Date(courses);

        console.log(courses)
        this.setState({ courses, courses_show: courses})
      });
    }catch(error){}
  }

  renderCourses(data) {
    return (
      <div key={data.course} className="col-3">
        <div className="course-grid-item card">
          <div className="course-grid-item-top">
            <h6>{data.enterprise_name}</h6>
            {
              data.isComplete && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
              )
            }
          </div>
          <div className="course-grid-item-top">
            <span><FormattedMessage id="LEARNING_GENERAL.TUTORIAL"/></span>
            <span><FormattedMessage id="GENERAL_MESSAGE.POSTED"/> {data.updated_at}</span>
          </div>
          <div className="course-grid-item-content">
            <img style={{width: "100%"}} src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: Equipo de Wissify</div>
          </div>
          <div className="course-grid-item-bottom">
            <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
          </div>
        </div>
      </div>
    );
  }

  render() {

    if(this.state.redirect) {
      return <Redirect to={{
        pathname: "/tutorial/course",
      }}/>;
    }

    if(this.state.redirectInicio) {
      return <Redirect to={{
        pathname: "/inicio",
      }}/>;
    }


    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <div className="dataview mb-5">
          <div className="card">
            <div className="row pt-6" style={{alignSelf:"center"}}>
              <h1><FormattedMessage id="LEARNING_TUTORIALS.TITLE"/></h1>
            </div>
            {
              this.state.courses_show ?
                this.state.courses_show.length !== 0 ?
                <div className="container">
                  <div className="row p-6">
                    {this.state.courses_show.map((course) => this.renderCourses(course))}
                  </div>
                  <div className="row p-6" style={{justifyContent:"flex-end"}}>
                    <button type="button" className="btn btn-secondary" onClick={this.hideTutorial}><FormattedMessage id="GENERAL_MESSAGE.NO_SHOW_AGAIN"/></button>
                  </div>
                </div>
                :
                <div className="container">
                  <div style={{display:"grid", justifyItems:"center", marginTop:"10vh"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                    </svg>
                    <span style={{marginTop:"1em", fontSize:"2em"}}><FormattedMessage id="LEARNING_TUTORIALS.NO_TUTORIALS"/></span>
                  </div>
                  <div className="row p-6" style={{justifyContent:"flex-end"}}>
                    <button type="button" className="btn btn-secondary" onClick={this.hideTutorial}><FormattedMessage id="GENERAL_MESSAGE.NO_SHOW_AGAIN"/></button>
                  </div>
                </div>
                  
              :
              <div className="row p-6">
                <div className="col" style={{display:"flex", justifyContent: "center", padding: "12em 4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, auth.actions)(TutorialSection);