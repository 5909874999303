import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/_core";
import {ContentRoute} from "../_metronic/layoutSchool";

import {BuilderPage} from "./pagesSchool/BuilderPage";
import {MyPage} from "./pagesSchool/MyPage";
import { InicioPage } from "./pagesAgency/InicioPage";
import {DashboardPage} from "./pagesSchool/DashboardPage";
import {DashboardPageDefault} from "./pagesSchool/DashboardPageDefault";

import {DataUserPage} from "./pages/DataUserPage";
import {TutorialsSectionPage} from "./pages/TutorialsSectionPage";

import { GetTasksPage } from './pagesSchool/GetTasksPage';
import { AddTaskPage } from './pagesSchool/AddTaskPage';

import { MarketerPage } from './pagesSchool/MarketerPage';
import { GroupAgenciesPage } from './pagesSchool/GroupAgenciesPage';
import { ImagesPage } from './pagesSchool/ImagesPage';
import { LessonPage } from './pagesSchool/LessonPage';
import { EvaluationPage } from './pagesSchool/EvaluationPage';
import { EvaluationResultPage } from './pagesSchool/EvaluationResultPage';
import { EvaluationListPage } from './pagesSchool/EvaluationListPage';
import { EvaluationLessonPage } from './pagesSchool/EvaluationLessonPage';
import { CourseAssignmentPage } from './pagesSchool/CourseAssignmentPage';
import { CoursesPage } from './pagesSchool/CoursesPage';
import { CoursePage } from './pagesSchool/CoursePage';
import { DataSchoolPage } from './pagesSchool/DataSchoolPage';
import { DataCampusesPage } from './pagesSchool/DataCampusesPage';

import { useSelector } from "react-redux";
import { MainBillingPage } from "./pages/MainBillingPage";
import { MovementsAndInvoicesBillingPage } from "./pages/MovementsAndInvoicesBillingPage";
import { ReportPaymentPage } from "./pages/ReportPaymentPage";
import { ToPayBillingPage } from "./pages/ToPayBillingPage";
import { UpgradeBillingPage } from "./pages/UpgradeBillingPage";

import { DataAgencyPage } from "./pagesAgency/DataAgencyPage";
import { AgentPage } from "./pagesAgency/AgentPage";





const GoogleMaterialPage = lazy(() =>
  import("./modulesAdmin/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modulesAdmin/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modulesAdmin/ECommerce/pages/eCommercePage")

);

export default function BasePageSchool() {
  const { user } = useSelector(state => state.auth);
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const renderSwitchRedirect = (user) => {
    switch(user.role) {
      case 'Director':
        if(user.billing === 2){
          return <Redirect exact from="/" to="/billing"/>;
        }else{
          if(user.tutorials === 0){
            return <Redirect exact from="/" to="/inicio"/>;
          }else{
            return <Redirect exact from="/" to="/tutorial"/>;
          }
        }
      case 'Marketeer':
        if(user.billing === 2){
          return <Redirect exact from="/" to="/billing"/>;
        }else{
          if(user.tutorials === 0){
            return <Redirect exact from="/" to="/inicio"/>;
          }else{
            return <Redirect exact from="/" to="/tutorial"/>;
          }
        }
      default:
        if(user.billing === 2){
          return <Redirect exact from="/" to="/billing"/>;
        }else{
          if(user.tutorials === 0){
            return <Redirect exact from="/" to="/inicio"/>;
          }else{
            return <Redirect exact from="/" to="/tutorial"/>;
          }
        }
    }
  };
  
  const renderSwitchRoute = (user) => {
      if(user.owner){
        return (
          <Switch>
            {
                /* Redirect from root URL to /dashboard. */
                user.billing === 2 ? <Redirect exact from="/" to="/billing"/> : user.tutorials === 0 ? <Redirect exact from="/" to="/inicio"/> : <Redirect exact from="/" to="/tutorial"/>
            }
            <ContentRoute path="/builder" component={BuilderPage}/>
            <ContentRoute path="/my-page" component={MyPage}/>
            
            <ContentRoute path="/get-task" component={GetTasksPage}/>
            <ContentRoute path="/add-task" component={AddTaskPage}/>
            <ContentRoute path='/get-agency' component={DataAgencyPage}/>
            <ContentRoute path='/get-agents' component={AgentPage}/>

    
            <ContentRoute path="/inicio" component={InicioPage}/>
            <ContentRoute path="/analytics" component={DashboardPage}/>
            <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage}/>
            <ContentRoute path="/tutorial/course/lesson" component={LessonPage}/>
            <ContentRoute path="/tutorial/course" component={CoursePage}/>
            <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>
            {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage}/>}
            {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage}/>}
            {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage}/>}
            {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage}/>}
            {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage}/>}
            <ContentRoute path="/get-marketers" component={MarketerPage}/>
            <ContentRoute path="/get-group-agencies" component={GroupAgenciesPage}/>
            <ContentRoute path="/get-lessons" component={ImagesPage}/>
            <ContentRoute path="/get-evaluations/evaluations/results" component={EvaluationResultPage}/>
            <ContentRoute path="/get-evaluations/evaluations" component={EvaluationListPage}/>
            <ContentRoute path="/get-evaluations" component={EvaluationPage}/>
            <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage}/>
            <ContentRoute path="/get-courses/course/lesson" component={LessonPage}/>
            <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage}/>
            <ContentRoute path="/get-courses/course" component={CoursePage}/>
            <ContentRoute path="/get-courses" component={CoursesPage}/>
            <ContentRoute path="/get-school" component={DataSchoolPage}/>
            <ContentRoute path="/get-campuses" component={DataCampusesPage}/>
  
            <ContentRoute path="/my-profile" component={DataUserPage}/>
  
            <Route path="/google-material" component={GoogleMaterialPage}/>
            <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
            <Route path="/e-commerce" component={ECommercePage}/>
            <Redirect to="error/error-v1"/>
          </Switch>);
      }else{
        switch(user.role) {
          case 'Director':
            return (
              <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    renderSwitchRedirect(user)
                }
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                
                <ContentRoute path="/get-task" component={GetTasksPage}/>
                <ContentRoute path="/add-task" component={AddTaskPage}/>
        
                <ContentRoute path="/inicio" component={InicioPage}/>
                <ContentRoute path="/analytics" component={DashboardPage}/>
                <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage}/>
                <ContentRoute path="/tutorial/course/lesson" component={LessonPage}/>
                <ContentRoute path="/tutorial/course" component={CoursePage}/>
                <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>
                {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage}/>}
                <ContentRoute path="/get-marketers" component={MarketerPage}/>
                <ContentRoute path="/get-group-agencies" component={GroupAgenciesPage}/>
                <ContentRoute path="/get-lessons" component={ImagesPage}/>
                <ContentRoute path="/get-evaluations/evaluations/results" component={EvaluationResultPage}/>
                <ContentRoute path="/get-evaluations/evaluations" component={EvaluationListPage}/>
                <ContentRoute path="/get-evaluations" component={EvaluationPage}/>
                <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage}/>
                <ContentRoute path="/get-courses/course/lesson" component={LessonPage}/>
                <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage}/>
                <ContentRoute path="/get-courses/course" component={CoursePage}/>
                <ContentRoute path="/get-courses" component={CoursesPage}/>
                <ContentRoute path="/get-school" component={DataSchoolPage}/>
                <ContentRoute path="/get-campuses" component={DataCampusesPage}/>
        
                <ContentRoute path="/my-profile" component={DataUserPage}/>
  
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
              </Switch>);
          case 'Marketeer':
            return (
              <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    renderSwitchRedirect(user)
                }
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                
                <ContentRoute path="/get-task" component={GetTasksPage}/>
                <ContentRoute path="/add-task" component={AddTaskPage}/>
        
                <ContentRoute path="/inicio" component={InicioPage}/>
                <ContentRoute path="/analytics" component={DashboardPage}/>
                <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage}/>
                <ContentRoute path="/tutorial/course/lesson" component={LessonPage}/>
                <ContentRoute path="/tutorial/course" component={CoursePage}/>
                <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>
                {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage}/>}
                <ContentRoute path="/get-marketers" component={MarketerPage}/>
                <ContentRoute path="/get-group-agencies" component={GroupAgenciesPage}/>
                <ContentRoute path="/get-lessons" component={LessonPage}/>
                <ContentRoute path="/get-evaluations/evaluations/results" component={EvaluationResultPage}/>
                <ContentRoute path="/get-evaluations/evaluations" component={EvaluationListPage}/>
                <ContentRoute path="/get-evaluations" component={EvaluationPage}/>
                <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage}/>
                <ContentRoute path="/get-courses/course/lesson" component={LessonPage}/>
                <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage}/>
                <ContentRoute path="/get-courses/course" component={CoursePage}/>
                <ContentRoute path="/get-courses" component={CoursesPage}/>
  
                <ContentRoute path="/my-profile" component={DataUserPage}/>
        
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
              </Switch>);
          default:
            return (
              <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    renderSwitchRedirect(user)
                }
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                
                <ContentRoute path="/get-task" component={GetTasksPage}/>
                <ContentRoute path="/add-task" component={AddTaskPage}/>
        
                <ContentRoute path="/inicio" component={InicioPage}/>
                <ContentRoute path="/dashboard" component={DashboardPageDefault}/>
                <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage}/>
                <ContentRoute path="/tutorial/course/lesson" component={LessonPage}/>
                <ContentRoute path="/tutorial/course" component={CoursePage}/>
                <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>
                {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage}/>}
                {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage}/>}
                <ContentRoute path="/get-marketers" component={MarketerPage}/>
                <ContentRoute path="/get-group-agencies" component={GroupAgenciesPage}/>
                <ContentRoute path="/get-lessons" component={LessonPage}/>
                <ContentRoute path="/get-evaluations" component={EvaluationPage}/>
                <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage}/>
                <ContentRoute path="/get-courses" component={CoursesPage}/>
  
                <ContentRoute path="/my-profile" component={DataUserPage}/>
        
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
              </Switch>);
        }
      }
  };

  return (
    <Suspense fallback={<LayoutSplashScreen/>}>
      {renderSwitchRoute(user)}
    </Suspense>
  );
}
