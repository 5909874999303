import React from "react";
import {useSubheader} from "../../_metronic/_core";
import DataSchool from "../modulesSchool/DataSchool"
import { useIntl } from "react-intl";

export const DataSchoolPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(int1.formatMessage({id: "LEARNING_GENERAL.SCHOOL_DATA"}));
    return (<DataSchool/>)

}