import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ListPrograms from "../modulesWiyu/ListPrograms/";

export const LProgramsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Programs page");

  return (<><ListPrograms/></>);

};