import React, { useState, useEffect } from "react";
import {useSubheader} from "../../_metronic/_core";
import axios from 'axios';
import ServerURL from '../../ServerURL';

const url = ServerURL();

export const ImagesPage = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Lecciones");

    const [selectFile, setSelectFile] = useState(null)
    const [previewFile, setPreviewFile] = useState(null)
    const [textFolder, setTextFolder] = useState()
    const [textDescription, setTextDescription] = useState()
    const [allImages, setAllImages] = useState({images:[]})
    const [lengthImages, setlengthImages] = useState(0)

    // const onChangeHandler = event => {
    //   setSelectFile(event.target.files[0])
    // }

    function onFormSubmit(e){
        e.preventDefault() // Stop form submit
        fileUpload(selectFile).then((response)=>{
          console.log("Resultado: ", response.data);
        }).catch(()=>{
            console.log("Sucedio un error, verifique los datos")
        })
      }
    
    function onChangeText(e) {
        setTextFolder(e.target.value)
    }

    function onChangeTextD(e) {
        setTextDescription(e.target.value)
    }

    function onChange(e) {
        setSelectFile(e.target.files[0])
        setPreviewFile(URL.createObjectURL(e.target.files[0]))
    }

    async function fileUpload(file){
        if (textFolder && textDescription){
            const formData = new FormData();
            formData.append('nameFolder',textFolder)
            formData.append('description',textDescription)
            formData.append('file',file)
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const res = await axios.post(`${url}/api/public/upload-file`, formData, config)
            getImages()
            return res
        }
    }
    
    const renderImage = (image) => {
        return (
            <tr key={image.id} style={{textAlign: 'center'}}>
                <th style={{verticalAlign:"middle"}} scope="row">{image.id}</th>
                <td style={{verticalAlign:"middle"}}><a href={image.url} target="_blank" rel="noopener noreferrer">{image.url}</a></td>
                <th style={{verticalAlign:"middle"}} scope="row">{image.description}</th>
                <td style={{verticalAlign:"middle"}}><img id="imgPreiew" style={imageStyles} alt="Imagen" src={image.url}/></td>
                <td style={{verticalAlign:"middle"}}>{image.updated_at}</td>
            </tr>
        );
    }

    const imageStyles = {
        width: '35%'
    };

    const getImages = async () => {
        await axios.get(`${url}/api/public/get-images`)
        .then(res => {
            const images = res.data;
            setAllImages({images: images})
            setlengthImages(images.length)
        });
        console.log("Actualizado")
    }

    useEffect( () => {
        getImages()
    },[lengthImages]);

    return (
        <div>
            <div className="container p-4">
                <div className="card card-body">
                    <h3 style={{marginBottom:"1em"}}>Subida de imagenes</h3>
                    <form onSubmit={onFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="file">Nombre del folder</label>
                            <input type="text" className="form-control mb-5" onChange={onChangeText}/>
                            <label htmlFor="file">Descripción</label>
                            <input type="text" className="form-control mb-5" onChange={onChangeTextD}/>
                            <label htmlFor="file">Elige un archivo</label>
                            <input type="file" name="file" className="form-control-file" onChange={onChange}/>
                        </div>
                    <button type="submit" className="btn btn-primary btn-block">Upload</button>
                    </form>
                </div>
                <div style={{width:"50%", marginRight:"auto", marginLeft:"auto", marginBottom:"1em"}} className="d-flex justify-content-center mt-5 card card-body">
                    <h3 style={{textAlign: "center", marginBottom:"1em"}}>Preview de la imagen</h3>
                    <img id="imgPreiew" style={{alignSelf: "center"}} className="img-fluid" alt="ImagenPreview" src={previewFile}/>
                </div>
                <div className="card card-body">
                    <h3 style={{marginBottom:"1em"}}>Imagenes</h3>
                    <div className="table-responsive">
                        <table className="table">
                            <thead className="bg-primary text-light">
                                <tr>
                                <th style={{width:  '8.33%', textAlign: 'center'}}>ID</th>
                                <th style={{width: '25%', textAlign: 'center'}}>URL</th>
                                <th style={{width: '20%', textAlign: 'center'}}>Descripción</th>
                                <th style={{width: '30%', textAlign: 'center'}}>Imagen</th>
                                <th style={{width: '16.66%'}}>Fecha de modificación</th>
                                </tr>
                            </thead>
                            {/*Contenido de la tabla*/
                            allImages ?
                            <tbody>
                                {allImages.images.map((image) => renderImage(image))}
                            </tbody>
                            :
                            <></>
                            }
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
