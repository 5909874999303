import React from "react";
import {useSubheader} from "../../_metronic/_core";

import ExchangeOffices from "../modulesAccounting/ExchangeOffices/ExchangeOfficesTable"

export const ExchangeOfficesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Exchange Offices");

  return (<><ExchangeOffices /></>);
};