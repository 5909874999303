 import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
//import Avatar from '@material-ui/core/Avatar';
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const url = ServerURL();



class AgenciesTable extends Component {
            

  
  emptyAgency = {
    agency: '',
    logo: '',
    contact: '',   
    email: '',
    phone:'',
    website: '',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    name: '',
    contact: '',
    email: '',
    phone:'',
    website: '',

      /**
       * Aux messages
       */
      agencyMessage: '',
      logoMessage: '',
      contactMessage: '',
      emailMessage: '',
      phoneMessage: '',
      websiteMessage: '',

    agency: this.emptyAgency,
    agencies:null,
    ids:null,
    globalFilter: null,
    selectedAgencies: null,
    agencyDialog: false,
    deleteAgencyDialog: false,
    deleteAgencysDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveAgency = this.saveAgency.bind(this);
  this.deleteAgency = this.deleteAgency.bind(this);
  this.openNew = this.openNew.bind(this);
  //this.agencyBodyTemplate = this.agencyBodyTemplate.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editAgency = this.editAgency.bind(this);
  this.confirmDeleteAgency= this.confirmDeleteAgency.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedAgencies = this.deleteSelectedAgencies.bind(this);
  this.hideDeleteAgencyDialog = this.hideDeleteAgencyDialog.bind(this);
  this.hideDeleteAgenciesDialog = this.hideDeleteAgenciesDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);

  }


  
      componentDidMount() {
        this.getAgencies()

      }



      isValid(){
        const { agency,contact, email, phone, website } = this.state.agency
              const agencyMessage = this.validateAgency()
              const contactMessage = this.validateContact()
              const emailMessage = this.validateEmail()
              const phoneMessage = this.validatePhone()
              const websiteMessage = this.validateWebsite()


        
              if(agency && contact && email && phone && website ){
                if(!agencyMessage && !contactMessage && !emailMessage && !phoneMessage && !websiteMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
            validateAgency() {
              const agency = this.state.agency
              
              let agencyMessage;
              if (!agency.agency) {
                agencyMessage = 'Requerid';
              } else {
                agencyMessage = ''
              }
              this.setState({ agencyMessage })
              return agencyMessage
            }

            validateContact() {
              const agency= this.state.agency
              
              let contactMessage;
              if (!agency.contact) {
                contactMessage = 'Requerido';
              } else {
                contactMessage = ''
              }
              this.setState({ contactMessage })
              return contactMessage
            }


            

            validateEmail() {
                    const agency = this.state.agency
                    
                    let emailMessage;
                    if (!agency.email) {
                      emailMessage = 'Requerido';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(agency.email)) {
                      emailMessage = 'Correo electronico invalido';
                    } else {
                      emailMessage = ''
                    }
                    this.setState({ emailMessage })
                    return emailMessage
                  }

            validatePhone() {
              const agency = this.state.agency
              
              let phoneMessage;
              if (!agency.phone) {
                phoneMessage = 'Requerido';
              } else if (!/^[A-Z0-9._%+-]{6,12}$/i.test(agency.phone)) {
                phoneMessage = 'Telefono invalido';
              } else {
                phoneMessage = ''
              }
              this.setState({ phoneMessage })
              return phoneMessage
            }

            validateWebsite() {
              const agency = this.state.agency
              
              let websiteMessage;
              if (!agency.website) {
                websiteMessage = 'Requerido';
              } else if (!/^[A-Z0-9._%+-]+\.[A-Z]{2,4}$/i.test(agency.website)) {
                websiteMessage = 'Website invalido';
              } else {
                websiteMessage = ''
              }
              this.setState({ websiteMessage })
              return websiteMessage
            }




            getTextAuxAgency(){
              const message = this.state.agencyMessage
              return message
            }
        
            getTextAuxContact(){
              const message = this.state.contactMessage
               return message
             }
      
            getTextAuxEmail(){
             const message = this.state.emailMessage
              return message
            }

            getTextAuxPhone(){
              const message = this.state.phoneMessage
               return message
             }

             getTextAuxWebsite(){
              const message = this.state.websiteMessage
               return message
             }


            
      getAgencies = async () =>{
         
          try {
             
              let res = axios.get(`${url}/api/auth/agencies`)
              .then(res => {
                const agencies = res.data;            
                this.setState({ agencies })
              });                     
                console.log(res.data)
              }catch(error){
         }
      }

      deleteAgency(){
        let data = {...this.state.agency};

        try {
        let res = axios.delete(`${url}/api/auth/agency/${data.id}`)
          .then(res => {
          this.getAgencies()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteAgencyDialog: false,
            agency: this.emptyAgency
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agency Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveAgency() {
        let state = { submitted: true };

        const decision = this.isValid();
        if (decision) {


       // if (this.state.agency.agency.trim()) {       
          let data = {...this.state.agency};


        if (this.state.agency.id) {

            try {
              let res = axios.put(`${url}/api/auth/agency/${data.id}`,data)
              .then(res =>{
              this.getAgencies()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agency Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/agency`,data)
            
             .then(res => {
                 this.getAgencies()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agency Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          agencyDialog: false,
          agency: this.emptyAgency
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.setState({
            agency: this.emptyAgency,
            submitted: false,
            agencyDialog: true
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let agency = {...this.state.agency};
        agency[`${name}`] = val;

        this.setState({ agency});
      }

      onRoleChange(e) {
        let agency = {...this.state.agency};
        agency['role'] = e.value;
        this.setState({ agency });
      }


      hideDialog() {
          this.setState({
              submitted: false,
              agencyDialog: false,
              agencyMessage: '',
              contactMessage: '',
              emailMessage: '',
              phoneMessage: '',
              websiteMessage: ''
        })  
      } 


      editAgency(agency) {
          this.setState({
              agency: { ...agency },
              agencyDialog: true
          })

      }

      confirmDeleteAgency(agency) {
        this.setState({
            agency,
            deleteAgencyDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteAgenciesDialog: true })
      }

      deleteSelectedAgencies() {
        let agencies = this.state.agencies.filter(val => !this.state.selectedAgencies.includes(val));
        let ids = this.state.agencies.filter(val => this.state.selectedAgencies.includes(val));
        console.log(ids)

        try {
        let res = axios.delete(`${url}/api/auth/agency/${ids.id}`)
          .then(res => {
          this.getAgencies()
        })
        if (!res.error) {
          this.setState({
            agencies,
            deleteAgenciesDialog: false,
            selectedAgencies: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agencies Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteAgencyDialog() {
        this.setState({ deleteAgencyDialog: false })
      }
        
      hideDeleteAgenciesDialog() {
        this.setState({ deleteAgenciesDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
     
    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editAgency(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteAgency(rowData)} />
            </>
        )
      }
      
      




      render(){
       const deleteAgencyDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAgencyDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteAgency} />
        </>
       );

       const AgencyDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveAgency} />}
            </FormattedMessage>
        </>
       );

       const deleteAgenciesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAgencysDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedAgencies} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
            <FormattedMessage id="ADMIN_AGENCY.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_AGENCY.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedAgencies || !this.state.selectedAgencies.length} />}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_AGENCY">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div>

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>

          
          <div>
           {/*SHOW AGENCY */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.agencies} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Agency found." loading={this.state.loading}
                        selection={this.state.selectedAgencies} onSelectionChange={e => this.setState({ selectedAgencies: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                       {/* <Column field="logo" body={this.agencyBodyTemplate}></Column>*/}
                        <Column field="agency" header={this.state.campusText.Agency}  filter filterPlaceholder={this.state.campusText.SearchByAgency} sortable></Column>
                        <Column field="contact" header={this.state.campusText.Contact}  filter filterPlaceholder={this.state.campusText.SearchByContact} sortable></Column>
                        <Column field="email" header={this.state.campusText.Email}  filter filterPlaceholder={this.state.campusText.SearchByEmail} sortable></Column>
                        <Column field="phone" header={this.state.campusText.Phone} filter filterPlaceholder={this.state.campusText.SearchByPhone} sortable></Column>
                        <Column field="website" header={this.state.campusText.Website} filter filterPlaceholder={this.state.campusText.SearchByWebsite} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE SCHOOL */}
          <Dialog visible={this.state.deleteAgencyDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAgencyDialogFooter} onHide={this.hideDeleteAgencyDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.agency && <span> Are you sure you want to delete <b>{this.state.agency.name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteAgencysDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAgenciesDialogFooter} onHide={this.hideDeleteAgenciesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.agency && <span>Are you sure you want to delete the selected agencies?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE AGNECY & CREATE AGENCY*/}
          <Dialog visible={this.state.agencyDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"310px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_AGENCY"/>} modal footer={AgencyDialogFooter} onHide={this.hideDialog}>
          <br></br>
             <div className="form-group row">
              {/* Agency */}
              <div className="col-lg-6"> 
              <span className="p-float-label">                      
                <InputText
                  style={{ width: 250, height:33 }}
                  id="agency"
                  variant="outlined"            
                  margin="dense"
                  value={this.state.agency.agency}             
                  onChange={(e) => this.onInputChange(e, 'agency')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.agency.agency  })}
                  />
              <label htmlFor="agency">Agency</label>    
              </span>
              <small id="agency-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAgency(this.state)}</small>
              </div>
              {/* Contact */}
              <div className="col-lg-6">
              <span className="p-float-label">                      
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="contact"
                  variant="outlined"
                  margin="dense"
                  value={this.state.agency.contact}             
                  onChange={(e) => this.onInputChange(e, 'contact')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.agency.contact  })}
                  />
              <label htmlFor="contact">Contact</label>    
              </span>    
              <small id="contact-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxContact(this.state)}</small>
              </div>
              </div>
                  {/* Email*/}
                  <br></br>
                  <div className="form-group row">
                  <div className="col-lg-6">
                  <span className="p-float-label">                      
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="email"
                  type="email"
                  variant="outlined"
                  margin="dense"
                  value={this.state.agency.email}             
                  onChange={(e) => this.onInputChange(e, 'email')} 
                  keyfilter="email"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.agency.email  })}
                  />
             <label htmlFor="email">Email</label>    
              </span>    
              <small id="email-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEmail(this.state)}</small>
              </div>
              <div className="col-lg-6">
              <span className="p-float-label">                      
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="phone"
                  type="phone"
                  variant="outlined"
                  margin="dense"
                  value={this.state.agency.phone}             
                  onChange={(e) => this.onInputChange(e, 'phone')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.agency.phone  })}
                  />
              <label htmlFor="phone">Phone</label>    
              </span>   
              <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPhone()}</small>
              </div>
              </div>
              <br></br>
              <div className="form-group row">
              <div className="col-lg-6">
              <span className="p-float-label">                      
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="website"
                  type="website"
                  variant="outlined"
                  margin="dense"
                  value={this.state.agency.website}             
                  onChange={(e) => this.onInputChange(e, 'website')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.agency.website  })}
                  />
              <label htmlFor="website">Website</label>        
              </span>    
              <small id="website-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxWebsite(this.state)}</small>
              </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(AgenciesTable);