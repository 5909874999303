import React from "react";
import {useSubheader} from "../../_metronic/_core";
import CampusesTable from "../modulesAdmin/AdminEnterprises/CampusesTable"

export const CampusesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Campus");

  return (<><CampusesTable/></>);

};