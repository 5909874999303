import React, { Component } from 'react';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from 'primereact/dialog';
import { Link, Redirect }  from "react-router-dom";
import "primeflex/primeflex.css";

import axios from 'axios';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { FormattedMessage, injectIntl } from 'react-intl';


const url = ServerURL();

class EvaluationEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      evaluations: null,
      displayConfirmation: false,
      reasonFail: [],
      reasonDialog: false,
      redirect: false,
      showMessage: false
    };

    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.createEvaluation = this.createEvaluation.bind(this);
    this.isEvaluationComplete = this.isEvaluationComplete.bind(this);
    this.showInfo = this.showInfo.bind(this);
  }

  componentDidMount() {
    this.getEvaluations()
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  onOpen(name){
    this.setState({
      [`${name}`]: true
    })
  }

  isEvaluationComplete(){
    let questionsComplete = true;
    let answersTextComplete = true;
    let answersCorrectComplete = false;
    let existTrueInAnswer = false
    let correctsInQuestions = 0
    this.state.evaluations[0].questions.forEach(question => {
      if(question.text === ""){
        questionsComplete = false;
      }
    });
    this.state.evaluations[0].answers.forEach(question => {
      question.forEach(answer => {
        if(answer.text === ""){
          answersTextComplete = false
        }
        if(answer.correct === true){
          existTrueInAnswer = true
        }
      })
      if(existTrueInAnswer){
        correctsInQuestions++;
      }
      existTrueInAnswer = false;
    });
    if(correctsInQuestions===5 || correctsInQuestions===10){
      answersCorrectComplete = true
    }

    const messages = []

    if(!questionsComplete){
      const message = this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.QUESTIONS_ARE_MISSING'})
      messages.push(message)
    }
    if(!answersTextComplete){
      const message = this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.ANSWERS_WITHOUT_TEXT'})
      messages.push(message)
    }
    if(!answersCorrectComplete){
      const message = this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.QUESTION_WITHOUT_ANSWER'})
      messages.push(message)
    }
    this.showInfo(messages)
  }

  showInfo(messages) {
    const timeLife = 10000
    switch(messages.length){
      case 3:
        this.toast.show([
          {severity:'info', summary:this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.INCOMPLETE_ASSESSMENT'}), detail: messages[0], life: timeLife},
          {severity:'info', summary:this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.INCOMPLETE_ASSESSMENT'}), detail: messages[1], life: timeLife},
          {severity:'info', summary:this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.INCOMPLETE_ASSESSMENT'}), detail: messages[2], life: timeLife}
        ]);
        break;
      case 2:
        this.toast.show([
          {severity:'info', summary:this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.INCOMPLETE_ASSESSMENT'}), detail: messages[0], life: timeLife},
          {severity:'info', summary:this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.INCOMPLETE_ASSESSMENT'}), detail: messages[1], life: timeLife}
        ]);
        break;
      case 1:
        this.toast.show({severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.INCOMPLETE_ASSESSMENT'}), detail: messages[0], life: timeLife});
        break;
      default:
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.FULL_ASSESSMENT'}), life: timeLife});
        this.saveEvaluation()
    }
  }

  createEvaluation(){
    this.setState({evaluations:[{
      questions: [
        {text: ""},
        {text: ""},
        {text: ""},
        {text: ""},
        {text: ""},
        {text: ""},
        {text: ""},
        {text: ""},
        {text: ""},
        {text: ""}
      ],
      answers: [
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ],
        [
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          },
          {
            text: "",
            correct: false
          }
        ]  
      ]
    }]});
  }

  getEvaluations = async () =>{
    try {
      await axios.get(`${url}/api/auth/evaluation/${this.props.dataLesson.id}`)
      .then(res => {
        const { redirect } = this.state; 
        const boolAux = redirect ? true : false;
        this.setState({ evaluations: res.data, showMessage: boolAux })
      });
      }catch(error){
    }
  }

  saveEvaluation = async () =>{
    const data = {...this.state.evaluations[0], fk_id_lesson: this.props.dataLesson.id}
    try {
      await axios.post(`${url}/api/auth/evaluation`, data)
      .then(res => {     
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.LOADED_SUCCESS'}), life: 6000});
        if(!this.props.dataLesson.lessonWithEvaluation){
          const dataLesson = {
            ...this.props.dataLesson,
            lessonWithEvaluation: true
          }
          this.props.updatedLesson(dataLesson)
        }
        this.setState({redirect: true})
        this.getEvaluations();
      })
      .catch(error => {
        this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.DELETE_ERROR'}), life: 6000});
      });
      }catch(error){
        this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.SERVER_ERROR'}), life: 6000});
    }
  }

  deleteEvaluation = async () =>{
    try {
      console.log(this.state.evaluations[0].id)
      await axios.delete(`${url}/api/auth/evaluation/${this.state.evaluations[0].id}`)
      .then(res => {     
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.ASSESSMENT_REMOVED'}), life: 6000});
        if(this.props.dataLesson.lessonWithEvaluation){
          const dataLesson = {
            ...this.props.dataLesson,
            lessonWithEvaluation: false
          }
          this.props.updatedLesson(dataLesson)
        }
        this.getEvaluations()
      })
      .catch(error => {
        this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.DELETE_ERROR'}), life: 6000});
      });
      }catch(error){
        this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.SERVER_ERROR'}), life: 6000});
    }
  }


  renderFooter(name) {
    return (
      <div>
        <FormattedMessage id="GENERAL.NO">
          {message => <Button label={message} icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" autoFocus/>}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
          {message => <Button label={message} icon="pi pi-check" onClick={() => this.deleteEvaluation()} />}
        </FormattedMessage>
      </div>
    );
  }

  changeQuestion(numQ,text){
    const evaluation = this.state.evaluations[0]
    if(this.props.dataLesson.dataCourse.published === 0){
      evaluation.questions[numQ].text = text
    }

    this.setState({evaluations: [{...evaluation}]})
  }

  changeAnswersCorrect(numQ,numQA){
    const evaluation = this.state.evaluations[0]
    if(this.props.dataLesson.dataCourse.published === 0){
      for (let index = 0; index < evaluation.answers[numQ].length; index++) {
        if(index === numQA){
          evaluation.answers[numQ][index].correct = !evaluation.answers[numQ][numQA].correct
        }else{
          evaluation.answers[numQ][index].correct = false
        } 
      }
    }

    this.setState({evaluations: [{...evaluation}]})
  }

  changeAnswersText(numQ,numQA,text){
    const evaluation = this.state.evaluations[0]
    if(this.props.dataLesson.dataCourse.published === 0){
      evaluation.answers[numQ][numQA].text = text
    }

    this.setState({evaluations: [{...evaluation}]})
  }

  renderAnswerQuestion(numQ,numQA){
    const evaluation = this.state.evaluations[0]
    return(
      <div key={`${numQ}-${numQA}`} className="p-col-6">
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon">
          <RadioButton checked={evaluation.answers[numQ][numQA].correct} onChange={(e) => this.changeAnswersCorrect(numQ,numQA)} />
          </span>
          <FormattedMessage id="LEARNING_ASSESSMENT.ANSWER">
          {message => <InputText placeholder={`${message} ${numQA+1}...`} value={evaluation.answers[numQ][numQA].text} onChange={e => this.changeAnswersText(numQ,numQA,e.target.value)}/>}
          </FormattedMessage>
        </div>
      </div>
    )
  }

  renderQuestion(numQ){
    const evaluation = this.state.evaluations[0]
    return(
      <div key={numQ} className="mb-8">
        <h4><FormattedMessage id="LEARNING_ASSESSMENT.QUESTION"/> {numQ+1}:</h4>
        <FormattedMessage id="LEARNING_ASSESSMENT.WRITE_QUESTION">
          {message => (
            <InputText 
              placeholder={`${message} ${numQ+1}...`} 
              style={{fontSize:"22px", width:"100%", border:"none"}} 
              className="p-inputtext-lg p-d-block mb-2" 
              value={evaluation.questions[numQ].text} onChange={e => this.changeQuestion(numQ,e.target.value)}/>
          )}
        </FormattedMessage>
        <div className="p-grid">
          {this.state.evaluations[0].answers[numQ].map((data, numQA) => this.renderAnswerQuestion(numQ, numQA))}
        </div>
      </div>
    )
  }

  render() {

    const { redirect, showMessage } = this.state;
    console.log(redirect)
    if(redirect && showMessage) {
      return <Redirect to={{
        pathname: "/get-courses/course"
      }}/>;
    }

    return (
      <div>
        <Link className="mb-5" to={{
            pathname:"/get-courses/course/lesson",
            aboutProps:{
                data: this.props.dataLesson
            }
        }}>
        <button className="btn"><KeyboardBackspaceSharpIcon/></button>
        </Link>
        <Toast ref={(el) => this.toast = el} />
        <div className="card p-6">
          <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
            {message => (
              <Dialog header={message} visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter('displayConfirmation')} onHide={() => this.onHide('displayConfirmation')}>
                <div className="confirmation-content">
                    <span className="d-flex"><i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', alignSelf: "center" }}/><FormattedMessage id="LEARNING_ASSESSMENT.DELETE_ASSESSMENT"/></span>
                </div>
              </Dialog>
            )}
          </FormattedMessage>
          <div className="p-grid p-nogutter">
            <div className="font-weight-bold">
            <h1><FormattedMessage id="LEARNING_ASSESSMENT.TITLE"/>:&nbsp;&nbsp;{this.props.dataLesson.name}</h1>
            </div>
          </div>
          <hr className="pb-5"/>
          {
            this.state.evaluations ?
              this.state.evaluations.length ?
              <>
                <h2 className="mb-8" style={{textAlign:"center"}}><FormattedMessage id="LEARNING_ASSESSMENT.COMPLETE_ASSESSMENT"/></h2>
                {this.state.evaluations[0].questions.map((data, index) => this.renderQuestion(index))}
                {
                  this.props.dataLesson.dataCourse.published === 0 ?
                  <div className="d-flex justify-content-between">
                    <button className="btn btn-danger" onClick={this.deleteEvaluation}><FormattedMessage id="LEARNING_ASSESSMENT.REMOVE_ASSESSMENT"/></button>
                    <button className="btn btn-primary" onClick={this.isEvaluationComplete}><FormattedMessage id="GENERAL.SAVE"/></button>
                  </div>
                  :
                  <></>
                }
              </>
              :
              <div style={{textAlign: "center"}}>
                <h2><FormattedMessage id="LEARNING_ASSESSMENT.NO_ASSESSMENT"/></h2>
                <button className="mt-6 btn btn-primary btn-lg" onClick={this.createEvaluation}><FormattedMessage id="LEARNING_COURSE_LESSON.ADD_ASSESSMENT"/></button>
              </div>
            :
            <div style={{textAlign: "center"}}>
              <h3><FormattedMessage id="LEARNING_ASSESSMENT.LOADING_ASSESSMENT"/>...</h3>
              <div style={{padding: "4em"}}>
                <i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  dataLesson: state.auth.dataLesson
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(EvaluationEdit)
)