import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';


import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';




import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const url = ServerURL();



class AgreementsTable extends Component {
            

  
  emptyAgreement = {
    fk_id_agency: '',
    fk_id_school: '',   
    updated_at:'',

}

 

constructor(props) {
  super(props);  

  this.state = { 

    


    fk_id_agency: '',
    fk_id_school: '',
    updated_at:'',

    /**
       * Aux messages
       */
      fk_id_agencyMessage: '',
      fk_id_schoolMessage: '', 

      
    agreement: this.emptyAgreement,
    schools:[],
    agreements:[],
    globalFilter: null,
    agreementDialog: false,
    selectedAgreement: null,
    selectedAgency: null,
    selectedSchool: null, 
    resetAgency:null,
    deleteAgreementDialog: false,
    deleteAgreementsDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveAgreement = this.saveAgreement.bind(this);
  this.deleteAgreement = this.deleteAgreement.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editAgreement = this.editAgreement.bind(this);
  this.confirmDeleteAgreement= this.confirmDeleteAgreement.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedAgreements = this.deleteSelectedAgreements.bind(this);
  this.hideDeleteAgreementDialog = this.hideDeleteAgreementDialog.bind(this);
  this.hideDeleteAgreementsDialog = this.hideDeleteAgreementsDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onAgencyChange = this.onAgencyChange.bind(this);
  this.onSchoolChange = this.onSchoolChange.bind(this);
  this.Check= this.Check.bind(this);
  //this.Date= this.Date.bind(this);
  


  }


    
      componentDidMount() {
        this.getAgreements()

      }

            isValid(){
              const { fk_id_agency} = this.state.agreement
                    const fk_id_agencyMessage = this.validateAgency()
            

        
              if(fk_id_agency){
                if(!fk_id_agencyMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
            }
            validateAgency() {
              const agreement = this.state.agreement
              
              let fk_id_agencyMessage;
              if (!agreement.fk_id_agency) {
                fk_id_agencyMessage = 'Requerid';
              } else {
                fk_id_agencyMessage = ''
              }
              this.setState({ fk_id_agencyMessage })
              return fk_id_agencyMessage
            }      

            getTextAuxAgency(){
              const message = this.state.fk_id_agencyMessage
              return message
            }

     

       


      getAgencies = async () =>{
         
        try {
           
            let res = axios.get(`${url}/api/auth/agencies`)
            .then(res => {
              const agencies= res.data;
              console.log(agencies)
              this.setState({ agencies })
            });                     
              console.log(res.data)
            }catch(error){
        }
      }

      

      getSchools = async () =>{
         
        try {
           
            let res = axios.get(`${url}/api/auth/schools`)
            .then(res => {
              const schools= res.data;
              console.log(schools)
              this.setState({ schools })
            });                     
              console.log(res.data)
            }catch(error){
        }
      }

      getAgreements = async () =>{

      

         
          try {
             
              let res = axios.get(`${url}/api/auth/agreements`)
              .then(res => {
                const agreements = res.data;  
                console.log(agreements) 
                this.Date(agreements)
                     
                this.setState({ agreements })
              });                     
                console.log(res.data)
              }catch(error){

         }
      }

     

      deleteAgreement(){
        let data = {...this.state.agreement};

        try {
        let res = axios.delete(`${url}/api/auth/agreement/${data.id}`)
          .then(res => {
          this.getAgreements()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteAgreementDialog: false,
            agreement: this.emptyAgreement
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveAgreement() {
        let state = { submitted: true };

        const decision = this.isValid();

        if (decision) {

        
       //if (this.state.agreement.fk_id_agency.trim()) {       
          let data = {...this.state.agreement};

        if (this.state.agreement.id) {


            try {
              let res = axios.put(`${url}/api/auth/agreement/${data.id}`,data)
              .then(res =>{
              this.getAgreements()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agreement Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/agreement`,data)
             .then(res => {
                 this.getAgreements()
                 console.log(res)
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agreement Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
            data,
          agreementDialog: false,
          agreement: this.emptyAgreement,
          selectedAgency: null,
          resetAgency:null,
        }; 
      }
      
      this.setState(state);
    }
      
   
   

      onAgencyChange(e) {
        let agreement = {...this.state.agreement};
        agreement['fk_id_agency'] = e.value;
        this.setState({ selectedAgency: e.value, agreement});  
        this.getSchools()
      }   

     

  

      onSchoolChange(e) {
        let agreement = {...this.state.agreement};   


         if( typeof agreement.fk_id_school ==='string'){

          agreement.fk_id_school =agreement.fk_id_school.split(',')    
          agreement.fk_id_school = agreement.fk_id_school.map(Number);


                  if (e.checked) {       
                                
                  agreement['fk_id_school'].push(e.value);
                  //console.log("HOLA",agreement.fk_id_school)
                  //console.log("FINAL",agreement)

                  }else {
                    for (let i = 0; i < agreement.fk_id_school.length+1; i++) {
                       
                        
        
                        if (agreement.fk_id_school[i] === e.value) {
                            console.log(agreement.fk_id_school[i], e.value)

                            agreement.fk_id_school.splice(i, 1);
                            break;
                        }
                    }  
                  } 
                  this.setState({agreement} );


        }else{
                if (e.checked) {       
                                
                  agreement['fk_id_school'].push(e.value);
                  //console.log("HOLA",agreement.fk_id_school)
                  //console.log("FINAL",agreement)
                  
                }else {
                    for (let i = 0; i < agreement.fk_id_school.length+1; i++) {

        
                        if (agreement.fk_id_school[i] === e.value) {
                            console.log(agreement.fk_id_school[i], e.value)

                            agreement.fk_id_school.splice(i, 1);
                            break;
                        }
                    }  
                  } 
                  this.setState({agreement});

          }
        
       

    }
     

      openNew() {
        this.getAgencies()
        this.getSchools()
        this.setState({
            selectedAgency: null, 
            selectedSchool: null,  
            resetAgency:"Agency",
            submitted: false,
            agreementDialog: true,   
            school:null,    
            agreement: this.emptyAgreement,
            
            
            

        })  
      }


      
      Check(val) {
        let a= {...this.state.agreement};   

        if( typeof a.fk_id_school ==='string'){
              a.fk_id_school =a.fk_id_school.split(',')
              a.fk_id_school = a.fk_id_school.map(Number); 
           
          return a.fk_id_school.some(arrVal => val === arrVal); 
                   
        }else{

          return a.fk_id_school.some(arrVal => val === arrVal);

        }
      }

      Date(e){
             
             
             e.map((agreement) => {
             //console.log("E:",agreement.updated_at)

             const date = new Date(agreement.updated_at);
             

              let year = date.getFullYear();
              let month = date.getMonth()+1;
              let dt = date.getDate();

              if (dt < 10) {
                dt = '0' + dt;
              }
              if (month < 10) {
                month = '0' + month;
              }

              let new_date = dt +'/' + month + '/'+year
              //console.log("New Data:",new_date)

              agreement.updated_at= new_date

              return agreement

            });


             
      }

    

      editAgreement(agreement) {
          this.getAgencies()
          this.getSchools()
          this.setState({
              selectedAgency: null,
              resetAgency:null,
              agreement: { ...agreement },
              agreementDialog: true,
              //submitted: false,

          })

          
      }

      hideDialog() {
        this.setState({
            submitted: false,
            agreementDialog: false,
            fk_id_agencyMessage: '',
           // fk_id_schoolMessage:'',
      })  
    } 



      confirmDeleteAgreement(agreement) {
        this.setState({
            agreement,
            deleteAgreementDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteAgreementsDialog: true })
      }

      deleteSelectedAgreements() {
        let agreements = this.state.agreements.filter(val => !this.state.selectedAgreements.includes(val));
        let data = this.state.agreements.filter(val => this.state.selectedAgreements.includes(val));

        try {
        let res = axios.delete(`${url}/api/auth/agreements`,{data})
          .then(res => {
          this.getAgreements()
        })
        if (!res.error) {
          this.setState({
            agreements,
            deleteAgreementsDialog: false,
            selectedAgreements: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agreements Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteAgreementDialog() {
        this.setState({ deleteAgreementDialog: false })
      }
        
      hideDeleteAgreementsDialog() {
        this.setState({ deleteAgreementsDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
      

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editAgreement(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteAgreement(rowData)} />
            </>
        )
      }
      

     
      




      render(){
        const deleteAgreementDialogFooter = (
         <>
             <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAgreementDialog} />
             <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteAgreement} />
         </>
        );
 
        const agreementDialogFooter = (
         <>
             <FormattedMessage id="GENERAL.CANCEL">
               {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
             </FormattedMessage>
             <FormattedMessage id="GENERAL.SAVE">
               {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveAgreement} />}
             </FormattedMessage>
         </>
        );
 
        const deleteAgreementsDialogFooter = (
         <>
             <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAgreementsDialog} />
             <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedAgreements} />
         </>
     );
     
 
 
        return(       
           <div>
           <div className= "card-header flex-wrap border-0 pt-6 pb-0">
           <Toast ref={(el) => this.toast = el} />  
           <h3 className="card-label" >
             <FormattedMessage id="ADMIN_AGREEMENT.TITLE"/>
           <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_AGREEMENT.SUBTITLE"/></span>
           </h3>
             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
              {/*TOOL BAR */} 
 
 
                     <div style={{ marginRight: 10}}>
                       <FormattedMessage id="GENERAL.EXPORT">
                         {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                       </FormattedMessage>
                     </div>
 
                     <div style={{ marginRight: 10}}>
                       <FormattedMessage id="GENERAL.DELETE">
                         {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedAgreements || !this.state.selectedAgreements.length} />}
                       </FormattedMessage>
                     </div>
 
                     <div style={{ marginRight: 10}}>
                       <FormattedMessage id="OPTION.NEW_AGREEMENT">
                         {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                       </FormattedMessage> 
                     </div>  
 
                     <div style={{ marginRight: 10}}>
                     <p> <span className="p-input-icon-left">
                     <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                      </span></p>
                     </div>  
                     
             </div>
           </div>
           
           <div>
            {/*SHOW ROLE */}
                 <div className="card">
                     <DataTable 
                         ef={(el) => this.dt = el} value={this.state.agreements} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                         currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                         globalFilter={this.state.globalFilter} emptyMessage="No Agreement found." loading={this.state.loading}
                         selection={this.state.selectedAgreements} onSelectionChange={e => this.setState({ selectedAgreements: e.value })} dataKey="id">
                         <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                         <Column field="id" header={this.state.campusText.Id} sortable></Column>
                         <Column field="enterprise_name" header={this.state.campusText.Agency} sortable></Column>
                         <Column field="updated_at" header={this.state.campusText.Date} sortable></Column>
                         <Column body={this.actionBodyTemplate}></Column>
                     </DataTable>
                 </div>
            </div>
            
 
           <div> 
           {/*DELETE ROLE */}
           <Dialog visible={this.state.deleteAgreementDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAgreementDialogFooter} onHide={this.hideDeleteAgreementDialog}>
                     <div className="confirmation-content">
                         <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                         {this.state.agreement && <span> Are you sure you want to delete <b>{this.state.agreement.agency}</b>?</span>}
                     </div>
           </Dialog>
           </div>
 
           {/*DELETE WITH TOOL BAR*/}
           <Dialog visible={this.state.deleteAgreementsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAgreementsDialogFooter} onHide={this.hideDeleteAgreementsDialog}>
                     <div className="confirmation-content">
                         <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                         {this.state.agreement && <span>Are you sure you want to delete the selected agreements?</span>}
                     </div>
           </Dialog>
 
 
           <div>
           {/*UPDATE AGREEMENT & CREATE AGREEMENT*/}
           <Dialog visible={this.state.agreementDialog} contentStyle={{maxHeight:"1600px", width:"1600px", height:"800px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_AGREEMENT"/>} modal footer={agreementDialogFooter} onHide={this.hideDialog}>
               <br></br>
               <div className="form-group row">
                {/* Agency */}
               <div className="col-lg-6">      
               <span className="p-float-label">          
               <Dropdown
                   style={{ width: 190, height:33 }}
                   id="agency"
                   variant="outlined"
                   placeholder={this.state.resetAgency || this.state.agreement.enterprise_name}
                   margin="dense"
                   value= { this.state.selectedAgency || "enterprise_name" } 
                   options={this.state.agencies} 
                   onChange={this.onAgencyChange}  
                   optionLabel="enterprise_name" 
                   optionValue="id"
                   />
                   <label htmlFor="agency">Agency</label>
               </span>    
              <small id="agency-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAgency()}</small>
               </div>
               </div>
 
               <br></br>
               <div className="form-group row">
                {/* School */}
                    
             {
               this.state.schools.map((school) => {  
             

              
               return(
               <div key={school.id} className="p-field-checkbox">
               <Checkbox 
               style={{ width: 35, height:35 }}
               variant="outlined"
               margin="dense"
               inputid="id"
               name="school" 
               value={school.id} 
               onChange={this.onSchoolChange} 
               checked={this.Check(school.id)} 
               />               
               <div className="form-group row">

               <div className="col-lg-3">      
               <span className="p-float-label">                 
               <div className="school-list-item">
                 <img src={school.logo} className="img" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={school.enterprise_name} />
                   <div className="school-list-detail">
                         <div className="school-name"  inputid="id">{school.enterprise_name}</div>
                   </div>
               </div>
               </span>    
               </div>  
               </div>
               </div>
               )
               })
             }
              
               </div>
           </Dialog>
         </div>
 
 
         <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
         <Fab color="secondary">
           <AddIcon />
         </Fab>
       </Tooltip>
    </div>  
          
     )
   }
 }
 export default withRouter(AgreementsTable);