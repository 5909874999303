import React from "react";
import {useSubheader} from "../../_metronic/_core";
import CustomerSuccessItems from "../modulesWiyu/CustomerSuccess/CustomerSuccessItems";

export const CustomerSuccessItemsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Customer Success");

  return (<><CustomerSuccessItems/></>);

};