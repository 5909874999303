import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/_core";
import {ContentRoute} from "../_metronic/layoutAccounting";

import {BuilderPage} from "./pagesAccounting/BuilderPage";
import {MyPage} from "./pagesAccounting/MyPage";
import {DashboardPage} from "./pagesAccounting/DashboardPage";
import {DashboardPageDefault} from "./pagesAccounting/DashboardPageDefault";
import {DashboardAgencyPage} from "./pagesAccounting/Agencies/DashboardAgencyPage";


import { GetTasksPage } from './pagesAccounting/GetTasksPage';
import { AddTaskPage } from './pagesAccounting/AddTaskPage';
import { UsersPage } from './pagesAccounting/UsersPage';
import { AgenciesPage } from './pagesAccounting/AgenciesPage';
import { CampusesPage } from './pagesAccounting/CampusesPage';
import { ProgramTypesPage } from './pagesAccounting/ProgramTypesPage';
import { CoursesPage } from './pagesAccounting/CoursesPage';

import { OfficesPage } from './pagesAccounting/OfficesPage';
import { SchoolsPage } from './pagesAccounting/SchoolsPage';
import { InvoicesPage } from './pagesAccounting/InvoicesPage';
import { AgencyProfitAgencyPage } from "./pagesAccounting/AgencyProfitPage";
import { AgentPage } from "./pagesAgency/AgentPage";


// import { AgencyDataPage } from "./pagesSettings/AgencyDataPage";

//import { ScriptEdvisorPage } from './pagesAccounting/ScriptEdvisorPage';


import { useSelector } from "react-redux";

import { RolesPage } from './pagesAccounting/RolesPage';
import { DataAgencyPage } from "./pagesAgency/DataAgencyPage";

const GoogleMaterialPage = lazy(() =>
  import("./modulesAdmin/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modulesAdmin/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modulesAdmin/ECommerce/pages/eCommercePage")

);

const renderSwitchRedirect = (role) => {
  switch(role) {
    case 'Admin':
      return <Redirect exact from="/" to="/analytics"/>;
    default:
      return <Redirect exact from="/" to="/dashboard"/>;
  }
};

const renderSwitchRoute = (role) => {
  switch(role) {
    case 'Admin':
      return <ContentRoute path="/analytics" component={DashboardPage}/>;
    default:
      return <ContentRoute path="/dashboard" component={DashboardPageDefault}/>;
  }
};

export default function BasePageAccounting() {
  const { user } = useSelector(state => state.auth);
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen/>}>
      <Switch>
        {
            /* Redirect from root URL to /dashboard. */
            renderSwitchRedirect(user.role)
        }
        {renderSwitchRoute(user.role)}
        <ContentRoute path="/dashboard-agency" component={DashboardAgencyPage}/>
        <ContentRoute path="/builder" component={BuilderPage}/>
        <ContentRoute path="/my-page" component={MyPage}/>
        
        <ContentRoute path="/get-task" component={GetTasksPage}/>
        <ContentRoute path="/add-task" component={AddTaskPage}/>

        <ContentRoute path="/get-users" component={UsersPage}/>
        <ContentRoute path="/get-roles" component={RolesPage}/>
        <ContentRoute path="/get-agencies" component={AgenciesPage}/>
        <ContentRoute path="/get-campuses" component={CampusesPage}/>
        <ContentRoute path="/get-programs" component={ProgramTypesPage}/>
        <ContentRoute path="/get-courses" component={CoursesPage}/>
        <ContentRoute path="/get-invoices" component={InvoicesPage}/>
        <ContentRoute path='/get-agency' component={DataAgencyPage}/>
        <ContentRoute path="/get-agents" component={AgentPage}/>
        <ContentRoute path='/get-agency-profit'  component={AgencyProfitAgencyPage}/>
        {/*<ContentRoute path="/get-script" component={ScriptEdvisorPage}/>*/}

        <ContentRoute path="/get-schools" component={SchoolsPage}/>
        <ContentRoute path="/get-offices" component={OfficesPage}/>

        <Route path="/google-material" component={GoogleMaterialPage}/>
        <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
        <Route path="/e-commerce" component={ECommercePage}/>
        <Redirect to="error/error-v1"/>
      </Switch>
    </Suspense>
  );
}
