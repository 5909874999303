import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import { Dialog } from 'primereact/dialog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { InputText } from 'primereact/inputtext';
import { Link, Redirect }  from "react-router-dom";
import "primeflex/primeflex.css";

import axios from 'axios';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import SVG from "react-inlinesvg";
import ServerURL from '../../../ServerURL';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { ProgressBar } from 'primereact/progressbar';
import { FormattedMessage, injectIntl } from "react-intl";
// import { FileUpload } from 'primereact/fileupload';

const url = ServerURL();

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "white" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
});

class Program extends Component {

  emptyPrograms = {
    program: ''
  }


  constructor(props) {
    super(props);
    this.state = {


      displayConfirmation: false,
      displayConfirmPublication: false,
      idLessonSelect: null,
      titleEdit: null,
      title: this.props.dataCourse.course,
      descriptionEdit: null,
      imgOficial: this.props.dataCourse.image,
      imgPreview: this.props.dataCourse.image,
      imgFile: null,
      redirect: false,


      // WIyu
      name: "",
      program: this.emptyPrograms
    };

    this.onHide = this.onHide.bind(this);
    this.onClickConfirmation = this.onClickConfirmation.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.saveLesson = this.saveLesson.bind(this);
    this.updateCourse = this.updateCourse.bind(this);
    this.updateCourseImage = this.updateCourseImage.bind(this);
    this.renderImageOption = this.renderImageOption.bind(this);
  }

  componentDidMount() {
      // this.getLessons()
      // this.getDocuments()
      this.getProgram()
  }

  changeDate(e){                 
    e.map((file) => {
    const date = new Date(file.updated_at);

     let year = date.getFullYear();
     let month = date.getMonth()+1;
     let dt = date.getDate();

     if (dt < 10) {
       dt = '0' + dt;
     }
     if (month < 10) {
       month = '0' + month;
     }

     let new_date = dt +'/' + month + '/'+year
    //  console.log("New Data:",new_date)

     file.updated_at = new_date
     return file
   });
  }

  onHide(){
    this.setState({
      dialog: false,
    })
  }

 
  onHideC(name) {
    this.setState({
      [`${name}`]: false
    });
  }


  onClickConfirmation(){
    this.setState({
      displayConfirmPublication: true
    })
  }



  changeImage(e){
    this.setState({imgPreview: URL.createObjectURL(e.target.files[0]), imgFile: e.target.files[0]})
  }

  
  getProgram() {
    let data = {...this.state.program}
    try {
      let res = axios.get(`${url}/api/public/wiyu_programs/${data.id}`)
      .then(res => {
          const programs = res.data;
          this.setState({ programs })
      });
      console.log(res.data)
  } catch (error) {

  }
  }

  async saveLesson(){
    let data = {
      fk_id_course: this.props.dataCourse.id,
      name: this.state.name,
      position: this.state.lessons.length
    }
    console.log(data)
    try {
      await axios.post(`${url}/api/auth/lesson`, data)
      .then(res => {     
        this.setState({ dialog: false, name: "" })
        this.getLessons()
      });
      }catch(error){
    }
  }


  async updateCourse(){
    let data = {
      fk_id_program_type: this.props.dataCourse.fk_id_program_type,
      name: this.state.title,
      description: this.state.description
    }
    try {
      await axios.put(`${url}/api/auth/course/${this.props.dataCourse.id}`, data)
      .then(res => {     
        const dataCourse = {...this.props.dataCourse, course: data.name, description: data.description};
        this.props.updatedCourse(dataCourse)
        
        this.setState({titleEdit: false, descriptionEdit: false})
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LIST.COURSE_UPDATED'}), life: 3000 });
      });
      }catch(error){
    }
  }

  async updateCourseImage(){
    this.setState({loadImage: true});
    const formData = new FormData();
    formData.append('file',this.state.imgFile);
    let config = {
      'Content-Type': 'multipart/form-data'
    }
    
    try {
      await axios.put(`${url}/api/auth/course_img/${this.props.dataCourse.id}`, formData, config)
      .then(res => {
        const newImg = this.state.imgPreview
        const originalmg = this.state.imgOficial
        const message_type = res.data.message_type
        const message = res.data.message
        let image = null;
        if(message_type === 'success'){
          console.log("IMG subido")
          image = newImg;
          this.setState({imgOficial: newImg, imgFile: null, loadImage: false})
        }else{
          image = originalmg;
          this.setState({imgPreview: originalmg, imgFile: null, loadImage: false})
        }

        const dataCourse = {...this.props.dataCourse, image};
        this.props.updatedCourse(dataCourse)

        let type = null;
        if(message_type === "success"){
          type = "GENERAL.SUCCESSFUL"
        }else{
          type = "GENERAL_MESSAGE.ERROR"
        }

        this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 6000 });
      });
      }catch(error){
    }
  }

  renderSVG(type){
      switch (type) {
        case "pdf":
          return <SVG src={toAbsoluteUrl("/media/svg/files/pdf.svg")}/>;
        case "docx":
          return <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}/>;
        case "doc":
          return <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}/>;
        case "jpg":
          return <SVG src={toAbsoluteUrl("/media/svg/files/jpg.svg")}/>;
        case "jpeg":
          return <SVG src={toAbsoluteUrl("/media/svg/files/jpeg.svg")}/>;
        case "png":
          return <SVG src={toAbsoluteUrl("/media/svg/files/png.svg")}/>;
        case "xlsx":
          return <SVG src={toAbsoluteUrl("/media/svg/files/xlsx.svg")}/>;
        case "mp4":
          return <SVG src={toAbsoluteUrl("/media/svg/files/mp4.svg")}/>;
        case "mkv":
          return <SVG src={toAbsoluteUrl("/media/svg/files/mkv.svg")}/>;
        case "zip":
          return <SVG src={toAbsoluteUrl("/media/svg/files/zip.svg")}/>;
        case "rar":
          return <SVG src={toAbsoluteUrl("/media/svg/files/rar.svg")}/>;
        case "txt":
          return <SVG src={toAbsoluteUrl("/media/svg/files/txt.svg")}/>;
        default:
          return <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}/>;
      }
  }


  renderFooter(name) {
    return (
      <div>
        <FormattedMessage id="GENERAL.NO">
          {message => <Button label={message} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
          {message => <Button label={message} icon="pi pi-check" onClick={() => this.deleteLesson(name)} />}
        </FormattedMessage>
      </div>
    );
  }

  renderFooterConfirmation(name) {
    return (
      <div>
        <FormattedMessage id="GENERAL.NO">
          {message => <Button label={message} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
          {message => <Button label={message} icon="pi pi-check" onClick={() => this.updateCoursePublished()} />}
        </FormattedMessage>
      </div>
    );
  }

  renderTitle(){

    let titleView

    if (this.state.titleEdit){
      titleView = (
        <div className="mb-4" style={{fontSize:34}}>
          <InputText style={{width:"100%", fontSize:"38px", fontWeight:400, border:"none", padding:0}} maxlength="39" className="p-inputtext-lg p-d-block" id="title" value="Nombre del programa" onChange={(e) => this.setState({title: "Nombre del programa"})} autoFocus/>
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.SAVE_CHANGES'})} style={{verticalAlign: 'middle',   marginTop: "10px"}} onClick={this.updateCourse}></Button>
        </div>
      )
    }else{
      titleView = (
        <>
        {
          this.state.isPublished === 0 ?
          <div className="mb-4" style={{fontSize:34}}>
            Nombre del programa <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({titleEdit: true})}></Button>
          </div>
          :
          <div className="mb-4" style={{fontSize:34}}>
            Nombre del programa
          </div>
        }
        </>
      )
    }

    return titleView
  }

  renderImageOption(){
    let imgOptionView
    if(!this.state.imgFile){
      imgOptionView = (
        <FormattedMessage id="GENERAL_MESSAGE.CHANGE_IMAGE">
          {message => <input type="button" className="btn btn-secondary mx-auto d-block mt-1" value={message} onClick={() => document.getElementById('fileInput').click()} />}
        </FormattedMessage>
      )
    }else{
      if(!this.state.loadImage){
        imgOptionView = (
        <div className="d-flex mt-2 justify-content-around">
          <button className="btn btn-danger" onClick={() => this.setState({imgFile: null, imgPreview: this.state.imgOficial})}><FormattedMessage id="GENERAL.DISCARD_CHANGES"/></button>
          <button className="btn btn-primary" onClick={this.updateCourseImage}><FormattedMessage id="GENERAL.SAVE"/></button>
        </div>
        )
      }else{
        imgOptionView = (
        <div className="mt-2 text-center">
          <ProgressSpinner style={{height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
        </div>
        )
      }
    }
    return imgOptionView
  }


  render() {
    let url_next = null
    let url_back = null
    if(this.props.dataCourse.fk_id_program_type !== 10){
      url_back = "/wiyu-programs"
      url_next = "/wiyu-programs";
    }else{
      url_next = "wiyu-programs";
      url_back = "/wiyu-programs"
    }

    if(this.state.redirect) {
      return <Redirect to={{
        pathname: url_next,
      }}/>;
    }

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />  
        <div className="d-flex justify-content-between mb-2">
          <Link to={{
              pathname: url_back
          }}>
          <button className="btn"><KeyboardBackspaceSharpIcon/></button>
          </Link>
          {
            this.state.isPublished ?
            <></>
            :
            <button className="btn btn-lg btn-info" onClick={this.onClickConfirmation}>Guardar cambios</button>
          }
        </div>
        <div className="card p-6">
          <div className="p-grid p-nogutter mb-6">
            <div className="p-col-6">
              <div className="mb-2 font-weight-bold">
                <h2>Nombre de la agencia </h2>
              </div>
              {
                this.renderTitle()
              }
              <div>
                <div className="mb-6">
                  <p><FormattedMessage id="GENERAL_MESSAGE.CREATED_IN"/>: </p>
                </div>
                {/* <FormattedMessage id="GENERAL.DESCRIPTION"/>:
                {
                  this.renderDescription()
                } */}
              </div>
            </div>
            <div className="p-col-6">
              <img className="img-fluid mx-auto d-block img-thumbnail" alt={`course-${this.props.dataCourse.course}`} style={{maxHeight:250}} src={this.state.imgPreview}/>
              {
                this.state.isPublished === 0 ?
                <>
                <input id="fileInput" type="file" accept="image/x-png,image/jpeg" style={{display:"none"}} onChange={ e => this.changeImage(e)}/>
                {
                  this.renderImageOption()
                }
                </>
                :
                <></>
              }
            </div>
          </div>
          <hr className="pb-5"/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  dataCourse: state.auth.dataCourse
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(Program)
)