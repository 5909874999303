import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";


//Traduccion
import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

//Componentes
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

//Conexion con API
import axios from 'axios'
import ServerURL from '../../../ServerURL'

// Redux
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux"

//Funciones extras
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import Moment from 'moment'

//PrimeReact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import moment from 'moment';


const url = ServerURL();

class FlightInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            // program_name: this.props.dataStudent.program_name,
            flight_start_date: this.props.dataStudent.flight_start_date,
            flight_end_date: this.props.dataStudent.flight_end_date,

            submitted: false,

            redirect: false,

            selectedAccommodationType: null,
            resetAccommodationType: null,

            selectedRoomType: null,
            resetRoomType: null,


        }
        this.accommodation_type = [
            {accommodation_type: 'Hotel'},
            {accommodation_type: 'Residence'},
            {accommodation_type: 'Host family'},
        ]

        this.room_type = [
            {room_type: 'Single'},
            {room_type: 'Shared'},
        ]

        this.updateStudent = this.updateStudent.bind(this)
        this.changePage = this.changePage.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
        // this.onAccommodationType = this.onAccommodationType.bind(this)
        // this.onRoomType = this.onRoomType.bind(this)
    }

    changePage(){
        this.setState({redirect: true})
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let student = {...this.state.student}
        student[`${name}`] = val
        this.setState({ student })
    }

    // onAccommodationType(e) {
    //     let student = {...this.state}
    //     student['accommodation_type'] = e.value
    //     this.setState({ selectedAccommodationType: e.value, student})
    //     console.log("Valor: ", student['accommodation_type']);
    //     // console.log("State: ", student);

    // }

    // onRoomType(e) {
    //     let student = {...this.state}
    //     student['room_type'] = e.value
    //     this.setState({ selectedRoomType: e.value, student})
    //     console.log("Valor: ", student['room_type']);
    //     // console.log("State: ", student);

    // }

    async updateStudent() {
        let state ={ submitted: true }
        let data = {
            // name: this.state.program_name,
            flight_start_date: Moment.utc(this.state.flight_start_date).format('YYYY-MM-DD'),
            flight_end_date: Moment.utc(this.state.flight_end_date).format('YYYY-MM-DD'),
        }
        // console.log("Data :", data);

        try{
            await axios.put(`${url}/api/public/students-flight/${this.props.dataStudent.id}`, data)
            .then(res => {
                const dataStudent = {
                    ...this.props.dataStudent,
                    // program_name: data.name,
                    flight_start_date: data.flight_start_date,
                    flight_end_date: data.flight_end_date,
                
                };
                this.props.updatedStudent(dataStudent)
                console.log(res);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Student updated', life: 3000})
            })
        } catch (error){
            console.log(error);
        }
        console.log("State: ", state);
        console.log("Data: ", data);
        this.setState({...state, data})
    }

    render(){

        if(this.state.redirect){
            return <Redirect to={{
                pathname: '/wiyu-students'
            }} />
        }

        return(
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className='d-flex justify-content-between mb-2'>
                    <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" icon="pi pi-arrow-left" onClick={this.changePage} variant='contained'/>
                </div>
                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <h5 className='card-label'> Flight info
                        <span className="d-block text-muted pt-2 font-size-sm" >Update flight info</span>
                    </h5>
                </div>
                <div>
                    <div className='card'>
                        <br/>
                        <br/>

                <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-calendar-plus'></i>
                            </span>
                            {/* Program start date */}
                            {
                                // this.props.dataStudent.program_start_date === null ?
                                this.props.dataStudent.flight_start_date === null ?
                                <span className='p-float-label'>
                                    <Calendar 
                                        id='basic'
                                        name='flight_start_date'
                                        value={this.state.flight_start_date}
                                        // value={this.props.dataStudent.program_start_date}
                                        onChange={(e) => this.setState({ flight_start_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // readOnlyInput
                                        // placeholder= {this.props.dataStudent.insurance_start_date}
                                    />
                                    <label htmlFor='flight_start_date'> Flight start date</label>
                                </span> 
                                :
                                this.props.dataStudent.flight_start_date === 'Invalid date' ?
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='basic'
                                            name='flight_start_date'
                                            value={this.state.flight_start_date}
                                            onChange={(e) => this.setState({ flight_start_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // readOnlyInput
                                            // placeholder= {Moment.utc(this.props.dataStudent.program_start_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='flight_start_date'> Flight start date</label>
                                    </span>
                                :
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='basic'
                                            name='flight_start_date'
                                            value={this.state.flight_start_date}
                                            onChange={(e) => this.setState({ flight_start_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // readOnlyInput
                                            placeholder= {moment.utc(this.props.dataStudent.flight_start_date).format("DD/MM/YYYY")}
                                        />
                                        <label htmlFor='flight_start_date'> Flight start date</label>
                                    </span> 
                            }
                        </div>
                        <small className='p-d-block' id='flight_start_date-help'>Format YYYY-MM-DD</small>
                    </div>

                    <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <i className='pi pi-calendar-times'></i>
                            </span>
                            {/* Program end date */}
                            {
                                this.props.dataStudent.flight_end_date === null ?
                                <span className='p-float-label'>
                                    <Calendar 
                                        id='basic'
                                        name='flight_end_date'
                                        value={this.state.flight_end_date}
                                        // value={this.props.dataStudent.program_start_date}
                                        onChange={(e) => this.setState({ flight_end_date: e.value})}
                                        dateFormat='yy-mm-dd'
                                        // readOnlyInput
                                        // placeholder= {this.props.dataStudent.insurance_start_date}
                                    />
                                    <label htmlFor='flight_end_date'> Flight end date</label>
                                </span> 
                                :
                                this.props.dataStudent.flight_end_date === 'Invalid date' ?
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='basic'
                                            name='flight_end_date'
                                            value={this.state.flight_end_date}
                                            onChange={(e) => this.setState({ flight_end_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // readOnlyInput
                                            // placeholder= {Moment.utc(this.props.dataStudent.program_start_date).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor='flight_end_date'> Flight end date</label>
                                    </span>
                                :
                                    <span className='p-float-label'>
                                        <Calendar 
                                            id='basic'
                                            name='flight_end_date'
                                            value={this.state.flight_end_date}
                                            onChange={(e) => this.setState({ flight_end_date: e.value})}
                                            dateFormat='yy-mm-dd'
                                            // readOnlyInput
                                            placeholder= {moment.utc(this.props.dataStudent.flight_end_date).format("DD/MM/YYYY")}
                                        />
                                        <label htmlFor='flight_end_date'> Flight end date</label>
                                    </span>
                            }
                        </div>
                        <small className='p-d-block' id='flight_end_date-help'>Format YYYY-MM-DD</small>
                    </div>
                </div>


                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end',
                    paddingBottom: 10,
                    marginRight: 5
                }}>
                    {/* Boton cancelar */}
                    <div style={{marginLeft: 10}}>
                        <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" label='Cancelar' icon="pi pi-times" onClick={this.changePage} variant='contained'/>
                    </div>

                    {/* Boton guardar */}
                    <div style={{marginLeft: 10}}>
                        <Button className='p-button-rounded p-button-help p-button-outlined' type="button" label='Guardar' icon="pi pi-check" onClick={this.updateStudent} variant='contained'/>
                    </div>
                </div>

                    </div>
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataStudent: state.auth.dataStudent
})

export default injectIntl(
    connect(mapStateToProps, auth.actions)(FlightInfo)
)