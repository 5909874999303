import React, {Component} from 'react'
import { withRouter } from "react-router";

import classNames from 'classnames';
import axios from 'axios'
import ServerURL from '../../../ServerURL';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Checkbox } from 'primereact/checkbox';

// Falta acomodar el codigo de Program_type a GroupAgencies

const url = ServerURL();


class AgencyGroupsTable extends Component {
            
  emptyGroupAgencies = {
    id: null,
    fk_id_enterprise: null,
    name: null,
    agencies: [],
  }



  constructor(props) {
    super(props);  

    this.state = {
      /**
       * Aux messages
       */
      nameMessage: '',
      agenciesMessage: '',
      fk_id_enterprise: null,
      groups_agencies: null,
      group_agencies: this.emptyGroupAgencies,
      agreements_original: null,
      agreements_show: null,
      program_types:null,
      ids:null,
      globalFilter: null,
      selectedGroupsAgencies: null,
      group_agencieDialog: false,
      deleteGroupAgenciesDialog: false,
      deleteGroupsAgenciesDialog: false,
      submitted: false,
      selectedCountries: [],

      schools:null,
      selectedSchool: null,
      resetSchool:null,

      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate()
    }
  
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.saveGroupAgencies = this.saveGroupAgencies.bind(this);
    this.deleteGroupAgencies = this.deleteGroupAgencies.bind(this);
    this.openNew = this.openNew.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.editGroupAgencies = this.editGroupAgencies.bind(this);
    this.confirmDeleteProgramType= this.confirmDeleteProgramType.bind(this);
    this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
    this.deleteSelectedGroupsAgencies = this.deleteSelectedGroupsAgencies.bind(this);
    this.hideDeleteProgramTypeDialog = this.hideDeleteProgramTypeDialog.bind(this);
    this.hideGroupsAgenciesDialog = this.hideGroupsAgenciesDialog.bind(this);
    this.hideDeleteGroupsAgenciesDialog = this.hideDeleteGroupsAgenciesDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSchoolChange = this.onSchoolChange.bind(this);
    this.countryTemplate = this.countryTemplate.bind(this);
    this.selectedCountriesTemplate = this.selectedCountriesTemplate.bind(this);
    this.changeCheck = this.changeCheck.bind(this);
    this.changeCountry = this.changeCountry.bind(this);

  
    //ADAPTAR PAISES
          this.countries = [
            {country_name: 'Mexico', code: 'MX'},
            {country_name: 'United States', code: 'US'},
            {country_name: 'Canada', code: 'CAN'},
            {country_name: 'France', code: 'FR'},
            {country_name: 'Ireland', code: 'IR'}
          ];

        }
  


  countryTemplate(option) {
    return (
        <div className="data-item">
            <div>{option.country_name}</div>
        </div>
    );
  }
  
  selectedCountriesTemplate(option) {
    if (option) {
        return (
            <div className="country-item data-item-value">
                <div>{option.country_name}</div>
            </div>
        );
    }

    return "Todos los paises";
  }

      componentDidMount() {
        console.log("Actualizo")
        this.getGroupsAgencies()
      }

      isValid(){
        const { name, agencies } = this.state.group_agencies
        const nameMessage = this.validateName()
        const agenciesMessage = this.validateAgencies()
      
  
        if(name && agencies ){
          if(!nameMessage && !agenciesMessage){
            return true;
          }else{
            return false;
          }
        }
        return false;
      }
      
      validateName() {
        const group_agencies = this.state.group_agencies
        
        let nameMessage;
        if (!group_agencies.name) {
          nameMessage = 'Requerido';
        } else {
          nameMessage = ''
        }
        this.setState({ nameMessage })
        return nameMessage
      }

      validateAgencies() {
        const group_agencies = this.state.group_agencies
        
        let agenciesMessage;
        if (!group_agencies.agencies) {
          agenciesMessage = 'Requerido';
        } else {
          agenciesMessage = ''
        }
        this.setState({ agenciesMessage })
        return agenciesMessage
      }

      getTextAuxName(){
        const message = this.state.nameMessage
        return message
      }
      
      getTextAuxAgencies(){
        const message = this.state.agenciesMessage
        return message
      }

     getGroupsAgencies = async () =>{
        try {
          await axios.get(`${url}/api/auth/groups_agencies`)
          .then(res => {
            const groups_agencies = res.data;
            this.setState({ groups_agencies })
            this.getAgreements(groups_agencies.fk_id_enterprise)
          });
          }catch(error){
        }
      }

     

      


      getSchools = async () =>{
         
        try {
           
            let res = axios.get(`${url}/api/auth/schools`)
            .then(res => {
              const schools = res.data;
              this.setState({ schools })
            });                     
              console.log(res.data)
            }catch(error){

        }
      }

      getAgreements = async (e) =>{ 
        let agreements
        try {
          await axios.get(`${url}/api/auth/agencies/${e}`)
          .then(res => {
            let agreements_original = res.data;
            this.setState({ agreements_original, agreements_show: agreements_original })
            agreements=res.data;
          });
        }catch(error){
        }
        return agreements
      }

      deleteGroupAgencies(){
        let data = {...this.state.group_agencies};

        try {
        let res = axios.delete(`${url}/api/auth/group_agencies/${data.id}`)
          .then(res => {
          this.getGroupsAgencies()
        })
        if (!res.error) {
          this.setState({
            group_agencies: data,
            deleteGroupAgenciesDialog: false
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Group agencies Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveGroupAgencies() {
        let state = { submitted: true };

       // if (this.state.program_type.program_type.trim()) {     
        const decision = this.isValid();

        if (decision) {  
          let data = {...this.state.group_agencies};

        if (this.state.group_agencies.id) {

            try {
              let res = axios.put(`${url}/api/auth/group_agencies/${data.id}`,data)
              .then(res =>{
              this.getGroupsAgencies()
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Program Type Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/group_agencies`,data)
            
            .then(res => {
                this.getGroupsAgencies()
              })
              if (!res.error) {
              this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Program Type Created', life: 3000 });
              }
                }catch(error){
              }   
        }
        state = {
          ...state,
          group_agencieDialog: false,
          selectedSchool:null,
          schools:null,
          group_agencies: this.emptyGroupAgencies
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        const newArrayAgrementsOriginal = []
        this.state.agreements_original.forEach(agreement => {
          agreement.isCheck = false;
          newArrayAgrementsOriginal.push(agreement);
        })
        this.setState({
            group_agencies: this.emptyGroupAgencies,
            submitted: false,
            group_agencieDialog: true,
            selectedSchool:null,
            schools:null,
            selectedCountries: [],
            agreements_original: newArrayAgrementsOriginal,
            agreements_show: newArrayAgrementsOriginal
        })
        this.getSchools()

      }

      


      onSchoolChange(e) {    
        let group_agencies = {...this.state.group_agencies};
        console.log("SCHOOL:",e.value)

        group_agencies['fk_id_enterprise']=e.value

        this.setState({ 
          selectedSchool: e.value, 
          group_agencies,
        });
        this.getAgreements(e.value)

      }

      onSchoolEditChange(e) {    
        let group_agencies = {...this.state.group_agencies};
        console.log("SCHOOL:",e)

        group_agencies['fk_id_enterprise']=e

        this.setState({ 
          selectedSchool: e, 
          group_agencies,
        });
        this.getAgreements(e)

      }

      

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let group_agencies = {...this.state.group_agencies};
        group_agencies[`${name}`] = val;
        this.setState({ group_agencies} );
      }





      hideDialog() {
        this.setState({
            submitted: false,
            group_agencieDialog: false,
            group_agencieMessage: '',
            agenciesMessage: '',
            group_agency: this.emptyGroupAgencies,
            selectedCountries: null,
            agreements_original: [],
            agreements_show:[],
            selectedSchool:null,
            schools:null,
      })  
    }


        async editGroupAgencies(group_agencies) {
          
        let agreements = await this.getAgreements(group_agencies.fk_id_enterprise)

        console.log("AGREEMENTS:",agreements)

        const newArrayAgrementsOriginal = []
        agreements.forEach(agreement => {
          console.log("GROUP:",group_agencies.agencies)
          const found = group_agencies.agencies.find(id => id === agreement.id);
          console.log("FOUND:", found)

          if(found){
            agreement.isCheck = true;
          }else{
            agreement.isCheck = false;
          }
          newArrayAgrementsOriginal.push(agreement);
          console.log("New Array:",newArrayAgrementsOriginal)
        })

          this.setState({
            group_agencies: { ...group_agencies },
            group_agencieDialog: true,
            selectedCountries: [],
            selectedSchool: null,  
            agreements_original: newArrayAgrementsOriginal,
            agreements_show: newArrayAgrementsOriginal
          })

      }

      confirmDeleteProgramType(group_agencies) {
        this.setState({
            group_agencies,
            deleteGroupAgenciesDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteGroupsAgenciesDialog: true })
      }

      deleteSelectedGroupsAgencies() {
        let groups_agencies = this.state.groups_agencies.filter(val => !this.state.selectedGroupsAgencies.includes(val));
        let data = this.state.groups_agencies.filter(val => this.state.selectedGroupsAgencies.includes(val));



        console.log("IDS:",data)

        try {
        let res = axios.delete(`${url}/api/auth/groups_agencies`, {data})
          .then(res => {
          this.getGroupsAgencies()
        })
        if (!res.error) {
          this.setState({
            groups_agencies,
            deleteGroupsAgenciesDialog: false,
            selectedGroupsAgencies: null
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Group Agencies Deleted', life: 3000 });
        }
        }catch(error){
        }
      }

      hideDeleteProgramTypeDialog() {
        this.setState({ deleteGroupAgenciesDialog: false })
      }
        
      hideGroupsAgenciesDialog() {
        this.setState({ groupsAgenciesDialog: false });
      }

      hideDeleteGroupsAgenciesDialog() {
        this.setState({ deleteGroupsAgenciesDialog: false });
    }
      
      exportCSV() {
        this.dt.exportCSV();
      }

      changeCountry(listCountrys){
        if(listCountrys.length === 0){
          this.setState({ selectedCountries: listCountrys, agreements_show: this.state.agreements_original })
        }else{
          const newArrayAgrementsShow = []
          this.state.agreements_original.forEach( agreement => {
            listCountrys.forEach(country => {
              if(agreement.code === country.code){
                newArrayAgrementsShow.push(agreement)
              }
            })
          })
          this.setState({ selectedCountries: listCountrys, agreements_show: newArrayAgrementsShow })
        }
      }
     
      changeCheck(id){


        const arrayAgreementsOriginal = this.state.agreements_original
        const newArrayAgrementsOriginal = []
        const newArrayAgrementsShow = []


        arrayAgreementsOriginal.forEach(agreement => {
          if(agreement.id === id){
            agreement = {...agreement, isCheck: !agreement.isCheck};
          }
          newArrayAgrementsOriginal.push(agreement)
          if(this.state.selectedCountries.length === 0){
            newArrayAgrementsShow.push(agreement)
          }else{
            this.state.selectedCountries.forEach(country => {
              if(country.code === agreement.code){
                newArrayAgrementsShow.push(agreement)
              }
            })
          }
        })

        const newArrayIds = []
        newArrayAgrementsOriginal.forEach(agreement => {
          if(agreement.isCheck){
            newArrayIds.push(agreement.id)
          }
        })
        const group_agencies = {...this.state.group_agencies, agencies: newArrayIds}
        this.setState({agreements_original:newArrayAgrementsOriginal, agreements_show: newArrayAgrementsShow, group_agencies})
      }

     
    
      actionBodyTemplate(rowData) {
        return (
          <>
            <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() =>  this.editGroupAgencies(rowData) } />
            <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteProgramType(rowData)} />
          </>
        )
      }

      renderAgreement(data){
        return(
          <div key={data.id} className="col-2 card p-4" style={{textAlign:"center"}}>
            <Checkbox inputId={data.enterprise_name} checked={data.isCheck} onChange={e => this.changeCheck(data.id)}></Checkbox>
            <div htmlFor={data.enterprise_name}>
              <div>
                <img style={{borderRadius:"100%", width:"100px"}} alt={data.enterprise_name} src={data.logo}/>
              </div>
              <hr/>
              <span className="p-text-bold" style={{display:"block"}}>{data.enterprise_name}</span>
              <span>Sitio web: {data.website}</span>
            </div>
          </div>
        )
      }

      render(){
      const deleteGroupAgenciesDialogFooter = (
        <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteProgramTypeDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteGroupAgencies} />
        </>
      );


      const GroupAgenciesFooter = (
        <>
          <FormattedMessage id="GENERAL.CANCEL">
            {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
          </FormattedMessage>
          <FormattedMessage id="GENERAL.SAVE">
            {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveGroupAgencies} />}
          </FormattedMessage>
        </>
      );

      const deleteGroupsAgenciesDialogFooter = (
        <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteGroupsAgenciesDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedGroupsAgencies} />
        </>
      );


       return(
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		        Lista de grupo de agencias
                <span className="d-block text-muted pt-2 font-size-sm">Listado de las agencias disponibles para los marketeers</span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
              {/*TOOL BAR */} 
              <div style={{ marginRight: 10}}>
                <FormattedMessage id="GENERAL.EXPORT">
                  {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                </FormattedMessage>
              </div>

              <div style={{ marginRight: 10}}>
                <FormattedMessage id="GENERAL.DELETE">
                  {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedGroupsAgencies || !this.state.selectedGroupsAgencies.length} />}
                </FormattedMessage>
              </div>

              <div style={{ marginRight: 10}}>
                  <p><Button type="button" label="Nuevo grupo" icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>
              </div>

              <div style={{ marginRight: 10}}>
              <p> <span className="p-input-icon-left">
              <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                </span></p>
              </div>                      
            </div>
          </div>

          
          <div>
           {/*SHOW AGREEMENTS */}
            <div className="card">
              <DataTable 
                ef={(el) => this.dt = el} value={this.state.groups_agencies} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                globalFilter={this.state.globalFilter} emptyMessage="No Program type found." loading={this.state.loading}
                selection={this.state.selectedGroupsAgencies} onSelectionChange={e => this.setState({ selectedGroupsAgencies: e.value })} dataKey="id">
                <Column selectionMode="multiple"></Column>
                <Column field="enterprise_name" header="Empresa" sortable></Column>
                <Column field="name" header="Grupo" sortable></Column>
                <Column body={this.actionBodyTemplate}></Column>
              </DataTable>
            </div>
           </div>
         

          <div> 
            {/*DELETE CAMPUS */}
            <Dialog visible={this.state.deleteGroupAgenciesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteGroupAgenciesDialogFooter} onHide={this.hideDeleteProgramTypeDialog}>
              <div className="confirmation-content">
                  {this.state.group_agencies && <span className="d-flex"><i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', alignSelf: "center" }}/>Are you sure you want to delete <b>{this.state.group_agencies.name}</b>?</span>}
              </div>
            </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteGroupsAgenciesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteGroupsAgenciesDialogFooter} onHide={this.hideDeleteGroupsAgenciesDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                {this.state.groups_agencies && <span>Are you sure you want to delete the selected agency group?</span>}
            </div>
          </Dialog>


          <div>
          {/*UPDATE GROUP & CREATE GROUP*/}
          <Dialog visible={this.state.group_agencieDialog} contentStyle={{maxHeight:"1600px", width:"1300px", height:"600px", overflow:"auto"}} header="Agencias con las que tiene convenio" modal footer={GroupAgenciesFooter} onHide={this.hideDialog}>
            <br></br>
            <div className="form-group row">
              {/* Schools */}
              <div className="col-lg-6">  
              <span className="p-float-label">   
              <Dropdown
                style={{ width: 190, height:33 }}
                id="school"
                variant="outlined"
                placeholder={this.state.group_agencies.enterprise_name || "School"}
                margin="dense"
                value= { this.state.selectedSchool || "enterprise_name" } 
                options={this.state.schools} 
                onChange={this.onSchoolChange}  
                optionLabel="enterprise_name" 
                optionValue="id"
                  />
                  <label htmlFor="role_type">School</label>
              </span>
              </div>
            </div>
            <div className="form-group row">
              {/* Progrm Type */}
              <div className="col-lg-6">  
                <span className="p-float-label">         
                  <InputText
                    style={{ width: 250, height:33 }}
                    id="name_group"
                    variant="outlined"
                    margin="dense"
                    value={this.state.group_agencies.name}             
                    onChange={(e) => this.onInputChange(e, 'name')} 
                    required className={classNames({ 'p-invalid': this.state.submitted && !this.state.group_agencies.name  })}
                  />
                  <label htmlFor="name_group">Nombre del grupo</label>        
                </span>
                <small id="program_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxName(this.state)}</small>
              </div>
            </div>
            <div className="form-group row">
              {/* Agencias */}
              <div className="col-lg-6 multiselect-demo">
                <span className="p-float-label">
                  Selecciona un pais
                </span>
                <MultiSelect value={this.state.selectedCountries} options={this.countries}  onChange={(e) => this.changeCountry(e.value)} optionLabel="country_name" placeholder="Todos los paises" filter className="multiselect-custom"
                itemTemplate={this.countryTemplate} selectedItemTemplate={this.selectedCountriesTemplate} />
              </div>
            </div>
            <div className="p-grid">
              {
                this.state.agreements_show ?
                this.state.agreements_show.map(agreement => this.renderAgreement(agreement))
                :
                <></>
              }
            </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}

export default withRouter(AgencyGroupsTable);
