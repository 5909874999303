import React, { Component } from 'react';

import axios from 'axios'
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import ServerURL from '../../../ServerURL'
import { Toast } from 'primereact/toast';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';




import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'


//import OpenExchangeURL from '../../../OpenExchangeURL'
//import OpenExchangeAPI from '../../../OpenExchangeAPI'

/*import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import {Checkbox} from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';*/
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import { name } from 'faker/lib/locales/az';


const url = ServerURL();
//const urlOE = OpenExchangeURL();
//const apiOE = OpenExchangeAPI();




//const url = ServerURL();

class Analytics extends Component {

  emptyAnalytics = {
    oficina: '',
    ventas: '',
    amount: '',
  }

  emptyExchange = {
    fk_id_currency: null,
    rate: null,
  }

  emptyCurrency = {
    code: null,
    symbol: null,
  }

  emptyEnterprise = {
    fk_id_currencies: ''
  }


  constructor(props) {
    super(props);
    this.state = {

      analytic: this.emptyAnalytics,
      globalFilter: null,
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate(),
      originalFk_id_baseCurrency: this.props.user.fk_id_baseCurrency,
      originalCodeCurrency: this.props.user.codeCurrency,


      exchange: this.emptyExchange,
      currency: this.emptyCurrency,
      enterprise: this.emptyEnterprise,
      dates: null,
      reportDates: null,
      showReportDates: false,
      selectedType: null,


      exchanges: [],
      currencies: [],
      currenciesShow: [],
      enterprises: [],
      invoices: null,
      reports: null,
      sales: null,

      submitted: false,
      currencyDialog: false,
      resetDateExchange: null,

      selectedBaseCurrency: null,
      selectedUsers: null,
      saveDialog: false,


      disabledConfig: true,
      user: {
        id: this.props.user.id,
        fk_id_baseCurrency: this.props.user.fk_id_baseCurrency,
        codeCurrency: this.props.user.codeCurrency
      },

    };

    this.types = [
      { type: 'Course', id: 1 },
      { type: 'School', id: 2 },
    ];


    this.onDateChange = this.onDateChange.bind(this);
    this.onReportDateChange = this.onReportDateChange.bind(this);
    this.onTypesChange = this.onTypesChange.bind(this);

  }

  componentDidMount() {
    this.getInitialData()
  }
  getInitialData = async () => {

    let currenciesShow = [];
    let aux = 0;
    let data = {
      date: null,
    }

    let requestOne = axios.get(`${url}/api/auth/currencies`);
    let requestTwo = axios.get(`${url}/api/auth/enterprise/${this.props.user.fk_id_enterprise}`);

    let requestThree = axios.post(`${url}/api/auth/accounting_reportGeneralDates`, data)

    data.date = this.Today()
    let requestFour = axios.post(`${url}/api/auth/getExchangeRates`, data)

    data.date = new Date()
    let year = data.date.getFullYear()
    data.date = year
    let requestFive = axios.post(`${url}/api/auth/accounting_reportGeneralMonths`, data)
    let requestSix = axios.post(`${url}/api/auth/accounting_reportGeneralOffices`, data)
    let requestSeven = axios.post(`${url}/api/auth/accounting_reportGeneralPrograms`, data)
    let requestEight = axios.post(`${url}/api/auth/accounting_reportGeneralProviders`, data)


    axios
      .all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix, requestSeven, requestEight])
      .then(
        axios.spread((...responses) => {
          const currencies = responses[0].data;
          const enterprises = responses[1].data;
          const exchangeRates = responses[2].data;

          const salesYear = responses[3].data
          const salesMonth = responses[4].data
          const salesOffices = responses[5].data
          const salesProgram = responses[6].data
          const salesProvider = responses[7].data


          this.codeSymbol(currencies)
          this.codeSymbolExchange(exchangeRates)
          this.updateAuxRates(exchangeRates)
          this.updateAuxNetRates(exchangeRates)
          this.getReportGeneral()
          this.getReportDefault()

          this.symbolTotalPerAmount(salesMonth)

          this.symbolTotalGross(salesOffices)
          this.symbolTotalNet(salesOffices)
          this.symbolTotalProfit(salesOffices)


          this.symbolTotalGross(salesProgram)
          this.symbolTotalNet(salesProgram)
          this.symbolTotalProfit(salesProgram)


          this.symbolTotalGross(salesProvider)
          this.symbolTotalNet(salesProvider)
          this.symbolTotalProfit(salesProvider)

          if (typeof this.props.user.fk_id_currencies === 'string') {
            this.props.user.fk_id_currencies = this.props.user.fk_id_currencies.split(',')
            this.props.user.fk_id_currencies = this.props.user.fk_id_currencies.map(Number);
          }

          for (let i = 0; i < Object.keys(exchangeRates).length; i++) {
            for (let j = 1; j < Object.keys(this.props.user.fk_id_currencies).length; j++) {
              if (exchangeRates[i].fk_id_currency === this.props.user.fk_id_currencies[j]) {
                currenciesShow[aux] = exchangeRates[i]
                aux++
              }
            }
          }


          this.setState({ 
            currencies, enterprises, exchangeRates, 
            resetDateExchange: 'Fecha de tipo de cambio', 
            currenciesShow,
            sales: salesYear, salesMonth, salesOffices, salesProgram, salesProvider
          })
        })

      )
      .catch(errors => {
        // react on errors.
        console.error(errors);
      });
  }

  codeSymbolExchange(e) {
    e.map((exchangeRates) => {
      exchangeRates.codeSymbol = exchangeRates.code + ' ' + exchangeRates.symbol;
      return exchangeRates

    });
  }



  Date(exchange) {

    let ts = exchange.timestamp;

    // convert unix timestamp to milliseconds
    let ts_ms = ts * 1000;

    // initialize new Date object
    let date_ob = new Date(ts_ms);

    // year as 4 digits (YYYY)
    let year = date_ob.getFullYear();

    // month as 2 digits (MM)
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // date as 2 digits (DD)
    var date = ("0" + date_ob.getDate()).slice(-2);

    let new_date = date + '/' + month + '/' + year

    exchange.date = new_date


    return exchange

  }

  Today() {
    let date = new Date()
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    if (d < 10) {
      d = '0' + d;
    }
    if (m < 10) {
      m = '0' + m;
    }
    let today = d + '/' + m + '/' + y

    return today
  }

  DateToOE(e) {

    let year = e.getFullYear();
    let month = e.getMonth() + 1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = day + '/' + month + '/' + year

    return new_date
  }

  codeCurrencies(e) {

    e.map((currency) => {

      currency.codeSymbol = currency.code + ' ' + currency.symbol;

      return currency

    });
  }

  codeSymbol(e) {


    e.map((currency) => {
      //console.log("E:",agreement.updated_at)

      currency.codeSymbol = currency.code + ' ' + currency.symbol;

      return currency

    });
  }

  onTypesChange = async (e) => {

    try {
      await axios.get(`${url}/api/auth/accounting_reportsByType/${e.value}`)
        .then(res => {
          const reports = res.data;
          this.symbolReportsCurrency(reports)
          this.setState({ selectedType: e.value, reports, showReportDates: true })
        });
    } catch (error) {
    }
  }


  onReportDateChange() {

    let reportDates = { ...this.state.reportDates }
    let type = this.state.selectedType

    if (reportDates[0] && reportDates[1]) {

      console.log("REPORT DATES:", reportDates)
      console.log("TYPE:", type)
      for (let i = 0; i < Object.keys(reportDates).length; i++) {

        let year = reportDates[i].getFullYear();
        let month = reportDates[i].getMonth() + 1;
        let day = reportDates[i].getDate();

        if (day < 10) {
          day = '0' + day;
        }
        if (month < 10) {
          month = '0' + month;
        }
        reportDates[i] = year + '-' + month + '-' + day
      }

      reportDates.type = type
      console.log("REPORT DATES WITH TYPE", reportDates)
      try {
        axios.post(`${url}/api/auth/accounting_reports`, reportDates)
          .then(res => {
            const reports = res.data;
            this.symbolReportsCurrency(reports)
            this.setState({ reportDates: null, reports })
          });
      } catch (error) {
      }
    }
  }



  onDateChange(e, name) {
    console.log('ENTRO');
    let date = { ...this.state.dates }
    // console.log('DATES: ', e);

    date[`${name}`] = e.target.value
    let date_year = date[`${name}`]

    if (date_year) {
      // console.log('DATES: ', date_year);

      //   for (let i = 0; i < Object.keys(dates).length; i++) {

      let year = date_year.getFullYear();
      // let month = date_year.getMonth()+1;
      // let day = date_year.getDate();

      // if (day < 10) {

      //   day = '0' + day;
      // }
      // if (month < 10) {
      //   month = '0' + month;
      // }
      date_year = year
      //   }

      console.log('DATES: ', date_year);

      let data = {
        date: date_year
      }

      try {

        let requestOne = axios.post(`${url}/api/auth/accounting_reportGeneralDates`, data)
        let requestTwo = axios.post(`${url}/api/auth/accounting_reportGeneralMonths`, data)
        let requestThree = axios.post(`${url}/api/auth/accounting_reportGeneralOffices`, data)
        let requestFour = axios.post(`${url}/api/auth/accounting_reportGeneralPrograms`, data)
        let requestFive = axios.post(`${url}/api/auth/accounting_reportGeneralProviders`, data)


        axios
          .all([requestOne, requestTwo, requestThree, requestFour, requestFive])
          .then(
            axios.spread((...responses) => {
              const salesYear = responses[0].data
              const salesMonth = responses[1].data
              const salesOffices = responses[2].data
              const salesProgram = responses[3].data
              const salesProvider = responses[4].data

              this.symbolTotalPerAmount(salesMonth)

              this.symbolTotalGross(salesOffices)
              this.symbolTotalNet(salesOffices)
              this.symbolTotalProfit(salesOffices)


              this.symbolTotalGross(salesProgram)
              this.symbolTotalNet(salesProgram)
              this.symbolTotalProfit(salesProgram)


              this.symbolTotalGross(salesProvider)
              this.symbolTotalNet(salesProvider)
              this.symbolTotalProfit(salesProvider)


              


              console.log('SALES MONTH: ', salesProgram);

              this.setState({ sales: salesYear, salesMonth, salesOffices, salesProgram, salesProvider})
            }) 
          )
        // axios.post(`${url}/api/auth/accounting_reportGeneralDates`, data)
        //   .then(res => {
        //     console.log("DATE: ", data);
        //     let sales = res.data;
        //     this.setState({ dates: null, sales })

        //   });
      } catch (error) {
      }
    }
  }

  symbolTotalPerAmount(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      e[i].symbolTotalFormat = dollarUSLocale.format(e[i].TotalPerMount)
      e[i].symbolTotalCurrency = '$ ' + e[i].symbolTotalFormat + ' ' + this.props.user.codeCurrency;
    }
    return e
  }

  symbolTotalGross(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolTotalFormat = dollarUSLocale.format(e[i].totalGross)
      e[i].symbolTotalGrossCurrency = '$ ' + e[i].symbolTotalFormat + ' ' + this.props.user.codeCurrency;
    }
    return e
  }

  symbolTotalNet(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolTotalFormat = dollarUSLocale.format(e[i].totalNet)
      e[i].symbolTotalNetCurrency = '$ ' + e[i].symbolTotalFormat + ' ' + this.props.user.codeCurrency;
    }
    return e
  }

  symbolTotalProfit(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolTotalFormat = dollarUSLocale.format(e[i].totalGross - e[i].totalNet)
      e[i].symbolTotalProfitCurrency = '$ ' + e[i].symbolTotalFormat + ' ' + this.props.user.codeCurrency;
    }
    return e
  }


  getReportGeneral = async () => {

    try {
      await axios.get(`${url}/api/auth/accounting_reportGeneral`)
        .then(res => {
          const sales = res.data;
          this.setState({ sales })
        });
    } catch (error) {
    }
  }

  getReportDefault = async () => {

    try {
      await axios.get(`${url}/api/auth/accounting_reportsDefault`)
        .then(res => {
          const reports = res.data;
          this.symbolReportsCurrency(reports)
          this.setState({ reports })
        });
    } catch (error) {
    }
  }

  symbolReportsCurrency(e) {
    const formatter = new Intl.NumberFormat('en-US', {
      maxFractionDigits: 2
    })

    let length = Object.keys(e).length
    for (let i = 0; i < length; i++) {
      e[i].total = formatter.format(e[i].total)
      e[i].totalCurrency = e[i].total + ' ' + this.props.user.codeCurrency;
    }
    return e
  }


  updateAuxRates = async (data) => {

    try {
      await axios.put(`${url}/api/auth/invoices_updateRates`, data)
        .then(res => {
          this.setState({ data })
        });
    } catch (error) {
    }

  }

  updateAuxNetRates = async (data) => {

    try {
      await axios.put(`${url}/api/auth/net_invoices_updateRates`, data)
        .then(res => {
          this.setState({ data })
        });
    } catch (error) {
    }

  }



  sales = () => {
    let show = []
    let sales = { ...this.state.sales }

    let totalPendding = 0
    let salesPendding = 0
    let totalFullyPaid = 0
    let salesFullyPaid = 0
    let totalSales = 0
    let totalPenddingInvoices = 0
    let porcentegePendding = 0
    let totalFullyPaidInvoices = 0
    let porcentegeFullyPaid = 0

    const formatter = new Intl.NumberFormat('en-US', {
      maxFractionDigits: 2
    })



    for (let i = 0; i < Object.keys(sales).length; i++) {
      if (i === 0) {
        totalPendding = formatter.format(sales[i].total)
        salesPendding = sales[i].sales
        console.log('INVOICES PENDING', totalPendding, salesPendding);
      }
      else if (i === 1) {
        totalFullyPaid = formatter.format(sales[i].total)
        salesFullyPaid = sales[i].sales
        console.log('INVOICES FULLY PAID: ', totalFullyPaid, salesFullyPaid);
      }
    }

    totalSales = salesPendding + salesFullyPaid


    for (let i = 0; i < Object.keys(sales).length; i++) {
      if (i === 0) {
        totalPenddingInvoices = (sales[i].sales).toFixed(0)
        porcentegePendding = ((100 / totalSales) * sales[i].sales).toFixed(0)
      } else if (i === 1) {
        totalFullyPaidInvoices = (sales[i].sales).toFixed(0)
        porcentegeFullyPaid = ((100 / totalSales) * sales[i].sales).toFixed(0)
      }
    }


    const chartData = {
      labels: ['Pendding', 'Paid'],
      datasets: [
        {
          data: [salesPendding, salesFullyPaid],
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FFA726"
          ],
          hoverBackgroundColor: [
            "#64B5F6",
            "#81C784",
            "#FFB74D"
          ]
        }
      ]
    };

    const lightOptions = {
      legend: {
        labels: {
          fontColor: '#495057'
        }
      }
    };





    show.push(
      <>
        <div class="flex justify-content-end flex-wrap">
          <span className="p-float-label">
            <Calendar
              id="yearpicker"
              value={ this.state.dates} 
              dateFormat="yy"
              onChange={(e) => this.onDateChange(e, 'date')}
              view='year'
              readOnlyInput
              showIcon
              style={{ marginBottom: 15 }}
            />
            {/* <label htmlFor="yearpicker">Year picker</label> */}
          </span>
        </div>

        <div className="grid">
          <div className="col-lg-6 col-xxl-6">
            <div className="card-header">
              <div className="grid">
                {/* <div className="col-fixed"> */}
                <div className='col-12'>
                  <h6 className="card-label" >
                    Facturas
                    <span className="d-block text-muted pt-2 font-size-sm">
                    </span>
                  </h6>
                </div>

                {/* Facturas pendientes */}
                <div className='col-6'>
                  <p className='text-base text-left'><span>Facturas pendientes</span></p>
                </div>

                <div className='col-1'>
                  <p className='text-base'>
                    <span>{totalPenddingInvoices}</span>
                  </p>
                </div>

                <div className='col-5'>
                  <p className='text-base'>
                    <span>{porcentegePendding}%</span>
                    {/* <span > de total de facturas</span>
                      <span>{totalPendding} </span>
                      <span >{this.props.user.codeCurrency}</span> */}
                  </p>
                </div>

                {/* <div className='col-fixed'>
                    <p className='text-3xl'>
                      <span>{porcentegePendding}%</span>
                      <span > de total de facturas</span>
                      <span>{totalPendding} </span>
                      <span >{this.props.user.codeCurrency}</span>
                    </p>
                  </div> */}


                {/* <p className='text-3xl'><span>{totalPendding}</span><span >{this.props.user.codeCurrency}</span></p> */}

                {/* Facturas pagadas */}
                <div className='col-6'>
                  <p className='text-base text-left'>Facturas pagadas</p>
                </div>

                <div className='col-1'>
                  <p className='text-base'>
                    <span>{totalFullyPaidInvoices}</span>
                    {/* <span > facturas pagadas</span> */}
                  </p>
                </div>

                <div className='col-5'>
                  <p className='text-base'>
                    <span>{porcentegeFullyPaid}%</span>
                    {/* <span > de total de facturas </span> */}
                    {/* <span>{totalFullyPaid} </span>
                      <span >{this.props.user.codeCurrency}</span> */}
                  </p>
                </div>

                {/* Total de facturas */}
                <div className='col-6'>
                  <p className='text-base text-left'><span>Total de facturas</span></p>
                  {/* <p className='text-3xl'><span>{totalSales}</span><span > facturas</span></p> */}
                </div>

                <div className='col-1'>
                  <p className='text-base'><span>{totalSales}</span></p>
                </div>
{/* 
                <div className='col-5'>
                  <p className='text-base'><span>{Number(porcentegeFullyPaid) + Number(porcentegePendding)}%</span></p>
                </div> */}

                {/* <p className='text-3xl'><span>{totalFullyPaid}</span><span >{this.props.user.codeCurrency}</span></p> */}
                {/* </div> */}
                {/* <div className="col-lg-10"> */}
                {/* <Chart type="pie" data={chartData} options={lightOptions} /> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xxl-6">
            <div className="card-header border-0 py-5">

              <Toast ref={(el) => this.toast = el} />
              <h6 className="card-label" >
                Ventas por mes
                <span className="d-block text-muted pt-2 font-size-sm">
                </span>
              </h6>
              <div className="card-body pt-0 pb-3">
                {/*SHOW EXCHANGE RATES*/}
                <DataTable
                  ef={(el) => this.dt = el} value={this.state.salesMonth} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate={this.state.paginatorText} rows={12} ref={(el) => { this.dt = el; }}
                  emptyMessage={<FormattedMessage id="ACCOUNTING_EXCHANGE.NO_DATA" />} loading={this.state.loading}
                  dataKey="id">
                  <Column field='Mes' header='Mes' headerStyle={{ width: 'flex' }} ></Column>
                  <Column field='symbolTotalCurrency' header='Total amount' headerStyle={{ width: 'flex' }} ></Column>
                  {/* <Column header='Gross' headerStyle={{ width: 'flex' }}></Column>
                  <Column header='Net' headerStyle={{ width: 'flex' }}></Column>
                  <Column header='Diferencial' headerStyle={{ width: 'flex' }}></Column> */}

                </DataTable>
              </div>
            </div>
          </div>
        </div>




      </>

    )
    return show

  }


  Calendar = () => {
    let show = []
    if (this.state.showReportDates === true) {
      show.push(
        <>
          <span className="p-float-label">
            <Calendar id="range" value={this.state.reportDates} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ reportDates: e.value })} selectionMode="range" readOnlyInput showIcon showButtonBar={this.onReportDateChange()} />
            <label htmlFor="range">{this.state.campusText.DateRange}</label>
          </span>
        </>
      )
    }
    return show
  }


  reports = () => {
    let show = []
    let reports = { ...this.state.reports }

    console.log("REPORTS:", reports)




    let agencies = []
    let sales = []
    let pendding = []
    let fullyPaid = []


    for (let i = 0; i < Object.keys(reports).length; i++) {
      agencies.push(reports[i].name)
      sales.push(reports[i].sales)
      pendding.push(reports[i].pendding)
      fullyPaid.push(reports[i].fullyPaid)
    }



    const stackedData = {

      labels: agencies,
      datasets: [{
        type: 'bar',
        label: 'Pending',
        backgroundColor: '#FFA726',
        data: pendding
      }, {
        type: 'bar',
        label: 'Paid',
        backgroundColor: '#66BB6A',
        data: fullyPaid
      }]
    };

    let stackedOptions = {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#495057'
          },
          gridLines: {
            color: '#ebedef'
          }
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#495057'
          },
          gridLines: {
            color: '#ebedef'
          }
        }]
      },
      legend: {
        labels: {
          fontColor: '#495057'
        }
      }
    };

    show.push(

      <>
        <div class="card-header border-0 py-12">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/* <Dropdown value={this.state.selectedType} options={this.types} onChange={this.onTypesChange} optionLabel="type" optionValue="id" placeholder={this.state.campusText.Type} /> */}
            {/* <div>
              {this.Calendar()}
            </div> */}
          </div>
          <div className="form-group row">
            <div className="col-lg-6">

              <Toast ref={(el) => this.toast = el} />
              <h6 className="card-label" >
                Ventas por oficina
                <span className="d-block text-muted pt-2 font-size-sm">
                </span>
              </h6>

              {/*SHOW SALES OFFICE & */}
              <DataTable
                ef={(el) => this.dt = el} value={this.state.salesOffices} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={this.state.paginatorText} rows={30} ref={(el) => { this.dt = el; }}
                emptyMessage={<FormattedMessage id="ACCOUNTING_SALES.NO_DATA" />} loading={this.state.loading}
                dataKey="id">
                <Column field='office' header={this.state.campusText.Name} headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalGrossCurrency' header='Gross' headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalNetCurrency' header='Net' headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalProfitCurrency' header='Diferencial' headerStyle={{ width: 'flex' }} sortable></Column>
                {/* <Column header={this.state.campusText.Pendding} headerStyle={{ width: 'flex' }} sortable></Column> */}
              </DataTable>
            </div>

            <div className="col-lg-6">

              <Toast ref={(el) => this.toast = el} />
              <h6 className="card-label" >
                Ventas por programa
                <span className="d-block text-muted pt-2 font-size-sm">
                </span>
              </h6>

              {/*SHOW SALES PROGRAM & */}
              <DataTable
                ef={(el) => this.dt = el} value={this.state.salesProgram} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={this.state.paginatorText} rows={30} ref={(el) => { this.dt = el; }}
                emptyMessage={<FormattedMessage id="ACCOUNTING_SALES.NO_DATA" />} loading={this.state.loading}
                dataKey="id">
                <Column field='program' header={this.state.campusText.Name} headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalGrossCurrency' header='Gross' headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalNetCurrency' header='Net' headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalProfitCurrency' header='Diferencial' headerStyle={{ width: 'flex' }} sortable></Column>
                {/* <Column header={this.state.campusText.Pendding} headerStyle={{ width: 'flex' }} sortable></Column> */}
              </DataTable>
            </div>
            {/* <div className="col-lg-6">
              <Chart type="bar" data={stackedData} options={stackedOptions} />
            </div> */}
          </div>

        </div>
      </>

    )
    return show

  }

  reportSchool = () => {
    let show = []
    let reports = { ...this.state.reports }

    console.log("REPORTS:", reports)




    let agencies = []
    let sales = []
    let pendding = []
    let fullyPaid = []


    for (let i = 0; i < Object.keys(reports).length; i++) {
      agencies.push(reports[i].name)
      sales.push(reports[i].sales)
      pendding.push(reports[i].pendding)
      fullyPaid.push(reports[i].fullyPaid)
    }



    const stackedData = {

      labels: agencies,
      datasets: [{
        type: 'bar',
        label: 'Pending',
        backgroundColor: '#FFA726',
        data: pendding
      }, {
        type: 'bar',
        label: 'Paid',
        backgroundColor: '#66BB6A',
        data: fullyPaid
      }]
    };

    let stackedOptions = {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#495057'
          },
          gridLines: {
            color: '#ebedef'
          }
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#495057'
          },
          gridLines: {
            color: '#ebedef'
          }
        }]
      },
      legend: {
        labels: {
          fontColor: '#495057'
        }
      }
    };

    show.push(

      <>
        <div class="card-header border-0 py-12">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/* <Dropdown value={this.state.selectedType} options={this.types} onChange={this.onTypesChange} optionLabel="type" optionValue="id" placeholder={this.state.campusText.Type} /> */}
            {/* <div>
              {this.Calendar()}
            </div> */}
          </div>
          <div className="grid">
            <div className="col">

              <Toast ref={(el) => this.toast = el} />
              <h6 className="card-label" >
                Ventas por escuela
                <span className="d-block text-muted pt-2 font-size-sm">
                </span>
              </h6>

              {/*SHOW SALES SCHOOL & */}
              <DataTable
                ef={(el) => this.dt = el} value={this.state.salesProvider} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={this.state.paginatorText} rows={30} ref={(el) => { this.dt = el; }}
                emptyMessage={<FormattedMessage id="ACCOUNTING_SALES.NO_DATA" />} loading={this.state.loading}
                dataKey="id">
                <Column field='provider' header={this.state.campusText.Name} headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalGrossCurrency' header='Gross' headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalNetCurrency' header='Net' headerStyle={{ width: 'flex' }} sortable></Column>
                <Column field='symbolTotalProfitCurrency' header='Diferencial' headerStyle={{ width: 'flex' }} sortable></Column>
                {/* <Column header={this.state.campusText.Pendding} headerStyle={{ width: 'flex' }} sortable></Column> */}
              </DataTable>
            </div>
            {/* <div className="col-lg-6">
              <Chart type="bar" data={stackedData} options={stackedOptions} />
            </div> */}
          </div>

        </div>
      </>

    )
    return show

  }


  render() {
    return (
      <div>
        {/* School analytics */}
        {this.sales()}
        <br></br>
        {this.reports()}
        <br></br>
        {this.reportSchool()}
      </div>
    )
  }




}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, auth.actions)(Analytics);