import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ListAgents from "../modulesAgency/ListAgents";
import { useIntl } from "react-intl";

export const AgentPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(int1.formatMessage({id: "LEARNING_GENERAL.AGENT_LIST"}));
    return (<><ListAgents/></>);
}