import React from "react";

export function AsideMenuListDefault({ layoutProps }) {

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        </ul>
        {/* end::Menu Nav */}
      </>
  );
}
