import React, { useState } from "react"
import StudentsInfo from "./StudentsInfo"
import ParentsInfo from "./ParentsInfo"
import ProgramInfo from "./ProgramInfo"
import InsuranceInfo from "./InsuranceInfo"
import NotificationsTable from "./NotificationsTable"
import DocumentsInfo from "./DocumentsInfo"

import { Button } from "primereact/button"
import { makeStyles, withStyles  } from "@material-ui/core"
import Tabs from '@material-ui/core/Tabs'
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import PropTypes from 'prop-types'
import FlightInfo from "./FlightInfo"
// import { FormattedMessage } from "react-intl"
// import { getGeneralTextTranslate } from "../../../_metronic/i18n/TextTranslate"

function TabContainer(props){


  return (
    <Typography component="div" style={{ padding: 8 * 1}}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: '#1890ff'
  }
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTansform: 'none',
    minWidth: 72,
    fontWeeight: theme.typography.fontWeighRegular,
    marginTight: theme.spacing(4),
    fontFamily : [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
    root: {
    flexGrow: 1,
    },
    padding: {
    padding: theme.spacing(3),
    },
    Tabs: {
    backgroundColor: theme.palette.background.paper,
    },
}));

export default function () {
        const classes = useStyles();
        const [value, setValue] = React.useState(0)

        const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <div className={classes.root}>
            <div className={classes.Tabs}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                <AntTab label="Students info" />
                <AntTab label="Parents info" />
                <AntTab label="Program info" />
                <AntTab label="Insurance info"/>
                <AntTab label="Notifications"/>
                <AntTab label="Documents info"/>
                <AntTab label="Flight info" />

            </AntTabs>
            {value === 0 && <TabContainer><StudentsInfo /></TabContainer>}
            {value === 1 && <TabContainer><ParentsInfo/></TabContainer>}
            {value === 2 && <TabContainer><ProgramInfo/></TabContainer>}
            {value === 3 && <TabContainer><InsuranceInfo/></TabContainer>}
            {value === 4 && <TabContainer><NotificationsTable/></TabContainer>}
            {value === 5 && <TabContainer><DocumentsInfo/></TabContainer>}
            {value === 6 && <TabContainer><FlightInfo/></TabContainer>}

            <Typography className={classes.padding} />
            </div>
        </div>
    )
}