import React, {Component} from 'react';
import axios from 'axios'
import ServerURL from '../../../ServerURL'

import AddIcon from '@material-ui/icons/Add'
import classNames from 'classnames'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
// import { Checkbox } from 'primereact/checkbox';

import { Avatar } from '@material-ui/core';

import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import * as auth from '../../modulesAdmin/Auth/_redux/authRedux';
import moment from 'moment';


const url = ServerURL();

class StudentsTable extends Component {

  emptyStudent = {
    name:'',
    first_name:'',
    last_name: '',
    phone: '',
    email: ''
  }

  constructor (props) {
    super(props)
    this.state = {
      name:'',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',

      emailMessage: '',
      first_nameMessage: '',
      last_nameMessage: '',
      phoneMessage: '',

      student: this.emptyStudent,
      students: null,
      ids: null,
      globalFilter: null,
      selectedStudents: null,
      studentDialog: false,
      btnDisable: false,
      deleteStudentDialog: false,
      deleteStudentsDialog: false,
      submitted: false,

      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTranslate: getGeneralTextTranslate(),

      selectedGroup: '',
      resetGroup: '',
      selectedCS: '',
      resetCS: '',

      changeToStudent: false,
      idStudentSelect: false,
      redirect: false,
    }

    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.saveStudent = this.saveStudent.bind(this);
    this.deleteStudent = this.deleteStudent.bind(this);
    this.openNew = this.openNew.bind(this);
    this.editStudent = this.editStudent.bind(this);
    this.confirmDeleteStudent= this.confirmDeleteStudent.bind(this);
    this.confirmDeleteStudentsSelected = this.confirmDeleteStudentsSelected.bind(this);
    this.deleteSelectedStudents = this.deleteSelectedStudents.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.hideDeleteStudentDialog = this.hideDeleteStudentDialog.bind(this);
    this.hideDeleteStudentsDialog = this.hideDeleteStudentsDialog.bind(this);

    this.onInputChange = this.onInputChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.onCSChange = this.onCSChange.bind(this);
    this.onClick = this.onClick.bind(this);

    // this.itemStudent = this.itemStudent.bind(this);
    this.changePage = this.changePage.bind(this);

    this.filePassportTemplate = this.filePassportTemplate.bind(this)
    }

    onClick(name, idStudentSelect){
      let state = {
        [`${name}`]: true,
        idStudentSelect
      };

      this.setState(state)
    }


    changePage(dataStudent) {
      console.log(this.props);
      console.log(dataStudent);
      this.props.updatedStudent(dataStudent)
      this.setState({ redirect: true })
    }
            
      componentDidMount() {
        this.getStudents()
      }
    
      editStudent(){
        this.setState({ studentDialog: true })
      }

      getStudents = async () =>{
          try {
              let res = await axios.get(`${url}/api/public/students`)
              .then(res => {
                const students = res.data;
                this.ProgramStartDate(students)
                this.ProgramEndDate(students)
                this.setState({ students   })
              });                     
                console.log(res.data)
              }catch(error){

        }
      }

      ProgramStartDate(e){
        e.map((student) => {
          var origin = student.program_start_date
          var date = moment.utc(origin).format('DD/MM/YYYY')

          if(student.program_start_date!==null){
            student.program_start_date = date
          }

          return student
        })
      }

      ProgramEndDate(e){
        e.map((student) => {
          var origin = student.program_end_date
          var date = moment.utc(origin).format('DD/MM/YYYY')

          if(student.program_end_date!==null){
            student.program_end_date = date
          }

          return student
        })
      }

            
      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let student = {...this.state.student};
        student[`${name}`] = val;
        this.setState({ student });
      }

      onGroupChange(e) {
        let student = {...this.state.student};
        student['fk_id_wiyu_group'] = e.value;
        this.setState({ selectedGroup: e.value, student })
      }

      onCSChange(e){
        let student ={...this.state.student};
        student['fk_id_cs'] = e.value;
        this.setState({ selectedCS: e.value, student })
      }

      openNew() {
        this.setState({
            studentDialog: true,
            student: this.emptyStudent
        })
      }

     /* async deleteUser(){
        let data = {...this.state.user};

        try {
        await axios.delete(`${url}/api/auth/user/${data.id}`)
          .then(res => {
          this.getUsers()
        })
        console.log(res)
        //if (!res.error) {
          this.setState({
            data,
            deleteUserDialog: false,
            user: this.emptyUser,

        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
        console.log("Delete:",this.state.deleteUserDialog)

        //}
          }catch(error){
          }    
      }    */  
      validateEmail() {
        const student = this.state.student

        let emailMessage;
        if (!student.email){
          emailMessage = 'Requerido';
        } else {
          emailMessage = ''
        }
        this.setState({ emailMessage })
        return emailMessage
      }

      validateFirstName() {
        const student = this.state.student

        let firstNameMessage;
        if (!student.first_name){
          firstNameMessage = 'Requerido';
        } else {
          firstNameMessage = ''
        }
        this.setState({ firstNameMessage })
        return firstNameMessage
      }

      validateLastName() {
        const student = this.state.student

        let lastNameMessage;
        if (!student.last_name){
          lastNameMessage = 'Requerido';
        } else {
          lastNameMessage = ''
        }
        this.setState({ lastNameMessage })
        return lastNameMessage
      }

      validatePhone() {
        const student = this.state.student

        let phoneMessage;
        if (!student.phone){
          phoneMessage = 'Requerido';
        } else {
          phoneMessage = ''
        }
        this.setState({ phoneMessage })
        return phoneMessage
      }

      validateFk_Id_Wiyu_Group() {
        const student = this.state.student

        let fk_id_wiyu_groupMessage;
        if (!student.fk_id_wiyu_group) {
          fk_id_wiyu_groupMessage = 'Requerido';
        } else {
          fk_id_wiyu_groupMessage = ''
        }
        this.setState( { fk_id_wiyu_groupMessage })
        return fk_id_wiyu_groupMessage
      }
      

      isValid(){
        const { email, first_name, last_name, 
        phone, fk_id_wiyu_group } = this.state.student

        const emailMessage = this.validateEmail()
        const firstNameMessage = this.validateFirstName()
        const lastNameMessage = this.validateLastName()
        const phoneMessage = this.validatePhone()
        const fk_id_wiyu_groupMessage = this.validateFk_Id_Wiyu_Group()

        if (email && first_name && last_name && phone && fk_id_wiyu_group) {
          if (!emailMessage && !firstNameMessage && !lastNameMessage && !phoneMessage && !fk_id_wiyu_groupMessage) {
            return true; 
          } else {
            return false;
          }
        }
        return false;
      }

      getGroups = async () => {
        try {
          await axios.get(`${url}/api/public/wiyu_groups`)
          .then(res => {
            const groups = res.data;
            this.setState({ groups })
            console.log(res);
          });
          // console.log(res.data)
        } catch (error) {
          console.log(error)
        }
      }

      getCS = async () => {
        try{
          await axios.get(`${url}/api/public/wiyu_customer_success`)
          .then(res => {
            const cs = res.data;
            this.setState({ cs })
            console.log(res);
          });
          // console.log(res.data);
        } catch (error) {
          console.log(error);
        }
      }

      getParents = async () =>{
        try {
          let res = await axios.get(`${url}/api/public/parents`)
          .then(res => {
            const parents = res.data;
            this.state({ parents })
          });
          console.log(res.data);
        } catch (error) {
          console.log(error);
        }
      }

    async saveStudent() {
      let data = {
        name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone
      }
      if(this.state.first_name && this.state.last_name && this.state.email && this.state.phone){
        console.log(data);
        try {
          await axios.post(`${url}/api/public/students`, data)
          .then(res => {
            const {message, message_type } = res.data.message
            let type = null;
            
            if(message_type === 'success'){
              type = "GENERAL.SUCCESSFUL"
              this.setState({
                student: this.emptyStudent,
                studentDialog: false,
              })
              this.getStudents()
            } else {
              type = "GENERAL_MESSAGE.ERROR"
            }

            this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id:message}), life:3000});
          })
        } catch (error) {
          
        }
      } else {
        this.toast.show({ severity: 'info', summary: this.props.intl.formatMessage({id: "GENERAL_MESSAGE.ERROR"}), detail: "Algo no anda bien", life:3000});
      }
  }

  deleteStudent(){
    let data = {...this.state.student};

    try {
    let res = axios.delete(`${url}/api/public/students/${data.id}`)
      .then(res => {
      this.getStudents()
    })
    if (!res.error) {
      this.setState({
        data,
        deleteStudentDialog: false,
        student: this.emptyStudent
    });
    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Student Deleted', life: 3000 });
    }
      }catch(error){
      } 
  } 

  confirmDeleteStudent(student) {
    console.log('Student: ', student)
    this.setState({
        student,
        deleteStudentDialog: true
    })
  }

  confirmDeleteStudentsSelected() {
    this.setState({ deleteStudentsDialog: true })
  }

  deleteSelectedStudents() {
    let students = this.state.students.filter(val => !this.state.selectedStudents.includes(val));
    let data = this.state.students.filter(val => this.state.selectedStudents.includes(val));

    console.log("Students: ", data)

    try {
    let res = axios.delete(`${url}/api/public/students`, {data})
      .then(res => {
      this.getStudents()
    })
    if (!res.error) {
      this.setState({
        students,
        deleteStudentsDialog: false,
        selectedStudents: null
    });
    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Students Deleted', life: 3000 });
    }
      }catch(error){
        console.log(error)
      } 
  }

  hideDeleteStudentDialog() {
    this.setState({ deleteStudentDialog: false })
  }
    
  hideDeleteStudentsDialog() {
    this.setState({ deleteStudentsDialog: false });
}

      hideDialog() {
          this.setState({
              submitted: false,
              studentDialog: false,
              firstNameMessage: '',
              lastNameMessage: '',
              emailMessage: '',
              phoneMessage: '',
              groupMessage: ''
        })  
      }



      userBodyTemplate(rowData) {
          return (
              <>
                  <Avatar alt={rowData.first_name} src={`${rowData.photo}`} width={32} style={{verticalAlign: 'middle',   margin: "10px", width: "50px", height: "50px",}} />
              </>
          )
      }
    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.changePage(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteStudent(rowData)} />
            </>
        )
      }

      filePassportTemplate(rowData){
        if(rowData.doc_passport_url!==null){
          return(
            <>
              <a href={`${rowData.doc_passport_url}`} target="_blank" rel="noopener noreferrer"><Button type="button" icon="pi pi-download" style={{verticalAlign: 'middle', width: "30px", height: "30px"}}></Button></a>
            </>
          )
        }
      }

      fileIdentificationTemplate(rowData){
        if(rowData.doc_identification_url!==null){
          return(
            <>
              <a href={`${rowData.doc_identification_url}`} target="_blank" rel="noopener noreferrer"><Button type="button" icon="pi pi-download" style={{verticalAlign: 'middle', width: "30px", height: "30px"}}></Button></a>            
            </>
          )
        }
      }

      FilePassport(){
        let UploadAndShow = []
        let student={...this.state.student}

        if(student.doc_passport_url){
          UploadAndShow.push(
            <>
              <div className='col-lg-2.5'>
                <FileUpload 
                  type="file"
                  name="doc_passport"
                  ref={(ref) => {this.uploadInput = ref }}
                  multiple={false}
                  mode="basic"
                  chooseLabel='Passport'
                />
              </div>
              <div className="col-lg-1">
                <a href={`${student.doc_passport_url}`} target="_blank" rel="noopener noreferrer"><Button type="button" icon="pi pi-download"></Button></a>
              </div>
            </>
          )
        } else {
          UploadAndShow.push(
            <>
              <div className="col-lg-3">
                <FileUpload 
                  type="file"
                  name="doc_passport"
                  ref={(ref) => {this.uploadInput = ref}}
                  multiple={false}
                  mode="basic"
                  chooseLabel="Passport"
                />
              </div>
            </>
          )
        }
        return UploadAndShow
      }

      FileIdentification(){
        let UploadAndShow = []
        let student={...this.state.student}

        if(student.doc_identification_url){
          UploadAndShow.push(
            <>
              <div className='col-lg-2.5'>
                <FileUpload 
                  type="file"
                  name="doc_identification"
                  ref={(ref) => {this.uploadInput2 = ref }}
                  multiple={false}
                  mode="basic"
                  chooseLabel='Identification'
                />
              </div>
              <div className="col-lg-1">
                <a href={`${student.doc_identification_url}`} target="_blank" rel="noopener noreferrer"><Button type="button" icon="pi pi-download"></Button></a>
              </div>
            </>
          )
        } else {
          UploadAndShow.push(
            <>
              <div className="col-lg-3">
                <FileUpload 
                  type="file"
                  name="doc_identification"
                  ref={(ref) => {this.uploadInput2 = ref}}
                  multiple={false}
                  mode="basic"
                  chooseLabel="Identification"
                />
              </div>
            </>
          )
        }
        return UploadAndShow
      }

      renderButtonStudent(data){
        console.log("Create student", data);
        return (
          <FormattedMessage id="OPTION.NEW_USER">
            {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
          </FormattedMessage>
        )
      }

      render(data){
        if(this.state.redirect){
          return <Redirect to={{
            pathname: '/wiyu-students-new'
          }} />
        }
      
      const studentDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button disabled={this.state.btnDisable} label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveStudent} />}
            </FormattedMessage>
        </>
      );

      const deleteStudentDialogFooter = (
        <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteStudentDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteStudent} />
        </>
      )

      const deleteStudentsDialogFooter = (
        <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteStudentsDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedStudents} />
        
        </>
      )

      return(       
          <div>
          <Toast ref={(el) => this.toast = el} />  
          <FormattedMessage id="GENERAL_MESSAGE.COMPLETE_INFORMATION">
            {
              (message) => (
                <Dialog header={message} footer={studentDialogFooter} visible={this.state.studentDialog} onHide={this.hideDialog} style={{width:"30%"}}>
                  <br></br>
                
                  <div className="form-group row">
                    <div className="col-lg-6">  
                      <Avatar  
                        size="small" 
                        shape="circle"/>
                        <input 
                        type="file" 
                        name="avatar"
                        ref={(ref) => { this.uploadInput = ref; }}
                        multiple= {false} 
                      />
                    </div>
                  </div>

                  <br></br>
                    {/* FirstName */}
                    <br></br>
                    <div className="form-group row">
                    <div className="col-lg-6">
                    <span className="p-float-label">      
                      <InputText  
                        style={{ width: 190, height:33 }}
                        id="first_name"
                        variant="outlined"
                        margin="dense"
                        value={this.state.first_name}             
                        onChange={(e) => this.setState({first_name: e.target.value})}
                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.student.first_name  })}
                        />
                        <label htmlFor="first_name">First Name</label>
                    </span>
                    {
                      this.state.firstNameMessage && (
                        <small id="first_name-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.firstNameMessage}/></small>
                      )
                    }
                    </div>
                        {/* Last Name*/}
                        <div className="col-lg-6">
                        <span className="p-float-label">      
                        <InputText
                        style={{ width: 190, height:33 }}
                        id="last_name"
                        variant="outlined"
                        margin="dense"
                        value={this.state.last_name}             
                        onChange={(e) => this.setState({last_name: e.target.value})}
                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.student.last_name  })}
                        />
                        <label htmlFor="last_name">Last Name</label>
                    </span>
                    {
                      this.state.lastNameMessage && (
                        <small id="last_name-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.lastNameMessage}/></small>
                      )
                    }
                    </div>          
                    </div>
                    <br></br>
                    <div className="form-group row">
                      {/* Email */}
                      <div className="col-lg-6">  
                        <span className="p-float-label">      
                          <InputText
                            style={{ width: 190, height:33 }}
                            id="email"
                            variant="outlined"
                            label="Email"
                            margin="dense"
                            value={this.state.email}
                            onChange={(e) => this.setState({email: e.target.value})}
                            keyfilter="email"
                            required autoFocus 
                            className={classNames({'p-invalid': this.state.submitted && !this.state.student.email})}
                            />
                            <label htmlFor="email">Email</label>
                        </span>
                      {
                        this.state.emailMessage && (
                          <small id="email-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.emailMessage}/></small>
                        )
                      }
                      </div>
                      {/* Phone*/}
                      <div className="col-lg-6">
                        <span className="p-float-label">      
                          <InputText
                          style={{ width: 190, height:33 }}
                          id="phone"
                          type="phone"
                          variant="outlined"
                          margin="dense"
                          value={this.state.phone}             
                          onChange={(e) => this.setState({phone: e.target.value})}
                          keyfilter="pnum"
                          required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.student.phone  })}
                          />
                          <label htmlFor="phone">Phone</label>
                        </span>
                        {
                          this.state.phoneMessage && (
                            <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.phoneMessage}/></small>
                          )
                        }
                      </div>
                    </div>

                    <br />

              </Dialog>
              )
            }
          </FormattedMessage>

          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
    			<h3 className="card-label" >
            Students administration
				  <span className="d-block text-muted pt-2 font-size-sm">You can create, update, delete and see the info of your students</span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 

                    <div style={{ marginRight: 10}} >
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteStudentsSelected} disabled={!this.state.selectedStudents || !this.state.selectedStudents.length } />}
                      </FormattedMessage>
                    </div>
                    
                    
                    <div style={{ marginRight: 10}}>
                      {this.renderButtonStudent(data)}
                    </div>

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GlobalSearch} />
                    </span></p>
                    </div>  
                    
            </div>
          </div>

          
          <div>
           {/*SHOW USER */}
          
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.students} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No students found." loading={this.state.loading}
                        selection={this.state.selectedStudents} onSelectionChange={e => this.setState({ selectedStudents: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="photo" body={this.userBodyTemplate}></Column>
                        <Column field="fullname" header="Full name" sortable filter filterPlaceholder='Search by full name'></Column>
                        {/* <Column field="email" header="Email" sortable filter filterPlaceholder={this.state.campusText.SearchByEmail}></Column>
                        <Column field="phone" header="Phone"sortable filter filterPlaceholder={this.state.campusText.SearchByPhone}></Column> */}
                        <Column field="program_name" header="Program name" sortable filter filterPlaceholder='Search by customer success'></Column>
                        <Column field="program_start_date" header="Program start date" sortable filter filterPlaceholder='Search by customer success'></Column>
                        <Column field="program_end_date" header="Program end date" sortable filter filterPlaceholder='Search by customer success'></Column>
                        <Column field='school_destination' header="School destination"></Column>
                        {/* <Column field="country_origin" header="Nationality" sortable filter filterPlaceholder='Search by nationality'></Column> */}
                        {/* <Column field="school_origin" header="School origin" sortable filter filterPlaceholder='Search by school origin'></Column> */}
                        <Column body={this.actionBodyTemplate}></Column>
                        {/*<Column body={this.actionUserView}></Column>*/}

                    </DataTable>
                </div>
          </div>

          {/* Delete student */}
          <Dialog visible={this.state.deleteStudentDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteStudentDialogFooter} onHide={this.hideDeleteStudentDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
              {this.state.student && <span> Are you sure you want to delete <b>{this.state.student.fullname}</b>?</span>}
            </div>
          </Dialog>

          {/* Delete selected students */}
          <Dialog visible={this.state.deleteStudentsDialog } style={{ width: '450px'}} header="Confirm" modal footer={ deleteStudentsDialogFooter } onHide={this.hideDeleteStudentsDialog} >
            <div className="confirmation-content" >
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
              {this.state.student && <span> Are you sure you want delete the selected students?</span>}
            </div>
          </Dialog>

          {/*UPDATE USER & CREATE USER*/}
          {/* <div>

          
          </div> */}
    </div>  
    )
  }
}

/*function mapStateToProps(state) {
  return {
    my_user: state.auth.user
  };
}*/

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(StudentsTable)
)

