import React, {Component} from 'react'
import classNames from 'classnames';
import axios from 'axios'
import ServerURL from '../../../ServerURL'


import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { connect } from 'react-redux';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import { InputNumber } from 'primereact/inputnumber';




const url = ServerURL();




class AgencyCommissionsTable extends Component {
            

  
  emptyAgencyCommissions = {
  id:'',
  fk_id_enterprise:'',
  commission_item_name:'',
  commission_percentage:'',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    fk_id_enterprise:'',
    commission_item_name:'',
    commission_percentage:'',


     /**
       * Aux messages
       */
      commission_percentageMessage: '',
    
     

    agencyCommission: this.emptyAgencyCommissions,
    agencyCommissions:null,
    globalFilter: null,
    agencyCommissionsDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate(),
    selectedAgencyCommission: null,

  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveAgencyCommissions = this.saveAgencyCommissions.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editAgencyCommissions = this.editAgencyCommissions.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  }


    
      componentDidMount() {
        this.getAgencyCommissions()
      }

      isValid(){
        const { commission_percentage } = this.state.agencyCommission
        console.log("COMMISSION EN IS VALID:", commission_percentage)
              const commission_percentageMessage = this.validateCommissionPercentage()
       

        
              if(commission_percentage){
                if(!commission_percentageMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
              validateCommissionPercentage() {
              const agencyCommission = this.state.agencyCommission
              
              let commission_percentageMessage;
              if (!agencyCommission.commission_percentage) {
                console.log("COMMISSION PERCENTAGE:",agencyCommission.commission_percentage)
                commission_percentageMessage = 'Requerido';
              } else {
                commission_percentageMessage = ''
              }
              this.setState({ commission_percentageMessage })
              return commission_percentageMessage
            }

            getTextAuxCommissionPercentage(){
              const message = this.state.commission_percentageMessage
              return message
            }

            
          
     
              getAgencyCommissions = async () =>{
                try {
                  
                    await axios.get(`${url}/api/auth/agencyCommissions`)
                    .then(res => {
                      const agencyCommissions = res.data;
                      this.symbolAgencyPercentage(agencyCommissions)

                      this.setState({ agencyCommissions})
                    });                     
                    }catch(error){
              }
              }       
             
              symbolAgencyPercentage(e){
                let length = Object.keys(e).length
                    for(let i=0; i<length; i++){ 
                      e[i].symbolAgencyPercentage = e[i].commission_percentage +'%';
                        }
                return e
              }

      saveAgencyCommissions() {
        console.log("SÍ ENTRO AL SAVE")
        let state = { submitted: true };

        const decision = this.isValid();

        if (decision) {

          let data = {...this.state.agencyCommission};
          console.log("DATA:", data)

        if (this.state.agencyCommission.id) {

            try {
              let res = axios.put(`${url}/api/auth/agencyCommission/${data.id}`,data)
              .then(res =>{
                this.getAgencyCommissions()
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agency Commission Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/agencyCommissions`,data)
            
             .then(res => {
              this.getOffices()
            })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Agency Commission Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          agencyCommissionDialog: false,
          //agencyCommission: this.emptyAgencyCommission,
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.setState({
            agencyCommission: this.emptyAgencyCommission,
            submitted: false,
            agencyCommissionDialog: true,
           
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let agencyCommission = {...this.state.agencyCommission};

        agencyCommission[`${name}`] = val;
        console.log("INPUT CHANGE:",agencyCommission)
        this.setState({ agencyCommission });
      }

   
   


      hideDialog() {
          this.setState({
              submitted: false,
              agencyCommissionDialog: false,
              commissionPercentageMessage: '',

        })  
      } 


      editAgencyCommissions(agencyCommission) {
        console.log("OPEN EDIT:",agencyCommission)
          this.setState({
              agencyCommission,
              agencyCommissionDialog: true
          })
      }

      
        exportCSV() {
          this.dt.exportCSV();
      }
      
      

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editAgencyCommissions(rowData)} />
               {/* <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteOffice(rowData)} />*/}
            </>
        )
      }
      
      




      render(){
      
       const AgencyCommissionDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveAgencyCommissions} />}
            </FormattedMessage>
        </>
       );

       return(       
        <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		      <FormattedMessage id="ACCOUNTING_AGENCY_COMMISSIONS.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm">
            <FormattedMessage id="ACCOUNTING_AGENCY_COMMISSIONS.DESCRIPTION"/>
            </span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>


                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>
          
          <div>
           {/*SHOW ROLE */}
           <div className= "card-header flex-wrap border-0 pt-6 pb-0">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.agencyCommissions} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage={<FormattedMessage id="ACCOUNTING_AGENCY_COMMISSIONS.NO_DATA"/>} loading={this.state.loading}
                        selection={this.state.selectedAgencyCommission} onSelectionChange={e => this.setState({ selectedAgencyCommission: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="commission_item_name" header={this.state.campusText.CommissionItemName} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.CommissionItemName} filterMatchMode="contains"></Column>
                        <Column field="symbolAgencyPercentage" header={this.state.campusText.CommissionPercentage} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.CommissionPercentage} filterMatchMode="contains"></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>


          <div>
          {/*UPDATE AGENCY COMMISSION*/}
          <Dialog visible={this.state.agencyCommissionDialog} contentStyle={{maxHeight:"800px", width:"700px", height:"200px", overflow:"auto"}} header={<FormattedMessage id="OPTION.AGENCY_COMMISSION"/>} modal footer={AgencyCommissionDialogFooter} onHide={this.hideDialog}>
              <br></br>
              {/* Agency Commission */}
              <div className="form-group row">
               <div className="col-lg-12">
              <span className="p-float-label">
                  <InputText
                  style={{ width: 600, height:33, backgroundColor:'#e3e4e5' }}
                  id="commission_item_name"
                  variant="outlined"
                  type="commission_item_name"
                  margin="dense"
                  readOnly="true"
                  value={this.state.agencyCommission.commission_item_name}             
                  />
                  <label htmlFor="commission_item_name">{this.state.campusText.CommissionItemName}</label>
              </span>
              </div>
              </div>
              <div className="form-group row">
              <div className="col-lg-12">
              <span className="p-float-label">
                  <InputNumber
                  locale="en-US" 
                  minFractionDigits={2}   
                  min="0"              
                  inputStyle={{ width: 100, height:33 }}
                  id="commission_percentage"
                  variant="outlined"
                  margin="dense"
                  value={this.state.agencyCommission.commission_percentage}             
                  onValueChange={(e) => this.onInputChange(e, 'commission_percentage')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.agencyCommission.commission_percentage  })}
                  />%
                  <label htmlFor="commission_percentage">{this.state.campusText.CommissionPercentage}</label>
              </span>
              <small id="commission_percentage-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxCommissionPercentage(this.state)}</small>    
              </div>
              </div>            
          </Dialog>
        </div>

   </div>  
         
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, auth.actions)(AgencyCommissionsTable);

