import React from "react";
import {useSubheader} from "../../_metronic/_core";

import AgencyCommissions from "../modulesAccounting/AgencyCommissions/AgencyCommissionsTable"

export const AgencyCommissionsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Agency Commissions");

  return (<><AgencyCommissions /></>);
};