import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ListCourses from "../modulesWiyu/ListCourses"
import { useIntl } from "react-intl";

export const CoursesPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(int1.formatMessage({id: "LEARNING_GENERAL.COURSE_LIST"}));
    return (<ListCourses/>)
}