import React from "react";
import {useSubheader} from "../../_metronic/_core";

import AgentPaymentsTable from "../modulesAccounting/Invoices/AgentPaymentsTable"

export const AgentPaymentsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Payments");

  return (<><AgentPaymentsTable /></>);
};