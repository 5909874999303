import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import MDEditor from '@uiw/react-md-editor';
import { Editor } from 'primereact/editor';
import { Link, Redirect }  from "react-router-dom";
import { Player } from 'video-react'
import { InputText } from 'primereact/inputtext';
import { Document, Page, pdfjs  } from 'react-pdf';
import { Toast } from 'primereact/toast';
import 'video-react/dist/video-react.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'primeflex/primeflex.css';

import axios from 'axios';
import * as auth from "../Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL'
import { ProgressBar } from 'primereact/progressbar';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

const url = ServerURL();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Lesson extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOption: false,
      displayConfirmation: false,
      displayConfirmationDelete: false,
      value: "**Hello world!!!**",
      selectFile: null,
      urlFile: null,
      selectFilePDF: null,
      urlFilePDF: null,
      numPages: 1,
      pageNumber: 1,
      materials: null,
      draftMaterial: null,
      draftMaterialSelected: null,
      materialSelect: false,
      materialSelectDelete: false,
      originalValueMaterial: null,
      titleEdit: false,
      descriptionEdit: false,
      title: this.props.dataLesson.name,
      description: this.props.dataLesson.description,
      uploadPercentage: 0,
      redirect: false
    };

    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onChangeMDE = this.onChangeMDE.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.onChangePageLeft = this.onChangePageLeft.bind(this);
    this.onChangePageRight = this.onChangePageRight.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderPagesControls = this.renderPagesControls.bind(this);
    this.renderMarkdownEditor = this.renderMarkdownEditor.bind(this);
    this.renderVideo = this.renderVideo.bind(this);
    this.renderPDF = this.renderPDF.bind(this);
    this.addMarkdown = this.addMarkdown.bind(this);
    this.addVideo = this.addVideo.bind(this);
    this.addPDF = this.addPDF.bind(this);
    this.deleteDraft = this.deleteDraft.bind(this);
    this.renderAddMaterial = this.renderAddMaterial.bind(this);
    this.renderDraftMaterial = this.renderDraftMaterial.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onHideD = this.onHideD.bind(this);
    this.saveMaterial = this.saveMaterial.bind(this);
    this.onDocumentLoadSuccessEachPDF = this.onDocumentLoadSuccessEachPDF.bind(this);
    this.onChangePageLeftEachPDF = this.onChangePageLeftEachPDF.bind(this);
    this.onChangePageRightEachPDF = this.onChangePageRightEachPDF.bind(this);
    this.renderVideoPreview = this.renderVideoPreview.bind(this);
    this.updateLesson = this.updateLesson.bind(this);
    this.onChangeMDEOficial = this.onChangeMDEOficial.bind(this);
    this.onChangeVisible = this.onChangeVisible.bind(this);
    this.onChangeVisibleReturn = this.onChangeVisibleReturn.bind(this);
    this.updateMaterialMD = this.updateMaterialMD.bind(this);
    this.changePage = this.changePage.bind(this);

  }

  componentDidMount(){
    this.getMaterials();
  }

  onClick(name, materialSelectDelete) {
    let state = {
      [`${name}`]: true
    };

    if (materialSelectDelete) {
      state = {
          ...state,
          materialSelectDelete
      }
    }

    this.setState(state);
  }

  onHideD(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  changePage(dataCourse) {
    this.props.updatedCourse(dataCourse)
    this.setState({redirect: true})
  }

  getMaterials = async () =>{
    try {
      await axios.get(`${url}/api/auth/materials/${this.props.dataLesson.id}`)
      .then(res => {
        console.log(res.data);
        const { materials } = this.state;
        const arrayNumPages = [];
        if(materials){
          materials.forEach(material => {
            if(material.type === 'pdf'){
              arrayNumPages.push({id: material.id, numPages: material.numPages});
            }
          });
        };

        const newMaterials = res.data;
        newMaterials.forEach(material => {
          arrayNumPages.forEach(data => {
            if(data.id === material.id){
              material.numPages = data.numPages;
            };
          });
        });

        this.setState({ materials: newMaterials })
      })
      }catch(error){
    }
  }

  addMarkdown(){
    this.setState({draftMaterial: {
      type: "markdown",
      value: "",
      fk_id_lesson: this.props.dataLesson.id
    },
    dialogOption: false})
  }

  addVideo(){
    this.setState({draftMaterial: {
      type: "video",
      value: "",
      urlLocal: "",
      fk_id_lesson: this.props.dataLesson.id
    },
    dialogOption: false})
  }

  addPDF(){
    this.setState({draftMaterial: {
      type: "pdf",
      value: "",
      urlLocal: "",
      numPages: 1,
      pageNumber: 1,
      fk_id_lesson: this.props.dataLesson.id
    },
    dialogOption: false})
  }
  
  deleteDraft(name){
    this.onChangeVisibleReturn(this.state.materialSelect, this.state.originalValueMaterial)
    this.setState({draftMaterial: null})
    this.onHideD(name)
  }

  delete(name){
    this.delete_material()
    this.setState({materialSelectDelete: false})
    this.onHideD(name)
  }

  onHide(){
    this.setState({
      dialogOption: false
    })
  }
  
  onOpen(){
    this.setState({
      dialogOption: true
    })
  }

  onChangeMDE(value){
    this.setState({draftMaterial: {...this.state.draftMaterial, value: value.htmlValue}})
  }

  onChangeMDEOficial(id, value, first_value){
    let newArray = []
    let valueOriginal = this.state.originalValueMaterial
    for (const material of this.state.materials) {
      if(material.idList === id && this.state.materialSelect !== false){
        if(!valueOriginal){
          valueOriginal = first_value
        }
        material.textMD = value.htmlValue
      }
      newArray.push(material)
    }
    this.setState({materials: newArray, originalValueMaterial: valueOriginal})
  }

  onChangeVisible(id){
    let newArray = []

    for (const material of this.state.materials) {
      if(material.idList === id){
        material.visibleEdit = "block"
        material.visibleOficial = "none"
      }
      newArray.push(material)
    }
    this.setState({materials: newArray, materialSelect: id})
  }

  onChangeVisibleReturn(id, valueOriginal = null){
    let newArray = []

    for (const material of this.state.materials) {
      if(material.idList === id){
        material.visibleEdit = "none"
        material.visibleOficial = "block"
        if(valueOriginal){
          material.textMD = valueOriginal
        }
      }
      newArray.push(material)
    }
    this.setState({materials: newArray, materialSelect: false, originalValueMaterial: null})
  }

  onChangeFile(e) {
    this.setState({draftMaterial: {...this.state.draftMaterial, value: e.target.files[0], urlLocal: URL.createObjectURL(e.target.files[0])}})
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({draftMaterial: {...this.state.draftMaterial, numPages}});
  }

  onChangePageLeft(){
    if(this.state.draftMaterial.pageNumber !== 1){
      this.setState({draftMaterial: {...this.state.draftMaterial, pageNumber: this.state.draftMaterial.pageNumber-1}})
    }
  }

  onChangePageRight(){
    if(this.state.draftMaterial.numPages !== this.state.draftMaterial.pageNumber){
      this.setState({draftMaterial: {...this.state.draftMaterial, pageNumber: this.state.draftMaterial.pageNumber+1}})
    }
  }

  onDocumentLoadSuccessEachPDF( data, materialID ) {
    let newArray = this.state.materials
    newArray[materialID] = {
      ...newArray[materialID],
      numPages: data.numPages,
      pageNumber: 1
    }
    this.setState({ materials: newArray });
  }

  onChangePageLeftEachPDF(materialID){
    let newArray = this.state.materials
    newArray[materialID] = {
      ...newArray[materialID],
      pageNumber: newArray[materialID].pageNumber-1
    }
    if(this.state.materials[materialID].pageNumber >= 0){
      this.setState({ materials: newArray });
    }
  }

  onChangePageRightEachPDF(materialID){
    let newArray = this.state.materials
    newArray[materialID] = {
      ...newArray[materialID],
      pageNumber: newArray[materialID].pageNumber+1
    }
    if(this.state.materials[materialID].numPages >= this.state.materials[materialID].pageNumber){
      this.setState({ materials: newArray });
    }
  }

  async saveMaterial(){
    document.getElementById("deleteMaterial").disabled=true;
    document.getElementById("SaveMaterial").disabled=true;
    switch(this.state.draftMaterial.type){
      case "markdown":
        try {

          const options = {
            onUploadProgress: (ProgressEvent) => {
              const { loaded, total } = ProgressEvent;
              let percent = Math.floor( loaded * 100 / total );
              // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

              if( percent < 100){
                this.setState({ uploadPercentage: percent })
              }
            }
          }
          
          await axios.post(`${url}/api/auth/materialmd`,this.state.draftMaterial, options)
          .then(res =>{
            this.setState({ uploadPercentage: 100}, ()=>{
              setTimeout(() => {
                this.setState({ uploadPercentage: 0 })
              }, 1000);
            })
            this.getMaterials()  
            this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LESSON.UPDATED_LESSON'}), life: 3000 });
          }).catch(error => {
            console.log("Sucedio un error", error)
            this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SERVER_ERROR'}), life: 3000 });
          })
        }catch(error){
          this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONNECTION_ERROR'}), life: 3000 });
        }
        break;
      case "video":
        try {

          console.log("Guardando video...")
          const formData = new FormData();
          formData.append('type',this.state.draftMaterial.type);
          formData.append('fk_id_lesson',this.state.draftMaterial.fk_id_lesson);
          formData.append('file',this.state.draftMaterial.value);

          let config = {
            'Content-Type': 'multipart/form-data',
            onUploadProgress: (ProgressEvent) => {
              const { loaded, total } = ProgressEvent;
              let percent = Math.floor( loaded * 100 / total );
              // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

              if( percent < 100){
                this.setState({ uploadPercentage: percent })
              }
            }
          }
          await axios.post(`${url}/api/auth/material`, formData, config)
          .then(res =>{
            this.setState({ uploadPercentage: 100}, ()=>{
              setTimeout(() => {
                this.setState({ uploadPercentage: 0 })
              }, 1000);
            })

            const message_type = res.data.message_type
            const message = res.data.message
            let type = null;

            if(message_type === "success"){
              type = "GENERAL.SUCCESSFUL"
            }else{
              type = "GENERAL_MESSAGE.ERROR"
            }

            this.getMaterials()  
            this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 3000 });
          }).catch(error => {
            this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SERVER_ERROR'}), life: 3000 });
            console.log("Sucedio un error", error)
          })
        }catch(error){
          this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONNECTION_ERROR'}), life: 3000 });
          console.log("Error")
        }
        break;
      case "pdf":
        try {

          const formData = new FormData();
          formData.append('type',this.state.draftMaterial.type);
          formData.append('fk_id_lesson',this.state.draftMaterial.fk_id_lesson);
          formData.append('file',this.state.draftMaterial.value);

          let config = {
            'Content-Type': 'multipart/form-data',
            onUploadProgress: (ProgressEvent) => {
              const { loaded, total } = ProgressEvent;
              let percent = Math.floor( loaded * 100 / total );
              // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

              if( percent < 100){
                this.setState({ uploadPercentage: percent })
              }
            }
          }

          await axios.post(`${url}/api/auth/material`, formData, config)
          .then(res =>{
            this.setState({ uploadPercentage: 100}, ()=>{
              setTimeout(() => {
                this.setState({ uploadPercentage: 0 })
              }, 1000);
            })

            const message_type = res.data.message_type
            const message = res.data.message
            let type = null;

            if(message_type === "success"){
              type = "GENERAL.SUCCESSFUL"
            }else{
              type = "GENERAL_MESSAGE.ERROR"
            }

            this.getMaterials()  
            this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 3000 });
          }).catch(error => {
            console.log("Sucedio un error", error)
            this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SERVER_ERROR'}), life: 3000 });
          })
        }catch(error){
          this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONNECTION_ERROR'}), life: 3000 });
        }
        break;
      default:
        console.log("No se pudo subir el archivo")
    }
    this.setState({draftMaterial: null})
  }

  async updateLesson(){
    let data = {
      fk_id_course: this.props.dataLesson.fk_id_course,
      name: this.state.title,
      description: this.state.description
    }
    try {
      await axios.put(`${url}/api/auth/lesson/${this.props.dataLesson.id}`, data)
      .then(res => {     
        this.setState({titleEdit: false, descriptionEdit: false})
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE.LESSON_UPDATED'}), life: 3000 });
      });
      }catch(error){
    }
  }

  async updateMaterialMD(){
    document.getElementById(`update-${this.state.materials[this.state.materialSelect].id}`).disabled=true;
    let data = {
      value: this.state.materials[this.state.materialSelect].textMD
    }
    let id
    if(this.state.materialSelect !== false){
      id = this.state.materialSelect
    }else{
      id = 0
    }
    try {
      await axios.put(`${url}/api/auth/materialmd/${this.state.materials[id].id}`, data)
      .then(res => {
        document.getElementById(`update-${this.state.materials[this.state.materialSelect].id}`).disabled=false;
        this.onChangeVisibleReturn(this.state.materialSelect)
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONNECTION_ERROR'}), life: 3000 });
      });
      }catch(error){
    }
  }

  async delete_material(){
    let id
    if(this.state.materialSelectDelete !== false){
      id = this.state.materialSelectDelete
    }else{
      id = 0
    }
    try {
      await axios.delete(`${url}/api/auth/material/${this.state.materials[id].id}`)
      .then(res => {
        this.getMaterials()
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LESSON.REMOVED_MATERIAL'}), life: 3000 });
      });
      }catch(error){
    }
  }

  renderPagesControls(){
    let classNameContainer = "d-flex justify-content-center invisible"
    let classNameBack = "d-inline p-2 m-4 bg-dark text-white invisible"
    let classNameNext = "d-inline p-2 m-4 bg-dark text-white"
    if (this.state.draftMaterial.value) {
      classNameContainer = "d-flex justify-content-center"
    }
    if (this.state.draftMaterial.pageNumber !== 1){
      classNameBack = "d-inline p-2 m-4 bg-dark text-white"
    }
    if (this.state.draftMaterial.pageNumber === this.state.draftMaterial.numPages){
      classNameNext = "d-inline p-2 m-4 bg-dark text-white invisible"
    }
    return (
    <div className={classNameContainer}>
      <button className={classNameBack} onClick={this.onChangePageLeft}><FormattedMessage id="GENERAL.BACK"/></button>
      <p style={{margin: "auto 0"}}><FormattedMessage id="GENERAL.PAGE"/> {this.state.draftMaterial.pageNumber} <FormattedMessage id="GENERAL.OF"/> {this.state.draftMaterial.numPages} </p>
      <button className={classNameNext} onClick={this.onChangePageRight}><FormattedMessage id="GENERAL.NEXT"/></button>
    </div>)
  }

  renderPagesControlsPreview(material){
    let classNameBack = "d-inline p-2 m-4 bg-dark text-white invisible"
    let classNameNext = "d-inline p-2 m-4 bg-dark text-white"
    if (this.state.materials[material.idList].pageNumber !== 1){
      classNameBack = "d-inline p-2 m-4 bg-dark text-white"
    }
    if (this.state.materials[material.idList].pageNumber === this.state.materials[material.idList].numPages){
      classNameNext = "d-inline p-2 m-4 bg-dark text-white invisible"
    }
    return (
    <div className="d-flex justify-content-center">
      <button className={classNameBack} onClick={() => this.onChangePageLeftEachPDF(material.idList)}><FormattedMessage id="GENERAL.BACK"/></button>
      <p style={{margin: "auto 0"}}><FormattedMessage id="GENERAL.PAGE"/> {this.state.materials[material.idList].pageNumber} <FormattedMessage id="GENERAL.OF"/> {this.state.materials[material.idList].numPages} </p>
      <button className={classNameNext} onClick={() => this.onChangePageRightEachPDF(material.idList)}><FormattedMessage id="GENERAL.NEXT"/></button>
    </div>)
  }

  renderMarkdownEditor(material = null) {
    let View
    if(!material){
      View = (
        <div>
          <div className="ql-editor">
            <MDEditor.Markdown source={this.state.draftMaterial.value} />
          </div>
          <div className="mt-8">
            <Editor style={{ height: '320px' }} value={this.state.draftMaterial.value} onTextChange={(e)=>this.onChangeMDE(e)} />
          </div>
        </div>
      )
    }else{
      const first_value = this.state.materials[material.idList].textMD
      View = (
        <div style={{display: material.visibleEdit}}>
          <div className="ql-editor">
            <MDEditor.Markdown source={this.state.materials[material.idList].textMD} />
          </div>
          <div className="mt-8">
            <Editor style={{ height: '320px' }} value={this.state.materials[material.idList].textMD} onTextChange={(e)=>this.onChangeMDEOficial(material.idList, e, first_value)} />
          </div>
          <div className="d-flex m-4 justify-content-between">
            <button className="btn btn-danger" onClick={() => this.onClick('displayConfirmation')}><FormattedMessage id="GENERAL.DISCARD_CHANGES"/></button>
            <button id={`update-${material.id}`} className="btn btn-primary" onClick={this.updateMaterialMD}><FormattedMessage id="GENERAL.SAVE"/></button>
          </div>
        </div>
      )
    }
    
    return View
  }
    

  renderMarkdownPreview(material) {
    return (
      <div className="mb-5 ql-editor" style={{display: material.visibleOficial}}>
        <MDEditor.Markdown source={material.textMD} />
      </div>
    )
  }

  renderVideo(material = null) {
    return (
      <div>
        <label className="mt-8" htmlFor="file"><FormattedMessage id="LEARNING_COURSE.CHOOSE_FILE"/></label>
        <input type="file" name="file" accept="video/mp4,video/avi,video/mkv" className="form-control-file" onChange={(e) => this.onChangeFile(e)}/>
        <div className="mt-4" style={{width:"80%", marginLeft:"auto", marginRight:"auto"}}>
          <Player
            playsInline
            src={this.state.draftMaterial.urlLocal}
            />
        </div>
      </div>
    )
  }

  renderVideoPreview(material) {
    return (
      <div key={material.idList}>
        <div className="mt-4 mb-4" style={{width:"80%", marginLeft:"auto", marginRight:"auto"}}>
          <Player
            playsInline
            src={material.url}
            />
        </div>
      </div>
    )
  }

  renderPDF(material = null) {
    return (
      <div>
        <label className="mt-8" htmlFor="file"><FormattedMessage id="LEARNING_COURSE.CHOOSE_PDF"/></label>
        <input type="file" name="file" accept="application/pdf" className="form-control-file" onChange={(e) => this.onChangeFile(e)}/>
        <div className="mt-4" style={{textAlign: "-webkit-center"}}>
          <Document
            file={this.state.draftMaterial.urlLocal}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <div style={{width:"50%"}}>
              <Page pageNumber={this.state.draftMaterial.pageNumber} className="border"/>
            </div>
          </Document>
          {this.renderPagesControls()}
        </div>
      </div>
    )
  }

  renderPDFPreview(material) {
    return (
      <div key={material.idList}>
        <div className="mt-4" style={{textAlign: "-webkit-center"}}>
          <Document
            file={material.url}
            onLoadSuccess={(data) => this.onDocumentLoadSuccessEachPDF(data, material.idList)}
          >
            <div style={{width:"50%"}}>
              <Page pageNumber={this.state.materials[material.idList].pageNumber} className="border"/>
            </div>
          </Document>
          {this.renderPagesControlsPreview(material)}
        </div>
      </div>
    )
  }

  renderMaterial(material) {
    let materialView
    let editMaterial
    switch (material.type) {
      case "markdown":
        materialView = this.renderMarkdownPreview(material);
        editMaterial = this.renderMarkdownEditor(material);
        break;
      case "video":
        materialView = this.renderVideoPreview(material);
        editMaterial = (<></>)
        break;
      case "pdf":
        materialView = this.renderPDFPreview(material);
        editMaterial = (<></>)
        break;
      default:
        console.log("No se puede identificar que tipo de material es")
        materialView = (<></>)
        editMaterial = (<></>)
    }

    let options = (
        this.props.dataLesson.dataCourse.published === 0 || this.props.dataLesson.dataCourse.published === 1 ?
          <div style={{display: material.visibleOficial, textAlignLast:"end"}}>
            {
              material.type === "markdown" ?
              <Button icon="pi pi-pencil" className="p-button-secondary" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={e => this.onChangeVisible(material.idList)}></Button>
              :
              <></>
            }
            <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onClick('displayConfirmationDelete', material.idList)} />
          </div>
        :
        <></>
    )

    if(this.state.materialSelect !== false){
      if(this.state.materialSelect !== material.idList){
        options = (<></>)
      }
    }


    return (
      <div key={material.idList}>
        {options}
        {materialView}
        {editMaterial}
      </div>
    )
  }

  renderDraftMaterial(material) {
    let draft;
    if (material){
      switch (material.type) {
        case "markdown":
          draft = this.renderMarkdownEditor();
          break;
        case "video":
          draft = this.renderVideo();
          break;
        case "pdf":
          draft = this.renderPDF();
          break;
        default:
          draft = (<></>)
          break;
      }
      return(
        <>
          {draft}
          {
            this.state.uploadPercentage === 0 && (
              <div className="d-flex m-4 justify-content-between">
                <button id="deleteMaterial" className="btn btn-danger" onClick={() => this.onClick('displayConfirmation')}><FormattedMessage id="GENERAL.DELETE"/></button>
                <button id="SaveMaterial" className="btn btn-primary" onClick={this.saveMaterial}><FormattedMessage id="GENERAL.SAVE"/></button>
              </div>
            )
          }
          <Dialog header="Confirmation" visible={this.state.displayConfirmationDraft} modal style={{ width: '350px' }} footer={this.renderFooter('displayConfirmationDraft')} onHide={() => this.onHideD('displayConfirmationDraft')}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                <span><FormattedMessage id="LEARNING_COURSE_LESSON.DETELE_MATERIAL"/></span>
            </div>
          </Dialog>
        </>
      )
    }
    return (<></>)
  }

  renderTitle(){

    let titleView

    if (this.state.titleEdit){
      titleView = (
        <div className="mb-5" style={{fontSize:34}}>
          <InputText style={{width:"100%", fontSize:"38px", fontWeight:400, border:"none", padding:0}} className="p-inputtext-lg p-d-block" id="title" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} autoFocus/>
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.SAVE_CHANGES'})} style={{verticalAlign: 'middle',   marginTop: "10px"}} onClick={this.updateLesson}></Button>
        </div>
      )
    }else if(this.props.dataLesson.dataCourse.published === 0 || this.props.dataLesson.dataCourse.published === 1){
      titleView = (
        <div className="mb-5" style={{fontSize:34}}>
          {this.state.title} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({titleEdit: true})}></Button>
        </div>
      )
    }else{
      titleView = (
        <div className="mb-5" style={{fontSize:34}}>
          {this.state.title}
        </div>
      )
    }

    return titleView
  }

  renderDescription(){

    let description

    if (this.state.descriptionEdit){
      description = (
        <div className="p-2" style={{marginTop: 15}}>
          <InputText style={{fontSize:"14px", width:"100%", border:"none", padding:0}} className="p-inputtext-lg p-d-block" id="description" value={this.state.description} onChange={(e) => this.setState({description: e.target.value})} autoFocus/>
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.SAVE_CHANGES'})} style={{verticalAlign: 'middle',   marginTop: "10px"}} onClick={this.updateLesson}></Button>
        </div>
      )
    }else{
      description = (
        <div className="p-2 d-flex">
          <div style={{margin: "auto 0", maxWidth:"96%"}}>
            <span>{this.state.description}</span> 
          </div>
          {
            this.props.dataLesson.dataCourse.published === 0 || this.props.dataLesson.dataCourse.published === 1 ?
            <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({descriptionEdit: true})}></Button>
            :
            <></>
          }
        </div>
      )
    }

    return description
  }

  renderAddMaterial() {

    let boton = (
      <div className="border border-secondary">
        <div className="p-10" style={{textAlign: "center"}}>
          <button className="btn btn-primary btn-lg" onClick={this.onOpen}><FormattedMessage id="LEARNING_COURSE_LESSON.ADD_MATERIAL"/> <AddIcon/></button>
        </div>
      </div>
    )
    let decision
    if(this.state.materialSelect === 0){
      decision = true
    }else if(this.state.materialSelect){
      decision = true
    }else{
      decision = false
    }

    if (this.state.draftMaterial || decision){
      boton = (<></>)
    }

    return boton
  }

  renderAddEvaluation() {

    let boton 
    if(this.props.dataLesson.dataCourse.published !==0 && this.props.dataLesson.dataCourse.published !== 1){
      boton = (
        <div className="pt-10" style={{textAlign: "end"}}>
          <Link className="menu-link" to={{
                pathname:"/get-tutorials/course/lesson/evaluation",
                aboutProps:{
                    data: this.props.dataLesson
                }
            }}>
            <button className="btn btn-warning"><FormattedMessage id="LEARNING_COURSE_LESSON.SEE_ASSESSMENT"/></button>
          </Link>
        </div>
      )
    }else{
      if(this.props.dataLesson.lessonWithEvaluation){
        boton = (
          <div className="pt-10" style={{textAlign: "end"}}>
            <Link className="menu-link" to={{
                  pathname:"/get-tutorials/course/lesson/evaluation",
                  aboutProps:{
                      data: this.props.dataLesson
                  }
              }}>
              <button className="btn btn-warning"><FormattedMessage id="LEARNING_COURSE_LESSON.EDIT_ASSESSMENT"/></button>
            </Link>
          </div>
        )
      }else{
        boton = (
          <div className="pt-10" style={{textAlign: "end"}}>
            <Link className="menu-link" to={{
                  pathname:"/get-tutorials/course/lesson/evaluation",
                  aboutProps:{
                      data: this.props.dataLesson
                  }
              }}>
              <button className="btn btn-warning"><FormattedMessage id="LEARNING_COURSE_LESSON.ADD_ASSESSMENT"/></button>
            </Link>
          </div>
        )
      }
    }
    let decision
    if(this.state.materialSelect === 0){
      decision = true
    }else if(this.state.materialSelect){
      decision = true
    }else{
      decision = false
    }

    if (this.state.draftMaterial || decision){
      boton = (<></>)
    }

    return boton
  }

  renderFooter(name) {
    return (
      <div>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={() => this.onHideD(name)} className="p-button-text" autoFocus/>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={() => this.deleteDraft(name)} />
      </div>
    );
  }

  renderFooterDelete(name) {
    return (
      <div>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={() => this.onHideD(name)} className="p-button-text" autoFocus/>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={() => this.delete(name)} />
      </div>
    );
  }

  render() {
    const {uploadPercentage} = this.state;
    const dataBack = this.props.dataLesson.dataCourse

    if(this.state.redirect) {
      return <Redirect to={{
        pathname: "/get-tutorials/course",
      }}/>;
    }

    return (
      <div>
        <button className="btn" onClick={e => this.changePage(dataBack)}><KeyboardBackspaceSharpIcon/></button>
        <div className="card p-6">
          <Toast ref={(el) => this.toast = el} />
          {
            this.renderTitle()
          }
          <div>
            <FormattedMessage id="GENERAL.DESCRIPTION"/>:
            {
              this.renderDescription()
            }
          </div>
          <hr className="pb-5"/>
          <Dialog header={this.props.intl.formatMessage({id: 'LEARNING_COURSE_LESSON.SELECT_TYPE_MATERIAL'})} visible={this.state.dialogOption} style={{ width: '20vw'}} onHide={this.onHide}>
            <div className="p-grid p-dir-col">
              <div className="p-col align-self-center">
                <Button style={{width:"15em"}} label={this.props.intl.formatMessage({id: 'LEARNING_COURSE_LESSON.TEXT_IMAGES'})} className="p-button-outlined btn-lg" onClick={this.addMarkdown}/>
              </div>
              <div className="p-col align-self-center">
                <Button style={{width:"15em"}} label={this.props.intl.formatMessage({id: 'LEARNING_COURSE_LESSON.VIDEO'})} className="p-button-outlined btn-lg" onClick={this.addVideo}/>
              </div>
              <div className="p-col align-self-center">
                <Button style={{width:"15em"}} label="PDF" className="p-button-outlined btn-lg" onClick={this.addPDF}/>
              </div>
            </div>
          </Dialog>
          <Dialog header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONFIRM'})} visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter('displayConfirmation')} onHide={() => this.onHideD('displayConfirmation')}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                <span><FormattedMessage id="LEARNING_COURSE_LESSON.DETELE_CHANGES"/></span>
            </div>
          </Dialog>
          <Dialog header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONFIRM'})} visible={this.state.displayConfirmationDelete} modal style={{ width: '350px' }} footer={this.renderFooterDelete('displayConfirmationDelete')} onHide={() => this.onHideD('displayConfirmationDelete')}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                <span><FormattedMessage id="LEARNING_COURSE_LESSON.DETELE_MATERIAL"/></span>
            </div>
          </Dialog>
          {
            this.state.materials ?
            this.state.materials.map((material) => this.renderMaterial(material))
            :
            <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
          }
          {this.renderDraftMaterial(this.state.draftMaterial)}
          {
            uploadPercentage > 0 && <ProgressBar className="mb-5" value={uploadPercentage}/>
          }
          {
            this.state.materials && (this.props.dataLesson.dataCourse.published === 0 || this.props.dataLesson.dataCourse.published === 1) ?
            this.renderAddMaterial()
            :
            <></>
          }
          {
            this.state.materials && (this.props.dataLesson.withEvaluation || (this.props.dataLesson.dataCourse.published === 0 || this.props.dataLesson.dataCourse.published === 1)) ?
            this.state.materials.length ? this.renderAddEvaluation() : <></>
            :
            <></>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  dataLesson: state.auth.dataLesson
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(Lesson)
)