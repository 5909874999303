import React, { Component } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Link }  from "react-router-dom";
import { Redirect }  from "react-router-dom";
import "primeflex/primeflex.css";

import axios from 'axios'
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL'
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

const url = ServerURL();

class ListCoursesEvaluations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      layout: 'grid',
      secondLayout: 'grid',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      coursesWithEvaluations: null,
      coursesWithoutEvaluations: null,
      types: null,
      resetType: "Type",
      selectedType: null,
      dialog: false,
      btnDisable: false,
      title: "",
      displayConfirmation: false,
      idCourseSelect: false
    };

    this.sortOptions = [
      {label: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.COURSES_PUBLISHED'}), value: '!published'},
      {label: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.DRAFTS'}), value: 'published'},
    ];

    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.itemSecondTemplate = this.itemSecondTemplate.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onHideC = this.onHideC.bind(this);

    this.id_director = 57;
  }

  componentDidMount() {
    this.getCourses()
    this.getProgramsTypes()
  }

  onClick(name, idCourseSelect) {
    let state = {
      [`${name}`]: true,
      idCourseSelect
    };

    this.setState(state);
  }

  onHide(){
    this.setState({
      dialog: false,
      selectedType: "Type"
    })
  }

  onHideC(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  onOpen(){
    this.setState({
      dialog: true
    })
  }

  changePage(dataEvaluation) {
    this.props.updatedEvaluation(dataEvaluation)
    this.setState({redirect: true})
  }

  getCourses = async () =>{
    const data = {
      enterprise_id: this.props.user.fk_id_enterprise,
      fullname: this.props.user.fullname
    }
    try {
      if(this.props.user.fk_id_role === this.id_director || this.props.user.owner){
        await axios.get(`${url}/api/auth/courses_all_evaluations/${data.enterprise_id}`)
        .then(res => {
          const newArrayCoursesWithEvaluations = []
          const newArrayCoursesWithoutEvaluations = []
          
          const arrayCourses = res.data
          arrayCourses.forEach(course => {
            if(course.hasEvaluations){
              newArrayCoursesWithEvaluations.push(course)
            }else{
              newArrayCoursesWithoutEvaluations.push(course)
            }
          })
          console.log(arrayCourses)
          this.setState({ coursesWithEvaluations: newArrayCoursesWithEvaluations, coursesWithoutEvaluations: newArrayCoursesWithoutEvaluations})
        });
      }else{
        await axios.post(`${url}/api/auth/courses_evaluations`, data)
        .then(res => {
          const newArrayCoursesWithEvaluations = []
          const newArrayCoursesWithoutEvaluations = []
          
          const arrayCourses = res.data
          arrayCourses.forEach(course => {
            if(course.hasEvaluations){
              newArrayCoursesWithEvaluations.push(course)
            }else{
              newArrayCoursesWithoutEvaluations.push(course)
            }
          })
          this.setState({ coursesWithEvaluations: newArrayCoursesWithEvaluations, coursesWithoutEvaluations: newArrayCoursesWithoutEvaluations})
        });
      }
      }catch(error){
    }
  }

  getProgramsTypes = async () =>{
    try {
      await axios.get(`${url}/api/auth/program_types`)
      .then(res => {     
        this.setState({ types: res.data })
      });
      }catch(error){
    }
  }

  deleteCourse = async () =>{
    console.log(this.state.idCourseSelect)
    try {
      await axios.delete(`${url}/api/auth/course/${this.state.idCourseSelect}`)
      .then(res => {     
        this.setState({ displayConfirmation: false })
        this.getCourses()
      });
      }catch(error){
    }
  }

  onTypeChange(event) {
    this.setState({selectedType: event.target.value})
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value
      });
    }
    else {
      this.setState({
        sortOrder: 1,
        sortField: value,
        sortKey: value
      });
    }
  }

  async saveCourse(){
    let data = {
      fk_id_enterprise: this.props.user.fk_id_enterprise,
      created: this.props.user.fullname,
      modified: this.props.user.fullname,
      fk_id_program_type: this.state.selectedType,
      course: this.state.title
    }
    console.log(data)
    try {
      await axios.post(`${url}/api/auth/course`, data)
      .then(res => {     
        this.setState({ dialog: false })
        this.getCourses()
      });
      }catch(error){
    }
  }

  renderListItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} />
          <div className="course-list-detail">
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
            <span className="course-category"><FormattedMessage id="GENERAL_MESSAGE.LAST_MODIFICATION"/>: {data.updated_at.substring(0,9)}</span>
          </div>
          <div className="course-list-action">
            <span className="course-price">{data.program_type}</span>
            <div style={{display:"flex", justifyContent:"flex-end"}}>
              <Link className="menu-link" to={{
                pathname:"/get-evaluations/evaluations",
                aboutProps:{
                    data
                }
              }}>
                <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}}/>
              </Link>
            </div>
            {
              data.published === 0 ?
              <span className="p-tag course-badge text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
              :
              <span className="p-tag course-badge text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
            }
          </div>
        </div>
      </div>
    );
  }

  renderListSecondItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} />
          <div className="course-list-detail">
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
            <span className="course-category"><FormattedMessage id="GENERAL_MESSAGE.LAST_MODIFICATION"/>: {data.updated_at.substring(0,9)}</span>
          </div>
          <div className="course-list-action">
            <span className="course-price">{data.program_type}</span>
            {
              data.published === 0 ?
              <span className="p-tag course-badge text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
              :
              <span className="p-tag course-badge text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
            }
          </div>
        </div>
      </div>
    );
  }

  renderGridItem(data) {
    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card">
          <div className="course-grid-item-top">
            <div>
              {
                data.published === 0 ?
                <span className="p-tag course-category text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
                :
                <span className="p-tag course-category text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
              }
            </div>
            <span className={`course-badge`}>{data.program_type}</span>
          </div>
          <div className="course-grid-item-content">
            <img src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
          <div className="course-grid-item-bottom">
            <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
          </div>
        </div>
      </div>
    );
  }

  renderGridSecondItem(data) {
    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card">
          <div className="course-grid-item-top">
            <div>
              {
                data.published === 0 ?
                <span className="p-tag course-category text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
                :
                <span className="p-tag course-category text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
              }
            </div>
            <span className={`course-badge`}>{data.program_type}</span>
          </div>
          <div className="course-grid-item-content">
            <img src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
        </div>
      </div>
    );
  }

  itemTemplate(course, layout) {
    if (!course) {
      return (<></>);
    }

    if (layout === 'list')
      return this.renderListItem(course);
    else if (layout === 'grid')
      return this.renderGridItem(course);
  }

  itemSecondTemplate(course, layout) {
    if (!course) {
      return (<></>);
    }

    if (layout === 'list')
      return this.renderListSecondItem(course);
    else if (layout === 'grid')
      return this.renderGridSecondItem(course);
  }

  renderHeaderWEvaluations() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-6" style={{textAlign: 'left', marginBottom: 0}}>
          <h2><FormattedMessage id="LEARNING_GENERAL.COURSES_WITH_ASSESSMENTS"/></h2>
        </div>
        <div className="p-col-6" style={{textAlign: 'right'}}>
          <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value })} />
        </div>
      </div>
    );
  }

  renderHeaderWOEvaluations() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-6" style={{textAlign: 'left', marginBottom: 0}}>
          <h2><FormattedMessage id="LEARNING_GENERAL.COURSES_WITHOUT_ASSESSMENTS"/></h2>
        </div>
        <div className="p-col-6" style={{textAlign: 'right'}}>
          <DataViewLayoutOptions layout={this.state.secondLayout} onChange={(e) => this.setState({ secondLayout: e.value })} />
        </div>
      </div>
    );
  }

  renderFooter(name) {
    return (
      <div>
        <FormattedMessage id="GENERAL.NO">
          {(message) => <Button label={message} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
          {(message) => <Button label={message} icon="pi pi-check" onClick={() => this.deleteCourse(name)} />}
        </FormattedMessage>
      </div>
    );
  }

  render() {
    const headerWE = this.renderHeaderWEvaluations();
    const headerWOE = this.renderHeaderWOEvaluations();

    if(this.state.redirect) {
      return <Redirect to={{
        pathname: "/get-evaluations/evaluations",
      }}/>;
    }

    return (
      <div>
        <div className="dataview">
          <div className="card mb-8">
            {
              this.state.coursesWithEvaluations ?
              <DataView value={this.state.coursesWithEvaluations} layout={this.state.layout} header={headerWE}
                itemTemplate={this.itemTemplate} rows={8}
                emptyMessage={this.props.intl.formatMessage({id: 'LEARNING_GENERAL.WITHOUT_COURSES_WITH_ASSESSMENTS'})}
                paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
              :
              <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
          <div className="card">
            {
              this.state.coursesWithoutEvaluations ?
              <DataView value={this.state.coursesWithoutEvaluations} layout={this.state.secondLayout} header={headerWOE}
                itemTemplate={this.itemSecondTemplate} rows={8}
                emptyMessage={this.props.intl.formatMessage({id: 'LEARNING_GENERAL.WITHOUT_COURSES_WITHOUT_ASSESSMENTS'})}
                paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
              :
              <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(ListCoursesEvaluations)
)