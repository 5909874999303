import React from "react";
import {useSubheader} from "../../_metronic/_core";
import Analytics from "../modulesWiyu/AnalyticsRates/";
import { useIntl } from "react-intl";

export const AnalyticsRatesPage = () => {
    const suhbeader = useSubheader();
    // const int1 = useIntl();

    // suhbeader.setTitle("Assign students");
    return (<><Analytics/></>);
}