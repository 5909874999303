import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../../_metronic/i18n/TextTranslate'

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const url = ServerURL();


class ProgramTypesTable extends Component {
            

  
  emptyProgramType = {
    program_type: '',
    description: '',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    name: '',
    description: '',

     /**
       * Aux messages
       */
      program_typeMessage: '',
      descriptionMessage: '',

    program_type: this.emptyProgramType,
    program_types:null,
    ids:null,
    globalFilter: null,
    selectedProgramTypes: null,
    program_typeDialog: false,
    deleteProgramTypeDialog: false,
    deleteProgramTypesDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveProgramType = this.saveProgramType.bind(this);
  this.deleteProgramType = this.deleteProgramType.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editProgramType = this.editProgramType.bind(this);
  this.confirmDeleteProgramType= this.confirmDeleteProgramType.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedProgramTypes = this.deleteSelectedProgramTypes.bind(this);
  this.hideDeleteProgramTypeDialog = this.hideDeleteProgramTypeDialog.bind(this);
  this.hideDeleteProgramTypesDialog = this.hideDeleteProgramTypesDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  }


    

      componentDidMount() {
        this.getProgramTypes()

      }

      isValid(){
        const { program_type, description } = this.state.program_type
              const program_typeMessage = this.validateProgramType()
              const descriptionMessage = this.validateDescription()
           
        
              if(program_type && description ){
                if(!program_typeMessage && !descriptionMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
      validateProgramType() {
              const program_type= this.state.program_type
              
              let program_typeMessage;
              if (!program_type.program_type) {
                program_typeMessage = 'Requerido';
              } else {
                program_typeMessage = ''
              }
              this.setState({ program_typeMessage })
              return program_typeMessage
            }

       validateDescription() {
              const program_type = this.state.program_type
              
              let descriptionMessage;
              if (!program_type.description) {
                descriptionMessage = 'Requerido';
              } else {
                descriptionMessage = ''
              }
              this.setState({ descriptionMessage })
              return descriptionMessage
            }

            getTextAuxProgramType(){
                    const message = this.state.program_typeMessage
                    return message
                  }
            
            getTextAuxDescription(){
                   const message = this.state.descriptionMessage
                    return message
                  }

      getProgramTypes = async () =>{
         
          try {
              let res = axios.get(`${url}/api/auth/program_types/`)
              .then(res => {
                const program_types = res.data;            
                this.setState({ program_types })
              });                     
                console.log(res.data)
              }catch(error){
         }
      }

      deleteProgramType(){
        let data = {...this.state.program_type};

        try {
        let res = axios.delete(`${url}/api/auth/program_type/${data.id}`)
          .then(res => {
          this.getProgramTypes()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteProgramTypeDialog: false,
            course: this.emptyProgramType
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Program Type Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveProgramType() {
        let state = { submitted: true };

       // if (this.state.program_type.program_type.trim()) {     
        const decision = this.isValid();

        if (decision) {  
          let data = {...this.state.program_type};


        if (this.state.program_type.id) {

            try {
              let res = axios.put(`${url}/api/auth/program_type/${data.id}`,data)
              .then(res =>{
              this.getProgramTypes()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Program Type Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/program_type`,data)
            
             .then(res => {
                 this.getProgramTypes()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Program Type Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          program_typeDialog: false,
          program_type: this.emptyProgramType
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.setState({
            program_type: this.emptyProgramType,
            submitted: false,
            program_typeDialog: true
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let program_type = {...this.state.program_type};
        program_type[`${name}`] = val;

        this.setState({ program_type});
      }



      hideDialog() {
          this.setState({
              submitted: false,
              program_typeDialog: false,
              program_typeMessage: '',
              descriptionMessage: '',
        })  
      } 


      editProgramType(program_type) {
          this.setState({
              program_type: { ...program_type },
              program_typeDialog: true
          })

      }

      confirmDeleteProgramType(program_type) {
        this.setState({
            program_type,
            deleteProgramTypeDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteProgramTypesDialog: true })
      }

      deleteSelectedProgramTypes() {
        let program_types = this.state.program_types.filter(val => !this.state.selectedProgramTypes.includes(val));
        let data = this.state.program_types.filter(val => this.state.selectedProgramTypes.includes(val));

        try {
        let res = axios.delete(`${url}/api/auth/program_types`,{data})
          .then(res => {
          this.getProgramTypes()
        })
        if (!res.error) {
          this.setState({
            program_types,
            deleteProgramTypesDialog: false,
            selectedProgramTypes: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Program Type Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteProgramTypeDialog() {
        this.setState({ deleteProgramTypeDialog: false })
      }
        
      hideDeleteProgramTypesDialog() {
        this.setState({ deleteProgramTypesDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
     

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editProgramType(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteProgramType(rowData)} />
            </>
        )
      }
      
      




      render(){
       const deleteProgramTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteProgramTypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProgramType} />
        </>
       );

       const ProgramTypeDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveProgramType} />}
            </FormattedMessage>
        </>
       );

       const deleteProgramTypesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteProgramTypesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedProgramTypes} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		        <FormattedMessage id="ADMIN_PROGRAM_TYPE.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_PROGRAM_TYPE.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedProgramTypes || !this.state.selectedProgramTypes.length} />}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_PROGRAM_TYPE">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div>

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>

          
          <div>
           {/*SHOW COURSE */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.program_types} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Program type found." loading={this.state.loading}
                        selection={this.state.selectedProgramTypes} onSelectionChange={e => this.setState({ selectedProgramTypes: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="program_type" header={this.state.campusText.ProgramType}  filter filterPlaceholder={this.state.campusText.SearchByProgamType} sortable></Column>
                        <Column field="description" header={this.state.campusText.Description}  filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE CAMPUS */}
          <Dialog visible={this.state.deleteProgramTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProgramTypeDialogFooter} onHide={this.hideDeleteProgramTypeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.program_type && <span> Are you sure you want to delete <b>{this.state.program_type.name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteProgramTypesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProgramTypesDialogFooter} onHide={this.hideDeleteProgramTypesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.program_type && <span>Are you sure you want to delete the selected program types?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE PROGRAM TYPE & CREATE PROGRAM TYPE*/}
          <Dialog visible={this.state.program_typeDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"150px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_PROGRAM_TYPE"/>} modal footer={ProgramTypeDialogFooter} onHide={this.hideDialog}>
          <br></br>
          <div className="form-group row">
              {/* Progrm Type */}
              <div className="col-lg-6">  
              <span className="p-float-label">         
                <InputText
                  style={{ width: 250, height:33 }}
                  id="program_type"
                  variant="outlined"
                  type="program_type"
                  margin="dense"
                  value={this.state.program_type.program_type}             
                  onChange={(e) => this.onInputChange(e, 'program_type')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.program_type.program_type  })}
                  />
              <label htmlFor="program_type">Program Type</label>        
              </span>
              <small id="program_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxProgramType(this.state)}</small>
              </div>
                  {/* Description*/}
                  <div className="col-lg-6">
                  <span className="p-float-label">
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="description"
                  variant="outlined"
                  margin="dense"
                  value={this.state.program_type.description}             
                  onChange={(e) => this.onInputChange(e, 'description')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.program_type.description  })}
                  />
              <label htmlFor="description">Description</label>    
              </span>    
              <small id="role_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription(this.state)}</small>
              </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(ProgramTypesTable);