import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import { Dialog } from 'primereact/dialog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { InputText } from 'primereact/inputtext';
import { Link, Redirect }  from "react-router-dom";
import "primeflex/primeflex.css";

import axios from 'axios'
import * as auth from "../Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL'
import SVG from "react-inlinesvg";
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { ProgressBar } from 'primereact/progressbar';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { FormattedMessage, injectIntl } from 'react-intl';

const url = ServerURL();

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "white" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
});

class Course extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lessons: null,
      documents: null,
      documents_show: [],
      layout: 'grid',
      name: "",
      displayConfirmation: false,
      displayConfirmPublication: false,
      idLessonSelect: null,
      titleEdit: null,
      descriptionEdit: null,
      title: this.props.dataCourse.course,
      description: this.props.dataCourse.description,
      imgOficial: this.props.dataCourse.image,
      imgPreview: this.props.dataCourse.image,
      imgFile: null,
      isPublished: this.props.dataCourse.published,
      withEvaluation: null,
      redirect: false,
      section_1: true,
      searchText: "",
      uploadPercentage: 0
    };

    this.onHide = this.onHide.bind(this);
    this.onHideNewDoc = this.onHideNewDoc.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClickConfirmation = this.onClickConfirmation.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.saveLesson = this.saveLesson.bind(this);
    this.savePosition = this.savePosition.bind(this);
    this.saveDoc = this.saveDoc.bind(this);
    this.updateCourse = this.updateCourse.bind(this);
    this.updateCourseImage = this.updateCourseImage.bind(this);
    this.updateCoursePublished = this.updateCoursePublished.bind(this);
    this.renderImageOption = this.renderImageOption.bind(this);
    this.renderDescription = this.renderDescription.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.changePage = this.changePage.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);

  }

  componentDidMount() {
      this.getLessons()
      this.getDocuments()
  }

  changeDate(e){                 
    e.map((file) => {
    const date = new Date(file.updated_at);

     let year = date.getFullYear();
     let month = date.getMonth()+1;
     let dt = date.getDate();

     if (dt < 10) {
       dt = '0' + dt;
     }
     if (month < 10) {
       month = '0' + month;
     }

     let new_date = dt +'/' + month + '/'+year
    //  console.log("New Data:",new_date)

     file.updated_at = new_date
     return file
   });
  }

  onHide(){
    this.setState({
      dialog: false,
      selectedType: "Type"
    })
  }

  onHideNewDoc(){
    if(this.state.uploadPercentage === 0){
      this.setState({
        dialogNewDoc: false,
        file: null
      })
    }
  }

  onHideC(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  onOpen(){
    this.setState({
      dialog: true
    })
  }

  onOpen2(name, idDocumentSelect){
    this.setState({[`${name}`]: true, idDocumentSelect});
  }


  onClick(name, idLessonSelect) {
    let state = {
      [`${name}`]: true,
      idLessonSelect
    };

    this.setState(state);
  }

  onClickConfirmation(){
    this.setState({
      displayConfirmPublication: true
    })
  }

  onChangeSearch(searchText){
    let documents = this.state.documents;
    const secondText = searchText.toLowerCase();
    const newDocumentsShow = []

    documents.forEach(document => {
      const foundNameDocument = document.doc_name.indexOf(searchText);
      const foundNameType = document.type.indexOf(secondText);
      if(foundNameDocument !== -1 || foundNameType !== -1){
        newDocumentsShow.push(document);
      }
    })

    this.setState({ documents_show: newDocumentsShow, searchText })
  }

  changeImage(e){
    this.setState({imgPreview: URL.createObjectURL(e.target.files[0]), imgFile: e.target.files[0]})
  }

  changeFile(e){
    this.setState({file: e.target.files[0]})
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((lesson, index) => {
      lesson.position = index;
    })
    
    return result;
  };

  onDragEnd(result){
    if(!result.destination){
      return;
    }
    if(result.destination.index === result.source.index){
      return;
    }
    const lessons = this.reorder(
      this.state.lessons,
      result.source.index,
      result.destination.index
    );

    this.setState({
      lessons
    });

    this.savePosition(lessons);
  }

  changePage(dataLesson) {
    this.props.updatedLesson(dataLesson)
    this.setState({redirect: true})
  }

  getType(name){
    const arregloAuxiliar = name.split(".");
    let formato;

    if(arregloAuxiliar.length){
      formato = arregloAuxiliar[arregloAuxiliar.length-1];
    }else{
      formato = this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.WITHOUT_FORMAT'});
    }

    return formato;
  }

  newData(data){
    const arregloAuxiliar = data.url.split("/");

    const doc_name = arregloAuxiliar[arregloAuxiliar.length-1];
    let type = this.getType(doc_name);

    return {...data, type, doc_name};
  }

  transformSize(bits){
    let newData;
    let message;
    if(Math.trunc(bits / 1.074e+9) > 0){
      newData = bits / 1.074e+9;
      message = `${newData.toFixed(2)} GB`;
    }else if(Math.trunc(bits / 1.049e+6) > 0){
      newData = bits / 1.049e+6;
      message = `${newData.toFixed(2)} MB`;
    }else{
      newData = bits / 1024;
      message = `${newData.toFixed(2)} KB`;
    }
    return message;
  }

  getDocuments = async () =>{
    try {
      await axios.get(`${url}/api/auth/documents/${this.props.dataCourse.id}`)
      .then(res => {
        console.log("Documents:",res.data);
        const arrayDocuments = Array.from(res.data);
        this.changeDate(arrayDocuments);
        const newArrayDocuments = [];
        arrayDocuments.forEach(document => {
          document = this.newData(document);
          newArrayDocuments.push(document);
        })
        console.log(newArrayDocuments)
        this.setState({ documents: newArrayDocuments, documents_show: newArrayDocuments });
      });
      }catch(error){
        console.log(error)
    }
  }

  getLessons = async () =>{
    try {
      await axios.get(`${url}/api/auth/lessons/${this.props.dataCourse.id}`)
      .then(res => {     
        console.log(res.data);
        let withEvaluation = this.state.withEvaluation;
        const newArrayLessons = Array.from(res.data);
        res.data.forEach(lesson => {
          if(lesson.withEvaluation){
            withEvaluation = true
          };

          this.props.dataCourse.fk_ids_lessons.forEach((id_lesson, index) => {
            if(id_lesson === lesson.id){
              newArrayLessons.splice(index, 1, lesson);
            }
          })

        })
        this.setState({ lessons: newArrayLessons, withEvaluation });
      });
      }catch(error){
    }
  }

  async saveLesson(){
    let data = {
      fk_id_course: this.props.dataCourse.id,
      name: this.state.name,
      position: this.state.lessons.length
    }
    console.log(data)
    try {
      await axios.post(`${url}/api/auth/lesson`, data)
      .then(res => {     
        this.setState({ dialog: false, name: "" })
        this.getLessons()
      });
      }catch(error){
    }
  }

  async saveDoc(){
    let is_original = true;
    this.state.documents.forEach(document => {
      const document_data = this.newData(document);
      if(document_data.doc_name === this.state.file.name){
        is_original = false;
      }
    });

    if(is_original){
      try {
        const formData = new FormData();
        formData.append('size',this.state.file.size);
        formData.append('fk_id_course',this.props.dataCourse.id);
        formData.append('fk_id_user',this.props.user.id);
        formData.append('file',this.state.file);
  
        let config = {
          'Content-Type': 'multipart/form-data',
          onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor( loaded * 100 / total );
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
  
            if( percent < 100){
              this.setState({ uploadPercentage: percent })
            }
          }
        }
  
        await axios.post(`${url}/api/auth/document`, formData, config)
        .then(res =>{
          this.setState({ uploadPercentage: 100}, ()=>{
            setTimeout(() => {
              this.setState({ uploadPercentage: 0, file: null, dialogNewDoc: false })
            }, 1000);
          })
  
          const message_type = res.data.message_type
          const message = res.data.message
          let type = null;

          if(message_type === "success"){
            type = "GENERAL.SUCCESSFUL"
          }else{
            type = "GENERAL_MESSAGE.ERROR"
          }
  
          this.getDocuments()  
          this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 6000 });
        }).catch(error => {
          console.log("Sucedio un error", error)
          this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SERVER_ERROR'}), life: 3000 });
        })
      }catch(error){
        this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONNECTION_ERROR'}), life: 3000 });
      }
    }else{
      this.toast.show({ severity: 'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.ERROR'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.NAME_FILE_ERROR'}), life: 6000 });
    }
  }

  async savePosition(lessons = null) {
    if(!lessons){
      lessons = this.state.lessons;
    }

    lessons.forEach((lesson, index) => {
      lesson.position = index;
      this.props.dataCourse.fk_ids_lessons[index] = lesson.id;
    })
    const data = {
      lessons: this.props.dataCourse.fk_ids_lessons
    }
    try {
      await axios.put(`${url}/api/auth/lessons_position/${this.props.dataCourse.id}`, data)
      .then(res => {
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.UPDATED'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LESSON.UPDATED_ORDER'}), life: 3000 });
      });
      }catch(error){}
  }

  deleteLesson = async () =>{
    const { lessons, idLessonSelect } = this.state;
    try {
      await axios.delete(`${url}/api/auth/lesson/${idLessonSelect}`)
      .then(res => {
        const newLessons = Array.from(lessons);
        let index_selected = null;
        newLessons.forEach((lesson, index) => {
          if(lesson.id === idLessonSelect){
            index_selected = index;
          }
        })
        newLessons.splice(index_selected, 1);
        this.props.dataCourse.fk_ids_lessons.splice(index_selected, 1);
        this.setState({ displayConfirmation: false, lessons: newLessons })
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.UPDATED'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE.REMOVED_LESSON'}), life: 3000 });
        this.savePosition(newLessons)
      });
    }catch(error){}
  }

  deleteDocument = async () =>{
    const { idDocumentSelect } = this.state;
    try {
      await axios.delete(`${url}/api/auth/document/${idDocumentSelect}`)
      .then(res => {
        this.setState({ displayDoc: false })
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.REMOVED'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE.REMOVED_FILE'}), life: 3000 });
        this.getDocuments()
      });
    }catch(error){}
  }

  async updateCourse(){
    let data = {
      fk_id_program_type: this.props.dataCourse.fk_id_program_type,
      name: this.state.title,
      description: this.state.description,
      fk_id_enterprise: this.props.dataCourse.fk_id_enterprise
    }
    console.log(data)
    try {
      await axios.put(`${url}/api/auth/course_tutorial/${this.props.dataCourse.id}`, data)
      .then(res => {     
        this.setState({titleEdit: false, descriptionEdit: false})
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LIST.COURSE_UPDATED'}), life: 3000 });
      });
      }catch(error){
    }
  }

  async updateCoursePublished(){
    // if(this.state.withEvaluation){
      try {
        await axios.put(`${url}/api/auth/course_published_tutorial/${this.props.dataCourse.id}`)
        .then(res => {
          if(this.state.isPublished === 0){
            this.setState({displayConfirmPublication: false, lessons: null, isPublished: 2})
          }else{
            this.setState({displayConfirmPublication: false, lessons: null, isPublished: 3})
          }
          this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE.PUBLISHED_SUCCESS'}), life: 3000 });
          this.getLessons()
        });
      }catch(error){}
    // }else{
    //   this.toast.show({ severity: 'info', summary: 'Curso no publicado', detail: 'El curso debe de contener al menos una evaluación para ser publicado', life: 6000 });
    // }
  }

  async updateCourseImage(){
    this.setState({loadImage: true});
    const formData = new FormData();
    formData.append('file',this.state.imgFile);
    let config = {
      'Content-Type': 'multipart/form-data'
    }
    
    try {
      await axios.put(`${url}/api/auth/course_img/${this.props.dataCourse.id}`, formData, config)
      .then(res => {
        const newImg = this.state.imgPreview
        const originalmg = this.state.imgOficial
        const message_type = res.data.message_type
        const message = res.data.message
        if(message_type === 'success'){
          console.log("IMG subido")
          this.setState({imgOficial: newImg, imgFile: null, loadImage: false})
        }else{
          this.setState({imgPreview: originalmg, imgFile: null, loadImage: false})
        }

        let type = null;
        if(message_type === "success"){
          type = "GENERAL.SUCCESSFUL"
        }else{
          type = "GENERAL_MESSAGE.ERROR"
        }

        this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 6000 });
      });
      }catch(error){
    }
  }

  itemTemplate(data, layout) {
    if (!data) {
      return (<></>);
    }

    if (layout === 'list')
      return this.renderListItem(data);
    else if (layout === 'grid')
      return this.renderGridItem(data);
  }

  renderSVG(type){
      switch (type) {
        case "pdf":
          return <SVG src={toAbsoluteUrl("/media/svg/files/pdf.svg")}/>;
        case "docx":
          return <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}/>;
        case "doc":
          return <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}/>;
        case "jpg":
          return <SVG src={toAbsoluteUrl("/media/svg/files/jpg.svg")}/>;
        case "jpeg":
          return <SVG src={toAbsoluteUrl("/media/svg/files/jpeg.svg")}/>;
        case "png":
          return <SVG src={toAbsoluteUrl("/media/svg/files/png.svg")}/>;
        case "xlsx":
          return <SVG src={toAbsoluteUrl("/media/svg/files/xlsx.svg")}/>;
        case "mp4":
          return <SVG src={toAbsoluteUrl("/media/svg/files/mp4.svg")}/>;
        case "mkv":
          return <SVG src={toAbsoluteUrl("/media/svg/files/mkv.svg")}/>;
        case "zip":
          return <SVG src={toAbsoluteUrl("/media/svg/files/zip.svg")}/>;
        case "rar":
          return <SVG src={toAbsoluteUrl("/media/svg/files/rar.svg")}/>;
        case "txt":
          return <SVG src={toAbsoluteUrl("/media/svg/files/txt.svg")}/>;
        default:
          return <SVG src={toAbsoluteUrl("/media/svg/files/doc.svg")}/>;
      }
  }

  renderGridItem(data) {
    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card">
          <div className="course-grid-item-top text-center align-self-center">
            <h6>{data.doc_name}</h6>
          </div>
          <span className="align-self-center mt-1 mb-3" style={{width:100}}>
            {this.renderSVG(data.type)}
          </span>
          <div className="d-flex flex-column text-center">
            <span><FormattedMessage id="LEARNING_COURSE.UPLOAD_DATE"/>: {data.updated_at}</span>
            <span><FormattedMessage id="GENERAL.SIZE"/>: {this.transformSize(data.size)}</span>
          </div>
          <div className="course-grid-item-bottom">
            <a id={`${data.id}-link`} href={data.url} style={{display:"none"}} target="_blank" rel="noopener noreferrer" download><i></i></a> 
            <Button icon="pi pi-download" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => document.getElementById(`${data.id}-link`).click()}/>
            <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onOpen2('displayDoc', data.id)} />
          </div>
        </div>
      </div>
    );
  }

  renderListItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          {/* <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} /> */}
          <span className="text-center h-auto align-self-center" style={{width:100}}>
            {this.renderSVG(data.type)}
          </span>
          <div className="course-list-detail">
            <h4>{data.doc_name}</h4>
            <span className="course-name text-uppercase d-block">{data.type}</span>
            <div className="d-flex flex-column">
              <span><FormattedMessage id="LEARNING_COURSE.UPLOAD_DATE"/>: {data.updated_at}</span>
              <span><FormattedMessage id="GENERAL.SIZE"/>: {this.transformSize(data.size)}</span>
            </div>
          </div>
          <div className="course-list-action">
            <div style={{alignSelf: "flex-end"}}>
              <a id={`${data.id}-link`} href={data.url} style={{display:"none"}} target="_blank" rel="noopener noreferrer" download><i></i></a> 
              <Button icon="pi pi-download" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => document.getElementById(`${data.id}-link`).click()}/>
              <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onOpen2('displayDoc', data.id)} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLessons(dataLesson, index) {
    const dataCourse = {...this.props.dataCourse, published: this.state.isPublished}
    const data = {...dataLesson, dataCourse, indexLesson: index+1, lessonWithEvaluation: dataLesson.withEvaluation}
    // console.log(data)
    return (
      <div key={index} className="border border-secondary p-grid p-nogutter mb-5">
        <div className="p-col-10 p-5">
          <div style={{fontSize:24}}>
            <FormattedMessage id="LEARNING_COURSE.LESSONS"/> {index+1} - {data.name}
          </div>
          <div className="mb-3" style={{fontSize:14}}>
            <FormattedMessage id="LEARNING_COURSE.DESCRIPTION_LESSON"/>:<br/>
            {data.description}
          </div>
        </div>
        <div className="p-col-2" style={{textAlign: "center", alignSelf: "center"}}>
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.VIEW_CONTENT'})} style={{verticalAlign: 'middle',   margin: "10px"}} onClick={() => this.changePage(data)}/>
          {
            this.state.isPublished === 0 || this.state.isPublished === 1 ?
            <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onClick('displayConfirmation', data.id)} />
            :
            <></>
          }
          {
            data.withEvaluation && <span className="p-tag p-tag-rounded p-tag-warning"><FormattedMessage id="LEARNING_COURSE.INCLUDES_EVALUATION"/></span>
          }
        </div>
      </div>
    )
  }

  renderLessonsModified(dataLesson, index) {
    const dataCourse = {...this.props.dataCourse, published: this.state.isPublished}
    const data = {...dataLesson, dataCourse, indexLesson: index+1, lessonWithEvaluation: dataLesson.withEvaluation}
    return (
      <Draggable key={data.name_unique} className="border border-secondary p-grid p-nogutter mb-5" draggableId={data.name_unique} index={index}>
          {(provided, snapshot) => (
            <div 
              className="border border-secondary p-grid p-nogutter mb-5"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <div className="p-col-10 p-5">
                <div style={{fontSize:24}}>
                  <FormattedMessage id="LEARNING_COURSE.LESSONS"/> {index+1} - {data.name}
                </div>
                <div className="mb-3" style={{fontSize:14}}>
                  <FormattedMessage id="LEARNING_COURSE.DESCRIPTION_LESSON"/>:<br/>
                  {data.description}
                </div>
              </div>
              <div className="p-col-2" style={{textAlign: "center", alignSelf: "center"}}>
                <div>
                  <Button label="Ver contenido" style={{verticalAlign: 'middle',   margin: "10px"}} onClick={() => this.changePage(data)}/>
                  {
                    this.state.isPublished === 0 || this.state.isPublished === 1 ?
                    <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onClick('displayConfirmation', data.id)} />
                    :
                    <></>
                  }
                </div>
                {
                  data.withEvaluation ?
                    <span className="p-tag p-tag-rounded p-tag-warning"><FormattedMessage id="LEARNING_COURSE.INCLUDES_EVALUATION"/></span>
                  :
                    <></>
                }
              </div>
            </div>
          )}
        </Draggable>
    )
  }

  renderFooter(name) {
    return (
      <div>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={() => this.deleteLesson(name)} />
      </div>
    );
  }

  renderFooterConfirmation(name) {
    return (
      <div>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={() => this.updateCoursePublished()} />
      </div>
    );
  }

  renderFooterDocument(name, data) {
    return (
      <div>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={() => this.deleteDocument()} />
      </div>
    );
  }

  renderTitle(){

    let titleView

    if (this.state.titleEdit){
      titleView = (
        <div className="mb-4" style={{fontSize:34}}>
          <InputText style={{width:"100%", fontSize:"38px", fontWeight:400, border:"none", padding:0}} className="p-inputtext-lg p-d-block" id="title" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} autoFocus/>
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.SAVE_CHANGES'})} style={{verticalAlign: 'middle',   marginTop: "10px"}} onClick={this.updateCourse}></Button>
        </div>
      )
    }else{
      titleView = (
        <>
        {
          this.state.isPublished === 0 || this.state.isPublished === 1 ?
          <div style={{display: "flex"}}>
            <div className="mb-4" style={{fontSize:34}}>
              {this.state.title}
            </div>
            <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px", alignSelf: "center"}} onClick={() => this.setState({titleEdit: true})}></Button>
          </div>
          :
          <div className="mb-4" style={{fontSize:34}}>
            {this.state.title}
          </div>
        }
        </>
      )
    }

    return titleView
  }

  renderDescription(){

    let description

    if (this.state.descriptionEdit){
      description = (
        <div className="p-2" style={{marginTop: 12}}>
          <InputText style={{fontSize:"14px", width:"100%", border:"none", padding:0}} className="p-inputtext-lg p-d-block" id="description" value={this.state.description} onChange={(e) => this.setState({description: e.target.value})} autoFocus/>
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.SAVE_CHANGES'})} style={{verticalAlign: 'middle',   marginTop: "10px"}} onClick={this.updateCourse}></Button>
        </div>
      )
    }else{
      description = (
        <>
        {
          this.state.isPublished === 0 || this.state.isPublished === 1 ?
            <div className="d-flex" style={{padding: "0 0.5rem"}}>
              <div style={{margin: "auto 0", maxWidth:"96%"}}>
                <span>{this.state.description}</span> 
              </div>
              <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({descriptionEdit: true})}></Button>
            </div>
          :
            <div className="d-flex mt-2" style={{padding: "0 0.5rem"}}>
              <div style={{margin: "auto 0", maxWidth:"96%"}}>
                <span>{this.state.description}</span> 
              </div>
            </div>
        }
        </>
      )
    }

    return description
  }

  renderImageOption(){
    let imgOptionView
    if(!this.state.imgFile){
      imgOptionView = (<input type="button" className="btn btn-secondary mx-auto d-block mt-1" value={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CHANGE_IMAGE'})} onClick={() => document.getElementById('fileInput').click()} />)
    }else{
      imgOptionView = (
      <div className="d-flex mt-2 justify-content-around">
        <button className="btn btn-danger" onClick={() => this.setState({imgFile: null, imgPreview: this.state.imgOficial})}><FormattedMessage id="GENERAL.DISCARD_CHANGES"/></button>
        <button className="btn btn-primary" onClick={this.updateCourseImage}><FormattedMessage id="GENERAL.SAVE"/></button>
      </div>
      )
    }
    return imgOptionView
  }

  renderHeader() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-12 mb-1" style={{display:"flex", justifyContent:"space-between"}}>
          <h3><FormattedMessage id="LEARNING_COURSE.DOCUMENTS_VIEW"/></h3>
          <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value })} />
        </div>
        <div className="p-col-12" style={{display:"flex", justifyContent:"space-between"}}>
          <div className="d-flex">
            <div style={{display:"grid"}}>
              <span style={{color:"darkgray"}}><FormattedMessage id="GENERAL_MESSAGE.SEARCH_BY_NAME_TYPE"/></span>
              <span style={{justifySelf:"center"}} className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={this.state.searchText} onChange={e => this.onChangeSearch(e.target.value)} placeholder={this.props.intl.formatMessage({id: 'GENERAL.SEARCH'})} />
              </span>
            </div>
            <Button label={this.props.intl.formatMessage({id: 'LEARNING_COURSE.ADD_NEW_FILE'})} className="mt-auto ml-3" style={{alignItems: "end"}} onClick={e => this.setState({dialogNewDoc: true})}><AddIcon/></Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if(this.state.redirect) {
      return <Redirect to={{
        pathname: "/get-tutorials/course/lesson",
      }}/>;
    }

    const header = this.renderHeader();

    const footer = (
      <div>
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={this.saveLesson} />
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={this.onHide} />
      </div>
    );

    const footerNewDoc = (
      <div>
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={this.saveDoc} disabled={(this.state.file && this.state.uploadPercentage === 0) ? false : true} />
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={this.onHideNewDoc} disabled={this.state.uploadPercentage === 0 ? false : true}/>
      </div>
    );

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />  
        <div className="d-flex justify-content-between mb-2">
          <Link to={{
              pathname:"/get-tutorials"
          }}>
          <button className="btn"><KeyboardBackspaceSharpIcon/></button>
          </Link>
          {
            this.state.isPublished === 0  || this.state.isPublished === 1 ? <button className="btn btn-lg btn-info" onClick={this.onClickConfirmation}>Publicar curso</button> : <></>
          }
        </div>
        <div className="card p-6">
          <Dialog header={this.props.intl.formatMessage({id: 'LEARNING_COURSE.ADD_NEW_LESSON'})} footer={footer} visible={this.state.dialog} onHide={this.onHide}>
            <div className="p-grid pt-5">
              <div className="p-col">
                <span className="p-float-label">
                  <InputText style={{width: "100%"}} id="title" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} />
                  <label htmlhtmlfor="title"><FormattedMessage id="LEARNING_COURSE.LESSON_NAME"/></label>
                </span>
              </div>
            </div>
          </Dialog>
          <Dialog header={this.props.intl.formatMessage({id: 'LEARNING_COURSE.ADD_NEW_FILE'})} style={{ width: '800px' }} footer={footerNewDoc} visible={this.state.dialogNewDoc} onHide={this.onHideNewDoc} contentStyle={{paddingBottom: 0}}>
            <input id="documentInput" type="file" style={{display:"none"}} onChange={ e => this.changeFile(e)}/>
            {
              this.state.file ?
                <div className="p-grid">
                  <div className="p-col-8 align-self-center">
                    <div className="text-center mb-2">
                      <h3><FormattedMessage id="LEARNING_COURSE.FILE_NAME"/>:</h3>
                      <span className="font-weight-bold">{this.state.file.name}</span>
                    </div>
                    <div className="d-flex justify-content-around mb-4">
                      <span><FormattedMessage id="GENERAL.SIZE"/>: {this.transformSize(this.state.file.size)}</span>
                      <div>
                        <span><FormattedMessage id="LEARNING_COURSE.TYPE_FILE"/>:</span>
                        <span className="ml-1 text-uppercase">{this.getType(this.state.file.name)}</span>
                      </div>
                    </div>

                    <input type="button" className="btn btn-secondary mx-auto d-block mt-2" value={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CHANGE_FILE'})} onClick={() => document.getElementById('documentInput').click()} />
                  </div>
                  <div className="p-col-4 align-self-center">
                    <span className="d-flex ml-auto mr-auto" style={{width:100}}>
                      {this.renderSVG(this.getType(this.state.file.name))}
                    </span>
                  </div>
                  {
                    this.state.uploadPercentage > 0 && 
                    <div className="p-col-12">
                      <ProgressBar value={this.state.uploadPercentage}/>
                    </div>
                  }
                </div>
              :
              <div className="p-grid">
                <div className="p-col">
                  <input type="button" className="btn btn-secondary mx-auto d-block" value={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SELECT_FILE'})} onClick={() => document.getElementById('documentInput').click()} />
                </div>
              </div>
            }
          </Dialog>
          <Dialog header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONFIRM'})} visible={this.state.displayConfirmPublication} modal style={{ width: '350px' }} footer={this.renderFooterConfirmation('displayConfirmPublication')} onHide={() => this.onHideC('displayConfirmPublication')}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_PUBLICATION_COURSE"/></span><br/><br/>
                <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_PUBLICATION_COURSE_DETAIL"/></span>
            </div>
          </Dialog>
          <Dialog header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONFIRM'})} visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter('displayConfirmation')} onHide={() => this.onHideC('displayConfirmation')}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_DELETE_LESSON"/></span>
            </div>
          </Dialog>
          <Dialog header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONFIRM'})} visible={this.state.displayDoc} modal style={{ width: '350px' }} footer={this.renderFooterDocument('displayDoc')} onHide={() => this.onHideC('displayDoc')}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_DELETE_FILE"/></span>
            </div>
          </Dialog>
          <div className="p-grid p-nogutter mb-6">
            <div className="p-col-6">
              <div className="mb-2 font-weight-bold">
                {
                  this.state.isPublished === 0 || this.state.isPublished === 1 ?
                  <span className="p-tag text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
                  :
                  <span className="p-tag text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
                }
              </div>
              {
                this.renderTitle()
              }
              <div>
                <div className="mb-6">
                  <p><FormattedMessage id="GENERAL_MESSAGE.CREATED_IN"/>: {this.props.dataCourse.created_at}</p>
                </div>
                <FormattedMessage id="GENERAL.DESCRIPTION"/>:
                {
                  this.renderDescription()
                }
              </div>
            </div>
            <div className="p-col-6">
              <img className="img-fluid mx-auto d-block img-thumbnail" alt={`course-${this.props.dataCourse.course}`} style={{maxHeight:250}} src={this.state.imgPreview}/>
              {
                this.state.isPublished === 0 || this.state.isPublished === 1 ?
                <>
                <input id="fileInput" type="file" style={{display:"none"}} onChange={ e => this.changeImage(e)}/>
                {
                  this.renderImageOption()
                }
                </>
                :
                <></>
              }
            </div>
          </div>
          <hr className="pb-5"/>
          {
            this.state.lessons ?
              this.state.section_1 ?
              <div className="tabs-options-course">
                <div className="tab-enabled-option">
                  <FormattedMessage id="LEARNING_COURSE.LESSONS"/>
                </div>
                <div className="tab-desabled-option" onClick={e => this.setState({section_1: false})}>
                  <FormattedMessage id="LEARNING_COURSE.DOCUMENTS"/>
                </div>
              </div>
              :
              <div className="tabs-options-course">
                <div className="tab-desabled-option" onClick={e => this.setState({section_1: true})}>
                  <FormattedMessage id="LEARNING_COURSE.LESSONS"/>
                </div>
                <div className="tab-enabled-option">
                  <FormattedMessage id="LEARNING_COURSE.DOCUMENTS"/>
                </div>
              </div>
            :
            <></>
          }
          {
            this.state.lessons ?
              this.state.section_1 ?
              <div className="p-4 border">
              {
                this.state.isPublished === 0 || this.state.isPublished === 1 ?
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {this.state.lessons.map((data, index) => this.renderLessonsModified(data, index))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                :
                  this.state.lessons.map((data, index) => this.renderLessons(data, index))
              }
              {this.state.isPublished === 0 || this.state.isPublished === 1 ?
                <div className="border border-secondary">
                  <div className="p-10" style={{textAlign: "center", alignSelf: "center"}}>
                    <button className="btn btn-primary btn-lg" onClick={this.onOpen}><FormattedMessage id="LEARNING_COURSE.ADD_NEW_LESSON"/> <AddIcon/></button>
                  </div>
                </div>
                :
                <></>
              }
              </div>
              :
              <div className="dataview p-4 border">
                <DataView value={this.state.documents_show} layout={this.state.layout} header={header}
                  itemTemplate={this.itemTemplate} rows={12}
                  emptyMessage={this.props.intl.formatMessage({id: 'LEARNING_COURSE.NO_DOCUMENTS'})}
                  paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
              </div>
            :
            <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  dataCourse: state.auth.dataCourse
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(Course)
)