import React from "react";
// import {useSubheader} from "../../_metronic/_core";

import InvoicesManagementTable from "../modulesAccounting/Invoices/InvoicesManagmentTable"

export const InvoicesManagementPage = () => {
//   const suhbeader = useSubheader();
//   suhbeader.setTitle("Invoices");

  return (<><InvoicesManagementTable /></>);
};