import React from "react";
import {useSubheader} from "../../_metronic/_core";
import EvaluationsResults from "../modulesSchool/EvaluationsResults"
import { useIntl } from "react-intl";

export const EvaluationResultPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();
    
    suhbeader.setTitle(`${int1.formatMessage({id: "LEARNING_ASSESSMENT.COURSE"})}`);
    return (<EvaluationsResults/>)
}
