// import RoleTypes from './RoleTypes';
// import Roles from './Roles';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import { DataGrid } from '@material-ui/data-grid';
// import { useSelector } from 'react-redux';
// import { toAbsoluteUrl } from '../../../_metronic/_helpers';
// import SVG from "react-inlinesvg";

const useStyles = makeStyles(() => ({
  description: {
    display: "block",
    fontSize: "14px",
    marginBottom: "12px",
    paddingLeft: "12.5px",
    paddingRight: "12.5px",
  },
  box: {
    background: "#DCDCDC",
    border: "1px solid black",
    borderRadius: "7px",
  },
  headBox: {
    fontSize:"16px",
    background: "dimgrey",
    color:"white",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px",
    textTransform: "uppercase"
  },
  salaryBox: {
    display: "flex",
    background: "#DCDCDC",
    height: "100px",
    border: "1px solid black",
    borderRadius: "7px",
  },
  titleSalaryBox: {
    fontSize: "24px"
  },
  addSalary: {
    color: "cornflowerblue",
    cursor: "pointer"
  },
  salaryMoney: {
    display: "flex",
    color: "dodgerblue"
  },
  simbolMoney: {
    fontSize: "20px",
    marginTop: "16px"
  },
  money: {
    fontSize: "37px",
    color: "dodgerblue"
  },
  contentBox: {
    fontSize: "14px",
    padding:"8px 20px 8px 20px"
    // paddingTop: "8px",
    // paddingBottom: "8px",
    // paddingLeft: "20px",
    // paddingRight: "20px",
  },
  contentMoney: {
    fontSize: "38px",
    fontWeight: 500,
  },
  downBox: {
    position: "absolute",
    right: "24px",
    bottom: "8px",
    fontSize: "16px",
    textAlign: "right"
  },
  buttons: {
    background: "grey",
    color: "white",
  },
  tabs: {
    display:"flex",
    textTransform:"uppercase"
  },
  tabEnabled: {
    background:"#DCDCDC",
    padding: "10px 14px",
    borderTopLeftRadius:"7px",
    borderTopRightRadius:"7px",
    fontWeight: 500
  },
  tabDesabled: {
    padding: "10px 14px",
    "&:hover": {
      fontWeight: 500,
      cursor: "pointer"
    }
  },
  filterSection: {
    display:"flex",
    background:"#DCDCDC",
    paddingTop:"16px",
    paddingLeft:"26px"
  }
}));

const columns = [
  { field: 'product',
    headerName: 'PRODUCTO',
    width: 300,
    valueGetter: (params) =>
      `${params.row.product || '0'}`,  
  },
  { field: 'expiration', headerName: 'VENCIMIENTO', width: 110 },
  {
    field: 'amount',
    headerName: 'IMPORTE',
    type: 'number',
    width: 100,
    valueGetter: (params) =>
      `$${params.row.money || '0'}.00`,
  },
];
/*
{
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
  },
*/

const rows = [
  { id: 1, product: 'Plan Sin Limites Accounting', expiration: '22/02/2021', money: 35 },
  { id: 2, product: 'Plan Sin Limites Accounting', expiration: '18/04/2021', money: 42 },
  { id: 3, product: 'Plan Sin Limites Accounting', expiration: '4/04/2021', money: 45 },
  { id: 4, product: 'Plan Sin Limites Accounting', expiration: '6/04/2021', money: 16 },
  { id: 5, product: 'Plan Sin Limites Accounting', expiration: '22/02/2021', money: null },
  { id: 6, product: 'Plan Sin Limites Accounting', expiration: '9/08/2021', money: 150 },
  { id: 7, product: 'Plan Sin Limites Accounting', expiration: '27/05/2021', money: 44 },
  { id: 8, product: 'Plan Sin Limites Accounting', expiration: '2/02/2021', money: 36 },
  { id: 9, product: 'Plan Sin Limites Accounting', expiration: '21/02/2021', money: 65 },
];


export default function ToPayBilling() {
  const classes = useStyles();
  /*
  const { user } = useSelector(state => state.auth);
  */

  return (
    <div style={{paddingLeft:"5em", paddingRight:"5em"}}>
      <div className="container">
        <div className="row">
          <NavLink to="/movements-and-invoices">
          <button className="btn"><KeyboardBackspaceSharpIcon/></button>
          </NavLink>
        </div>
        <h1>Pagos de compras y renovaciones</h1>
        <div className="row">
          <div className={classes.description}>
            <span>Selecciona aquí los servicios que vas a renovar y las compras que quieras pagar.</span>
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-12 col-sm-7">
            <div className={classes.box}>
              <div className={classes.headBox} style={{marginBottom: "10px"}}>
              Vencimientos
              </div>
              <div className={classes.contentBox}>
                <div style={{ height: 300, width: '100%' }}>
                  <DataGrid loading={false} disableColumnMenu={true} rows={rows} columns={columns} pageSize={3} checkboxSelection />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-5">
            <div className={classes.box} style={{marginLeft: "3em", marginRight: "3em"}}>
              <div className={classes.headBox} style={{marginBottom: "10px"}}>
              Total a pagar
              </div>
              <div className="text-center">
                <div className="mb-5">
                  <div className={classes.money}>
                  $0.00
                  </div>
                  <div>
                  Pesos Mexicanos
                  </div>
                </div>
                <div className="mb-4">
                  <div>
                    <button type="button" className="btn btn-lg" style={{background: "green", color: "white", width:"90%"}}>Ir a pagar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}