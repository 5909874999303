import React from "react";
import {useSubheader} from "../../_metronic/_core";

import ExchangesPulic from "../modulesAccounting/ExchangeRate/ExchangeRatesPublic"

export const ExchangesRatesPublicPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Exchanges");

  return (<><ExchangesPulic /></>);
};