import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { useIntl } from "react-intl";

const initialValues = {
  email: "",
};

function getText(result){
  const { selectedLang } = JSON.parse(localStorage.i18nConfig);
  switch (result) {
    case "WrongEmail":
      switch (selectedLang) {
        case "en":
          return "Wrong email format"
        case "es":
          return "Formato de email incorrecto"
        case "de":
          return "Falsches E-Mail-Format"
        case "fr":
          return "Mauvais format d'e-mail"
        default:
          return "Wrong email format"
      }
    case "Min3Sy":
      switch (selectedLang) {
        case "en":
          return "Minimum 3 symbols"
        case "es":
          return "Minimo 3 simbolos"
        case "de":
          return "Mindestens 3 Symbole"
        case "fr":
          return "Minimum 3 symboles"
        default:
          return "Minimum 3 symbols"
      }
    case "Max50Sy":
      switch (selectedLang) {
        case "en":
          return "Maximum 50 symbols"
        case "es":
          return "Maximo 50 simbolos"
        case "de":
          return "Maximal 50 Symbole"
        case "fr":
          return "50 symboles maximum"
        default:
          return "Maximum 50 symbols"
      }
    default:
      return "Invalid"
  }
}

function ForgotPassword(props) {
  const int1 = useIntl();
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(getText("WrongEmail"))
      .min(3, getText("Min3Sy"))
      .max(50, getText("Max50Sy"))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then(() => setIsRequested(true))
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">{int1.formatMessage({id: "AUTH.FORGOT.TITLE"})}</h3>
            <div className="text-muted font-weight-bold">
              {int1.formatMessage({id: "AUTH.FORGOT.DESC"})}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                {int1.formatMessage({id: "AUTH.GENERAL.SUBMIT_BUTTON"})}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
