/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
// import SVG from "react-inlinesvg";
// import {toAbsoluteUrl} from "../../../_helpers";
import { useIntl } from "react-intl";
import { Dropdown } from "primereact/dropdown";
import Axios from "axios";
import ServerURL from "../../../../../ServerURL";
import { Avatar } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
// import { useLang } from "../../../../../_metronic/i18n";

const url = ServerURL();

// const id_director = 58;
const id_gerente = 62;
const id_coordinador = 63;
const id_agente = 64;

const coursesPoints = 50
const qualificationPoints = 50

export function AdvanceTablesWidget5({ className }) {
  const int1 = useIntl();

  const [users, setUsers] = useState(null);
  const [agents, setAgents] = useState(null);
  const [coordinadores, setCoordinadores] = useState(null);
  const [gerentes, setGerentes] = useState(null);
  const [roleSelected, setRoleSelected] = useState(3);
  const [options, setOptions] = useState(null);

  /*
  agents: rankingAgents,
  coordinadores: rankingCoordinadores,
  gerentes: rankingGerentes,
  roleSelected: 3,
  options
  */

  const getRol = (role) => {
    let Message = null;
    switch (role) {
      case id_gerente:
        Message = "MANAGER";
        break;
      case id_coordinador:
          Message = "COORDINATOR";
        break;
      case id_agente:
        Message = "AGENT";    
        break;
      default:
        Message = "DIRECTOR";
        break;
    }
    return Message;
  }

  const getOrder = arrayUsers => {
    const newArrayUsers = [];
    arrayUsers.forEach(user => {
      let coursesPersonalCompleted = 0;
      if(user.avances){
        user.avances.forEach(course => {
          if(course.avance === 100){
            coursesPersonalCompleted++;
          }
        })
      }
      let coursesCompletedPoints = (coursesPersonalCompleted/user.coursesPersonalAssigned)*coursesPoints;
      if(!coursesCompletedPoints){
        coursesCompletedPoints = 0;
      }
      const qualificationsPoints = (user.qualification/100)*qualificationPoints;
      const ranking = coursesCompletedPoints + qualificationsPoints;
      user = { ...user, coursesPersonalCompleted, coursesCompletedPoints, qualificationsPoints, ranking};
      newArrayUsers.push(user);
    })
    
    let newArrayUsersOrder = newArrayUsers.sort(function (a, b){
      return a.ranking - b.ranking;
    })

    newArrayUsersOrder = newArrayUsersOrder.reverse();

    return newArrayUsersOrder
  }

  const getQualification = user => {
    let sumPromedios = 0;
    const coursesValid = [];
    if(user.avances){
      user.avances.forEach(course => {
        if(course.avance === 100){
          coursesValid.push(course.id_course);
        };
      })
    }

    if(user.promedios){
      user.promedios.forEach(course => {
        const found = coursesValid.find(id => id === course.id_course)
        if(found){
          sumPromedios += course.promedio;
        }
      })
    }

    let qualification = 0
    if(coursesValid.length){
      qualification = (sumPromedios/(coursesValid.length*100))*100;
    }

    user = { ...user, qualification }
    return user;
  }

  useEffect(() => {
    if(!users){
      const getData = async () => {
        try {
          await Axios.get(`${url}/api/auth/getranking`)
          .then(res => {
            let users = res.data
    
            const newArrayAgents = [];
            const newArrayCoordinadores = [];
            const newArrayGerentes = [];
            
            users.forEach(user => {
              console.log(user)
              if(user.learning){
                user = getQualification(user);
                if(user.fk_id_role === id_agente){
                  newArrayAgents.push(user);
                }else if(user.fk_id_role === id_coordinador){
                  newArrayCoordinadores.push(user);
                }else if(user.fk_id_role === id_gerente){
                  newArrayGerentes.push(user);
                }
              }
            })
    
            const rankingAgents = getOrder(newArrayAgents)
    
            const rankingCoordinadores = getOrder(newArrayCoordinadores)
    
            const rankingGerentes = getOrder(newArrayGerentes)
    
            const options = [];
    
            if(rankingGerentes.length){
              options.push({label: <FormattedMessage id="GENERAL_ROLES.MANAGERS"/>, value: 1})
            }
            if(rankingCoordinadores.length){
              options.push({label: <FormattedMessage id="GENERAL_ROLES.COORDINATORS"/>, value: 2})
            }
            if(rankingAgents.length){
              options.push({label: <FormattedMessage id="GENERAL_ROLES.AGENTS"/>, value: 3})
            }
    
            console.log(rankingGerentes);
            console.log(rankingCoordinadores);
            console.log(rankingAgents);
    
            setUsers(users);
            setAgents(rankingAgents);
            setCoordinadores(rankingCoordinadores);
            setGerentes(rankingGerentes);
            setRoleSelected(3);
            setOptions(options);
          });
        }catch(error){
          console.log("ERROR")
        }
      }

      getData();
    }
  }, [users]);

  const selectRole = (role) => {
    switch (role) {
      case 3:
        return agents.map((agente, index) => renderRow(agente, index));
      case 2:
        return coordinadores.map((coordinador, index) => renderRow(coordinador, index));
      case 1:
        return gerentes.map((gerente, index) => renderRow(gerente, index));

      default:
        return agents.map((agente, index) => renderRow(agente, index));
    }
  }

  const renderRow = (data, index) => {
    const role = getRol(data.fk_id_role);
    return (
      <tr key={data.id}>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
            {data.ranking !== 0 ? (index+1) : "-"}
          </span>
        </td>
        <td className="pl-0 py-8">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50 symbol-light mr-4">
                <span>
                  <span className="svg-icon h-75 align-self-end">
                    <Avatar alt={data.fullname} src={`${data.photo}`} style={{width: "50px", height: "50px"}} />
                  </span>
                </span>
            </div>
            <div>
              <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">{data.fullname}</span>
              <span className="text-muted font-weight-bold d-block"><FormattedMessage id={`GENERAL_ROLES.${role}`}/></span>
            </div>
          </div>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {data.coursesPersonalCompleted}
            </span>
          <span className="text-muted font-weight-bold">
              
            </span>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {data.qualification}
            </span>
          <span className="text-muted font-weight-bold">
              
            </span>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {Number.parseFloat(data.ranking).toFixed(2)}
            </span>
          <span className="text-muted font-weight-bold">
              
            </span>
        </td>
        <td>
          <span className="text-muted font-weight-bold d-block font-size-sm text-center">
            {data.jefeInmediato}
          </span>
        </td>
      </tr>
    )
  }

  let boolView = true;

  if(users && users.length === 1){
    boolView = false;
  }

  return (
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        {
          boolView ?
            <div className="card-header border-0 py-5">
              <h3 className="card-title align-self-center flex-column">
                <span className="card-label font-weight-bolder text-dark">{int1.formatMessage({id: "WIDGETS.ADVANCE4.TITLE"})}</span>
              </h3>
              <div className="card-toolbar">
                <span className="text-muted font-weight-bold font-size-sm"><FormattedMessage id="GENERAL_MESSAGE.SORT_BY"/>: <Dropdown style={{minWidth:"min-content"}} className="ml-2" placeholder="Roles" options={options} value={roleSelected} onChange={(e) => setRoleSelected(e.value)}/></span>
                {/* <a href="#" className="btn btn-info font-weight-bolder font-size-sm mr-3">{int1.formatMessage({id: "WIDGETS.ADVANCE4.REPORT"})}</a>
                <a href="#" className="btn btn-danger font-weight-bolder font-size-sm">{int1.formatMessage({id: "WIDGETS.GENERAL.CREATE"})}</a> */}
              </div>
            </div>
          :
          <></>
        }
        {/* Body */}
        {
          boolView ? (
            <div className="card-body pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                    <tr className="text-left text-uppercase">
                      <th className="text-center" style={{minWidth: "50px"}}><span className="text-dark-75">#</span></th>
                      <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75"><FormattedMessage id="GENERAL_MESSAGE.MEMBERS"/></span></th>
                      <th className="text-center" style={{minWidth: "100px"}}><FormattedMessage id="GENERAL_MESSAGE.COMPLETED_COURSES"/></th>
                      <th className="text-center" style={{minWidth: "100px"}}><FormattedMessage id="GENERAL_MESSAGE.AVERAGE_MARK"/></th>
                      <th className="text-center" style={{minWidth: "100px"}}><FormattedMessage id="GENERAL_MESSAGE.POINTS"/></th>
                      <th className="text-center" style={{minWidth: "130px"}}><FormattedMessage id="GENERAL_MESSAGE.BOSS"/></th>
                    </tr>
                    </thead>
                    {
                      (users && agents && coordinadores && gerentes) ?
                        <tbody>
                          {selectRole(roleSelected)}
                        </tbody>
                      :
                        <></>
                    }
                  </table>
                  {
                    (users && agents && coordinadores && gerentes) ?
                    <></>
                    :
                    <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
                  }
                </div>
              </div>
            </div>
          )
          :
            <div style={{display:"grid", justifyItems:"center", marginTop:"15vh", marginBottom:"15vh"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
              </svg>
              <span style={{marginTop:"1em", fontSize:"2em"}}><FormattedMessage id="GENERAL_MESSAGE.NO_USERS"/></span>
            </div>
        }
      </div>
  );
}
