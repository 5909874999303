import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'



const url = ServerURL();


class SchoolsTable extends Component {
            

  
  emptySchool = {
    school: '',
    logo: '',
    contact: '',
    email: '',
    phone:'',
    website: '',
    fllogo:'',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    name: '',
    logo: '',
    contact: '',
    email: '',
    phone:'',
    website: '',

    /**
       * Aux messages
       */
      schoolMessage: '',
      contactMessage: '',
      emailMessage: '',
      phoneMessage: '',
      websiteMessage: '',

    school: this.emptySchool,
    schools:null,
    ids:null,
    globalFilter: null,
    selectedSchools: null,
    schoolDialog: false,
    deleteSchoolDialog: false,
    deleteSchoolsDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveSchool = this.saveSchool.bind(this);
  this.deleteSchool = this.deleteSchool.bind(this);
  this.openNew = this.openNew.bind(this);
  this.schoolBodyTemplate = this.schoolBodyTemplate.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editSchool = this.editSchool.bind(this);
  this.confirmDeleteSchool= this.confirmDeleteSchool.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedSchools = this.deleteSelectedSchools.bind(this);
  this.hideDeleteSchoolDialog = this.hideDeleteSchoolDialog.bind(this);
  this.hideDeleteSchoolsDialog = this.hideDeleteSchoolsDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onRoleChange = this.onRoleChange.bind(this);
  }



      componentDidMount() {
        this.getSchools()

      }


      isValid(){
        const { school,contact, email, phone, website } = this.state.school
              const schoolMessage = this.validateSchool()
              const contactMessage = this.validateContact()
              const emailMessage = this.validateEmail()
              const phoneMessage = this.validatePhone()
              const websiteMessage = this.validateWebsite()


        
              if(school && contact && email && phone && website ){
                if(!schoolMessage && !contactMessage && !emailMessage && !phoneMessage && !websiteMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
            validateSchool() {
              const school = this.state.school
              
              let schoolMessage;
              if (!school.school) {
                schoolMessage = 'Requerid';
              } else {
                schoolMessage = ''
              }
              this.setState({ schoolMessage })
              return schoolMessage
            }

            validateContact() {
              const school= this.state.school
              
              let contactMessage;
              if (!school.contact) {
                contactMessage = 'Requerido';
              } else {
                contactMessage = ''
              }
              this.setState({ contactMessage })
              return contactMessage
            }


            

            validateEmail() {
                    const school = this.state.school
                    
                    let emailMessage;
                    if (!school.email) {
                      emailMessage = 'Requerido';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(school.email)) {
                      emailMessage = 'Correo electronico invalido';
                    } else {
                      emailMessage = ''
                    }
                    this.setState({ emailMessage })
                    return emailMessage
                  }

            validatePhone() {
              const school = this.state.school
              
              let phoneMessage;
              if (!school.phone) {
                phoneMessage = 'Requerido';
              } else if (!/^[A-Z0-9._%+-]{6,12}$/i.test(school.phone)) {
                phoneMessage = 'Telefono invalido';
              } else {
                phoneMessage = ''
              }
              this.setState({ phoneMessage })
              return phoneMessage
            }

            validateWebsite() {
              const school = this.state.school
              
              let websiteMessage;
              if (!school.website) {
                websiteMessage = 'Requerido';
              } else if (!/^[A-Z0-9._%+-]+\.[A-Z]{2,4}$/i.test(school.website)) {
                websiteMessage = 'Website invalido';
              } else {
                websiteMessage = ''
              }
              this.setState({ websiteMessage })
              return websiteMessage
            }


            getTextAuxSchool(){
              const message = this.state.schoolMessage
              return message
            }
        
            getTextAuxContact(){
              const message = this.state.contactMessage
               return message
             }
      
            getTextAuxEmail(){
             const message = this.state.emailMessage
              return message
            }

            getTextAuxPhone(){
              const message = this.state.phoneMessage
               return message
             }

             getTextAuxWebsite(){
              const message = this.state.websiteMessage
               return message
             }

      getSchools = async () =>{
         
          try {
              let res = axios.get(`${url}/api/auth/schools/`)
              .then(res => {     
                const schools = res.data;            
                this.setState({ schools })
              });                     
                console.log(res.data)
              }catch(error){
         }
      }

      deleteSchool(){
        let data = {...this.state.school};

        try {
        let res = axios.delete(`${url}/api/auth/school/${data.id}`)
          .then(res => {
          this.getSchools()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteSchoolDialog: false,
            user: this.emptySchool
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'School Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveSchool() {
        let state = { submitted: true };

        //if (this.state.school.school.trim()) {   
          const decision = this.isValid();
          if (decision) {    
          let data = {...this.state.school};

          const formData = new FormData();
          formData.append('fllogo',this.uploadInput.files[0]);
          formData.append('school',data.school);
          formData.append('contact',data.contact);
          formData.append('email',data.email);
          formData.append('phone',data.phone);
          formData.append('website',data.website);


          const config = {
            headers: {
         
           'Content-Type':'multipart/form-data'}
           //,body:JSON.stringify(data)
          }
          
          console.log(formData,config)


        if (this.state.school.id) {

            try {
              let res = axios.put(`${url}/api/auth/school/${data.id}`,formData,config)
              .then(res =>{
              this.getSchools()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'School Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/school`,formData, config)
            
             .then(res => {
                 this.getSchools()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'School Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          schoolDialog: false,
          school: this.emptySchool
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.setState({
            school: this.emptySchool,
            submitted: false,
            schoolDialog: true
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let school = {...this.state.school};
        school[`${name}`] = val;

        this.setState({ school});
      }

      onRoleChange(e) {
        let school = {...this.state.school};
        school['role'] = e.value;
        this.setState({ school });
      }


      hideDialog() {
          this.setState({
              submitted: false,
              schoolDialog: false,
              schoolMessage: '',
              contactMessage: '',
              emailMessage: '',
              phoneMessage: '',
              websiteMessage: ''
        })  
      } 


      editSchool(school) {
          this.setState({
              school: { ...school },
              schoolDialog: true
          })

      }

      confirmDeleteSchool(school) {
        this.setState({
            school,
            deleteSchoolDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteSchoolsDialog: true })
      }

      deleteSelectedSchools() {
        let schools = this.state.schools.filter(val => !this.state.selectedSchools.includes(val));
        let ids = this.state.schools.filter(val => this.state.selectedSchools.includes(val));
        console.log(ids)

        try {
        let res = axios.delete(`${url}/api/school/${ids.id}`)
          .then(res => {
          this.getSchools()
        })
        if (!res.error) {
          this.setState({
            schools,
            deleteSchoolsDialog: false,
            selectedSchools: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Schools Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteSchoolDialog() {
        this.setState({ deleteSchoolDialog: false })
      }
        
      hideDeleteSchoolsDialog() {
        this.setState({ deleteSchoolsDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
      schoolBodyTemplate(rowData) {
          return (
              <>
                  <Avatar alt={rowData.name} src={`${rowData.logo}`} width={32} style={{verticalAlign: 'middle',   margin: "10px", width: "50px", height: "50px",}} />
              </>
          )
      }

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editSchool(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteSchool(rowData)} />
            </>
        )
      }
      
      




      render(){
       const deleteSchoolDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSchoolDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSchool} />
        </>
       );

       const SchoolDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveSchool} />
        </>
       );

       const deleteSchoolsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSchoolsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedSchools} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		        <FormattedMessage id="ADMIN_SCHOOL.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_SCHOOL.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedSchools || !this.state.selectedSchools.length} />}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_SCHOOL">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div>

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
            </div>
          </div>

          
          <div>
           {/*SHOW SCHOOL */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.schools} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No School found." loading={this.state.loading}
                        selection={this.state.selectedSchools} onSelectionChange={e => this.setState({ selectedSchools: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="logo" body={this.schoolBodyTemplate}></Column>
                        <Column field="school" header={this.state.campusText.School}  filter filterPlaceholder={this.state.campusText.SearchBySchool} sortable></Column>
                        <Column field="contact" header={this.state.campusText.Contact}  filter filterPlaceholder={this.state.campusText.SearchByContact} sortable></Column>
                        <Column field="email" header={this.state.campusText.Email}  filter filterPlaceholder={this.state.campusText.SearchByEmail} sortable></Column>
                        <Column field="phone" header={this.state.campusText.Phone}  filter filterPlaceholder={this.state.campusText.SearchByPhone} sortable></Column>
                        <Column field="website" header={this.state.campusText.Website}  filter filterPlaceholder={this.state.campusText.SearchByWebsite} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE SCHOOL */}
          <Dialog visible={this.state.deleteSchoolDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSchoolDialogFooter} onHide={this.hideDeleteSchoolDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.school && <span> Are you sure you want to delete <b>{this.state.school.name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteSchoolsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSchoolsDialogFooter} onHide={this.hideDeleteSchoolsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.school && <span>Are you sure you want to delete the selected schools?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE SCHOOL & CREATE SCHOOL*/}
          <Dialog visible={this.state.schoolDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"360px", overflow:"auto"}} header="School Profile" modal footer={SchoolDialogFooter} onHide={this.hideDialog}>
          <br></br>
          <div className="form-group row">
          <div className="col-lg-6">  
          
          <Avatar  
              size="small" 
              shape="circle"/>
              <input 
              type="file" 
              name="avatar"
              ref={(ref) => { this.uploadInput = ref; }}
              multiple= {false} />
          </div>
          </div>
          <br></br>
          <div className="form-group row">
              {/* School */}
              <div className="col-lg-6">  
              <span className="p-float-label">           
                <InputText
                  style={{ width: 250, height:33 }}
                  id="school"
                  variant="outlined"
                  margin="dense"
                  label="School"
                  value={this.state.school.school}             
                  onChange={(e) => this.onInputChange(e, 'school')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.school.school  })}
                  />
              <label htmlFor="school">School</label>    
              </span>    
              <small id="school-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxSchool(this.state)}</small>
              </div>
              {/* Contact */}
              <div className="col-lg-6">
              <span className="p-float-label">           
                  <InputText
                   style={{ width: 250, height:33 }}
                  id="contact"
                  label="Contact"
                  variant="outlined"
                  margin="dense"
                  value={this.state.school.contact}             
                  onChange={(e) => this.onInputChange(e, 'contact')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.school.contact  })}
                  />
              <label htmlFor="contact">Contact</label>        
              </span>    
              <small id="contact-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxContact(this.state)}</small>
              </div>
              </div>
                  {/* Email*/}
                  <br></br>
                  <div className="form-group row">
                  <div className="col-lg-6">
                  <span className="p-float-label">            
                  <InputText
                   style={{ width: 250, height:33 }}
                  id="email"
                  type="email"
                  variant="outlined"
                  margin="dense"
                  value={this.state.school.email}             
                  onChange={(e) => this.onInputChange(e, 'email')} 
                  keyfilter="email"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.school.email  })}
                  />
              <label htmlFor="email">Email</label>        
              </span>     
              <small id="email-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEmail(this.state)}</small>
              </div>
              <div className="col-lg-6">
              <span className="p-float-label">            
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="phone"
                  type="phone"
                  variant="outlined"
                  margin="dense"
                  value={this.state.school.phone}             
                  onChange={(e) => this.onInputChange(e, 'phone')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.school.phone  })}
                  />
              <label htmlFor="phone">Phone</label>          
              </span>  
              <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPhone(this.state)}</small>
              </div>
              </div>
              <br></br>
              <div className="form-group row">
              <div className="col-lg-6">
              <span className="p-float-label">            
                  <InputText
                   style={{ width: 250, height:33 }}
                  id="website"
                  type="website"
                  variant="outlined"
                  margin="dense"
                  value={this.state.school.website}             
                  onChange={(e) => this.onInputChange(e, 'website')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.school.website  })}
                  />
              <label htmlFor="website">Website</label>          
              </span>    
              <small id="website-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxWebsite(this.state)}</small>
              </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(SchoolsTable);