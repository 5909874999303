import React, {Component} from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import { Col } from 'react-bootstrap'
import { Button } from 'primereact/button'
import { Redirect } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import * as auth from '../../modulesAdmin/Auth/_redux/authRedux';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'

const url = ServerURL()

class AssignGroups extends Component {

    emptyGroup = {
        group_name: ''
    }

    constructor(props) {
        super(props)
        this.state = {

            id_cs: null,
            group: this.emptyGroup,
            group_name: '',
            groups: null,
            groups_customer: null,

            groupDialog: false,
            deleteGroupDialog: false,
            selectedGroups: null,

            ids: null,
            globalFilter: null,

            redirect: false
        }

        // this.actionBodyTemplate = this.actionBodyTemplate.bind(this)
        this.confirmaAssignSelected = this.confirmaAssignSelected.bind(this)
        this.assignSelectedGroups = this.assignSelectedGroups.bind(this)
        this.openNew = this.openNew.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onHideD = this.onHideD.bind(this)

        this.changePage = this.changePage.bind(this)
    }

    confirmaAssignSelected() {
        this.setState({
            groupDialog: true,
        })
    }

    openNew() {
        this.setState({
            groupDialog: true, 
            group: this.emptyGroup
        })
    }

    onHide() {
        this.setState({
            groupDialog: false,
            group_name: ''
        })
    }

    onHideD() {
        this.setState({
            deleteGroupDialog: false,
        })
    }

    onDelete(group) {
        console.log("Group: ", group);
        this.setState({
            group,
            deleteGroupDialog: true
        })
    }

    componentDidMount(){
        this.getGroups()
        this.getGroupsCustomer()
    }
    
    changePage(){
        this.setState({ redirect: true })
    }

    assignSelectedGroups() {
        let data = this.state.groups.filter(val => this.state.selectedGroups.includes(val))
        let id_cs = this.props.dataCustomerSuccess.id

        // for (let i = 0; i < data.length; i++){
        //     data[i].id_cs = this.state.selectedCustomer
        // }

        try {
            console.log("Data: ", data);
            let res = axios.put(`${url}/api/public/assign_groups_customer`, {data, id_cs})
            .then(res => {
                this.getGroups()
                this.getGroupsCustomer()
            })
            if(!res.error){
                this.setState({
                    groupDialog: false,
                    selectedGroups: null,
                })
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Students assigned', life: 3000})
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    }

    deleteGroup = async () => {
        let data = {...this.state.group}

        try {
            await axios.delete(`${url}/api/public/wiyu_groups/${data.id}`)
            .then(res => {
            this.getGroups() 
            if(!res.error){
                this.setState({
                data,
                deleteGroupDialog: false,
                group: this.emptyGroup
                })
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Group deleted', life: 3000 });
            }
            })

        } catch (error) {
            
        }
    }

    getGroups = async () => {
        try {
            axios.get(`${url}/api/public/wiyu_groups`)
            .then(res => {
                const groups = res.data;
                this.setState({ groups })
                console.log("groups: ", groups);
            })
        } catch (error) {
            
        }
    }

    getGroupsCustomer = async => {
        try {
            axios.get(`${url}/api/public/assign_groups_customer/${this.props.dataCustomerSuccess.id}`)
            .then(res => {
                const groups_customer = res.data
                this.setState({ groups_customer })
                console.log("Groups customer: ", groups_customer);

            })
        } catch (error) {
            console.log(error);
        }
    }

    actionBodyTemplate(rowData){
        return(
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.changePage(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onDelete(rowData)} />
            </>
        )
    }

    render(){

        if(this.state.redirect){
            return <Redirect to={{
                pathname: '/wiyu-customer-success'
            }} />
        }

        const footer =(
            <>
                <FormattedMessage id="GENERAL.CANCEL">
                    {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.onHide} />}
                </FormattedMessage>
                <FormattedMessage id="GENERAL.SAVE" >
                    {(message) => <Button label={message} icon="pi pi-check" className="p-button-check" onClick={this.saveGroup} />}
                </FormattedMessage>
            </>
        );
        
        const deleteFooter =(
            <>
                <FormattedMessage id="GENERAL.CANCEL">
                    {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.onHideD} />}
                </FormattedMessage>
                <FormattedMessage id="GENERAL.SAVE" >
                    {(message) => <Button label={message} icon="pi pi-check" className="p-button-check" onClick={this.deleteGroup} />}
                </FormattedMessage>
            </>
        ); 


        return(
            <div>
                <Toast ref={(el) => this.toast = el} />


                <FormattedMessage id='GENERAL_MESSAGE.COMPLETE_INFORMATION'>
                    {
                        (message) => (
                            <Dialog header={message} footer={footer} visible={this.state.groupDialog} onHide={this.onHide} style={{width: "30%"}}>
                                <div className="p-grid" style={{marginTop: "1em"}}>
                                    <div className="p-col">
                                        <span className="p-float-label">
                                        <InputText id="group_name" value={this.state.group_name} onChange={(e) => this.setState({group_name: e.target.value})} style={{width:"100%"}}/>
                                        <label htmlFor="title">Group name</label>
                                        </span>
                                    </div>
                                </div>
                            </Dialog>
                        )
                    }
                </FormattedMessage>

                <Dialog header="Delete group" footer={deleteFooter} visible={this.state.deleteGroupDialog} onHide={this.onHideD} style={{width: "30%"}}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.group && <span> Are you sure you want to delete <b>{this.state.group.group_name}</b>?</span>}
                    </div>
                </Dialog>

                <h1>Customer success - {this.props.dataCustomerSuccess.cs_name}</h1>

                <div className='d-flex justify-content-between mb-2'>
                    <Button className='p-button-rounded p-button-danger p-button-outlined' type='button' icon='pi pi-arrow-left' onClick={this.changePage} variant='contained' />
                </div>

                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <h3 className='card-label'>
                        Groups assignment
                        <span className='d-block text-muted pt-2 font-size-sm'>Administration of students groups</span>
                    </h3>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{ marginRight: 10}}>
                            <Button type='button' label='Assign groups' icon='pi pi-plus' variant='contained' color='primary' onClick={this.assignSelectedGroups} />
                        </div>
                    </div>

                    
                    <div>
                        <div className='card'>
                        <DataTable
                        ef={(el) => this.dt = el} value={this.state.groups} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No groups found." loading={this.state.loading}
                        selection={this.state.selectedGroups} onSelectionChange={e => this.setState({ selectedGroups: e.value })} dataKey="id">
                            <Column selectionMode='multiple'></Column>
                            <Column field='group_name' header="Group name"></Column>
                            {/* <Column field='created_by' header="Created by"></Column> */}
                            <Column field='students_assign' header="Students assigned"></Column>
                            {/* <Column body={this.actionBodyTemplate}></Column> */}
                        </DataTable>
                        </div>
                    </div>

                </div>

                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <h3 className='card-label'>
                        List groups assigned
                        <span className='d-block text-muted pt-2 font-size-sm'>Administration of students groups</span>
                    </h3>

                    
                    <div>
                        <div className='card'>
                        <DataTable
                        ef={(el) => this.dt = el} value={this.state.groups_customer} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No groups found." loading={this.state.loading}
                        selection={this.state.selectedStudents} onSelectionChange={e => this.setState({ selectedStudents: e.value })} dataKey="id">
                            {/* <Column selectionMode='multiple'></Column> */}
                            <Column field='group_name' header="Group name"></Column>
                            {/* <Column field='created_by' header="Created by"></Column> */}
                            {/* <Column field='students_assign' header="Students assigned"></Column> */}
                            {/* <Column body={this.actionBodyTemplate}></Column> */}
                        </DataTable>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataCustomerSuccess: state.auth.dataCustomerSuccess
})

export default injectIntl(
    connect(mapStateToProps, auth.actions)(AssignGroups)
)