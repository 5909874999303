import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Button, Chips, ConfirmDialog } from 'primereact'

import axios from 'axios'
import ServerURL from '../../../ServerURL'

import InvoicesQuery from './LegacyScriptEdvisor'


import { Query } from 'react-apollo';

let GET_DATA = gql
    `
    query($externalIds:[Int]) {
        invoiceList(
        pagination: { limit: 100, offset:0 }, 
        filter: {
            agencyIds: [6452],
            externalIds: $externalIds

        }) {
            count
            data {
                invoiceId
                externalId
                createdByUserId
                depositDateDue
                lastUpdatedByUserId
                created
                modified
            
                invoiceType {
                    invoiceTypeId
                    codeName
                }
                invoiceStatus {
                    invoiceStatusId
                    codeName
                }
            
                paymentCurrency {
                    currencyId
                    code
                    symbol
                    oneUsd
                }
                totalAmount

                student{
                    studentId
                    firstname
                    lastname
                    preferredName
                    email
                    agencyId
                    agency {
                    agencyId
                    agencyCompanyId
                    name
                    email
                        agencyCompany{
                        agencyCompanyId
                        name
                        email
                        websiteUrl
                        } 
                    }
                    owner{
                        userId
                        firstname
                        lastname
                        email 
                    }
                }

                rawItems {
                    invoiceItemId
                    invoiceId
                    invoiceItemType{
                        invoiceItemTypeId
                        codeName   
                        displayOrder      
                    }
                    school
                    program
                    isAgencyItem
                    isGlobalFee
                    position
                    durationAmount
                    durationTypeId
                    startDate
                    endDate
                    amount
                    isAmountPerDuration
                    serviceFeeMultiplier
                    currency{
                        currencyId
                        code
                        symbol
                        oneUsd
                    }
                    snapshotExtensionDurationTypeId
                    snapshotFreeDurationAmount
                    snapshotBonusDurationAmount
                    snapshotPromotionApplicableTypeId
                    snapshotPromotionSecondaryTypeId
                    snapshotPromotionPercentage
                    offering{
                        school{
                            name
                        schoolCompany{
                            name
                        }
                        country{
                            code
                            continent
                        }
                    }
                }
                notes
            
                created
                modified
            }
            invoiceFiles {
            fileId
            uploaderUserId
            mimeType
            fileExtension
            name
            path
            url
            created
            modified
            }
        
            } 
        }

        }
`
const url = ServerURL();

export const LegacyInvoices = ({ invoices, externalIds }) => {
    console.log("VENGO DE LEGACY");

    console.log("EXTERNAL IDS: ", externalIds);
    const [getInvoices, result] = useLazyQuery(GET_DATA)

    console.log("RESULT: ", result);

    const [invoice, setInvoice] = useState(null)
    const [visible, setVisible] = useState(true)

    const accept = () => {
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        getInvoices({ variables: { externalIds: [externalIds] } })
        setVisible(false)
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        setVisible(false)

    }

    // const showInvoice = () => {
    // }

    useEffect(() => {

        if (result.data) {
            setInvoice(result.data.invoiceList.data)
            let data = result.data

            let options = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            let requestOne = axios.post(`${url}/api/auth/invoice`, data.invoiceList.data, options);
            let requestTwo = axios.post(`${url}/api/auth/invoiceItem`, data.invoiceList.data, options)
            let requestThree = axios.post(`${url}/api/auth/net_invoice`, data.invoiceList.data, options)
            let requestFour = axios.post(`${url}/api/auth/invoiceNetItems`, data.invoiceList.data, options);
            // let requestFour = axios.post(`${url}/api/public/students-edvisor`, data.invoiceList.data, options);

            console.log("requestThree: ", requestThree);

            axios
                .all([
                    requestOne,
                    requestTwo,
                    requestThree,
                    requestFour
                ])
                .then(
                    axios.spread((...responses) => {
                        const invoice = responses[0].data;
                        const invoiceItems = responses[1].data
                        const net_invoice = responses[2].data;
                        const invoiceNetItems = responses[3].data
                        // const student = responses[0].data  

                        console.log(invoice)
                        console.log(net_invoice)
                        console.log(invoiceItems);
                        console.log(invoiceNetItems);
                        // console.log(student);

                    }))
                .catch(errors => {
                    console.error(errors);
                });

            // console.log("DATA:", externalIds)
        }

    }, [result])

    if (invoice) {
        console.log('RESULT: ', invoice[0].externalId);
        console.log('RESULT: ', result.loading);

        return (
            <div>
                {result.loading ?
                    <div>
                        <h2>Loading...</h2>
                    </div>
                    :
                    <div>
                        <h3>
                            Invoice {invoice[0].externalId} downloaded
                        </h3>
                    </div>
                }
            </div>
        )
    }

    // if (invoices == null) {
    //     // console.log("INVOICES: ", invoices.data);

    //     return <div>SIN INFO</div>
    // }

    return (
        <div>

            <div>
                <ConfirmDialog visible={visible} accept={accept} reject={reject} message={`Are you sure to get this invoices: ${externalIds}`} header='Confirmation' />
            </div>

        </div>
    )

}

export default LegacyInvoices;