import Axios from "axios";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from "../../../ServerURL";

// Componentes
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";

// Moment
import moment from "moment";
import { Redirect } from "react-router-dom";
import { getGeneralTextTranslate, getTextCampusTranslate, getTextPaginatorTranslate } from "../../../_metronic/i18n/TextTranslate";


const url = ServerURL()

class NotificationTable extends Component {

    emptyNotification = {
        notification: '',
        description: '',
        execute_date: ''
    }

    constructor(props){
        super(props)

        this.state = {
            name: '',
            description: '',
            execute_date: '',

            notification: this.emptyNotification,
            notifications: null,

            dialog: null,
            deleteDialog: null,
            deleteSelectedDialog: null,

            ids: null,
            globalFilter: null,

            selectedNotification: null,

            campusText: getTextCampusTranslate(),
            paginatorText: getTextPaginatorTranslate(),
            textTranslate: getGeneralTextTranslate(),

            submitted: false,

            selectedNotifications: null,

            redirect: false,
        }

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.saveNotification = this.saveNotification.bind(this);
        this.deleteNotification = this.deleteNotification.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);

        this.openNew = this.openNew.bind(this);
        this.editNotification = this.editNotification.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);

        this.hideDialog = this.hideDialog.bind(this);
        this.hideDialogDelete = this.hideDialogDelete.bind(this);
        this.hideDialogDeleteSelected = this.hideDialogDeleteSelected.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);

        this.changePage = this.changePage.bind(this);
    }

    componentDidMount() {
        this.getNotifications()
        // this.dates()
    }

    getNotifications = async () => {
        try {
            await Axios.get(`${url}/api/public/studentnotification/${this.props.dataStudent.id}`)
            .then(res => {
                const notifications = res.data
                this.ExecuteDate(notifications)
                this.NotificationRead(notifications)
                this.VisibleNotification(notifications)
                this.setState({ notifications })
                console.log("Notification: ", notifications);
            })
        } catch (error) {
            console.log(error);
        }
    }

    // dates() {
    //     let utc = moment.utc(today).format('YYYY-MM-DD')

    //     console.log("Fecha de hoy: ", utc);
    // }

    

    ExecuteDate(e){
        e.map((notification) => {
            var origin = notification.execute_date
            var date = moment.utc(origin).format("DD/MM/YYYY")

            notification.execute_date = date

            return notification
        })
    }

    NotificationRead(e){
        e.map((notification) => {
            var origin = notification.read_notification

            if(origin === 0){
                notification.read_notification = 'No'
            } else {
                notification.read_notification = 'Yes'
            }

            return notification
        })
    }

    VisibleNotification(e){
        e.map((notification) => {
            var origin = notification.visible_notification

            if(origin === 0){
                notification.visible_notification = 'No'
            } else {
                notification.visible_notification = 'Yes'
            }

            return notification
        })
    }

    changePage() {
        this.setState({ redirect: true })
    }

    onInputChange(e, name){
        const val = (e.target && e.target.value ) || '';
        let notification = {...this.state.notification}
        notification[`${name}`] = val 
        this.setState({ notification })
    }

    onDateChange(e, name) {
        const val = moment.utc(e.target.value).format('YYYY-MM-DD') || ''
        let notification = {...this.state.notification}

        notification[`execute_date`] = val

        this.setState({ notification })
    }

    async saveNotification() {
        let state = { submitted: true }
        let data = {
            ...this.state.notification,
            id_student: this.props.dataStudent.id,
            // execute_date: moment.utc(this.state.notification.execute_date).format('YYYY-MM-DD')
        }

        console.log("Data: ", data);

        if (this.state.notification.id) {
            try {
                await Axios.put(`${url}/api/public/notification-update/${data.id}`, data)
                .then(res => {
                    this.getNotifications()
                    // console.log("Resultado: ", res);
                    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Notification updated', life: 3000})
                })
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                await Axios.post(`${url}/api/public/notification-create`, data)
                .then(res => {
                    this.getNotifications()
                    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Notification updated', life: 3000})
                    console.log("Resultado: ", res);
                })            
            } catch (error) {
                console.log(error);
            }
        }

        state = {
            ...state,
            data,
            dialog: false,
            notification: this.emptyNotification,
        }

        this.setState(state)
    }



    deleteNotification(){
        let data = {...this.state.notification}
        try {
            Axios.delete(`${url}/api/public/notification-delete/${data.id}`)
            .then(res =>{
                this.getNotifications()
                
                if(!res.error) {
                    this.setState({
                        data,
                        deleteDialog: false,
                        notification: this.emptyNotification
                    })

                    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Event Deleted', life: 3000 });

                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    deleteSelected(){
        let notifications = this.state.notifications.filter(val => !this.state.selectedNotifications.includes(val))
        let data = this.state.notifications.filter(val => this.state.selectedNotifications.includes(val))

        try {
            console.log("Notifications: ", notifications );
            console.log("Data: ", data);
            Axios.delete(`${url}/api/public/notification-delete-all`, {data})
            .then(res => {
                this.getNotifications()
                this.setState({
                    notifications,
                    deleteSelectedDialog: false,
                    selectedNotifications: null,
                })
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Notifications deleted', life: 3000})    
            })
        } catch (error) {
            console.log(error);
        }
    }

    deleteEvent(){
        this.setState({
            deleteDialog: false,
            notification: this.emptyNotification
        })
    }

    openNew() {
        this.setState({
            notification: this.emptyNotification,
            submitted: false,
            dialog: true,
        })
    }

    hideDialog() {
        this.setState({
            submitted: false,
            dialog: false
        })
    }

    editNotification(notification) {
        this.setState({
            notification: {...notification},
            dialog: true,
        })
        console.log(notification);
    }

    confirmDelete(notification) {
        this.setState({
            notification,
            deleteDialog: true
        })
    }

    confirmDeleteSelected(){
        this.setState({
            deleteSelectedDialog: true
        })
    }

    hideDialogDelete(){
        this.setState({
            deleteDialog: false
        })
    }

    hideDialogDeleteSelected(){
        this.setState({
            deleteSelectedDialog: false
        })
    }

    actionBodyTemplate(rowData){
        return (
            <>
                <Button icon="pi pi-pencil" color="primary" style={{ verticalAlign: 'middle', margin: '10px', width: '30px', height: '30px'}} onClick={() => this.editNotification(rowData)} />
                <Button icon='pi pi-trash' className='p-button-danger' style={{verticalAlign: 'middle', margin: '10px', width: '30px', height: '30px'}} onClick={() => this.confirmDelete(rowData)} />

            </>
        )
    }

    render(){

        if(this.state.redirect){
            return <Redirect to={{
                pathname: '/wiyu-students'
            }} />
        }

        const footer = (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" onClick={this.saveNotification} />
            </div>
        )

        const deleteFooter = (
            <div>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogDelete} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteNotification} />
            </div>
        )

        const deleteSelectedFooter = (
            <div>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogDeleteSelected } />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelected} />
            </div>
        )

        return(
            <div>

                <div className="card-header flex-wrap border-0 pt-6 pb-0">
                    
                    {/* Create & update notifications */}
                    <Dialog header='Complete the information' footer={footer} visible={this.state.dialog} contentStyle={{ maxHeight: '800px', width:"770px", height:"430px", overflow:"auto"}} onClick={this.openNew} onHide={this.hideDialog} >
                        <div className='form-group row'>
                            <div className='col-lg-6'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-bell'></i>
                                    </span>
                                    {/* First name */}
                                    <span className='p-float-label'>
                                        <InputText
                                            // placeholder='First name'
                                            style={{width: 190, height: 33}}
                                            id="notification"
                                            variant="outlined"
                                            margin="dense"
                                            value={this.state.notification.notification}
                                            onChange={(e) => this.onInputChange(e, 'notification')}
                                            // required autoFocus
                                            // className={classNames({'p-invalid': this.state.submitted && !this.state.fullname_parents})}
                                        />
                                        <label htmlFor='fullname_parents'>Notification name</label>
                                    </span>
                                    {/* {
                                        this.state.first_name_parents && (
                                            <small id="first_name_parents-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.first_name_parents}/></small>
                                        )
                                    } */}
                                </div>
                                <small className='p-d-block' id='first_name_parents-help'>Enter first name and last name of the parent</small>
                            </div>

                            <div className="col-lg-6" >
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-calendar"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <Calendar
                                        style={{width: 190, height: 33}}
                                        id="execute_date"
                                        value={this.state.notification.execute_date}
                                        onChange={(e) => this.onDateChange(e, 'execute_date')}
                                        dateFormat='yy-mm-dd'
                                        monthNavigator
                                        yearNavigator
                                        yearRange="2019:2030"
                                        // maxDate={this.maxDate}
                                        minDate={new Date()}
                                        // disabledDates={this.dates}
                                        // disabledDays={[0,1,2,3,4,5,6]} 
                                        />
                                        <label htmlFor="execute_date">Execute date</label>
                                    </span>
                                </div>
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-lg-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-bell"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputTextarea
                                        // style={{ width: 109, height: 33}}
                                        id='description'
                                        variant='outlined'
                                        margin='dense'
                                        autoResize
                                        value={this.state.notification.description}
                                        onChange={(e) => this.onInputChange(e, 'description')} />
                                        <label htmlFor="description-help">Description</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    {/* Delete action body template */}
                    <Dialog visible={this.state.deleteDialog} style={{ width: '450px'}} header='Confirm' modal footer={deleteFooter} onHide={this.hideDialogDelete} >
                        <div>
                            {this.state.notification && <span>Are you sure want to delete this notification: <b>{this.state.notification.notification}</b></span>}
                        </div>
                    </Dialog>

                    {/* Delete with tool bar */}
                    <Dialog visible={this.state.deleteSelectedDialog} style={{ width: '450px'}} header='Confirm' modal footer={deleteSelectedFooter} onHide={this.hideDialogDeleteSelected} >
                        <div>
                            {/* <h1>Eliminando varias notificaciones</h1> */}
                            {this.state.notification && <span>Are you sure want to delete the selected events types?</span>}
                        </div>
                    </Dialog>
                    
                    <Toast ref={(el) => this.toast = el} />

                    <div className='d-flex justify-content-between mb-2'>
                        <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" icon="pi pi-arrow-left" onClick={this.changePage} variant='contained'/>
                    </div>
                    <h3>
                        Notifications table
                        <span className="d-block text-muted pt-2 font-size-sm">Notification of your students</span>
                    </h3>

                    {/* Tool bar */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{ marginRight: 10}}>
                            <FormattedMessage id="GENERAL.DELETE">
                                {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedNotifications || !this.state.selectedNotifications.length} />}
                            </FormattedMessage>
                        </div>

                        <div style={{ marginRight: 10}}>
                            <Button label='New notification' icon="pi pi-plus" onClick={this.openNew} />
                        </div>
                    </div>

                    <div>
                        <div className="card">
                            <DataTable
                            ef={(el) => this.dt = el} value={this.state.notifications} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                            globalFilter={this.state.globalFilter} emptyMessage="No students found." loading={this.state.loading}
                            selection={this.state.selectedNotifications} onSelectionChange={e => this.setState({ selectedNotifications: e.value })} dataKey="id">
                                <Column selectionMode="multiple" headerStyle={{ width: '3em'}}></Column>
                                <Column field="notification" header="Notification" sortable></Column>
                                <Column field="description" header="Description" sortable></Column>
                                <Column field="execute_date" header="Execute date" sortable></Column>
                                <Column field="visible_notification" header="Notification visible" sortable></Column>
                                <Column field="read_notification" header="Notification read" sortable></Column>
                                <Column body={this.actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataStudent: state.auth.dataStudent
})

export default injectIntl(
    connect(mapStateToProps, auth.actions)(NotificationTable)
)