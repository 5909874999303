import React from "react";
// import {useSubheader} from "../../_metronic/_core";

import InvoicesTable from "../modulesAccounting/Invoices/InvoicesTable"

export const InvoicesPage = () => {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("Invoices");

  return (<><InvoicesTable /></>);
};