// CustomerSuccessItems
    import React, {Component} from 'react'
    import classNames from 'classnames';
    import { withRouter } from "react-router";
    import axios from 'axios'
    import ServerURL from '../../../ServerURL'
    import { Redirect } from 'react-router-dom';

    import AddIcon from '@material-ui/icons/Add';
    import Fab from '@material-ui/core/Fab';
    import Tooltip from '@material-ui/core/Tooltip';

    import { FormattedMessage, injectIntl } from "react-intl";
    import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

    import { DataTable } from 'primereact/datatable';
    import { Toast } from 'primereact/toast';
    import { Column } from 'primereact/column';
    import { Button } from 'primereact/button';
    import { InputText } from 'primereact/inputtext';
    import { Dialog } from 'primereact/dialog';
    import { Dropdown } from 'primereact/dropdown';
    import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
    import { InputNumber } from 'primereact/inputnumber';
    import {InputSwitch} from 'primereact/inputswitch';
    import { Calendar } from 'primereact/calendar';
    import { InputTextarea } from 'primereact/inputtextarea';


    import moment from 'moment';

    import 'primereact/resources/themes/saga-blue/theme.css';
    import 'primereact/resources/primereact.min.css';
    import 'primeicons/primeicons.css';

    import * as auth from '../../modulesAdmin/Auth/_redux/authRedux';
    import { connect } from 'react-redux';

    const url = ServerURL();

    class CustomerSuccessItems extends Component {

          emptyItem = {
            id_event_type: '',
            id_date_type: '',
            // id_language: '',
            specific_date: '',
            item_name: '',
            description: '',
            guia: '',
            parent_or_student: '',
            rango: '',

          }

          constructor (props) {
            super(props);
        
            this.state= {

              id_event_type: '',
              id_date_type: '',
              // id_language: '',
              name: '',
              description: '',
              guia: '',
              parent_or_student: '',
              rango: '',
              specific_date: '',
        
              item: this.emptyItem,
              items: null,
              events: null,
        
              ids: null,
              globalfilter: null,

              selectedItems: null,
              itemDialog: null,
        
              selectedEvents: null,
              eventDialog: null,

              assignEventsDialog: null,
        
              selectedEventType: null,
              resetEventType: null,

              deleteItemDialog: false,
              deleteItemsDialog: false,
        
              submitted: false,
        
              campusText: getTextCampusTranslate(),
              paginatorText: getTextPaginatorTranslate(),
              textTranslate: getGeneralTextTranslate(),
        
              languageMessage: '',
              selectedLanguage: null,
              resetLanguage: null,
        
              fk_id_date_typeMessage: '',
              rangoMessage: '',
              // datesMessage: '',
              
              selectedDateType: null,
              resetDateType: null,
        
              redirect: false,
              changeToTemplate: false,
              
              checked1: false,
        
              options: '',
            }
        
            this.languages = [
              {language: 'Español'},
              {language: 'English'},
              {language: 'Français'},
              {language: 'Deutsch'}
            ]
        
            // this.dates = [
            //   {name: 'Accommodation start date', value: 1},
            //   {name: 'Accommodation end date', value: 2},
            //   {name: 'Insurance start date', value: 3},
            //   {name: 'Insurance end date', value: 4},
            //   {name: 'Program start date', value: 5},
            //   {name: 'Program end date', value: 6},
            //   {name: 'Flight start date', value: 7},
            //   {name: 'Flight end date', value: 8},
            // ]
        
            this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
            this.openNew = this.openNew.bind(this);
        
            this.confirmAssignEventSelected = this.confirmAssignEventSelected.bind(this);
            this.assignSelectedEvents = this.assignSelectedEvents.bind(this);
            this.confirmDeleteItems = this.confirmDeleteItems.bind(this);
        
            this.hideAssignEventsDialog = this.hideAssignEventsDialog.bind(this);
            this.hideDialog = this.hideDialog.bind(this);
            this.hideDeleteItemDialog = this.hideDeleteItemDialog.bind(this);
            this.hideDeleteItemsDialog = this.hideDeleteItemsDialog.bind(this);

            this.saveItem = this.saveItem.bind(this);
            this.editItem = this.editItem.bind(this);
            this.deleteItem = this.deleteItem.bind(this);
            this.deleteItems = this.deleteItems.bind(this);

            this.onInputChange = this.onInputChange.bind(this);
            this.onEventTypeChange = this.onEventTypeChange.bind(this);
            // this.onLanguageChange = this.onLanguageChange.bind(this);
            this.onDateTypeChange = this.onDateTypeChange.bind(this);
            this.changePage = this.changePage.bind(this);
            this.onDateChange = this.onDateChange.bind(this)
        
            // this.onItemChange = this.onItemChange.bind(this);
        
            // this.onLazyItemChange = this.onLazyItemChange.bind(this);
            // this.onLazyLoad = this.onLazyLoad.bind(this);
          }
        
          componentDidMount() {
            this.getEvents()
            this.getItems()
          }
        
          onClick(name, idEventSelect){
            let state = {
              [`${name}`]: true,
              idEventSelect
            };
            this.setState(state)
          }

          onInputChange(e, name){
            const val = (e.target && e.target.value) || '';
            let item = {...this.state.item}
            item[`${name}`] = val

            this.setState({item})
          }

          onEventTypeChange(e) {
            let item = {...this.state.item}
            item['id_event_type'] = e.value;
            this.setState({ selectedEventType: e.value, item})
          }

          onDateChange(e, name){
            const val = (e.target && e.target.value) || ''
            let item = {...this.state.item}
            item[`${name}`] = val
            this.setState({ item })
          }

          // onLanguageChange(e) {
          //   let item = {...this.state.item}
          //   item['id_language'] = e.value
          //   this.setState({ selectedLanguage: e.value, item})
          // }

          onDateTypeChange(e) {
            let item = {...this.state.item}
            item['id_date_type'] = e.value
            this.setState({ selectedDateType: e.value, item})
          }

          getEvents = async () => {
            try {
                axios.get(`${url}/api/auth/events`)
                .then(res => {
                  const events  = res.data;            
                  this.setState({ events })
                  console.log("Events: ", events);
                });                     
                  // console.log(res.data)
                }catch(error){
                  
                }
        }
        
        getEventTypes = async () =>{  
          try { 
              axios.get(`${url}/api/auth/event_types`)
              .then(res => {
                const event_types= res.data;
                this.setState({ event_types })
                console.log(res.data);
              });                     
              }catch(error){
          }
        }
        
        getDateTypes = async () => {
          try {
            await axios.get(`${url}/api/public/date_types`)
            .then(res => {
              const date_types = res.data
              this.setState({ date_types })
              console.log(res.data);
            });
            // console.log(res.data);
          } catch (error) {
            console.log(error);
          }
        }
        
        getLanguages = async () => {
          try {
            await axios.get(`${url}/api/public/languages`)
            .then(res => {
              const languages = res.data
              this.setState({ languages })
              console.log(res.data);
            })
          } catch (error) {
            console.log(error);
          }
        }

        getItems = async () => {
          try {
            await axios.get(`${url}/api/public/wiyu_customer_success_items/${this.props.dataCustomerSuccess.id}`)
            .then(res => {
              const items = res.data
              this.setState({ items })
              console.log(res.data);
            })
          } catch (error) {
            console.log(error);
          }
        }

          saveItem() {
            let state = { submitted: true }

            let data = {...this.state.item}
            console.log("DATA: ", data);

            if (this.state.item.id){
              console.log("Id item: ", data.id);
              try {
                let res = axios.put(`${url}/api/public/edit_item/${data.id}`, data)
                .then(res => {
                  this.getItems()
                  console.log(res);
                })
                if (!res.error) {
                  this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Item Updated', life: 3000 });
                }
              } catch (error) {
                console.log(error);
              }
            } else {
              try {
                let res = axios.post(`${url}/api/public/item`, data)
                .then(res => {
                  this.getItems()
                })
                if(!res.erro){
                  this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Item created', life: 3000 });
                }
              } catch (error) {
                console.log(error);
              }
            }

            state = {
              ...state,
              data,
              itemDialog: false,
              item: this.emptyItem,
              selectedEventType: null,
              selectedDateType: null,
              selectedLanguage: null,
              resetEventType: null,
              resetLanguage: null,
              resetDateType: null
            }
            this.setState(state)
          }
        

        
          assignSelectedEvents() {
            // let events = this.state.events.filter(val => !this.state.selectedEvents.includes(val));
            let data = this.state.events.filter(val => this.state.selectedEvents.includes(val));
            let id_cs = this.props.dataCustomerSuccess.id
        
            try {
              // console.log("Events: ", events);
              console.log("Data: ", data);
              let res = axios.put(`${url}/api/public/assign_items`, {data, id_cs})
              .then(res => {
              this.getEvents()
              this.getItems()
            })
            if (!res.error) {
              this.setState({
                assignEventsDialog: false,
                selectedEvents: null
            });
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Events Deleted', life: 3000 });
            }
              } catch(error){
                console.log(error);
              } 
        }

        deleteItem() {
          let data = {...this.state.item}

          try {
            let res = axios.delete(`${url}/api/public/delete_item/${data.id}`)
            .then(res => {
              this.getItems()
            })
            if(!res.error) {
              this.setState({
                data,
                deleteItemDialog: false,
                item: this.emptyItem
              });
              this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Item deleted', life: 3000 });
            }
          } catch (error) {
            
          }
        }

        deleteItems() {
          let items = this.state.items.filter(val => !this.state.selectedItems.includes(val))
          let data = this.state.items.filter(val => this.state.selectedItems.includes(val))

          try {
            console.log("Items: ", items);
            console.log("Data: ", data);

            let res = axios.delete(`${url}/api/public/delete_items`, {data})
            .then(res => {
              this.getItems()
            })

            if(!res.error){
              this.setState({
                items,
                deleteItemsDialog: false, 
                selectedItems: null,
              })
              this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Items deleted', life: 3000 });
            }
          } catch (error) {
            console.log("Error:", error);
          }
        }

        openNew(){
          this.getEventTypes()
          this.getDateTypes()
          this.getLanguages()
          this.setState({
            item: this.emptyItem,
            submitted: false,
            itemDialog: true,
            selectedEventType: null,
            selectedDateType: null,
            // selectedLanguage: null,
            resetEventType: 'Event type',
            // resetLanguage: 'Language',
            resetDateType: 'Reference date'
          })
        }

        editItem(item){
          console.log(item);
          this.getEventTypes()
          this.getDateTypes()
          this.getLanguages()
          this.setState({
            item: {...item},
            selectedEventType: null,
            // selectedLanguage: null,
            selectedDateType: null,
            resetEventType: null,
            // resetLanguage: null,
            resetDateType: null,
            itemDialog: true
          })
        }

          hideDialog() {
            this.setState({
                submitted: false,
                itemDialog: false,
                event_typeMessage: '',
                eventMessage:'',
                descriptionMessage: '',
                // languageMessage: ''
          })  
          }

        confirmAssignEventSelected() {
          this.setState({ assignEventsDialog: true })
        }

        confirmDeleteItem(item){
          console.log(item);
          this.setState({
            item,
            deleteItemDialog: true
          })
        }

        confirmDeleteItems() {
          this.setState({ deleteItemsDialog: true })
        }
        
          hideDeleteEventDialog() {
            this.setState({ deleteEventDialog: false })
          }
            
          hideAssignEventsDialog() {
            this.setState({ assignEventsDialog: false });
          }

          hideDeleteItemDialog() {
            this.setState({ deleteItemDialog: false})
          }

          hideDeleteItemsDialog() {
            this.setState({ deleteItemsDialog: false })
          }

        changePage(){
          this.setState({redirect: true})
        }

        actionBodyTemplate(rowData){
          return(
            <>
              <Button icon='pi pi-pencil' color='primary' style={{ verticalAlign: 'middle', margin: '10px', width: '30px', height:'30px'}} onClick={() => this.editItem(rowData)} />
              <Button icon='pi pi-trash' className='p-button-danger' style={{verticalAlign: 'middle', margin: '10px', width: '30px', height:'30px'}} onClick={() => this.confirmDeleteItem(rowData)} /> 
            </>
          )
        }
        
          renderHeader(){
            return (
                <span>
                  <Button color='primary' style={{ width: "30px", height: "30px"}}>Nombre</Button>
                </span>
            )
          }
        
          render(){

            if(this.state.redirect) {
              return <Redirect to={{
                pathname: '/wiyu-customer-success'
              }} />
            }

            const assignEventsDialogFooter = (
              <>
                  <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideAssignEventsDialog } />
                  <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.assignSelectedEvents} />
              </>
            );

            const itemDialogFooter = (
              <>
                <FormattedMessage id="GENERAL.CANCEL">
                  {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
                </FormattedMessage>
                <FormattedMessage id="GENERAL.SAVE">
                  {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveItem} />}
                </FormattedMessage>
              </>
            )

            const deleteItemDialogFooter = (
              <>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteItemDialog } />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteItem} />
              </>
            )

            const deleteItemsDialogFooter = (
              <>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteItemsDialog } />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteItems} />
              </>
            )
        
            // const header = this.renderHeader()
        
            return(
              <div>
                {/* Assign WITH TOOL BAR */}
                <Dialog visible={this.state.assignEventsDialog} style={{ width: '450px' }} header="Confirm" modal footer={assignEventsDialogFooter} onHide={this.hideAssignEventsDialog}>
                  <div className="confirmation-content">
                      <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                      {this.state.events && <span>Are you sure you want to assign the selected events?</span>}
                  </div>
                </Dialog>

                {/* Delete with tool bar */}
                <Dialog visible={this.state.deleteItemsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={this.hideDeleteItemsDialog}>
                  <div className="confirmation-content">
                      <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                      {this.state.events && <span>Are you sure you want to assign the selected events?</span>}
                  </div>
                </Dialog>

                {/* Create & edit item dialog */}
                <Dialog visible={this.state.itemDialog} contentStyle={{ maxHeight: '800px', width:"770px", height:"430px", overflow:"auto"}} header="New event" modal footer={itemDialogFooter} onHide={this.hideDialog} >
                  <h1>Edit items</h1>
                  <br></br>
                  <div className="form-group row">
                  {/* Event Type */}
                  <div className="col-lg-6">
                      <div className='p-inputgroup'>
                        <span className='p-inputgroup-addon'>
                          <i className='pi pi-tag'></i>
                        </span>      
                        <span className="p-float-label">          
                          <Dropdown
                              style={{ width: 250, height:33 }}
                              id="event_type"
                              variant="outlined"
                              placeholder= { this.state.resetEventType || this.state.item.event_type}
                              margin="dense"
                              value={this.state.selectedEventType || "event_type"}   
                              options ={this.state.event_types} 
                              onChange={this.onEventTypeChange} 
                              optionLabel="event_type" 
                              optionValue="id"
                              />
                            <label htmlFor="event_type">Event Type</label>
                          </span>
                      </div>
                      <small id='event_type-help' className='p-d-block'>Choose the event type</small>    
                      {/* <small id="event_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFK_Id_Event_Type()}</small> */}
                    </div>
                  {/* Event */}
                    <div className="col-lg-6">
                      <div className='p-inputgroup'>
                        <span className='p-inputgroup-addon'>
                          <i className='pi pi-tag'></i>
                        </span>
                        <span className="p-float-label">
                          <InputText
                          id="item_name"
                          variant="outlined"
                          type="item_name"
                          margin="dense"
                          value={this.state.item.item_name}             
                          onChange={(e) => this.onInputChange(e, 'item_name')}
                          // required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.event.event  })}
                          />
                          <label htmlFor="event">Event</label>
                        </span>
                        {/* <small id="event-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEvent(this.state)}</small>  */}
                      </div>
                      <small id='event-help' className='p-d-block'>Enter event name for your notifications</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    {/* Idioma */}
                    {/* <div className="col-lg-6">      
                      <div className='p-inputgroup'>
                        <span className='p-inputgroup-addon'>
                          < i className='pi pi-globe'></i>
                        </span>
                        <span className="p-float-label">   
                            <Dropdown
                              style={{ width: 250, height: 33 }}
                              id='language'
                              variant='outlined'
                              placeholder={ this.state.resetLanguage || this.state.item.language}
                              margin='dense'
                              value={this.state.selectedLanguage || "language" }
                              options={this.state.languages}
                              onChange={this.onLanguageChange}
                              optionLabel='language'
                              optionValue='id'
                            />
                            <label htmlFor="language">Language</label>     
                        </span>
                      </div>
                      <small className='p-d-block'>Enter the language for your notifications</small>
                    </div> */}
                  <br/>
                  <br/>
                  </div>

                  {/* <div className='col-lg-6' >
                    <h5>Do you prefer a specific date?</h5>
                    <InputSwitch checked={this.state.checked1} onChange={(e) => this.setState({checked1: e.value})} />
                  </div> */}

                  {/* <br/> */}
                  {/* { */}
                    {/* this.state.checked1 === true ? */}
                    {/* <div className='form-group row'>
                      <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                          <span className='p-inputgroup-addon'>
                            <i className='pi pi-calendar'></i>
                          </span>
                          <span className='p-float-label'>
                            <Calendar 
                              id='basic'
                              name='flight_start_date'
                              value={this.state.flight_start_date}
                              // value={this.props.dataStudent.program_start_date}
                              onChange={(e) => this.setState({ flight_start_date: e.value})}
                              dateFormat='yy-mm-dd'
                              // readOnlyInput
                              placeholder= "Enter your specific date"
                            />
                          </span>

                        </div>
                      </div>
                    </div> */}
                  {/* : */}
                  <div className='form-group row'>
                    {/* Fecha base */}
                    <div className='col-lg-6'>
                      <div className='p-inputgroup'>
                        <span className='p-inputgroup-addon'>
                          <i className='pi pi-calendar'></i>
                        </span>
                        <span className='p-float-label'>
                          <Dropdown
                            style={{ width: 250, height: 33 }}
                            id='date_type'
                            variant='outlined'
                            placeholder={ this.state.resetDateType || this.state.item.date_type}
                            margin='dense'
                            value={this.state.selectedDateType || "date_type" }
                            options={this.state.date_types}
                            onChange={this.onDateTypeChange}
                            optionLabel='date_type'
                            optionValue='id'
                          />
                          <label htmlFor='date_type'>Execute date</label>
                        </span>
                      </div>
                      <small className='p-d-block'>Enter the base date, for execute your notifications</small>
                    </div>

                    {/* Dias para ejecutar */}
                    {
                      this.state.selectedDateType === 9 ?
                      <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                          <span className='p-inputgroup-addon'>
                            <i className='pi pi-calendar'></i>
                          </span>
                          <span className='p-float-label'>
                            <Calendar 
                              id='specific_date'
                              name='specific_date'
                              value={this.state.item.specific_date}
                              // value={this.props.dataStudent.program_start_date}
                              onChange={(e) => this.onInputChange(e, 'specific_date')}
                              dateFormat='yy-mm-dd'
                              // readOnlyInput
                              placeholder= "Enter your specific date"
                            />
                          </span>

                        </div>
                      </div>
                    :
                      <div className='col-lg-6'>
                        <div className='p-inputgroup'>
                          {/* <span className='p-inputgroup-addon'>
                            <i className='pi pi-calendar'></i>
                          </span> */}
                          <span>
                            <InputNumber id="horizontal" value={this.state.item.rango} onValueChange={(e) => this.onInputChange(e, 'rango')} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal"/>
                          </span>
                        </div>
                        <small className='p-d-block'>Enter the days for execute your notification</small>
                      </div>
                    }

                    {/* <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="horizontal">Horizontal with Step</label>
                        <InputNumber id="horizontal" value={this.state.value18} onValueChange={(e) => this.setState({value18: e.value})} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal"/>
                    
                    </div> */}
                    </div>      

                    <div className='form-group row'>                  
                      <div className="col-lg-6">
                        <div className='p-inputgroup'>
                          <span className='p-float-label' >
                            <InputTextarea 
                            style={{ width: 1000, height: 80}}
                            id='description'
                            variant='outlined'
                            margin='dense'
                            value={this.state.item.description} 
                            onChange={(e) => this.onInputChange(e, 'description')}
                            // required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.event.description})}
                            autoResize/>
                            <label htmlFor="description">Descripción</label>
                            {/* <small id="description-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription(this.state)}</small>     */}
                          </span>
                        </div>
                  </div>

                  {/* <div className='col-lg-3'>
                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onClearDescription('')} >Limpiar descripción</Button>
                    </span>
                  </div> */}
                </div>            
                  {/* } */}

                  
                </Dialog>

                {/* Delete item */}
                <Dialog visible={this.state.deleteItemDialog} style={{ width: '450px'}} header="Confirm delete item" modal footer={deleteItemDialogFooter} onHide={this.hideDeleteItemDialog}>
                    <div className='confirmation-content'>
                      <i className='pi pi-exclamation-triangle p-mr-3' style={{ fontSize: '2rem'}} />
                      {this.state.item && <span>Are you sure that you want to delete the selected item?</span>}
                    </div>
                </Dialog>

                <Toast ref={(el) => this.toast = el} />

                <h1>Customer success - {this.props.dataCustomerSuccess.cs_name}</h1>

                <div className='d-flex justify-content-between mb-2'>
                  <Button className='p-button-rounded p-button-danger p-button-outlined' type='button' icon='pi pi-arrow-left' onClick={this.changePage} variant='contained' />
                </div>

                <div className="card-header flex-wrap border-0 pt-6 pb-0">
                  <Toast ref={(el) => this.toast = el} />
                  <h3 className="card-label">
                    Notification template
                    <span className="d-block text-muted pt-2 font-size-sm">Notification template list</span>
                  </h3>
        
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    {/* TOOL BAR */ }
        
                    <div style={{ marginRight: 10}}>
                      <Button label="Assign items" icon="pi pi-plus" onClick={this.confirmAssignEventSelected} disabled={!this.state.selectedEvents || !this.state.selectedEvents.length} />
                    </div>
        
                    {/* <div style={{ marginRight: 10}} >
                      <p><Button type='button' label="New Event" icon='pi pi-plus' onClick={this.openNew} variant='contained' color='primary' /></p>
                    </div> */}
        
                    <div style={{ marginRight: 10}}>
                      <p>
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GlobalSearch} />
                        </span>
                      </p>
                    </div>
        
                  </div>
        
        
                  <div>
                  {/* SHOW EVENTS */}
                    <div className="card">
                      <DataTable
                        ef={(el) => this.dt = el} value={this.state.events} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Events found." loading={this.state.loading}
                        selection={this.state.selectedEvents} onSelectionChange={e => this.setState({ selectedEvents: e.value })} dataKey="id">
                          <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                          <Column field="event" header="Event"  filter filterPlaceholder={this.state.campusText.SearchByEvent} sortable></Column>
                          <Column field="event_type" header="Event type"  filter filterPlaceholder={this.state.campusText.SearchByEventType} sortable></Column>
                          <Column field="description" header={this.state.campusText.Description}  filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                          <Column field="guia" header="Guia"  filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                          {/* <Column field="language" header="Language" filter filterPlaceHolder={this.state.campusText.SearchByLanguage} sortable></Column> */}
                          <Column field='date_type' header="Reference date" filter filterPlaceholder={this.state.campusText.SearchByDateType} sortable />
                          <Column field="rango" header="Range" filter filterPlaceHolder={this.state.campusText.SearchByLanguage} sortable></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>

                <br/>

                <div className="card-header flex-wrap border-0 pt-6 pb-0">
                  <h3 className="card-label">
                    Customer success items
                    <span className="d-block text-muted pt-2 font-size-sm">Customer success list</span>
                  </h3>
                  {/* Tool bar */}
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>        
                    <div style={{ marginRight: 10}}>
                      <Button label="Delete items" className='p-button-danger' icon="pi pi-plus" onClick={this.confirmDeleteItems} disabled={!this.state.selectedItems || !this.state.selectedItems.length} />
                    </div>
        
                    {/* <div style={{ marginRight: 10}} >
                      <p><Button type='button' label="New Event" icon='pi pi-plus' onClick={this.openNew} variant='contained' color='primary' /></p>
                    </div> */}
        
                    <div style={{ marginRight: 10}}>
                      <p>
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GlobalSearch} />
                        </span>
                      </p>
                    </div>
        
                  </div>

                  <div>
                      <div className='card'>
                        <DataTable
                          ef={(el) => this.dt = el} value={this.state.items} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                          globalFilter={this.state.globalFilter} emptyMessage="No Events found." loading={this.state.loading}
                          selection={this.state.selectedItems} onSelectionChange={e => this.setState({ selectedItems: e.value })} dataKey="id">
                            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                            <Column field="item_name" header="Event"  filter filterPlaceholder={this.state.campusText.SearchByEvent} sortable></Column>
                            <Column field="event_type" header="Event type"  filter filterPlaceholder={this.state.campusText.SearchByEventType} sortable></Column>
                            <Column field="description" header={this.state.campusText.Description}  filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                            <Column field="guia" header="Guia"  filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                            {/* <Column field="language" header="Language" filter filterPlaceHolder={this.state.campusText.SearchByLanguage} sortable></Column> */}
                            <Column field='date_type' header="Reference date" filter filterPlaceholder={this.state.campusText.SearchByDateType} sortable />
                            <Column field="rango" header="Range" filter filterPlaceHolder={this.state.campusText.SearchByLanguage} sortable></Column>
                            <Column body={this.actionBodyTemplate}></Column>
                        </DataTable>
                      </div>
                    </div>

                </div>
              </div>
            )
          }
    }

const mapStateToProps = state => ({
user: state.auth.user,
dataCustomerSuccess: state.auth.dataCustomerSuccess
});

export default injectIntl(
connect(mapStateToProps, auth.actions)(CustomerSuccessItems)
)