import React from "react";
import {useSubheader} from "../../_metronic/_core";
import AgencyGroupsTable from "../modulesAdmin/AdminEnterprises/AgencyGroupsTable"

export const AgencyGroupsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Agency Groups");

  return (<><AgencyGroupsTable/></>);

};