import React, { Component } from 'react'
import axios from 'axios'

//http://localhost:3333
//https://api.labsreact.com
//https://api.devsreact.com

//console.log("http://localhost:3333");

class AddTask extends Component {

    state = {
        model:{
            task_name: '',
        }
    };
    
    setValues=(e, field) => {
        const { model } = this.state;
        model[field] = e.target.value;
        this.setState({ model });
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        const data = this.state.model;
        //console.log(data)
        axios.post('http://localhost:3333/api/task', data)
            .then(
                res => {
                    
            })
            .catch(
                e => console.log(e)
            )
    }
      
    render() {
      return (        
        <div className="row">
            <h1>Agregar tareas</h1>
            <div className="container p-4"> 
                <div className="row">
                    <div className="col-md-4  offset-md-4">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input id="task_name" type="text" name="task_name" value={this.state.model.task_name} placeholder="Tarea:" className="form-control" autoFocus 
                                        onChange={e => this.setValues(e, 'task_name')} />
                                    </div>
                                    <button className="btn btn-success btn-block">
                                        Agregar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
      )
    }
}

export default AddTask