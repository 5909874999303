import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const url = ServerURL();




class CountriesTable extends Component {
            

  
  emptyCountry = {
  country_name: '',
  code:'',
  description: '',  
}

 

constructor(props) {
  super(props);  

  this.state = { 
    name:'',
    description: '',

    /**
       * Aux messages
       */
      country_nameMessage: '',
      codeMessage:'',
      descriptionMessage: '',
     

    country: this.emptyCountry,
    countries:null,
    ids:null,
    globalFilter: null,
    selectedCountries: null,
    countryDialog: false,
    deleteCountryDialog: false,
    deleteCountriesDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveCountry = this.saveCountry.bind(this);
  this.deleteCountry = this.deleteCountry.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editCountry = this.editCountry.bind(this);
  this.confirmDeleteCountry= this.confirmDeleteCountry.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedCountries = this.deleteSelectedCountries.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.hideDeleteCountryDialog = this.hideDeleteCountryDialog.bind(this);
  this.hideDeleteCountriesDialog = this.hideDeleteCountriesDialog.bind(this);
 
  }


    
      componentDidMount() {
        this.getCountries()

      }

      isValid(){
        const { country_name,code, description } = this.state.country
              const country_nameMessage = this.validateCountry()
              const codeMessage = this.validateCode()
              const descriptionMessage = this.validateDescription()
           
        
              if(country_name && code && description ){
                if(!country_nameMessage && !codeMessage && !descriptionMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
      validateCountry() {
              const country= this.state.country
              
              let country_nameMessage;
              if (!country.country_name) {
                country_nameMessage = 'Requerido';
              } else {
                country_nameMessage = ''
              }
              this.setState({ country_nameMessage })
              return country_nameMessage
            }

      validateCode() {
              const country= this.state.country
              
              let codeMessage;
              if (!country.code) {
                codeMessage = 'Requerido';
              } else {
                codeMessage = ''
              }
              this.setState({ codeMessage })
              return codeMessage
            }     

       validateDescription() {
              const country = this.state.country
              
              let descriptionMessage;
              if (!country.description) {
                descriptionMessage = 'Requerido';
              } else {
                descriptionMessage = ''
              }
              this.setState({ descriptionMessage })
              return descriptionMessage
            }


            getTextAuxCountry(){
                    const message = this.state.country_nameMessage
                    return message
                  }

            getTextAuxCode(){
                      const message = this.state.codeMessage
                      return message
            }      
            
            getTextAuxDescription(){
                   const message = this.state.descriptionMessage
                    return message
                  }


      getCountries = async () =>{
         
          try {
             
              let res = axios.get(`${url}/api/auth/countries`)
              .then(res => {
                const countries = res.data;            
                this.setState({ countries })
              });                     
                console.log(res.data)
              }catch(error){

         }
      }
      

      deleteCountry(){
        let data = {...this.state.country};

        try {
        let res = axios.delete(`${url}/api/auth/country/${data.id}`)
          .then(res => {
          this.getCountries()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteCountryDialog: false,
            country: this.emptyCountry
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Country Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveCountry() {
        let state = { submitted: true };
        
        const decision = this.isValid();
        if (decision) {
         

          let data = {...this.state.country};


        if (this.state.country.id) {

            try {
              let res = axios.put(`${url}/api/auth/country/${data.id}`,data)
              .then(res =>{
              this.getCountries()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Country Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/country`,data)
            
             .then(res => {
                 this.getCountries()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Country Type Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          countryDialog: false,
          country: this.emptyCountry
        }; 
      }
    
      this.setState(state);
    }
      
      openNew() {
        this.setState({
            country: this.emptyCountry,
            submitted: false,
            countryDialog: true
        })
      }

      hideDialog() {
        this.setState({
        submitted: false,
        countryDialog: false,
        countryMessage: '',
        descriptionMessage: '',
        })
      } 
     
      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let country = {...this.state.country};
        country[`${name}`] = val;
        
        this.setState({ country});
      }

      editCountry(country) {
          this.setState({
              country: { ...country },
              countryDialog: true
          })

      }

      confirmDeleteCountry(country) {
        this.setState({
            country,
            deleteCountryDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteCountriesDialog: true })
      }

      deleteSelectedCountries() {
        let countries = this.state.countries.filter(val => !this.state.selectedCountries.includes(val));
        let data = this.state.countries.filter(val => this.state.selectedCountries.includes(val));

        try {
        let res = axios.delete(`${url}/api/auth/countries`,{data})
          .then(res => {
          this.getCountries()
        })
        if (!res.error) {
          this.setState({
            countries,
            deleteCountriesDialog: false,
            selectedCountries: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Countries Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteCountryDialog() {
        this.setState({ deleteCountryDialog: false })
      }
        
      hideDeleteCountriesDialog() {
        this.setState({ deleteCountriesDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
   

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editCountry(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteCountry(rowData)} />
            </>
        )
      }
      
      




      render(){
       const deleteCountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCountryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteCountry} />
        </>
       );

       const CountryDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveCountry} />}
            </FormattedMessage>
        </>
       );

       const deleteCountriesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCountriesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedCountries} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
            <FormattedMessage id="ADMIN_COUNTRY.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_COUNTRY.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedCountries || !this.state.selectedCountries.length} />}
                      </FormattedMessage>
                    </div>
                    

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_COUNTRY">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div> 

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>

          
          <div>
           {/*SHOW COUNTRIES */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.countries} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Country found." loading={this.state.loading}
                        selection={this.state.selectedCountries} onSelectionChange={e => this.setState({ selectedCountries: e.value })} dataKey="id">

                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="country_name" header={this.state.campusText.Country}  filter filterPlaceholder={this.state.campusText.SearchByCountry} sortable></Column>
                        <Column field="code" header={this.state.campusText.Code}  filter filterPlaceholder={this.state.campusText.SearchByCode} sortable></Column>
                        <Column field="description" header={this.state.campusText.Description}  filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE COUNTRY */}
          <Dialog visible={this.state.deleteCountryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCountryDialogFooter} onHide={this.hideDeleteCountryDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.country && <span> Are you sure you want to delete <b>{this.state.country.country_name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteCountriesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCountriesDialogFooter} onHide={this.hideDeleteCountriesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.country && <span>Are you sure you want to delete the selected countries?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE COUNTRY & CREATE COUNTRY*/}
          <Dialog visible={this.state.countryDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"170", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_COUNTRY"/>} modal footer={CountryDialogFooter} onHide={this.hideDialog}>
          <br></br>
          <div className="form-group row">
              {/* Country */}
              <div className="col-lg-6"> 
              <span className="p-float-label">                      
                <InputText
                  style={{ width: 250, height:33 }}
                  id="country"
                  variant="outlined"
                  margin="dense"
                  value={this.state.country.country_name}             
                  onChange={(e) => this.onInputChange(e, 'country_name')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.country.country_name})}
                  />
              <label htmlFor="country">Country</label>    
              </span>
              <small id="country-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxCountry(this.state)}</small>
              </div>
              {/* Code */}
              <div className="col-lg-6">
              <span className="p-float-label">                      
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="code"
                  variant="outlined"
                  type="code"
                  margin="dense"
                  value={this.state.country.code}             
                  onChange={(e) => this.onInputChange(e, 'code')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.country.code })}
                  />
              <label htmlFor="description">Code</label>    
              </span>    
              <small id="code-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxCode(this.state)}</small>
              </div>
              </div>
              <div className="form-group row">
                {/* Description */}
              <div className="col-lg-6">
              <span className="p-float-label">                      
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="description"
                  variant="outlined"
                  type="description"
                  margin="dense"
                  value={this.state.country.description}             
                  onChange={(e) => this.onInputChange(e, 'description')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.country.description })}
                  />
              <label htmlFor="description">Description</label>    
              </span>    
              <small id="description-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription(this.state)}</small>
              </div>


              </div>



          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(CountriesTable);