import React, {Component} from 'react'
import classNames from 'classnames';
import { Redirect, withRouter } from "react-router";

import axios from 'axios'
import ServerURL from '../../../ServerURL'
// import AddIcon from '@material-ui/icons/Add';
// import Fab from '@material-ui/core/Fab';
// import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
 
// import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
// import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea} from 'primereact/inputtextarea'
// import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
// import { Editor} from 'primereact/editor';
import { InputNumber } from 'primereact/inputnumber';
// import { Chips } from 'primereact/chips'
// import { AutoComplete } from 'primereact/autocomplete'

// import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom'



import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// import EventsTypes from './EventsTypes';
// import Events from './Events';
// import EventsTable from './EventsTable'
// import { element } from 'prop-types';



const url = ServerURL();

class NewEvents extends Component {

  emptyEvent = {
    id_event_type: '',
    id_date_type: '',
    id_language: '',
    event: '',
    description: '',
    guia: ' ',
    rango: '',
  }


  constructor (props) {
    super(props);

    this.state= {
      id_event_type: '',
      id_date_type: '',
      id_language: '',      
      name: '',
      description: '',
      guia: '',
      rango: 0,

      
      id_event_typeMessage: '',
      id_date_typeMessage: '',
      id_languageMessage: '',
      eventMessage: '',
      descriptionMessage: '',
      guiaMessage: '',
      rangoMessage: '',

      event: this.emptyEvent,
      events: null,
      ids: null,
      globalfilter: null,
      selectedEvents: null,
      eventDialog: null,
      
      selectedEventType: null,
      resetEventType: null,
      
      deleteEventDialog: false,
      deleteEventsDialog: false,
      submitted: false,

      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTranslate: getGeneralTextTranslate(),

      selectedLanguage: null,
      resetLanguage: null,

      selectedDates: null,
      resetDates: null,

      redirect: false,
      changeToTemplate: false,

      options: ''
    }


    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.saveEvent = this.saveEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.openNew = this.openNew.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.confirmDeleteEvent = this.confirmDeleteEvent.bind(this);
    this.confirmDeleteEventSelected = this.confirmDeleteEventSelected.bind(this);
    this.deleteSelectedEvents = this.deleteSelectedEvents.bind(this);
    this.hideDeleteEventDialog = this.hideDeleteEventDialog.bind(this);
    this.hideDeleteEventsDialog = this.hideDeleteEventsDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    
    this.onEventTypeChange = this.onEventTypeChange.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.changePage = this.changePage.bind(this)

    // this.onItemChange = this.onItemChange.bind(this);

    // this.onLazyItemChange = this.onLazyItemChange.bind(this);
    // this.onLazyLoad = this.onLazyLoad.bind(this);
  }

  componentDidMount() {
    this.getEventTypes()
    this.getDateTypes()
    this.getLanguages()
  }

  changePage(){
    this.setState({ redirect: true })
  }

  onButtonChange(e, name, f) {
    let event = {...this.state.event}
    event['description'] = e + ' ' + [`[${name}]`]
    event['guia'] =  [`${name}`]  + ',' + event['guia']

    this.setState({ event })

  }

  onClearGuia(e){
    let event = {...this.state.event}
    event['guia'] = e
    this.setState({ event })
  }

  onClearDescription(e){
    let event = {...this.state.event}
    event['description'] = e
    this.setState({ event })
  }


 isValid(){
    const { id_event_type,  event, description, id_date_type, id_language, rango  } = this.state.event

      const id_event_typeMessage = this.validateEventType()
      const id_date_typeMessage = this.validateDateType()
      const id_languageMessage = this.validateLanguage()
      const eventMessage = this.validateEvent()
      const descriptionMessage = this.validateDescription()
      const rangoMessage = this.validateRango()

      if(id_event_type && event && description && id_language && id_date_type && rango  ){
        if(!rangoMessage && !id_event_typeMessage && !eventMessage && !descriptionMessage && !id_languageMessage && !id_date_typeMessage ){
          return true;
        }else{
          return false;
        }
      }
      return false;
  }

  validateLanguage() {
    const event = this.state.event
    let languageMessage;
    if (!event.language) {
      languageMessage = 'Requerido';
    } else {
      languageMessage = ''
    }
    this.setState({ languageMessage })
    return languageMessage
  }

  validateDateType(){
    const event = this.state.event

    let id_date_typeMessage;
    if(!event.id_date_type){
      id_date_typeMessage = 'Requerido'
    } else {
      id_date_typeMessage = ''
    }
    this.setState({ id_date_typeMessage })
    return id_date_typeMessage

  }

  validateEventType() {
    const event = this.state.event
    
    let id_event_typeMessage;
    if (!event.id_event_type) {
      id_event_typeMessage = 'Requerido';
    } else {
      id_event_typeMessage = ''
    }
    this.setState({ id_event_typeMessage })
    return id_event_typeMessage
  }

  validateEvent() {
    const event= this.state.event
    
    let eventMessage;
    if (!event.event) {
      eventMessage = 'Requerido';
    } else {
      eventMessage = ''
    }
    this.setState({ eventMessage })
    return eventMessage
  }

validateDescription() {
    const event = this.state.event
    
    let descriptionMessage;
    if (!event.description) {
      descriptionMessage = 'Requerido';
    } else {
      descriptionMessage = ''
    }
    this.setState({ descriptionMessage })
    return descriptionMessage
  }

  validateRango() {
    const event = this.state.event
    
    let rangoMessage;
    if(!event.rango){
      rangoMessage = 'Requerido'
    } else {
      rangoMessage = ''
    }
    this.setState({ rangoMessage })
    return rangoMessage
  }

  getTextAuxEventType(){
    const message = this.state.id_event_typeMessage
    return message
  }

  getTextAuxDateType() {
    const message = this.state.id_date_typeMessage
    return message
  }

  getTextAuxLanguage() {
    const message = this.state.id_languageMessage
    return message
  }

  getTextAuxEvent(){
    const message = this.state.eventMessage
    return message
  }

  getTextAuxDescription(){
    const message = this.state.descriptionMessage
      return message
  }

  getTextAuxRango() {
    const message = this.state.rangoMessage
    return message
  }

  getTextAuxGuia(){
    const message = this.state.guiaMessage
    return message
  }

  getEventTypes = async () =>{  
    try { 
        let res = axios.get(`${url}/api/auth/event_types`)
        .then(res => {
          const event_types= res.data;
          this.setState({ event_types })
        });                     
          console.log(res.data)
        }catch(error){
    }
  }

  getDateTypes = async () => {
    try {
      await axios.get(`${url}/api/public/date_types`)
      .then(res => {
        const date_types = res.data;
        this.setState({ date_types })
        console.log(res.data);
      })
    } catch (error) {
      console.log(error);
    }
  }
  
  getLanguages = async () => {
    try {
      await axios.get(`${url}/api/public/languages`)
      .then(res => {
        const languages = res.data;
        this.setState({ languages })
        console.log(res.data);
      })
    } catch (error) {
      console.log(error);
    }
  }

deleteEvent(){
  let data = {...this.state.event};

  try {
  let res = axios.delete(`${url}/api/auth/event/${data.id}`)
    .then(res => {
    this.getEvents()
  })
  if (!res.error) {
    this.setState({
      data,
      deleteEventDialog: false,
      event: this.emptyEvent
  });
  this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Event Deleted', life: 3000 });
  }
    }catch(error){
    } 
}

saveEvent() {
  let state = { submitted: true };
  // const decision = this.isValid();
  
  try {
    // if (decision) {

          try {
            let data = {...this.state.event};
          console.log("Data: ", data);
          try {
            let res = axios.post(`${url}/api/public/event`, data)
            .then(res => {
            })
            if (!res.error) {
              this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Event Created', life: 3000 });
            }
          } catch(error){
              console.log(error);
          }   

          state = {
            ...state,
            data,
            eventDialog: false,
            event: this.emptyEvent,
            selectedEventType: null,
            selectedLanguage: null,
            selectedDates: null,
            resetEventType:null,
            resetLanguage: null,
            resetDates: null,
            redirect: true,
          };
          } catch (error) {
            console.log(error);
          }  
        // } 
  } catch (error) {
    console.log(error);
  }
this.setState(state);
}

openNew() {
  this.getEventTypes()
  this.setState({
      event: this.emptyEvent,
      submitted: false,
      eventDialog: true,
      selectedEventType: null,
      selectedLanguage: null,
      selectedDates: null,
      resetEventType:"Event Type",
      resetLanguage: "Language",
      resetDates: "Execute date"
  })
}

onInputChange(e, name) {
  const val = (e.target && e.target.value) || '';
  let event = {...this.state.event};
  event[`${name}`] = val;

  this.setState({ event });
}

onEventTypeChange(e) {
  let event = {...this.state.event};
  event['id_event_type'] = e.value;
  this.setState({ selectedEventType: e.value, event});      
}

onLanguageChange(e) {
  let event = {...this.state.event};
  event['id_language'] = e.value;
  this.setState({ selectedLanguage: e.value, event})
}

onDateChange(e){
  let event = {...this.state.event}
  event['id_date_type'] = e.value;
  this.setState({ selectedDates: e.value, event})
}


// onItemChange(e) {
//   this.setState({
    
//   })
// }

// onLazyItemChange() {

// }

// onLazyLoad() {

// }



hideDialog() {
  this.setState({
      submitted: false,
      eventDialog: false,
      event_typeMessage: '',
      eventMessage:'',
      descriptionMessage: '',
      languageMessage: ''
})  
}

editEvent(event) {
  console.log(event)
  this.getEventTypes()
  this.setState({
      event: { ...event },
      selectedEventType: null,
      selectedLanguage: null,
      selectedDates: null,
      resetEventType:null, 
      resetLanguage: null,
      resetDates: null,
      eventDialog: true,
  })
}

confirmDeleteEvent(event) {
  this.setState({
      event,
      deleteEventDialog: true
  })
}

confirmDeleteEventSelected() {
  this.setState({ deleteEventsDialog: true })
}

deleteSelectedEvents() {
  let events = this.state.events.filter(val => !this.state.selectedEvents.includes(val));
  let data = this.state.events.filter(val => this.state.selectedEvents.includes(val));

  try {
  let res = axios.delete(`${url}/api/auth/events`, {data})
    .then(res => {
    this.getEvents()
  })
  if (!res.error) {
    this.setState({
      events,
      deleteEventsDialog: false,
      selectedEvents: null
  });
  this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Events Deleted', life: 3000 });
  }
    }catch(error){
    } 
}

hideDeleteEventDialog() {
  this.setState({ deleteEventDialog: false })
}
  
hideDeleteEventsDialog() {
  this.setState({ deleteEventsDialog: false });
}

actionBodyTemplate(rowData) {
  return (
      <>
          <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editEvent(rowData)} />
          <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteEvent(rowData)} />
      </>
  )
}


renderHeader(){
  return (
      <span>
        <Button color='primary' style={{ width: "30px", height: "30px"}}>Nombre</Button>
      </span>
  )
}

  render(){

    if(this.state.redirect){
      return <Redirect to={{
          pathname: '/wiyu-events'
      }} />
  }

    const deleteEventDialogFooter = (
      <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEventDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteEvent} />
      </>
    );

    const eventDialogFooter = (
      <>
          <FormattedMessage id="GENERAL.CANCEL">
            {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
          </FormattedMessage>
          <FormattedMessage id="GENERAL.SAVE">
            {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveEvent} />}
          </FormattedMessage>
      </>
    );

    const deleteEventsDialogFooter = (
      <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEventsDialog } />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedEvents} />
      </>
    );

    const header = this.renderHeader()

    return(
      <div>
        <Toast ref={(el) => this.toast = el} />
        <div className='d-flex justify-content-between mb-2'>
            <Button className='p-button-rounded p-button-danger p-button-outlined' type='button' icon='pi pi-arrow-left' onClick={this.changePage} variant='contained' />
        </div>

        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <h3 className="card-label">
            Add new notification templates
            <span className="d-block text-muted pt-2 font-size-sm">You can create new notification templates for yoy customer successes.</span>
          </h3>
        </div>
        <div>
        <br/>
        <div className="card">
                <div className="form-group row" style={{ paddingTop: 20, marginLeft: 5 }}>
                  {/* Event Type */}
                  <div className="col-lg-6">      
                    <div className='p-inputgroup'>
                      <span className='p-inputgroup-addon'>
                        <i className='pi pi-tag'></i>
                      </span>
                      <span className="p-float-label">          
                        <Dropdown
                        style={{ width: 250, height:33 }}
                        id="event_type"
                        variant="outlined"
                        placeholder= { this.state.resetEventType || this.state.event.event_type}
                        margin="dense"
                        value={this.state.selectedEventType || "event_type"}   
                        options ={this.state.event_types} 
                        onChange={this.onEventTypeChange} 
                        optionLabel="event_type" 
                        optionValue="id"
                        />
                        <label htmlFor="event_type">Notification Type</label>
                      </span>
                    </div>
                    <small className='p-d-block'>Select the notification type for your notifications</small>
                    <small id="event_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEventType()}</small>
                  </div>
                  <br />
                  <br />
                  {/* Event */}
                  <div className="col-lg-6">
                    <div className='p-inputgroup'>
                      <span className='p-inputgroup-addon'>
                        <i className='pi pi-bell'></i>
                      </span>
                      <span className="p-float-label">
                        <InputText
                        id="event"
                        variant="outlined"
                        type="event"
                        margin="dense"
                        value={this.state.event.event}             
                        onChange={(e) => this.onInputChange(e, 'event')}
                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.event.event  })}
                        />
                        <label htmlFor="event">Notification name</label>
                      </span>
                    </div>
                    <small className='p-d-block'>Enter the notification name for your notifications</small>
                    <small id="event-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEvent(this.state)}</small>    
                  </div>

                </div>

                <div className="form-group row" style={{ paddingTop: 10, marginLeft: 5 }}>
                {/* Idioma */}
                {/* <div className='p-col-12 p-md-4'>
                  <div className='p-inputgroup'>
                      <span className='p-inputgroup-addon'>
                        <i className='pi pi-globe'></i>
                      </span>
                      <span className="p-float-label">   
                      <Dropdown
                          style={{ width: 250, height:33 }}
                          id='language'
                          variant="outlined"
                          placeholder= { this.state.resetLanguage || this.state.event.language}
                          margin= "dense"
                          value={this.state.selectedLanguage || "language"}
                          options ={this.state.languages}
                          onChange={this.onLanguageChange} 
                          optionLabel="language"
                          optionValue="id"
                          />
                    <label htmlFor="language">Language</label>     
                    </span>   
                  </div>
                <small id="language-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxLanguage(this.state)}</small>
                </div> */}

                {/* Dates */}
                {/* <div className='p-col-12 p-md-4'> */}
                <div className='col-lg-6'>
                  <div className='p-inputgroup'>
                    <span className='p-inputgroup-addon'>
                      <i className='pi pi-calendar'></i>
                    </span>
                    <span className="p-float-label">   
                      <Dropdown
                        style={{ width: 250, height: 33 }}
                        id='date_type'
                        variant='outlined'
                        placeholder={ this.state.resetDates || this.state.event.date_type }
                        margin='dense'
                        value={this.state.selectedDates || 'date_type' }
                        options={this.state.date_types}
                        onChange={this.onDateChange}
                        optionLabel='date_type'
                        optionValue='id'
                      />
                    <label htmlFor="date_type">Execute date base</label>     
                  </span>   
                  </div>
                </div>

                {/* <div className='p-col-12 p-md-4'> */}
                <div className='col-lg-6'>
                  <div className='p-inputgroup'>
                      {/* <span className='p-inputgroup-addon'>
                        <i className='pi pi-calendar'></i>
                      </span> */}
                      <span>
                        <InputNumber id="horizontal" value={this.state.event.rango} onValueChange={(e) => this.onInputChange(e, 'rango')} showButtons buttonLayout="horizontal"
                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal"/>
                      </span>
                    </div>
                    <small className='p-d-block'>Enter the days for execute your notification</small>
                </div>
              <br/>
              {/* <br/> */}
              </div>

                <div className='form-group row' style={{ paddingTop: 10, marginLeft: 5 }}>
                  
                  <div className='col-lg-8'>
                    <span>
                      <Button type='button' onClick={() => this.onButtonChange(this.state.event.description , 'nombre', this.onButtonChange(this.state.event.guia))}>[nombre]</Button>
                    </span>

                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onButtonChange(this.state.event.description , 'ciudad', this.onButtonChange(this.state.event.guia))}  >[ciudad]</Button>
                    </span>

                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onButtonChange(this.state.event.description , 'fecha', this.onButtonChange(this.state.event.guia))} >[fecha]</Button>
                    </span>

                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onButtonChange(this.state.event.description , 'programa', this.onButtonChange(this.state.event.guia))} >[programa]</Button>
                    </span>

                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onClearGuia('')} >Clear guia</Button>
                    </span>

                  </div>
                  
                  <br/>
                  <br/>

                  <div className='col-lg-7'>
                    <div className='p-inputgroup'>
                      <span className='p-float-label'>
                        <InputText
                        id='guia'
                        type='guia'
                        value={this.state.event.guia}
                        // onChange={(e) => this.onInputChange(e, 'guia')}
                        disabled
                        />
                      </span>
                    </div>
                  </div>

                </div>

                <div className='form-group row' style={{ paddingTop: 10, marginLeft: 5 }}>                  
                  <div className="col-lg-6">
                    <div className='p-inputgroup'>
                      <span className='p-float-label' >
                        <InputTextarea 
                        style={{ width: 600, height: 80}}
                        id='description'
                        variant='outlined'
                        margin='dense'
                        value={this.state.event.description} 
                        onChange={(e) => this.onInputChange(e, 'description')}
                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.event.description})}
                        autoResize/>
                        <label htmlFor="description">Description</label>
                        <small id="description-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription(this.state)}</small>    
                      </span>
                    </div>
                  </div>

                  <div className='col-lg-3'>
                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onClearDescription('')} >Clear description</Button>
                    </span>
                  </div>
                </div>
                
                <div  
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end',
                    paddingBottom: 10,
                    marginRight: 5
                  }}>
                  <div style={{ marginRight: 10}}>
                    <Button type="button" label='Nuevo evento' icon="pi pi-plus" onClick={this.saveEvent} variant='contained' color='primary'/>
                  </div>
                </div>                
            </div>
        </div>
      </div>
    )
  }
}

export default withRouter(NewEvents);