
export function validateAccountingOffice(state, setState) {
  const user = state.user
  console.log("ENTRO AQUI")
  let accounting_officeMessage;
  if (!user.accounting_office && state.CheckedAccounting) {
    accounting_officeMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    accounting_officeMessage = ''
  }
  return accounting_officeMessage
}

export function validateField(data) {
  let Message;
  if (!data) {
    Message = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    Message = ''
  }
  return Message
}


export function validateEmail(state, setState) {
  const user = state.user
  
  let emailMessage;
  if (!user.email) {
    emailMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.email)) {
    emailMessage = "AUTH.VALIDATION.INVALID_EMAIL"
  } else {
    emailMessage = ''
  }
  return emailMessage
}

export function validatePassword(state, setState) {
  const user = state.user
  
  let passwordMessage;
  if (!user.password) {
    passwordMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else if (!/^[A-Z0-9._%+-,$,/]{6,120}$/i.test(user.password)) {
    passwordMessage = "AUTH.VALIDATION.INVALID_PASSWORD"
  } else {
    passwordMessage = ''
  }
  return passwordMessage
}

export function validateFirstName(state, setState) {
  const user = state.user
  
  let firstNameMessage;
  if (!user.first_name) {
    firstNameMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    firstNameMessage = ''
  }
  return firstNameMessage
}

export function validateLastName(state, setState) {
  const user = state.user
  
  let lastNameMessage;
  if (!user.last_name) {
    lastNameMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    lastNameMessage = ''
  }
  return lastNameMessage
}

export function validateAmount(state, setState) {
  const invoiceItem = state.invoiceItem
  
  let amountMessage;
  if (!invoiceItem.amount) {
    amountMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    amountMessage = ''
  }
  return amountMessage
}

export function validateProgram(state, setState) {
  const invoiceItem = state.invoiceItem
  
  let programMessage;
  if (!invoiceItem.program) {
    programMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    programMessage = ''
  }
  return programMessage
}

export function validatePhone(state, setState) {
  const user = state.user
  
  let phoneMessage;
  if (!user.phone) {
    phoneMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else if (!/^[A-Z0-9._%+-]{6,12}$/i.test(user.phone)) {
    phoneMessage = 'Telefono invalido';
  } else {
    phoneMessage = ''
  }
  return phoneMessage
}

export function validateRoleType(state, setState) {
  const user = state.user
  const role_type = state.selectedRoleType
  
  let roletypeMessage;
  if (!role_type && !user.role_type) {
    roletypeMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    roletypeMessage = ''
  }
  return roletypeMessage
}

export function validateRole(state, setState) {
  const role = state.selectedRole
  const user = state.user

  let fkIdRoleMessage;
  if (user.fk_id_role==null && role==null) {
    fkIdRoleMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    fkIdRoleMessage = ''
  }
  return fkIdRoleMessage
}

export function validateEnterprise(state, setState) {
  const enterprise = state.selectedEnterprise
  const user = state.user
  
  let enterpriseMessage;
  if (user.fk_id_enterprise==null && enterprise==null ) {
    enterpriseMessage = "AUTH.VALIDATION.REQUIRED_FIELD"
  } else {
    enterpriseMessage = ''
  }
  return enterpriseMessage
}


export function getTextAuxAmount(state){
  const message = state.amountMessage
  return message
}

export function getTextAuxAccountingOffice(state){
  const message = state.accounting_officeMessage
  return message
}

export function getTextAuxEmail(state){
  const message = state.emailMessage
  return message
}

export function getTextAuxPassword(state){
  const message = state.passwordMessage
  return message
}

export function getTextAuxFirstName(state){
  const message = state.firstNameMessage
  return message
}

export function getTextAuxLastName(state){
  const message = state.lastNameMessage
  return message
}

export function getTextAuxPhone(state){
  const message = state.phoneMessage
  return message
}

export function getTextAuxRole(state){
  const message = state.fkIdRoleMessage
  return message
}

export function getTextAuxEnterprise(state){
  const message = state.enterpriseMessage
  return message
}

export function getTextAuxRoleType(state){
  const message = state.roletypeMessage
  return message
}

export function getTextAuxBoss(state){
  const message = state.bossMessage
  return message
}