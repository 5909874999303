import React from "react";
import {useSubheader} from "../../_metronic/_core";
import MyTeam from "../modulesAgency/MyTeam"
import { useIntl } from "react-intl";

export const TeamPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(int1.formatMessage({id: "LEARNING_GENERAL.MY_TEAM"}));
    return (<MyTeam/>)
}