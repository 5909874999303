import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ProgramTypesTable from "../modulesAdmin/AdminEnterprises/AdminPrograms/ProgramTypesTable"

export const ProgramTypesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("ProgramTypes");

  return (<><ProgramTypesTable/></>);

};