// LoadStudents
import React from 'react';
import EdvisorURL from '../../../EdvisorURL'
import { ApolloClient,InMemoryCache,HttpLink } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import axios from 'axios'
import ServerURL from '../../../ServerURL'


import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';

import "./styles.css";


const url = ServerURL();


const urlEdvisor = EdvisorURL()
const cache = new InMemoryCache();
const client = new ApolloClient({
    cache,
    link: new HttpLink({
        uri: 'https://api.edvisor.io/graphql',
        headers:{
        authorization: `Bearer ${urlEdvisor}`
        },
    }),
});

const StudentQuery = () => {
    let GET_DATA = gql`
    query RootQueryType {
        studentsList {
            count,
            data {
                studentId,
                firstname,
                lastname,
                email,
                phone,
                created,
                modified

                studentSecondaryContacts {
                    studentSecondaryContactId,
                    studentId,
                    name,
                    email,
                    phone,
                    description,
                    created,
                    modified
                }
            }
        }
    }
    `

    return(
        <Query query={GET_DATA}>
            {({ loading, error, data }) => {
                if (loading) return <p>Loading...</p>;
                if (error) return <p>Error!</p>;

                let options = {
                    headers: {
                    'Content-Type': 'application/json'
                    }
                }

                let requestOne = axios.post(`${url}/api/public/students-edvisor`,data.studentsList.data,options);
                // let requestTwo = axios.post(`${url}/api/public/parents-edvisor`,data.studentsList.data,options);


                axios
                .all([
                    requestOne, 
                    // requestTwo
                ])
                .then(
                axios.spread((...responses) => {
                const student = responses[0].data;
                // const parents = responses[1].data;
                console.log(student)
                // console.log(parents);
                })
                )
                .catch(errors => {
                console.error(errors);
                });  

                console.log("DATA:",data.studentsList.data)


                return(
                <div>
                Information loaded!
                </div>

                )
            }}
        </Query>
    )
}

function StudentsEdvisorTable() {
    return (
      <div className="App">
        <StudentQuery />
      </div>
    );
  }
  
  export default () => (
      <ApolloProvider client={client}> {/* en "client" debe ir la configuración de apollo */}
          <StudentsEdvisorTable />
      </ApolloProvider>
  );
  