// import RoleTypes from './RoleTypes';
// import Roles from './Roles';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  head: {
    textAlign: "center",
    padding: theme.spacing(3),
  },
  title: {
    fontSize: "4em"
  },
  box: {
    background: "#DCDCDC",
    height: "150px",
    border: "1px solid black",
    borderRadius: "7px",
    padding: "1em",
    marginBottom: "12px"
  },
  titleBox: {
    fontSize:"18px",
  },
  subMenu: {
    fontSize:"32px",
    marginBottom: "12px"
  },
  contentBox: {
    fontSize: "16px"
  },
  contentMoney: {
    fontSize: "38px",
    fontWeight: 500,
  },
  downBox: {
    position: "absolute",
    bottom: "18px",
    right: "20px",
    fontSize: "16px",
    textAlign: "right"
  },
  boxServices: {
    background: "#DCDCDC",
    height: "150px",
    border: "1px solid black",
    borderRadius: "7px",
    textAlign: "center",
    marginBottom:"12px"
  },
  topText: {
    fontSize: "32px",
    fontWeight: "600",
    letterSpacing: "3px",
  },
  svg: {
    margin: "-16px",
    width: 96,
    height: "auto",
  },
  bottonText: {
    fontSize: "16px",
    marginTop: "10px",
    fontWeight: 500,
    letterSpacing: "1px",
  },
  upgrade: {
    height: "60px",
    fontSize: "40px",
    background: "grey",
    color: "white",
    textAlign: "center",
    fontWeight: "500",
    borderRadius: "7px",
    letterSpacing: "3px",
  }
}));


export default function MainBilling() {
  const classes = useStyles();
  const { user } = useSelector(state => state.auth);

  return (
    <div>
      <div className={classes.head}>
        <h1 className={classes.title}>¡Bienvenido {user.fullname}!</h1>
      </div>
      <div style={{paddingLeft:"5em", paddingRight:"5em"}} className="container">
        <div className="row">
          <div className="col-12 col-sm-8">
            <div className={classes.box}>
              <div className={classes.titleBox} style={{marginBottom: "10px"}}>
              VENCIMIENTOS
              </div>
              <div className={classes.contentBox}>
              ¡Tus servicios estan al dia!
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className={classes.box}>
              <div className={classes.titleBox}>
                SALDO DE LA EMPRESA
              </div>
              <div className={classes.contentMoney}>
                $100.00
              </div>
              <div className={classes.downBox}>
                agregar saldo {'>'}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.subMenu}>
          MIS SERVICIOS
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-2">
            <div className={classes.boxServices}>
              <div className={classes.topText}>
                FREE
              </div>
              <div>
                <span>
                  <SVG className={classes.svg} src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}/>
                </span>
              </div>
              <div className={classes.bottonText}>
                LEARNING
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-2">
            <div className={classes.boxServices}>
              <div className={classes.topText}>
                FREE
              </div>
              <div>
                <span>
                  <SVG className={classes.svg} src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}/>
                </span>
              </div>
              <div className={classes.bottonText}>
                ACCOUNTING
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 align-self-center" style={{textAlign:"center"}}>
            <NavLink to="/movements-and-invoices/upgrade">
              <button type="button" className={classes.upgrade}>UPGRADE</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}