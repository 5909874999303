import React from "react";
import {useSubheader} from "../../_metronic/_core";
import DataUser from "../modulesGeneral/DataUser";
import { useIntl } from "react-intl";

export const DataUserPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(int1.formatMessage({id: "QUICK_USER.MY_PROFILE"}));
    return (<DataUser/>)

}