import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ListTutorials from "../../app/modulesAdmin/ListTutorials"

export const TutorialsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Tutorials");

  return (<><ListTutorials/></>);

};