import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios';
import * as auth from '../../modulesAdmin/Auth/_redux/authRedux'
import ServerURL from '../../../ServerURL'
import { Redirect, Link } from "react-router-dom";

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Calendar } from 'primereact/calendar'
import { FileUpload } from 'primereact/fileupload';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { connect } from 'react-redux';

const url = ServerURL()

class InvoicesDocuments extends Component {
    emptyDoc = {
        id_doc_type: '',
        document_name: '',
        extension: '',
        doc_url_file: '',
        doc_url: ''
    }

    constructor(props) {
        super(props)

        this.state = {
            id_doc_type: '',
            document_name: '',
            extension: '',
            doc_url_file: '',
            doc_url: '',

            document: this.emptyDoc,
            documents: null,
            selectedDocuments: null,

            ids: null,
            globalFilter: null,

            resetDocType: null,
            selectedDocType: null,

            docDialog: null,
            deleteDialog: null,
            submitted: false,
            campusText: getTextCampusTranslate(),
            paginatorText: getTextPaginatorTranslate(),
            textTranslate: getGeneralTextTranslate(),
        }

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this)
        this.hideDialog = this.hideDialog.bind(this)
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this)
        this.onOpen = this.onOpen.bind(this)
        // this.editDocument = this.editDocument.bind(this)
        this.onDocTypeChange = this.onDocTypeChange.bind(this)
        // this.changePage = this.changePage.bind(this)
        // this.onInputChange = this.onInputChange.bind(this)

        this.fileBodyTemplate = this.fileBodyTemplate.bind(this)
        this.fileExtensionTemplate = this.fileExtensionTemplate.bind(this)

        this.saveDocument = this.saveDocument.bind(this)
        this.deleteDocument = this.deleteDocument.bind(this)
    }

    componentDidMount() {
        this.getDocuments()
    }

    getDocuments = async () => {
        const invoiceId = this.props.invoice
        console.log('INVOICE ID: ', invoiceId);

        try {
            await axios.get(`${url}/api/auth/invoice_documents/${invoiceId}`).then(
                res => {
                    const documents = res.data
                    this.setState({ documents })
                    console.log('DOCUMENTS: ', documents);
                }
            )
        } catch (error) {
            console.log('ERROR: ', error);
        }
    }

    getDocTypes= async () => {
        try {
            await axios.get(`${url}/api/auth/invoice_doc_types`)
            .then(res => {
                const doc_types = res.data
                this.setState({ doc_types })
                console.log("Documents types: ", doc_types);
            })
        } catch (error) {
            
        }
    }

    saveDocument() {
        let state = { submitted: true }
        let data = {
            ...this.state.document,
            invoiceId: this.props.invoice
        }

        const formData = new FormData();
        const fileInvoice = this.uploadInput.getFiles()

        formData.append('document_name', data.document_name)
        formData.append('id_doc_type', data.id_doc_type)
        formData.append('invoiceId', data.invoiceId)

        if(fileInvoice.length !== 0 ) {
            console.log('File: ', fileInvoice[0]);
            formData.append('doc_url', fileInvoice[0])
        }

        console.log('DATA: ', data.document_name, data.invoiceId, data.doc_url );

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        console.log('FORMDATA: ', formData);
        console.log('CONFIG: ', config);


        axios.post(`${url}/api/auth/invoice_documents`, formData, config)
        .then(res => {
            this.getDocuments()
            console.log('MESSAGE: ', res.data.message);
        })


        state = {
            ...state,
            data,
            docDialog: false,
            document: this.emptyDoc,
            selectedDocType: null,
            resetDocType: null,

        }

        this.setState(state)
    }

    async deleteDocument() {
        let data = {...this.state.document}
        try {
            await axios.delete(`${url}/api/auth/delete_invoice_documents/${data.id_document}`).then( res => {
                this.getDocuments()
                this.setState({
                    data,
                    deleteDialog: false,
                    document: this.emptyDoc
                })
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Document deleted', life: 3000 });
            })
        } catch (error) {
            
        }
    }

    onOpen() {
        this.getDocTypes()
        this.setState({
            docDialog: true,
            document: this.emptyDoc,
            selectedDocType: null,
            resetDocType: 'Document type'
        })
    }

    editDocument(document) {
        console.log('DOCUMENT: ', document);
        this.getDocTypes()

        this.setState({
            document: {...document},
            selectedDocType: null,
            resetDocType: null,
            docDialog: true
        })
    }

    confirmDeleteDocument(document) {
        console.log('DOCUMENT: ', document);
        this.setState({
            document,
            deleteDialog: true
        })
    }

    hideDialog() {
        this.setState({
            docDialog: false,
            submitted: false
        })
    }

    hideDeleteDialog() {
        this.setState({
            deleteDialog: false
        })
    }

    onDocTypeChange(e){
        let document = {...this.state.document}
        document['id_doc_type'] = e.value
        this.setState({ selectedDocType: e.value, document})
    }

    actionBodyTemplate(rowData){
        return(
            <>
                <Button 
                    icon="pi pi-trash" 
                    className='p-button-danger p-button-outlined p-button-rounded' 
                    style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} 
                    onClick={() => this.confirmDeleteDocument(rowData)} />
                {/* <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteEvent(rowData)} /> */}
            </>
        )
    }

    fileBodyTemplate(rowData) {
        if(rowData.doc_url_file!==null) {
            return (
                <>
                    <a href={`${rowData.doc_url_file}`} target='_blank' rel="noopener noreferrer">
                        <Button 
                            className='p-button-rounded p-button-outlined p-button-help'
                            type='button'
                            icon='pi pi-download'
                            style={{ verticalAlign: 'middle', width: '30px', height: '30px'}} />
                    </a>
                </>
            )
        }
    }

    fileExtensionTemplate(rowData) {
        if(rowData.document!==null) {
            return (
                <React.Fragment>
                    <i className='pi pi-file' />
                    <span className='image-text'>{rowData.document}</span>
                </React.Fragment>
            )
        }
    }

    FileDocument() {
        let UploadAndShow = []
        let document = {...this.state.document}

        if(document.doc_url_file) {
            UploadAndShow.push(
                <>
                    <div className='col-lg-2.5'>
                        <FileUpload
                            type='file'
                            name='doc_url'
                            ref={(ref) => { this.uploadInput = ref }}
                            multiple={false}
                            mode='basic'
                            chooseLabel="Update file..." />
                    </div>
                    <div className='col-lg-1'>
                        <a href={`${document.doc_url_file}`} target='_blank' rel='noopener noreferrer'><Button type='button' icon='pi pi-download' /></a>
                    </div>
                </>
            )

        } else {
            UploadAndShow.push(
                <>
                    <div>
                        <FileUpload 
                            type='file'
                            name='doc_url'
                            ref={(ref) => {this.uploadInput = ref}}
                            multiple={false}
                            mode='basic'
                            chooseLabel='Update file...'
                        />
                    </div>
                </>
            )
        }

        return UploadAndShow
    }



    render() {
        const footer = (
            <>
                <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={this.hideDialog} />
                <Button label='Save' icon='pi pi-check' className='p-button-text'  onClick={this.saveDocument}/>
            </>
        )

        const deleteFooter = (
            <>
                <Button label='No' icon='pi pi-times' className='p-button-text' onClick={this.hideDeleteDialog} />
                <Button label='Yes' icon='pi pi-check' className='p-button-text' onClick={this.deleteDocument} />
            </>
        )

        return (
            <div>
                {/* <h1>DOCUMENTS TABLE</h1>
                <h2>Coming soon...</h2> */}
                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <Toast ref={(el) => this.toast = el} />
                    {/* <Dialog visible={this.state.docDialog} contentStyle={{ maxHeight: "1000px", width: "800px", height: "250px", overflow: "auto" }} header='Complete the information' modal footer={footer} onHide={this.hideDialog} >
                        <br></br>
                        <div className='form-group row'>
                            <div className='col-lg-6'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-file'></i>
                                    </span>
                                    <span className='p-float-label'>
                                        <Dropdown
                                            style={{ width: 250, height: 33 }}
                                            id='doc_type'
                                            variant='outlined'
                                            placeholder={this.state.resetDocType || this.state.document.doc_type}
                                            margin='dense'
                                            value={this.state.selectedDocType || 'doc_type'}
                                            options={this.state.doc_types}
                                            onChange={this.onDocTypeChange}
                                            optionLabel='doc_type'
                                            optionValue='id' />
                                        <label htmlFor='doc_type'>Document type</label>
                                    </span>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                            <i className='pi pi-plus'></i>
                                        </span>
                                    {this.FileDocument()}
                                </div>
                            </div>
                        </div>

                        <div className='form-group row'>
                                <div className='col-lg-6'>
                                    <div className='p-inputgroup'>
                                        <span className='p-inputgroup-addon'>
                                            <i className='pi pi-file'></i>
                                        </span>
                                        <span className='p-float-label'>
                                            <InputText
                                            id='document_name'
                                            variant='outlined'
                                            margin='dense'
                                            label='Document name'
                                            value={this.state.document.document_name}
                                            onChange={(e) => this.onInputChange(e, 'document_name')}
                                            required autoFocus
                                            className={classNames({ 'p-invalid': this.state.submitted && !this.state.document.document_name })}
                                            />
                                            <label htmlFor='document_name'>Document name</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                    </Dialog> */}

                    <h3 className='card-label'>
                        Invoices documents
                        <span className='d-block text-muted pt-2 font-size-sm'>You can see the documents of your invoice.</span>
                    </h3>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end ' }}>
                        <div style={{ marginRight: 10 }} >

                            <p><Button type='button' label="Upload File" icon='pi pi-plus' onClick={this.onOpen} variant='contained' color='primary' /></p>

                        </div>

                    </div>

                </div>
                <div>
                    <div className='card'>
                        <DataTable
                            ef={(el) => this.dt = el} value={this.state.documents} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10, 20, 50]} ref={(el) => { this.dt = el; }}
                            globalFilter={this.state.globalFilter} emptyMessage="No students found." loading={this.state.loading}
                        >
                            {/* <Column body={this.fileExtensionTemplate}></Column> */}
                            <Column field='document' body={this.fileExtensionTemplate} header="Document name"></Column>
                            <Column field='doc_type' header="Document type"></Column>
                            <Column body={this.fileBodyTemplate} header='Document'></Column>
                            <Column body={this.actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>

                {/* DIALOGS */}
                <Dialog visible={this.state.docDialog} header='Complete de information' modal footer={footer} onHide={this.hideDialog} breakpoints={{'100px':'75vw'}} style={{ width: '50vw'}} >
                    {/* <br/>
                    <br/> */}
                    <div>
                        <div className='form-group row'>
                            <div className='col-lg-3'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-file'/>
                                    </span>
                                    <span className='p-float-label'>
                                        <Dropdown
                                            style={{ width: 250, height: 33}}
                                            id='doc_type'
                                            variant='outlined'
                                            // placeholder='Select document type...'
                                            margin='dense'
                                            value={this.state.selectedDocType}
                                            options={this.state.doc_types}
                                            onChange={this.onDocTypeChange}
                                            optionLabel='doc_type'
                                            optionValue='id'
                                        />
                                        <label htmlFor='doc_type'>Document type</label>
                                    </span>
                                </div>
                            </div>

                            <div className='col-lg-3'>
                                {this.FileDocument()}
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteDialog} style={{ width: '450px' }} header='Confirm' modal footer={deleteFooter} onHide={this.hideDeleteDialog} >
                    <div>
                        <h1>Are you sure to delete this file?</h1>
                    </div>
                </Dialog>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    invoice: state.auth.invoiceId,
    user: state.auth.user
})

export default injectIntl(
    connect(mapStateToProps, auth.actions)(InvoicesDocuments)
)