import React from "react";
import {useSubheader} from "../../_metronic/_core";

import PaymentTransfersTable from "../modulesAccounting/Transfers/PaymentTransfersTable"

export const PaymentTransfersPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("PaymentTransfers");

  return (<><PaymentTransfersTable /></>);
};