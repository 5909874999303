/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useIntl } from "react-intl";

export function DropdownMenu2() {
    const int1 = useIntl();
    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
            <li className="navi-header pb-1">
                <span className="text-primary text-uppercase font-weight-bold font-size-sm">{int1.formatMessage({id: "DROPDOWNS.MENU.ADD"})}</span>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-shopping-cart-1"></i></span>
                    <span className="navi-text">{int1.formatMessage({id: "DROPDOWNS.ORDER"})}</span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-calendar-8"></i></span>
                    <span className="navi-text">{int1.formatMessage({id: "DROPDOWNS.EVENT"})}</span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-graph-1"></i></span>
                    <span className="navi-text">{int1.formatMessage({id: "DROPDOWNS.REPORT"})}</span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-rocket-1"></i></span>
                    <span className="navi-text">{int1.formatMessage({id: "DROPDOWNS.POST"})}</span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-writing"></i></span>
                    <span className="navi-text">{int1.formatMessage({id: "DROPDOWNS.FILE"})}</span>
                </a>
            </li>
        </ul>
        {/*end::Navigation*/}
    </>
}
