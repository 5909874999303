// Nuevo codigo
// EditInvoice - Accounting
import React, { Component } from 'react'
import classNames from 'classnames';
import axios from 'axios'
import ServerURL from '../../../ServerURL'
//import AddIcon from '@material-ui/icons/Add';
//import Fab from '@material-ui/core/Fab';
//import Tooltip from '@material-ui/core/Tooltip';
//import { DataTable } from 'primereact/datatable';
//import { Toast } from 'primereact/toast';
//import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card'
import { Checkbox } from 'primereact/checkbox'
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact';


import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css'

import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';

//import * as Validation from '../../../Validation';

const url = ServerURL();

class EditInvoice extends Component {

  emptyInvoice = {
    invoiceId: null,
    externalId: null,
    invoiceTypeId: null,
    invoiceType: null,
    invoiceStatusId: null,
    invoiceStatus: null,
    note: null,
    currencyId: null,
    currencyCode: null,
    currencySymbol: null,
    currencyOneUsd: null,
    totalAmount: null,
    totalBalance: null,
    totalBalanceAgency: null,
    studentId: null,
    student: null,
    studentEmail: null,
    enterpriseId: null,
    enterprise: null,
    enterpriseUrl: null,
    agencyId: null,
    agency: null,
    agencyEmail: null,
    agentId: null,
    agent: null,
    agentEmail: null,
    created_at: null,
    updated_at: null,
    invoice_gross_file_url: '',
    invoice_gross_file: null,
    invoice_net_file_url: '',
    invoice_net_file: null,
    validateInvoice: 0,
  }

  emptyNetInvoice = {
    invoiceId: null,
    externalId: null,
    invoiceTypeId: null,
    invoiceType: null,
    invoiceStatusId: null,
    invoiceStatus: null,
    note: null,
    currencyId: null,
    currencyCode: null,
    currencySymbol: null,
    currencyOneUsd: null,
    totalAmount: null,
    totalBalance: null,
    totalBalanceAgency: null,
    studentId: null,
    student: null,
    studentEmail: null,
    enterpriseId: null,
    enterprise: null,
    enterpriseUrl: null,
    agencyId: null,
    agency: null,
    agencyEmail: null,
    agentId: null,
    agent: null,
    agentEmail: null,
    created_at: null,
    updated_at: null,
    invoice_gross_file_url: '',
    invoice_gross_file: null,
    invoice_net_file_url: '',
    invoice_net_file: null,
    program_profit: null,
    validateInvoice: 0,
  }

  emptyInvoiceItem = {
    invoiceItemId: null,
    invoiceId: null,
    externalId: null,
    invoiceItemTypeId: null,
    invoiceItemType: null,
    invoiceItemTypeDisplayOrder: null,
    provider: null,
    program: null,
    agencyId: null,
    agency: null,
    isAgencyItem: null,
    isGlobal: null,
    position: null,
    durationAmount: null,
    durationTypeId: null,
    durationType: null,
    startDate: null,
    endDate: null,
    amount: null,
    balance: null,
    code: null,
    symbol: null,
    selectedCurrency: null,
    resetCurrency: null,
    totalAmount: null,
    totalAmountAgency: null,
    totalBalance: null,
    totalBalanceAgency: null,
    isAmountPerDuration: null,
    serviceFeeMultiplier: null,
    currencyId: null,
    currencyCode: null,
    currencySymbol: null,
    currencyOneUsd: null,
    snapshotExtensionDurationTypeId: null,
    snapshotBonusDurationAmount: null,
    snapshotFreeDurationAmount: null,
    snapshotPromotionApplicableTypeId: null,
    snapshotPromotionSecondaryTypeId: null,
    snapshotPromotionPercentage: null,
    notes: null,
    created_at: null,
    updated_at: null,
    itemAmountEdit: null,
    itemStartDateEdit: null,
    itemEndDateEdit: null,
    itemAmountAccommodationEdit: null,
    itemStartAccommodationDateEdit: null,
    itemEndDateAccommodationEdit: null,
    itemAmountServiceEdit: null,
    itemMultiplierServiceEdit: null,
    itemAmountFeeEdit: null,
    itemMultiplierFeeEdit: null,
    itemStartFeeDateEdit: null,
    itemEndDateFeeEdit: null,
    itemAmountDiscountEdit: null,
    itemMultiplierDiscountEdit: null,
    itemStartDiscountDateEdit: null,
    itemEndDateDiscountEdit: null,
  }

  emptyInvoiceNetItem = {
    invoiceItemId: null,
    invoiceId: null,
    externalId: null,
    invoiceItemTypeId: null,
    invoiceItemType: null,
    invoiceItemTypeDisplayOrder: null,
    provider: null,
    program: null,
    agencyId: null,
    agency: null,
    isAgencyItem: null,
    isGlobal: null,
    position: null,
    durationAmount: null,
    durationTypeId: null,
    durationType: null,
    startDate: null,
    endDate: null,
    amount: null,
    balance: null,
    code: null,
    symbol: null,
    selectedCurrency: null,
    resetCurrency: null,
    totalAmount: null,
    totalAmountAgency: null,
    totalBalance: null,
    totalBalanceAgency: null,
    isAmountPerDuration: null,
    serviceFeeMultiplier: null,
    currencyId: null,
    currencyCode: null,
    currencySymbol: null,
    currencyOneUsd: null,
    snapshotExtensionDurationTypeId: null,
    snapshotBonusDurationAmount: null,
    snapshotFreeDurationAmount: null,
    snapshotPromotionApplicableTypeId: null,
    snapshotPromotionSecondaryTypeId: null,
    snapshotPromotionPercentage: null,
    notes: null,
    created_at: null,
    updated_at: null,

    itemAmountEdit: null,
    itemStartDateEdit: null,
    itemEndDateEdit: null,

    itemAmountAccommodationEdit: null,
    itemStartAccommodationDateEdit: null,
    itemEndDateAccommodationEdit: null,

    itemAmountServiceEdit: null,
    itemMultiplierServiceEdit: null,

    itemAmountFeeEdit: null,
    itemMultiplierFeeEdit: null,
    itemStartFeeDateEdit: null,
    itemEndDateFeeEdit: null,

    itemAmountDiscountEdit: null,
    itemMultiplierDiscountEdit: null,
    itemStartDiscountDateEdit: null,
    itemEndDateDiscountEdit: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      invoice: this.emptyInvoice,
      net_invoice: this.emptyNetInvoice,
      invoiceItem: this.emptyInvoiceItem,
      invoiceNetItem: this.emptyInvoiceNetItem,

      isAgencyItemMessage: '',
      providerMessage: '',
      startDateMessage: '',
      endDateMessage: '',
      programMessage: '',
      amountMessage: '',
      currencyIdMessage: '',

      showInvoice: null,
      showNetInvoice: null,
      invoiceItems: null,
      invoiceNetItems: null,
      currencies: null,

      globalFilter: null,
      selectedInvoices: null,

      invoiceDialogItem: false,
      deleteInvoiceDialog: false,
      deleteInvoicesDialog: false,

      deleteInvoiceItemDialog: false,
      deleteNetInvoiceItemDialog: false,

      assignInvoicesDialog: false,
      CheckedIsAgencyItem: false,
      checkedValidate: false,

      editInvoice: false,
      openSaveInvoiceDialog: false,

      selectedCurrency: null,
      selectedItemType: '',
      selectedItemGrossNet: null,
      resetCurrency: null,
      edit: false,
      // newRate: null,
      newInvoiceItemDialog: false,
      invoice_gross_file: null,
      invoice_net_file: null,

      submitted: false,
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate(),

      checked2: false,
    }

    this.ItemTypes = [
      { type: this.state.campusText.Agency, id: 1 },
      { type: this.state.campusText.School, id: 0 },
    ];

    this.itemGrossNet = [
      { type: 'Gross', id: 0 },
      { type: 'Net', id: 1 }
    ]

    this.currencies = [
      { code: 'USD', id: 142 }
    ]

    //this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.saveInvoiceItem = this.saveInvoiceItem.bind(this);
    this.createItem = this.createItem.bind(this);
    //this.deleteInvoice = this.deleteInvoice.bind(this);
    //this.openNew = this.openNew.bind(this);
    this.openNewItem = this.openNewItem.bind(this);
    this.editInvoice = this.editInvoice.bind(this);
    this.saveInvoice = this.saveInvoice.bind(this);
    //this.exportCSV = this.exportCSV.bind(this);
    //this.deleteInvoiceItem = this.deleteInvoiceItem.bind(this);
    this.confirmDeleteInvoiceItem = this.confirmDeleteInvoiceItem.bind(this);
    this.hideDeleteInvoiceItemDialog = this.hideDeleteInvoiceItemDialog.bind(this);

    this.confirmSaveInvoiceDialog = this.confirmSaveInvoiceDialog.bind(this)
    this.hideSaveInvoiceDialog = this.hideSaveInvoiceDialog.bind(this)
    this.cancelInvoice = this.cancelInvoice.bind(this)
    this.onValidateChange = this.onValidateChange.bind(this)

    //  this.confirmNewInvoiceItem = this.confirmNewInvoiceItem.bind(this);
    this.hideNewItemDialog = this.hideNewItemDialog.bind(this);

    //this.onIsAgencyItemChange = this.onIsAgencyItemChange.bind(this);
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.onCurrencyNetChange = this.onCurrencyNetChange.bind(this);
    this.onInputCreateChange = this.onInputCreateChange.bind(this);
    this.onInputNetCreateChange = this.onInputNetCreateChange.bind(this);
    this.onItemTypeChange = this.onItemTypeChange.bind(this);
    this.onNetItemTypeChange = this.onNetItemTypeChange.bind(this);
    this.onItemGrossNetChange = this.onItemGrossNetChange.bind(this);
    this.onInputCurrency = this.onInputCurrency.bind(this);
    this.saveInvoiceNet = this.saveInvoiceNet.bind(this);
    this.saveInvoiceGross = this.saveInvoiceGross.bind(this);
  }

  componentDidMount() {
    this.getInitialData()
  }

  isValid() {
    const { program, amount, currencyId } = this.state.invoiceItem
    const programMessage = this.validateProgram()
    const amountMessage = this.validateAmount()
    const currencyIdMessage = this.validateCurrencyId()

    if (program && amount && currencyId) {
      if (!programMessage && !amountMessage && !currencyIdMessage) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  validateIsAgencyItem() {
    const invoiceItem = this.state.invoiceItem

    let isAgencyItemMessage

    if (!invoiceItem.isAgencyItem === 0 && !invoiceItem.isAgencyItem === 1) {
      isAgencyItemMessage = 'Requerido'
    } else {
      isAgencyItemMessage = ''
    }

    this.setState({ isAgencyItemMessage })
    return isAgencyItemMessage
  }

  getTextAuxIsAgencyItem() {
    const message = this.state.isAgencyItemMessage
    return message
  }

  validateProgram() {
    const invoiceItem = this.state.invoiceItem
    let programMessage
    if (!invoiceItem.program) {
      programMessage = 'Requerido'
    } else {
      programMessage = ''
    }
    this.setState({ programMessage })
    return programMessage
  }

  getTextAuxProgram() {
    const message = this.state.programMessage
    return message
  }

  validateAmount() {
    const invoiceItem = this.state.invoiceItem
    let amountMessage
    if (!invoiceItem.amount) {
      amountMessage = 'Requerido'
    } else {
      amountMessage = ''
    }
    this.setState({ amountMessage })
    return amountMessage
  }

  getTextAuxAmount() {
    const message = this.state.amountMessage
    return message
  }

  validateCurrencyId() {
    const invoiceItem = this.state.invoiceItem

    let currencyIdMessage
    if (!invoiceItem.currencyId) {
      currencyIdMessage = 'Requerido'
    } else {
      currencyIdMessage = ''
    }
    this.setState({ currencyIdMessage })
    return currencyIdMessage
  }

  getTextAuxCurrencyId() {
    const message = this.state.currencyIdMessage
    return message
  }

  getInitialData = async () => {
    let id = this.props.invoiceId;
    let data = {
      date: null
    }

    data.date = this.Today()
    // console.log("DATA: ", data);
    // console.log("INVOICE ID REDUX:",id)

    let requestOne = axios.get(`${url}/api/auth/invoice/${id}`);
    let requestTwo = axios.post(`${url}/api/auth/invoiceItems/${id}`, data);
    let requestThree = axios.get(`${url}/api/auth/net_invoice/${id}`)
    let requestFour = axios.post(`${url}/api/auth/invoiceNetItems/${id}`)
    let requestFive = axios.post(`${url}/api/auth/getExchangeRates`, data)
    // let requestThree  = axios.post(`${url}/api/auth/getExchangeRates`,data)

    axios.all([
      requestOne,
      requestTwo,
      requestThree,
      requestFour,
      requestFive
    ]).then(axios.spread((...responses) => {
      const invoice = responses[0].data;
      const invoiceItems = responses[1].data;
      const net_invoice = responses[2].data
      const invoiceNetItems = responses[3].data
      const currencies = responses[4].data

      // console.log("INVOICE:", invoice)
      // console.log("NET INVOICE:", net_invoice)
      // // console.log("CURRENCIES: ", currencies);
      console.log("INVOICE ITEMS: ", invoiceItems);
      console.log("INVOICES NET ITEMS: ", invoiceNetItems);

      // console.log("INVOICE ITEMS:",invoiceItems[0])

      this.symbolInvoice(invoice)
      this.symbolInvoiceAgency(invoice)

      this.symbolAmount(invoice)
      this.symbolAmountAgency(invoice)
      this.DateInvoice(invoice)

      let showInvoice = null;
      showInvoice = invoice[0]

      let showNetInvoice = null
      showNetInvoice = net_invoice[0]

      let program_profit = 0
      let agency_profit = 0
      let total_profit = 0

      program_profit = invoice[0].totalAmount - net_invoice[0].totalAmount
      agency_profit = invoice[0].totalAmountAgency - net_invoice[0].totalAmountAgency
      total_profit = program_profit + agency_profit

      invoice[0].program_profit = program_profit
      net_invoice[0].program_profit = program_profit

      invoice[0].agency_profit = agency_profit
      net_invoice[0].agency_profit = agency_profit

      invoice[0].total_profit = total_profit
      net_invoice[0].total_profit = total_profit

      // this.addCurrencies(invoice, net_invoice, currencies)

      console.log("TOTAL PROFIT: ", total_profit);

      this.setState({
        invoice,
        invoiceItems,
        showInvoice,
        net_invoice,
        invoiceNetItems,
        showNetInvoice,
        currencies,
        checkedValidate: false,
      })
    })).catch(errors => {
      // react on errors.
      console.error(errors);
    });
  }

  addCurrencies(invoice, net_invoice, currencies) {
    // invoice = {...this.state.showInvoice}
    // let net_invoice = {...this.state.showNetInvoice }
    let program_profit_convert = 0
    let agency_profit_convert = 0
    let rate_base = 0
    let total_profit_convert = 0
    let total_profit = 0

    console.log("INVOICE: ", invoice);

    for (let currency = 0; currency < Object.keys(currencies).length; currency++) {
      if (currencies[currency].code === invoice[0].code) {
        program_profit_convert = currencies[currency].rate * invoice[0].program_profit
        rate_base = currencies[currency].rate

        console.log('PROGRAM PROFIT CONVERT: ', program_profit_convert.toFixed(2));
      }

      if (currencies[currency].code === invoice[0].codeA) {
        agency_profit_convert = currencies[currency].rate * invoice[0].agency_profit

        console.log('AGENCY PROFIT CONVERT: ', agency_profit_convert.toFixed(2));
      }

      total_profit_convert = program_profit_convert + agency_profit_convert
      total_profit = total_profit_convert / rate_base
    }

    console.log("TOTAL PROFIT CONVERT: ", total_profit_convert);
    console.log("TOTAL PROFIT: ", total_profit);

    invoice[0].total_profit = total_profit.toFixed(2)
    net_invoice[0].total_profit = total_profit.toFixed(2)

    this.setState({
      invoice,
      net_invoice,
      currencies
    })

    // invoice[0].total_profit_convert = total_profit_convert

    // console.log("INVOICE: ", invoice);
    // console.log("NET INVOICE: ", net_invoice);
    // console.log("CURRENCIES: ", currencies);
  }

  // positiveExchanges(e) {
  //   let positiveExchanges = e

  //   console.log("TC: ", positiveExchanges);
  // }

  Today() {
    let date = new Date()
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();

    if (d < 10) {
      d = '0' + d;
    }
    if (m < 10) {
      m = '0' + m;
    }

    let today = d + '/' + m + '/' + y
    return today
  }

  getCurrencies = async () => {
    try {
      let res = axios.get(`${url}/api/auth/currencies`)
        .then(res => {
          const currencies = res.data;
          this.setState({ currencies })
        });
      console.log(res.data)
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  createItem = async () => {
    let data = { ...this.state.invoiceItem };
    console.log("DATA EN CREATE ITEM", data)

    let state = { submitted: true };
    const decision = this.isValid();

    if (decision) {
      try {
        let requestOne = axios.post(`${url}/api/auth/create_invoiceItem`, data)
        let requestTwo = axios.post(`${url}/api/auth/create_net_item`, data)

        let res = axios.all([
          requestOne,
          requestTwo
        ]).then(axios.spread((...responses) => {
          this.getInitialData()
        }))

        if (!res.error) {
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice Items Created', life: 3000 });
        }

      } catch (error) {
        console.log("ERROR: ", error);
      }

      state = {
        ...state,
        data,
        newInvoiceItemDialog: false,
        invoiceItem: this.emptyInvoiceItem,
        invoiceNetItem: this.emptyInvoiceNetItem,
        selectedCurrency: null,
        CheckedIsAgencyItem: false,
      };
    }
    this.setState(state);
    this.getInitialData()
  }

  async saveInvoiceItem(e, name, item, gross_net) {
    // this.getInitialData()
    let state = { submitted: true };
    let invoice = { ...this.state.showInvoice }
    let net_invoice = { ...this.state.showNetInvoice }
    let newRate = null

    // console.log(this.props.user);

    console.log('E: ', e);
    console.log('Name: ', name);
    console.log('Item: ', item);
    console.log("Gross o net: ", gross_net);
    // console.log("Program profit: ", program_profit);

    if (gross_net === 'Net') {
      console.log("ENTRO A CONDICION NET");
      item[`${name}`] = e;

      let data = {
        ...this.state.invoiceNetItem,
        date: null,
        total_profit: null,
        externalId: null,
      };

      if (item.invoiceItemTypeId === 1) {
        data.total_profit = invoice.total_profit
        data.externalId = invoice.externalId

        console.log("PROGRAM PROFIT: ", data.total_profit);
      } else if (item.invoiceItemTypeId === 2 || item.invoiceItemTypeId === 3 || item.invoiceItemTypeId === 4 || item.invoiceItemTypeId === 5) {
        data.total_profit = item.amountToPaid - data.amount
        data.externalId = invoice.externalId

        console.log("PROGRAM PROFIT: ", data.total_profit);
      }

      let requestOne
      let requestTwo

      data.date = this.Today()

      console.log("SAVE NET ITEM: ", data);

      try {
        requestOne = axios.put(`${url}/api/auth/invoiceNetItem/${data.invoiceItemId}`, data)
        requestTwo = axios.put(`${url}/api/auth/balance_net/${data.invoiceItemId}`, data)

        axios.all([
          requestOne,
          requestTwo
        ]).then(axios.spread((...responses) => {
          newRate = responses[0].data

          console.log(newRate);

          this.getInitialData()
        }))

      } catch (error) {
        console.log("ERROR: ", error);
      }

    } else if (gross_net === 'Gross') {
      console.log("ENTRO A CONDICION GROSS");
      item[`${name}`] = e;
      let data = {
        ...this.state.invoiceItem,
        date: null,
        total_profit: null,
        externalId: null,
      };

      data.date = this.Today()
      data.externalId = invoice.externalId
      data.total_profit = invoice.total_profit

      console.log("SAVE ITEM: ", data);

      try {

        let requestOne = axios.put(`${url}/api/auth/invoiceItem/${data.invoiceItemId}`, data)
        let requestTwo = axios.put(`${url}/api/auth/balance/${data.invoiceItemId}`, data)

        axios
          .all([
            requestOne,
            requestTwo
          ])
          .then(axios.spread((...responses) => {
            newRate = responses[0].data
            console.log(newRate);
            this.getInitialData()
          }))
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }

    state = {
      ...state,
      invoice: this.emptyInvoice,
      invoiceItem: this.emptyInvoiceItem,
      invoiceNetItem: this.emptyInvoiceNetItem,
      selectedItemType: null,
      edit: false,
      newRate
    };
    this.setState(state)
    this.getInitialData()
  }

  symbolInvoice(e) {
    let dollarUSLocale = Intl.NumberFormat('en-US')

    e.map((invoice) => {
      invoice.symbolInvoice = invoice.symbol + ' ' + dollarUSLocale.format(invoice.totalInvoice) + ' ' + invoice.code;
      return invoice
    })
  }

  symbolInvoiceAgency(e) {
    let dollarUSLocale = Intl.NumberFormat('en-US')

    e.map((invoice) => {
      invoice.symbolInvoiceAgency = invoice.symbolA + ' ' + dollarUSLocale.format(invoice.totalInvoiceAgency) + ' ' + invoice.codeA;
      return invoice
    })
  }

  symbolAmount(e) {
    let dollarUSLocale = Intl.NumberFormat('en-US');

    e.map((invoice) => {
      invoice.symbolAmount = invoice.symbol + ' ' + dollarUSLocale.format(invoice.totalAmount) + ' ' + invoice.code;
      return invoice
    });
  }

  symbolAmountAgency(e) {
    let dollarUSLocale = Intl.NumberFormat('en-US');

    e.map((invoice) => {
      //console.log("E:",agreement.updated_at)
      invoice.symbolAmountAgency = invoice.symbolA + ' ' + dollarUSLocale.format(invoice.totalAmountAgency) + ' ' + invoice.codeA;
      console.log("INVOICE CON SYMBOL", invoice)
      return invoice
    });
  }

  deleteInvoiceItem = async () => {
    let state = { submitted: true };
    let data = { ...this.state.invoiceItem };

    console.log("DATA: ", data);

    if (data.gross_net === 'Gross') {
      console.log("GROSS");

      try {
        await axios.delete(`${url}/api/auth/invoiceItem/${data.id}`).then(res => {
          console.log(res);
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice item deleted', life: 3000 })
        })
      } catch (error) {
        console.log("Error: ", error);
      }
    } else if (data.gross_net === 'Net') {
      console.log("NET");

      try {
        await axios.delete(`${url}/api/auth/invoiceNetItem/${data.id}`).then(res => {
          console.log(res);
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice item deleted', life: 3000 })
        })
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }

    // try {
    //   await axios.delete(`${url}/api/auth/invoiceItem/${data.id}`).then(res => {
    //     console.log(res)
    //     this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice Item Deleted', life: 3000 });
    //   })      

    // }catch(error){
    //   console.log("Error: ", error);
    // }

    state = {
      ...state,
      data,
      deleteInvoiceItemDialog: false,
      invoiceItem: this.emptyInvoiceItem,
    };

    this.setState(state);
    this.getInitialData()
  }

  DateInvoice(e) {
    e.map((invoice) => {
      //console.log("E:",agreement.updated_at)

      const date = new Date(invoice.created_at);

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      let new_date = dt + '/' + month + '/' + year
      //console.log("New Data:",new_date)

      invoice.created_at = new_date

      return invoice
    });
  }

  Date(e) {
    let year = e.getFullYear();
    let month = e.getMonth() + 1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = day + '/' + month + '/' + year

    return new_date
  }

  openNewItem(e) {
    this.getCurrencies()
    let invoice = { ...this.state.invoice }
    let net_invoice = { ...this.state.net_invoice }

    let invoiceItem = { ...this.state.invoiceItem };
    let invoiceNetItem = { ...this.state.invoiceNetItem }

    console.log("INVOICE EN NEW ITEM", invoice)
    console.log("NET INVOICE EN NEW ITEM", net_invoice);

    invoiceItem['invoiceId'] = invoice[0].invoiceId
    invoiceItem['externalId'] = invoice[0].externalId
    invoiceItem['agencyId'] = invoice[0].agencyId
    invoiceItem['agency'] = invoice[0].agency

    invoiceNetItem['invoiceId'] = invoice[0].invoiceId
    invoiceNetItem['externalId'] = invoice[0].externalId
    invoiceNetItem['agencyId'] = invoice[0].agencyId
    invoiceNetItem['agency'] = invoice[0].agency

    this.setState({
      submitted: false,
      newInvoiceItemDialog: true,
      invoiceItem,
      invoiceNetItem
    })
  }

  saveInvoice() {
    let state = { submitted: false }
    let invoice = { ...this.state.showInvoice }
    let inv = { ...this.state.invoice }
    let net_invoice = { ...this.state.showNetInvoice }
    let id = this.props.invoiceId
    // let newRate = null
    // let editInvoice = false

    let data = {
      ...this.state.invoice
    }

    data = invoice
    data.totalNet = net_invoice.totalAmount
    data.invoiceValidate = inv.validateInvoice
    // data.validateInvoice = invoice.validateInvoice

    console.log("SAVE INVOICE: ", data);

    try {
      let requestOne = axios.put(`${url}/api/auth/save-invoice/${id}`, data)
      let requestTwo = axios.put(`${url}/api/auth/save-net-invoice/${id}`, data)

      let res = axios.all([
        requestOne,
        requestTwo
      ]).then(axios.spread((...responses) => {
        this.getInitialData()
      }))

      if (!res.error) {
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice saved', life: 3000 });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
    // state = {
    //   ...state,
    //   editInvoice: false
    // }
    this.setState({ state, editInvoice: false, openSaveInvoiceDialog: false })
    this.getInitialData()
  }

  confirmSaveInvoiceDialog() {
    let invoice = { ...this.state.invoice }
    this.setState({ invoice, openSaveInvoiceDialog: true })
  }

  hideSaveInvoiceDialog() {
    this.setState({ openSaveInvoiceDialog: false })
  }

  editInvoice() {
    let editInvoice = true

    console.log("Edit invoice: ", editInvoice);

    this.setState({ editInvoice })
  }

  cancelInvoice() {
    let editInvoice = false
    console.log("Edit invoice: ", editInvoice);

    this.setState({ editInvoice })
  }

  hideDialogItem() {
    this.setState({
      submitted: false,
      invoiceItemDialog: false,
      invoiceItem: this.emptyInvoiceItem,
      invoiceNetItem: this.emptyInvoiceNetItem,
      programMessage: '',
      amountMessage: '',
      currencyIdMessage: '',
    })
  }

  OneditInvoiceItem(e, name, data) {
    let invoiceItem = { ...this.state.invoiceItem };
    invoiceItem = data
    invoiceItem[`${name}`] = e;
    this.setState({ invoiceItem, edit: true });
  }

  onEditInvoiceNetItem(e, name, data) {
    let invoiceNetItem = { ...this.state.invoiceNetItem }
    invoiceNetItem = data
    console.log("INVOICE NET ITEM: ", invoiceNetItem);

    invoiceNetItem[`${name}`] = e

    // this.saveInvoiceItem()
    this.setState({ invoiceNetItem, edit: true })
  }

  onInputChange(e, name, data) {
    const val = (e.target && e.target.value) || '';
    let invoiceItem = { ...this.state.invoiceItem };
    invoiceItem = data
    invoiceItem[`${name}`] = val;

    this.setState({ invoiceItem });
  }

  onInputNetChange(e, name, data) {
    const val = (e.target && e.target.value) || '';
    let invoiceNetItem = { ...this.state.invoiceNetItem };
    invoiceNetItem = data
    invoiceNetItem[`${name}`] = val;

    this.setState({ invoiceNetItem });
  }

  onInputCreateChange(e, name) {
    let invoiceItem = { ...this.state.invoiceItem };
    invoiceItem[`${name}`] = e.target.value;
    this.setState({ invoiceItem });
  }

  onInputNetCreateChange(e, name) {
    let invoiceNetItem = { ...this.state.invoiceNetItem }
    invoiceNetItem[`${name}`] = e.target.value
    this.setState({ invoiceNetItem })
  }

  onCurrencyChange(e) {
    let invoiceItem = { ...this.state.invoiceItem };

    invoiceItem['currencyId'] = e.value;

    this.setState({ selectedCurrency: e.value, invoiceItem });
  }

  onCurrencyNetChange(e) {
    let invoiceNetItem = { ...this.state.invoiceNetItem }

    invoiceNetItem['currencyId'] = e.value;

    this.setState({ selectedCurrency: e.value, invoiceNetItem })
  }


  onItemTypeChange(e) {
    let invoiceItem = { ...this.state.invoiceItem }
    let invoiceNetItem = { ...this.state.invoiceNetItem }
    let invoice = { ...this.state.invoice }
    let net_invoice = { ...this.state.net_invoice }

    console.log("IS AGENCY:", e.value)
    invoiceItem['isAgencyItem'] = e.value
    invoiceItem['provider'] = invoice[0].enterprise

    invoiceNetItem['isAgencyItem'] = e.value
    invoiceNetItem['provider'] = net_invoice[0].enterprise
    if (e.value === 0) {
      invoiceItem['currencyId'] = invoice[0].currencyId
      invoiceNetItem['currencyId'] = net_invoice[0].currencyId
    }


    this.setState({ selectedItemType: e.value, invoiceItem, invoiceNetItem });
  }

  onNetItemTypeChange(e) {

    let invoiceNetItem = { ...this.state.invoiceNetItem }
    let invoice = { ...this.state.invoice }

    console.log("IS NET AGENCY: ", e.value);
    invoiceNetItem['isAgencyItem'] = e.value
    invoiceNetItem['provider'] = invoice[0].enterprise

    if (e.value === 0) {
      invoiceNetItem['currencyId'] = invoice[0].currencyId
    }

    this.setState({ selectedItemType: e.value, invoiceNetItem })
  }

  onDateChange(e, name, data) {
    const val = this.Date(e.target.value) || '';
    //console.log("E:", this.Date(e.target.value))
    let invoiceItem = { ...this.state.invoiceItem };
    invoiceItem = data

    invoiceItem[`${name}`] = val;

    //console.log("ONE ITEM:",invoiceItem)
    this.setState({ invoiceItem });
  }

  onItemGrossNetChange(e) {
    console.log("Gross o net: ", e.value);
    this.setState({ selectedItemGrossNet: e.value })
  }

  onInputCurrency(e) {
    let invoiceItem = { ...this.state.invoiceItem };
    let invoice = { ...this.state.showInvoice }
    console.log("E:", e, invoice.currencyId)
    invoiceItem['currencyId'] = invoice.currencyId
    this.setState({ invoiceItem });
  }

  /*formatCurrency = (value) => {
  return value.toLocaleString('en-US', {mode:"currency", maxFractionDigits:2, minFractionDigits:2});
  
  }*/

  confirmDeleteInvoiceItem(invoiceItem) {
    // console.log("ITEM: ", invoiceItem);
    this.setState({
      invoiceItem,
      deleteInvoiceItemDialog: true
    })
  }

  hideDeleteInvoiceItemDialog() {
    this.setState({ deleteInvoiceItemDialog: false })
  }


  hideNewItemDialog() {
    this.setState({
      newInvoiceItemDialog: false,
      programMessage: '',
      amountMessage: '',
      currencyIdMessage: '',
      selectedCurrency: '',
      selectedItemType: '',
      invoiceItem: this.emptyInvoiceItem
    })
  }

  renderInvoice = () => {
    let invoice = { ...this.state.showInvoice }
    console.log("EN RENDER INVOICE:", invoice)

    let showInvoice = []

    showInvoice.push(
      <>
        <tr class="top">
          <td colspan="1">
            <table>
              <tr>
                <td style={{ color: 'black' }}>
                  {this.state.campusText.Invoice} #: {invoice.invoiceId}<br></br>
                  {this.state.campusText.Created}: {invoice.created_at}<br></br>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="information">
          <td colspan="1">
            <table>
              <tr>
                <td style={{ color: 'black' }}>
                  {this.state.campusText.CreatedBy}: {invoice.agency}<br></br>
                  {invoice.agent}<br></br>
                  {invoice.agentEmail}
                </td>

                <td style={{ color: 'black' }}>
                  {this.state.campusText.CreatedTo}:    {invoice.student}<br></br>
                  {invoice.studentEmail}<br></br>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </>
    )
    return showInvoice
  }

  renderOrderNet = () => {

    let invoiceNetItems = { ...this.state.invoiceNetItems }
    let invoice = { ...this.state.showInvoice }
    // let newRate = this.state.newRate

    let programView = []
    let isTotalProgram = false
    let totalProgramView = []
    // let isNewProgram=false
    let NewProgramView = []


    let accommodationView = []
    let isTotalAccommodation = false
    let totalAccommodationView = []
    // let isNewAccommodation=false
    // let NewAccommodationView=[]


    let serviceView = []
    let isTotalService = false
    let totalServiceView = []
    // let isNewService=false
    // let NewServiceView=[]


    let feeView = []
    let totalFeeView = []
    let isTotalFee = false
    // let isNewFee=false
    // let NewFeeView=[]


    let discountView = []
    let totalDiscountView = []
    let isTotalDiscount = false
    let isNewDiscount = false
    let NewDiscountView = []

    let feeAgencyView = []
    // let isNewFeeAgency=false
    // let NewFeeAgencyView=[]
    let dollarUSLocale = Intl.NumberFormat('en-US');

    let gross_net = null

    let Order = []

    for (let i = 0; i < Object.keys(invoiceNetItems).length; i++) {
      gross_net = "Net"
      invoiceNetItems[i].gross_net = gross_net

      if (invoiceNetItems[i].invoiceItemTypeId === 1 && invoiceNetItems[i].isAgencyItem === 0 && invoiceNetItems[i] !== null) {
        if (this.props.user.fk_id_role === 58 || this.props.user.fk_id_role === 62) {
          if (this.state.invoiceNetItems[i].itemAmountEdit) {
            programView.push(
              // <div style={{fontSize:14}}>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.School}: {invoiceItems[i].provider}
              // </div>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
              // <InputNumber style={{width:150, height:25, fontSize:"14px", fontWeight:400, border:"none", padding:0}} id="amount" locale="en-US" minFractionDigits={2} min="0"  value={this.state.invoiceItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount',this.state.invoiceItems[i])}/>
              // <Button icon="pi pi-check" style={{ height:25, verticalAlign: 'middle'}} onClick={ (e) => this.saveInvoiceItem(e.value=null,'itemAmountEdit',this.state.invoiceItems[i])}></Button>                                         
              // </div>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
              // </div>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
              // </div>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.Course}: {invoiceItems[i].program}
              // </div>
              // </div>

              <Card>
                <div className='grid'>
                  <div className='col-fixed'>
                    <span className='text-3xl text-900'>Detalles del programa</span>
                  </div>

                  {/* <div className='col-fixed'>
                    {this.FileInvoiceNet()}
                  </div>

                  {invoice.invoice_net_file_url ?
                    <div className='col-fixed'>
                      {this.FileInvoicenNetDownload()}
                    </div>
                    :
                    <></>
                  } */}

                </div>

                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.School}: {invoiceNetItems[i].provider}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                    <InputNumber style={{ width: 150, height: 25, fontSize: "14px", fontWeight: 400, border: "none", padding: 0 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceNetItem.amount} onValueChange={(e) => this.onInputNetChange(e, 'amount', this.state.invoiceNetItems[i])} />
                    <Button icon="pi pi-check" style={{ height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountEdit', this.state.invoiceNetItems[i], invoiceNetItems[i].gross_net)}></Button>
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Course}: {invoiceNetItems[i].program}
                  </div>
                </div>
              </Card>
            )

            if (isTotalProgram === false) {
              totalProgramView.push(
                <Card>
                  <div><span className='text-3xl text-900'>Detalles del programa</span></div>

                  <td>
                    <div style={{ fontSize: 14 }}>
                      <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                        {this.state.campusText.TotalCourse}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                      </div>
                    </div>
                  </td>
                </Card>
              )
              isTotalProgram = true;
            }
          }
          else if (this.state.invoiceNetItems[i].itemStartDateEdit) {

            programView.push(
              // <div style={{fontSize:14}}>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.School}: {invoiceItems[i].provider}
              // </div>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
              // </div>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.StartDate}: {invoiceItems[i].startDate} 
              // <Calendar readOnlyInput="true" dateFormat="dd/mm/yy" style={{width:150, height:25, fontSize:"14px", fontWeight:400, border:"none", padding:0}}   id="startDate" value={this.state.invoiceItem.startDate} onChange={(e) => this.onDateChange(e, 'startDate',this.state.invoiceItems[i])} />
              // <Button icon="pi pi-check" style={{ height:25, verticalAlign: 'middle'}} onClick={ (e) => this.saveInvoiceItem(e.value=null,'itemStartDateEdit',this.state.invoiceItems[i])}></Button>
              // </div>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
              // </div>
              // <div style={{marginLeft:30, marginTop:10}}>
              // {this.state.campusText.Course}: {invoiceItems[i].program}
              // </div>
              // </div>

              <Card>
                <div className='grid'>
                  <div className='col-fixed'>
                    <span className='text-3xl text-900'>Detalles del programa</span>
                  </div>

                  {/* <div className='col-fixed'>
                    {this.FileInvoiceNet()}
                  </div>

                  {invoice.invoice_net_file_url ?
                    <div className='col-fixed'>
                      {this.FileInvoicenNetDownload()}
                    </div>
                    :
                    <></>
                  } */}

                </div>
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.School}: {invoiceNetItems[i].provider}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate}
                    <Calendar readOnlyInput="true" dateFormat="dd/mm/yy" style={{ width: 150, height: 25, fontSize: "14px", fontWeight: 400, border: "none", padding: 0 }} id="startDate" value={this.state.invoiceItem.startDate} onChange={(e) => this.onDateChange(e, 'startDate', this.state.invoiceNetItems[i])} />
                    <Button icon="pi pi-check" style={{ height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemStartDateEdit', this.state.invoiceNetItems[i])}></Button>
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Course}: {invoiceNetItems[i].program}
                  </div>
                </div>
              </Card>
            )

            if (isTotalProgram === false) {
              totalProgramView.push(
                //   <td> 
                //     <div style={{fontSize:14}}>              
                //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
                //     {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                //   </div>
                //     </div>
                // </td>

                <Card>
                  <div><span className='text-3xl text-900'>Detalles del programa</span></div>

                  <td>
                    <div style={{ fontSize: 14 }}>
                      <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                        {this.state.campusText.TotalCourse}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                      </div>
                    </div>
                  </td>
                </Card>
              )
              isTotalProgram = true;
            }

          }
          // v1.4.6 Se cambio la linea de codigo, ya que se esta llamando a una variable inexistente 
          else if (this.state.invoiceNetItems[i].itemEndDateEdit) {

            programView.push(
              // <div  style={{fontSize:14}}>
              // <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.School}: {invoiceItems[i].provider}
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
              //     <Calendar readOnlyInput="true" dateFormat="dd/mm/yy" style={{width:150, height:25, fontSize:"14px", fontWeight:400, border:"none", padding:0}}  id="endDate" value={this.state.invoiceItem.endDate} onChange={(e) => this.onDateChange(e, 'endDate',this.state.invoiceItems[i])}/>
              //     <Button icon="pi pi-check" style={{ height:25, verticalAlign: 'middle'}} onClick={ (e) => this.saveInvoiceItem(e.value=null,'itemEndDateEdit',this.state.invoiceItems[i])}></Button>
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.Course}: {invoiceItems[i].program}
              //     </div>
              //     </div>

              <Card>
                <div className='grid'>
                  <div className='col-fixed'>
                    <span className='text-3xl text-900'>Detalles del programa</span>
                  </div>

                  <div className='col-fixed'>
                    {this.FileInvoiceNet()}
                  </div>

                  {invoice.invoice_net_file_url ?
                    <div className='col-fixed'>
                      {this.FileInvoicenNetDownload()}
                    </div>
                    :
                    <></>
                  }

                </div>
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.School}: {invoiceNetItems[i].provider}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}
                    <Calendar readOnlyInput="true" dateFormat="dd/mm/yy" style={{ width: 150, height: 25, fontSize: "14px", fontWeight: 400, border: "none", padding: 0 }} id="endDate" value={this.state.invoiceNetItem.endDate} onChange={(e) => this.onDateChange(e, 'endDate', this.state.invoiceNetItems[i])} />
                    <Button icon="pi pi-check" style={{ height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemEndDateEdit', this.state.invoiceNetItems[i])}></Button>
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Course}: {invoiceNetItems[i].program}
                  </div>
                </div>
              </Card>
            )

            if (isTotalProgram === false) {
              totalProgramView.push(
                //   <td> 
                //     <div style={{fontSize:14}}>              
                //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
                //     {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                //   </div>
                //     </div>
                // </td>

                <Card>
                  <div><span className='text-3xl text-900'>Detalles del programa</span></div>

                  <td>
                    <div style={{ fontSize: 14 }}>
                      <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                        {this.state.campusText.TotalCourse}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                      </div>
                    </div>
                  </td>
                </Card>
              )
              isTotalProgram = true;
            }

          }
          else if (this.state.edit === true) {
            programView.push(
              //   <div style={{fontSize:14}}>
              // <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.School}: {invoiceItems[i].provider}
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType} 
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.StartDate}: {invoiceItems[i].startDate}  
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.EndDate}: {invoiceItems[i].endDate}  
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.Course}: {invoiceItems[i].program}
              //     </div>                      
              //   </div>

              <Card>
                <div className='grid'>
                  <div className='col-fixed'>
                    <span className='text-3xl text-900'>Detalles del programa</span>
                  </div>

                  {/* <div className='col-fixed'>
                    {this.FileInvoiceNet()}
                  </div>

                  {invoice.invoice_net_file_url ?
                    <div className='col-fixed'>
                      {this.FileInvoicenNetDownload()}
                    </div>
                    :
                    <></>
                  } */}

                </div>

                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.School}: {invoiceNetItems[i].provider}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Course}: {invoiceNetItems[i].program}
                  </div>
                </div>
              </Card>
            )
            if (isTotalProgram === false) {
              totalProgramView.push(
                //   <td> 
                //     <div style={{fontSize:14}}>              
                //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
                //     {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                //   </div>
                //     </div>
                // </td>

                <Card>
                  <div><span className='text-3xl text-900'>Detalles del programa</span></div>
                  <td>
                    <div style={{ fontSize: 14 }}>
                      <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                        {this.state.campusText.TotalCourse}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                      </div>
                    </div>
                  </td>
                </Card>
              )
              isTotalProgram = true;
            }

          } else {
            if (this.state.editInvoice === true) {
              programView.push(
                //   <div style={{fontSize:14}}>
                // <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.School}: {invoiceItems[i].provider}
                //     </div>
                //     <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemAmountEdit',this.state.invoiceItems[i])}></Button>
                //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                //     </div>
                //     <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.StartDate}: {invoiceItems[i].startDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemStartDateEdit',this.state.invoiceItems[i])}></Button> 
                //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                //     </div>
                //     <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.EndDate}: {invoiceItems[i].endDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemEndDateEdit',this.state.invoiceItems[i])}></Button>
                //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                //     </div>
                //     <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.Course}: {invoiceItems[i].program}
                //     </div>                      
                //   </div>

                <Card>
                  <div className='grid'>
                    <div className='col-fixed'>
                      <span className='text-3xl text-900'>Detalles del programa</span>
                    </div>

                    {/* <div className='col-fixed'>
                      {this.FileInvoiceNet()}
                    </div>

                    {invoice.invoice_net_file_url ?
                      <div className='col-fixed'>
                        {this.FileInvoicenNetDownload()}
                      </div>
                      :
                      <></>
                    } */}

                  </div>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ marginLeft: 30, marginTop: 10 }}>
                      {this.state.campusText.School}: {invoiceNetItems[i].provider}
                    </div>
                    <div style={{ marginLeft: 30, marginTop: 10 }}>
                      {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.onEditInvoiceNetItem(e.value = true, 'itemAmountEdit', this.state.invoiceNetItems[i])}></Button>
                      <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                    </div>
                    <div style={{ marginLeft: 30, marginTop: 10 }}>
                      {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate} {/* <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.onEditInvoiceNetItem(e.value=true, 'itemStartDateEdit',this.state.invoiceNetItems[i])}></Button>  */}
                      {/* <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceNetItems[i])}></Button> */}
                    </div>
                    <div style={{ marginLeft: 30, marginTop: 10 }}>
                      {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate} {/* <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.onEditInvoiceNetItem(e.value=true, 'itemStartDateEdit',this.state.invoiceNetItems[i])}></Button>  */}
                      {/* <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button> */}
                    </div>
                    <div style={{ marginLeft: 30, marginTop: 10 }}>
                      {this.state.campusText.Course}: {invoiceNetItems[i].program}
                    </div>
                  </div>

                  {/* { isNewProgram === false ?
                  NewProgramView.push(
                  <div style={{marginLeft:30, marginTop:10}}>
                  <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=1)}/>
                  </div>
                  )
                  :
                  NewProgramView.push(
                  <></>
                  )
                  } */}

                </Card>
              )
            } else {
              programView.push(
                //   <div style={{fontSize:14}}>
                // <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.School}: {invoiceItems[i].provider}
                //     </div>
                //     <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemAmountEdit',this.state.invoiceItems[i])}></Button>
                //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                //     </div>
                //     <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.StartDate}: {invoiceItems[i].startDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemStartDateEdit',this.state.invoiceItems[i])}></Button> 
                //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                //     </div>
                //     <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.EndDate}: {invoiceItems[i].endDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemEndDateEdit',this.state.invoiceItems[i])}></Button>
                //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                //     </div>
                //     <div style={{marginLeft:30, marginTop:10}}>
                //     {this.state.campusText.Course}: {invoiceItems[i].program}
                //     </div>                      
                //   </div>

                <Card>
                  <div className='grid'>
                    <div className='col-fixed'>
                      <span className='text-3xl text-900'>Detalles del programa</span>
                    </div>

                    {/* {invoice.invoice_net_file_url ?
                      <div className='col-fixed'>
                        {this.FileInvoicenNetDownload()}
                      </div>
                      :
                      <></>
                    } */}

                  </div>

                  <div style={{ fontSize: 16 }}>
                    <div style={{ marginLeft: 30, marginTop: 10 }}>
                      <i className='pi pi-book' />: {invoiceNetItems[i].program} <i className='pi pi-money-bill' />: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                    </div>

                    <div style={{ marginLeft: 30, marginTop: 10 }}>
                      <i className='pi pi-calendar-plus' />: {invoiceNetItems[i].startDate} <i className='pi pi-calendar-minus' />: {invoiceNetItems[i].endDate}
                    </div>

                    <div style={{ marginLeft: 30, marginTop: 10 }}>
                      <i className='pi pi-building' />: {invoiceNetItems[i].provider}
                    </div>

                    {/* <div style={{marginLeft:30, marginTop:10}}>
                    </div> */}

                  </div>

                  {/* { isNewProgram === false ?
                  NewProgramView.push(
                  <div style={{marginLeft:30, marginTop:10}}>
                  <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=1)}/>
                  </div>
                  )
                  :
                  NewProgramView.push(
                  <></>
                  )
                  } */}

                </Card>
              )
            }
            // if (isNewProgram===false){
            //   NewProgramView.push(
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=1)}/>
            //     </div>
            //   )
            //   isNewProgram=true;
            // }
            if (isTotalProgram === false) {
              totalProgramView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalCourse}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalProgram = true;
            }

          }
        } else if (this.props.user.fk_id_role === 64 || this.props.user.fk_id_role === 63) {
          if (this.state.editInvoice === true) {
            programView.push(
              <Card>
                <div className='grid'>
                  <div className='col-fixed'>
                    <span className='text-3xl text-900'>Detalles del programa</span>
                  </div>

                  {/* <div className='col-fixed'>
                    {this.FileInvoiceNet()}
                  </div>

                  {invoice.invoice_net_file_url ?
                    <div className='col-fixed'>
                      {this.FileInvoicenNetDownload()}
                    </div>
                    :
                    <></>
                  } */}
                </div>

                <div style={{ fontSize: 16 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-book' />: {invoiceNetItems[i].program} <i className='pi pi-money-bill' />: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                  </div>

                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-calendar-plus' />: {invoiceNetItems[i].startDate} <i className='pi pi-calendar-minus' />: {invoiceNetItems[i].endDate}
                  </div>

                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-building' />: {invoiceNetItems[i].provider}
                  </div>
                </div>
              </Card>
            )
          } else {
            programView.push(
              <Card>
                <div className='grid'>
                  <div className='col-fixed'>
                    <span className='text-3xl text-900'>Detalles del programa</span>
                  </div>

                  {/* <div className='col-fixed'> 
                    {this.FileInvoiceNet()}
                  </div> */}
                  {/* 
                  {invoice.invoice_net_file_url ?
                    <div className='col-fixed'>
                      {this.FileInvoicenNetDownload()}
                    </div>
                    :
                    <></>
                  } */}
                </div>

                <div style={{ fontSize: 16 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-book' />: {invoiceNetItems[i].program} <i className='pi pi-money-bill' />: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                  </div>

                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-calendar-plus' />: {invoiceNetItems[i].startDate} <i className='pi pi-calendar-minus' />: {invoiceNetItems[i].endDate}
                  </div>

                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-building' />: {invoiceNetItems[i].provider}
                  </div>
                </div>
              </Card>
            )
          }

          if (isTotalProgram === false) {
            totalProgramView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalCourse}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalProgram = true;
          }
          // if (isNewProgram===false){
          //   NewProgramView.push(
          //     <div style={{marginLeft:30, marginTop:10}}>
          //     <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=1)}/>
          //     </div>
          //   )
          //   isNewProgram=true;
          // }

        }
      }//COURSE

      else if (invoiceNetItems[i].invoiceItemTypeId === 2 && invoiceNetItems[i].isAgencyItem === 0 && invoiceNetItems[i] !== null) {
        if (this.props.user.fk_id_role === 58 || this.props.user.fk_id_role === 62) {
          if (this.state.invoiceNetItems[i].itemAmountAccommodationEdit) {
            accommodationView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Provider}: {invoiceNetItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Acommodation}: {invoiceNetItems[i].program}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                  <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceNetItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceNetItems[i])} />
                  <br />
                  <Dropdown
                    style={{ width: 150, height: 30 }}
                    id='invoice_item'
                    variant='outlined'
                    margin="dense"
                    value={this.state.selectedItemType}
                    onChange={this.onItemTypeChange}
                    options={this.ItemTypes}
                    optionLabel='type'
                    optionValue='id'
                  />
                  <br />
                  <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceNetItems[i], invoiceNetItems[i].gross_net)}></Button>
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}
                </div>
              </div>
            )
            if (isTotalAccommodation === false) {
              totalAccommodationView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalAccommodation}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalAccommodation = true;
            }

          } else if (this.state.invoiceNetItems[i].itemStartDateAccommodationEdit) {

            accommodationView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Provider}: {invoiceNetItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Accommodation}: {invoiceNetItems[i].program}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                  {/* <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.deleteInvoiceItem(e.value=this.state.invoiceNetItems[i].id)}></Button> */}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate}
                  {/* <Calendar readOnlyInput="true" dateFormat="dd/mm/yyyy" style={{width:150, height:25, fontSize:"14px", fontWeight:400, border:"none", padding:0}}  id="startDate" value={this.state.invoiceNetItem.startDate} onChange={(e) => this.onDateChange(e, 'startDate',this.state.invoiceNetItems[i])}/> */}
                  {/* <Button icon="pi pi-check" style={{ height:25, verticalAlign: 'middle'}} onClick={ (e) => this.saveInvoiceItem(e.value=null,'itemStartDateAccommodationEdit',this.state.invoiceNetItems[i])}></Button> */}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}
                </div>
              </div>
            )

            if (isTotalAccommodation === false) {
              totalAccommodationView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalAccommodation}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalAccommodation = true;
            }

          } else if (this.state.invoiceNetItems[i].itemEndDateAccommodationEdit) {

            accommodationView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Provider}: {invoiceNetItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Accommodation}: {invoiceNetItems[i].program}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}
                  {/* <Calendar readOnlyInput="true" dateFormat="dd/mm/yyyy" style={{width:150, height:25, fontSize:"14px", fontWeight:400, border:"none", padding:0}}  id="endDate" value={this.state.invoiceNetItem.endDate} onChange={(e) => this.onDateChange(e, 'endDate',this.state.invoiceNetItems[i])}/> */}
                  {/* <Button icon="pi pi-check" style={{ height:25, verticalAlign: 'middle'}} onClick={ (e) => this.saveInvoiceItem(e.value=null,'itemEndDateAccommodationEdit',this.state.invoiceNetItems[i])}></Button> */}
                </div>
              </div>
            )

            if (isTotalAccommodation === false) {
              totalAccommodationView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalAccommodation}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalAccommodation = true;
            }

          } else if (this.state.edit === true) {

            accommodationView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Provider}: {invoiceNetItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Acommodation}: {invoiceNetItems[i].program}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}
                </div>
              </div>
            )

            if (isTotalAccommodation === false) {
              totalAccommodationView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalAccommodation}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalAccommodation = true;
            }

          } else {
            if (this.state.editInvoice === true) {
              accommodationView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Provider}: {invoiceNetItems[i].provider}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Accommodation}: {invoiceNetItems[i].program}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType} <Button icon='pi pi-pencil' className='p-button-text p-button-plain' style={{ verticalAlign: 'middle', margin: '10px', width: '20px', height: '20px' }} onClick={(e) => this.onEditInvoiceNetItem(e.value = true, 'itemAmountAccommodationEdit', this.state.invoiceNetItems[i])}></Button>
                    <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceNetItems[i])}></Button>
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.StartDate}: {invoiceNetItems[i].startDate} {/* <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.onEditInvoiceNetItem(e.value=true, 'itemStartDateEdit',this.state.invoiceNetItems[i])}></Button>  */}
                    {/* <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceNetItems[i])}></Button> */}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.EndDate}: {invoiceNetItems[i].endDate}  {/* <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.onEditInvoiceNetItem(e.value=true, 'itemStartDateEdit',this.state.invoiceNetItems[i])}></Button>  */}
                    {/* <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceNetItems[i])}></Button> */}
                  </div>
                </div>
              )
              // if (isNewAccommodation===false){
              // NewAccommodationView.push(
              // <div style={{marginLeft:30, marginTop:10}}>
              // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=2)}/>
              // </div>
              // )
              // isNewAccommodation=true;
              // }

            } else {
              accommodationView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-building' />: {invoiceNetItems[i].provider}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-home' />: {invoiceNetItems[i].program} <i className='pi pi-dollar' />: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
                  </div>
                  {/* <div style={{marginLeft:30, marginTop:10}}>
              </div> */}
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-calendar-plus' />: {invoiceNetItems[i].startDate} <i className='pi pi-calendar-minus' />: {invoiceNetItems[i].endDate}
                  </div>
                  {/* <div style={{marginLeft:30, marginTop:10}}>
              </div>      */}
                </div>
              )
            }
            if (isTotalAccommodation === false) {
              totalAccommodationView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalAccommodations}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalAccommodation = true;
            }
          }
        } else if (this.props.user.fk_id_role === 64 || this.props.user.fk_id_role === 63) {
          accommodationView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                <i className='pi pi-building' />: {invoiceNetItems[i].provider}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                <i className='pi pi-home' />: {invoiceNetItems[i].program} <i className='pi pi-dollar' />: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {invoiceNetItems[i].durationAmount} {invoiceNetItems[i].durationType}
              </div>
              {/* <div style={{marginLeft:30, marginTop:10}}>
          </div> */}
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                <i className='pi pi-calendar-plus' />: {invoiceNetItems[i].startDate} <i className='pi pi-calendar-minus' />: {invoiceNetItems[i].endDate}
              </div>
              {/* <div style={{marginLeft:30, marginTop:10}}>
          </div>      */}
            </div>
          )
          // if (isNewAccommodation===false){
          // NewAccommodationView.push(
          // <div style={{marginLeft:30, marginTop:10}}>
          // </div>
          // )
          // isNewAccommodation=true;
          // }
          if (isTotalAccommodation === false) {
            totalAccommodationView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalAccommodations}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalAccommodation = true;
          }
        }
      }//ACCOMODATION

      else if (invoiceNetItems[i].invoiceItemTypeId === 3 && invoiceNetItems[i].isAgencyItem === 0 && invoiceNetItems[i] !== null) {
        if (this.props.user.fk_id_role === 58 || this.props.user.fk_id_role === 62) {
          if (this.state.invoiceNetItems[i].itemAmountServiceEdit) {
            serviceView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Service}: {invoiceNetItems[i].program}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */}
                  <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceNetItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceNetItems[i])} />
                  <br />
                  <Dropdown
                    style={{ width: 150, height: 30 }}
                    id='invoice_item'
                    variant='outlined'
                    margin="dense"
                    value={this.state.selectedItemType}
                    onChange={this.onItemTypeChange}
                    options={this.ItemTypes}
                    optionLabel='type'
                    optionValue='id'
                  />
                  <br />
                  <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceNetItems[i], invoiceNetItems[i].gross_net)}></Button>
                </div>
              </div>
            )

            if (isTotalService === false) {
              totalServiceView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalService}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalService = true;
            }

          } else if (this.state.edit === true) {

            serviceView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Service}: {invoiceNetItems[i].program}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */}
                </div>
              </div>
            )
            if (isTotalService === false) {
              totalServiceView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalServices}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalService = true;
            }
          } else {
            if (this.state.editInvoice === true) {
              serviceView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Service}: {invoiceNetItems[i].program}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Price}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} cantidad: {invoiceNetItems[i].serviceFeeMultiplier} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.onEditInvoiceNetItem(e.value = true, 'itemAmountServiceEdit', this.state.invoiceNetItems[i])}></Button>
                    <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceNetItems[i])}></Button>
                  </div>
                </div>
              )
            } else {
              serviceView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-globe' />: {invoiceNetItems[i].program} <i className='pi pi-money-bill' />: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
                  </div>
                  {/* <div style={{marginLeft:30, marginTop:10}}>
              </div> */}
                </div>
              )
            }

            // if (isNewService===false){
            // NewServiceView.push(
            // <div style={{marginLeft:30, marginTop:10}}>
            // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=3)}/>
            // </div>
            // )
            // isNewService=true;
            // }
            if (isTotalService === false) {
              totalServiceView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalServices}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalService = true;
            }
          }
        } else if (this.props.user.fk_id_role === 64 || this.props.user.fk_id_role === 63) {
          serviceView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                <i className='pi pi-globe' />: {invoiceNetItems[i].program} <i className='pi pi-money-bill' />: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
              </div>
              {/* <div style={{marginLeft:30, marginTop:10}}>
        </div> */}
            </div>
          )

          // if (isNewService===false){
          // NewServiceView.push(
          // <div style={{marginLeft:30, marginTop:10}}>
          // </div>
          // )
          // isNewService=true;
          // }

          if (isTotalService === false) {
            totalServiceView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalServices}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalService = true;
          }
        }
      }//SERVICE

      else if (invoiceNetItems[i].invoiceItemTypeId === 4 && invoiceNetItems[i].isAgencyItem === 0 && invoiceNetItems[i] !== null) {
        if (this.props.user.fk_id_role === 58 || this.props.user.fk_id_role === 62) {

          if (this.state.invoiceNetItems[i].itemAmountDiscountEdit) {

            discountView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceNetItems[i].program}: <span style={{ color: 'red' }}> - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} </span>{/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */}
                  <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceNetItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceNetItems[i])} />
                  <br />
                  <Dropdown
                    style={{ width: 150, height: 30 }}
                    id='invoice_item'
                    variant='outlined'
                    margin="dense"
                    value={this.state.selectedItemType}
                    onChange={this.onItemTypeChange}
                    options={this.ItemTypes}
                    optionLabel='type'
                    optionValue='id'
                  />
                  <br />
                  <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceNetItems[i], invoiceNetItems[i].gross_net)}></Button>
                </div>
              </div>
            )
            // if (isNewDiscount===false){

            // NewDiscountView.push(
            // <div style={{fontSize:14}}>
            // <div style={{marginLeft:30, marginTop:10}}>
            // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=4)}/>
            // </div>
            // </div>
            // )
            // isNewDiscount=true;
            // }
            if (isTotalDiscount === false) {
              totalDiscountView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalDiscounts}: <span style={{ color: 'red' }}> - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} </span>
                    </div>
                  </div>
                </td>
              )
              isTotalDiscount = true;
            }


          } else if (this.state.edit === true) {
            discountView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceNetItems[i].program}: <span style={{ color: 'red' }}> - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} </span> {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */}
                </div>
              </div>
            )
            if (isTotalDiscount === false) {
              totalDiscountView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalDiscount}: <span style={{ color: 'red' }}> - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} </span>
                    </div>
                  </div>
                </td>
              )
              isTotalDiscount = true;
            }
          } else {
            if (this.state.editInvoice === true) {
              discountView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {invoiceNetItems[i].program}: <span style={{ color: 'red' }}> - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} </span> {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.onEditInvoiceNetItem(e.value = true, 'itemAmountDiscountEdit', this.state.invoiceNetItems[i])}></Button>
                    <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.confirmDeleteInvoiceItem(this.state.invoiceNetItems[i])}></Button>
                  </div>
                </div>
              )
            } else {
              discountView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {invoiceNetItems[i].program}: <span style={{ color: 'red' }}> - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} </span> {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */}
                  </div>
                </div>
              )
            }
            // if (isNewDiscount===false){
            // NewDiscountView.push(
            // <div style={{marginLeft:30, marginTop:10}}>
            // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=4)}/>
            // </div>
            // )
            // isNewDiscount=true;
            // }

            if (isTotalDiscount === false) {
              totalDiscountView.push(
                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalDiscount}: - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmount)} {invoiceNetItems[i].code}
                    </div>
                  </div>
                </td>
              )
              isTotalDiscount = true;
            }
          }
        } else if (this.props.user.fk_id_role === 64 || this.props.user.fk_id_role === 63) {
          discountView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {invoiceNetItems[i].program}: <span style={{ color: 'red' }}> - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} </span> {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */}
              </div>
            </div>
          )
          if (isNewDiscount === false) {
            NewDiscountView.push(
              <div style={{ marginLeft: 30, marginTop: 10 }}>
              </div>
            )
            isNewDiscount = true;
          }

          if (isTotalDiscount === false) {
            totalDiscountView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalDiscount}: <span style={{ color: 'red' }}> - {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} </span>
                  </div>
                </div>
              </td>
            )
            isTotalDiscount = true;
          }
        }
      }//DISCOUNT

      else if (invoiceNetItems[i].invoiceItemTypeId === 5 && invoiceNetItems[i].isAgencyItem === 0 && invoiceNetItems[i] !== null) {
        if (this.props.user.fk_id_role === 58 || this.props.user.fk_id_role === 62) {
          if (this.state.invoiceNetItems[i].itemAmountFeeEdit) {
            feeView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceNetItems[i].program}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)}
                  <br />
                  <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceNetItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceNetItems[i])} />
                  <br />
                  <Dropdown
                    style={{ width: 150, height: 30 }}
                    id='invoice_item'
                    variant='outlined'
                    margin="dense"
                    value={this.state.selectedItemType}
                    onChange={this.onItemTypeChange}
                    options={this.ItemTypes}
                    optionLabel='type'
                    optionValue='id'
                  />
                  <br />
                  <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceNetItems[i], invoiceNetItems[i].gross_net)}></Button>
                </div>
              </div>
            )

            if (isTotalFee === false) {
              totalFeeView.push(
                //   <td> 
                //     <div style={{fontSize:14}}>              
                //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
                //       {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code})
                //      </div>
                //     </div>
                // </td>

                <td>
                  {this.state.campusText.TotalAdditionals}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalBalanceAgency)} {invoiceNetItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
                </td>

              )
              isTotalFee = true;
            }


          } else if (this.state.edit === true) {
            feeView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceNetItems[i].program}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} cantidad: {invoiceNetItems[i].serviceFeeMultiplier}
                </div>
              </div>
            )

            if (isTotalFee === false) {
              totalFeeView.push(
                //   <td> 
                //     <div style={{fontSize:14}}>              
                //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
                //       {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code})
                //      </div>
                //     </div>
                // </td>

                <td>
                  {this.state.campusText.TotalAdditionals}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalAmountAgency)} {invoiceNetItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
                </td>
              )
              isTotalFee = true;
            }
          }
          else {
            if (this.state.editInvoice === true) {
              feeView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {invoiceNetItems[i].program}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.onEditInvoiceNetItem(e.value = true, 'itemAmountFeeEdit', this.state.invoiceNetItems[i])}></Button>
                    <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.confirmDeleteInvoiceItem(this.state.invoiceNetItems[i])}></Button>
                  </div>
                </div>
              )
            } else {
              feeView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-wallet' /> {invoiceNetItems[i].program}: <i className='pi pi-money-bill' /> {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
                  </div>
                </div>
              )
            }
            // if (isNewFee===false){
            // NewFeeView.push(
            // <div style={{fontSize:14}}>
            // <div style={{marginLeft:30, marginTop:10}}>
            // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=5)}/>
            // </div>
            // </div>
            // )
            // isNewFee=true;
            // }
            if (isTotalFee === false) {
              totalFeeView.push(
                //   <td> 
                //     <div style={{fontSize:14}}>              
                //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
                //       {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}  ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code})
                //     </div>
                //     </div>
                // </td>

                <td>
                  {this.state.campusText.TotalAdditionals}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalBalanceAgency)} {invoiceNetItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
                </td>
              )
              isTotalFee = true;
            }
          }
        } else if (this.props.user.fk_id_role === 64 || this.props.user.fk_id_role === 63) {
          feeView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                <i className='pi pi-wallet' /> {invoiceNetItems[i].program}: <i className='pi pi-money-bill' /> {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
              </div>
            </div>
          )
          // if (isNewFee===false){
          // NewFeeView.push(
          // <div style={{fontSize:14}}>
          // <div style={{marginLeft:30, marginTop:10}}>

          // </div>
          // </div>
          // )
          // isNewFee=true;
          // }

          if (isTotalFee === false) {
            totalFeeView.push(
              //   <td> 
              //     <div style={{fontSize:14}}>              
              //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
              //       {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}  ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code})
              //     </div>
              //     </div>
              // </td>

              <td>
                {this.state.campusText.TotalAdditionals}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].totalBalanceAgency)} {invoiceNetItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
              </td>
            )
            isTotalFee = true;
          }
        }
      }//FEE

      else if ((invoiceNetItems[i].invoiceItemTypeId === 5 && invoiceNetItems[i].isAgencyItem === 1 && invoiceNetItems[i] !== null) || (invoiceNetItems[i].invoiceItemTypeId === 3 && invoiceNetItems[i].isAgencyItem === 1 && invoiceNetItems[i] !== null)) {
        if (this.props.user.fk_id_role === 58 || this.props.user.fk_id_role === 62) {
          if (this.state.invoiceNetItems[i].itemAmountFeeEdit) {

            feeAgencyView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceNetItems[i].program}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */}
                  <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceNetItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceNetItems[i])} />
                  <br />
                  <Dropdown
                    style={{ width: 150, height: 30 }}
                    id='invoice_item'
                    variant='outlined'
                    margin="dense"
                    value={this.state.selectedItemType}
                    onChange={this.onItemTypeChange}
                    options={this.ItemTypes}
                    optionLabel='type'
                    optionValue='id'
                  />
                  <br />
                  <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceNetItems[i], invoiceNetItems[i].gross_net)}></Button>
                </div>
              </div>
            )
          } else if (this.state.edit === true) {
            feeAgencyView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceNetItems[i].program}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */} {invoiceNetItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
                </div>
              </div>
            )
          }

          else {
            if (this.state.editInvoice === true) {
              feeAgencyView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {invoiceNetItems[i].program}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.onEditInvoiceNetItem(e.value = true, 'itemAmountFeeEdit', this.state.invoiceNetItems[i])}></Button>
                    <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.confirmDeleteInvoiceItem(this.state.invoiceNetItems[i])}></Button>
                  </div>
                </div>
              )
            } else {
              feeAgencyView.push(
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-money-bill' /> {invoiceNetItems[i].program}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
                  </div>
                </div>
              )
            }
            // if (isNewFeeAgency===false){
            // NewFeeAgencyView.push(
            // <div style={{fontSize:14}}>
            // <div style={{marginLeft:30, marginTop:10}}>
            // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=5)}/>
            // </div>
            // </div>
            // )
            // isNewFeeAgency=true;
            // }
          }
        } else if (this.props.user.fk_id_role === 64 || this.props.user.fk_id_role === 63) {
          feeAgencyView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {invoiceNetItems[i].program}: {invoiceNetItems[i].symbol} {dollarUSLocale.format(invoiceNetItems[i].amount)} {invoiceNetItems[i].code} {/* cantidad: {invoiceNetItems[i].serviceFeeMultiplier} */} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */} {/* <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemAmountFeeEdit',this.state.invoiceNetItems[i])}></Button>*/}
                {/* <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button> */}
              </div>
            </div>
          )
          // if (isNewFeeAgency===false){
          // NewFeeAgencyView.push(
          // <div style={{fontSize:14}}>
          // <div style={{marginLeft:30, marginTop:10}}>
          // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=5)}/>
          // </div>
          // </div>
          // )
          // isNewFeeAgency=true;
          // }
        }
      }//FEE AGENCY

    }//FOR
    Order[0] = (

      <>
        <div>
          <div className="card">
            {programView}
            {NewProgramView}
          </div>

        </div>
        <br />
      </>

    )
    // Order[1]=totalProgramView

    Order[2] = (
      <>
        <div>
          {/* <div className="card">  */}
          <div className='card'>
            <Card>
              <div><span className='text-3xl text-900'>Complemento del programa Net</span></div>
              <span className='text-3xl text-700' style={{ marginLeft: 30, marginTop: 20 }}> Detalles del alojamiento </span>
              {accommodationView}
              <br />
              {/* {NewAccommodationView} */}

              <span className='text-3xl text-700' style={{ marginLeft: 30, marginTop: 20 }}>Complementos y cuotas </span>
              {serviceView}
              {/* {NewServiceView} */}

              {discountView}
              {/* {NewDiscountView} */}

              {feeView}
              {/* {NewFeeView} */}
            </Card>
          </div>
          {/* </div> */}

          <div className='card' style={{ marginTop: 10 }}>
            {this.renderFooterProgramNet()}
          </div>
        </div>
      </>

    )

    // Order[3] = (
    //   <>
    //       <tr>
    //     <td>
    //       <div style={{marginTop:10, fontSize:24, background:"#e8edf0", color:'black', height:40}}>
    //           {this.renderFooter()}
    //       </div>
    //     </td>
    //   </tr>
    //   <br/>
    //   </>
    // )

    Order[3] = (

      <>
        <div>
          <div className="card" style={{ marginTop: 10 }}>
            <Card>
              <div><span className='text-3xl text-900'>Complemento de agencia Net</span></div>
              {feeAgencyView}
              {/* {NewFeeAgencyView} */}
            </Card>
          </div>
        </div>
      </>

    )

    Order[4] = (
      <>
        <div className='card' style={{ marginTop: 10 }}>
          {this.renderFooterAdditionalsNet()}
        </div>
      </>
    )

    // Order[5] = (
    //   <>
    //     <div className='card' style={{ marginTop: 10}}>
    //       {this.renderProgramProfit()}
    //     </div>
    //   </>
    // )
    

    return Order
  }

  renderOrderGross = () => {

    let invoiceItems = { ...this.state.invoiceItems }
    let invoice = { ...this.state.showInvoice }
    // let newRate = this.state.newRate

    let programView = []
    let isTotalProgram = false
    let totalProgramView = []
    let isNewProgram = false
    let NewProgramView = []


    let accommodationView = []
    let isTotalAccommodation = false
    let totalAccommodationView = []
    let isNewAccommodation = false
    let NewAccommodationView = []


    let serviceView = []
    let isTotalService = false
    let totalServiceView = []
    let isNewService = false
    let NewServiceView = []


    let feeView = []
    let totalFeeView = []
    let isTotalFee = false
    let isNewFee = false
    let NewFeeView = []


    let discountView = []
    let totalDiscountView = []
    let isTotalDiscount = false
    let isNewDiscount = false
    let NewDiscountView = []

    let feeAgencyView = []
    let isNewFeeAgency = false
    let NewFeeAgencyView = []
    let dollarUSLocale = Intl.NumberFormat('en-US');

    let gross_net = null

    let Order = []



    for (let i = 0; i < Object.keys(invoiceItems).length; i++) {
      gross_net = "Gross"
      invoiceItems[i].gross_net = gross_net

      if (invoiceItems[i].invoiceItemTypeId === 1 && invoiceItems[i].isAgencyItem === 0 && invoiceItems[i] !== null) {
        if (this.state.invoiceItems[i].itemAmountEdit) {
          gross_net = "Gross"
          invoiceItems[i].gross_net = gross_net

          programView.push(
            // <div style={{fontSize:14}}>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.School}: {invoiceItems[i].provider}
            // </div>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
            // <InputNumber style={{width:150, height:25, fontSize:"14px", fontWeight:400, border:"none", padding:0}} id="amount" locale="en-US" minFractionDigits={2} min="0"  value={this.state.invoiceItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount',this.state.invoiceItems[i])}/>
            // <Button icon="pi pi-check" style={{ height:25, verticalAlign: 'middle'}} onClick={ (e) => this.saveInvoiceItem(e.value=null,'itemAmountEdit',this.state.invoiceItems[i])}></Button>                                         
            // </div>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
            // </div>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
            // </div>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.Course}: {invoiceItems[i].program}
            // </div>
            // </div>

            <Card legend="Detalles del programa Gross">
              <div className='grid'>
                <div className='col-fixed'>
                  <span className='text-3xl text-900'>Detalles del programa</span>
                </div>

                {/* <div className='col-fixed'>
                  {this.FileInvoiceGross()}
                </div>

                {invoice.invoice_gross_file_url ?
                  <div className='col-fixed'>
                    {this.FileInvoiceGrossDownload()}
                  </div>
                  :
                  <></>
                } */}

              </div>
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.School}: {invoiceItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
                  <InputNumber style={{ width: 150, height: 25, fontSize: "14px", fontWeight: 400, border: "none", padding: 0 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceItems[i])} />
                  <Button icon="pi pi-check" style={{ height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountEdit', this.state.invoiceItems[i], invoiceItems[i].gross_net)}></Button>
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Course}: {invoiceItems[i].program}
                </div>
              </div>
            </Card>
          )

          if (isTotalProgram === false) {
            totalProgramView.push(
              <Card legend="Detalles del programa Gross" >
                <div><span className='text-3xl text-900'>Detalles del programa</span></div>

                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                    </div>
                  </div>
                </td>
              </Card>
            )
            isTotalProgram = true;
          }


        } else if (this.state.invoiceItems[i].itemStartDateEdit) {

          programView.push(
            // <div style={{fontSize:14}}>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.School}: {invoiceItems[i].provider}
            // </div>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
            // </div>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.StartDate}: {invoiceItems[i].startDate} 
            // <Calendar readOnlyInput="true" dateFormat="dd/mm/yy" style={{width:150, height:25, fontSize:"14px", fontWeight:400, border:"none", padding:0}}   id="startDate" value={this.state.invoiceItem.startDate} onChange={(e) => this.onDateChange(e, 'startDate',this.state.invoiceItems[i])} />
            // <Button icon="pi pi-check" style={{ height:25, verticalAlign: 'middle'}} onClick={ (e) => this.saveInvoiceItem(e.value=null,'itemStartDateEdit',this.state.invoiceItems[i])}></Button>
            // </div>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
            // </div>
            // <div style={{marginLeft:30, marginTop:10}}>
            // {this.state.campusText.Course}: {invoiceItems[i].program}
            // </div>
            // </div>

            <Card legend='Detalles del programa Gross'>
              <div className='grid'>
                <div className='col-fixed'>
                  <span className='text-3xl text-900'>Detalles del programa</span>
                </div>
                {/* 
                <div className='col-fixed'>
                  {this.FileInvoiceGross()}
                </div>

                {invoice.invoice_gross_file_url ?
                  <div className='col-fixed'>
                    {this.FileInvoiceGrossDownload()}
                  </div>
                  :
                  <></>
                } */}

              </div>
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.School}: {invoiceItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
                  <Calendar readOnlyInput="true" dateFormat="dd/mm/yy" style={{ width: 150, height: 25, fontSize: "14px", fontWeight: 400, border: "none", padding: 0 }} id="startDate" value={this.state.invoiceItem.startDate} onChange={(e) => this.onDateChange(e, 'startDate', this.state.invoiceItems[i])} />
                  <Button icon="pi pi-check" style={{ height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemStartDateEdit', this.state.invoiceItems[i])}></Button>
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Course}: {invoiceItems[i].program}
                </div>
              </div>
            </Card>
          )

          if (isTotalProgram === false) {
            totalProgramView.push(
              //   <td> 
              //     <div style={{fontSize:14}}>              
              //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
              //     {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
              //   </div>
              //     </div>
              // </td>

              <Card legend="Detalles del programa Gross">
                <div><span className='text-3xl text-900'>Detalles del programa</span></div>

                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                    </div>
                  </div>
                </td>
              </Card>
            )
            isTotalProgram = true;
          }

        } else if (this.state.invoiceItems[i].itemEndDateEdit) {

          programView.push(
            // <div  style={{fontSize:14}}>
            // <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.School}: {invoiceItems[i].provider}
            //     </div>
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
            //     </div>
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
            //     </div>
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
            //     <Calendar readOnlyInput="true" dateFormat="dd/mm/yy" style={{width:150, height:25, fontSize:"14px", fontWeight:400, border:"none", padding:0}}  id="endDate" value={this.state.invoiceItem.endDate} onChange={(e) => this.onDateChange(e, 'endDate',this.state.invoiceItems[i])}/>
            //     <Button icon="pi pi-check" style={{ height:25, verticalAlign: 'middle'}} onClick={ (e) => this.saveInvoiceItem(e.value=null,'itemEndDateEdit',this.state.invoiceItems[i])}></Button>
            //     </div>
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.Course}: {invoiceItems[i].program}
            //     </div>
            //     </div>

            <Card legend="Detalles del programa Gross">
              <div className='grid'>
                <div className='col-fixed'>
                  <span className='text-3xl text-900'>Detalles del programa</span>
                </div>

                {/* <div className='col-fixed'>
                  {this.FileInvoiceGross()}
                </div>

                {invoice.invoice_gross_file_url ?
                  <div className='col-fixed'>
                    {this.FileInvoiceGrossDownload()}
                  </div>
                  :
                  <></>
                } */}

              </div>
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.School}: {invoiceItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
                  <Calendar readOnlyInput="true" dateFormat="dd/mm/yy" style={{ width: 150, height: 25, fontSize: "14px", fontWeight: 400, border: "none", padding: 0 }} id="endDate" value={this.state.invoiceItem.endDate} onChange={(e) => this.onDateChange(e, 'endDate', this.state.invoiceItems[i])} />
                  <Button icon="pi pi-check" style={{ height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemEndDateEdit', this.state.invoiceItems[i])}></Button>
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Course}: {invoiceItems[i].program}
                </div>
              </div>
            </Card>
          )

          if (isTotalProgram === false) {
            totalProgramView.push(
              //   <td> 
              //     <div style={{fontSize:14}}>              
              //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
              //     {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
              //   </div>
              //     </div>
              // </td>

              <Card legend='Detalles del programa Gross'>
                <div><span className='text-3xl text-900'>Detalles del programa</span></div>

                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                    </div>
                  </div>
                </td>
              </Card>
            )
            isTotalProgram = true;
          }

        } else if (this.state.edit === true) {
          programView.push(
            //   <div style={{fontSize:14}}>
            // <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.School}: {invoiceItems[i].provider}
            //     </div>
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType} 
            //     </div>
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.StartDate}: {invoiceItems[i].startDate}  
            //     </div>
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.EndDate}: {invoiceItems[i].endDate}  
            //     </div>
            //     <div style={{marginLeft:30, marginTop:10}}>
            //     {this.state.campusText.Course}: {invoiceItems[i].program}
            //     </div>                      
            //   </div>

            <Card legend='Detalles del programa Gross'>
              <div className='grid'>
                <div className='col-fixed'>
                  <span className='text-3xl text-900'>Detalles del programa</span>
                </div>

                {/* <div className='col-fixed'>
                  {this.FileInvoiceGross()}
                </div>

                {invoice.invoice_gross_file_url ?
                  <div className='col-fixed'>
                    {this.FileInvoiceGrossDownload()}
                  </div>
                  :
                  <></>
                } */}

              </div>

              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.School}: {invoiceItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Course}: {invoiceItems[i].program}
                </div>
              </div>
            </Card>
          )
          if (isTotalProgram === false) {
            totalProgramView.push(
              //   <td> 
              //     <div style={{fontSize:14}}>              
              //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
              //     {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
              //   </div>
              //     </div>
              // </td>

              <Card legend='Detalles del programa Gross'>
                <div><span className='text-3xl text-900'>Detalles del programa</span></div>

                <td>
                  <div style={{ fontSize: 14 }}>
                    <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                      {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                    </div>
                  </div>
                </td>
              </Card>
            )
            isTotalProgram = true;
          }

        } else {
          if (this.state.editInvoice === true) {
            programView.push(
              //   <div style={{fontSize:14}}>
              // <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.School}: {invoiceItems[i].provider}
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemAmountEdit',this.state.invoiceItems[i])}></Button>
              //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.StartDate}: {invoiceItems[i].startDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemStartDateEdit',this.state.invoiceItems[i])}></Button> 
              //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.EndDate}: {invoiceItems[i].endDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemEndDateEdit',this.state.invoiceItems[i])}></Button>
              //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.Course}: {invoiceItems[i].program}
              //     </div>                      
              //   </div>

              <Card>
                <div className='grid'>
                  <div className='col-fixed'>
                    <span className='text-3xl text-900'>Detalles del programa</span>
                  </div>

                  {/* <div className='col-fixed'>
                    {this.FileInvoiceGross()}
                  </div>

                  {invoice.invoice_gross_file_url ?
                    <div className='col-fixed'>
                      {this.FileInvoiceGrossDownload()}
                    </div>
                    :
                    <></>
                  } */}

                </div>
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.School}: {invoiceItems[i].provider}
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemAmountEdit', this.state.invoiceItems[i])}></Button>
                    <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.StartDate}: {invoiceItems[i].startDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemStartDateEdit', this.state.invoiceItems[i])}></Button>
                    <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.EndDate}: {invoiceItems[i].endDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemEndDateEdit', this.state.invoiceItems[i])}></Button>
                    <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                  </div>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    {this.state.campusText.Course}: {invoiceItems[i].program}
                  </div>
                </div>

                {/* { isNewProgram === false ?
        NewProgramView.push(
          <div style={{marginLeft:30, marginTop:10}}>
          <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=1)}/>
          </div>
        )
        :
        NewProgramView.push(
          <></>
        )
        } */}

              </Card>
            )
          } else {
            programView.push(
              //   <div style={{fontSize:14}}>
              // <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.School}: {invoiceItems[i].provider}
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemAmountEdit',this.state.invoiceItems[i])}></Button>
              //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.StartDate}: {invoiceItems[i].startDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemStartDateEdit',this.state.invoiceItems[i])}></Button> 
              //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.EndDate}: {invoiceItems[i].endDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={(e) => this.OneditInvoiceItem(e.value=true, 'itemEndDateEdit',this.state.invoiceItems[i])}></Button>
              //     <Button icon="pi pi-trash"  className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   margin: "10px", width: "20px", height: "20px",}} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
              //     </div>
              //     <div style={{marginLeft:30, marginTop:10}}>
              //     {this.state.campusText.Course}: {invoiceItems[i].program}
              //     </div>                      
              //   </div>

              <Card>
                <div className='grid'>
                  <div className='col-fixed'>
                    <span className='text-3xl text-900'>Detalles del programa</span>
                  </div>

                  {/* {invoice.invoice_gross_file_url ?
                    <div className='col-fixed'>
                      {this.FileInvoiceGrossDownload()}
                    </div>
                    :
                    <></>
                  } */}

                </div>
                <div style={{ fontSize: 16 }}>
                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-book' />: {invoiceItems[i].program} <i className='pi pi-money-bill' />: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
                  </div>

                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-calendar-plus' />: {invoiceItems[i].startDate} <i className='pi pi-calendar-minus' />: {invoiceItems[i].endDate}
                  </div>

                  <div style={{ marginLeft: 30, marginTop: 10 }}>
                    <i className='pi pi-building' />: {invoiceItems[i].provider}
                  </div>

                  {/* <div style={{marginLeft:30, marginTop:10}}>
              </div> */}

                </div>

                {/* { isNewProgram === false ?
          NewProgramView.push(
            <div style={{marginLeft:30, marginTop:10}}>
            <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=1)}/>
            </div>
          )
          :
          NewProgramView.push(
            <></>
          )
          } */}

              </Card>
            )
          }
          // if (isNewProgram===false){
          //   NewProgramView.push(
          //     <div style={{marginLeft:30, marginTop:10}}>
          //     <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=1)}/>
          //     </div>
          //   )
          //   isNewProgram=true;
          // }
          if (isTotalProgram === false) {
            totalProgramView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalCourse}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalProgram = true;
          }

        }
      }//COURSE

      //ACCOMMODATION
      else if (invoiceItems[i].invoiceItemTypeId === 2 && invoiceItems[i].isAgencyItem === 0 && invoiceItems[i] !== null) {
        if (this.state.invoiceItems[i].itemAmountAccommodationEdit) {

          accommodationView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Provider}: {invoiceItems[i].provider}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Acommodation}: {invoiceItems[i].program}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
                <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceItems[i])} />
                <br />
                <Dropdown
                  style={{ width: 150, height: 30 }}
                  id='invoice_item'
                  variant='outlined'
                  margin="dense"
                  value={this.state.selectedItemType}
                  onChange={this.onItemTypeChange}
                  options={this.ItemTypes}
                  optionLabel='type'
                  optionValue='id'
                />
                <br />
                <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceItems[i], invoiceItems[i].gross_net)}></Button>
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
              </div>
            </div>
          )

          if (isTotalAccommodation === false) {
            totalAccommodationView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalAccommodation}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalAccommodation = true;
          }

        } else if (this.state.invoiceItems[i].itemStartDateAccommodationEdit) {

          accommodationView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Provider}: {invoiceItems[i].provider}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Accommodation}: {invoiceItems[i].program}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
                <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.deleteInvoiceItem(e.value = this.state.invoiceItems[i].id)}></Button>
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
                <Calendar readOnlyInput="true" dateFormat="dd/mm/yyyy" style={{ width: 150, height: 25, fontSize: "14px", fontWeight: 400, border: "none", padding: 0 }} id="startDate" value={this.state.invoiceItem.startDate} onChange={(e) => this.onDateChange(e, 'startDate', this.state.invoiceItems[i])} />
                <Button icon="pi pi-check" style={{ height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemStartDateAccommodationEdit', this.state.invoiceItems[i])}></Button>
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
              </div>
            </div>
          )

          if (isTotalAccommodation === false) {
            totalAccommodationView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalAccommodation}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalAccommodation = true;
          }

        } else if (this.state.invoiceItems[i].itemEndDateAccommodationEdit) {

          accommodationView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Provider}: {invoiceItems[i].provider}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Accommodation}: {invoiceItems[i].program}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
                <Calendar readOnlyInput="true" dateFormat="dd/mm/yyyy" style={{ width: 150, height: 25, fontSize: "14px", fontWeight: 400, border: "none", padding: 0 }} id="endDate" value={this.state.invoiceItem.endDate} onChange={(e) => this.onDateChange(e, 'endDate', this.state.invoiceItems[i])} />
                <Button icon="pi pi-check" style={{ height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemEndDateAccommodationEdit', this.state.invoiceItems[i])}></Button>
              </div>
            </div>
          )

          if (isTotalAccommodation === false) {
            totalAccommodationView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalAccommodation}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalAccommodation = true;
          }

        } else if (this.state.edit === true) {

          accommodationView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Provider}: {invoiceItems[i].provider}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Acommodation}: {invoiceItems[i].program}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.StartDate}: {invoiceItems[i].startDate}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.EndDate}: {invoiceItems[i].endDate}
              </div>
            </div>
          )

          if (isTotalAccommodation === false) {
            totalAccommodationView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalAccommodation}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalAccommodation = true;
          }

        } else {
          if (this.state.editInvoice === true) {
            accommodationView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Provider}: {invoiceItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Accommodation}: {invoiceItems[i].program}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemAmountAccommodationEdit', this.state.invoiceItems[i])}></Button>
                  <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.StartDate}: {invoiceItems[i].startDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemStartDateAccommodationEdit', this.state.invoiceItems[i])}></Button>
                  <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.EndDate}: {invoiceItems[i].endDate}  <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemEndDateAccommodationEdit', this.state.invoiceItems[i])}></Button>
                  <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                </div>
              </div>
            )
          } else {
            accommodationView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  <i className='pi pi-building' />: {invoiceItems[i].provider}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  <i className='pi pi-home' />: {invoiceItems[i].program} <i className='pi pi-dollar' />: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {invoiceItems[i].durationAmount} {invoiceItems[i].durationType}
                </div>
                {/* <div style={{marginLeft:30, marginTop:10}}>
        </div> */}
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  <i className='pi pi-calendar-plus' />: {invoiceItems[i].startDate} <i className='pi pi-calendar-minus' />: {invoiceItems[i].endDate}
                </div>
                {/* <div style={{marginLeft:30, marginTop:10}}>
        </div>      */}
              </div>
            )
          }
          // if (isNewAccommodation===false){
          // NewAccommodationView.push(
          // <div style={{marginLeft:30, marginTop:10}}>
          // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=2)}/>
          // </div>
          // )
          // isNewAccommodation=true;
          // }
          if (isTotalAccommodation === false) {
            totalAccommodationView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalAccommodations}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalAccommodation = true;
          }
        }
      }//ACCOMMODATION

      //SERVICE
      else if (invoiceItems[i].invoiceItemTypeId === 3 && invoiceItems[i].isAgencyItem === 0 && invoiceItems[i] !== null) {
        if (this.state.invoiceItems[i].itemAmountServiceEdit) {

          serviceView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {invoiceItems[i].program}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)}
                <br />
                <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceItems[i])} />
                <br />
                <Dropdown
                  style={{ width: 150, height: 30 }}
                  id='invoice_item'
                  variant='outlined'
                  margin="dense"
                  value={this.state.selectedItemType}
                  onChange={this.onItemTypeChange}
                  options={this.ItemTypes}
                  optionLabel='type'
                  optionValue='id'
                />
                <br />
                <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceItems[i], invoiceItems[i].gross_net)}></Button>
              </div>
            </div>
          )

          if (isTotalService === false) {
            totalServiceView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalService}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalService = true;
          }

        } else if (this.state.edit === true) {

          serviceView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Service}: {invoiceItems[i].program}
              </div>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
              </div>
            </div>
          )
          if (isTotalService === false) {
            totalServiceView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalServices}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalService = true;
          }
        } else {
          if (this.state.editInvoice === true) {
            serviceView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Service}: {invoiceItems[i].program}
                </div>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {this.state.campusText.Price}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemAmountServiceEdit', this.state.invoiceItems[i])}></Button>
                  <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={() => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                </div>
              </div>
            )
          } else {
            serviceView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  <i className='pi pi-globe' />: {invoiceItems[i].program} <i className='pi pi-money-bill' />: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
                </div>
                {/* <div style={{marginLeft:30, marginTop:10}}>
        </div> */}
              </div>
            )
          }
          // if (isNewService===false){
          //   NewServiceView.push(
          //     <div style={{marginLeft:30, marginTop:10}}>
          //     <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=3)}/>
          //     </div>
          //   )
          //   isNewService=true;
          // }
          if (isTotalService === false) {
            totalServiceView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalServices}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalService = true;
          }
        }
      }//SERVICE

      //DISCOUNT
      else if (invoiceItems[i].invoiceItemTypeId === 4 && invoiceItems[i].isAgencyItem === 0 && invoiceItems[i] !== null) {
        console.log("ENTRO AQUI A DISCOUNT");

        if (this.state.invoiceItems[i].itemAmountDiscountEdit) {

          discountView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {invoiceItems[i].program}: <span style={{ color: 'red' }}> - {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} </span>
                <br />
                <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceItems[i])} />
                <br />
                <Dropdown
                  style={{ width: 150, height: 30 }}
                  id='invoice_item'
                  variant='outlined'
                  margin="dense"
                  value={this.state.selectedItemType}
                  onChange={this.onItemTypeChange}
                  options={this.ItemTypes}
                  optionLabel='type'
                  optionValue='id'
                />
                <br />
                <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceItems[i], invoiceItems[i].gross_net)}></Button>
              </div>
            </div>
          )
          // if (isNewDiscount===false){

          // NewDiscountView.push(
          // <div style={{fontSize:14}}>
          // <div style={{marginLeft:30, marginTop:10}}>
          // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=4)}/>
          // </div>
          // </div>
          // )
          // isNewDiscount=true;
          // }
          if (isTotalDiscount === false) {
            totalDiscountView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalDiscounts}: <span style={{ color: 'red' }}> - {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} </span>
                  </div>
                </div>
              </td>
            )
            isTotalDiscount = true;
          }


        } else if (this.state.edit === true) {
          discountView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {invoiceItems[i].program}: <span style={{ color: 'red' }}> - {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} </span> {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
              </div>
            </div>
          )
          if (isTotalDiscount === false) {
            totalDiscountView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalDiscount}: <span style={{ color: 'red' }}> - {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} </span>
                  </div>
                </div>
              </td>
            )
            isTotalDiscount = true;
          }
        } else {
          if (this.state.editInvoice === true) {
            discountView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceItems[i].program}: <span style={{ color: 'red' }}> - {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} </span> {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemAmountDiscountEdit', this.state.invoiceItems[i])}></Button>
                  <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                </div>
              </div>
            )
          } else {
            discountView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceItems[i].program}: <span style={{ color: 'red' }}> - {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} </span> {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
                </div>
              </div>
            )
          }
          // if (isNewDiscount===false){
          // NewDiscountView.push(
          // <div style={{marginLeft:30, marginTop:10}}>
          // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=4)}/>
          // </div>
          // )
          // isNewDiscount=true;
          // }

          if (isTotalDiscount === false) {
            totalDiscountView.push(
              <td>
                <div style={{ fontSize: 14 }}>
                  <div style={{ height: 40, fontSize: 18, marginTop: 10, marginLeft: 30, color: 'black' }}>
                    {this.state.campusText.TotalDiscount}: - {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}
                  </div>
                </div>
              </td>
            )
            isTotalDiscount = true;
          }
        }
      }//DISCOUNT

      // FEE
      else if (invoiceItems[i].invoiceItemTypeId === 5 && invoiceItems[i].isAgencyItem === 0 && invoiceItems[i] !== null) {

        if (this.state.invoiceItems[i].itemAmountFeeEdit) {

          feeView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {invoiceItems[i].program}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)}
                <br />
                <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceItems[i])} />
                <br />
                <Dropdown
                  style={{ width: 150, height: 30 }}
                  id='invoice_item'
                  variant='outlined'
                  margin="dense"
                  value={this.state.selectedItemType}
                  onChange={this.onItemTypeChange}
                  options={this.ItemTypes}
                  optionLabel='type'
                  optionValue='id'
                />
                <br />
                <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceItems[i], invoiceItems[i].gross_net)}></Button>
              </div>
            </div>
          )

          if (isTotalFee === false) {
            totalFeeView.push(
              //   <td> 
              //     <div style={{fontSize:14}}>              
              //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
              //       {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code})
              //      </div>
              //     </div>
              // </td>

              <td>
                {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalBalanceAgency)} {invoiceItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
              </td>

            )
            isTotalFee = true;
          }


        } else if (this.state.edit === true) {
          feeView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                <i className='pi pi-money-bill' /> {invoiceItems[i].program}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
              </div>
            </div>
          )

          if (isTotalFee === false) {
            totalFeeView.push(
              //   <td> 
              //     <div style={{fontSize:14}}>              
              //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
              //       {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code})
              //      </div>
              //     </div>
              // </td>

              <td>
                {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmountAgency)} {invoiceItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
              </td>
            )
            isTotalFee = true;
          }
        }

        else {
          if (this.state.editInvoice === true) {
            feeView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceItems[i].program}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemAmountFeeEdit', this.state.invoiceItems[i])}></Button>
                  <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                </div>
              </div>
            )
          } else {
            feeView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  <i className='pi pi-wallet' /> {invoiceItems[i].program}: <i className='pi pi-money-bill' /> {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */}
                </div>
              </div>
            )
          }
          // if (isNewFee===false){
          // NewFeeView.push(
          // <div style={{fontSize:14}}>
          // <div style={{marginLeft:30, marginTop:10}}>
          // <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=5)}/>
          // </div>
          // </div>
          // )
          // isNewFee=true;
          // }
          if (isTotalFee === false) {
            totalFeeView.push(
              //   <td> 
              //     <div style={{fontSize:14}}>              
              //     <div style={{ height:40, fontSize:18, marginTop:10, marginLeft:30, color:'black'}}>
              //       {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalAmount)} {invoiceItems[i].code}  ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code})
              //     </div>
              //     </div>
              // </td>

              <td>
                {this.state.campusText.TotalAdditionals}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].totalBalanceAgency)} {invoiceItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
              </td>
            )
            isTotalFee = true;
          }
        }
      }//FEE

      // FEE AGENCY
      else if ((invoiceItems[i].invoiceItemTypeId === 5 && invoiceItems[i].isAgencyItem === 1 && invoiceItems[i] !== null) || (invoiceItems[i].invoiceItemTypeId === 3 && invoiceItems[i].isAgencyItem === 1 && invoiceItems[i] !== null)) {
        if (this.state.invoiceItems[i].itemAmountFeeEdit) {

          feeAgencyView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {invoiceItems[i].program}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)}
                <br />
                <InputNumber style={{ width: 30, height: 30, fontSize: "14px", fontWeight: 400, border: "none", paddingBottom: 5 }} id="amount" locale="en-US" minFractionDigits={2} min="0" value={this.state.invoiceItem.amount} onValueChange={(e) => this.onInputChange(e, 'amount', this.state.invoiceItems[i])} />
                <br />
                <Dropdown
                  style={{ width: 150, height: 30 }}
                  id='invoice_item'
                  variant='outlined'
                  margin="dense"
                  value={this.state.selectedItemType}
                  onChange={this.onItemTypeChange}
                  options={this.ItemTypes}
                  optionLabel='type'
                  optionValue='id'
                />
                <br />
                <Button label='Save' style={{ color: "blue", height: 25, verticalAlign: 'middle' }} onClick={(e) => this.saveInvoiceItem(e.value = null, 'itemAmountFeeEdit', this.state.invoiceItems[i], invoiceItems[i].gross_net)}></Button>
              </div>
            </div>
          )
        } else if (this.state.edit === true) {
          feeAgencyView.push(
            <div style={{ fontSize: 14 }}>
              <div style={{ marginLeft: 30, marginTop: 10 }}>
                {invoiceItems[i].program}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */} {invoiceItems[i].code} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
              </div>
            </div>
          )
        }

        else {
          if (this.state.editInvoice === true) {
            feeAgencyView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {invoiceItems[i].program}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */} <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.OneditInvoiceItem(e.value = true, 'itemAmountFeeEdit', this.state.invoiceItems[i])}></Button>
                  <Button icon="pi pi-trash" className="p-button-text p-button-plain" style={{ verticalAlign: 'middle', margin: "10px", width: "20px", height: "20px", }} onClick={(e) => this.confirmDeleteInvoiceItem(this.state.invoiceItems[i])}></Button>
                </div>
              </div>
            )

            // if (isNewFeeAgency===false){
            //   NewFeeAgencyView.push(
            //   <div style={{fontSize:14}}>
            //   <div style={{marginLeft:30, marginTop:10}}>
            //   <Button label={this.state.campusText.Add} style={{fontSize:14}}  className="p-button-info p-button-text" onClick={ (e) => this.openNewItem(e.value=5)}/>
            //   </div>
            //   </div>
            //   )
            //   isNewFeeAgency=true;
            //   }
          } else {
            feeAgencyView.push(
              <div style={{ fontSize: 14 }}>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  <i className='pi pi-money-bill' /> {invoiceItems[i].program}: {invoiceItems[i].symbol} {dollarUSLocale.format(invoiceItems[i].amount)} {invoiceItems[i].code} {/* cantidad: {invoiceItems[i].serviceFeeMultiplier} */} {/* ({dollarUSLocale.format(invoiceItems[i].final_rate)} - {invoiceItems[i].final_code}) */}
                </div>
              </div>
            )

            // if (isNewFeeAgency===false){
            //   NewFeeAgencyView.push(
            //   <div style={{fontSize:14}}>
            //   <div style={{marginLeft:30, marginTop:10}}>
            //   </div>
            //   </div>
            //   )
            //   isNewFeeAgency=true;
            //   }
          }

        }
      }//FEE AGENCY


    }//FOR
    Order[0] = (

      <>
        <div>
          <div className='card'>
            {programView}
            {NewProgramView}
          </div>

        </div>
        <br />
      </>

    )
    // Order[1]=totalProgramView


    Order[2] = (
      <>
        <div>
          <div className='card'>
            <Card>
              <div><span className='text-3xl text-900'>Complemento del programa Gross</span></div>
              <span className='text-3xl text-700' style={{ marginLeft: 30, marginTop: 20 }}> Detalles del alojamiento </span>
              {accommodationView}
              {/* {NewAccommodationView} */}
              <br />

              <span className='text-3xl text-700' style={{ marginLeft: 30, marginTop: 20 }}> Complementos y cuotas </span>
              {serviceView}
              {/* {NewServiceView} */}

              {discountView}
              {/* {NewDiscountView} */}

              {feeView}
              {/* {NewFeeView} */}
            </Card>
          </div>

          <div className='card' style={{ marginTop: 10 }}>
            {this.renderFooterProgramGross()}
          </div>
        </div>
      </>

    )

    // Order[3] = (
    //   <>
    //       <tr>
    //     <td>
    //       <div style={{marginTop:10, fontSize:24, background:"#e8edf0", color:'black', height:40}}>
    //           {this.renderFooter()}
    //       </div>
    //     </td>
    //   </tr>
    //   <br/>
    //   </>
    // )

    Order[3] = (

      <>
        <div>
          <div className='card' style={{ marginTop: 10 }}>
            <Card>
              <div><span className='text-3xl text-900'>Complemento de agencia Gross</span></div>

              {feeAgencyView}
              {/* {NewFeeAgencyView} */}
            </Card>
          </div>
        </div>
      </>

    )

    Order[4] = (
      <>
        <div className='card' style={{ marginTop: 10 }}>
          {this.renderFooterAdditionalsGross()}
        </div>
      </>
    )
    return Order

  }

  renderFooterProgramGross = () => {
    let invoice = { ...this.state.showInvoice }

    let dollarUSLocale = Intl.NumberFormat('en-US');
    let showFooter = []

    showFooter.push(
      <>
        <Card style={{ background: "#F1F3F4" }}>
          <div className='flex justify-content-between mb-3'>
            <span className='text-3xl text-900'>{this.state.campusText.ProgramTotal} Gross</span>

            <div>
              <div className=" text-3xl text-900 text-xl">{invoice.symbol + ' ' + dollarUSLocale.format(invoice.totalAmount) + ' ' + invoice.code}</div>
            </div>

            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
              <i className="pi pi-money-bill text-green-500 text-xl"></i>
            </div>
          </div>

        </Card>
      </>
    )

    return showFooter
  }

  renderFooterProgramNet = () => {
    let invoice = { ...this.state.showNetInvoice }

    let dollarUSLocale = Intl.NumberFormat('en-US');
    let showFooter = []

    showFooter.push(
      <>
        <Card style={{ background: "#F1F3F4" }}>
          <div className='flex justify-content-between mb-3'>
            <span className='text-3xl text-900'>{this.state.campusText.ProgramTotal} Net</span>

            <div>
              <div className=" text-3xl text-900 text-xl">{invoice.symbol + ' ' + dollarUSLocale.format(invoice.totalAmount) + ' ' + invoice.code}</div>
            </div>

            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
              <i className="pi pi-money-bill text-green-500 text-xl"></i>
            </div>
          </div>

        </Card>
      </>
    )

    return showFooter
  }

  renderFooterAdditionalsGross = () => {
    let invoice = { ...this.state.showInvoice }

    let dollarUSLocale = Intl.NumberFormat('en-US')
    let showFooter = []

    showFooter.push(
      <>
        <Card style={{ background: "#F1F3F4" }}>
          <div className='flex justify-content-between mb-3'>
            <span className='text-3xl text-900'>{this.state.campusText.TotalAdditional} Gross</span>

            <div>
              <div className=" text-3xl text-900 text-xl">{invoice.symbol + ' ' + dollarUSLocale.format(invoice.totalAmountAgency) + ' ' + invoice.codeA}</div>
            </div>

            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
              <i className="pi pi-money-bill text-green-500 text-xl"></i>
            </div>
          </div>

        </Card>
      </>
    )

    return showFooter
  }

  renderFooterAdditionalsNet = () => {
    let invoice = { ...this.state.showNetInvoice }

    let dollarUSLocale = Intl.NumberFormat('en-US')
    let showFooter = []

    showFooter.push(
      <>
        <Card style={{ background: "#F1F3F4" }}>
          <div className='flex justify-content-between mb-3'>
            <span className='text-3xl text-900'>{this.state.campusText.TotalAdditional} Net</span>

            <div>
              <div className=" text-3xl text-900 text-xl">{invoice.symbol + ' ' + dollarUSLocale.format(invoice.totalAmountAgency) + ' ' + invoice.codeA}</div>
            </div>

            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
              <i className="pi pi-money-bill text-green-500 text-xl"></i>
            </div>
          </div>

        </Card>
      </>
    )

    return showFooter
  }

  renderProgramProfit() {
    let invoice = { ...this.state.showInvoice }
    // let net_invoice = {...this.state.showNetInvoice}

    let dollarUSLocale = Intl.NumberFormat('en-US')
    let showFooter = []


    // console.log("PROGRAM PROFIT: ", invoice.program_profit);

    // console.log(invoice.total_profit);
    // console.log(net_invoice.total_profit);

    if (invoice.program_profit >= 0) {
      showFooter.push(
        <>
          <div style={{ alignSelf: 'center', marginTop: 10, fontSize: 24, background: '#e8edf0', height: 40 }}>
            <td>Program profit: </td><td style={{ color: 'green' }}> {' ' + invoice.symbol + ' ' + dollarUSLocale.format(invoice.program_profit) + ' ' + invoice.code} </td>
          </div>
        </>
      )
    } else if (invoice.program_profit < 0) {
      showFooter.push(
        <>
          <div style={{ alignSelf: 'center', marginTop: 10, fontSize: 24, background: '#e8edf0', height: 40 }}>
            <td>Program profit : </td><td style={{ color: 'red' }}> {' ' + invoice.symbolA + ' ' + dollarUSLocale.format(invoice.program_profit) + ' ' + invoice.code} </td>
          </div>
        </>
      )
    }

    return showFooter
  }

  renderAgencyProfit() {
    // let invoice = {...this.state.showInvoice}
    let net_invoice = { ...this.state.showNetInvoice }

    let dollarUSLocale = Intl.NumberFormat('en-US')
    let showFooter = []

    // console.log("PROGRAM PROFIT: ", agency_profit);

    // console.log(invoice.total_profit);
    // console.log(net_invoice.total_profit);

    if (net_invoice.agency_profit >= 0) {
      showFooter.push(
        <>
          <div style={{ alignSelf: 'center', marginTop: 10, fontSize: 24, background: '#e8edf0', height: 40 }}>
            <td>Agency profit: </td><td style={{ color: 'green' }}> {' ' + net_invoice.symbolA + ' ' + dollarUSLocale.format(net_invoice.agency_profit) + ' ' + net_invoice.codeA} </td>
          </div>
        </>
      )
    } else if (net_invoice.agency_profit < 0) {
      showFooter.push(
        <>
          <div style={{ alignSelf: 'center', marginTop: 10, fontSize: 24, background: '#e8edf0', height: 40 }}>
            <td>Agency profit : </td><td style={{ color: 'red' }}> {' ' + net_invoice.symbolA + ' ' + dollarUSLocale.format(net_invoice.agency_profit) + ' ' + net_invoice.codeA} </td>
          </div>
        </>
      )
    }

    return showFooter
  }

  renderTotalProfit() {
    let invoice = { ...this.state.showInvoice }
    let net_invoice = { ...this.state.showNetInvoice }

    let dollarUSLocale = Intl.NumberFormat('en-US')
    let showFooter = []

    // console.log("Invoice: ", invoice);
    // console.log("Net invoice: ", net_invoice);

    // let total_profit = null

    // total_profit = invoice.program_profit +  invoice.agency_profit
    // invoice.total_profit = total_profit

    // console.log("PROGRAM PROFIT: ", total_profit);

    // console.log(invoice.total_profit);
    // console.log(net_invoice.total_profit);

    if (invoice.total_profit >= 0) {
      showFooter.push(
        <>
          <div style={{ alignSelf: 'center', marginTop: 10, fontSize: 24, background: '#e8edf0', height: 40 }}>
            <td>Total profit: </td><td style={{ color: 'green' }}> {' ' + invoice.symbol + ' ' + dollarUSLocale.format(invoice.total_profit) + ' ' + invoice.code} </td>
          </div>
        </>
      )
    } else if (invoice.total_profit < 0) {
      showFooter.push(
        <>
          <div style={{ alignSelf: 'center', marginTop: 10, fontSize: 24, background: '#e8edf0', height: 40 }}>
            <td>Total profit : </td><td style={{ color: 'red' }}> {' ' + invoice.symbolA + ' ' + dollarUSLocale.format(invoice.total_profit) + ' ' + invoice.code} </td>
          </div>
        </>
      )
    }

    return showFooter
  }

  Currencies = () => {
    let showCurrency = []
    let invoiceItem = { ...this.state.invoiceItem }
    let invoice = { ...this.state.invoice }


    if (invoiceItem.isAgencyItem === 1) {
      showCurrency.push(
        <>
          <span className="p-float-label">
            <Dropdown
              style={{ width: 190, height: 33 }}
              id="currency"
              variant="outlined"
              margin="dense"
              value={this.state.selectedCurrency}
              options={this.currencies}
              onChange={this.onCurrencyChange}
              optionLabel="code"
              optionValue="id"
              required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.invoiceItem.currencyId })}
            />
            <label htmlFor="currency">{this.state.campusText.Currency}</label>
          </span>
          <small id="currencyId-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxCurrencyId(this.state)}</small>
        </>
      )
    } else if (invoiceItem.isAgencyItem === 0) {
      showCurrency.push(
        <>
          <span className="p-float-label">
            <InputText
              style={{ width: 190, height: 33, backgroundColor: '#e3e4e5' }}
              readOnly='true'
              id="currency"
              variant="outlined"
              margin="dense"
              value={invoice[0].code}
            />
            <label htmlFor="currency">{this.state.campusText.Currency}</label>
          </span>
        </>
      )
    }
    return showCurrency
  }

  saveInvoiceGross = async () => {
    const formData = new FormData();
    const filePayment = this.uploadGross.getFiles()

    console.log('FILE: ', filePayment);

    if (filePayment.length !== 0) {
      formData.append('invoice_gross_file', filePayment[0]);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      await axios.put(`${url}/api/auth/invoiceGross/${this.props.invoiceId}`, formData, config)
        .then(res => {
        })

      // if (filePayment.length > 0) {
      this.toast.show({ severity: 'info', summary: 'Success', detail: 'Invoice gross has been uploaded' });
      // } 
      // else {
      //   this.toast.show({ severity: 'info', summary: 'Success', detail: 'Invoice gross selected' });

      // }
    } catch (error) {
      console.log("ERROR: ", error);
    }
    
    this.getInitialData()
  }

  FileInvoiceGross() {
    let UploadAndShow = []


    UploadAndShow.push(
      // <>
      <FileUpload
        type="file"
        auto
        name="invoice_gross_file"
        multiple={false}
        mode="basic"
        chooseLabel='Gross file'
        chooseOptions={{
          icon: 'pi pi-fw pi-file',
          // iconOnly: true,
          className: 'p-button-help p-button-rounded p-button-outlined mr-2'
        }}
        ref={(ref) => { this.uploadGross = ref; }}
        // customUpload 
        customUpload uploadHandler={this.saveInvoiceGross}
      />
      // </>

    )
    console.log('UPLOAD AND SHOW: ', UploadAndShow);

    return UploadAndShow
  }

  saveInvoiceNet = async () => {
    const formData = new FormData();
    const filePayment = this.uploadNet.getFiles()


    if (filePayment.length !== 0) {
      formData.append('invoice_net_file', filePayment[0]);
    }


    const config = {
      headers: {

        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      await axios.put(`${url}/api/auth/invoiceNet/${this.props.invoiceId}`, formData, config)
        .then(res => {
          this.toast.show({ severity: 'info', summary: 'Success', detail: 'Invoice gross has been uploaded' });
        })
    } catch (error) {
    }
    this.getInitialData()
  }

  FileInvoiceNet() {
    let UploadAndShow = []

    UploadAndShow.push(
      // <div className='p-fileupload-buttonbar'>
      <FileUpload
        type="file"
        auto
        name="invoice_net_file"
        multiple={false}
        mode="basic"
        chooseOptions={{
          icon: 'pi pi-fw pi-file',
          // iconOnly: true, 
          className: 'p-button-help p-button-rounded p-button-outlined mr-2',
        }}
        chooseLabel='Net file'
        ref={(ref) => { this.uploadNet = ref; }}
        customUpload uploadHandler={this.saveInvoiceNet}
      />
      // </div>
    )

    return UploadAndShow
  }



  FileInvoicenNetDownload() {
    let UploadAndShow = []
    let invoice = { ...this.state.showInvoice }


    if (invoice.invoice_net_file_url) {

      UploadAndShow.push(
        <>
          <div style={{ marginLeft: 10 }}>
            <a href={`${invoice.invoice_net_file_url}`} target="_blank" rel="noopener noreferrer">
              <Button tooltip='Net file' tooltipOptions={{ position: 'bottom' }} type="button" icon="pi pi-download" className='net-file-dwl p-button-success p-button-rounded p-button-outlined' >

              </Button>
            </a>
          </div>
        </>
      )
      return UploadAndShow
    }
  }

  FileInvoiceGrossDownload() {
    let UploadAndShow = []
    let invoice = { ...this.state.showInvoice }


    if (invoice.invoice_gross_file_url) {

      UploadAndShow.push(
        <>
          <div style={{ marginLeft: 10 }}>
            <a href={`${invoice.invoice_gross_file_url}`} target="_blank" rel="noopener noreferrer">
              <Button tooltip='Gross file' tooltipOptions={{ position: 'bottom' }} type="button" icon="pi pi-download" className='p-button-success p-button-rounded p-button-outlined' >

              </Button>
            </a>
          </div>
        </>
      )
      return UploadAndShow

    }
  }

  onValidateChange(e) {
    let invoice = { ...this.state.invoice }

    console.log("E: ", e);

    if (e.value) {
      invoice['validateInvoice'] = 1
      console.log("YES: ", 1);
    } else {
      invoice['validateInvoice'] = 0
      console.log("NO: ", 0);
    }

    this.setState({ checkedValidate: e.value, invoice })
  }

  actionBodyValidate = () => {
    let showInvoice = { ...this.state.showInvoice }
    let invoice = { ...this.state.invoice }

    invoice['validateInvoice'] = showInvoice.validateInvoice
    let showValidate = []

    // console.log("SHOW INVOICE: ", showInvoice.validateInvoice);
    // console.log("INVOICE: ", invoice.validateInvoice);

    if (this.props.user.fk_id_role === 58) {
      if (invoice.validateInvoice === 1) {
        console.log("ENTRO 1");
        if (this.state.editInvoice === true) {
          showValidate.push(
            <>
              <Checkbox
                id='validateInvoice'
                margin='dense'
                value={this.state.checkedValidate || this.state.invoice.validateInvoice}
                checked={this.state.invoice.validateInvoice}
                onChange={this.onValidateChange}
                style={{ verticalAlign: 'middle' }}
              // disabled
              />
              <label
                style={{ width: 190, height: 33, fontSize: 16, margin: "2px" }} >
                Validate invoice
              </label>
            </>
          )
        } else if (this.state.editInvoice === false) {
          console.log("ENTRO ELSE");
          showValidate.push(
            <>
              {/* <Checkbox
              id='validateInvoice'
              margin='dense'
              value={this.state.checkedValidate || invoice.validateInvoice}
              checked={this.state.invoice.validateInvoice}
              onChange={this.onValidateChange}
              style={{ verticalAlign: 'middle'}}
              disabled
            /> */}
              <label
                style={{ width: 190, height: 33, fontSize: 16, margin: "2px", color: 'green' }} >
                Invoice validated
              </label>
            </>
          )
        }
      }
      else if (invoice.validateInvoice === 0) {
        console.log("ENTRO 0");
        if (this.state.editInvoice === true) {
          showValidate.push(
            //     <div className='form-group-row'>
            //       <div className='col-lg-2'>
            //         <div className='p-float-label' 
            //         style={{ width: 190, height: 33, fontSize: 18, 
            //         verticalAlign: 'middle', margin: '-5px', color: 'green'}} 
            //         inputid='validateInvoice'>
            //           Validate invoice
            //         </div>
            // <span>Hola</span>
            //         <div className='col-lg-2'>
            <>
              <Checkbox
                id='validateInvoice'
                margin='dense'
                value={this.state.checkedValidate || invoice.validateInvoice}
                checked={this.state.invoice.validateInvoice}
                onChange={this.onValidateChange}
                style={{ verticalAlign: 'middle' }}
              // disabled
              />
              <label
                style={{ width: 190, height: 33, fontSize: 16, margin: "2px", color: 'red' }} >
                Invoice not validated
              </label>
            </>
            //         </div>
            //       </div>
            //     </div>
          )
        } else {
          showValidate.push(
            //     <div className='form-group-row'>
            //       <div className='col-lg-2'>
            //         <div className='p-float-label' 
            //         style={{ width: 190, height: 33, fontSize: 18, 
            //         verticalAlign: 'middle', margin: '-5px', color: 'green'}} 
            //         inputid='validateInvoice'>
            //           Validate invoice
            //         </div>
            // <span>Hola</span>
            //         <div className='col-lg-2'>
            <>
              {/* <Checkbox
                id='validateInvoice'
                margin='dense'
                value={this.state.checkedValidate || invoice.validateInvoice}
                checked={this.state.invoice.validateInvoice}
                onChange={this.onValidateChange}
                style={{ verticalAlign: 'middle'}}
                disabled
              />  */}
              <label
                style={{ width: 190, height: 33, fontSize: 16, margin: "2px", color: 'red' }} >
                Invoice not validated
              </label>
            </>
            //         </div>
            //       </div>
            //     </div>
          )
        }
      }
    } else {
      if (showInvoice.validateInvoice === 1) {
        // console.log("ENTRO 1");
        if (this.state.editInvoice === true) {
          showValidate.push(
            <>
              {/* <Checkbox
                      id='validateInvoice'
                      margin='dense'
                      value={this.state.checkedValidate || invoice.validateInvoice}
                      checked={this.state.invoice.validateInvoice}
                      onChange={this.onValidateChange}
                      style={{ verticalAlign: 'middle'}}
                      // disabled
                    /> */}
              <label
                style={{ width: 190, height: 33, fontSize: 16, margin: "2px", color: 'green' }} >
                Invoice validated
              </label>
            </>
          )
        } else {
          // console.log("ENTRO ELSE");
          showValidate.push(
            <>
              {/* <Checkbox
                      id='validateInvoice'
                      margin='dense'
                      value={this.state.checkedValidate || invoice.validateInvoice}
                      checked={this.state.invoice.validateInvoice}
                      onChange={this.onValidateChange}
                      style={{ verticalAlign: 'middle'}}
                      disabled
                    /> */}
              <label
                style={{ width: 190, height: 33, fontSize: 16, margin: "2px", color: 'green' }} >
                Invoice validated
              </label>
            </>
          )
        }
      }
      else if (showInvoice.validateInvoice === 0) {
        console.log("ENTRO 0");
        if (this.state.editInvoice === true) {
          showValidate.push(
            //     <div className='form-group-row'>
            //       <div className='col-lg-2'>
            //         <div className='p-float-label' 
            //         style={{ width: 190, height: 33, fontSize: 18, 
            //         verticalAlign: 'middle', margin: '-5px', color: 'green'}} 
            //         inputid='validateInvoice'>
            //           Validate invoice
            //         </div>
            // <span>Hola</span>
            //         <div className='col-lg-2'>
            <>
              <Checkbox
                id='validateInvoice'
                margin='dense'
                value={this.state.checkedValidate || invoice.validateInvoice}
                checked={this.state.invoice.validateInvoice}
                onChange={this.onValidateChange}
                style={{ verticalAlign: 'middle' }}
              // disabled
              />
              <label
                style={{ width: 190, height: 33, fontSize: 16, margin: "2px" }} >
                Validate invoice
              </label>
            </>
            //         </div>
            //       </div>
            //     </div>
          )
        } else {
          showValidate.push(
            //     <div className='form-group-row'>
            //       <div className='col-lg-2'>
            //         <div className='p-float-label' 
            //         style={{ width: 190, height: 33, fontSize: 18, 
            //         verticalAlign: 'middle', margin: '-5px', color: 'green'}} 
            //         inputid='validateInvoice'>
            //           Validate invoice
            //         </div>
            // <span>Hola</span>
            //         <div className='col-lg-2'>
            <>
              {/* <Checkbox
                id='validateInvoice'
                margin='dense'
                value={this.state.checkedValidate || invoice.validateInvoice}
                checked={this.state.invoice.validateInvoice}
                onChange={this.onValidateChange}
                style={{ verticalAlign: 'middle'}}
                disabled
              />  */}
              <label
                style={{ width: 190, height: 33, fontSize: 16, margin: "2px", color: 'red' }} >
                Validate invoice
              </label>
            </>
            //         </div>
            //       </div>
            //     </div>
          )
        }
      }
    }
    return showValidate
  }



  render() {
    let invoice = { ...this.state.showInvoice }

    const invoiceNewItemDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideNewItemDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.createItem} />}
        </FormattedMessage>
      </>
    );

    const deleteInvoiceItemDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteInvoiceItemDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteInvoiceItem} />
      </>
    );

    const saveInvoiceDialogFooter = (
      <>
        <Button label='Cancel' icon='pi pi-times' className='p-button-danger p-button-text' onClick={this.hideSaveInvoiceDialog} />
        <Button label='Save' icon="pi pi-check" className='p-button-success p-button-text' onClick={this.saveInvoice} />
      </>
    )

    return (
      <>
        <Toast ref={(el) => this.toast = el} />
        <Tooltip target=".gross-file-btn" content='Upload gross file' position='bottom' />
        <Tooltip target=".gross-file-dwl" content='Upload gross file' position='bottom' />
        <Tooltip target=".net-file-btn" content='Upload gross file' position='bottom' />
        <Tooltip target=".net-file-dwl" content='Upload gross file' position='bottom' />
        <div className="surface-section px-4 py-5 md:px-6 lg:px-8">
          <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
            <div>
              <div><label>Student:</label> <span className='text-3xl text-900'>{invoice.student}</span> {invoice.validateInvoice === 1 ? <i className='pi pi-check-circle' style={{ fontSize: '15px', color: '#51FF00' }} /> : <i className='pi pi-exclamation-triangle' style={{ fontSize: '15px', color: '#FFCD00' }} />}</div>
              <div className="flex align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3">
                  <i className="pi pi-calendar mr-2"></i>
                  <span>Created at: {invoice.created_at}</span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                  <i className="pi pi-user mr-2"></i>
                  <span>Created by: {invoice.agent}</span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                  <i className="pi pi-at mr-2"></i>
                  <span>Agent email: {invoice.agentEmail}</span>
                </div>
                <div className="flex align-items-center mt-3">
                  <i className="pi pi-building mr-2"></i>
                  <span>Office: {invoice.agency}</span>
                </div>
              </div>
            </div>

            <div style={{ width: '900px' }}>
              <div className='grid' style={{ marginTop: 5 }}>
                {
                  this.state.editInvoice === true ?
                    <>
                      <div className='col-fixed'>
                        <Button tooltip='Add new item' tooltipOptions={{ position: 'bottom' }} className='p-button-outlined p-button-rounded mr-2' icon='pi pi-plus' onClick={this.openNewItem} />
                      </div>

                      <div className='col-fixed'>
                        <Button tooltip='Save invoice' tooltipOptions={{ position: 'bottom' }} icon='pi pi-save' className='p-button-success p-button-outlined p-button-rounded mr-2' onClick={this.confirmSaveInvoiceDialog} />

                      </div>
                      {/* </div> */}

                      <div className='col-fixed'>
                        <Button tooltip='Cancel' tooltipOptions={{ position: 'bottom' }} icon='pi pi-times' className='p-button-outlined p-button-rounded p-button-danger mr-2' onClick={this.cancelInvoice} />

                      </div>


                      <div className='col-fixed'>
                        {this.FileInvoiceGross()}
                      </div>

                      {invoice.invoice_gross_file_url ?
                        <div className='col-fixed'>
                          {this.FileInvoiceGrossDownload()}
                        </div>
                        :
                        <></>
                      }



                      <div className='col-fixed'>
                        {this.FileInvoiceNet()}
                      </div>

                      {invoice.invoice_net_file_url ?
                        <div className='col-fixed'>
                          {this.FileInvoicenNetDownload()}
                        </div>
                        :
                        <></>
                      }

                      <div className='col-fixed'>
                        <InputSwitch tooltip='Validate invoice' tooltipOptions={{ position: 'bottom' }} checked={this.state.checkedValidate} onChange={(e) => this.onValidateChange(e)} /> <span className='vertical-align-super' >Validate invoice</span>
                      </div>

                    </>
                    :
                    <>
                      <div className='col-fixed'>
                        <Button tooltip='Edit invoice' tooltipOptions={{ position: 'bottom' }} icon='pi pi-pencil' className='p-button-rounded p-button-outlined' onClick={this.editInvoice} />
                      </div>

                      {invoice.invoice_gross_file_url ?
                        <div className='col-fixed'>
                          {this.FileInvoiceGrossDownload()}
                        </div>
                        :
                        <></>
                      }

                      {invoice.invoice_net_file_url ?
                        <div className='col-fixed'>
                          {this.FileInvoicenNetDownload()}
                        </div>
                        :
                        <></>
                      }
                      {/* <FileUpload mode='basic' chooseOptions={{ iconOnly: true, icon: 'pi pi-file', className: 'p-button-outlined p-button-rounded'}} />                 */}
                    </>
                }
                {/* {this.FileInvoiceNet()}
              {this.FileInvoiceGrossDownload()}
              {this.FileInvoiceNet()}
              {this.FileInvoicenNetDownload()} */}
                {/* <Button label="Edit" icon="pi pi-check" /> */}
              </div>
            </div>
          </div>


          <br />
          {/* <h3>Detalles de la factura</h3> */}

          <div className='grid'>
            <div className='col'>{this.renderOrderGross()}</div>
            <div className='col'>{this.renderOrderNet()}</div>
          </div>

          {/* <div className='grid'>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-900 font-medium text-xl">Total invoice</span>
                    <div className='grid nested-grid'>
                      <div className='col-12'>
                        <div className="text-600 font-medium text-lg mb-3"> Total program: + <span className='text-600 font-medium text-lg mb-3 text-green-500'> {invoice.symbolAmount}</span></div>
                      </div>

                      <div className='col-12'>
                        <div className="text-600 font-medium text-lg mb-3"> Total additional: + <span className='text-600 font-medium text-lg mb-3 text-green-500'> {invoice.symbolAmountAgency}</span></div>
                      </div>

                      <div className='col-12'>
                        <div className="text-600 font-medium text-lg mb-3"> Total invoice: + <span className='text-600 font-medium text-lg mb-3 text-green-500'> {invoice.symbolInvoice}</span></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                    <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                  </div>
                </div>
                <span className="text-green-500 font-medium">%52+ </span>
                <span className="text-500">since last week</span>
              </div>
            </div>
          </div> */}

            {/* <Card style={{ background: "#F1F3F4" }}>
              <div className='flex justify-content-between mb-3'>
                <span className='text-3xl text-900'> Total invoice </span>

                <div>
                  <div className=" text-3xl text-900 text-xl"> {invoice.symbolInvoice} </div>
                </div>

                <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                  <i className="pi pi-money-bill text-green-500 text-xl"></i>
                </div>
              </div>
            </Card> */}
        </div>


        <Dialog visible={this.state.deleteInvoiceItemDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteInvoiceItemDialogFooter} onHide={this.hideDeleteInvoiceItemDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {<span><FormattedMessage id="ACCOUNTING_INVOICE_ITEM.DIALOG_DELETE" /></span>}
          </div>
        </Dialog>

        <Dialog visible={this.state.newInvoiceItemDialog} contentStyle={{ maxHeight: "800px", width: "850px", height: "370px", overflow: "auto" }} header={this.state.campusText.NewItem} modal footer={invoiceNewItemDialogFooter} onHide={this.hideNewItemDialog}>
          {/* Is agency item*/}
          <br></br>
          <div className="form-group row">
            <div className="col-lg-3">
              {/* <span className="p-float-label"> */}
              <label htmlFor='item_type'> Item Type</label>
              <Dropdown
                style={{ width: 190, height: 33 }}
                id="invoice_item"
                placeholder='Select item type...'
                value={this.state.selectedItemType}
                options={this.ItemTypes}
                onChange={this.onItemTypeChange}
                optionLabel="type"
                optionValue="id"
                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.invoiceItem.isAgencyItem })}
              />
              {/* <label htmlFor="item_type">Item types</label>    */}
              {/* </span> */}
              <small id="invoice_item-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxIsAgencyItem(this.state)}</small>
            </div>

          </div>

          {/* Item*/}
          <br></br>
          <div className="form-group row">
            <div className="col-lg-3">
              <span className="p-float-label">
                <InputText
                  style={{ width: 190, height: 33 }}
                  id="program"
                  variant="outlined"
                  margin="dense"
                  value={this.state.invoiceItem.program}
                  onChange={(e) => this.onInputCreateChange(e, 'program')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.invoiceItem.program })}
                />
                <label htmlFor="item">Item name</label>
              </span>
              <small id="program-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxProgram(this.state)}</small>
            </div>
            {/* Amount*/}
            <br></br>
            <div className="col-lg-3">
              <span className="p-float-label">
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  style={{ width: 190, height: 33 }}
                  id="amount"
                  variant="outlined"
                  margin="dense"
                  value={this.state.invoiceItem.amount}
                  onValueChange={(e) => this.onInputCreateChange(e, 'amount')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.invoiceItem.amount })}
                />
                <label htmlFor="amount">{this.state.campusText.Amount}</label>
              </span>
              <small id="concepto-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAmount(this.state)}</small>
            </div>

            {/* Currency*/}
            <br></br>
            <div className="col-lg-3">
              {this.Currencies()}
            </div>
          </div>
        </Dialog>

        <Dialog visible={this.state.openSaveInvoiceDialog} style={{ width: '450px' }} header="Confirm" modal footer={saveInvoiceDialogFooter} onHide={this.hideSaveInvoiceDialog}>
          <div className='confirmation-content'>
            <i className='pi pi-exclamation-triangle p-mr-3' style={{ fontSize: '2rem' }} />
            <span>Are you sure to save this invoice?</span>
          </div>
        </Dialog>
      </>
    )

  }
}
const mapStateToProps = state => ({
  invoiceId: state.auth.invoiceId,
  user: state.auth.user
});
export default connect(mapStateToProps, auth.actions)(EditInvoice);
