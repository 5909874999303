/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
// import SVG from "react-inlinesvg";
// import {toAbsoluteUrl} from "../../../_helpers";
// import { useIntl } from "react-intl";
import axios from 'axios'
import ServerURL from "../../../../../ServerURL";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

const url = ServerURL();

// const id_director = 58;
const id_gerente = 62;
const id_coordinador = 63;
const id_agente = 64;

export function AdvanceTablesWidget6({ className }) {
  // const int1 = useIntl();
  const my_user = useSelector(state => state.auth.user);


  const [users, setUsers] = useState(null);
  const [originalUsers, setOriginalUsers] = useState(null);
  const [groupAverage, setGroupAverage] = useState(null);
  const [nav, setNav] = useState(<span className="font-weight-bolder text-dark" style={{fontSize:"1.7em"}}><FormattedMessage id="LEARNING_GENERAL.MY_TEAM"/></span>);

  /*
  agents: rankingAgents,
  coordinadores: rankingCoordinadores,
  gerentes: rankingGerentes,
  roleSelected: 3,
  options
  */
 
  const styleButton = {
    background: "none",
    border: "none",
    padding: 0,
    /*optional*/
    fontFamily: "Poppins, Helvetica, sans-serif",
  }


  useEffect(() => {
    if(!users){
      const getTeam = async () =>{
        try {
          await axios.get(`${url}/api/auth/students`)
          .then(res => {
            let data_users = res.data
            console.log("users:",data_users)
    
            let sumAverageCourses = 0;
            const newArrayUsers = []
            data_users.forEach(user => {
              let totalCoursesAssigned = user.totalCoursesAssigned
              if(user.team){
                sumAverageCourses += user.groupAverage;
              }else{
                sumAverageCourses += user.averageCourses;
              }
              user = {...user, totalCoursesAssigned}
              newArrayUsers.push(user);
            })
    
            const groupAverage = sumAverageCourses/data_users.length
    
            setOriginalUsers(res.data);
            setUsers(newArrayUsers);
            setGroupAverage(groupAverage);
          });
          }catch(error){
        }
      }

      getTeam();
    }
  }, [users]);

  const getRol = (role) => {
    let Message = null;
    switch (role) {
      case id_gerente:
        Message = "MANAGER";
        break;
      case id_coordinador:
          Message = "COORDINATOR";
        break;
      case id_agente:
        Message = "AGENT";    
        break;
      default:
        Message = "DIRECTOR";
        break;
    }
    return Message;
  }

  const changeBoss = (boss) => {
    let users = originalUsers
    if(boss !== my_user.fullname){
      let arrayUsers = users;
      users.forEach(user => {
        if(user.fullname === boss){
          arrayUsers = user.team;
        }else{
          if(user.team){
            user.team.forEach(subUser => {
              if(subUser.fullname === boss){
                arrayUsers = subUser.team;
              }
            })
          }
        }
      })
      users = arrayUsers;
    }
    let sumAverageCourses = 0;
      const newArrayUsers = []
      users.forEach(user => {
        let totalCoursesAssigned = user.totalCoursesAssigned
        if(user.team){
          sumAverageCourses += user.groupAverage;
        }else{
          sumAverageCourses += user.averageCourses;
        }
        user = {...user, totalCoursesAssigned}
        newArrayUsers.push(user);
      })

      const groupAverage = sumAverageCourses/users.length

      setUsers(newArrayUsers);
      setGroupAverage(groupAverage);
      getNav(boss)
  }

  const getNav = (boss) => {
    let flag = true;
    if(boss === my_user.fullname){
      setNav(<span className="font-weight-bolder text-dark" style={{fontSize:"1.7em"}}><FormattedMessage id="LEARNING_GENERAL.MY_TEAM"/></span>)
    }else{
      originalUsers.forEach(user => {
        if(user.fullname === boss && flag){
          setNav(
            <div style={{display:"inline-flex"}}>
              <button className="font-weight-bolder text-dark text-hover-primary" style={{...styleButton, width:"50%", fontSize:"1.7em"}} onClick={e => changeBoss(my_user.fullname)}><FormattedMessage id="LEARNING_GENERAL.MY_TEAM"/></button>
              <span style={{fontSize:"1.7em", alignSelf: "center", marginLeft:"2px", marginRight:"2px"}}> / </span>
              <span className="font-weight-bolder text-dark" style={{fontSize:"1.7em", minWidth: "max-content"}}>{user.fullname}</span>
            </div>
            )
          flag = false
        }
      })
      if(flag){
        originalUsers.forEach(user => {
          const subBoss = user.fullname;
          if(user.team){
            user.team.forEach(subUser => {
              if(subUser.fullname === boss && flag){
                setNav(
                  <div style={{display:"inline-flex"}}>
                    <button className="font-weight-bolder text-dark text-hover-primary" style={{...styleButton, fontSize:"1.7em"}} onClick={e => changeBoss(my_user.fullname)}><FormattedMessage id="LEARNING_GENERAL.MY_TEAM"/></button>
                    <span style={{fontSize:"1.7em", alignSelf: "center", marginLeft:"2px", marginRight:"2px"}}>/</span>
                    <button className="font-weight-bolder text-dark text-hover-primary" style={{...styleButton, width: "default",  minWidth: "max-content", fontSize:"1.7em"}} onClick={e => changeBoss(subBoss)}>{subBoss}</button>
                    <span style={{fontSize:"1.7em", alignSelf: "center", marginLeft:"2px", marginRight:"2px"}}>/</span>
                    <span className="font-weight-bolder text-dark" style={{fontSize:"1.7em", minWidth: "max-content"}}>{subUser.fullname}</span>
                  </div>
                  )
                flag = false
              }
            })
          }
        })
      }
    }
  }

  const renderRow = (data) => {
    const role = getRol(data.fk_id_role);
    let viewOption = null;

    if(data.team){
      viewOption = <button className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg" style={styleButton} onClick={e => changeBoss(data.fullname)}>{data.fullname}</button>
    }else{
      viewOption = <span className="text-dark-75 font-weight-bolder font-size-lg">{data.fullname}</span>
    }

    return (
      <tr key={data.id}>
        <td className="pl-0 py-8">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50 symbol-light mr-4">
                <span>
                  <span className="svg-icon h-75 align-self-end">
                    <Avatar alt={data.fullname} src={`${data.photo}`} style={{width: "50px", height: "50px"}} />
                  </span>
                </span>
            </div>
            <div>
              {/* <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{data.fullname}</a> */}
              {viewOption}
              {/* <span className="text-muted font-weight-bold d-block"><FormattedMessage id={`GENERAL_ROLES.${role}`}/></span> */}
            </div>
          </div>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {data.coursesPersonalAssigned}
            </span>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {data.coursesInProgress}
            </span>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {data.coursesCompleted}
            </span>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {
                data.groupAverage ?
                Number.parseFloat(data.groupAverage).toFixed(2)
                :
                Number.parseFloat(data.averageCourses).toFixed(2)
              }
            </span>
        </td>
        <td>
          <span className="text-muted font-weight-bold d-block font-size-sm text-center">
            {data.jefeInmediato}
          </span>
        </td>
      </tr>
    )
  }

  let boolView = true;

  if(users && users.length === 0){
    boolView = false;
  }

  let footer = null;
  if(groupAverage === 0 || groupAverage){
    footer = ": " + groupAverage.toFixed(2);
  }else{
    footer = ": 0";
  }

  return (
    <>
      {
        boolView ?
          (users && users.length !== 0) ?
            <div className={`card card-custom ${className}`}>
              {/* Head */}
              <div className="card-header border-0 py-5">
                {nav}
                <span className="font-weight-bold" style={{width:"100%"}}><FormattedMessage id="LEARNING_REPORTS.SUB_TITLE"/></span>
              </div>
              {/* Body */}
              <div className="card-body pt-0 pb-3">
                <div className="tab-content">
                  <div className="table-responsive">
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                      <thead>
                      <tr className="text-left text-uppercase">
                        <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75"><FormattedMessage id="GENERAL_MESSAGE.MEMBERS"/></span></th>
                        <th className="text-center" style={{minWidth: "100px"}}> Correo </th>
                        <th className="text-center" style={{minWidth: "100px"}}> Phone </th>
                        <th className="text-center" style={{minWidth: "100px"}}> Programas asignado </th>
                      </tr>
                      </thead>
                      {
                        users ?
                          <tbody>
                            {users.map((user) => renderRow(user))}
                          </tbody>
                        :
                          <></>
                      }
                    </table>
                    <div className="text-dark-75 font-weight-bolder mb-2 h3 text-center">
                      <span><FormattedMessage id="GENERAL_MESSAGE.GROUP_AVERAGE"/>{footer}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          :
            <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
        :
          <div style={{display:"grid", justifyItems:"center", marginTop:"15vh", marginBottom:"15vh"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <span style={{marginTop:"1em", fontSize:"2em"}}><FormattedMessage id="GENERAL_MESSAGE.NO_USERS"/></span>
          </div>
      }
    </>
  );
}
