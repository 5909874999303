import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/_core";
import {ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import {DashboardAgencyPage} from "./pages/Agencies/DashboardAgencyPage";

import { GetTasksPage } from './pages/GetTasksPage';
import { AddTaskPage } from './pages/AddTaskPage';
import { UsersPage } from './pages/UsersPage';
import { AgenciesPage } from './pages/AgenciesPage';
import { CampusesPage } from './pages/CampusesPage';
import { ProgramTypesPage } from './pages/ProgramTypesPage';
import { CoursesPage } from './pages/CoursesPage';
import { DataAgencyPage } from "./pagesAgency/DataAgencyPage";

import { OfficesPage } from './pages/OfficesPage';
import { SchoolsPage } from './pages/SchoolsPage';
import { EnterprisesPage } from './pages/EnterprisesPage';

import { RolesPage } from './pages/RolesPage';

const GoogleMaterialPage = lazy(() =>
  import("./modulesAdmin/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modulesAdmin/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modulesAdmin/ECommerce/pages/eCommercePage")

);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/dashboard-agency" component={DashboardAgencyPage}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <ContentRoute path='/get-agency' component={DataAgencyPage}/>
                
                <ContentRoute path="/get-task" component={GetTasksPage}/>
                <ContentRoute path="/add-task" component={AddTaskPage}/>

                <ContentRoute path="/get-users" component={UsersPage}/>
                <ContentRoute path="/get-roles" component={RolesPage}/>
                <ContentRoute path="/get-agencies" component={AgenciesPage}/>
                <ContentRoute path="/get-campuses" component={CampusesPage}/>
                <ContentRoute path="/get-programs" component={ProgramTypesPage}/>
                <ContentRoute path="/get-courses" component={CoursesPage}/>

                <ContentRoute path="/get-enterprises" component={EnterprisesPage}/>
                <ContentRoute path="/get-schools" component={SchoolsPage}/>
                <ContentRoute path="/get-offices" component={OfficesPage}/>

                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
