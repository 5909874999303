import React, { useState, useEffect } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

import axios from 'axios'
import ServerURL from '../../../ServerURL'

const InvoicesHealth = () => {
    const [invoices, setInvoices] = useState('')
    const [loading, setLoading] = useState('')
    const url = ServerURL();

    // async function fetchInvoice() {
    // }

    useEffect(() => {
        axios.get(`${url}/api/public/operation-health/`)
            .then(response => {
                setInvoices(response.data)
            })
    }, [setInvoices])

    const onRefresh = () => {
        setLoading(true)

        setTimeout(() => {
            axios.get(`${url}/api/public/operation-health/`)
                .then(response => {
                    console.log('RESPONSE: ', response);
                    setInvoices(response.data)

                    if (response.request.statusText === 'OK') {
                        setLoading(false)
                    }
                })
        }, 2000)

    }


    console.log('INVOICES: ', invoices);
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" onClick={onRefresh} loading={loading} />;
    // const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" icon="pi pi-file-excel"
                // onClick={exportExcel}
                className="p-button-success mr-2" data-pr-tooltip="XLS" />
        </div>
    )

    return (
        <div>
            Invoices health

            <div className="card">
                <DataTable
                    value={invoices}
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
                    header={header}
                >
                    <Column field="invoiceExternalId" header="External id" sortable></Column>
                    <Column field="invoiceAgent" header="Agent" sortable></Column>
                    <Column field="invoiceOffice" header="Office" sortable></Column>
                    <Column field="invoiceTotalAmount" header="Total amount" sortable></Column>
                    <Column field="invoiceItemsTotal" header="Total items amount" sortable></Column>
                    <Column field="invoiceCompare" header="Compare" sortable></Column>
                </DataTable>
            </div>
        </div>
    )
}

export default InvoicesHealth