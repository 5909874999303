import React from "react";
// import {useSubheader} from "../../_metronic/_core";
import AssignStudents from "../modulesWiyu/ListGroupStudents/AssignStudents";
// import { useIntl } from "react-intl";

export const AssignStudentsGroupsPage = () => {
    // const suhbeader = useSubheader();
    // const int1 = useIntl();

    // suhbeader.setTitle("Assign students");
    return (<><AssignStudents/></>);
}