// ScriptEdvisor
import React  from 'react';
import { useSelector } from 'react-redux';
import { ApolloClient,InMemoryCache,HttpLink } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import axios from 'axios'
import ServerURL from '../../../ServerURL'


import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';

import "./styles.css";


const url = ServerURL();

let today = new Date()
let dayMiliseconds = 120 * 60 * 60 * 1000;
let yday = new Date(today.getTime() - dayMiliseconds);


let y = yday.getFullYear();
let m = yday.getMonth()+1;
let d = yday.getDate();
if (d < 10) {
  d = '0' + d;
}
if (m < 10) {
  m = '0' + m;
}
let nd = y +'-' + m + '-'+ d

//const urlEdvisor = EdvisorURL();
//console.log(urlEdvisor);


// const cache = new InMemoryCache();
// const client = new ApolloClient({
//   cache,
//   link: new HttpLink({
//       uri: 'https://api.edvisor.io/graphql',
//       headers:{
//         authorization: `Bearer ${urlEdvisor}`
//       },
//   }),
// });

const InvoicesQuery = () => {

  let GET_DATA = gql
  // `
  // query($yesterday:DateOnly) {
  // created: {gte:$yesterday},      

    `
    query RootQueryType {
    invoiceList(
    pagination: { limit: 100, offset:0 }, 
    filter: {
      agencyIds: [6452],
    }) {
      count
      data {
        invoiceId
        externalId
        createdByUserId
        depositDateDue
        lastUpdatedByUserId
        created
        modified
    
      invoiceType {
        invoiceTypeId
        codeName
      }
      invoiceStatus {
        invoiceStatusId
        codeName
        }
      
      paymentCurrency {
        currencyId
        code
        symbol
        oneUsd
      }
      totalAmount

      student{
        studentId
        firstname
        lastname
        preferredName
        email
        agencyId
          agency {
            agencyId
            agencyCompanyId
            name
            email
              agencyCompany{
                agencyCompanyId
                name
                email
                websiteUrl
              } 
          }
          owner{
            userId
            firstname
            lastname
            email 
          }
      }

      rawItems {
        invoiceItemId
        invoiceId
        invoiceItemType{
          invoiceItemTypeId
          codeName   
          displayOrder      
        }
        school
        program
        isAgencyItem
        isGlobalFee
        position
        durationAmount
        durationTypeId
        startDate
        endDate
        amount
        isAmountPerDuration
        serviceFeeMultiplier
        currency{
          currencyId
          code
          symbol
          oneUsd
        }
        snapshotExtensionDurationTypeId
        snapshotFreeDurationAmount
        snapshotBonusDurationAmount
        snapshotPromotionApplicableTypeId
        snapshotPromotionSecondaryTypeId
        snapshotPromotionPercentage
        offering{
          school{
            name
            schoolCompany{
              name
            }
            country{
              code
              continent
            }
          }
        }
        notes
        
        created
        modified
      }
      invoiceFiles {
        fileId
        uploaderUserId
        mimeType
        fileExtension
        name
        path
        url
        created
        modified
      }
    
      } 
    }
  
  }
  ` 

  console.log("GET_DATA: ", GET_DATA);


  return (
    
    <Query query={GET_DATA} variables={{ yesterday:nd }}>

      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error!</p>;

      let options = {
          headers: {
              'Content-Type': 'application/json'
          }
      }

    let requestOne = axios.post(`${url}/api/auth/invoice`,data.invoiceList.data, options);
    let requestTwo = axios.post(`${url}/api/auth/invoiceItem`, data.invoiceList.data, options) 
    let requestThree =  axios.post(`${url}/api/auth/net_invoice`, data.invoiceList.data, options)
    let requestFour = axios.post(`${url}/api/auth/invoiceNetItems`,data.invoiceList.data, options);

    

    // let requestFour = axios.post(`${url}/api/public/students-edvisor`, data.invoiceList.data, options);

    // console.log("requestThree: ", requestThree  );

    axios
    .all([
      requestOne, 
      requestTwo, 
      requestThree,
      requestFour
    ])
    .then(
          axios.spread((...responses) => {
            const invoice = responses[0].data;
            const invoiceItems = responses[1].data
            const net_invoice = responses[2].data;
            const invoiceNetItems = responses[3].data
            // const student = responses[0].data  

            console.log(invoice)
            console.log(net_invoice)
            console.log(invoiceItems);     
            console.log(invoiceNetItems);       
            // console.log(student);

          }))
    .catch(errors => {
      console.error(errors);
    });  

    console.log("DATA:", data.invoiceList.data)


          return(
            <div>
              Information loaded!
            </div>
            
          )
      }}
    </Query>
  );
};


function InvoicesTabel() {
  
  return (
    <div className="App">
      <InvoicesQuery />
    </div>
  );
}

export default () => {

  const [apiKey, setApiKey] = React.useState(null);
  const userData = useSelector((state) => state.auth.user)

  // console.log(userData);


  React.useEffect(() => {
    axios.get(`${url}/api/auth/enterprise/${userData.fk_id_enterprise}`).then((response) => {
      setApiKey(response.data.api_key);
    });
  }, [userData.fk_id_enterprise]);  

// console.log(apiKey);

let api_keyEdvisor = apiKey;

const cache = new InMemoryCache();
const client = new ApolloClient({
  cache,
  link: new HttpLink({
      uri: 'https://api.edvisor.io/graphql',
      headers:{
        authorization: `Bearer ${api_keyEdvisor}`
      },
  }),
})
return (
	<ApolloProvider client={client}> {/* en "client" debe ir la configuración de apollo */}
		<InvoicesTabel />
	</ApolloProvider>
)
};


