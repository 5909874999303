import axios from "axios";
import ServerURL from '../../../../../src/ServerURL'

const url  = ServerURL();

export const LOGIN_URL = `${url}/api/public/auth/login`;
export const REGISTER_URL = `${url}/api/public/register`;
export const REQUEST_PASSWORD_URL = `${url}/api/auth/forgot-password`;

export const ME_URL = `${url}/api/auth/me`;



export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, firstName, lastName, companyName, typeEnterprise, password) {
  return axios.post(REGISTER_URL, { email, firstName, lastName, companyName, typeEnterprise, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor. //
  return axios.get(ME_URL);
}




