import React, {Component} from 'react'
import classNames from 'classnames';
// import { withRouter } from "react-router";


//Traduccion
import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

//Componentes
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
// import { Dialog } from 'primereact/dialog'
import { Toast } from "primereact/toast";
// import { Dropdown } from 'primereact/dropdown'
// import { KeyboardBackspaceSharpIcon } from '@material-ui/icons/KeyboardBackspaceSharp';

//Pages
// import { ParentsInfo } from './ParentsInfo'

//Conexion con API
import axios from 'axios'
import ServerURL from '../../../ServerURL'

// Redux
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux"

//Funciones extras
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

//PrimeReact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// import NewStudents from './NewStudents';

const url = ServerURL();

class StudentsInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            first_name: this.props.dataStudent.first_name,
            last_name: this.props.dataStudent.last_name,
            email: this.props.dataStudent.email,
            phone: this.props.dataStudent.phone,
            whatsapp: this.props.dataStudent.whatsapp,
            school_origin: this.props.dataStudent.school_origin,
            country_origin: this.props.dataStudent.country_origin,
            fb_link: this.props.dataStudent.fb_link,
            ig_user: this.props.dataStudent.ig_user,

            first_nameMessage: '',
            last_nameMessage: '',
            emailMessage: '',
            phoneMessage: '',
            whatsappMessage: '',
            school_originMessage: '',
            country_originMessage: '',
            fb_linkMessage: '',
            ig_userMessage: '',

            student: this.emptyStudent,
            students: null,
            studentDialog: null,
            submitted: false,
            
            redirect: false,
            redirectParents: false,
        }

        this.saveStudent = this.saveStudent.bind(this);
        // this.hideDialog = this.hideDialog.bind(this);
        this.confirmStudent = this.confirmStudent.bind(this);
        this.updateStudent = this.updateStudent.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changePage2 = this.changePage2.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
    }

    // componentDidMount() {
    //     // this.getStudents()
    //     // this.setState({redirect: false})
    // }

    changePage(){
        this.setState({redirect: true})
    }

    changePage2(){
        this.setState({redirectParents: true})
    }

    // getStudents = async () => {
    //     try {
    //         let res = await axios.get(`${url}/api/public/students`)
    //         .then(res => {
    //             const students = res.data;
    //             this.setState({ students })
    //         });
    //         console.log(res.data)
    //     } catch (error) {
    //     }
    // }

    getGroupStudent = async () => {
        try {
            let res = await axios.get(`${url}/api/public/wiyu_groups`)
            .then(res => {
                const groups =  res.data;
                this.setState({ groups })
                console.log("Grupos: ", groups);
            })
            console.log(res.data);
        } catch (error) {
            
        }
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let student = {...this.state.student};
        student[`${name}`] = val;
        this.setState({ student })
    }



    confirmStudent() {
        this.setState({
            student: this.emptyStudent,
            submitted: false,
            studentDialog: true
        })
    }

    // hideDialog() {
    //     this.setState({
    //         submitted: false,
    //         studentDialog: false,
    //         first_nameMessage: '',
    //         last_nameMessage: '',
    //         emailMessage: '',
    //         phoneMessage: '',
    //         whatsappMessage: '',
    //         school_originMessage: '',
    //         country_originMessage: '',
    //         fb_linkMessage: '',
    //         ig_userMessage: ''
    //     })
    // }

    validateFirstName() {
        const student = this.state.student

        let first_nameMessage;
        if (!student.first_name){
            first_nameMessage = 'Requerido'
        } else {
            first_nameMessage = ''
        }
        this.setState({ first_nameMessage})
        return first_nameMessage
    }

    validateLastName() {
        const student = this.state.student

        let last_nameMessage;
        if (!student.last_name){
            last_nameMessage = 'Requerido'
        } else {
            last_nameMessage = ''
        }
        this.setState({ last_nameMessage})
        return last_nameMessage
    }

    validateEmail() {
        const student = this.state.student

        let emailMessage;
        if (!student.email){
            emailMessage = 'Requerido'
        } else {
            emailMessage = ''
        }
        this.setState({ emailMessage})
        return emailMessage
    }

    validatePhone() {
        const student = this.state.student

        let phoneMessage;
        if (!student.phone){
            phoneMessage = 'Requerido'
        } else {
            phoneMessage = ''
        }
        this.setState({ phoneMessage})
        return phoneMessage
    }

    validateSchoolOrigin() {
        const student = this.state.student

        let school_originMessage;
        if (!student.school_origin){
            school_originMessage = 'Requerido'
        } else {
            school_originMessage = ''
        }
        this.setState({ school_originMessage})
        return school_originMessage
    }

    validateCountryOrigin() {
        const student = this.state.student

        let country_originMessage;
        if (!student.country_origin){
            country_originMessage = 'Requerido'
        } else {
            country_originMessage = ''
        }
        this.setState({ country_originMessage})
        return country_originMessage
    }

    isValid() {
        const { first_name, last_name, email, phone, school_origin,
        country_origin } = this.state.student

        const first_nameMessage = this.validateFirstName();
        const last_nameMessage = this.validateLastName();
        const emailMessage = this.validateEmail();
        const phoneMessage = this.validatePhone();
        const school_originMessage = this.validateSchoolOrigin()
        const country_originMessage = this.validateCountryOrigin();
        
        if(first_name && last_name && email && phone && school_origin && country_origin){
            if(!first_nameMessage && !last_nameMessage && !emailMessage && !phoneMessage &&
            !school_originMessage && !country_originMessage){
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    saveStudent() {
        let state = { submitted: true }
        let data = {...this.state.student}

        try {
            let res = axios.post(`${url}/api/public/students`, data)
            .then(res => {
                this.toast.show(res.message)
                this.getStudents()
                console.log(res.message)
            })
            if(!res.error){
                this.toast.show({severity: 'success', summary: 'Successful', detail: 'Student signed', life: 3000})
            }
        } catch (error) {
            console.log(error);
        }

        state = {
            ...state,
            data,
            studentDialog: false,
            student: this.emptyStudent,
        }
        console.log("Data: ", data);
        console.log("State: ", state);
        this.setState(state)
    }

    async updateStudent(){
        let state = { submitted: true }
        let data = {
            name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            phone: this.state.phone,
            whatsapp: this.state.whatsapp,
            school_origin: this.state.school_origin,
            country_origin: this.state.country_origin,
            fb_link: this.state.fb_link,
            ig_user: this.state.ig_user
        }
        // console.log("Data: ", data);
        try {
            await axios.put(`${url}/api/public/students/${this.props.dataStudent.id}`, data)
            .then(res => {
                const dataStudent ={
                    ...this.props.dataStudent,
                    first_name: data.name,
                    last_name: data.last_name,
                    email: data.email,
                    phone: data.phone,
                    whatsapp: data.whatsapp,
                    school_origin: data.school_origin,
                    country_origin: data.country_origin,
                    fb_link: data.fb_link,
                    ig_user: data.ig_user
                };
                this.props.updatedStudent(dataStudent)

                // this.setState({...state, data})
                console.log(res);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Student updated', life: 3000})
            })
        } catch (error) {
        }
        console.log("State: ", state);
        console.log("Data: ", data);
        this.setState({...state, data})

    }

    render(){

        // url_back = '/wiyu-students'
        // url_next = <ParentsInfo />



        if(this.state.redirect){
            return <Redirect to={{
                pathname: '/wiyu-students',
            }} />
        }

        if(this.state.redirectParents){
            return <Redirect to={{
                // <NewStudents />
            }} />
        }

        const studentDialogFooter = (
            <>
                <FormattedMessage id="GENERAL.CANCEL">
                    {(message) => <Button label={message} icon='pi pi-times' className='p-button-text' onClick={this.hideDialog}></Button>}
                </FormattedMessage>
                <FormattedMessage id="GENERAL.SAVE">
                    {(message) => <Button label={message} icon="pi pi-check" className='p-button-text' onClick={this.saveStudent} ></Button>}
                </FormattedMessage>
            </>
        )

        return(
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className='d-flex justify-content-between mb-2'>
                    <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" icon="pi pi-arrow-left" onClick={this.changePage} variant='contained'/>
                </div>
                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <h5 className='card-label'> Students info
                        <span className="d-block text-muted pt-2 font-size-sm" >Update your student information</span>
                    </h5>
                </div>
                <div>
                    <div className='card'>
                        <br/>
                        <br/>
                        <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-user'></i>
                                </span>
                                {/* First name */}
                                <span className='p-float-label'>
                                <InputText
                                    // placeholder={this.props.dataStudent.first_name || 'First name'}
                                    style={{width: 190, height: 33}}
                                    id="first_name"
                                    variant="outlined"
                                    margin="dense"
                                    value={this.state.first_name}
                                    onChange={(e) => this.setState({first_name: e.target.value} )}
                                />
                                <label htmlFor="first_name">First name</label>                            
                                </span>
                                {
                                    this.state.first_nameMessage && (
                                        <small id="first_name-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.first_nameMessage}/></small>
                                    )
                                }
                            </div>
                            <small className='p-d-block' id='first_name-help'>Enter the student first name</small>
                        </div>

                        {/* Last name */}
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-user'></i>
                                </span>
                                <span className='p-float-label'>
                                    <InputText 
                                    // placeholder={this.props.dataStudent.last_name || 'Last name'}
                                    style={{width: 190, height: 33}}
                                    id="last_name"
                                    variant="outlined"
                                    margin="dense"
                                    value={this.state.last_name}
                                    onChange={(e) => this.setState({last_name: e.target.value} )}
                                    />
                                    <label htmlFor="last_name">Last name name</label>                            
                                </span>
                                {
                                    this.state.last_nameMessage && (
                                        <small id="last_name-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.last_nameMessage}/></small>
                                    )
                                }
                            </div>
                            <small className='p-d-block' id='last_name-help'>Enter the student last name</small>
                        </div>
                        </div>

                        <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                        <div className='p-col-12 p-md-4'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-envelope'></i>
                                </span>
                                {/* EMAIL */}
                                <span className='p-float-label'>
                                    <InputText 
                                    // placeholder={this.props.dataStudent.email || 'Email'}
                                    style={{width: 190, height: 33}}
                                    id="email"
                                    variant="outlined"
                                    margin="dense"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({email: e.target.value} )}
                                    />
                                    <label htmlFor="email">Email</label>                            
                                </span>
                                {
                                    this.state.emailMessage && (
                                        <small id="email-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.emailMessage}/></small>
                                    )
                                }
                            </div>
                            <small className='p-d-block' id='whatsapp-help'>Enter the student email. Ex. email@email.com</small>
                        </div>

                        <div className='p-col-12 p-md-4'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-phone'></i>
                                </span>
                                {/* Phone */}
                                <span className='p-float-label'>
                                    <InputText 
                                    // placeholder={this.props.dataStudent.phone || 'Phone'}
                                    style={{width: 190, height: 33}}
                                    id="phone"
                                    variant="outlined"
                                    margin="dense"
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({phone: e.target.value} )}
                                    />
                                    <label htmlFor="phone">Phone</label>                            
                                </span>
                                {
                                    this.state.phoneMessage && (
                                        <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.phoneMessage}/></small>
                                    )
                                }
                            </div>
                            <small className='p-d-block' id='phone-help'>Enter phone's number of the student. Ex. (000) 000-0000</small>
                        </div>

                        <div className='p-col-12 p-md-4'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-phone'></i>
                                </span>
                                {/* Whatsapp */}
                                <span className='p-float-label'>
                                    <InputText 
                                        // placeholder={this.props.dataStudent.whatsapp || 'WhatsApp'}
                                        style={{width: 190, height: 33}}
                                        id="whatsapp"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.whatsapp}
                                        onChange={(e) => this.setState({whatsapp: e.target.value} )}
                                    />
                                    <label htmlFor='whatsapp'>WhatsApp</label>
                                </span>
                                {this.state.whatsappMessage &&(
                                    <small id='whatsapp-help' className={classNames({'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.whatsappMessage}></FormattedMessage></small>
                                )}
                            </div>
                            <small className='p-d-block' id='whatsapp-help'>Enter WhatsApp number of the student. Ex. (000) 000-0000</small>
                        </div>


                        </div>

                        <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-paperclip'></i>
                                </span>
                                {/* School origin */}
                                <span className='p-float-label'>
                                    <InputText 
                                        // placeholder={this.props.dataStudent.school_origin || 'School origin'}
                                        style={{width: 190, height: 33}}
                                        id="school_origin"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.school_origin}
                                        onChange={(e) => this.setState({school_origin: e.target.value} )}
                                    />
                                    <label htmlFor='school_origin'>School origin</label>
                                </span>
                            </div>
                            <small className='p-d-block' id='school_origin-help'>School origin of the student</small>
                        </div>

                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-globe'></i>
                                </span>
                                {/* Country origin */}
                                <span className='p-float-label'>
                                    <InputText 
                                        // placeholder={this.props.dataStudent.country_origin || 'Country origin'}
                                        style={{width: 190, height: 33}}
                                        id="Country origin"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.country_origin}
                                        onChange={(e) => this.setState({country_origin: e.target.value} )}
                                    />
                                    {/* <Dropdown /> */}
                                    <label htmlFor='country_origin'>Country origin</label>
                                </span>
                            </div>
                            <small className='p-d-block' id='country_origin-help'>Country origin of the student</small>
                        </div>
                        </div>

                        <div className='form-group row' style={{marginLeft: 3, marginRight: 5}}>
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className="pi pi-facebook"></i>
                                </span>
                                {/* Fb link */}
                                <span className='p-float-label'>
                                    <InputText 
                                        // placeholder={this.props.dataStudent.fb_link || 'Facebook link'}
                                        style={{width: 190, height: 33}}
                                        id="fb_link"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.fb_link}
                                        onChange={(e) => this.setState({fb_link: e.target.value} )}
                                    />
                                    <label htmlFor='fb_link'>Facebook Link</label>
                                </span>
                            </div>
                            <small className='p-d-block' id='fb_link-help'>Facebook profile of the student</small>
                        </div>

                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-comment'></i>
                                </span>
                                {/* IG user */}
                                <span className='p-float-label'>
                                    <InputText 
                                        // placeholder={this.props.dataStudent.ig_user || 'Instagram user'}
                                        style={{width: 190, height: 33}}
                                        id="ig_user"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.ig_user}
                                        onChange={(e) => this.setState({ig_user: e.target.value} )}
                                    />
                                    <label htmlFor='ig_user'>Instagram user</label>
                                </span>
                            </div>
                            <small className='p-d-block' id='fb_link-help'>Instagram user of the student</small>
                        </div>
                        </div>

                        <div style={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'flex-end',
                        paddingBottom: 10,
                        marginRight: 5
                        }}>

                            {/* Boton cancelar */}
                            <div style={{marginLeft: 10}}>
                                <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" label='Cancelar' icon="pi pi-times" onClick={this.changePage} variant='contained'/>
                            </div>

                            {/* Boton guardar */}
                            <div style={{marginLeft: 10}}>
                                <Button className='p-button-rounded p-button-help p-button-outlined' type="button" label='Guardar' icon="pi pi-check" onClick={this.updateStudent} variant='contained'/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataStudent: state.auth.dataStudent
});

export default injectIntl(
    connect(mapStateToProps, auth.actions)(StudentsInfo)
)