import React, { useMemo } from "react";
// import {AsideMenuListMyPlan} from "../../../../layout/components/aside/aside-menu/AsideMenuListMyPlan";
import { AsideMenuListDG } from "./AsideMenuListDG";
import { AsideMenuListDO } from "./AsideMenuListDO";
import { AsideMenuListPM } from "./AsideMenuListPM";
import { AsideMenuListA } from "./AsideMenuListA";
import { AsideMenuListDefault } from "./AsideMenuListDefault";
import { useHtmlClassService } from "../../../../_core";
import { renderSwitch as renderSwitchAccounting } from "../../../../layoutAccounting/components/aside/aside-menu/AsideMenu";
import { renderSwitch as renderSwitchWiyu } from "../../../../layoutWiyu/components/aside/aside-menu/AsideMenu";
import { renderSwitch as renderSwitchSettings } from "../../../../layoutWiyu copy/components/aside/aside-menu/AsideMenu";
// import { renderSwitch as renderSwitchSettings } from "../../../../layoutSettings/components/aside/aside-menu/AsideMenu"; 
import { useSelector } from "react-redux";
// import { NavLink } from "react-router-dom";
import { Snackbar } from '@material-ui/core/';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function AsideMenu({ disableScroll }) {
  const { user } = useSelector(state => state.auth);
  const uiService = useHtmlClassService();

  let is_active = false;

  if (user.is_active === 1) {
    is_active = true;
  }

  const [open, setOpen] = React.useState(!is_active);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  const renderSwitch = (user) => {
    let agency_menu = null
    if (user.learning) {
      if (user.owner) {
        agency_menu = <AsideMenuListDG layoutProps={layoutProps} />;
      } else {
        switch (user.role) {
          case 'Director':
            agency_menu = <AsideMenuListDG layoutProps={layoutProps} />;

            break;
          case 'Gerente':
            agency_menu = <AsideMenuListDO layoutProps={layoutProps} />;
            break;
          case 'Coordinador':
            agency_menu = <AsideMenuListPM layoutProps={layoutProps} />;
            break;
          case 'Agente':
            agency_menu = <AsideMenuListA layoutProps={layoutProps} />;
            break;
          default:
            agency_menu = <AsideMenuListDefault layoutProps={layoutProps} />;
        }
      }
    }
    return <>
      {renderSwitchAccounting(user, layoutProps)}
      {agency_menu}
      {renderSwitchWiyu(user, layoutProps)}
      {
        user.owner ?
          renderSwitchSettings(user, layoutProps)
          :
          <></>
      }
      {/* {
      user.billing === 1 && (
        <div className="billing">
          <h4>Plan Inicial</h4>
          <span>Usuarios ilimitados</span>
          <span>0 de 3 cursos creados</span>
          <span>0 de 5 registros de pagos</span>
          <NavLink to="/billing">
            <span className="access-billing">Modificar mi plan</span>
          </NavLink>
        </div>
        // <AsideMenuListMyPlan layoutProps={layoutProps} />
      )
    } */}
      <Snackbar
        open={open}
        autoHideDuration={60000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="info">
          <FormattedMessage id="GENERAL_MESSAGE.ACCOUNT_NOT_CONFIRMED" />
        </Alert>
      </Snackbar>
    </>;
  };

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {renderSwitch(user)}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
