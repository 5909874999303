import React, {useMemo} from "react";
import {AsideMenuListA} from "./AsideMenuListA";
import {AsideMenuListH} from "./AsideMenuListH";
import {AsideMenuListDefault} from "./AsideMenuListDefault";
import {useHtmlClassService} from "../../../../_core";
import { useSelector } from "react-redux";

export function AsideMenu({disableScroll}) {
  const { user } = useSelector(state => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  const renderSwitch = (role) => {
    switch(role) {
      case 'Administrador':
        return <AsideMenuListA layoutProps={layoutProps} />;
      case 'Helpdesk':
        return <AsideMenuListH layoutProps={layoutProps} />;
      default:
        return <AsideMenuListDefault layoutProps={layoutProps} />;
    }
  };

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {renderSwitch(user.role)}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
