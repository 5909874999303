import React from "react";
import {useSubheader} from "../../_metronic/_core";
import EventsTable from "../modulesWiyu/StudentsAndEvents/EventsTable"

export const EventsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Roles");

  return (<><EventsTable/></>);

};