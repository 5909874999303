import React, { Component } from 'react';
import axios from 'axios'
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import ServerURL from '../../../ServerURL'
import "primeflex/primeflex.css";
import { FormattedMessage, injectIntl } from 'react-intl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';


const url = ServerURL();

export default class ListAssignmentPrograms extends Component {

  constructor(props) {
    super(props);
    this.state = {
      layout: 'grid',
      secondLayout: 'grid',
      programsWithAssignment: null,
      programsWithoutAssignment: null,
      groups: [],
      students_original: null,
      students_show: null,
      infoProgramSelect: null,
      selectProgram: null,
      selectedGroup: null,
      dialog: false,
      btnDisable: false,
      displayConfirmation: false,
      assignmentProgramDialog: false,
      options: null
    };

    this.onHide = this.onHide.bind(this);
    this.onClick = this.onClick.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.onProgramSelect = this.onProgramSelect.bind(this);
    // this.onGroupAgenciesChange = this.onGroupAgenciesChange.bind(this);
    this.itemSecondTemplate = this.itemSecondTemplate.bind(this);
  }

  componentDidMount() {
    this.getPrograms();
    this.getStudents();
    this.getGroups();
  }

  onClick(name) {
    let state = {
      [`${name}`]: true
    };

    this.setState(state);
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false,
      selectedProgram: null,
      selectedGroup: null,
    });
  }

  onProgramSelect(e) {
    this.setState({ selectProgram: e.value }, () => {
      this.op.hide();
    });
  }

  // onOptionChange(e) {
  //   const newArrayOptionShow = []
  //   if(e.value){
  //     this.state.agreements_original.forEach(agreement => {
  //       const found = e.value.agencies.find(id => id === agreement.id);
  //       if(found){
  //         newArrayAgreementShow.push(agreement);
  //       }
  //     })
  //   }
  //   this.setState({ selectedGroupAgencies: e.value, agreements_show: newArrayAgreementShow });
  // }

  selectedGroupTemplate(option, props) {
    if (option) {
      return (
        <div className="country-item data-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  
  groupOptionTemplate(option) {
    return (
        <div className="data-item">
            <div>{option.name}</div>
        </div>
    );
  }

  getPrograms = async () =>{
    try {
      await axios.get(`${url}/api/public/wiyu_programs/`)
      .then(res => {
        const newArrayProgramsWithAssignment = []
        const newArrayProgramsWithoutAssignment = []
        
        const arrayPrograms = res.data
        arrayPrograms.forEach(program => {
          if(program.isAssigned){
            newArrayProgramsWithAssignment.push(program)
          }else{
            newArrayProgramsWithoutAssignment.push(program)
          }
        })
        this.setState({ programsWithAssignment: newArrayProgramsWithAssignment, programsWithoutAssignment: newArrayProgramsWithoutAssignment})
      });
      }catch(error){
    }
  }

  getStudents = async () =>{ 
    try {
      await axios.get(`${url}/api/public/students/`)
      .then(res => {
        const students_original  = res.data;
        this.setState({ students_original })
      });                     
    }catch(error){
    }
  }

  getGroups = async () =>{
    try {
      await axios.get(`${url}/api/public/wiyu_groups/`)
      .then(res => {
        const groups = res.data;
        this.setState({ groups })
      });
      }catch(error){
    }
  }
  
  getInfoProgramSelect = async (program) => {
    const data = {
      program
    }
    try {
      await axios.post(`${url}/api/auth/wiyu_program_info`, data)
      .then(res => {
        const infoProgramSelect = {...program, group: res.data};
        const newArrayStudentsShow = [];
        this.state.students_original.forEach(student => {
          const found = infoProgramSelect.group.groups.find(id => id === student.id);
          if(found){
            newArrayStudentsShow.push(student);
          }
        })
        this.setState({ assignmentProgramDialog: true, infoProgramSelect, students_show: newArrayStudentsShow })
      });
      }catch(error){
    }
  }

  saveGroupAgencies = async () => {
    if(this.isValid()){
      this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.FULL_ALLOCATION'}), life: 4000});
      const data = {
        fk_id_course: this.state.selectCourse.id,
        fk_id_group_agencies: this.state.selectedGroupAgencies.id
      }
      console.log(data)
      await axios.post(`${url}/api/auth/course_assigned`, data)
      .then(res => {
        this.setState({ 
          assignmentCourseDialog: false,
          selectCourse: null,
          selectedGroupAgencies: null,
          agreements_show: []
        })
        this.getCourses();
      });
    }
  }

  isValid = () => {
    try{
      const timeLife = 4000
      let isSelectedCourse = true
      let isPublished = true
      let isSelectedGroupAgencies = true
      if(!this.state.selectCourse){
        isSelectedCourse = false;
      }else if(this.state.selectCourse.published === 0){
        isPublished = false
      }
      if(!this.state.selectedGroupAgencies){
        isSelectedGroupAgencies = false
      }
      if(isSelectedCourse && isSelectedGroupAgencies && isPublished){
        return true
      }else{
        if(!isSelectedCourse && !isSelectedGroupAgencies){
          this.toast.show([
            {severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.INCOMPLETE_ALLOCATION'}), detail: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.NOT_SELECT_COURSE_MESSAGE'}), life: timeLife},
            {severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.INCOMPLETE_ALLOCATION'}), detail: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.NOT_SELECT_GROUP_MESSAGE'}), life: timeLife},
          ]);
          return false
        }else{
          if(isSelectedCourse && isSelectedGroupAgencies){
            this.toast.show([
              {severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.INCOMPLETE_ALLOCATION'}), detail: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.NOT_PUBLISHED_MESSAGE'}), life: timeLife},
            ]);
            return false
          }else{
            if(!isSelectedCourse){
              this.toast.show([
                {severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.INCOMPLETE_ALLOCATION'}), detail: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.NOT_SELECT_COURSE_MESSAGE'}), life: timeLife},
              ]);
              return false
            }else if(!isPublished){
              this.toast.show([
                {severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.INCOMPLETE_ALLOCATION'}), detail: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.NOT_PUBLISHED_MESSAGE'}), life: timeLife},
                {severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.INCOMPLETE_ALLOCATION'}), detail: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.NOT_SELECT_GROUP_MESSAGE'}), life: timeLife},
              ]);
              return false
            }else{
              this.toast.show([
                {severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.INCOMPLETE_ALLOCATION'}), detail: this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.NOT_SELECT_GROUP_MESSAGE'}), life: timeLife}
              ]);
              return false
            }
          }
        }
      }
    }catch(error){}
  }

  imageBody(rowData) {
    return <img src={rowData.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image" />
  }

  itemTemplate(course, layout) {
    if (!course) {
      return (<></>);
    }

    if (layout === 'list')
      return this.renderListItem(course);
    else if (layout === 'grid')
      return this.renderGridItem(course);
  }

  itemCourseTemplate(course){
    if (!course) {
      return (<></>);
    }
    return this.renderListItem(course)
  }

  itemSecondTemplate(course, layout) {
    if (!course) {
      return (<></>);
    }

    if (layout === 'list')
      return this.renderListSecondItem(course);
    else if (layout === 'grid')
      return this.renderGridSecondItem(course);
  }

  renderListItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} />
          <div className="course-list-detail">
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
            <span className="course-category"><FormattedMessage id="GENERAL_MESSAGE.LAST_MODIFICATION"/>: {data.updated_at.substring(0,9)}</span>
          </div>
          <div className="course-list-action">
            <span className="course-price">{data.program_type}</span>
            <div style={{display:"flex", justifyContent:"flex-end"}}>
              <Button icon="pi pi-search" color='primary' onClick={() => this.getInfoCourseSelect(data)} style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}}/>
            </div>
            {
              data.published === 0 ?
              <span className="p-tag course-badge text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
              :
              <span className="p-tag course-badge text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
            }
          </div>
        </div>
      </div>
    );
  }

  renderListSecondItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} />
          <div className="course-list-detail">
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
            <span className="course-category"><FormattedMessage id="GENERAL_MESSAGE.LAST_MODIFICATION"/>: {data.updated_at.substring(0,9)}</span>
          </div>
          <div className="course-list-action">
            <span className="course-price">{data.program_type}</span>
            {
              data.published === 0 ?
              <span className="p-tag course-badge text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
              :
              <span className="p-tag course-badge text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
            }
          </div>
        </div>
      </div>
    );
  }

  renderGridItem(data) {
    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card">
          <div className="course-grid-item-top">
            <div>
              {
                data.published === 0 ?
                <span className="p-tag course-category text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
                :
                <span className="p-tag course-category text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
              }
            </div>
            <span className={`course-badge`}>{data.program_type}</span>
          </div>
          <div className="course-grid-item-content">
            <img src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
          <div className="course-grid-item-bottom">
            <Button icon="pi pi-search" color='primary' onClick={() => this.getInfoCourseSelect(data)} style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}}/>
          </div>
        </div>
      </div>
    );
  }

  renderGridSecondItem(data) {
    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card">
          <div className="course-grid-item-top">
            <div>
              {
                data.published === 0 ?
                <span className="p-tag course-category text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
                :
                <span className="p-tag course-category text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
              }
            </div>
            <span className={`course-badge`}>{data.program_type}</span>
          </div>
          <div className="course-grid-item-content">
            <img src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div className="course-description"><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
        </div>
      </div>
    );
  }

  renderAgreement(data){
    return(
      <div key={data.id} className="col-2 card p-4 m-6" style={{textAlign:"center"}}>
        <div>
          <img style={{borderRadius:"100%", width:"100px"}} alt={data.enterprise_name} src={data.logo}/>
        </div>
        <hr/>
        <span className="p-text-bold" style={{display:"block"}}>{data.enterprise_name}</span>
        <span><FormattedMessage id="ADMIN_GENERAL.WEBSITE"/>: {data.website}</span>
      </div>
    )
  }

  renderHeaderWAssignment() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-6" style={{textAlign: 'left', marginBottom: 0}}>
          <h2><FormattedMessage id="GENERAL_MESSAGE.ASSIGNED_COURSES"/></h2>
        </div>
        <div className="p-col-6" style={{textAlign: 'right'}}>
          <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value })} />
        </div>
      </div>
    );
  }

  renderHeaderWOAssignment() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-6" style={{textAlign: 'left', marginBottom: 0}}>
          <h2><FormattedMessage id="GENERAL_MESSAGE.UNASSIGNED_COURSES"/></h2>
        </div>
        <div className="p-col-6" style={{textAlign: 'right'}}>
          <DataViewLayoutOptions layout={this.state.secondLayout} onChange={(e) => this.setState({ secondLayout: e.value })} />
        </div>
      </div>
    );
  }

  renderFooter(name) {
    return (
      <div>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" autoFocus/>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={() => this.deleteCourse(name)} />
      </div>
    );
  }

  render() {
    const headerWE = this.renderHeaderWAssignment();
    const headerWOE = this.renderHeaderWOAssignment();

    const assignmentCourseFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={() => this.onHide('assignmentCourseDialog')} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveGroupAgencies} />}
        </FormattedMessage>
      </>
    );

    const assignmentCourseSelectedFooter = (
      <>
        <Button label="Cancel" className="p-button-text" onClick={() => this.onHide('assignmentCourseSelectedDialog')} />
      </>
    );

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />  
        <Dialog visible={this.state.assignmentCourseDialog} contentStyle={{maxHeight:"1600px", width:"1300px", height:"600px", overflow:"auto"}} header={this.props.intl.formatMessage({id: 'LEARNING_GENERAL.COURSE_ASSIGMENT'})} modal footer={assignmentCourseFooter} onHide={() => this.onHide('assignmentCourseDialog')}>
          <div className="form-group row ml-1">
            <div>  
              <span className="p-float-label">
                <FormattedMessage id="GENERAL_MESSAGE.SELECT_COURSE_TO_ASSIGN"/>
              </span>
              <div className="overlaypanel-demo">
                <div className="card">
                    <Button type="button" icon="pi pi-search" label={this.state.selectCourse ? this.state.selectCourse.course : this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SELECT_COURSE'})} onClick={(e) => this.op.toggle(e)} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />
                    <OverlayPanel className="course-options" ref={(el) => this.op = el} showCloseIcon id="overlay_panel" style={{width: '600px'}}>
                        <DataTable value={this.state.coursesWithoutAssignment} selectionMode="single" paginator rows={5}
                            selection={this.state.selectCourse} onSelectionChange={this.onCourseSelect}>
                            <Column field="course" header={this.props.intl.formatMessage({id: 'LEARNING_GENERAL.COURSE_NAME'})} sortable />
                            <Column header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.COURSE_IMAGE'})} body={this.imageBody} />
                            <Column field="created" header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CREATED_BY'})} sortable />
                        </DataTable>
                    </OverlayPanel>
                </div>
            </div>
          </div>
          </div>
          <div className="form-group row">
            {/* Agencias */}
            <div className="col-lg-6 multiselect-demo">
              <span className="p-float-label">
                <FormattedMessage id="GENERAL_MESSAGE.SELECT_GROUP_TO_ASSIGN"/>
              </span>
              <Dropdown value={this.state.selectedGroupAgencies} options={this.state.groups_agencies} onChange={this.onGroupAgenciesChange} optionLabel="name" filter showClear filterBy="name" placeholder={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SELECT_COURSE'})}
                valueTemplate={this.selectedGroupAgenciesTemplate} itemTemplate={this.groupAgenciesOptionTemplate} />
            </div>
          </div>
          <div className="p-grid">
            {
              this.state.agreements_show ?
              this.state.agreements_show.map(agreement => this.renderAgreement(agreement))
              :
              <></>
            }
          </div>
        </Dialog>
        <Dialog visible={this.state.assignmentCourseSelectedDialog} contentStyle={{maxHeight:"1600px", width:"1300px", height:"600px", overflow:"auto"}} header={this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.DATA'})} modal footer={assignmentCourseSelectedFooter} onHide={() => this.onHide('assignmentCourseSelectedDialog')}>
          {
            this.state.infoCourseSelect ?
            <>
              <h2 className="mb-8">{this.state.infoCourseSelect.course} <FormattedMessage id="LEARNING_ASSIGNMENT.ASSIGNED_TO"/>: {this.state.infoCourseSelect.group.name}</h2>
              <h3 className="mb-6"><FormattedMessage id="LEARNING_ASSIGNMENT.AGENCIES_BELONG_GROUP"/> {this.state.infoCourseSelect.group.name}:</h3>
              <div className="p-grid">
                {
                  this.state.agreements_show ?
                  this.state.agreements_show.map(agreement => this.renderAgreement(agreement))
                  :
                  <></>
                }
              </div>
            </>
            :
            <></>
          }
        </Dialog>
        <div className="dataview">
          <div className="card mb-8">
            {
              this.state.coursesWithAssignment ?
              <DataView value={this.state.coursesWithAssignment} layout={this.state.layout} header={headerWE}
                itemTemplate={this.itemTemplate} rows={8}
                emptyMessage={this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.NOT_COURSES_ASSIGNED'})}
                paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
              :
              <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
          <div className="card">
            {
              this.state.coursesWithoutAssignment ?
              <DataView value={this.state.coursesWithoutAssignment} layout={this.state.secondLayout} header={headerWOE}
                itemTemplate={this.itemSecondTemplate} rows={8}
                emptyMessage={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.NO_COURSES_AVAILABLE'})}
                paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
              :
              <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
        </div>
        {
          this.state.coursesWithoutAssignment ?
          <div className="p-8 p-text-center">
            <Button className="btn-lg" label={this.props.intl.formatMessage({id: 'LEARNING_ASSIGNMENT.ASSIGN_COURSE'})} onClick={() => this.onClick('assignmentCourseDialog')}/>
          </div>
          :
          <></>
        }
      </div>
    );
  }
}


// const mapStateToProps = state => ({
//   user: state.auth.user
// });

// export default injectIntl(
//   connect(mapStateToProps)(ListAssignmentPrograms)
// )