import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FormattedMessage, injectIntl } from "react-intl";
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";

import axios from 'axios';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const url = ServerURL();

class ListCourses extends Component {

  constructor(props) {
    super(props);
    this.state = {
      layout: 'grid',
      layoutFinished: 'grid',                    
      layoutInProgress: 'grid',
      courses: null,
      courses_show: null,
      courses_finished: null,
      courses_finished_show: null,
      courses_in_progress: null,
      courses_in_progress_show: null,
      courses_recents: null,
      sortKey: {courses_show:null, courses_finished_show:null, courses_in_progress_show:null},
      sortOrder: null,
      sortField: null,
      types: null,
      resetType: "Type",
      selectedType: 9, // Tipo de programa - Interno por default
      dialog: false,
      btnDisable: false,
      title: "",
      displayConfirmation: false,
      changeToTemplate:false,
      idCourseSelect: false,
      searchText: {courses_show:"", courses_finished_show:"", courses_in_progress_show:""},
      optionsProgramType: null,
      programTypes: {courses_show:null, courses_finished_show:null, courses_in_progress_show:null},
      redirect: false
    };

    this.sortOptions = [
      {label: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.COURSES_PUBLISHED'}), value: '!published'},
      {label: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.DRAFTS'}), value: 'published'},
    ];

    this.optionsType = [
      {label: this.props.intl.formatMessage({id: 'MENU.DASHBOARD.SCHOOLS'}), value: 'school'},
      {label: this.props.intl.formatMessage({id: 'AUTH.INPUT.SELECT.OPTION_AGENCY'}), value: 'agency'},
      {label: this.props.intl.formatMessage({id: 'GENERAL.ALL'}), value: 'all'},
    ];

    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onHideC = this.onHideC.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.changeType = this.changeType.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
    this.changePage = this.changePage.bind(this);

    this.id_agente = 64;
  }

  componentDidMount() {
    this.getCourses()
    this.getProgramsTypes()
  }

  onClick(name, idCourseSelect) {
    let state = {
      [`${name}`]: true,
      idCourseSelect
    };

    this.setState(state);
  }

  onHide(){
    this.setState({
      dialog: false,
      title: ""
    })
  }

  onHideC(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  onOpen(){
    this.setState({
      dialog: true
    })
  }

  textResponsive(text){
    if(text.length >=39){
      return(<div style={{fontSize:"1rem", fontWeight: 700}}>{text}</div>)
    }else{
      return(<div style={{fontSize:"auto", fontWeight: 700}}>{text}</div>)
    }
  }

  onChangeSearch(searchText, courseTable){
    let courses = this.state[`${courseTable}`];
    courseTable = courseTable + "_show"
    const newCoursesShow = []

    const sortKey = this.state.sortKey[`${courseTable}`]
    const programType = this.state.programTypes[`${courseTable}`];

    courses.forEach(course => {
      let isValidEnterprise = false;
      let isValidProgramType = false;
      const foundNameCourse = course.course.indexOf(searchText);
      const foundNameEnterprise = course.enterprise_name.indexOf(searchText);
      const foundNameCreated = course.created.indexOf(searchText);
      if(foundNameCourse !== -1 || foundNameEnterprise !== -1 || foundNameCreated !== -1){
        if((!sortKey || sortKey === "all") && (!programType || programType === "all")){
          newCoursesShow.push(course);
        }else{
          switch (sortKey) {
            case "school":
              if(course.published === 1){
                isValidEnterprise = true;
              }
              break;
            case "agency":
              if(course.published !== 1){
                isValidEnterprise = true;
              }
              break;
            default:
              isValidEnterprise = true;
              break;
          }
          if(course.program_type === programType || programType === "all"){
            isValidProgramType = true;
          }
          if(isValidEnterprise && isValidProgramType){
            newCoursesShow.push(course);
          }
        }
      }
    })

    this.setState({ [`${courseTable}`]: newCoursesShow, searchText: {...this.state.searchText, [`${courseTable}`]: searchText} })
  }

  onTypeChange(event) {
    this.setState({selectedType: event.target.value})
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value
      });
    }
    else {
      this.setState({
        sortOrder: 1,
        sortField: value,
        sortKey: value
      });
    }
  }

  changeType(event, courseTable, type="typeProgram") {
    const value = event.value;

    const courses = this.state[`${courseTable}`];
    courseTable = courseTable + "_show"

    const newCoursesShow = []

    
    courses.forEach(course => {
      let publishedValid = false;
      let programTypeValid = false;
      const searchText = this.state.searchText[`${courseTable}`]
      
      const foundNameCourse = course.course.indexOf(searchText);
      const foundNameEnterprise = course.enterprise_name.indexOf(searchText);
      const foundNameCreated = course.created.indexOf(searchText);
      if(foundNameCourse !== -1 || foundNameEnterprise !== -1 || foundNameCreated !== -1){
        if(type === "enterprise"){
          const programType = this.state.programTypes[`${courseTable}`];
          switch (value) {
            case "school":
              if(course.published === 1){
                publishedValid = true
              }
              break;
            case "agency":
              if(course.published !== 1){
                publishedValid = true
              }
              break;
            default:
              publishedValid = true
              break;
          }
          if(course.program_type === programType || !programType || programType==="all"){
            programTypeValid = true;
          }
        }else{
          const sortKey = this.state.sortKey[`${courseTable}`]
          if(course.program_type === value || value==="all"){
            programTypeValid = true;
          }
          switch (sortKey) {
            case "school":
              if(course.published === 1){
                publishedValid = true
              }
              break;
            case "agency":
              if(course.published !== 1){
                publishedValid = true
              }
              break;
            default:
              publishedValid = true
              break;
          }
        }
      }

      if(publishedValid && programTypeValid){
        newCoursesShow.push(course);
      }
      
    })

    if(type === "enterprise"){
      this.setState({ [`${courseTable}`]: newCoursesShow, sortKey: { ...this.state.sortKey, [`${courseTable}`]: value } })
    }else{
      this.setState({ [`${courseTable}`]: newCoursesShow, programTypes: { ...this.state.programTypes, [`${courseTable}`]: value } })
    }

  }

  Date(e){                 
    e.map((course) => {
    console.log("E:",course.updated_at)
    const date = new Date(course.updated_at);

     let year = date.getFullYear();
     let month = date.getMonth()+1;
     let dt = date.getDate();

     if (dt < 10) {
       dt = '0' + dt;
     }
     if (month < 10) {
       month = '0' + month;
     }

     let new_date = dt +'/' + month + '/'+year
    //  console.log("New Data:",new_date)

     course.updated_at = new_date
     return course

   });
  }

  changePage(dataCourse) {
    console.log(this.props)
    console.log(dataCourse)
    this.props.updatedCourse(dataCourse)
    this.setState({redirect: true})
  }

  getCourses = async () =>{
    try {
      const data = {
        fk_id_enterprise: this.props.user.fk_id_enterprise,
        id_user: this.props.user.id
      }
      await axios.post(`${url}/api/auth/courses_school`, data)
      .then(res => {
        const coursesAux = res.data;
        this.Date(coursesAux);

        const courses = []
        let courses_recents = []
        const courses_finished = []
        const courses_in_progress = []
        coursesAux.forEach(course => {
          if(course.fk_id_user === this.props.user.id){
            course = {...course, isMyCourse: true}
          }
          if(course.published !== 0){
            const progress = course.numEvaluationsComplete/course.numEvaluations
            course = {...course, progress}
            if(course.numEvaluations === course.numEvaluationsComplete){
              course = { ...course, isComplete: true, progress: null};
              courses_finished.push(course);
            }else if(course.numEvaluationsComplete === 0){
              courses.push(course);
            }else{
              courses_in_progress.push(course);
            }
          }else{
            courses.push(course);
          }
        })
        console.log(coursesAux)
        coursesAux.forEach((course) => {
          if(course.fk_id_user === this.props.user.id){
            course = {...course, isMyCourse: true}
          }
          if(course.published !== 0){
            const progress = course.numEvaluationsComplete/course.numEvaluations
            course = {...course, progress}
          }
          if(course.isRecent === true){
            if(course.numEvaluations === course.numEvaluationsComplete && course.numEvaluationsComplete){
              course = { ...course, isComplete: true};
            }
            if(!courses_recents.length){
              courses_recents.push(course);
            }else{
              const newCourseRecents = courses_recents;
              courses_recents.every(function(courseR, index) {
                if(course.positionRecent < courseR.positionRecent){
                  newCourseRecents.splice(index, 0, course);
                  return false
                }else if(index+1 === courses_recents.length){
                  newCourseRecents.push(course);
                  return false
                }
                return true
              })
            }
          }
        })
        courses_recents = courses_recents.reverse();
        this.setState({ courses, courses_show: courses, courses_recents, courses_finished, courses_finished_show: courses_finished, courses_in_progress, courses_in_progress_show: courses_in_progress })
        console.log(courses)
        return({ courses_finished, courses_in_progress })
      });
    }catch(error){}
  }

  deleteCourse = async () =>{
    console.log(this.state.idCourseSelect)
    try {
      await axios.delete(`${url}/api/auth/course/${this.state.idCourseSelect}`)
      .then(res => {     
        this.setState({ displayConfirmation: false })
        this.getCourses()
      });
      }catch(error){
    }
  }
  templateCourse = async () =>{
    try {
      await axios.put(`${url}/api/auth/templateCourse/${this.state.idCourseSelect}`)
      .then(res => {     
        this.setState({ changeToTemplate: false })
        this.getCourses()
      });
      }catch(error){
    }                                                             
  } 


  getProgramsTypes = async () =>{
    try {
      await axios.get(`${url}/api/auth/program_types`)
      .then(res => {
        const types = res.data
        const optionsProgramType = []

        types.forEach(ProgramType => {
          const program_type = ProgramType.program_type;
          optionsProgramType.push({label: program_type, value: program_type});
        })
        optionsProgramType.push({label: this.props.intl.formatMessage({id: 'GENERAL.ALL'}), value: "all"});
        this.setState({ types, optionsProgramType })
      });
      }catch(error){
    }
  }

  async saveCourse(){
    let data = {
      fk_id_enterprise: this.props.user.fk_id_enterprise,
      created: this.props.user.fullname,
      modified: this.props.user.fullname,
      fk_id_program_type: this.state.selectedType,
      course: this.state.title
    }
    if(this.state.title){
      console.log(data)
      try {
        await axios.post(`${url}/api/auth/course`, data)
        .then(res => {
          const { message, message_type } = res.data;
          let type = null;

          if(message_type === 'success'){
            type = "GENERAL.SUCCESSFUL"
            this.setState({ dialog: false, title: "" })
            this.getCourses()
          }else{
            type = "GENERAL_MESSAGE.ERROR"
          }

          this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 3000 });

        });
        }catch(error){
      }
    }else{
      this.toast.show({ severity: 'info', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.ERROR'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LIST.MISSING_DATA'}), life: 3000 });
    }
  }

  renderButtonTemplate(data){
    console.log("RESULTEV:",data.resultEvaluation)
    if(data.resultEvaluation===0){  
      return(
        <Button icon="pi pi-undo" className="p-button-success" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.onClick('changeToTemplate', data.id)}/>
      )
    }
  }  

  renderAgencyListItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} />
          <div className="course-list-detail">
            <h4>{data.enterprise_name}</h4>
            {this.textResponsive(data.course)}
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
          <div className="course-list-action">
            <span className="course-price">{data.program_type}</span>
            <div>
              {
                data.published === 0 || data.isMyCourse ?
                <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
                :
                <div style={{alignSelf: "flex-end", textAlign: "end"}}>
                  <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
                </div>
              }
              {
                data.published === 0 || data.isMyCourse ?
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onClick('displayConfirmation', data.id)} />
                :
                <></>
              } 
               {
                data.published === 2 && data.isMyCourse ?
                this.renderButtonTemplate(data)
                :
                <></>
              } 
            </div>
            {
              data.published === 0 ?
              <span className="p-tag course-badge text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
              :
              data.isMyCourse ?
              <span className="p-tag course-badge text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
              :
              <span><FormattedMessage id="GENERAL_MESSAGE.POSTED"/> {data.updated_at}</span>
            }
          </div>
        </div>
        <div className="p-2">
          {
            data.progress > 0 &&
            <ProgressBar color="mediumseagreen" value={data.progress*100} />
          }
          {
            data.progress === 0 &&
            <span className="text-uppercase"><FormattedMessage id="GENERAL_MESSAGE.NOT_STARTED"/></span>
          }
        </div>
      </div>
    );
  }
  
  renderSchoolListItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} />
          <div className="course-list-detail">
            <h4>{data.enterprise_name}</h4>
            {this.textResponsive(data.course)}
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
          <div className="course-list-action">
            <span className="course-price">{data.program_type}</span>
            <div style={{alignSelf: "flex-end"}}>
              <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
            </div>
            <span><FormattedMessage id="GENERAL_MESSAGE.POSTED"/> {data.updated_at}</span>
          </div>
        </div>
        <div className="p-2">
          {
            data.progress > 0 &&
            <ProgressBar color="mediumseagreen" value={data.progress*100} />
          }
          {
            data.progress === 0 &&
            <span className="text-uppercase"><FormattedMessage id="GENERAL_MESSAGE.NOT_STARTED"/></span>
          }
        </div>
      </div>
    );
  }

  renderAgencyGridItem(data) {

    const img = {
      width: '32px',
      height: '32px',
      textAlign: 'center',
      objetcFit: 'cover'
    }

    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card" style={{borderColor:"darkgoldenrod"}}>
          <div className="mb-3">
            <Avatar alt={`${data.enterprise_name} Logo`} src={data.logo} imgProps={{style:{ height:"40px", margin: 0 }}} />
          </div>
          <div className="course-grid-item-top">
            <h6>{data.enterprise_name}</h6>
            {
              data.isComplete && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
              )
            }
          </div>
          <div className="course-grid-item-top">
            <span>{data.program_type}</span>
            <div>
              {
                data.published === 0 ?
                <span className="p-tag course-category text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
                :
                data.isMyCourse ?
                <span className="p-tag course-category text-uppercase" style={{background:"darkgoldenrod"}}><FormattedMessage id="LEARNING_GENERAL.PUBLISHED"/></span>
                :
                <span><FormattedMessage id="GENERAL_MESSAGE.POSTED"/> {data.updated_at}</span>
              }
            </div>
          </div>
          <div className="course-grid-item-content">
            <img style={{width: "100%"}} src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            {/* <div className="course-name">{data.course}</div> */}
            {this.textResponsive(data.course)}
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
          <div className="course-grid-item-bottom">
            <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
            {
              data.published === 0 || data.isMyCourse ?
              <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.onClick('displayConfirmation', data.id)} />
              :
              <></>
            }
            {
                data.published === 2 && data.isMyCourse ?
                this.renderButtonTemplate(data)
                :
                <></>
              } 
          </div>
          {
            data.progress > 0 &&
            <ProgressBar color="mediumseagreen" value={data.progress*100} />
          }
          {
            data.progress === 0 &&
            <span className="text-uppercase"><FormattedMessage id="GENERAL_MESSAGE.NOT_STARTED"/></span>
          }
        </div>
      </div>
    );
  }

  renderSchoolGridItem(data) {
    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card" style={{borderColor:"cadetblue"}}>
          <div className="mb-3">
            <Avatar alt={`${data.enterprise_name} Logo`} src={data.logo} imgProps={{style:{ height:"40px", margin: 0 }}}>{data.enterprise_name[0]}</Avatar>
          </div>
          <div className="course-grid-item-top">
            <h6>{data.enterprise_name}</h6>
            {
              data.isComplete && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                
              )
            }
          </div>
          <div className="course-grid-item-top">
            <span>{data.program_type}</span>
            <span><FormattedMessage id="GENERAL_MESSAGE.POSTED"/> {data.updated_at}</span>
          </div>
          <div className="course-grid-item-content">
            <img style={{width: "100%"}} src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            {/* <div className="course-name">{data.course}</div> */}
            {this.textResponsive(data.course)}
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
          </div>
          <div className="course-grid-item-bottom">
            <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
          </div>
          {
            data.progress > 0 &&
            <ProgressBar color="mediumseagreen" value={data.progress*100} />
          }
          {
            data.progress === 0 &&
            <span className="text-uppercase"><FormattedMessage id="GENERAL_MESSAGE.NOT_STARTED"/></span>
          }
        </div>
      </div>
    );
  }

  itemTemplate(course, layout) {
    if (!course) {
      return (<></>);
    }

    if(course.published === 1){
      if (layout === 'list')
        return this.renderSchoolListItem(course);
      else if (layout === 'grid')
        return this.renderSchoolGridItem(course);
    }else{
      if (layout === 'list')
        return this.renderAgencyListItem(course);
      else if (layout === 'grid')
        return this.renderAgencyGridItem(course);
    }
  }

  renderHeaderCourses() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-12 mb-1" style={{display:"flex", justifyContent:"space-between"}}>
          <h3><FormattedMessage id="LEARNING_COURSE_LIST.AVAILABLE_COURSES"/></h3>
          <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value })} />
        </div>
        <div className="p-col-12" style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{marginTop:"auto", marginBottom:"auto"}}>
            <FormattedMessage id="GENERAL_MESSAGE.SELECT_TYPE_ENTERPRISE">
            {(message) => <Dropdown style={{minWidth:"min-content"}} className="mr-2" options={this.optionsType} value={this.state.sortKey["courses_show"]} placeholder={message} onChange={e => this.changeType(e, "courses", "enterprise")}/>}
            </FormattedMessage>
            <FormattedMessage id="GENERAL_MESSAGE.SELECT_TYPE_PROGRAM">
            {(message) => <Dropdown style={{minWidth:"min-content"}} options={this.state.optionsProgramType} value={this.state.programTypes["courses_show"]} placeholder={message} onChange={e => this.changeType(e, "courses")}/>}
            </FormattedMessage>
            {/* <Dropdown style={{minWidth:"min-content"}} className="mr-2" options={this.optionsType} value={this.state.sortKey["courses_show"]} placeholder="Seleccionar tipo de empresa" onChange={e => this.changeType(e, "courses", "enterprise")}/> */}
            {/* <Dropdown style={{minWidth:"min-content"}} options={this.state.optionsProgramType} value={this.state.programTypes["courses_show"]} placeholder="Seleccionar tipo de programa" onChange={e => this.changeType(e, "courses")}/> */}
          </div>
          <div style={{display:"grid"}}>
            <span style={{color:"darkgray"}}><FormattedMessage id="GENERAL_MESSAGE.SEARCH_BY_NAME_CREATOR_COMPANY"/></span>
            <span style={{justifySelf:"center"}} className="p-input-icon-left">
                <i className="pi pi-search" />
                <FormattedMessage id="GENERAL.SEARCH">
                  {
                    message => <InputText onChange={e => this.onChangeSearch(e.target.value, "courses")} placeholder={message} />
                  }
                </FormattedMessage>
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderHeaderRecentCourses() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-12" style={{display:"flex", justifyContent:"space-between"}}>
          <h3><FormattedMessage id="LEARNING_COURSE_LIST.RECENT_COURSES"/></h3>
          <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value })} />
        </div>
      </div>
    );
  }

  renderHeaderInProgress() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-12 mb-1" style={{display:"flex", justifyContent:"space-between"}}>
          <h3><FormattedMessage id="LEARNING_COURSE_LIST.COURSES_IN_PROGRESS"/></h3>
          <DataViewLayoutOptions layout={this.state.layoutInProgress} onChange={(e) => this.setState({ layoutInProgress: e.value })} />
        </div>
        <div className="p-col-12" style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{marginTop:"auto", marginBottom:"auto"}}>
            {/* <Dropdown style={{minWidth:"min-content"}} className="mr-2" options={this.optionsType} value={this.state.sortKey["courses_in_progress_show"]} placeholder="Seleccionar tipo de empresa" onChange={e => this.changeType(e, "courses_in_progress", "enterprise")}/>
            <Dropdown style={{minWidth:"min-content"}} options={this.state.optionsProgramType} value={this.state.programTypes["courses_in_progress_show"]} placeholder="Seleccionar tipo de programa" onChange={e => this.changeType(e, "courses_in_progress")}/> */}
            <FormattedMessage id="GENERAL_MESSAGE.SELECT_TYPE_ENTERPRISE">
            {(message) => <Dropdown style={{minWidth:"min-content"}} className="mr-2" options={this.optionsType} value={this.state.sortKey["courses_in_progress_show"]} placeholder={message} onChange={e => this.changeType(e, "courses_in_progress", "enterprise")}/>}
            </FormattedMessage>
            <FormattedMessage id="GENERAL_MESSAGE.SELECT_TYPE_PROGRAM">
            {(message) => <Dropdown style={{minWidth:"min-content"}} options={this.state.optionsProgramType} value={this.state.programTypes["courses_in_progress_show"]} placeholder={message} onChange={e => this.changeType(e, "courses_in_progress")}/>}
            </FormattedMessage>
          </div>
          <div style={{display:"grid"}}>
            <span style={{color:"darkgray"}}><FormattedMessage id="GENERAL_MESSAGE.SEARCH_BY_NAME_CREATOR_COMPANY"/></span>
            <span style={{justifySelf:"center"}} className="p-input-icon-left">
                <i className="pi pi-search" />
                <FormattedMessage id="GENERAL.SEARCH">
                  {
                    message => <InputText onChange={e => this.onChangeSearch(e.target.value, "courses_in_progress")} placeholder={message} />
                  }
                </FormattedMessage>
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderHeaderFinished() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-12 mb-1" style={{display:"flex", justifyContent:"space-between"}}>
          <h3><FormattedMessage id="LEARNING_COURSE_LIST.COMPLETED_COURSES"/></h3>
          <DataViewLayoutOptions layout={this.state.layoutFinished} onChange={(e) => this.setState({ layoutFinished: e.value })} />
        </div>
        <div className="p-col-12" style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{marginTop:"auto", marginBottom:"auto"}}>
            {/* <Dropdown style={{minWidth:"min-content"}} className="mr-2" options={this.optionsType} value={this.state.sortKey["courses_finished_show"]} placeholder="Seleccionar tipo de empresa" onChange={e => this.changeType(e, "courses_finished_show", "enterprise")}/>
            <Dropdown style={{minWidth:"min-content"}} options={this.state.optionsProgramType} value={this.state.programTypes["courses_finished_show"]} placeholder="Seleccionar tipo de programa" onChange={e => this.changeType(e, "courses_finished_show")}/> */}
            <FormattedMessage id="GENERAL_MESSAGE.SELECT_TYPE_ENTERPRISE">
            {(message) => <Dropdown style={{minWidth:"min-content"}} className="mr-2" options={this.optionsType} value={this.state.sortKey["courses_finished_show"]} placeholder={message} onChange={e => this.changeType(e, "courses_finished_show", "enterprise")}/>}
            </FormattedMessage>
            <FormattedMessage id="GENERAL_MESSAGE.SELECT_TYPE_PROGRAM">
            {(message) => <Dropdown style={{minWidth:"min-content"}} options={this.state.optionsProgramType} value={this.state.programTypes["courses_finished_show"]} placeholder={message} onChange={e => this.changeType(e, "courses_finished_show")}/>}
            </FormattedMessage>
          </div>
          <div style={{display:"grid"}}>
            <span style={{color:"darkgray"}}><FormattedMessage id="GENERAL_MESSAGE.SEARCH_BY_NAME_CREATOR_COMPANY"/></span>
            <span style={{justifySelf:"center"}} className="p-input-icon-left">
                <i className="pi pi-search" />
                <FormattedMessage id="GENERAL.SEARCH">
                  {
                    message => <InputText onChange={e => this.onChangeSearch(e.target.value, "courses_finished")} placeholder={message} />
                  }
                </FormattedMessage>
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderFooter(name) {
    return (
      <div>
        <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
        {(message) => <Button label={message} icon="pi pi-check" onClick={() => this.deleteCourse(name)} />}
        </FormattedMessage>
        {/* <Button label="No" icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>
        <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteCourse(name)} /> */}
      </div>
    );
  }

  renderFooterTemplate(name) {
    return (
      <div>
        <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
        {(message) => <Button label={message} icon="pi pi-check" onClick={() => this.templateCourse(name)} />}
        </FormattedMessage>
        {/* <Button label="No" icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>
        <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteCourse(name)} /> */}
      </div>
    );
  }

  render() {

    if(this.state.redirect) {
      return <Redirect to={{
        pathname: "/get-courses/course",
      }}/>;
    }

    const headerCourses = this.renderHeaderCourses();
    const headerRecentCourses = this.renderHeaderRecentCourses();
    const headerFinished = this.renderHeaderFinished();
    const headerInProgress = this.renderHeaderInProgress();

    const footer = (
      <div>
          <Button disabled={this.state.btnDisable} label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={this.saveCourse} />
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={this.onHide} />
      </div>
    );

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <FormattedMessage id="GENERAL_MESSAGE.COMPLETE_INFORMATION">
          {
            (message) => (
              <Dialog header={message} footer={footer} visible={this.state.dialog} onHide={this.onHide} style={{width:"30%"}}>
                <div className="p-grid" style={{marginTop: "1em"}}>
                  <div className="p-col">
                    <span className="p-float-label">
                      <InputText id="title" value={this.state.title} maxlength="39" onChange={(e) => this.setState({title: e.target.value})} style={{width:"100%"}}/>
                      <label htmlhtmlfor="title"><FormattedMessage id="GENERAL_MESSAGE.COURSE_TITLE"/></label>
                    </span>
                  </div>
                  {/* <div className="p-col">
                    <span className="p-float-label">   
                      <Dropdown
                        style={{ width: 190, height:33 }}
                        id="type"
                        variant="outlined"
                        placeholder= { this.state.resetType}
                        margin="dense"
                        value={this.state.selectedType || "Type"}   
                        options ={this.state.types} 
                        onChange={this.onTypeChange} 
                        optionLabel="program_type" 
                        optionValue="id"
                        
                        />
                          <label htmlFor="type">Tipo de curso</label>
                    </span>
                  </div> */}
                </div>
              </Dialog>
            )
          }
        </FormattedMessage>
        <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
          {
            (message) => (
              <Dialog header={message} visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter('displayConfirmation')} onHide={() => this.onHideC('displayConfirmation')}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_DELETE_COURSE"/></span>
                </div>
              </Dialog>
            )
          }
        </FormattedMessage>
        <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
          {
            (message) => (
              <Dialog header={message} visible={this.state.changeToTemplate} modal style={{ width: '350px' }} footer={this.renderFooterTemplate('changeToTemplate')} onHide={() => this.onHideC('changeToTemplate')}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_TEMPLATE_COURSE"/></span>
                </div>
              </Dialog>
            )
          }
        </FormattedMessage> 

        <div className="dataview mb-5">
          <div className="card">
            {
              this.state.courses_recents && (
                this.state.courses_recents.length ?
                <DataView value={this.state.courses_recents} layout={this.state.layout} header={headerRecentCourses}
                  itemTemplate={this.itemTemplate} rows={8}/>
                :
                <></>
              )
            }
          </div>
        </div>
        <div className="dataview mb-5">
          {
            (this.state.courses && (this.props.user.fk_id_role !== this.id_agente || this.props.user.owner)) ?
            <button className="m-3 btn btn-primary" onClick={this.onOpen}><AddIcon/> <FormattedMessage id="GENERAL_MESSAGE.ADD_COURSE"/></button>
            :
            <></>
          }
          <div className="card">
            {
              this.state.courses ?
              <FormattedMessage id="GENERAL_MESSAGE.NO_COURSES_AVAILABLE">
                {
                  (message) => (
                    <DataView value={this.state.courses_show} layout={this.state.layout} header={headerCourses}
                      itemTemplate={this.itemTemplate} rows={12}
                      emptyMessage={message}
                      paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
                        )
                }
              </FormattedMessage>
              :
              <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
        </div>
        <div className="dataview mb-5">
          <div className="card">
            {
              this.state.courses_in_progress ?
                this.state.courses_in_progress.length ?
                  <DataView value={this.state.courses_in_progress_show} layout={this.state.layoutInProgress} header={headerInProgress}
                    itemTemplate={this.itemTemplate} rows={12}
                    emptyMessage="No courses in progress"
                    paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
                :
                  <></>
              :
                <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
        </div>
        <div className="dataview">
          <div className="card">
            {
              this.state.courses_finished ?
                this.state.courses_finished.length ?
                  <DataView value={this.state.courses_finished_show} layout={this.state.layoutFinished} header={headerFinished}
                    itemTemplate={this.itemTemplate} rows={12}
                    emptyMessage="No courses finished"
                    paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
                :
                  <></>
              :
                <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(ListCourses)
)