import React from "react";
import {useSubheader} from "../../_metronic/_core";

import TransfersTable from "../modulesAccounting/Transfers/TransfersTable"

export const TransfersPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Transfers");

  return (<><TransfersTable /></>);
};