import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  GetInvoiceId: "[InvoiceId] Action",
  GetAuthToken_ADMIN: "[AuthToken_ADMIN] Action",
  GetTransferId: "[TransferId] Action",



  Logout: "[Logout] Action",
  Register: "[Register] Action",

  UserRequested: "[Request User] Action",
  //InvoiceRequested: "[Request Invoice] Action",

  UserLoaded: "[Load User] Auth API",
  UserUpdated: "[Update User] Action",
  AuthTokenUpdated: "[Update AuthToken] Action",
  CourseUpdated: "[Update Course] Action",
  EvaluationUpdated: "[Update Evaluation] Action",
  LessonUpdated: "[Update Lesson] Action",
  StudentUpdated: "[Updated Student] Action",
  CustomerSuccessUpdated: "[Updated Customer] Action",
  GroupUpdated: "[GroupUpdated] Action",
  EventUpdated: "[EventUpdated] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  authToken_ADMIN:undefined,
  dataCourse: undefined,
  dataProgram: undefined,
  dataEvaluation: undefined,
  dataLesson: undefined,
  dataStudent: undefined,
  dataCustomerSuccess: undefined,
  dataGroup: undefined,
  dataEvent: undefined,
  invoiceId: undefined,
  transferId: undefined
};







export const reducer = persistReducer(
  { storage, key: "wissify", whitelist: ["user", "authToken", "authToken_ADMIN", "dataCourse", "dataStudent", "dataCustomerSuccess", "dataGroup", "dataProgram", "dataEvaluation", "dataLesson", "dataEvent", "invoiceId", "transferId"] },
  (state = initialAuthState, action) => {
    switch (action.type) {

      case actionTypes.Login: {
        console.log("Entre")
        const { authToken } = action.payload;
        console.log("Token: ", authToken);
        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        let { user } = action.payload;
        user = user[0] 
        console.log("REDUX USER:", user)

        return { ...state, user };
      }

      case actionTypes.UserUpdated: {
        let { user } = action.payload;
        //console.log(user)
        return { ...state, user };
      }

      case actionTypes.AuthTokenUpdated: {
        let { authToken } = action.payload;
        return { ...state, authToken };
      }

      case actionTypes.CourseUpdated: {
        let { dataCourse } = action.payload;
        return { ...state, dataCourse };
      }

      case actionTypes.StudentUpdated: {
        let { dataStudent } = action.payload;
        return { ...state, dataStudent }
      }

      case actionTypes.CustomerSuccessUpdated: {
        let { dataCustomerSuccess } = action.payload;
        return { ...state, dataCustomerSuccess }
      }

      case actionTypes.GroupUpdated: {
        let { dataGroup } = action.payload;
        return { ...state, dataGroup }
      }

      case actionTypes.ProgramUpdated: {
        let { dataProgram } = action.payload;
        return {...state, dataProgram}
      }

      case actionTypes.EvaluationUpdated: {
        let { dataEvaluation } = action.payload;
        return { ...state, dataEvaluation };
      }

      case actionTypes.LessonUpdated: {
        let { dataLesson } = action.payload;
        return { ...state, dataLesson };
      }  
      
      case actionTypes.EventUpdated: {
        let { dataEvent } = action.payload;
        return { ...state, dataEvent }
      }
      
      case actionTypes.GetAuthToken_ADMIN: {
        const { authToken_ADMIN } = action.payload;

        return { ...state, authToken_ADMIN };
      } 
      

      case actionTypes.GetInvoiceId: {
        const { invoiceId } = action.payload;
        console.log("Encontré Invoice Id:",invoiceId)

        return { ...state, invoiceId };
      }     
      
      case actionTypes.GetTransferId: {
        const { transferId } = action.payload;
        console.log("Encontré Transfer Id:",transferId)

        return { ...state, transferId };
      }     


      default:
        return state;
    }
  }
);




export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  getInvoiceId: invoiceId => ({ type: actionTypes.GetInvoiceId, payload: { invoiceId } }),
  getAuthToken_ADMIN: authToken_ADMIN => ({ type: actionTypes.GetAuthToken_ADMIN, payload: { authToken_ADMIN } }),
  getTransferId: transferId => ({ type: actionTypes.GetTransferId, payload: { transferId } }),



  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),

  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),


  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  updatedUser: user => ({ type: actionTypes.UserUpdated, payload: { user }}),
  updatedAuthToken: authToken => ({ type: actionTypes.AuthTokenUpdated, payload: { authToken }}),
  updatedCourse: dataCourse => ({ type: actionTypes.CourseUpdated, payload: { dataCourse }}),
  updatedStudent: dataStudent => ({ type: actionTypes.StudentUpdated, payload: { dataStudent }}),
  updatedCustomerSuccess: dataCustomerSuccess => ({ type: actionTypes.CustomerSuccessUpdated, payload: { dataCustomerSuccess}}),
  updatedGroup: dataGroup => ({ type: actionTypes.GroupUpdated, payload: { dataGroup}}),
  updatedProgram: dataProgram => ({ type: actionTypes.ProgramUpdated, payload: { dataProgram }}),
  updatedEvaluation: dataEvaluation => ({ type: actionTypes.EvaluationUpdated, payload: { dataEvaluation }}),
  updatedLesson: dataLesson => ({ type: actionTypes.LessonUpdated, payload: { dataLesson }}),
  updatedEvent: dataEvent => ({ type: actionTypes.EventUpdated, payload: { dataEvent }})

};


export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user.data));
  });
}
