import React, {useMemo} from "react";
import {useHtmlClassService} from "../../../_core";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  let DEVELOP_MODE = '';
  if (process.env.NODE_ENV === 'development'){
    DEVELOP_MODE = (
      <div>
        <p><small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small></p>
      </div>
    );      
  } else {
    DEVELOP_MODE = '';
  }
  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{" "}
          <a
            href="http://wissify.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary"
          >
            Wissify 0.0.9
          </a>
          {DEVELOP_MODE}
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <a
            href="http://wissify.com"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pr-3 pl-0"
          >
            About
          </a>
          <a
            href="http://wissify.com"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            Teams
          </a>
          <a
            href="http://wissify.com"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pl-3 pr-0"
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
}
