import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios'
import { Chart } from 'primereact/chart';
import { connect } from 'react-redux';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import { Link }  from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import ServerURL from '../../../ServerURL'
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";
import { Dropdown } from 'primereact/dropdown';
import { FormattedMessage, injectIntl } from 'react-intl';


const url = ServerURL();

class Course extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lessons: null,
      id_course: this.props.dataEvaluation.id,
      users_original: null,
      users_show: null,
      agreements_original: null,
      agreements_show: null,
      selectedOption: null,
      selectedOptionAgency: null,
      agencies_options: null,
      quantity: null,
      completeQuantity: 0,
      inProcessQuantity: 0,
      incompleteQuantity: 0
    };

    this.onChangeTypeView = this.onChangeTypeView.bind(this);
    this.onChangeAgency = this.onChangeAgency.bind(this);
    this.calculateUsers = this.calculateUsers.bind(this);

    this.id_gerente = 62;  
    this.id_coordinador = 63;  
    this.id_agente = 64;  

    this.lightOptions = {
      legend: {
        labels: {
          fontColor: '#495057'
        }
      }
    };

    this.options = [
      {name: this.props.intl.formatMessage({id: 'MENU.DASHBOARD.AGENCIES'}), id: 0},
      {name: this.props.intl.formatMessage({id: 'GENERAL_ROLES.AGENTS'}), id: this.id_agente}
    ];

  }

  getData(){
    return{
      labels: [this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.THEY_FINISHED_COURSE'}), this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.THEY_ARE_IN_PROGRESS'}), this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.THEY_NOT_TAKEN_COURSE'})],
      datasets: [
        {
          data: [this.state.completeQuantity, this.state.inProcessQuantity, this.state.incompleteQuantity],
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FFA726"
          ],
          hoverBackgroundColor: [
            "#64B5F6",
            "#81C784",
            "#FFB74D"
          ]
        }
      ]
    }
  }

  componentDidMount() {
    this.getResults();
  }

  onChangeAgency(e) {
    let agreements_show = this.state.agreements_original
    let users_show = this.state.users_original
    if(this.state.agreements_show.length){
      users_show = []
    }else{
      agreements_show = []
    }
    if(e.value){
      this.state.agreements_original.forEach(agreement => {
        if(e.value.name === agreement.enterprise_name){
          if(this.state.agreements_show.length){
            agreements_show = [agreement];
          }else{
            const newUsers = [];
            agreement.users.forEach(user => {
              newUsers.push(user);
            })
            users_show = newUsers
          }
        }
      })
    }
    this.setState({
      selectedOptionAgency: e.value,
      agreements_show,
      users_show
    })
  }

  onChangeTypeView(e) {
    let newArrayAgreementShow = []
    let newArrayUsers = []
    if(e.value){
      switch(e.value.id){
        case 0:
          newArrayAgreementShow = this.state.agreements_original;
          break;
        case this.id_agente:
          newArrayUsers = this.state.users_original;
          break;
        default:
          newArrayAgreementShow = this.state.agreements_original;
      }
    }else{
      newArrayAgreementShow = this.state.agreements_original;
    }
    this.setState({ selectedOption: e.value, agreements_show: newArrayAgreementShow, users_show: newArrayUsers });
  }

  calculateUsers = (evaluations, agencies) => {
    let completeQuantity = 0
    let inProcessQuantity = 0
    let incompleteQuantity = 0

    const numberEvaluations = this.props.dataEvaluation.listEvaluations.length

    const newArrayAgencies = []
    const allUsers = []
    agencies.forEach(agency => {
      const newArrayUsers = []
      let usersComplete = 0
      let usersIncomplete = 0
      let usersInProgress = 0
      agency.users.forEach(user => {
        let numberEvaluationsComplete = 0
        let progress = 0
        evaluations.forEach(evaluation => {
          if(evaluation.fk_id_user === user.id){
            numberEvaluationsComplete++;
            progress = numberEvaluationsComplete/numberEvaluations
          }
        })
        switch(numberEvaluationsComplete){
          case 0:
            incompleteQuantity++;
            usersIncomplete++;
            break;
          case numberEvaluations:
            completeQuantity++;
            usersComplete++;
            break;
          default:
            inProcessQuantity++;
            usersInProgress++;
        }
        user = {...user, progress}
        newArrayUsers.push(user)
        allUsers.push(user)
      })
      agency = {...agency, users: newArrayUsers, usersComplete, usersIncomplete, usersInProgress}
      newArrayAgencies.push(agency)
      usersComplete = 0
      usersIncomplete = 0
      usersInProgress = 0
    })
    const quantity = incompleteQuantity + completeQuantity + inProcessQuantity
    this.setState({
      quantity,
      incompleteQuantity,
      completeQuantity,
      inProcessQuantity,
      users_original: allUsers,
      agreements_original: newArrayAgencies,
      agreements_show: newArrayAgencies
    })
  }

  getAgreements = async () =>{
    try {
      await axios.get(`${url}/api/auth/agencies/${this.props.user.fk_id_enterprise}`)
      .then(res => {
        const agreements_original = res.data;
        this.setState({ agreements_original })
      });                     
    }catch(error){
    }
  }

  getResults = async () =>{
    try {
      await axios.get(`${url}/api/auth/show_evaluations/${this.state.id_course}`)
      .then(res => {
        const results = res.data;
        const newAgenciesOptions = []
        results.group_agencies.forEach(agreement => {
          newAgenciesOptions.push({name: agreement.enterprise_name})
        })
        this.setState({ results, agencies_options: newAgenciesOptions })
        this.calculateUsers(results.data_evaluations, results.group_agencies)
      });                     
    }catch(error){
    }
  }

  selectedTypeViewTemplate(option, props) {
    if (option) {
      return (
        <div className="country-item data-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return (
      <span>
        {props.placeholder}
      </span>
    );
  }

  typeViewTemplate(option) {
    return (
        <div className="data-item">
            <div>{option.name}</div>
        </div>
    );
  }

  renderAgreement(data){
    return(
      <div key={data.id} className="col-sm-4 col-md-3 col-lg-2 p-1">
        <div key={data.id} className="card p-4" style={{textAlign:"center"}}>
          <div>
            <img style={{borderRadius:"100%", height:"100px"}} alt={data.enterprise_name} src={data.logo}/>
          </div>
          <hr/>
          <span className="p-text-bold" style={{display:"block"}}>{data.enterprise_name}</span>
          <span ><FormattedMessage id="GENERAL_MESSAGE.THEY_FINISHED_COURSE"/>: {data.usersComplete}</span>
          <span ><FormattedMessage id="GENERAL_MESSAGE.THEY_ARE_IN_PROGRESS"/>: {data.usersInProgress}</span>
          <span ><FormattedMessage id="GENERAL_MESSAGE.THEY_NOT_TAKEN_COURSE"/>: {data.usersIncomplete}</span>
        </div>
      </div>
    )
  }

  renderUser(data){
    return(
      <div key={data.id} className="ol-sm-4 col-md-3 col-lg-2">
        <div className="card p-4" style={{textAlign:"center"}}>
          <div style={{alignSelf:"center"}}>
            <Avatar alt={data.fullname} src={`${data.photo}`} width={32} style={{verticalAlign: 'middle',   margin: "10px", width: "50px", height: "50px",}} />
          </div>
          <hr/>
          <span className="p-text-bold" style={{display:"block"}}>{data.fullname}</span>
          <span ><FormattedMessage id="GENERAL.STATE"/>:</span>
          <span style={{fontSize: "12px"}}>{data.progress*100}%</span>
        </div>
      </div>
    )
  }

  render() {
    const data = this.props.dataEvaluation
    const graph = this.getData()
    return (
      <div>
        <Toast ref={(el) => this.toast = el} />  
        <Link to={{
          pathname:"/get-evaluations/evaluations",
          aboutProps:{
              data
          }
        }}>
        <button className="btn"><KeyboardBackspaceSharpIcon/></button>
        </Link>
        <div className="card p-6">
          <h1><FormattedMessage id="LEARNING_ASSESSMENT.STATISTICS"/></h1>
          {
            this.state.quantity ?
              <div>
                <h3 className="mb-4"><FormattedMessage id="LEARNING_ASSESSMENT.AGENTS_WHO_HAVE_ACCESS_MESSAGE"/>: {this.state.quantity}</h3>
                <div className="card mb-6">
                  <Chart style={{marginLeft:"auto", marginRight:"auto", marginBottom: "10px"}} height="400px" width="400px" type="pie" data={graph} options={this.lightOptions} />
                </div>
                <div className="ml-5 mb-5">
                  <div className="row mb-3" style={{alignItems: "center"}}>
                    <span className="mr-4">
                      <FormattedMessage id="GENERAL_MESSAGE.SEE_FOR"/>:
                    </span>
                    <Dropdown value={this.state.selectedOption} options={this.options} onChange={this.onChangeTypeView} optionLabel="name" filter showClear filterBy="name" placeholder={this.props.intl.formatMessage({id: 'MENU.DASHBOARD.AGENCIES'})}
                      valueTemplate={this.selectedTypeViewTemplate} itemTemplate={this.typeViewTemplate} />
                  </div>
                  <div className="row mb-3" style={{alignItems: "center"}}>
                    <span className="mr-4">
                      <FormattedMessage id="AUTH.INPUT.SELECT.OPTION_AGENCY"/>:
                    </span>
                    <Dropdown value={this.state.selectedOptionAgency} options={this.state.agencies_options} onChange={this.onChangeAgency} optionLabel="name" filter showClear filterBy="name" placeholder={this.props.intl.formatMessage({id: 'GENERAL.ALL'})}
                      valueTemplate={this.selectedTypeViewTemplate} itemTemplate={this.typeViewTemplate} />
                  </div>
                </div>
                <div className="p-grid">
                  {
                    this.state.agreements_show ?
                    this.state.agreements_show.map(agreement => this.renderAgreement(agreement))
                    :
                    <></>
                  }
                  {
                    this.state.users_show ?
                    this.state.users_show.map(user => this.renderUser(user))
                    :
                    <></>
                  }
                </div>
              </div>
            :
            <ProgressSpinner/>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  dataEvaluation: state.auth.dataEvaluation
});

export default injectIntl(
  connect(mapStateToProps)(Course)
)