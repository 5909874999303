import React from "react";
import {useSubheader} from "../../_metronic/_core";
import SchoolsTable from "../modulesAdmin/AdminEnterprises/SchoolsTable"

export const SchoolsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Schools");

  return (<><SchoolsTable/></>);

};