import React, {Component} from 'react'
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const url = ServerURL();



class UtilitiesTable extends Component {
            
  emptyCourse = {
    fk_id_enterprise:'',
    course:'',
    description:'',
    created:'',
  }
  emptyLessons = {
  
  }


 

constructor(props) {
  super(props);  

  this.state = { 
  
    enterpriseType:null,
    course:this.emptyCourse,

    agencies:null,
    schools:null,
    amount:1,
    dateCourse:'',  
    fk_id_enterprise:null,
    created:null,
    groups_agencies: [],
    group_agents: null,
    agreements_show: [],
    courses:null,
    agreements_original: null,
    selectedGroupAgencies: null,
    selectedAgents: null,
    selectedAgentsCheck:0,

    users:null,
    globalFilter: null,
    
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate(),
    selectedItemType:'',
    selectedAgency:'',
    selectedSchool:'',
    selectedEnterprise:0,
    selectedUser:'',
    selectedFieldUser:0,
    selectedGroup:0,
    fakeInformation:0

  }

  this.ItemTypes = [
    { type: this.state.campusText.Agency, id:0 },
    { type: this.state.campusText.School, id:1 },
  ];
 
  this.onEnterpriseTypeChange = this.onEnterpriseTypeChange.bind(this);
  this.onEnterpriseChange = this.onEnterpriseChange.bind(this);
  this.onUserChange = this.onUserChange.bind(this);
  this.FakeInformation = this.FakeInformation.bind(this);
  this.onGroupAgenciesChange = this.onGroupAgenciesChange.bind(this);
  this.onAgentsChange = this.onAgentsChange.bind(this);
  this.onAmountChange = this.onAmountChange.bind(this);
  this.onDateCourseChange = this.onDateCourseChange.bind(this);


  }


    
      componentDidMount() {
        this.getInitialData()
      }

      getInitialData= async () =>{

        let requestOne = axios.get(`${url}/api/auth/agencies`);
        let requestTwo =  axios.get(`${url}/api/auth/schools`);
    
        axios
        .all([requestOne, requestTwo])
        .then(
              axios.spread((...responses) => {
                const agencies = responses[0].data;
                const schools = responses[1].data;
                console.log("Agencies en initial", agencies)
                console.log("Schools en initial", schools)
    
                      this.setState({agencies,schools})
                    })
                  )
                  .catch(errors => {
                     console.error(errors);
                  });                  
      }   

      getAgreements = async (e) =>{ 
        try {
          await axios.get(`${url}/api/auth/agencies/${e}`)
          .then(res => {
            const agreements_original = res.data;
            this.setState({ agreements_original })
          });                     
        }catch(error){
        }
      }
    
     
               
     
      getUsersByEnterprise = async (e) =>{
        console.log("ID DE USER:", e)
          try {
             
              await axios.get(`${url}/api/auth/userbyEnterprise/${e}`)
              .then(res => {
                const users = res.data;       
                console.log("USERS:",users)     
                this.setState({ users })
              });                     
              }catch(error){

         }
      }  

      getGroupsAgencies = async (e) =>{
        try {
          await axios.get(`${url}/api/auth/groups_agencies/${e}`)
          .then(res => {
            const groups_agencies = res.data;
            this.setState({ groups_agencies })
          });
          }catch(error){
        }
      }

      getGroupAgents = async (e) =>{
        try {
          await axios.get(`${url}/api/auth/getUserByGroupAgency/${e}`)
          .then(res => {
            const group_agents = res.data;
            console.log("AGENS:",group_agents)
            this.setState({ group_agents })
          });
          }catch(error){
        }
      }

      getAgents = async (e) =>{
        try {
          await axios.get(`${url}/api/auth/getUserByAgency/${e}`)
          .then(res => {
            const group_agents = res.data;
            console.log("AGENS:",group_agents)
            this.setState({ group_agents })
          });
          }catch(error){
        }
      }


      onAmountChange(e){
        const val = (e.target && e.target.value) || '';
        let amount = this.state.amount
        if(val>=2){
          amount = val
        }
        this.setState({ amount});
      }

      onDateCourseChange(e){
        const val = (e.target && e.target.value) || '';
        let dateCourse = this.state.dateCourse
        if(e.value!==null){
          dateCourse = this.DateTime(val)
        }
        this.setState({ dateCourse});
      }

      DateTime(e){

          const date = new Date(e);          

           let year = date.getFullYear();
           let month = date.getMonth()+1;
           let day = date.getDate();
           let hours = date.getHours();
           let minutes = date.getUTCMinutes();
           let seconds = date.getUTCSeconds();


           if (day < 10) {
             day = '0' + day;
           }
           if (month < 10) {
             month = '0' + month;
           }
           if (hours < 10) {
            hours = '0' + hours;
           }
           if (minutes < 10) {
            minutes = '0' + minutes;
           }
           if (seconds < 10) {
            seconds = '0' + seconds;
           }

           let new_date = year +'-' + month + '-'+day + ' '+hours+':'+minutes+':'+seconds
           //console.log("New Data:",new_date)

           return new_date
      }




      onEnterpriseTypeChange(e) {   
        let enterpriseType = this.state.enterpriseType    
        if(e.value!==null){
            enterpriseType = e.value
        }
        
        this.setState({enterpriseType, selectedItemType:e.value})

      }

      onEnterpriseChange(e) {   
        let selectedEnterprise = this.state.selectedEnterprise    
        let fk_id_enterprise = this.state.fk_id_enterprise
        let enterpriseType = this.state.enterpriseType
           
        if(e.value!==null){
          selectedEnterprise = 1
          fk_id_enterprise = e.value
        }else{
          selectedEnterprise = 0
        }
        
        this.setState({selectedEnterprise, fk_id_enterprise, selectedAgency:e.value, selectedSchool:e.value})
        this.getUsersByEnterprise(e.value)
        if(enterpriseType === 1){
        this.getGroupsAgencies(e.value)
        this.getAgreements(e.value)

        }
      
      }

      onUserChange(e){
        let created = this.state.created
        let selectedFieldUser = this.state.selectedFieldUser
        let enterpriseType = this.state.enterpriseType
        let fk_id_enterprise = this.state.fk_id_enterprise

        created = e.value
        if(created!==null){
          selectedFieldUser=1
          if(enterpriseType===0){
            this.getAgents(fk_id_enterprise)
          }
        }else{
          selectedFieldUser=0
        }
        this.setState({created,selectedFieldUser, selectedUser:e.value})
      }

      onGroupAgenciesChange(e) {
        let selectedGroupAgencies = this.state.selectedGroupAgencies
        let selectedGroup = this.state.selectedGroup

        if(e.value!==null){
          selectedGroup = 1
          this.getGroupAgents(e.value)
          selectedGroupAgencies = e.value
        }else{
          selectedGroup = 0 
        }

        this.setState({selectedGroupAgencies, selectedGroup})
      }

      onAgentsChange(e) {
        let selectedAgents = this.state.selectedAgents
        let selectedAgentsCheck = this.state.selectedAgentsCheck
        console.log("E:",e)
          selectedAgents = e.value
          if(e.value.length===0){
            selectedAgentsCheck=0
          }else{
            selectedAgentsCheck=1
          }
        this.setState({selectedAgents, selectedAgentsCheck})
      }

      groupAgenciesOptionTemplate(option) {
        return (
            <div className="data-item">
                <div>{option.name}</div>
            </div>
        );
      }
      
    
      selectedGroupAgenciesTemplate(option, props) {
        if (option) {
          return (
            <div className="country-item data-item-value">
              <div>{option.name}</div>
            </div>
          );
        }
    
        return (
          <span>
            {props.placeholder}
          </span>
        );
      }     

//Hay que revisar el error del faker
/*       FakeInformation(){
        var courses = []
        
        let fk_id_enterprise = this.state.fk_id_enterprise
        let created = this.state.created
        let dateCourse = this.state.dateCourse
        let faker = require('faker');
        let amount = this.state.amount
        let enterpriseType = this.state.enterpriseType



        for(let i=0; i<amount; i++){ 
          courses[i] = {  
            fk_id_enterprise:fk_id_enterprise,
            course: (Math.floor(Math.random()*99)+1) + ' ' + faker.commerce.productName(),
            description: faker.commerce.productAdjective(),
            created:created,
            name_lesson: faker.hacker.adjective(),
            description_lesson: faker.hacker.phrase(),  
            dateCourse:dateCourse,
            evaluations:{
              questions: [
                {text: faker.commerce.productName()},
                {text: faker.commerce.productName()},
                {text: faker.commerce.productName()},
                {text: faker.commerce.productName()},
                {text: faker.commerce.productName()}
              ],
              answers: [
                [
                  {
                    text: faker.commerce.color(),
                    correct: true
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  }
                ],
                [
                  {
                    text: faker.commerce.color(),
                    correct: true
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  }
                ],
                [
                  {
                    text: faker.commerce.color(),
                    correct: true
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  }
                ],
                [
                  {
                    text: faker.commerce.color(),
                    correct: true
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  }
                ],
                [
                  {
                    text: faker.commerce.color(),
                    correct: true
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  },
                  {
                    text: faker.commerce.color(),
                    correct: false
                  }
                ]
              ]
            }
          }

        }

        let fk_id_group_agencies = this.state.selectedGroupAgencies
        let selectedAgents = this.state.selectedAgents
        for(let i in courses){
        courses[i].fk_id_group_agencies = fk_id_group_agencies
        courses[i].enterpriseType = enterpriseType
        courses[i].selectedAgents = selectedAgents
        }

        try {
          let res = axios.post(`${url}/api/auth/fake_course`,courses)
          
           .then(res => {
             })
             if (!res.error) {
              this.setState({fakeInformation:1, courses})
              this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Information fake is created', life: 3000 });
             }
               }catch(error){
             }   


      } */




      renderEnterprise=()=>{
        let enterpriseType = this.state.enterpriseType


        let showInfo=[]
        
        if(enterpriseType === 0){
            showInfo.push(<>
                      <div style={{ marginRight: 10}}>
                        <span className="p-float-label">   
                            <Dropdown
                              style={{ width: 190, height:33 }}
                              id="agency_name"
                              variant="outlined"
                              margin="dense"
                              value= { this.state.selectedAgency || "enterprise_name" } 
                              options={this.state.agencies} 
                              onChange={this.onEnterpriseChange}  
                              optionLabel="enterprise_name" 
                              optionValue="id"    
                            />
                          <label htmlFor="agency_name">{this.state.campusText.Agency}</label>
                          </span>
                        </div> 
            </>)
        }else if(enterpriseType === 1){
          showInfo.push(<>
            <div style={{ marginRight: 10}}>
              <span className="p-float-label">   
                  <Dropdown
                    style={{ width: 190, height:33 }}
                    id="school_name"
                    variant="outlined"
                    margin="dense"
                    value= { this.state.selectedSchool || "enterprise_name" } 
                    options={this.state.schools} 
                    onChange={this.onEnterpriseChange}  
                    optionLabel="enterprise_name" 
                    optionValue="id"    
                  />
                <label htmlFor="school_name">{this.state.campusText.School}</label>
                </span>
              </div> 
            </>)
          }
        
        return showInfo
        
        }

        renderUsers=()=>{
          let selectedEnterprise = this.state.selectedEnterprise
          let showInfo=[]

          if(selectedEnterprise === 1){
          showInfo.push(<>
            <div style={{ marginRight: 10}}>
              <span className="p-float-label">   
                  <Dropdown
                    style={{ width: 190, height:33 }}
                    id="user"
                    variant="outlined"
                    margin="dense"
                    value= { this.state.selectedUser || "fullname"} 
                    options={this.state.users} 
                    onChange={this.onUserChange}  
                    optionLabel="fullname" 
                    optionValue="id"    
                  />
                <label htmlFor="user">{this.state.campusText.User}</label>
                </span>
              </div> 
            </>)
          }

          return showInfo

        }


        renderAssign=()=>{
          let showInfo=[]
          let selectedFieldUser = this.state.selectedFieldUser
          let enterpriseType = this.state.enterpriseType    


          if(selectedFieldUser === 1 && enterpriseType === 1){
          showInfo.push(<>
              <div style={{ marginRight: 10}}>
              <span className="p-float-label">   
                  <Dropdown 
                  style={{ width: 190, height:33 }}
                  value={this.state.selectedGroupAgencies || "name"} 
                  options={this.state.groups_agencies} 
                  onChange={this.onGroupAgenciesChange} 
                  optionLabel="name" 
                  optionValue="id"
                  filter showClear filterBy="name" 
                  valueTemplate={this.selectedGroupAgenciesTemplate} itemTemplate={this.groupAgenciesOptionTemplate} />
              <label htmlFor="user">{this.state.campusText.AssignCourse}</label>
              </span>
              </div> 
            </>)
          }

          return showInfo

        }

        renderAgents=()=>{
          let showInfo=[]
          let selectedGroup = this.state.selectedGroup
          let selectedFieldUser = this.state.selectedFieldUser
          let enterpriseType = this.state.enterpriseType    


          if(selectedGroup === 1 && enterpriseType === 1){
          showInfo.push(<>
              <div style={{ marginRight: 10}}>
              <span className="p-float-label">   
              <MultiSelect 
                style={{ width: 190, height:33 }}
                value={this.state.selectedAgents} 
                options={this.state.group_agents} 
                onChange={this.onAgentsChange}
                optionLabel="fullname"
                optionValue="id"
                />
              <label htmlFor="user">{this.state.campusText.AssignAgents}</label>
              </span>
              </div> 
            </>)
          }else if(selectedFieldUser ===1 && enterpriseType === 0){
              showInfo.push(<>
              <div style={{ marginRight: 10}}>
              <span className="p-float-label">   
              <MultiSelect 
                style={{ width: 190, height:33 }}
                value={this.state.selectedAgents} 
                options={this.state.group_agents} 
                onChange={this.onAgentsChange}
                optionLabel="fullname"
                optionValue="id"
                />
              <label htmlFor="user">{this.state.campusText.AssignAgents}</label>
              </span>
              </div> 
            </>)

          }

          return showInfo

        }

        renderButton=()=>{
          let selectedGroup = this.state.selectedGroup
          let enterpriseType = this.state.enterpriseType
          let showInfo=[]

          if(selectedGroup === 1 && this.state.selectedAgentsCheck ===1){
          showInfo.push(<>
          <div style={{ marginRight: 10}}>
                      <FormattedMessage id="ADMIN_UTILITIES.CREATE_COURSES_AND_LESSONS_FAKE">
                        {(message) => <p><Button type="button" icon="pi pi-plus" label={message} onClick={this.FakeInformation}></Button></p>}
                      </FormattedMessage>
                    </div>        
            </>)
          }else if(enterpriseType === 0 && this.state.selectedAgentsCheck ===1){
            showInfo.push(<>
              <div style={{ marginRight: 10}}>
                          <FormattedMessage id="ADMIN_UTILITIES.CREATE_COURSES_AND_LESSONS_FAKE">
                            {(message) => <p><Button type="button" icon="pi pi-plus" label={message} onClick={this.FakeInformation}></Button></p>}
                          </FormattedMessage>
                        </div>        
                </>)
          }

          return showInfo

        }

        

      render(){

       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		      	<FormattedMessage id="ADMIN_UTILITIES.INFORMATION_FAKE.TITLE"/>
			    </h3>
          <br></br>
          <br></br>
          <br></br>

            <div style={{ display: 'flex', flexDirection: 'row' }}>  
             {/*TOOLS */} 
                   <div style={{ marginRight: 10}}>
                     <span className="p-float-label">   
                        <InputNumber
                       inputStyle={{ width: 190, height:33 }}
                       min="2"
                       id="amount"
                       variant="outlined"
                       value={this.state.amount}   
                       margin="dense"
                       onValueChange={(e) => this.onAmountChange(e)}
                       />
                      <label htmlFor="amount">{this.state.campusText.CoursesAmount}</label>
                      </span>
                     </div>  
                     <div style={{ marginRight: 10}}>
                     <span className="p-float-label">   
                       <Calendar
                       style={{ width: 190, height:33 }}
                       dateFormat="dd/mm/yy" 
                       id="dateCourse"
                       variant="outlined"
                       showTime 
                       showSeconds
                       value={this.state.dateCourse}   
                       margin="dense"
                       onChange={(e) => this.onDateCourseChange(e)}
                       />
                      <label htmlFor="amount">{this.state.campusText.DateCourse}</label>
                      </span>
                     </div>  
                   <div style={{ marginRight: 10}}>
                     <span className="p-float-label">   
                        <Dropdown
                        style={{ width: 190, height:33 }}
                        id="type"
                        variant="outlined"
                        margin="dense"
                        value={this.state.selectedItemType || "type"} 
                        options={this.ItemTypes} 
                        onChange={this.onEnterpriseTypeChange}  
                        optionLabel="type" 
                        optionValue="id"
                        />
                      <label htmlFor="type">{this.state.campusText.IsAgencyOrSchool}</label>
                      </span>
                     </div>  
                    {this.renderEnterprise()}
                    {this.renderUsers()}
                    {this.renderAssign()}      
                    {this.renderAgents()}
                   
            </div>
            <br></br>
            <div style={{ display: 'flex', flexDirection: 'row' }}>  
                    {this.renderButton()}      
            </div>
            <br></br>
            <br></br>
            <br></br>
          </div>
   </div>  
         
    )
  }
}
export default withRouter(UtilitiesTable);