import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";

import axios from 'axios';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

const url = ServerURL();

class ListTutorials extends Component {

  constructor(props) {
    super(props);
    this.state = {
      layoutSchool: 'grid',
      layoutAgency: 'grid',
      tutorials_school: null,
      tutorials_school_show: null,
      tutorials_agency: null,
      tutorials_agency_show: null,
      sortKey: {tutorials_school_show:null, tutorials_agency_show:null},
      sortOrder: null,
      sortField: null,
      types: null,
      resetType: "Type",
      dialogSchool: false,
      dialogAgency: false,
      btnDisable: false,
      title: "",
      displayConfirmation: false,
      idCourseSelect: false,
      searchText: {tutorials_school_show:"", tutorials_agency_show:""},
      optionsProgramType: null,
      programTypes: {tutorials_school_show:null, tutorials_agency_show:null},
      redirect: false
    };

    this.sortOptions = [
      {label: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.COURSES_PUBLISHED'}), value: '!published'},
      {label: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.DRAFTS'}), value: 'published'},
    ];

    this.optionsType = [
      {label: this.props.intl.formatMessage({id: 'MENU.DASHBOARD.SCHOOLS'}), value: 'school'},
      {label: this.props.intl.formatMessage({id: 'AUTH.INPUT.SELECT.OPTION_AGENCY'}), value: 'agency'},
      {label: this.props.intl.formatMessage({id: 'GENERAL.ALL'}), value: 'all'},
    ];

    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onHideC = this.onHideC.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
    this.changePage = this.changePage.bind(this);

    this.id_agente = 64;
    this.fk_id_program_type_tutorials = 10;
  }

  componentDidMount() {
    this.getCourses()
  }

  onClick(name, idCourseSelect) {
    let state = {
      [`${name}`]: true,
      idCourseSelect
    };

    this.setState(state);
  }

  onHide(name){
    this.setState({
      [`${name}`]: false,
      title: ""
    })
  }

  onHideC(name) {
    this.setState({
      [`${name}`]: false,
      title: ""
    });
  }

  onOpen(name){
    this.setState({
      [`${name}`]: true
    })
  }

  onSortChange(event) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value
      });
    }
    else {
      this.setState({
        sortOrder: 1,
        sortField: value,
        sortKey: value
      });
    }
  }

  Date(e){                 
    e.map((course) => {
    // console.log("E:",course.updated_at)
    
    let date = null
    let type_time = 0;
    while (type_time < 2) {
      if(type_time === 0){
        date = new Date(course.created_at); 
      }else{
        date = new Date(course.updated_at);
      }
      let year = date.getFullYear();
      let month = date.getMonth()+1;
      let dt = date.getDate();
  
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
  
      let new_date = dt +'/' + month + '/'+year
      // console.log("New Data ", (type_time+1) ,":",new_date)
  
      if(type_time === 0){
        course.created_at = new_date
      }else{
        course.updated_at = new_date
      }
      type_time++;
    }

     return course

   });
  }

  changePage(dataCourse) {
    console.log(this.props)
    console.log(dataCourse)
    this.props.updatedCourse(dataCourse)
    this.setState({redirect: true})
  }

  getCourses = async () =>{
    try {
      await axios.get(`${url}/api/auth/courses_tutorials/${3}`)
      .then(res => {
        const coursesAux = res.data;
        this.Date(coursesAux);

        const tutorials_school = []
        const tutorials_agency = []
        coursesAux.forEach(course => {
          const published = course.published;
          if (published === 0 || published === 2) {
            tutorials_school.push(course);
          }else{
            tutorials_agency.push(course);
          }
          
        })
        console.log(coursesAux)
        this.setState({ tutorials_school, tutorials_school_show: tutorials_school, tutorials_agency, tutorials_agency_show: tutorials_agency})
        return({ tutorials_school, tutorials_agency })
      });
    }catch(error){}
  }

  async saveCourse(type){
    let data = {
      course: this.state.title,
      published: type,
      fk_id_program_type: this.fk_id_program_type_tutorials,
    }
    if(type){
      data = {...data, fk_id_enterprise: 38}
    }else{
      data = {...data, fk_id_enterprise: 39}
    }
    if(this.state.title){
      console.log(data)
      try {
        await axios.post(`${url}/api/auth/course`, data)
        .then(res => {
          const { message, message_type } = res.data;
          let type = null;

          if(message_type === 'success'){
            type = "GENERAL.SUCCESSFUL"
            if(this.state.dialogSchool === true){
              this.setState({ dialogSchool: false, title: "" })
            }else{
              this.setState({ dialogAgency: false, title: "" })
            }
            this.getCourses()
          }else{
            type = "GENERAL_MESSAGE.ERROR"
          }

          this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 3000 });

        });
        }catch(error){
          console.log(error)
      }
    }else{
      this.toast.show({ severity: 'info', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.ERROR'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LIST.MISSING_DATA'}), life: 3000 });
    }
  }

  deleteCourse = async () =>{
    console.log(this.state.idCourseSelect)
    try {
      await axios.delete(`${url}/api/auth/course/${this.state.idCourseSelect}`)
      .then(res => {     
        this.setState({ displayConfirmation: false })
        this.getCourses()
      });
      }catch(error){
    }
  }

  renderListItem(data) {
    return (
      <div className="p-col-12">
        <div className="course-list-item">
          <img src={data.image} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`course-${data.course}`} />
          <div className="course-list-detail">
            <h4>{data.enterprise_name}</h4>
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            {
              data.published === 0 || data.published === 1 ?
              <div><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
              :
              <div><FormattedMessage id="GENERAL_MESSAGE.POSTED"/>: {data.updated_at}</div>
            }
          </div>
          <div className="course-list-action">
            <span className="course-price">{data.program_type}</span>
            <div>
              <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
              <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.onClick('displayConfirmation', data.id)} />
            </div>
            {
              data.published === 0 || data.published === 1 ?
              <span className="p-tag course-badge text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
              :
              <></>
            }
          </div>
        </div>
      </div>
    );
  }

  renderGridItem(data) {
    return (
      <div className="p-col-12 p-sm-6 p-md-4 p-lg-3">
        <div className="course-grid-item card">
          <div className="course-grid-item-top">
            <h6>{data.enterprise_name}</h6>
            {
              data.isComplete && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
              )
            }
          </div>
          <div className="course-grid-item-top">
            <span>{data.program_type}</span>
            {
              data.published === 0 || data.published === 1 ?
              <span className="p-tag course-category text-uppercase" style={{background:"black"}}><FormattedMessage id="LEARNING_GENERAL.DRAFT"/></span>
              :
              <span><FormattedMessage id="GENERAL_MESSAGE.POSTED"/> {data.updated_at}</span>
            }
          </div>
          <div className="course-grid-item-content">
            <img style={{width: "100%"}} src={data.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.name} />
            <div className="course-name">{data.course}</div>
            <div><FormattedMessage id="GENERAL_MESSAGE.CREATED_BY"/>: {data.created}</div>
            {
              data.published === 0 || data.published === 1 ?
              <div><FormattedMessage id="GENERAL_MESSAGE.MODIFIED_BY"/>: {data.modified}</div>
              :
              <></>
            }
          </div>
          <div className="course-grid-item-bottom">
            <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
            <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.onClick('displayConfirmation', data.id)} />
          </div>
        </div>
      </div>
    );
  }

  itemTemplate(course, layout) {
    if (!course) {
      return (<></>);
    }

    if (layout === 'list')
      return this.renderListItem(course);
    else if (layout === 'grid')
      return this.renderGridItem(course);
  }

  renderHeaderSchool() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-12 mb-1" style={{display:"flex", justifyContent:"space-between"}}>
          <h3><FormattedMessage id="LEARNING_TUTORIALS.SCHOOL_TUTORIALS"/></h3>
          <DataViewLayoutOptions layout={this.state.layoutSchool} onChange={(e) => this.setState({ layoutSchool: e.value })} />
        </div>
      </div>
    );
  }

  renderHeaderAgency() {
    return (
      <div className="p-grid p-nogutter">
        <div className="p-col-12 mb-1" style={{display:"flex", justifyContent:"space-between"}}>
          <h3><FormattedMessage id="LEARNING_TUTORIALS.AGENCY_TUTORIALS"/></h3>
          <DataViewLayoutOptions layout={this.state.layoutAgency} onChange={(e) => this.setState({ layoutAgency: e.value })} />
        </div>
      </div>
    );
  }

  renderFooter(name) {
    return (
      <div>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={() => this.onHideC(name)} className="p-button-text" autoFocus/>
        <Button label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={() => this.deleteCourse(name)} />
      </div>
    );
  }

  render() {

    if(this.state.redirect) {
      return <Redirect to={{
        pathname: "/get-tutorials/course",
      }}/>;
    }

    const headerTutorialSchool = this.renderHeaderSchool();
    const headerTutorialAgency = this.renderHeaderAgency();

    const footerSchool = (
      <div>
          <Button disabled={this.state.btnDisable} label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={e => this.saveCourse(0)} />
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={e => this.onHide('dialogSchool')} />
      </div>
    );

    const footerAgency = (
      <div>
          <Button disabled={this.state.btnDisable} label={this.props.intl.formatMessage({id: 'GENERAL.YES'})} icon="pi pi-check" onClick={e => this.saveCourse(1)} />
          <Button label={this.props.intl.formatMessage({id: 'GENERAL.NO'})} icon="pi pi-times" onClick={e => this.onHide('dialogAgency')} />
      </div>
    );

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <Dialog header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.COMPLETE_INFORMATION'})} footer={footerSchool} visible={this.state.dialogSchool} onHide={e => this.onHide('dialogSchool')}>
          <div className="p-grid">
            <div className="p-col">
              <span className="p-float-label">
                <InputText id="title" style={{width:"100%"}} value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} />
                <label htmlhtmlfor="title"><FormattedMessage id="GENERAL_MESSAGE.COURSE_TITLE"/></label>
              </span>
            </div>
          </div>
        </Dialog>
        <Dialog header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.COMPLETE_INFORMATION'})} footer={footerAgency} visible={this.state.dialogAgency} onHide={e => this.onHide('dialogAgency')}>
          <div className="p-grid">
            <div className="p-col">
              <span className="p-float-label">
                <InputText id="title" style={{width:"100%"}} value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} />
                <label htmlhtmlfor="title"><FormattedMessage id="GENERAL_MESSAGE.COURSE_TITLE"/></label>
              </span>
            </div>
          </div>
        </Dialog>
        <Dialog header={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.CONFIRM'})} visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter('displayConfirmation')} onHide={() => this.onHideC('displayConfirmation')}>
          <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              <span><FormattedMessage id="GENERAL_MESSAGE.CONFIRM_DELETE_COURSE"/></span>
          </div>
        </Dialog>
        <div className="dataview mb-5">
          {
            this.state.tutorials_school ?
            <button className="m-3 btn btn-primary" onClick={e => this.onOpen('dialogSchool')}><AddIcon/> <FormattedMessage id="LEARNING_TUTORIALS.ADD_NEW_TUTORIAL_SCHOOL"/></button>
            :
            <></>
          }
          <div className="card">
            {
              this.state.tutorials_school ?
              <DataView value={this.state.tutorials_school_show} layout={this.state.layoutSchool} header={headerTutorialSchool}
                itemTemplate={this.itemTemplate} rows={12}
                emptyMessage={this.props.intl.formatMessage({id: 'LEARNING_TUTORIALS.NO_TUTORIALS'})}
                paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
              :
              <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
        </div>
        <div className="dataview mb-5">
          {
            this.state.tutorials_agency ?
            <button className="m-3 btn btn-primary" onClick={e => this.onOpen('dialogAgency')}><AddIcon/> <FormattedMessage id="LEARNING_TUTORIALS.ADD_NEW_TUTORIAL_AGENCY"/></button>
            :
            <></>
          }
          <div className="card">
            {
              this.state.tutorials_agency ?
                <DataView value={this.state.tutorials_agency_show} layout={this.state.layoutAgency} header={headerTutorialAgency}
                  itemTemplate={this.itemTemplate} rows={12}
                  emptyMessage={this.props.intl.formatMessage({id: 'LEARNING_TUTORIALS.NO_TUTORIALS'})}
                  paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"/>
              :
                <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
            }
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(ListTutorials)
)