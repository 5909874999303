import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen} from "../_metronic/_core";
import {ContentRoute} from "../_metronic/layoutAdmin";
import {BuilderPage} from "./pagesAdmin/BuilderPage";
import {MyPage} from "./pagesAdmin/MyPage";
import {DashboardPage} from "./pagesAdmin/DashboardPage";
import {DashboardPageDefault} from "./pagesAdmin/DashboardPageDefault";
import {DashboardAgencyPage} from "./pagesAdmin/Agencies/DashboardAgencyPage";

import {DataUserPage} from "./pages/DataUserPage";

import { GetTasksPage } from './pagesAdmin/GetTasksPage';
import { AddTaskPage } from './pagesAdmin/AddTaskPage';
import { UsersPage } from './pagesAdmin/UsersPage';
import { AgenciesPage } from './pagesAdmin/AgenciesPage';
import { AgreementsPage } from './pagesAdmin/AgreementsPage';
import { CountriesPage } from './pagesAdmin/CountriesPage';
import { CampusesPage } from './pagesAdmin/CampusesPage';
import { ProgramTypesPage } from './pagesAdmin/ProgramTypesPage';
import { CoursesPage } from './pagesAdmin/CoursesPage';
import { TutorialsPage } from './pagesAdmin/TutorialsPage';
import { CoursePage } from './pagesAdmin/CoursePage';
import { LessonPage } from './pagesAdmin/LessonPage';
import { EvaluationPage } from './pagesAdmin/EvaluationPage';

import { OfficesPage } from './pagesAdmin/OfficesPage';
import { SchoolsPage } from './pagesAdmin/SchoolsPage';
import { EnterprisesPage } from './pagesAdmin/EnterprisesPage';
import { AgencyGroupsPage } from './pagesAdmin/AgencyGroupsPage';

import { CurrenciesPage } from './pagesAdmin/CurrenciesPage';
import { UtilitiesPage } from './pagesAdmin/UtilitiesPage';

import { LoadStudents, LoadStudentsPage } from './pagesAdmin/LoadStudentsPage'
import { InvoicesHealthPage } from "./pagesAdmin/InvoicesHealthPage";


import { useSelector } from "react-redux";

import { RolesPage } from './pagesAdmin/RolesPage';

const GoogleMaterialPage = lazy(() =>
  import("./modulesAdmin/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modulesAdmin/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modulesAdmin/ECommerce/pages/eCommercePage")

);

export default function BasePageAdmin() {
  const { user } = useSelector(state => state.auth);
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const renderSwitchRedirect = (role) => {
    switch(role) {
      case 'Administrador':
        return <Redirect exact from="/" to="/analytics"/>;
      default:
        return <Redirect exact from="/" to="/dashboard"/>;
    }
  };
  
  const renderSwitchRoute = (role) => {
    switch(role) {
      case 'Administrador':
        return <ContentRoute path="/analytics" component={DashboardPage}/>;
      default:
        return <ContentRoute path="/dashboard" component={DashboardPageDefault}/>;
    }
  };

  return (
    <Suspense fallback={<LayoutSplashScreen/>}>
      <Switch>
        {
            /* Redirect from root URL to /dashboard. */
            renderSwitchRedirect(user.role)
        }
        {renderSwitchRoute(user.role)}
        <ContentRoute path="/dashboard-agency" component={DashboardAgencyPage}/>
        <ContentRoute path="/builder" component={BuilderPage}/>
        <ContentRoute path="/my-page" component={MyPage}/>
        
        <ContentRoute path="/get-task" component={GetTasksPage}/>
        <ContentRoute path="/add-task" component={AddTaskPage}/>

        <ContentRoute path="/get-users" component={UsersPage}/>
        <ContentRoute path="/get-roles" component={RolesPage}/>
        <ContentRoute path="/get-agencies" component={AgenciesPage}/>
        <ContentRoute path="/get-agreements" component={AgreementsPage}/>
        <ContentRoute path="/get-countries" component={CountriesPage}/>
        <ContentRoute path="/get-campuses" component={CampusesPage}/>
        <ContentRoute path="/get-programs" component={ProgramTypesPage}/>
        <ContentRoute path="/get-courses" component={CoursesPage}/>
        <ContentRoute path="/get-tutorials/course/lesson/evaluation" component={EvaluationPage}/>
        <ContentRoute path="/get-tutorials/course/lesson" component={LessonPage}/>
        <ContentRoute path="/get-tutorials/course" component={CoursePage}/>
        <ContentRoute path="/get-tutorials" component={TutorialsPage}/>

        <ContentRoute path="/get-currencies" component={CurrenciesPage}/>
        <ContentRoute path="/get-utilities" component={UtilitiesPage}/>

        <ContentRoute path="/get-students" component={LoadStudentsPage}/>
        <ContentRoute path="/invoice-health" component={InvoicesHealthPage}/>



        <ContentRoute path="/my-profile" component={DataUserPage}/>

        <ContentRoute path="/get-schools" component={SchoolsPage}/>
        <ContentRoute path="/get-enterprises" component={EnterprisesPage}/>
        <ContentRoute path="/get-agencyGroups" component={AgencyGroupsPage}/>
        <ContentRoute path="/get-offices" component={OfficesPage}/>

        <Route path="/google-material" component={GoogleMaterialPage}/>
        <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
        <Route path="/e-commerce" component={ECommercePage}/>
        <Redirect to="error/error-v1"/>
      </Switch>
    </Suspense>
  );
}
