//* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import { FormattedMessage } from "react-intl";

//import { useIntl } from "react-intl";

export function AsideMenuListS({ layoutProps }) {
  //const int1 = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/google-material", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text text-uppercase"><FormattedMessage id="ACCOUNTING_GENEREAL.ACCOUNTING"/></span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
              <li className="menu-section">
              <li
                  className={`menu-item ${getMenuItemActive("/get-analytics", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-analytics">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")}/>
                    </span>
                    <span className="menu-text">Analytics</span>
                  </NavLink>
                </li>
                </li>
                {/* Usuarios */}
                {/* begin::section */}
                <li className="menu-section">
                  <h4 className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.FINANCES"/></h4>
                  <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/get-invoices", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-invoices">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                  </span>
                    <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.INVOICES"/></span>
                  </NavLink>
                </li>
               {/*} <li
                    className={`menu-item ${getMenuItemActive("/get-admin-payments", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-admin-payments">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")}/>
                  </span>
                    <span className="menu-text">Payments</span>
                  </NavLink>
          </li>*/}
                {/*<li
                    className={`menu-item ${getMenuItemActive("/my-page", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/my-page">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale1.svg")}/>
                  </span>
                    <span className="menu-text">Incentives</span>
                  </NavLink>
                </li>*/}
                {/*end::1 Level*/}

              

              

                {/* Configuración */}
                {/* begin::section */}
                <li className="menu-section ">
                  <h4 className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.SETTINGS"/></h4>
                  <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/get-exchanges", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-exchanges">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Exchange.svg")}/>
                  </span>
                    <span className="menu-text"><FormattedMessage id="ACCOUNTING_GENEREAL.EXCHANGE_RATES"/></span>
                  </NavLink>
                </li>


                {/*<li
                    className={`menu-item ${getMenuItemActive("/get-script", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-script">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")}/>
                  </span>
                    <span className="menu-text">Script Edvisor</span>
                  </NavLink>
                </li>*/}
                {/*end::1 Level*/}
                </ul>
            </div>
          </li>
        </ul>
        {/* end::Menu Nav */}
      </>
  );
}
