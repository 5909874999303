import React from "react";
import {useSubheader} from "../../_metronic/_core";
import EnterprisesTable from "../modulesAdmin/AdminEnterprises/EnterprisesTable"

export const EnterprisesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Enterprises");

  return (<><EnterprisesTable/></>);

};