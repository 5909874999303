import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import { Redirect } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import Avatar from '@material-ui/core/Avatar'

import moment from 'moment';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import * as auth from '../../modulesAdmin/Auth/_redux/authRedux';
import { connect } from 'react-redux';

const url = ServerURL();

class CustomerSuccess extends Component {

  emptyCustomers ={
    cs_name: '',
    // start_date: '',
    // end_date: '',
    // events: ''
  }

  constructor (props) {
    super(props);

    this.state = {
      cs_name:'',
      // start_date: '',
      // end_date: '',
      // events: '',

      nameMessage: '',
      // start_dateMessage: '',
      // end_dateMessage: '',

      customer: this.emptyCustomers,
      customers: null,
      event: null,
      ids: null,
      globalFilter: null,
      selectedCustomers: null,
      selectedEvents: null,
      customerDialog: null,
      customerDeleteDialog: null,
      // deleteCustomerDialog: null,
      // deleteCustomersDialog: null,
      submitted: false,
      displayConfirmation: false,

      layout: 'grid',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      
      
      customerText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTranslate: getGeneralTextTranslate(),

      idCustomerSuccessSelect: false,
      changeToCustomer: false,
      redirect: false
    }

    this.sortOptions =[
      {label: 'Por grupos', value: 'groups'}
    ]


    // this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    // this.deleteCustomer = this.deleteCustomer.bind(this);
    // this.openNew = this.openNew.bind(this);
    // this.editCustomer = this.editCustomer.bind(this);
    // this.confirmDeleteCustomer = this.confirmDeleteCustomer.bind(this);
    // this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
    // this.deleteSelected = this.deleteSelected.bind(this);
    // this.hideDialog = this.hideDialog.bind(this);
    // this.hideDeleteCustomerDialog = this.hideDeleteCustomerDialog.bind(this);
    // this.hideDeleteCustomersDialog = this.hideDeleteCustomersDialog.bind(this);
    // this.onInputChange = this.onInputChange.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);

    this.onClick = this.onClick.bind(this);
    this.onHideC = this.onHideC.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onDelete = this.onDelete.bind(this);
    // this.onChangeSearch = this.onChangeSearch.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    try {

      console.log('Actualizado')
      this.getCustomers()
    } catch (error) {
      console.log(error)
    }
  }

  onClick(name, idCustomerSuccessSelect){
    let state = {
      [`${name}`]: true,
      idCustomerSuccessSelect
    };

    this.setState({ state })
  }

  onHide() {
    this.setState({
      customerDialog: false,
      cs_name: ''
    })
  }

  onHideC(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  onOpen(){
    this.setState({
      customerDialog: true
    })
  }

  onDelete(customer){
    console.log("CS: ", customer);
    this.setState({
      customer,
      customerDeleteDialog: true
    })
  }

  changePage(dataCustomerSuccess) {
    this.props.updatedCustomerSuccess(dataCustomerSuccess)
    this.setState({ redirect: true })
    console.log(this.props);
    console.log(dataCustomerSuccess);
  }
  
  validateName(){
    const customer = this.state.customer

    let customerMessage;

    if(!customer.name) {
      customerMessage = 'Requerido'
    } else {
      customerMessage = ''
    }
    this.setState({ customerMessage })
    return customerMessage
  }

  validateStartDate() {
    const customer = this.state.customer

    let start_dateMessage;

    if(!customer.start_date){
      start_dateMessage = 'Requerido'
    } else {
      start_dateMessage = ''
    }
    this.setState({ start_dateMessage })
    return start_dateMessage
  }

  validateEndDate() {
    const customer = this.state.customer

    let end_dateMessage

    if(!customer.end_date) {
      end_dateMessage = 'Requerido'
    } else {
      end_dateMessage = ''
    }
    this.setState({ end_dateMessage })
    return end_dateMessage
  }

  isValid(){
    const { name, start_date, end_date } = this.state.customer

    const nameMessage =  this.validateName()
    const start_dateMessage =  this.validateStartDate()
    const end_dateMessage =  this.validateEndDate()

    
    if(name && start_date && end_date) {
      if(!nameMessage && start_dateMessage && end_dateMessage) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  getTextAuxName() {
    const message = this.state.nameMessage
    return message
  }


  getTextStartDate() {
    const message = this.state.start_date
    return message
  }

  getTextEndDate() {
    const message = this.state.end_date
    return message
  }

  openNew() {
    this.getEvents()
    this.setState({
      customer: this.emptyCustomers,
      submitted: false,
      customerDialog: true
    })
  }

  onInputChange(e, name){
    const val = (e.target && e.target.value) || '';
    let customer = {...this.state.customer}
    customer[`${name}`] = val;

    this.setState({ customer })
  }

  hideDialog() {
    this.setState({
      submitted: false,
      customerDialog: false,
      customerMessage: ''
    })
  }

  editCustomer(customer){
    console.log(customer);
    this.getCustomers()
    this.setState({
      customer: {...customer},
      customerDialog: true
    })
  }

  confirmDeleteCustomer(customer) {
    console.log(customer)
    this.setState({
      customer,
      deleteCustomerDialog: true
    })
  }

  confirmDeleteSelected() {
    this.setState({ deleteCustomersDialog: true })
  }

  hideDeleteCustomerDialog() {
    this.setState({ deleteCustomerDialog: false })
  }

  hideDeleteCustomersDialog() {
    this.setState({ deleteCustomersDialog: false})
  }

  getCustomers() {
    try {
      axios.get(`${url}/api/public/wiyu_customer_success`)
      .then(res =>{
        const customers = res.data;
        this.setState({ customers })
        console.log(customers)
      });
    } catch (error) {
      console.log(error)
    }
  }

  getEvents() {
    try {
      let res = axios.get(`${url}/api/public/events`)
      .then(res => {
        const events = res.data;
        this.setState({ events })
      });
      console.log(res.data)
      return res
    } catch (error) {
      console.error(error);
    }
  }

  async saveCustomer(){
    // let state = { submitted: true }
    // const decision = this.isValid();

    // if (decision) {
    // let data = {...this.state.customer}
    let data = {
      cs_name: this.state.cs_name,
      created: this.props.user.fullname,
      modified: this.props.user.fullname,
    }

        if( this.state.cs_name ) {
          console.log("Data: ", data);
          try {
            await axios.post(`${url}/api/public/wiyu_customer_success/`, data)
            .then(res => {
              this.getCustomers()
              this.setState({ customerDialog: false, cs_name: ''})
              // const { message, message_type } = res.data;
              // let type = null;

              // if(message_type === 'success'){
              //   type = "GENERAL.SUCCESSFUL"
              //   this.setState({ customerDialog: false, cs_name: "" })
              //   this.getCustomers()
              // }else{
              //   type = "GENERAL_MESSAGE.ERROR"
              // }

              this.toast.show({ severity: 'success', summary:'Successful', detail: 'Customer success created', life: 3000 });

            })
            // if(!res.error){
            //   this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Customer Success created', life: 3000})
            // }
          } catch (error) {
            console.error(error);
          }

        // state = {
        //   ...state,
        //   data,
        //   customerDialog: false,
        //   customer: this.emptyCustomers,
        // }

        } else {
          this.toast.show({ severity: 'info', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.ERROR'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LIST.MISSING_DATA'}), life: 3000 });
        }

    // this.setState(state)
  }

  deleteCustomer = async () => {
    let data = {...this.state.customer}

    try {
      await axios.delete(`${url}/api/public/wiyu_customer_success/${data.id}`)
      .then(res => {
        this.getCustomers() 
        if(!res.error){
          this.setState({
            data,
            customerDeleteDialog: false,
            customer: this.emptyCustomers
          })
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Customer deleted', life: 3000 });
        }
      })

    } catch (error) {
      
    }
  }

  deleteSelected() {
    let customers = this.state.customers.filter(val => !this.state.selectedCustomers.includes(val));
    let data = this.state.customers.filter(val => this.state.selectedCustomers.includes(val));

    try {
      let res = axios.delete(`${url}/api/public/wiyu_customer_success`, {data})
      .then(res => {
        this.getCustomers()
      })
      if(!res.error){
        this.setState({
          customers,
          deleteCustomerDialog: false,
          selectedCustomers: null
        })
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Customer Successes deleted', life: 3000 })
      }
    } catch (error) {
      
    }
  }

  saveSelected() {
    let customer = this.state.customer.filter(val => !this.state.selectedEvents.includes(val));
    let data = this.state.customer.filter(val => this.state.selectedEvents.includes(val));

    try {
      let res = axios.post(`${url}/api/public/events`, {data})
      .then(res => {
        this.getEvents()
      })
      if(!res.error){
        this.setState({
          customer,
          saveEventsDialog: false,
          selectedEvents: null
        })
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Customer Successes deleted', life: 3000 })
      }
    } catch (error) {
      
    }
  }

  onSortChange(event) {
    const value = event.value

    if (value.indexOf('!') === 0) {
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value
      })
    }
    else {
      this.setState({
        setOrder: 1,
        sortField: value,
        sortKey: value
      })
    }
  }

  actionBodyTemplate(rowData) {
    return (
      <>
        {/* <Button icons="pi pi-pencil" color='primary' style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px"}} onClick={() => this.editCustomer(rowData)} /> */}
        <Button icon='pi pi-trash' className="p-button-danger" style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px"}} onClick={() => this.onDelete(rowData)} />
        {/* <Button icon='pi pi-check' className="primary" style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px"}} onClick={() => this.confirmDeleteCustomer(rowData)} /> */}
      </>
    )
  }

  renderListCustomer(data) {
    return (
      <div className='p-col-12'>
        <div className='course-list-item'>
          {/* <Avatar alt={`${data.cs_name}`} src={data.cs_name}  width={32} style={{verticalAlign: 'middle',   margin: "10px", width: "50px", height: "50px",}} /> */}
          <div className='course-list-detail'>
            {/* <h4>{data.enterprise_name}</h4> */}
            <h4>{data.cs_name}</h4>
            <div><FormattedMessage id='GENERAL_MESSAGE.CREATED_BY'/>: {data.created}</div>
            <div><FormattedMessage id='GENERAL_MESSAGE.MODIFIED_BY'/>: {data.modified}</div>
            {
                    data.status === 0 ?
                    <span className='p-tag course-badge text-uppercase' style={{ background:'black', alignSelf:'center'}}>Inactive</span>
                    :
                    <span className='p-tag course-badge text-uppercase' style={{ background:'green'}}>Active</span>
            }
            {/* <div>Students assignment: </div> */}
          </div>
          <div className='course-list-action'>
            <div style={{ alignSelf: 'center'}}>
            </div>
            <div style={{ alignSelf: 'flex-end'}}>
              <Button icon="pi pi-pencil" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
              {this.actionBodyTemplate(data)}
            </div>
            <span><FormattedMessage id='GENERAL_MESSAGE.POSTED'/> {moment.utc(data.created_at).format("DD/MM/YYYY")}</span>
          </div>
        </div>
        
      </div>
    )
  }

  renderGridCustomer(data){
    // const img = {
    //   width: '32px',
    //   height: '32px',
    //   textAlign: 'center',
    //   objectFit: 'cover'
    // }

    return (
      <div className='p-col-12 p-sm-6 p-md-4 p-lg-3'>
        <div className='course-grid-item card'>
          {/* <div className='mb-3'>
            <Avatar alt={`${data.cs_name}`} src={data.cs_name} imgProps={{ style:{ height: '40px', margin: 0}}} />
          </div> */}
        <div className='course-grid-item-top'>
            <h6>{data.enterprise_name}</h6>
            {
              data.status === 0 ?
              <span className='p-tag course-badge text-uppercase' style={{ background:'black'}}>Inactive</span>
              :
              <span className='p-tag course-badge text-uppercase' style={{ background:'green'}}>Active</span>
            }
          {/* <span className=''></span> */}
        </div>
        <div className='course-grid-item-top'>
            {/* <span><FormattedMessage id='GENERAL_MESSAGE.POSTED'/></span>
            <span>{moment.utc(data.created_at).format('DD/MM/YYYY')}</span> */}
            {/* <h4 style={{alignSelf: 'center'}}>{data.cs_name}</h4> */}
          {/* <span className=''></span> */}
        </div>
        <div className='course-grid-item-content'>
          {/* <img src={data.photo} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.cs_name}></img> */}
          <div className='course-name'>{data.cs_name}</div>
          {/* <div>{moment(data.created_at).format("DD/MM/YYYY")}</div> */}
          <div><FormattedMessage id='GENERAL_MESSAGE.CREATED_BY'/>: {data.created}</div>
          <div><FormattedMessage id='GENERAL_MESSAGE.MODIFIED_BY'/>: {data.modified}</div>
          <span><FormattedMessage id='GENERAL_MESSAGE.POSTED'/>: {moment.utc(data.created_at).format('DD/MM/YYYY')}</span>
          {/* <span> {moment.utc(data.created_at).format('DD/MM/YYYY')}</span> */}
          {/* <div>Students assignment: {data.total_students} </div> */}

        </div>
        <div className="course-grid-item-bottom">
          <Button icon="pi pi-pencil" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)}/>
          {this.actionBodyTemplate(data)}
        </div>
        </div>
      </div>
    )
  }

  itemTemplate(customer, layout){
    if (!this.getCustomers){
      return;
    }

    if(layout === 'list')
      return this.renderListCustomer(customer)
    else if (layout === 'grid')
      return this.renderGridCustomer(customer) 
  }

  renderHeader() {
    return(
      <div className='p-grid p-nogutter'>
        <div className='col-6' style={{textAlign: 'left', justifyContent: 'space-between'}}>
          <Dropdown style={{ minWidth: 'min-content'}} className='mr-2' options={this.sortOptions} value={this.state.sortKey} optionLabel='label' placeholder='Sort by Group' onChange={this.onSortChange} />
        </div>
        <div className='col-6' style={{textAlign: 'right'}}>
          <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value})} />
        </div>
      </div>
    )
  }

  renderFooter(name){
    return (
      <div>
        <FormattedMessage id=" GENERAL.NO">
          {
            (message) => <Button label={message} icon='pi pi-times' onClick={() => this.onHideC(name)} className='p-button-text' autoFocus />
          }
        </FormattedMessage>

        <FormattedMessage id='GENERAL.YES'>
          {
            (message) => <Button label={message} icon='pi pi-check' onClick={() => this.deleteCustomer(name) } />
          }
        </FormattedMessage>
      </div>
    )
  }

  render(data) {

    if(this.state.redirect){
      return <Redirect to={{
        pathname: '/wiyu-customer-assign'
      }} />
    }


    const footer =(
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.onHide} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE" >
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-check" onClick={this.saveCustomer} />}
        </FormattedMessage>
      </>
    );

    const deleteFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={() => this.onHideC('customerDeleteDialog')} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES" >
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-check" onClick={this.deleteCustomer} />}
        </FormattedMessage>
      </>
    )

    // const deleteCustomerDialogFooter = (
    //   <>
    //     <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCustomerDialog} />
    //     <Button label="Yes" icon="pi pi-check" className="p-button=text" onClick={this.deleteCustomer} />
    //   </>
    // )

    // const deleteCustomersDialogFooter = (
    //   <>
    //     <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCustomersDialog} />
    //     <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelected } />
    //   </>
    // )

    const headerCustomer = this.renderHeader();


    return (     
      <div>
        <Toast ref={(el) => this.toast = el} />

        <FormattedMessage id="GENERAL_MESSAGE.COMPLETE_INFORMATION">
          {
            (message) => (
              <Dialog header={message} footer={footer} visible ={this.state.customerDialog} onHide={this.onHide} style={{width:"30%"}}>
                <div className="p-grid" style={{marginTop: "1em"}}>
                  <div className="p-col">
                    <span className="p-float-label">
                      <InputText id="cs_name" value={this.state.cs_name} onChange={(e) => this.setState({cs_name: e.target.value})} style={{width:"100%"}}/>
                      <label htmlFor="title">Customer success name</label>
                    </span>
                  </div>
                </div>
              </Dialog>
            )
          }
        </FormattedMessage>

          {/* Delete customer */}
        <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
          {
            (message) => (
              <Dialog header={message} footer={deleteFooter} visible={this.state.customerDeleteDialog} onHide={() => this.onHideC('customerDeleteDialog')} style={{width:"30%"}}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.customer && <span> Are you sure you want to delete <b>{this.state.customer.cs_name}</b>?</span>}
                    </div>
              </Dialog>
            )
          }
        </FormattedMessage>

        <div className='dataview mb-5'>
          <div className='card'>
            <DataView value={this.state.customers} layout={this.state.layout} header={headerCustomer} 
            itemTemplate={this.itemTemplate} sortOrder={this.state.sortOrder} sortField={this.state.sortField} rows={8} />
          </div>
          <Button className="m-3 btn btn-primary" onClick={this.onOpen}><AddIcon/> Add new customer</Button>
        </div>


        {/* UPDATE CUSTOMER SUCCESS & CREATE CUSTOMER SUCCESS */}
        {/* <div>
          <Dialog visible ={this.state.customerDialog} contentStyle={{maxHeight: "800px", width:"600px", height:"270px", overflow: "auto"}} header="Save your Customer Success" modal footer={footer} onHide={this.hideDialog} >
            <br></br>
            <div className="form-group row" >
              <div className='col-lg-6' >
                <span className="p-float-label" >
                  <InputText
                  id="name"
                  variant="outlined"
                  type="name"
                  margin="dense"
                  value={this.state.customer.name}
                  onChange={(e) => this.onInputChange(e, 'name')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.customer.name })}
                  />
                  <label htmlFor="name" >Name</label>
                </span>
                <small></small>
              </div>

            </div>
          </Dialog>
        </div> */}


      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(CustomerSuccess)
)