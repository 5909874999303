import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../../_metronic/i18n/TextTranslate'

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Dropdown } from 'primereact/dropdown';



const url = ServerURL();




class CoursesTable extends Component {
            

  
  emptyCourse = {
    fk_id_program_type: '',
      //fk_id_campus: '',
      //fk_id_user: '',
      course: '',
      description: '',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    fk_id_program_type: '',
      //fk_id_campus: '',
      //fk_id_user: '',
      name: '',
      description: '',

     /**
       * Aux messages
       */
      fk_id_program_typeMessage: '',
      courseMessage: '', 
      descriptionMessage: '', 

    course: this.emptyCourse,
    courses:null,
    selectedProgramType: null,
    resetProgramType:null,
    ids:null,
    globalFilter: null,
    selectedCourses: null,
    courseDialog: false,
    deleteCourseDialog: false,
    deleteCoursesDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveCourse = this.saveCourse.bind(this);
  this.deleteCourse = this.deleteCourse.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editCourse = this.editCourse.bind(this);
  this.confirmDeleteCourse= this.confirmDeleteCourse.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedCourses = this.deleteSelectedCourses.bind(this);
  this.hideDeleteCourseDialog = this.hideDeleteCourseDialog.bind(this);
  this.hideDeleteCoursesDialog = this.hideDeleteCoursesDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onProgramTypeChange = this.onProgramTypeChange.bind(this);

  }


    

      componentDidMount() {
        this.getCourses()

      }

      isValid(){
        const { fk_id_program_type,course, description } = this.state.course
              const fk_id_program_typeMessage = this.validateProgramType()
              const courseMessage = this.validateCourse()
              const descriptionMessage = this.validateDescription()

        
              if(fk_id_program_type && course && description ){
                if(!fk_id_program_typeMessage && !courseMessage && !descriptionMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
      validateProgramType() {
              const course = this.state.course
              
              let fk_id_program_typeMessage;
              if (!course.fk_id_program_type) {
                fk_id_program_typeMessage = 'Requerido';
              } else {
                fk_id_program_typeMessage = ''
              }
              this.setState({ fk_id_program_typeMessage })
              return fk_id_program_typeMessage
            }

            validateCourse() {
              const course= this.state.course
              
              let courseMessage;
              if (!course.course) {
                courseMessage = 'Requerido';
              } else {
                courseMessage = ''
              }
              this.setState({ courseMessage })
              return courseMessage
            }



       validateDescription() {
              const course = this.state.course
              
              let descriptionMessage;
              if (!course.description) {
                descriptionMessage = 'Requerido';
              } else {
                descriptionMessage = ''
              }
              this.setState({ descriptionMessage })
              return descriptionMessage
            }



        getTextAuxProgramType(){
              const message = this.state.fk_id_program_typeMessage
              return message
            }
        
            getTextAuxCourse(){
              const message = this.state.courseMessage
               return message
             }
      
        getTextAuxDescription(){
             const message = this.state.descriptionMessage
              return message
            }

      getProgramTypes = async () =>{
         
        try {
           
            let res = axios.get(`${url}/api/auth/program_types`)
            .then(res => {
              const program_types= res.data;
              this.setState({ program_types })
            });                     
              console.log(res.data)
            }catch(error){

        }
      }

      getCourses = async () =>{
         
          try {
             
              let res = axios.get(`${url}/api/auth/courses`)
              .then(res => {
                const courses = res.data; 
                console.log(courses)           
                this.setState({ courses })
              });                     
                console.log(res.data)
              }catch(error){
         }
      }

      deleteCourse(){
        let data = {...this.state.course};

        try {
        let res = axios.delete(`${url}/api/auth/course/${data.id}`)
          .then(res => {
          this.getCourses()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteCourseDialog: false,
            course: this.emptyCourse
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Course Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveCourse() {
        let state = { submitted: true };

        //if (this.state.course.course.trim()) {  
          
          const decision = this.isValid();

          if (decision) {
          let data = {...this.state.course};


        if (this.state.course.id) {

            try {
              let res = axios.put(`${url}/api/auth/course/${data.id}`,data)
              .then(res =>{
              this.getCourses()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Course Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/course`,data)
             .then(res => {
                 this.getCourses()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Course Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          courseDialog: false,
          course: this.emptyCourse,
          selectedProgramType: null,
          resetProgramType:null
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.getProgramTypes()
        this.setState({
            course: this.emptyCourse,
            selectedProgramType: null,
            resetProgramType:"Program Type",
            submitted: false,
            courseDialog: true
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let course = {...this.state.course};
        course[`${name}`] = val;

        this.setState({ course});
      }

      onProgramTypeChange(e) {
        let course = {...this.state.course};
        course['fk_id_program_type'] = e.value;
        this.setState({ selectedProgramType: e.value, course });      
      }



      hideDialog() {
          this.setState({
              submitted: false,
              courseDialog: false,
              program_typeMessage: '',
              courseMessage:'',
              descriptionMessage: '',
        })  
      } 


      editCourse(course) {
        this.getProgramTypes()
          this.setState({
              course: { ...course },
              selectedProgramType: null,
              resetProgramType:null,  
              courseDialog: true
          })

      }

      confirmDeleteCourse(course) {
        this.setState({
            course,
            deleteCourseDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteCoursesDialog: true })
      }

      deleteSelectedCourses() {
        let courses = this.state.courses.filter(val => !this.state.selectedCourses.includes(val));
        let data = this.state.courses.filter(val => this.state.selectedCourses.includes(val));


        try {
        let res = axios.delete(`${url}/api/auth/courses`,{data})
          .then(res => {
          this.getCourses()
        })
        if (!res.error) {
          this.setState({
            courses,
            deleteCoursesDialog: false,
            selectedCourses: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Courses Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteCourseDialog() {
        this.setState({ deleteCourseDialog: false })
      }
        
      hideDeleteCoursesDialog() {
        this.setState({ deleteCoursesDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
     

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editCourse(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteCourse(rowData)} />
            </>
        )
      }
      
      




      render(){
       const deleteCourseDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCourseDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteCourse} />
        </>
       );

       const CourseDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveCourse} />}
            </FormattedMessage>
        </>
       );

       const deleteCoursesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCoursesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedCourses} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		        <FormattedMessage id="ADMIN_COURSE.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_COURSE.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedCourses || !this.state.selectedCourses.length} />}
                      </FormattedMessage>
                    </div>

                   <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_COURSE">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div> 

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>

          
          <div>
           {/*SHOW COURSE */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.courses} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Course found." loading={this.state.loading}
                        selection={this.state.selectedCourses} onSelectionChange={e => this.setState({ selectedCourses: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="program_type" header={this.state.campusText.ProgramType}  filter filterPlaceholder={this.state.campusText.SearchByProgamType} sortable></Column>
                        <Column field="course" header={this.state.campusText.Course} filter filterPlaceholder={this.state.campusText.SearchByCourse} sortable></Column>
                        <Column field="description" header={this.state.campusText.Description} filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE CAMPUS */}
          <Dialog visible={this.state.deleteCourseDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCourseDialogFooter} onHide={this.hideDeleteCourseDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.course && <span> Are you sure you want to delete <b>{this.state.course.name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteCoursesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCoursesDialogFooter} onHide={this.hideDeleteCoursesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.course && <span>Are you sure you want to delete the selected courses?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE COURSE & CREATE COURSE*/}
          <Dialog visible={this.state.courseDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"170px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_COURSE"/>} modal footer={CourseDialogFooter} onHide={this.hideDialog}>
          <br></br>
          <div className="form-group row">
              {/* Progrm Type */}
              <div className="col-lg-6">    
              <span className="p-float-label">              
                <Dropdown
                  style={{ width: 250, height:33 }}
                  id="program_type"
                  variant="outlined"
                  placeholder= { this.state.resetProgramType || this.state.course.program_type}
                  margin="dense"
                  value={this.state.selectedProgramType || "program_type"}   
                  options ={this.state.program_types} 
                  onChange={this.onProgramTypeChange} 
                  optionLabel="program_type" 
                  optionValue="id"
                  />
              <label htmlFor="program_type">Program</label>
              </span>
              <small id="program_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxProgramType()}</small>
              </div>
              {/* Course */}
              <div className="col-lg-6">
              <span className="p-float-label">              
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="course"
                  variant="outlined"
                  margin="dense"
                  value={this.state.course.course}             
                  onChange={(e) => this.onInputChange(e, 'course')} 
                  keyfilter="alpha" 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.course.course  })}
                  />
              <label htmlFor="course">Course</label>
              </span>  
              <small id="course-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxCourse()}</small>  
              </div>
              </div>
                  {/* Description*/}
                  <br></br>
                  <div className="form-group row">
                  <div className="col-lg-6">
                  <span className="p-float-label">              
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="description"
                  variant="outlined"
                  margin="dense"
                  value={this.state.course.description}             
                  onChange={(e) => this.onInputChange(e, 'description')} 
                  keyfilter="alpha" 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.course.description  })}
                  />
              <label htmlFor="description">Description</label>
              </span>
              <small id="description-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription()}</small>  
              </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(CoursesTable);