import React, { Component } from 'react';
import axios from 'axios'
import { connect } from 'react-redux';
import ServerURL from '../../../ServerURL'
import SVG from "react-inlinesvg";
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { FormattedMessage, injectIntl } from "react-intl";
import { ProgressSpinner } from 'primereact/progressspinner';

const url = ServerURL()

class DataAgency extends Component {

}

export default DataAgency