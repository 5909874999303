import React from "react";
import {useSubheader} from "../../_metronic/_core";

import AdminPaymentsTable from "../modulesAccounting/Payments/AdminPaymentsTable"

export const AdminPaymentsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Payments");

  return (<><AdminPaymentsTable /></>);
};