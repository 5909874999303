import React, {Component} from 'react'
import classNames from 'classnames';
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { connect } from "react-redux";
//import { FileUpload } from 'primereact/fileupload';



import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import * as Validation from '../../../Validation';



const url = ServerURL();


class ListMarketeer extends Component {
            
  emptyUser = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  fullname:'',
  phone:'',
  fk_id_role: 60,
  fk_id_enterprise: this.props.my_user.fk_id_enterprise,
  flphoto:'',
  }

  


  constructor(props) {
    super(props)


    this.state = {
      /**
       * Aux messages
       */
      emailMessage: '',
      passwordMessage: '',
      firstNameMessage: '',
      lastNameMessage: '',
      phoneMessage: '',
        
        
        
      user: this.emptyUser,
      users:null,
      ids:null,
      selectedRole: null,
      selectedEnterprise:null,
      selectedRoleType: null,
      globalFilter: null,
      selectedUsers: null,
      userDialog: false,
      disableUserDialog: false,
      enableUserDialog: false,
      disableUsersDialog: false,
      enableUsersDialog: false,
      assignUsersDialog: false,
      statusUser:1,
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate()
    }
  
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveUser = this.saveUser.bind(this);
  this.disableUser = this.disableUser.bind(this);
  this.enableUser = this.enableUser.bind(this);
  this.openNew = this.openNew.bind(this);
  this.userBodyTemplate = this.userBodyTemplate.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editUser = this.editUser.bind(this);
  this.confirmDisableUser= this.confirmDisableUser.bind(this);
  this.confirmEnableUser= this.confirmEnableUser.bind(this);
  this.confirmDisableSelected = this.confirmDisableSelected.bind(this);
  this.confirmEnableSelected = this.confirmEnableSelected.bind(this);
  this.disableSelectedUsers = this.disableSelectedUsers.bind(this);
  this.enableSelectedUsers = this.enableSelectedUsers.bind(this);
  this.hideDisableUserDialog = this.hideDisableUserDialog.bind(this);
  this.hideEnableUserDialog = this.hideEnableUserDialog.bind(this);
  this.hideDisableUsersDialog = this.hideDisableUsersDialog.bind(this);
  this.hideEnableUsersDialog = this.hideEnableUsersDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onPhoto = this.onPhoto.bind(this);

  this.id_director = 57;

}

componentDidMount() {
  this.getUsers()
}

getUsers = async () =>{
  try {
    await axios.get(`${url}/api/auth/usersm/${this.state.user.fk_id_enterprise}`)
    .then(res => {
      const users = res.data;
      this.setState({ users, statusUser:1 })
    });                     
    }catch(error){
  }
}

getUsersDisabled= async () =>{   
  try {
      let res = axios.get(`${url}/api/auth/usersDisabled`)
      .then(res => {
        const users = res.data;
        this.setState({ users, statusUser:0 })
      });                     
        console.log(res.data)
      }catch(error){

 }
}

isValid(){
  const { email, password, first_name, last_name, phone, fk_id_role } = this.state.user

  const emailMessage = Validation.validateEmail(this.state)
  this.setState({ emailMessage })
  const passwordMessage = Validation.validatePassword(this.state)
  this.setState({ passwordMessage })
  const firstNameMessage = Validation.validateFirstName(this.state)
  this.setState({ firstNameMessage })
  const lastNameMessage = Validation.validateLastName(this.state)
  this.setState({ lastNameMessage })
  const phoneMessage = Validation.validatePhone(this.state)
  this.setState({ phoneMessage })

  // if(email && password && first_name && last_name && phone && photo && fk_id_role && fk_id_enterprise){
  if(email && password && first_name && last_name && phone && fk_id_role){
    if(!emailMessage && !passwordMessage && !firstNameMessage && !lastNameMessage && !phoneMessage){
      return true;
    }else{
      return false;
    }
  }
  return false;
} 

// async deleteUser(){
//   let data = {...this.state.user};

//   try {
//     await axios.delete(`${url}/api/auth/user/${data.id}`)
//       .then(res => {
//       this.getUsers()
//       this.setState({
//         data,
//         deleteUserDialog: false,
//         user: this.emptyUser
//       });
//       this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: 'User Deleted', life: 3000 });
//       console.log(res)
//     })
//   }catch(error){
//   } 
// }      

async disableUser(){
  let data = {...this.state.user};

  try {
  let res = await axios.put(`${url}/api/auth/disable_user/${data.id}`)
    .then(res => {
    this.getUsers()
  })
  console.log(res)
  //if (!res.error) {
    this.setState({
      data,
      disableUserDialog: false,
      user: this.emptyUser,

  });
  this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_DISABLED'}), life: 3000 });

  //}
    }catch(error){
    }           
}      

async enableUser(){
  let data = {...this.state.user};

  try {
  let res = await axios.put(`${url}/api/auth/enable_user/${data.id}`)
    .then(res => {
    this.getUsersDisabled()
  })
  console.log(res)
  //if (!res.error) {
    this.setState({
      data,
      enableUserDialog: false,
      user: this.emptyUser,

  });
  this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_ENABLED'}), life: 3000 });

  //}
    }catch(error){
    }           
}

async saveUser() {
  let state = { submitted: true };
  const decision = this.isValid();
  if (decision) {
    let data = {...this.state.user};

    const formData = new FormData();
    formData.append('flphoto',this.uploadInput.files[0]);
    formData.append('first_name',data.first_name);
    formData.append('last_name',data.last_name);
    formData.append('email',data.email);
    formData.append('password',data.password);
    formData.append('phone',data.phone);
    formData.append('fk_id_role',data.fk_id_role);      
    formData.append('fk_id_enterprise',data.fk_id_enterprise);      
    formData.append('learning', 1);     

    const config = {
      headers: {
    
      'Content-Type':'multipart/form-data'}
      //,body:JSON.stringify(data)
    }

    if (this.state.user.id) {
      try {
        await axios.put(`${url}/api/auth/user/${data.id}`,formData,config)
        .then(res =>{
          this.getUsers()
          this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_UPDATED'}), life: 3000 });
        })
        .catch(res => {
          // this.toast.show({ severity: 'error', summary: 'Error', detail: 'User not created', life: 3000 });
        })
      }catch(error){          
      }         

    }else{
      try {
        await axios.post(`${url}/api/auth/user/`,formData, config)
          .then(res => {
              this.getUsers()
              this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_CREATED'}), life: 3000 });
          })
          .catch(res => {
            // this.toast.show({ severity: 'error', summary: 'Error', detail: 'User not created', life: 3000 });
          })
      }catch(error){
      }   
    }
    state = {
      ...state,
      data,
      userDialog: false,
      user: this.emptyUser,
      selectedRole: null,
      selectedEnterprise: null,
      selectedRoleType: null,    
    }; 
  }
  this.setState(state);
}
  
openNew() {
  this.setState({
    user: this.emptyUser,
    selectedRole: null,
    selectedEnterprise: null,
    selectedRoleType: null,
    submitted: false,
    userDialog: true,
  })
}

onInputChange(e, name) {
  const val = (e.target && e.target.value) || '';
  let user = {...this.state.user};
  user[`${name}`] = val;

  this.setState({ selectedRole: e.value,selectedEnterprise: e.value,user });
}


hideDialog() {
    this.setState({
      submitted: false,
      userDialog: false,
      emailMessage: '',
      passwordMessage: '',
      firstNameMessage: '',
      lastNameMessage: '',
      phoneMessage: '',
      fkIdRoleMessage: ''
  })  
} 


editUser(user) {
  this.setState({
    selectedRole: null,
    selectedEnterprise: null,
    selectedRoleType: null,        
      user: { ...user },   
      userDialog: true
  })
}

confirmEnableUser(user) {
  this.setState({
      user,
      enableUserDialog: true
  })
}

confirmDisableSelected() {
  this.setState({ disableUsersDialog: true })
}



confirmEnableSelected() {
  this.setState({ enableUsersDialog: true })
}

confirmDisableUser(user) {
  this.setState({
      user,
      disableUserDialog: true
  })
}

disableSelectedUsers() {
  let users = this.state.users.filter(val => !this.state.selectedUsers.includes(val));
  let data = this.state.users.filter(val => this.state.selectedUsers.includes(val));

  console.log("DATA:",data)

  try {
  
  let res = axios.put(`${url}/api/auth/disable_users`,{data})
    .then(res => {
    this.getUsers()
  })
  if (!res.error) {
    this.setState({
      users,
      disableUsersDialog: false,
      selectedUsers: null
  });
  this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USERS_DISABLED'}), life: 3000 });
  }
    }catch(error){
    } 
}

enableSelectedUsers() {
  let users = this.state.users.filter(val => !this.state.selectedUsers.includes(val));
  let data = this.state.users.filter(val => this.state.selectedUsers.includes(val));


  try {
  
  let res = axios.put(`${url}/api/auth/enable_users`,{data})
    .then(res => {
    this.getUsersDisabled()
  })
  if (!res.error) {
    this.setState({
      users,
      enableUsersDialog: false,
      selectedUsers: null
  });
  this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USERS_ENABLED'}), life: 3000 });
  }
    }catch(error){
    } 
}

hideDisableUserDialog() {
  this.setState({ disableUserDialog: false })
}

hideEnableUserDialog() {
  this.setState({ enableUserDialog: false })
}
  
hideDisableUsersDialog() {
  this.setState({ disableUsersDialog: false });
}

hideEnableUsersDialog() {
  this.setState({ enableUsersDialog: false });
}
  
exportCSV() {
  this.dt.exportCSV();
}

onPhoto() {
  this.toast.show({severity: 'info', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.FILE_UPDATED'})});
}
  
userBodyTemplate(rowData) {
  return (
    <>
      <Avatar alt={rowData.first_name} src={`${rowData.photo}`} width={32} style={{verticalAlign: 'middle',   margin: "10px", width: "50px", height: "50px",}} />
    </>
  )
}

actionBodyTemplate(rowData) {
  return (
    <>
      <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editUser(rowData)} />
      {
        this.props.my_user.fk_id_role === this.id_director && (
          this.buttonED(rowData)
        )
      }
    </>
  )
}
  
  
buttonED=(rowData)=>{


  let EDView=[]

    if( this.state.statusUser===1){
      EDView.push(
        <Button icon="pi pi-times"  className="p-button-danger"  style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDisableUser(rowData)} />
        )
      }else{
       EDView.push(
        <Button icon="pi pi-check"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmEnableUser(rowData)} />
       )
       }

       return EDView
}

enabledNdisabled=()=>{


  let EDView=[]

    if( this.state.statusUser===1){
      EDView.push(
        <FormattedMessage id="OPTION.DISABLED_USER">
        {(message) => <p><Button  className="p-button-outlined p-button-secondary" type="button" label={message}  onClick={this.getUsersDisabled} variant='contained'/></p>}
        </FormattedMessage>)
      }else{
       EDView.push(
        <FormattedMessage id="OPTION.ENABLED_USER">
        {(message) => <p><Button className="p-button-outlined p-button-secondary"  type="button" label={message}  onClick={this.getUsers} variant='contained'/></p>}
        </FormattedMessage>)
       }

       return EDView
}

toolbarButtonED=()=>{


  let EDView=[]

    if( this.state.statusUser===1){
      EDView.push(
        <FormattedMessage id="GENERAL.DISABLE">
          {(message) => <Button icon="pi pi-times" label={message}  className="p-button-danger" onClick={this.confirmDisableSelected} disabled={!this.state.selectedUsers || !this.state.selectedUsers.length} />}
        </FormattedMessage>)
      }else{
       EDView.push(
        <FormattedMessage id="GENERAL.ENABLE">
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-danger" onClick={this.confirmEnableSelected} disabled={!this.state.selectedUsers || !this.state.selectedUsers.length} />}
        </FormattedMessage>)
       }

       return EDView
}



render(){
  const disableUserDialogFooter = (
    <>
      <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDisableUserDialog} />}
      </FormattedMessage>
      <FormattedMessage id="GENERAL.YES">
        {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.disableUser} />}
      </FormattedMessage>
    </>
  );

  const enableUserDialogFooter = (
    <>
      <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableUserDialog} />}
      </FormattedMessage>
      <FormattedMessage id="GENERAL.YES">
        {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.enableUser} />}
      </FormattedMessage>
    </>
  );

  const userDialogFooter = (
  <>
    <FormattedMessage id="GENERAL.CANCEL">
      {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
    </FormattedMessage>
    <FormattedMessage id="GENERAL.SAVE">
      {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveUser} />}
    </FormattedMessage>
  </>
  );

  const disableUsersDialogFooter = (
    <>
      <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDisableUsersDialog} />}
      </FormattedMessage>
      <FormattedMessage id="GENERAL.YES">
        {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.disableSelectedUsers} />}
      </FormattedMessage>
    </> 
  );

  const enableUsersDialogFooter = (
    <>
      <FormattedMessage id="GENERAL.NO">
        {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableUsersDialog} />}
      </FormattedMessage>
      <FormattedMessage id="GENERAL.YES">
        {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.enableSelectedUsers} />}
      </FormattedMessage>
    </> 
  );


  return(       
    <div>
      <div className= "card-header flex-wrap border-0 pt-6 pb-0">
        <Toast ref={(el) => this.toast = el} />  
        <h3 className="card-label" >
          <FormattedMessage id="LEARNING_GENERAL.MARKETER_LIST"/>
          <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="LEARNING_AGENT_LIST.SUB_TITLE"/></span>
        </h3>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  

          {/*TOOL BAR */} 
          <div style={{ marginRight: 10}}>
            <FormattedMessage id="GENERAL.EXPORT">
              {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
            </FormattedMessage>
          </div>
          {
            this.props.my_user.fk_id_role === this.id_director ?
            <div style={{ marginRight: 10}}>
              {this.toolbarButtonED()}
            </div>
            :
            <></>
          }
          {
            this.props.my_user.fk_id_role === this.id_director ?
            <div style={{ marginRight: 10}}>
              <FormattedMessage id="OPTION.NEW_USER">
                {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
              </FormattedMessage> 
            </div>
            :
            <></>
          }

          <div style={{ marginRight: 10}}>
            {this.enabledNdisabled()}
          </div>

          <div style={{ marginRight: 10}}>
            <p> <span className="p-input-icon-left">
              <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
            </span></p>
          </div>           
        </div>
      </div>

          
          <div>
           {/*SHOW USER */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.users} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USERS_NOT_FOUND'})} loading={this.state.loading}
                        selection={this.state.selectedUsers} onSelectionChange={e => this.setState({ selectedUsers: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="photo" body={this.userBodyTemplate}></Column>
                        <Column field="first_name" header={this.state.campusText.FirstName}   sortable></Column>
                        <Column field="last_name" header={this.state.campusText.LastName}   sortable></Column>
                        <Column field="email" header={this.state.campusText.Email} sortable></Column>
                        <Column field="phone" header={this.state.campusText.Phone}   sortable></Column>
                        <Column field="role_type" header='Role Type'   sortable></Column>
                        <Column field="enterprise_name" header='Enterprise'  sortable></Column>
                        <Column field="role" header='Role'   sortable></Column>
                        {
                          this.props.my_user.fk_id_role === this.id_director || this.props.my_user.owner ?
                          <Column body={this.actionBodyTemplate}></Column>
                          :
                          <></>
                        }
                    </DataTable>
                </div>
           </div>
         

           <div> 
          {/*DISABLE USER */}
          <Dialog visible={this.state.disableUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={disableUserDialogFooter} onHide={this.hideDisableUserDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.user && <span> <FormattedMessage id="GENERAL_MESSAGE.USER_DISABLED_MESSAGE"/> <b>{this.state.user.fullname}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          <div> 
          {/*ENABLE USER */}
          <Dialog visible={this.state.enableUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={enableUserDialogFooter} onHide={this.hideEnableUserDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.user && <span> <FormattedMessage id="GENERAL_MESSAGE.USER_ENABLED_MESSAGE"/> <b>{this.state.user.fullname}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DISABLE WITH TOOL BAR*/}
          <Dialog visible={this.state.disableUsersDialog} style={{ width: '450px' }} header="Confirm" modal footer={disableUsersDialogFooter} onHide={this.hideDisableUsersDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.user && <span><FormattedMessage id="GENERAL_MESSAGE.USERS_DISABLED_MESSAGE"/></span>}
                    </div>
          </Dialog>

          {/*ENABLE WITH TOOL BAR*/}
          <Dialog visible={this.state.enableUsersDialog} style={{ width: '450px' }} header="Confirm" modal footer={enableUsersDialogFooter} onHide={this.hideEnableUsersDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.user && <span><FormattedMessage id="GENERAL_MESSAGE.USERS_ENABLED_MESSAGE"/></span>}
                    </div>
          </Dialog>

          <div>
          {/*UPDATE USER & CREATE USER*/}
          <Dialog visible={this.state.userDialog} contentStyle={{maxHeight:"800px", width:"500px", height:"550px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_USER"/>} modal footer={userDialogFooter} onHide={this.hideDialog}>
          <br></br>
          
          <div className="form-group row">
          <div className="col-lg-6">  
          
          <Avatar  
              size="small" 
              shape="circle"/>
              <input 
              type="file"
              accept="image/x-png,image/jpeg" 
              name="avatar"
              ref={(ref) => { this.uploadInput = ref; }}
              multiple= {false} />
          </div>
          </div>
          <br></br>
          <div className="form-group row">
              {/* Email */}
              <br></br>
              <div className="col-lg-6">  
              <span className="p-float-label">      
                <InputText
                  style={{ width: 190, height:33 }}
                  id="email"
                  variant="outlined"
                  type="email"
                  label="Email"
                  margin="dense"
                  value={this.state.user.email}             
                  onChange={(e) => this.onInputChange(e, 'email')}
                  keyfilter="email"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.email  })}
                  />
                  <label htmlFor="email"><FormattedMessage id="AUTH.INPUT.EMAIL"/></label>
              </span>
              {
                this.state.emailMessage && (
                  <small id="email-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.emailMessage}/></small>
                )
              }
              </div>
              {/* Password */}
              <br></br>
              <div className="col-lg-6">
              <span className="p-float-label">      
                  <InputText
                  style={{ width: 190, height:33 }}
                  id="password"
                  variant="outlined"
                  type="password"
                  margin="dense"
                  value={this.state.user.password}             
                  onChange={(e) => this.onInputChange(e, 'password')}
                  keyfilter={/[^\s]/}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.password  })}
                  />
                  <label htmlFor="password"><FormattedMessage id="AUTH.INPUT.PASSWORD"/></label>
              </span>
              {
                this.state.passwordMessage && (
                  <small id="password-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.passwordMessage}/></small>
                )
              }
              </div>
              </div>
              {/* FirstName */}
              <br></br>
              <div className="form-group row">
              <div className="col-lg-6">
              <span className="p-float-label">      
                <InputText
                  style={{ width: 190, height:33 }}
                  id="first_name"
                  variant="outlined"
                  margin="dense"
                  value={this.state.user.first_name}             
                  onChange={(e) => this.onInputChange(e, 'first_name')}
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.first_name  })}
                  />
                  <label htmlFor="first_name"><FormattedMessage id="AUTH.INPUT.FIRST_NAME"/></label>
              </span>
              {
                this.state.firstNameMessage && (
                  <small id="first_name-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.firstNameMessage}/></small>
                )
              }
              </div>
                  {/* Last Name*/}
                  <br></br>
                  <div className="col-lg-6">
                  <span className="p-float-label">      
                  <InputText
                  style={{ width: 190, height:33 }}
                  id="last_name"
                  variant="outlined"
                  margin="dense"
                  value={this.state.user.last_name}             
                  onChange={(e) => this.onInputChange(e, 'last_name')}
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.last_name  })}
                  />
                  <label htmlFor="last_name"><FormattedMessage id="AUTH.INPUT.LAST_NAME"/></label>
              </span>
              {
                this.state.lastNameMessage && (
                  <small id="last_name-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.lastNameMessage}/></small>
                )
              }
              </div>          
              </div>
                  {/* Phone*/}
                  <br></br>
                  <div className="form-group row">
                  <div className="col-lg-6">
                  <span className="p-float-label">      
                  <InputText
                  style={{ width: 190, height:33 }}
                  id="phone"
                  type="phone"
                  variant="outlined"
                  margin="dense"
                  value={this.state.user.phone}             
                  onChange={(e) => this.onInputChange(e, 'phone')}
                  keyfilter="pnum"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.phone  })}
                  />
                  <label htmlFor="phone"><FormattedMessage id="ADMIN_GENERAL.PHONE"/></label>
              </span>
              {
                this.state.phoneMessage && (
                  <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.phoneMessage}/></small>
                )
              }
              </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}

function mapStateToProps(state) {
  return {
    my_user: state.auth.user
  };
}

export default injectIntl(
  connect(mapStateToProps)(ListMarketeer)
)