//AsideMenuListDG - Wiyu
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
//import { useIntl } from "react-intl";

export function AsideMenuListDG({ layoutProps }) {
  //const int1 = useIntl();
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  const id_agente = 64;
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* WIYU */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text text-uppercase">Admin WIYU</span>
            <i className="menu-arrow" />
          </NavLink>

          {/* Analytics */}
          <div className="menu-submenu">
            <div className="menu-arrow">
              <ul className="menu-subnav">
                <li className="menu-section ">
                  <h4 className="menu-text text-white-alpha-90 text-base">ANALYTICS</h4>
                  <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* Analytics */}
                <li className={`menu-item ${getMenuItemActive("/wiyu-analytics-rates", false)}`}
                  aria-haspopup='true'>
                  <NavLink className="menu-link menu-toggle" to='/wiyu-analytics-rates'>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
                    </span>
                    <span className="menu-text">Dashboard</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          {/* Programs */}

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* Estudiantes */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base">Students</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/wiyu-students", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to="/wiyu-students">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Student list</span>
                </NavLink>
              </li>
              {/* end:: section */}

              {/* DOCUMENTACION */}
              {/* <li
                      className={`menu-item ${getMenuItemActive("/wiyu-documents", false)}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/wiyu-documents">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
                    </span>
                      <span className="menu-text">Documentación</span>
                    </NavLink>
                  </li> */}
            </ul>
          </div>



          {/* Grupos */}
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">

              <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base">Students groups</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/wiyu-groups", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to="/wiyu-groups">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Students groups list</span>
                </NavLink>
              </li>

            </ul>
          </div>

          {/* Eventos */}
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base">Notification templates</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/wiyu-events", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to="/wiyu-events">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-notification.svg")} />
                  </span>
                  <span className="menu-text">Notification templates list</span>
                </NavLink>
              </li>
              {/* end:: section */}
            </ul>
          </div>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* Customer success */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base">Customer success</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/wiyu-customer-success", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to="/wiyu-customer-success">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
                  </span>
                  <span className="menu-text">Customer success admin</span>
                </NavLink>
              </li>
              {/* end:: section */}
            </ul>
          </div>

          <div className="menu-submenu">
            <div className="menu-arrow">
              <ul className="menu-subnav">
                <li className="menu-section">
                  <h4 className="menu-text text-white-alpha-90 text-base">Notifications</h4>
                  <i className="menu-icon flaticon-more-v2"></i>
                </li>

                {/* Create notifications */}
                <li className={`menu-item ${getMenuItemActive("/wiyu-create-notifications", false)}`}
                  aria-haspopup='true'>
                  <NavLink className="menu-link menu-toggle" to='/wiyu-create-notifications'>
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
                    </span>

                    <span className="menu-text">Create notification script</span>
                  </NavLink>
                </li>

                {/* Send notifications */}
                <li className={`menu-item ${getMenuItemActive("/prueba2", false)}`}
                  aria-haspopup='true'>
                  {/* <NavLink className="menu-link menu-toggle" to='/wiyu-send-notifications'> */}
                  <NavLink className="menu-link menu-toggle" to='/prueba2'>

                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
                    </span>

                    <span className="menu-text">Update notification script</span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </div>

        </li>
      </ul>
    </>
  );
}
