import React, {Component} from 'react'


import axios from 'axios'
import ServerURL from '../../../ServerURL'
import classNames from 'classnames';
import { TabView, TabPanel } from 'primereact/tabview';
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact';


import 'primeflex/primeflex.css';

import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const url = ServerURL();

class TransfersTable extends Component {


  emptyTransfer={
    invoiceId:null,
    fk_id_balanceItem:null,
    fk_id_currency:null,
    fk_id_commissionType:null,
    transferTotal:null,
    commission:null,
    commissionPercentage:null,
    transferBalance:null,
    status:null,
    enable:null,
  }         
  
  emptyPaymentTransfer={
  fk_id_transfer_id:null,
  originAccount:null,
  paymentDate:null,
  scheduleDate:null,
  fk_id_paymentTransferMethod:null,
  paymentAmount:null,
  paymentEquivalentAmount:null,
  rate:null,
  currencyExchangeOffice:null,
  status:null,
  }         


constructor(props) {
  super(props)

  this.columns = [
    {field: 'externalId', header: "Invoice Id" },
    // {field: 'created_at', header: 'Created at'},
    {field: 'agency', header: 'Office'},
    // {field: 'agent', header: 'Agent'},
    {field: 'provider', header: 'Provider'},
    {field: 'student', header: 'Student'},
    // {field: 'symbolAmount', header: 'Total'},
    // {field: 'statusBalance', header: 'Validate' },
    // {field: <Column body={this.actionBodyTemplate}></Column>}
    // {field: 'agent', header: 'Agent'},

  ]

  this.state = { 
    invoiceTransferDialog:false,
    paymentTransferDialog:false,

    filterInputDate: null,
     /*
       * Aux messages
       */
     transferTotalMessage: '',

     fk_id_transfer_idMessage:'',
     originAccountMessage:'',
     paymentDateMessage:'',
     fk_id_paymentTransferMethodMessage:'',
     paymentAmountMessage:'',
     rateMessage:'',
     currencyExchangeOfficeMessage:'',

     messageSchedulePayments:'',
     
     
    
      
   
    disableTransferDialog: false,
    enableTransferDialog: false,
    disableTransfersDialog: false,
    schedulePaymentsDialog:false,
    schedulePaymentValidationDialog:false,
    enableTransfersDialog: false,
    enableTransfer:1,
    activeIndex:0,
    scheduleDate: null,
    deletePaymentDialog: false,
    deletePaymentsDialog: false,
    
  
    globalFilter: null,
    selectedTransfers: null,
    selectedPayments: null,
    transferDialog: false,
    scheduleTransferDialog: false,
      
    selectedAccount: null, 
    resetAccount:null, 
    selectedMethod: null, 
    resetMethod:null, 
    selectedExchangeOffice: null, 
    resetExchangeOffice:null, 
    selectedCommissionType:null,
    resetCommissionType:null,
    /*showCheckBox: false,
    CheckedApprove:false,*/

    transfer: this.emptyTransfer,
    transfers:null,
    paymentTransfer: this.emptyPaymentTransfer,
    payments:null,

    showTransfers:null,
    showTransferPayments:null,
    
    redirect: false,

    statusTransfers:1,

    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate(),

    selectedColumns: this.columns
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.paymentsActionBodyTemplate = this.paymentsActionBodyTemplate.bind(this)
  this.scheduleDateActionBodyTemplate = this.scheduleDateActionBodyTemplate.bind(this);
  this.columnInvoiceTransfer = this.columnInvoiceTransfer.bind(this);
  this.disableTransfer = this.disableTransfer.bind(this);
  this.enableTransfer = this.enableTransfer.bind(this);
  this.confirmDeletePayment= this.confirmDeletePayment.bind(this);
  this.deletePayment = this.deletePayment.bind(this);


  this.openNew = this.openNew.bind(this);
  this.openSchedule = this.openSchedule.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.confirmDisableTransfer= this.confirmDisableTransfer.bind(this);
  this.confirmDisableSelected = this.confirmDisableSelected.bind(this);
  this.confirmEnableTransfer= this.confirmEnableTransfer.bind(this);
  this.confirmEnableSelected = this.confirmEnableSelected.bind(this);
  this.confirmDeletePayment = this.confirmDeletePayment.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.confirmSchedulePaymentsSelected = this.confirmSchedulePaymentsSelected.bind(this);
  this.confirmSchedulePaymentValidation = this.confirmSchedulePaymentValidation.bind(this);


  this.disableSelectedTransfers = this.disableSelectedTransfers.bind(this);
  this.enableSelectedTransfers = this.enableSelectedTransfers.bind(this);
  this.hideDisableTransferDialog = this.hideDisableTransferDialog.bind(this);
  this.hideDisableTransfersDialog = this.hideDisableTransfersDialog.bind(this);
  this.hideEnableTransferDialog = this.hideEnableTransferDialog.bind(this);
  this.hideEnableTransfersDialog = this.hideEnableTransfersDialog.bind(this);
  this.hideDeletePaymentDialog = this.hideDeletePaymentDialog.bind(this);
  this.hideDeletePaymentsDialog = this.hideDeletePaymentsDialog.bind(this);
  this.hideSchedulePaymentValidationDialog = this.hideSchedulePaymentValidationDialog.bind(this);


  this.hideInvoiceTransferDialog = this.hideInvoiceTransferDialog.bind(this);
  this.hidePaymentTransferDialog = this.hidePaymentTransferDialog.bind(this);
  this.hideScheduleTransferDialog = this.hideScheduleTransferDialog.bind(this);
  this.hideSchedulePaymentsDialog = this.hideSchedulePaymentsDialog.bind(this);

  this.getExchangeRates = this.getExchangeRates.bind(this);
  this.onAccountChange = this.onAccountChange.bind(this);
  this.onDateChange = this.onDateChange.bind(this);
  this.onScheduleDateChange = this.onScheduleDateChange.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onMethodChange = this.onMethodChange.bind(this);
  this.saveInvoiceTransfer = this.saveInvoiceTransfer.bind(this);
  this.createScheduleTransfer = this.createScheduleTransfer.bind(this);
  this.createPaymentTransfer = this.createPaymentTransfer.bind(this);
  this.onRateChange = this.onRateChange.bind(this);
  this.onAmountChange = this.onAmountChange.bind(this);
  this.onExchangeOfficeChange = this.onExchangeOfficeChange.bind(this);
  this.dateBodyTemplate = this.dateBodyTemplate.bind(this);
  this.filterDate = this.filterDate.bind(this);
  this.onFilterDateChange = this.onFilterDateChange.bind(this);
  this.onIndexChange = this.onIndexChange.bind(this);
  this.onScheduleAmountChange = this.onScheduleAmountChange.bind(this);
  this.deleteSelectedPayments = this.deleteSelectedPayments.bind(this);
  this.schedulePaymentsSelected = this.schedulePaymentsSelected.bind(this);
  this.onSortStartDate = this.onSortStartDate.bind(this);
  this.onSortPaymentDate = this.onSortPaymentDate.bind(this);
  this.onSortScheduleDate = this.onSortScheduleDate.bind(this);
  this.onColumnToggle = this.onColumnToggle.bind(this);


  }
  
  componentDidMount() {
    this.getTransfers()
    this.getInvoice()
  }

  isValidSchedulePayments(){
    const { scheduleDate} = this.state.paymentTransfer
          const scheduleDateMessage = this.validateScheduleDate()
      
          if(scheduleDate){
            if(!scheduleDateMessage){
              return true;
            }else{
              return false;
            }
          }
          return false;
  }



  isValidSchedulePayment(){
    const { scheduleDate, paymentEquivalentAmount } = this.state.paymentTransfer
          const scheduleDateMessage = this.validateScheduleDate()
          const paymentEquivalentAmountMessage = this.validatePaymentEquivalentAmount()
      
          if(scheduleDate && paymentEquivalentAmount){
            if(!scheduleDateMessage && !paymentEquivalentAmountMessage){
              return true;
            }else{
              return false;
            }
          }
          return false;
  }

  
    validatePaymentEquivalentAmount() {
    const paymentTransfer = {...this.state.paymentTransfer}
    const transfer = {...this.state.transfer}

    let paymentEquivalentAmountMessage;

    if (!paymentTransfer.paymentEquivalentAmount) {
      paymentEquivalentAmountMessage = 'Requerido'
    }else if (paymentTransfer.paymentEquivalentAmount>transfer.transferBalance) {
      paymentEquivalentAmountMessage = `El monto programado no puede ser mayor a ${transfer.transferBalance} ${transfer.code}`
    }else if (paymentTransfer.paymentEquivalentAmount<0) {
      paymentEquivalentAmountMessage = 'El monto programado no puede ser menor a 0'
    }else {
      paymentEquivalentAmountMessage = ''
    }
    this.setState({ paymentEquivalentAmountMessage })

    return paymentEquivalentAmountMessage
   }

        getTextAuxPaymentEquivalentAmount(){
         const message = this.state.paymentEquivalentAmountMessage
          return message
        }

        
        validateScheduleDate() {
          const paymentTransfer = this.state.paymentTransfer
          
          let scheduleDateMessage;

          if (!paymentTransfer.scheduleDate){
            scheduleDateMessage = 'Requerido'
          } else {
            scheduleDateMessage = ''
          }
          this.setState({scheduleDateMessage })

          return scheduleDateMessage
        }
  
        getTextAuxScheduleDate(){
              const message = this.state.scheduleDateMessage
                return message
        }





      isValidTransferTotal(){
        const { transferTotal } = this.state.transfer
              const transferTotalMessage = this.validateTransferTotal()
          
              if(transferTotal){
                if(!transferTotalMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
        }

        validateTransferTotal() {
          const transfer = this.state.transfer
          const balance = this.state.balances 
  
          let transferTotalMessage;
          if (!transfer.transferTotal) {
            transferTotalMessage = 'Requerido'
          }else if (transfer.transferTotal>balance.total) {
            transferTotalMessage = `Transferencia total no puede ser mayor a ${balance.symbolTotalCurrency}`
          }else if (transfer.transferTotal<0) {
            transferTotalMessage = 'Transferencia total no puede ser menor a 0'
          }else {
            transferTotalMessage = ''
          }
          this.setState({ transferTotalMessage })
          return transferTotalMessage
        }
  
        
        getTextAuxTransferTotal(){
               const message = this.state.transferTotalMessage
                return message
              }

              isValid(){
                const { originAccount, paymentDate, fk_id_paymentTransferMethod, paymentAmount, rate} = this.state.paymentTransfer
                      const originAccountMessage = this.validateOriginAccount()
                      const paymentDateMessage = this.validatePaymentDate()
                      const fk_id_paymentTransferMethodMessage = this.validateFk_id_paymentTransferMethod()
                      const paymentAmountMessage = this.validatePaymentAmount()
                      const rateMessage = this.validateRate()

        
                      if(originAccount && paymentDate && fk_id_paymentTransferMethod && paymentAmount && rate){
                        if(!originAccountMessage && !paymentDateMessage && !fk_id_paymentTransferMethodMessage && !paymentAmountMessage  && !rateMessage){
                          return true;
                        }else{
                          return false;
                        }
                      }
                      return false;
                    }

                    
                  
        
                    validateOriginAccount() {
                      const paymentTransfer = this.state.paymentTransfer
                      
                      let originAccountMessage
                      if (!paymentTransfer.originAccount) {
                        originAccountMessage = 'Requerido'
                      } else {
                        originAccountMessage = ''
                      }
                      this.setState({ originAccountMessage })
                      return originAccountMessage
                    }
              
                    getTextAuxOriginAccount(){
                          const message = this.state.originAccountMessage
                            return message
                    }
        
                    validatePaymentDate() {
                      const paymentTransfer = this.state.paymentTransfer
                      
                      let paymentDateMessage
                      if (!paymentTransfer.paymentDate) {
                        paymentDateMessage = 'Requerido'
                      } else {
                        paymentDateMessage = ''
                      }
                      this.setState({ paymentDateMessage })
                      return paymentDateMessage
                    }
              
                    getTextAuxPaymentDate(){
                          const message = this.state.paymentDateMessage
                            return message
                    }
        
                    validateFk_id_paymentTransferMethod() {
                      const paymentTransfer = this.state.paymentTransfer
                      
                      let fk_id_paymentTransferMethodMessage
                      if (!paymentTransfer.fk_id_paymentTransferMethod) {
                        fk_id_paymentTransferMethodMessage = 'Requerido'
                      } else {
                        fk_id_paymentTransferMethodMessage = ''
                      }
                      this.setState({ fk_id_paymentTransferMethodMessage })
                      return fk_id_paymentTransferMethodMessage
                    }
              
                    getTextAuxFk_id_paymentTransferMethod(){
                          const message = this.state.fk_id_paymentTransferMethodMessage
                            return message
                    }
        
                    validatePaymentAmount() {
                      const paymentTransfer = this.state.paymentTransfer
                      const transfer = {...this.state.transfer}

                      let paymentAmountMessage
                      if (!paymentTransfer.paymentAmount) {
                        paymentAmountMessage = 'Requerido'
                      }else if(transfer.transferBalance < (paymentTransfer.paymentAmount/paymentTransfer.rate).toFixed(2)){
                        paymentAmountMessage = `El pago no puede ser mayor a ${transfer.symbolBalanceTransferCurrency}`
                      }else {
                        paymentAmountMessage = ''
                      }
                      this.setState({ paymentAmountMessage })
                      return paymentAmountMessage
                    }
              
                    getTextAuxPaymentAmount(){
                          const message = this.state.paymentAmountMessage
                            return message
                    }
        
                    validateRate() {
                      const paymentTransfer = this.state.paymentTransfer
                      
                      let rateMessage;
                      if (!paymentTransfer.rate) {
                        rateMessage = 'Requerido'
                      } else {
                        rateMessage = ''
                      }
                      this.setState({ rateMessage })
                      return rateMessage
                    }
              
                    getTextAuxRate(){
                          const message = this.state.rateMessage
                            return message
                    }
 
               
               getTransfers = async () =>{
       
                try {
                   
                   let res = axios.get(`${url}/api/auth/transferPending/${this.props.invoice}`)
                    .then(res => {
                      const transfers = res.data;
                      this.DateTransfer(transfers)
                      this.symboltoTransfersCurrency(transfers)
                      this.symbolBalanceTransfersCurrency(transfers)
                      this.symbolCommissionCurrency(transfers)
                      this.statusBalance(transfers)

                      this.setState({ transfers, statusTransfers:1, enableTransfer:1, selectedColumns: []})
                    });                     
                      console.log(res.data)
                    }catch(error){
               }
              }
              
              getInvoice = async () => {
                try {
                  let res = axios.get(`${url}/api/auth/invoice/${this.props.invoice}`)
                  .then(res => {
                    const invoice = res.data
                    // console.log("INVOICE: ", invoice);
                    this.DateInvoice(invoice)
                    this.setState({ invoice })
                  })

                  console.log(res.data);
                } catch (error) {
                  console.log("ERROR: ", error);
                }
              }

              DateInvoice(e) { 
                e.map((invoice) => {
                  const date = new Date(invoice.created_at)

                  let year = date.getFullYear()
                  let month = date.getMonth()+1
                  let dt = date.getDate()

                  if ( dt < 10 ) {
                    dt = '0' + dt
                  }

                  if ( month < 10 ) {
                    dt = '0' +  month
                  }

                  let new_date = dt + '/' + month + '/' + year

                  invoice.created_at = new_date

                  return invoice
                })
              }
              
                getTransfer = async (paymentTransfer) =>{       
                    try {
                      
                      let res = axios.get(`${url}/api/auth/transfer/${paymentTransfer.fk_id_transfer_id}`)
                        .then(res => {
                          const data = res.data;
                          this.symboltoTransfersCurrency(data)
                          this.symbolBalanceTransfersCurrency(data)
                          this.symbolCommissionCurrency(data)
                          this.statusBalance(data)
                          let transfer = data[0]
                          this.getBalance(transfer)
                          this.setState({ transfer})
                        });                     
                          console.log(res.data)
                        }catch(error){
                  }

                  }    


              getTransfersApproved= async () =>{
         
                try {
                   
                    let res = axios.get(`${url}/api/auth/transferApproved/${this.props.invoice}`)
                    .then(res => {
                      const transfers = res.data;
                      this.DateTransfer(transfers)
                      this.symboltoTransfersCurrency(transfers)
                      this.symbolBalanceTransfersCurrency(transfers)
                      this.symbolCommissionCurrency(transfers)
                      this.statusBalance(transfers)

                      this.setState({ transfers, statusTransfers:0, enableTransfer:1, selectedColumns: []})

                    });                     
                      console.log(res.data)
                    }catch(error){
               }
            }

            getTransferDisabled= async () =>{
         
              try {
                 
                  let res = axios.get(`${url}/api/auth/transferDisabled/${this.props.invoice}`)
                  .then(res => {
                    const transfers = res.data;
                    this.DateTransfer(transfers)
                    this.symboltoTransfersCurrency(transfers)
                    this.symbolBalanceTransfersCurrency(transfers)
                    this.symbolCommissionCurrency(transfers)
                    this.statusBalance(transfers)
                    this.setState({ transfers, enableTransfer:0, selectedColumns: [] })
                  });                     
                    console.log(res.data)
                  }catch(error){
             }
          }

            async disableTransfer(){
              let data = {...this.state.transfer};
              console.log("TRANSFER EN SAVE:",data)
      
              try {
              await axios.put(`${url}/api/auth/disableTransfer/${data.id}`)
                .then(res => {
                  console.log(res)
                  this.setState({
                    data,
                    disableTransferDialog: false,
                    enableTransfer:1
                });
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Transfer Disabled', life: 3000 });
              })
           
                }catch(error){
                } 
                this.getTransfers()
      
            }     

            async enableTransfer(){
              let data = {...this.state.transfer};
              console.log("TRANSFER EN SAVE:",data)

              try {
              await axios.put(`${url}/api/auth/enableTransfer/${data.id}`)
                .then(res => {
                  console.log(res)
                  this.setState({
                    data,
                    enableTransferDialog: false,
                    enableTransfer:0,
                });
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Transfer Disabled', life: 3000 });
              })
           
                }catch(error){
                } 
                this.getTransferDisabled()
            }     


            exchangePayment(e){
              let length = Object.keys(e).length

                  for(let i=0; i<length; i++){ 
                    let payment
                    payment= e[i].paymentAmount/e[i].rate;
                    e[i].exchangePayment = payment
                    e[i].exchangePayment =  e[i].exchangePayment + ' ' + e[i].codeRate
                  }
               return e
          }

          getPayments = async () =>{
            try {
               
                let res = axios.get(`${url}/api/auth/paymentTransferByInvoice/${this.props.invoice}`)
                .then(res => {
                  console.log("PAYMENTS:",res.data)
                  const payments = res.data;
                  this.DatePayment(payments)
                  this.symbolPaymentsCurrency(payments)
                  this.symbolRatesCurrency(payments)
                  this.symbolSchedulePaymentsCurrency(payments)
                  this.setState({ payments})
                });                     
                console.log(res)
                }catch(error){
           }
          }  

          symbolRatesCurrency(e){
            let length = Object.keys(e).length
            let dollarUSLocale = Intl.NumberFormat('en-US');
                for(let i=0; i<length; i++){ 
                  e[i].symbolRatesCurrency = e[i].symbol +' '+ dollarUSLocale.format(e[i].rate) +' '+e[i].code;
                    }
            return e
          }

          symbolPaymentsCurrency(e){
            let length = Object.keys(e).length
            let dollarUSLocale = Intl.NumberFormat('en-US');

                for(let i=0; i<length; i++){ 
                  e[i].symbolAmountCurrency = e[i].symbol +' '+ dollarUSLocale.format(e[i].paymentAmount) +' '+e[i].code;
                    }
            return e
          }

          getSchedulesPayments = async () =>{
            try {
               
                let res = axios.get(`${url}/api/auth/scheduleTransferByInvoice/${this.props.invoice}`)
                .then(res => {
                  const payments = res.data;
                  this.DateSchedule(payments)
                  this.symbolSchedulePaymentsCurrency(payments)
                  console.log("SCHEDULES:",res.data)
                  this.setState({ payments})
                });                     
                console.log(res)
                }catch(error){
              }
            }  

          symbolSchedulePaymentsCurrency(e){
            let length = Object.keys(e).length
            let dollarUSLocale = Intl.NumberFormat('en-US');

                for(let i=0; i<length; i++){ 
                  e[i].symbolScheduleAmountCurrency = e[i].originalSymbol +' '+dollarUSLocale.format(e[i].paymentEquivalentAmount)+' '+e[i].originalCode;
                    }
            return e
          }                     
         

             symbolTransferPaymentCurrency(e){
              let length = Object.keys(e).length
              let dollarUSLocale = Intl.NumberFormat('en-US');
             
                  for(let i=0; i<length; i++){ 
                    e[i].symbolTransferPaymentCurrency = e[i].symbol +' '+dollarUSLocale.format(e[i].paymentAmount)+' '+e[i].code;
                      }
               return e
          }

            symbolCommissionCurrency(e){
              let length = Object.keys(e).length
              let dollarUSLocale = Intl.NumberFormat('en-US');

                  for(let i=0; i<length; i++){ 
                    e[i].symbolCommissionCurrency = e[i].symbol +' '+dollarUSLocale.format(e[i].commission)+' '+e[i].code;
                      }
               return e
          }
          
            
               symboltoTransfersCurrency(e){
                let length = Object.keys(e).length
                let dollarUSLocale = Intl.NumberFormat('en-US');

                    for(let i=0; i<length; i++){ 
                      e[i].symboltoTransferCurrency = e[i].symbol +' '+dollarUSLocale.format(e[i].transferTotal)+' '+e[i].code;
                        }
                 return e
            }

            symbolBalanceTransfersCurrency(e){
              let length = Object.keys(e).length
              let dollarUSLocale = Intl.NumberFormat('en-US');

                  for(let i=0; i<length; i++){ 
                    e[i].symbolBalanceTransferCurrency = e[i].symbol +' '+dollarUSLocale.format(e[i].transferBalance)+' '+e[i].code;
                      }
               return e
          }

            statusBalance(e){             
              e.map((transfer) => {
      
              if(transfer.studentBalance===0){
              transfer.studentBalanceString=this.state.campusText.Pendding
              }else{
              transfer.studentBalanceString=this.state.campusText.FullyPaid
              }
               return transfer
             });
            }

            async deletePayment(){
              let data = {...this.state.transferPayment};
      
              try {
              await axios.delete(`${url}/api/auth/paymentTransfer/${data.id}`)
                .then(res => {
                  console.log(res)
                  this.setState({
                    data,
                    deletePaymentDialog: false,
                    trnasferPayment: this.emptyTransferPayment
                });
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Transfer Payment Deleted', life: 3000 });
              })
           
                }catch(error){
                } 
                this.getSchedulesPayments()
            }      


            deleteSelectedPayments() {
              let payments = this.state.payments.filter(val => !this.state.selectedPayments.includes(val));
              let data = this.state.payments.filter(val => this.state.selectedPayments.includes(val));
              console.log("DATA:",data)
        
              try {
              axios.delete(`${url}/api/auth/paymentTransfers`,{data})
                .then(res => {
                  this.setState({
                    payments,
                    deletePaymentsDialog: false,
                    selectedPayments: null
                });
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payments Deleted', life: 3000 });
                
              })
              
                }catch(error){
                } 
                this.getSchedulesPayments()        
            }


   getAccounts = async () =>{
       
    try {
       
        let res = axios.get(`${url}/api/auth/accounts`)
        .then(res => {
          const accounts = res.data;
          this.setState({ accounts})
        });                     
          console.log(res.data)
        }catch(error){
   }
  }  

  getExchangeOffices = async () =>{
       
    try {
       
        let res = axios.get(`${url}/api/auth/exchangeOffices`)
        .then(res => {
          const exchangeOffices = res.data;
          console.log("EXCHANGE OFFICES:", exchangeOffices)
          this.setState({ exchangeOffices})
        });                     
          console.log(res.data)
        }catch(error){
   }
  } 
   

    getBalance = async (transfer) =>{
          
        try {
          
            let res = axios.get(`${url}/api/auth/balancebyItemId/${transfer.fk_id_balanceItem}`)
            .then(res => {
              const balances = res.data[0];
              let dollarUSLocale = Intl.NumberFormat('en-US');

              balances.symbolTotalCurrency=balances.symbol+' '+ dollarUSLocale.format(balances.total)+' '+balances.code;
              balances.symbolBalanceCurrency=balances.symbol+' '+dollarUSLocale.format(balances.balance)+' '+balances.code;

              this.setState({ balances})
            });                     
              console.log(res.data)
            }catch(error){
      }
      }

    getCurrencies = async () =>{
      
      try {
        
          let res = axios.get(`${url}/api/auth/currencies`)
          .then(res => {
            const currencies = res.data;
            this.setState({ currencies})
          });                     
            console.log(res.data)
          }catch(error){
      } 
    }    

    getMethods = async () =>{
    
      try {
        
          let res = axios.get(`${url}/api/auth/methods`)
          .then(res => {
            const methods = res.data;
            this.setState({ methods})
          });                     
            console.log(res.data)

          }catch(error){
      }
    }
   
      symbolAmount(e){      
        let dollarUSLocale = Intl.NumberFormat('en-US');       
        e.map((invoice) => {

        invoice.symbolAmount=invoice.symbol+' '+ dollarUSLocale.format(invoice.totalAmount)+' '+invoice.code;
        
         return invoice

       });
      }

      symbolAmountAgency(e){
        let dollarUSLocale = Intl.NumberFormat('en-US');       
                         
        e.map((invoice) => {

        invoice.symbolAmountAgency=invoice.symbolA+' '+dollarUSLocale.format(invoice.totalAmountAgency)+' '+invoice.codeA;
        
         return invoice

       });
      }

      DateInvoice(e){

        e.map((invoice) => {

          const date = new Date(invoice.created_at);
          
           let year = date.getFullYear();
           let month = date.getMonth()+1;
           let dt = date.getDate();

           if (dt < 10) {
             dt = '0' + dt;
           }
           if (month < 10) {
             month = '0' + month;
           }

           let new_date = dt +'/' + month + '/'+year

           invoice.created_at= new_date

           return invoice

         });

      }
      
      DateTransfer(e){

        e.map((transfer) => {
          
          var origin = transfer.startDate.split('/')
          var originDate = origin[1] + '/' +origin[0] +'/' +origin[2];

          var date = new Date(originDate).getTime();

           transfer.startDate_new = date

           return transfer
         });
      }

      DatePayment(e){

        e.map((payment) => {
          
          var origin = payment.paymentDate.split('/')
          var originDate = origin[1] + '/' +origin[0] +'/' +origin[2];

          var date = new Date(originDate).getTime();

           payment.paymentDate_new = date

           return payment
         });
      }

      DateSchedule(e){

        e.map((payment) => {
          
          var origin = payment.scheduleDate.split('/')
          var originDate = origin[1] + '/' +origin[0] +'/' +origin[2];

          var date = new Date(originDate).getTime();

           payment.scheduleDate_new = date

           return payment
         });
      }


      Date(e){
        
           let year = e.getFullYear();
           let month = e.getMonth()+1;
           let day = e.getDate();

           if (day < 10) {
             day = '0' + day;
           }
           if (month < 10) {
             month = '0' + month;
           }
        
           let new_date= day +'/' + month + '/'+year

           return new_date

      }
    
      saveInvoiceTransfer() {
        let state = { submitted: true };
        const decision = this.isValidTransferTotal();

        if (decision) {
         
          let data = {...this.state.transfer};
            try {
              let res = axios.put(`${url}/api/auth/invoiceTransfer/${data.id}`,data)
              .then(res =>{
              this.getTransfers()  
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice Transfer Updated', life: 3000 });
                this.getTransfers()  
              }
            }catch(error){          
          }         
        state = {
          ...state,
          data,
          invoiceTransferDialog: false,
          transfer: this.emptyTransfer,
          selectedCommissionType:'',
          resetCommissionType:'Commission Type'
        }; 
      }
      this.setState(state);
    }
    
    

    createPaymentTransfer() {
      let state = { submitted: true };
      const decision = this.isValid();

      if (decision) {
      //if (this.state.role_type.role_type.trim()) {
      
        let data = {...this.state.paymentTransfer};

        if(data.status===0){
                  try {
                    let res = axios.put(`${url}/api/auth/paymentTransfer/${data.id}`,data)
                    .then(res =>{
                      this.getSchedulesPayments()
                      this.getTransfers()
                    })
                    
                    if (!res.error) {     
                      this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Transfer Updated', life: 3000 });
                    }
                  }catch(error){          
                }     
        }else{

                  try {
                    let res = axios.post(`${url}/api/auth/paymentTransfer`,data)
                    .then(res =>{
                      this.getTransfers()
                      this.getPayments()

                    })
                    if (!res.error) {     
                      this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Transfer Created', life: 3000 });
                      this.getTransfers()
                    }
                  }catch(error){          
                }         
        }
      state = {
        ...state,
        data,
        paymentTransferDialog: false,
        transfer:this.emptyTransfer,
        paymentTransfer: this.emptyPaymentTransfer,
        selectedExchangeOffice:'',
        balances:''
      }; 
    }
  
    this.setState(state);
    this.getTransfers()
  }
  
  
  createScheduleTransfer() {
    let state = { submitted: true };
    const decision = this.isValidSchedulePayment();

    if (decision) {
    //if (this.state.role_type.role_type.trim()) {
     

      let data = {...this.state.paymentTransfer};
      console.log("SHEDULE:",data   )

        try {
          let res = axios.post(`${url}/api/auth/scheduleTransfer`,data)
          .then(res =>{
          this.getSchedulesPayments()  
          })
          if (!res.error) {     
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Shudele Transfer Created', life: 3000 });
          }
        }catch(error){          
      }         
    state = {
      ...state,
      data,
      scheduleTransferDialog: false,
      paymentTransfer: this.emptyPaymentTransfer,
      balances:''
    }; 
  }

  this.setState(state);
  }

    confirmDisableTransfer(transfer) {
        this.setState({
          transfer,
          disableTransferDialog: true
      })
    }

    confirmEnableTransfer(transfer) {
      this.setState({
          transfer,
          enableTransferDialog: true
      })
    }

    confirmDisableSelected() {
      this.setState({ disableTransfersDialog: true })
    }

    confirmEnableSelected() {
      this.setState({ enableTransfersDialog: true })
    }

    confirmSchedulePaymentsSelected(){
      this.setState({schedulePaymentsDialog:true})
    }

    confirmSchedulePaymentValidation(){
      this.setState({schedulePaymentValidationDialog:true})
    }

    disableSelectedTransfers() {
      let transfers = this.state.transfers.filter(val => !this.state.selectedTransfers.includes(val));
      let data = this.state.transfers.filter(val => this.state.selectedTransfers.includes(val));
      console.log("disable transfers:",data)

      try {
      axios.put(`${url}/api/auth/disableTransfers`,{data})
        .then(res => {
          this.setState({
            transfers,
            disableTransfersDialog: false,
            selectedTransfers: null,
            enableTransfer:1
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Transfers Disabled', life: 3000 });
        
      })
      
        }catch(error){
        } 
        this.getTransfers()
    }

    enableSelectedTransfers() {
      let transfers = this.state.transfers.filter(val => !this.state.selectedTransfers.includes(val));
      let data = this.state.transfers.filter(val => this.state.selectedTransfers.includes(val));
      console.log("enable transfers:",data)

      try {
      axios.put(`${url}/api/auth/enableTransfers`,{data})
        .then(res => {
          this.setState({
            transfers,
            enableTransfersDialog: false,
            selectedTransfers: null,
            enableTransfer:0
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Transfers Enabled', life: 3000 });
        
      })
      
        }catch(error){
        } 
        this.getTransferDisabled()

    }

    scheduleTransferValidation=()=>{
      let aux =0;
      let data = this.state.transfers.filter(val => this.state.selectedTransfers.includes(val));

      for(let i=0; i<Object.keys(data).length; i++){ 
        for(let j=0; j<Object.keys(data).length; j++){ 
          if(data[i].fk_id_currency!==data[j].fk_id_currency){
            aux++
          }
        }
      }

      if(aux===0){
        this.confirmSchedulePaymentsSelected()
      }else{
        this.confirmSchedulePaymentValidation()
      }
    }


    schedulePaymentsSelected() {
      let state = { submitted: true };
      const decision = this.isValidSchedulePayments();
      
    
      if (decision) {

      let paymentTransfer = {...this.state.paymentTransfer}
      //let transfers = this.state.transfers.filter(val => !this.state.selectedTransfers.includes(val));
      let data = this.state.transfers.filter(val => this.state.selectedTransfers.includes(val));

      
      for(let i=0; i<Object.keys(data).length; i++){ 
        data[i].scheduleDate=paymentTransfer.scheduleDate
      }

      try {
          axios.post(`${url}/api/auth/schedulePayments`,{data})
            .then(res => {
            this.getTransfers()
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Scheduled payments created', life: 3000 });
          })
        }catch(error){
        } 
        state = {
          ...state,
          //transfers,
          schedulePaymentsDialog: false,
          selectedTransfers: null,
        };
      }
      this.setState(state);   
    }

    hideDisableTransferDialog() {
      this.setState({ disableTransferDialog: false })
    }
      
    hideDisableTransfersDialog() {
      this.setState({ disableTransfersDialog: false });
    }

    hideEnableTransferDialog() {
      this.setState({ enableTransferDialog: false })
    }
      
    hideEnableTransfersDialog() {
      this.setState({ enableTransfersDialog: false });
    }
    
    hideSchedulePaymentsDialog(){
      this.setState({schedulePaymentsDialog:false});
    }

    hideSchedulePaymentValidationDialog(){
      this.setState({schedulePaymentValidationDialog:false});
    }
         
      exportCSV() {
        this.dt.exportCSV();
    }

    editInvoiceTransfer(transfer) {
      this.getBalance(transfer)


        this.setState({
            transfer: { ...transfer },
            selectedCommissionType: null,
            resetCommissionType:null,  
            invoiceTransferDialog: true,
        })
    }

    openNew(transfer){
      let paymentTransfer = {...this.state.paymentTransfer}
      this.getBalance(transfer)

      paymentTransfer['fk_id_transfer_id']= transfer.id
      paymentTransfer['status']= 1

      this.setState({
        transfer:{ ...transfer },
          paymentTransfer,
          selectedAccount: null, 
          selectedMethod: null, 
          submitted: false,
          paymentTransferDialog: true,           
      })
      //this.getPaymentTransfer()
      this.getAccounts()
      this.getMethods()
      this.getExchangeOffices()
    }

    openEdit(paymentTransfer){

      this.setState({
          paymentTransfer:{...paymentTransfer},
          selectedAccount: null, 
          selectedMethod: null, 
          submitted: false,
          paymentTransferDialog: true,           
      })
      this.getTransfer(paymentTransfer) 
      this.getAccounts()
      this.getMethods()
      this.getExchangeOffices()
    }

    openSchedule(transfer){
      let paymentTransfer = {...this.state.paymentTransfer}

        paymentTransfer['fk_id_transfer_id'] = transfer.id
        paymentTransfer['status'] = 0
        paymentTransfer['paymentEquivalentAmount'] = transfer.transferBalance
        this.setState({
            transfer:{ ...transfer},
            paymentTransfer,
            submitted: false,
            scheduleTransferDialog: true, 
      })
    }

    hideInvoiceTransferDialog() {
      this.setState({
          submitted: false,
          invoiceTransferDialog: false,     
          transfer:this.emptyTransfer,
          paymentTransfer:this.emptyPaymentTransfer,
          transferTotalMessage: '',      
      })  
    }  

  hidePaymentTransferDialog() {
    this.setState({
        submitted: false,
        paymentTransferDialog: false,     
        transfer:this.emptyTransfer,
        paymentTransfer:this.emptyPaymentTransfer,
        balances:'',

        selectedAccount:'',
        selectedExchangeOffice:'',
        selectedMethod:'',

        fk_id_transfer_idMessage:'',
        originAccountMessage:'',
        paymentDateMessage:'',
        fk_id_paymentTransferMethodMessage:'',
        paymentAmountMessage:'',
        rateMessage:'',
        currencyExchangeOfficeMessage:'',

  })  
}  


hideScheduleTransferDialog() {
  this.setState({
      submitted: false,
      scheduleTransferDialog: false,     
      transfer:this.emptyTransfer,
      paymentTransfer:this.emptyPaymentTransfer,
      balances:'',
      paymentEquivalentAmountMessage:'',
      scheduleDateMessage:'',
  })  
}  

  getAccount= async (e) =>{ 
    let accountER
  try {
    await axios.get(`${url}/api/auth/account/${e.value}`)
    .then(res => {
      accountER = res.data;
      });                     
    }catch(error){
  }
        return accountER           
  }


  DateToOE(e){
            
    let year = e.getFullYear();
    let month = e.getMonth()+1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date= day +'/' + month + '/'+ year

    return new_date
  }
   
   getExchangeRates= async (paymentTransfer,DateOE) =>{ 
    let balances = {...this.state.balances}
    let transfer = {...this.state.transfer}
    let data={
      date:null
    }
    data.date = DateOE

    try {
      await axios.post(`${url}/api/auth/getExchangeRates`,data)
      .then(res => {
      let exchanges = res.data;
      
        if(paymentTransfer.status===0){
          for(let i=0; i<Object.keys(exchanges).length; i++){ 
              if(balances.id_currency === exchanges[i].fk_id_currency){
                balances.rate = exchanges[i].rate
              }
          } 
          paymentTransfer.paymentAmount = paymentTransfer.paymentEquivalentAmount * balances.rate
          paymentTransfer.rate = balances.rate

        }else{
          for(let i=0; i<Object.keys(exchanges).length; i++){ 
            if(balances.id_currency === exchanges[i].fk_id_currency){
              balances.rate = exchanges[i].rate
            }
          }
          paymentTransfer.paymentAmount = transfer.transferBalance * balances.rate
          paymentTransfer.rate = balances.rate
          paymentTransfer.paymentEquivalentAmount = paymentTransfer.paymentAmount/paymentTransfer.rate
        }

        this.setState({balances, paymentTransfer})
        });                     
      }catch(error){
    }
  }

  onInputChange(e,name) {
    const val = (e.target && e.target.value) || '';
    let transfer = {...this.state.transfer};
    let balance = {...this.state.balances};

    if(!val){

        if(name==='transferTotal'){  
          transfer['commission'] = balance.total
          transfer['transferTotal']= "0"
        }

    }else{
  
        if(name==='transferTotal'){  
            transfer['commission'] = balance.total - val
            transfer['transferTotal']= balance.total - transfer['commission']
        }
    }  

    this.setState({ transfer });
  }

  onRateChange(e){
    const val = (e.target && e.target.value) || '';
    let paymentTransfer = {...this.state.paymentTransfer}
    let balances = {...this.state.balances}
    //let transfer = {...this.state.transfer}
 
    balances.rate=val

    if(paymentTransfer.status===0){
      paymentTransfer['rate']=val
      paymentTransfer.paymentAmount = paymentTransfer.paymentEquivalentAmount * paymentTransfer.rate

    }else{
      paymentTransfer['rate']=val
      paymentTransfer.paymentEquivalentAmount = paymentTransfer.paymentAmount/paymentTransfer.rate
    }

    this.setState({ paymentTransfer,balances });
  }

  onAmountChange(e){
    const val = (e.target && e.target.value) || '';
    let paymentTransfer = {...this.state.paymentTransfer}
    let balances = {...this.state.balances}


    if(paymentTransfer.status===0){
      paymentTransfer.paymentAmount=val
      balances.rate = paymentTransfer.paymentAmount/paymentTransfer.paymentEquivalentAmount

    }else{
      paymentTransfer.paymentAmount=val
      paymentTransfer.paymentEquivalentAmount = paymentTransfer.paymentAmount/paymentTransfer.rate
      
    }
    
    this.setState({ paymentTransfer, balances});
  }

  onScheduleAmountChange(e){
    const val = (e.target && e.target.value) || '';
    let paymentTransfer = {...this.state.paymentTransfer}

    paymentTransfer.paymentEquivalentAmount = val

    this.setState({ paymentTransfer});
  }


  async onAccountChange(e) { 

    let account = await this.getAccount(e)

    let paymentTransfer = {...this.state.paymentTransfer};
    paymentTransfer['originAccount'] = e.value;
    paymentTransfer['code']=account.codeCurrency
    this.setState({ selectedAccount: e.value,paymentTransfer}); 
  }


  onMethodChange(e) {
      
    let paymentTransfer = {...this.state.paymentTransfer};

    paymentTransfer['fk_id_paymentTransferMethod'] = e.value;
    this.setState({ selectedMethod: e.value,paymentTransfer});
  }

  onExchangeOfficeChange(e){
    let paymentTransfer = {...this.state.paymentTransfer};
    console.log("SELECT EXCHANGE OFFICE:",e.value)

    paymentTransfer['currencyExchangeOffice'] = e.value;
    this.setState({ selectedExchangeOffice: e.value,paymentTransfer});

  }


  onDateChange(e,name) {  

    const val =  this.Date(e.target.value) || ''
    const DateOE = this.DateToOE(e.target.value) || ''
    let paymentTransfer = {...this.state.paymentTransfer}

    this.getExchangeRates(paymentTransfer,DateOE)

    paymentTransfer[`${name}`] = val;
   
    this.setState({ paymentTransfer });
  }

  onScheduleDateChange(e,name){
    const val =  this.Date(e.target.value) || ''
    let paymentTransfer = {...this.state.paymentTransfer}

    paymentTransfer[`${name}`] = val;
   
    this.setState({ paymentTransfer });
  }


  amountNrate=()=>{
    let InputAmountView=[] 

    let balance = {...this.state.balances}  
    let paymentTransfer = {...this.state.paymentTransfer} 

    
    
    console.log("PAYMENT TRANSFER:",paymentTransfer.code)
              InputAmountView.push(
                <>
                    <div className="form-group row">  
                      <div className="col-lg-4">
                      <span className="p-float-label">  
                      <InputNumber
                          locale="en-US" 
                          minFractionDigits={2}  
                          inputStyle={{ width: 190, height:33 }}
                          min="0"
                          id="amount"
                          variant="outlined"
                          value={paymentTransfer.paymentAmount}   
                          margin="dense"
                          onValueChange={(e) => this.onAmountChange(e)}
                          required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentTransfer.paymentAmount  })}
                          />{paymentTransfer.code} 
                          <label htmlFor="amount">{this.state.campusText.Amount}</label>
                      </span>
                      <small id="payment_amount-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPaymentAmount(this.state)}</small>
                      </div>
                           <div className="col-lg-1.5"> 
                              <span className="p-float-label">  
                              <InputNumber
                                  locale="en-US" 
                                  minFractionDigits={4}
                                  min="0"
                                  inputStyle={{ width: 70, height:33 }}
                                  id="rate"
                                  variant="outlined"
                                  margin="dense"
                                  value={balance.rate}   
                                  onValueChange={(e) => this.onRateChange(e)}
                                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentTransfer.rate  })}
                                  /> 
                                  <label htmlFor="rate">{this.state.campusText.Rate}</label>
                              </span>  
                              <small id="rate-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxRate(this.state)}</small>
                           </div>  
                          <div className="col-lg-5"> 
                              <span className="p-float-label">  
                              <InputNumber
                                  locale="en-US" 
                                  minFractionDigits={2}
                                  inputStyle={{ width: 190, height:33, backgroundColor:'#e3e4e5' }}
                                  id="equivalent"
                                  variant="outlined"
                                  margin="dense"
                                  readOnly="true"
                                  value={paymentTransfer.paymentEquivalentAmount}   
                                  />{balance.code} 
                                  <label htmlFor="equivalent">{this.state.campusText.Equivalent}</label>
                              </span>
                          </div>
                      </div>
                      
                      <div className="form-group row">  
                      <div className="col-lg-3">  
                            <span className="p-float-label">   
                              <Dropdown
                                style={{ width: 150, height:33 }}
                                id="exchangeOffice_name"
                                variant="outlined"
                                margin="dense"
                                value= { this.state.selectedExchangeOffice} 
                                options={this.state.exchangeOffices} 
                                onChange={this.onExchangeOfficeChange}  
                                optionLabel="exchangeOffice_name" 
                                optionValue="id"    
                                />
                                <label htmlFor="exchangeOffice_name">{this.state.campusText.ExchangeOffice}</label>
                            </span>
                            </div>
                        </div>    
                </>
              )
    return InputAmountView
  }

  toolbarButtonED=()=>{
    console.log("INDEX:", this.state.activeIndex)
    let EDView=[]

     if(this.state.activeIndex===0){
        EDView.push(
                      <FormattedMessage id="GENERAL.DISMISS">
                        {(message) => 
                        <Button icon="pi pi-times" label={message}  className="p-button-danger" onClick={this.confirmDisableSelected} disabled={!this.state.selectedTransfers || !this.state.selectedTransfers.length}/>
                      }</FormattedMessage>

                      )
        }else if(this.state.activeIndex===4){
         EDView.push(
          <FormattedMessage id="GENERAL.ENABLE">
                        {(message) => 
                        <Button label={message} icon="pi pi-check" className="p-button-danger" onClick={this.confirmEnableSelected} disabled={!this.state.selectedTransfers || !this.state.selectedTransfers.length} />
                        }</FormattedMessage>
                      )
         }else if(this.state.activeIndex===1){
          EDView.push(
                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedPayments || !this.state.selectedPayments.length} />}
                      </FormattedMessage>
                    </div>
                        )

         }

         return EDView
  }

  toolbarButtonSchedule=()=>{
    let ScheduleView=[]

     if(this.state.activeIndex===0){
        ScheduleView.push(
                      <FormattedMessage id="GENERAL.SCHEDULE_PAYMENT">
                        {(message) => 
                        <Button icon="pi pi-calendar" label={message}  color='primary' onClick={this.scheduleTransferValidation} disabled={!this.state.selectedTransfers || !this.state.selectedTransfers.length}/>
                      }</FormattedMessage>

                      )
    }
         return ScheduleView
  }

  actionBodyTemplate(rowData) {
    let showButton=[]

    if(rowData.status===0 && rowData.enable===1){
      showButton.push(
        <>
            {/*<Button icon="pi pi-plus"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}}  onClick={() => this.openNew(rowData)} />*/}
            <Button tooltip='Schedule transfer' tooltipOptions={{ position: 'bottom'}} className='p-button-rounded p-button-outlined' icon="pi pi-calendar"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}}  onClick={() => this.openSchedule(rowData)} />
            {/*{this.buttonED(rowData)}*/}
        </>
      )
    }
    return showButton
  }

  paymentsActionBodyTemplate(rowData) {
    let showButton=[]

    if(rowData.status===0){
      showButton.push(  
        <>
             <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}}  onClick={() => this.openEdit(rowData)} />
             <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeletePayment(rowData)} />
        </>
      )
    }
    return showButton
  }



  buttonED=(rowData)=>{

    let EDView=[]

      if( this.state.enableTransfer===1){
        EDView.push(
          <Button icon="pi pi-times"  className="p-button-danger"  style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDisableTransfer(rowData)} />
          )
        }else{
         EDView.push(
          <Button icon="pi pi-check"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmEnableTransfer(rowData)} />
         )
         }

         return EDView
  }

  

  /*  formatCurrency = (value) => {
      if(value===0 || value===null){  
        return null
      }else{
      return value.toLocaleString('en-US', {mode:"currency", maxFractionDigits:3, minFractionDigits:3});
      }
    }   */

 
  columnInvoiceTransfer(rowData){
    return(
      <>
            <Button  label = {rowData.symboltoTransferCurrency} className="p-button-text"  onClick={() => this.editInvoiceTransfer(rowData)} />
      </>
    )
  }

  statusStudentBodyTemplate(rowData) {
    let showTag=[]

    if(rowData.studentBalance===0){
      showTag.push(
                    <>
                    <span className="p-tag p-tag-warning">{rowData.studentBalanceString}</span>
                    </>
      )
    }else{
      showTag.push(
              <>
              <span className="p-tag p-tag-success">{rowData.studentBalanceString}</span>
              </> 
      )
    }

    return showTag
  }


  onIndexChange(e) {    
 
    if(e===0){
      this.getTransfers()
    }else if(e===1){
      this.getSchedulesPayments()
    }else if(e===2){
      this.getPayments()
    }else if(e===3){
      this.getTransfersApproved()
    }else if(e===4){
      this.getTransferDisabled()
    }

    this.setState({transfers:null,payments:null, activeIndex:e});
  }

  onSortStartDate(event) {
    let data = [...this.state.transfers];
    data.sort((data1, data2) => {
      console.log("EVENT:", event)
        const value1 = data1.startDate_new;
        const value2 = data2.startDate_new;

        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2, undefined, { numeric: true });
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.order * result);
    });

    return data;
}

  onColumnToggle(event) {
    let selectedColumns = event.value
    let orderableSelectedColumns = this.columns.filter(col => selectedColumns.some (
      sCol => sCol.field === col.field
    ))

    this.setState({ selectedColumns: orderableSelectedColumns})
  }


  tableTransfers(){
    let showTransfers=[]

    const header = (
      <div style={{ textAlign: 'left'}}>
        <MultiSelect 
          value={this.state.selectedColumns}
          placeholder='Fields to show...'
          display='chip'
          options={this.columns}
          optionLabel='header'
          onChange={this.onColumnToggle}
        />
      </div>
    )

    const columnComponents = this.state.selectedColumns.map( col => {
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

      showTransfers.push(
        <>
        <div className= "card-header flex-wrap border-0 pt-6 pb-0">
        <DataTable 
            ef={(el) => this.dt = el} 
            value={this.state.transfers} 
            paginator 
            paginatorTemplate="InvoicePageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            resizableColumns
            columnResizeMode='fit'
            responsiveLayout='scroll'
            header={header}
            stripedRows
            currentPageReportTemplate={this.state.paginatorText} 
            rows={10} 
            rowsPerPageOptions={[10,20,50]} 
            ref={(el) => { this.dt = el; }}
            globalFilter={this.state.globalFilter} 
            emptyMessage={<FormattedMessage id="ACCOUNTING_TRANSFER.NO_DATA"/>} 
            loading={this.state.loading}
            selection={this.state.selectedTransfers} 
            onSelectionChange={e => this.setState({ selectedTransfers: e.value })} 
            dataKey="id">
            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
            {columnComponents}
            {/* <Column field="externalId" header={this.state.campusText.InvoiceId} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
            <Column field="invoiceName" header={this.state.campusText.InvoiceName} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceName} filterMatchMode="contains"></Column>
            {/* <Column field="provider" header={this.state.campusText.Provider} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Provider} filterMatchMode="contains"></Column> */}
            {/* <Column field="agency" header={this.state.campusText.Office} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
            {/* <Column field="student" header={this.state.campusText.Student} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column> */}
            {/* <Column field="studentBalanceString" header={this.state.campusText.StudentBalance} body={this.statusStudentBodyTemplate} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Balance} filterMatchMode="contains"></Column> */}
            <Column field="startDate" header={this.state.campusText.StartDate} sortable={true} sortFunction={this.onSortStartDate} headerStyle={{width: 'flex'}}  body={this.dateBodyTemplate} filterPlaceholder={this.state.campusText.StartDate} filter filterElement={this.dateFilter} filterFunction={this.filterDate} ></Column>
            <Column field="symbolCommissionCurrency" header={this.state.campusText.AgencyCommission} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.AgencyCommission} filterMatchMode="contains"></Column>                        
            <Column field="symboltoTransferCurrency" /*body={this.columnInvoiceTransfer}*/ header={this.state.campusText.ToTransfer}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.ToTransfer} filterMatchMode="contains"></Column>
            <Column field="symbolBalanceTransferCurrency" header={this.state.campusText.TransferBalance} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.TransferBalance} filterMatchMode="contains"></Column>
            <Column body={this.actionBodyTemplate}  headerStyle={{width: 'flex'}}></Column>
        </DataTable>
    </div>
    </>
      )
      return showTransfers
  }

  onSortPaymentDate(event) {
    let data = [...this.state.payments];
    data.sort((data1, data2) => {
      console.log("EVENT:", event)
        const value1 = data1.paymentDate_new;
        const value2 = data2.paymentDate_new;

        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2, undefined, { numeric: true });
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.order * result);
    });

    return data;
}

    

  transferedPayments=()=>{
    let paymentsView=[]

    const header = (
      <div style={{ textAlign: 'left'}}>
        <MultiSelect 
          value={this.state.selectedColumns}
          placeholder='Fields to show...'
          options={this.columns}
          optionLabel='header'
          onChange={this.onColumnToggle}
          // style={{ width: '20em'}}
        />
      </div>
    )

    const columnComponents = this.state.selectedColumns.map( col => {
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

    paymentsView.push(
      <>
        <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <DataTable 
              ef={(el) => this.dt = el} 
              value={this.state.payments} 
              paginator 
              paginatorTemplate="InvoicePageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              resizableColumns
              columnResizeMode='fit'
              responsiveLayout='scroll'
              header={header}
              stripedRows
              currentPageReportTemplate={this.state.paginatorText} 
              rows={10} 
              rowsPerPageOptions={[10,20,50]} 
              ref={(el) => { this.dt = el; }}
              globalFilter={this.state.globalFilter} 
              emptyMessage={<FormattedMessage id="ACCOUNTING_TRANSFER.PAYMENTS_PAID_NO_DATA"/>}  
              loading={this.state.loading}
              selection={this.state.selectedTransfers} 
              onSelectionChange={e => this.setState({ selectedTransfers: e.value })} 
              dataKey="id">
                {columnComponents}
              {/* <Column field="externalId" header={this.state.campusText.InvoiceId} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
              <Column field="invoiceName" header={this.state.campusText.InvoiceName} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceName} filterMatchMode="contains"></Column>
              {/* <Column field="provider" header={this.state.campusText.Provider} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Provider} filterMatchMode="contains"></Column> */}
              {/* <Column field="office" header={this.state.campusText.Office} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
              {/* <Column field="student" header={this.state.campusText.Student} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column> */}
              <Column field="paymentDate" header={this.state.campusText.PaymentDate} sortable={true} sortFunction={this.onSortPaymentDate} headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.PaymentDate} filterMatchMode="contains"></Column>
              {/* <Column field="account_name" header={this.state.campusText.Account}  sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Account} filterMatchMode="contains"></Column> */}
              <Column field="symbolAmountCurrency" header={this.state.campusText.Amount} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Amount} filterMatchMode="contains"></Column>
              <Column field="symbolRatesCurrency" header={this.state.campusText.Rate} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Rate} filterMatchMode="contains"></Column>
              <Column field="symbolScheduleAmountCurrency" header={this.state.campusText.Equivalent} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Equivalent} filterMatchMode="contains"></Column>
              <Column field="exchangeOffice_name" header={this.state.campusText.ExchangeOffice} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.ExchangeOffice} filterMatchMode="contains"></Column>
              <Column field="method_name" header={this.state.campusText.Method} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Method} filterMatchMode="contains"></Column>
          </DataTable>
        </div>
      </>
    )
    return paymentsView
  }


  onSortScheduleDate(event) {
    let data = [...this.state.payments];
    data.sort((data1, data2) => {
      console.log("EVENT:", event)
        const value1 = data1.scheduleDate_new;
        const value2 = data2.scheduleDate_new;

        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2, undefined, { numeric: true });
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.order * result);
    });

    return data;
}


  schedulesPayments=()=>{
    let paymentsView=[]

    const header = (
      <div style={{ textAlign: 'left'}}>
        <MultiSelect 
        value={this.state.selectedColumns}
        placeholder='Field to show...'
        options={this.columns} 
        optionLabel='header'
        onChange={this.onColumnToggle} 
        // style={{ width: '20em'}}
        />
{/* 
        <ToggleButton 
          checked={this.state.symbolAmountFrozen} 
          onChange={(e) => this.setState({ symbolAmountFrozen: e.value })} 
          onIcon="pi pi-lock" 
          offIcon='pi pi-lock-open' 
          onLabel='Unfreeze balance' 
          offLabel='Freeze balance' 
          style={{ flexGrow: 1, flexBasis: '12rem', marginLeft: '10px'}}
        /> */}
      </div>
    )

    const columnComponents = this.state.selectedColumns.map( col =>{
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

    paymentsView.push(
      <>
                 <div className= "card-header flex-wrap border-0 pt-6 pb-0">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.payments} paginator paginatorTemplate="PaymentsPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        resizableColumns
                        columnResizeMode='fit'
                        responsiveLayout='scroll'
                        header={header}
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage={<FormattedMessage id="ACCOUNTING_TRANSFER.SCHEDULED_PAYMENTS_NO_DATA"/>}  loading={this.state.loading}
                        selection={this.state.selectedPayments} onSelectionChange={e => this.setState({ selectedPayments: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        {columnComponents}
                        {/* <Column field="externalId" header={this.state.campusText.InvoiceId} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
                        <Column field="invoiceName" header={this.state.campusText.InvoiceName} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceName} filterMatchMode="contains"></Column>
                        {/* <Column field="provider" header={this.state.campusText.Provider} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Provider} filterMatchMode="contains"></Column> */}
                        {/* <Column field="office" header={this.state.campusText.Office} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
                        {/* <Column field="student" header={this.state.campusText.Student} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column> */}
                        <Column field="scheduleDate" header={this.state.campusText.ScheduleDate} editor={(props) => this.dateEditor('scheduleDate', props)} sortable={true} sortFunction={this.onSortScheduleDate} headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.ScheduleDate} filterMatchMode="contains"></Column>
                        <Column field="symbolScheduleAmountCurrency" header={this.state.campusText.Amount} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Amount} filterMatchMode="contains"></Column>
                        {/*<Column body={this.paymentsActionBodyTemplate}></Column>*/}
                    </DataTable>
                 </div>
      </>
    )
    return paymentsView
  }

    dateEditor(idKey, props) {
        return this.scheduleDateEditor(idKey, props, 'scheduleDate');
    }

    scheduleDateEditor(idKey, props, field) {
      return <Calendar type="text" showIcon margin="dense"  variant="outlined" readOnlyInput="true" dateFormat="dd/mm/yy" value={props.rowData[field]} onChange={(e) => this.scheduleDateActionBodyTemplate(idKey, props, e.target.value)} />;
    }

    scheduleDateActionBodyTemplate(idKey, props, value) {

      let paymentTransfer = [...props.value];
      const newDate = this.Date(value)

      console.log("PROPS:", ...props.value)

      paymentTransfer[0][`${idKey}`] = newDate;
      let data = paymentTransfer[0];
      
      try {
        let res = axios.put(`${url}/api/auth/scheduleTransfer/${data.id}`,data)
        .then(res =>{
        this.getSchedulesPayments()
        })
        if (!res.error) {     
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Schedule Date Updated', life: 3000 });
          this.getSchedulesPayments()

        }
      }catch(error){          
    }         
  }


  confirmDeletePayment(transferPayment) {
    this.setState({
        transferPayment,
        deletePaymentDialog: true
    })
  }


  confirmDeleteSelected() {
    this.setState({ deletePaymentsDialog: true })
  }

  hideDeletePaymentDialog() {
    this.setState({ deletePaymentDialog: false })
  }

  hideDeletePaymentsDialog() {
    this.setState({ deletePaymentsDialog: false });
}

    
    filterDate = (value,filter) => {
  

    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
        return true;
    }

    if (value === undefined || value === null) {
        return false;
    }

    return value === filter
    }


  dateBodyTemplate(rowData) {
    return (
        <React.Fragment>
            <span>{rowData.startDate}</span>
        </React.Fragment>
    );
}

    onFilterDateChange(e){
       const val = (e.target && e.target.value) || '';

        this.dt.filter(val, 'startDate', 'contains');
        
        this.setState({ filterInputDate: val });
    } 

      render(){

        let invoice = {...this.state.invoice}

        let externalId
        let created_at
        let agency
        let agent
        let agentEmail
        let student
        let studentEmail
        let validateInvoice
  
        for (let i in invoice ) { 
          externalId = invoice[i].externalId
          created_at = invoice[i].created_at
          agency = invoice[i].agency
          agent = invoice[i].agent
          agentEmail = invoice[i].agentEmail
          student = invoice[i].student
          studentEmail = invoice[i].studentEmail
          validateInvoice = invoice[i].validateInvoice
        }


      const disableTransferDialogFooter = (
      <>
          <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDisableTransferDialog} />
          <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.disableTransfer} />
      </>
      );

      const enableTransferDialogFooter = (
      <>
          <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableTransferDialog} />
          <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.enableTransfer} />
      </>
      );

      const disableTransfersDialogFooter = (
      <>
          <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDisableTransfersDialog} />
          <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.disableSelectedTransfers} />
      </> 
      ); 

      const enableTransfersDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableTransfersDialog} />
            <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.enableSelectedTransfers} />
        </> 
      ); 

      const schedulePaymentsDialogFooter = (
        <>
            <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hideSchedulePaymentsDialog} />
            <Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.schedulePaymentsSelected} />
        </>
      )

      const invoiceTransferDialogFooter = (
        <>  
            <FormattedMessage id="GENERAL.CANCEL">
            {(message) => <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hideInvoiceTransferDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
            {(message) =><Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.saveInvoiceTransfer} />}
            </FormattedMessage>
        </> 
      );

      const paymentTransferDialogFooter = (
        <>  
            <FormattedMessage id="GENERAL.CANCEL">
            {(message) => <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hidePaymentTransferDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
            {(message) =><Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.createPaymentTransfer} />}
            </FormattedMessage>
        </> 
      );

      const scheduleTransferDialogFooter = (
        <>  
            <FormattedMessage id="GENERAL.CANCEL">
            {(message) => <Button label={this.state.campusText.Cancel} icon="pi pi-times" className="p-button-text" onClick={this.hideScheduleTransferDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
            {(message) =><Button label={this.state.campusText.Save} icon="pi pi-check" className="p-button-text" onClick={this.createScheduleTransfer} />}
            </FormattedMessage>
        </> 
      );

      const deletePaymentDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeletePaymentDialog} />
            <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deletePayment} />
        </>
      );

      const deletePaymentsDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeletePaymentsDialog} />
            <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedPayments} />
        </> 
      ); 

      const schudelePaymentValidationDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideSchedulePaymentValidationDialog} />
        </> 
      ); 

 /* const dateFilter = <InputText 
  value={this.state.filterInputDate} 
  onChange={(e) => this.onFilterDateChange(e)} 
  className="p-column-filter" placeholder="Start Date"/>;
*/


       return(       
        <div>
          <div>
          <div className="surface-section px-4 py-5 md:px-6 lg:px-8">
          <div className="grid flex align-items-start flex-column lg:justify-content-between lg:flex-row">
              <Toast ref={(el) => this.toast = el} />  
              <div className='col'>
              <div><label>Student:</label> <span className='text-3xl text-900'>{student}</span> {validateInvoice === 1 ? <i className='pi pi-check-circle' style={{fontSize: '15px', color: '#51FF00'}}/> : <i className='pi pi-exclamation-triangle' style={{fontSize: '15px', color: '#FFCD00'}}/>}</div>
              <div className="flex align-items-center text-700 flex-wrap">
                  <div className="mr-5 flex align-items-center mt-3">
                      <i className="pi pi-calendar mr-2"></i>
                      <span>Created at: {created_at}</span>
                  </div>
                  <div className="mr-5 flex align-items-center mt-3">
                      <i className="pi pi-user mr-2"></i>
                      <span>Created by: {agent}</span>
                  </div>
                  <div className="mr-5 flex align-items-center mt-3">
                      <i className="pi pi-at mr-2"></i>
                      <span>Agent email: {agentEmail}</span>
                  </div>
                  <div className="flex align-items-center mt-3">
                      <i className="pi pi-building mr-2"></i>
                      <span>Office: {agency}</span>
                  </div>
              </div>
            </div>
      {/*<h3 className="card-label" >
            <FormattedMessage id="ACCOUNTING_TRANSFER.TITLE"/>
        <span span className="d-block text-muted pt-2 font-size-sm">
            <FormattedMessage id="ACCOUNTING_TRANSFER.DESCRIPTION"/>
            </span>
            </h3> */}     

<div className='col' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
                {/*TOOL BAR */} 
                      
                          <div style={{ marginRight: 10}}>
                            <FormattedMessage id="GENERAL.EXPORT">
                              {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                            </FormattedMessage>
                          </div>
                          {/*<div style={{ marginRight: 10}}>
                            {this.toolbarButtonED()}
                          </div>
                          <div style={{ marginRight: 10}}>
                            {this.toolbarButtonSchedule()}
                            </div>*/}
                          <div style={{ marginRight: 10}}>
                          <p> <span className="p-input-icon-left">
                          <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                          </span></p>
                          </div>  
                </div>
            </div>

          </div>
          <br/>
          <br/>
          <br/>

          
                      <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onIndexChange(e.index )} >
                          <TabPanel header={this.state.campusText.Transfers}>
                            <div>
                                  {/*SHOW PENDDING TRANSFER */}
                                  {this.tableTransfers()}
                            </div>
                          </TabPanel>
                          <TabPanel header={this.state.campusText.ScheduleTransferPayments}>
                              <div>
                                  {/*SHOW SHEDULED TRANSFER */}
                                  {this.schedulesPayments()}

                              </div>
                          </TabPanel>
                          <TabPanel header={this.state.campusText.TransferPayments}>
                              <div>
                                  {/*SHOW SHEDULED TRANSFER */}
                                  {this.transferedPayments()}

                              </div>
                          </TabPanel>
                          <TabPanel header={this.state.campusText.TransfersFullyPaid}>
                                <div>
                                    {/*SHOW PAID TRANSFERS */}
                                    {this.tableTransfers()}
                                </div>
                          </TabPanel>
                          <TabPanel header={this.state.campusText.DismissedTransfers}>
                                <div>
                                    {/*SHOW DISMISSED TRANSFERS */}
                                    {this.tableTransfers()}
                                </div>
                          </TabPanel>
                      </TabView>
          
         

          <div> 
         {/*DISABLE TRANSFER */}
          <Dialog visible={this.state.disableTransferDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={disableTransferDialogFooter} onHide={this.hideDisableTransferDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {<span> <FormattedMessage id="ACCOUNTING_TRANSFER.DIALOG_DISABLE"/></span>}
                    </div>
          </Dialog>
          </div>

          <div> 
         {/*ENABLE TRANSFER */}
          <Dialog visible={this.state.enableTransferDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={enableTransferDialogFooter} onHide={this.hideEnableTransferDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {<span> <FormattedMessage id="ACCOUNTING_TRANSFER.DIALOG_ENABLE"/></span>}
                    </div>
          </Dialog>
          </div>

          {/*DISABLE WITH TOOL BAR*/}
          <Dialog visible={this.state.disableTransfersDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={disableTransfersDialogFooter} onHide={this.hideDisableTransfersDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.transfer && <span><FormattedMessage id="ACCOUNTING_TRANSFER.DIALOG_DISABLE_ALL"/></span>}
                    </div>
          </Dialog>


          {/*ENABLE WITH TOOL BAR*/}
          <Dialog visible={this.state.enableTransfersDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={enableTransfersDialogFooter} onHide={this.hideEnableTransfersDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.transfer && <span><FormattedMessage id="ACCOUNTING_TRANSFER.DIALOG_ENABLE_ALL"/></span>}
                    </div>
          </Dialog>

           {/*DELETE PAYMENT */}
           <Dialog visible={this.state.deletePaymentDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={deletePaymentDialogFooter} onHide={this.hideDeletePaymentDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {<span> <FormattedMessage id="ACCOUNTING_PAYMENT.DIALOG_DELETE"/></span>}
                    </div>
          </Dialog>

            {/*DELETE WITH TOOL BAR*/}
            <Dialog visible={this.state.deletePaymentsDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={deletePaymentsDialogFooter} onHide={this.hideDeletePaymentsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        { <span><FormattedMessage id="ACCOUNTING_PAYMENT.DIALOG_DELETE_ALL"/></span>}
                    </div>
           </Dialog>

           <Dialog visible={this.state.schedulePaymentValidationDialog} style={{ width: '450px' }} header={this.state.campusText.Warning} modal footer={schudelePaymentValidationDialogFooter} onHide={this.hideSchedulePaymentValidationDialog}>
              <div className="confirmation-content">
                  <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                  { <span><FormattedMessage id="ACCOUNTING_TRANSFER.DIALOG_CURRENCIES_NOT_SAME"/></span>}
              </div>
         </Dialog>

            {/*SCHEDULE PAYMENTS WITH TOOL BAR*/}
          <Dialog visible={this.state.schedulePaymentsDialog} contentStyle={{maxHeight:"600px", width:"500px", height:"420px", overflow:"auto"}} header={this.state.campusText.SchedulePayment} modal footer={schedulePaymentsDialogFooter} onHide={this.hideSchedulePaymentsDialog}>
                    <h6 className="confirmation-content">
                        { <span><FormattedMessage id="ACCOUNTING_TRANSFER.DIALOG_SCHEDULE_PAYMENTS_ALL"/></span>}
                    </h6>
                    <br></br>    
              {/* Shudele Date*/}
           <div className="form-group row">
           <div className="col-lg-3">
              <span className="p-float-label">  
                <Calendar 
                style={{ width: 190, height:33 }}
                showIcon
                margin="dense"
                variant="outlined"
                readOnlyInput="true" 
                dateFormat="dd/mm/yy" 
                id="cheduleDate" 
                value={this.state.paymentTransfer.scheduleDate || this.state.campusText.ScheduleDate} 
                onChange={(e)  => this.onScheduleDateChange(e,'scheduleDate')} 
                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentTransfer.scheduleDate})}
                />
              <label htmlFor="scheduleDate">{this.state.campusText.scheduleDate}</label>
              </span>
              <small id="scheduleDate-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxScheduleDate(this.state)}</small>
              {this.state.messageSchedulePayments}
              </div>   
              </div>
          </Dialog>

          <Dialog visible={this.state.invoiceTransferDialog} contentStyle={{maxHeight:"300px", width:"700px", height:"200px", overflow:"auto"}} header={this.state.campusText.ToTransfer} modal footer={invoiceTransferDialogFooter} onHide={this.hideInvoiceTransferDialog}>
            <br></br>
              {/* Student*/}
              <div className="form-group row">
              <div className="col-lg-12">
                  <h6 className="card-label" >{this.state.campusText.Student}: {this.state.transfer.student}</h6>
                  <h6 className="card-label" >{this.state.campusText.Office}:  {this.state.transfer.agency}</h6>
              </div>    
              </div>      
              <br></br>
              {/* Transfer Total*/}
              <div className="form-group row">
              <div className="col-lg-4">
                  <span className="p-float-label">      
                  <InputNumber
                  inputStyle	={{ width: 100, height:33}}
                  locale="en-US" 
                  minFractionDigits={2}                 
                  id="trasferTotal"
                  variant="outlined"
                  margin="dense"
                  min="0"
                  value={this.state.transfer.transferTotal}             
                  onValueChange={(e) => this.onInputChange(e, 'transferTotal')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.transfer.transferTotal})}
                  /> {this.state.transfer.code}
                  <label htmlFor="transferTotal">{this.state.campusText.TransferTotal}</label>
              </span>
              <small id="transferTotal-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxTransferTotal(this.state)}</small>
              </div>   
              {/* Commission*/}
              <div className="col-lg-4">
                    <span className="p-float-label">      
                    <InputNumber
                    locale="en-US" 
                    minFractionDigits={2}
                    min="0"
                    inputStyle={{ width: 100, height:33, backgroundColor:'#e3e4e5' }}
                    readOnly='true'
                    id="commission"
                    variant="outlined"
                    margin="dense"
                    value={this.state.transfer.commission}             
                    /> {this.state.transfer.code}
                    <label htmlFor="commission">{this.state.campusText.AgencyCommission}</label>
                  </span>
                  </div>    
               {/* Balance*/}
               <div className="col-lg-4">
                    <span className="p-float-label">      
                    <InputText
                    locale="en-US" 
                    minFractionDigits={2}
                    style	={{ width: 100, height:33, backgroundColor:'#e3e4e5' }}
                    readOnly='true'
                    id="commission"
                    variant="outlined"
                    margin="dense"
                    value={this.state.transfer.transferBalance}             
                    /> {this.state.transfer.code}
                    <label htmlFor="commission">{this.state.campusText.TransferBalance}</label>
                  </span>
                  </div>   
               </div>      
          </Dialog>
          

          <Dialog visible={this.state.paymentTransferDialog} contentStyle={{maxHeight:"800px", width:"900px", height:"550px", overflow:"auto"}} header={this.state.campusText.PaymentTransfer} modal footer={paymentTransferDialogFooter} onHide={this.hidePaymentTransferDialog}>
          <h6 className="card-label" >{this.state.campusText.Invoice}: {this.state.transfer.provider} - {this.state.transfer.invoiceName}
                    {/*<FormattedMessage id="ADMIN_INVOICE.TITLE"/>*/}
                  </h6>
                  <br></br>
                  <br></br>
                  <div className="form-group row">  
                          <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                           locale="en-US" 
                           minFractionDigits={2}
                          style={{ width: 190, height:33, backgroundColor:'#e3e4e5' }}
                          id="commmission"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symbolCommissionCurrency}             
                          />
                          <label htmlFor="commission">{this.state.campusText.AgencyCommission}</label>
                      </span>
                      </div>   

                      <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                           locale="en-US" 
                           minFractionDigits={2}
                          style={{ width: 190, height:33, backgroundColor:'#e3e4e5' }}
                          id="toTransfer"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symboltoTransferCurrency}             
                          />
                          <label htmlFor="toTransfer">{this.state.campusText.ToTransfer}</label>
                      </span>
                      </div>   

                      <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                           locale="en-US" 
                           minFractionDigits={2}
                          style={{ width: 190, height:33, backgroundColor:'#e3e4e5' }}
                          id="balance"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symbolBalanceTransferCurrency}             
                          />
                          <label htmlFor="balance">{this.state.campusText.Balance}</label>
                      </span>
                      </div>   
              </div>
              <br></br>
              <br></br>

              <div className="form-group row">
              <div className="col-lg-3">  
              <span className="p-float-label">   
                <Dropdown
                  style={{ width: 190, height:33 }}
                  id="account_name"
                  variant="outlined"
                  placeholder={this.state.campusText.Account}
                  margin="dense"
                  value= { this.state.selectedAccount || this.state.campusText.Account } 
                  options={this.state.accounts} 
                  onChange={this.onAccountChange}  
                  optionLabel="account_name" 
                  optionValue="id"    
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentTransfer.originAccount})}
                  />
                  <label htmlFor="account_name">{this.state.campusText.Account}</label>
              </span>
              <small id="account_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxOriginAccount(this.state)}</small>
              </div>  
              
              <div className="col-lg-3">
              <span className="p-float-label">  
                <Calendar 
                style={{ width: 190, height:33 }}
                showIcon
                margin="dense"
                variant="outlined"
                readOnlyInput="true" 
                dateFormat="dd/mm/yy" 
                id="payment_date" 
                placeholder={this.state.campusText.PaymentDate}
                value={this.state.paymentTransfer.paymentDate || this.state.campusText.PaymentDate} 
                onChange={(e) => this.onDateChange(e, 'paymentDate')} 
                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentTransfer.paymentDate})}
                />
              <label htmlFor="payment_date">{this.state.campusText.PaymentDate}</label>
              </span>
              <small id="payment_date-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPaymentDate(this.state)}</small>
              </div>

              <div className="col-lg-3">  
              <span className="p-float-label">   
                <Dropdown
                  style={{ width: 190, height:33 }}
                  id="method_name"
                  variant="outlined"
                  placeholder={this.state.campusText.PaymentMethod}
                  margin="dense"
                  value= { this.state.selectedMethod || this.state.campusText.PaymentMethod } 
                  options={this.state.methods} 
                  onChange={this.onMethodChange}  
                  optionLabel="method_name" 
                  optionValue="id"    
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentTransfer.fk_id_paymentTransferMethod})}
                  />
                  <label htmlFor="transfer_method">{this.state.campusText.PaymentMethod}</label>
              </span>
              <small id="transfer_method-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFk_id_paymentTransferMethod(this.state)}</small>
              </div>
              </div>
              {this.amountNrate()}
          </Dialog>

           <Dialog dismissableMask='true' visible={this.state.scheduleTransferDialog} contentStyle={{maxHeight:"600px", width:"800px", height:"550px", overflow:"auto"}} header={this.state.campusText.ScheduleTransfer} footer={scheduleTransferDialogFooter} onHide={this.hideScheduleTransferDialog}>
           <br></br>
              <div className="form-group row">
              <div className="col-lg-12">
                  <h6 className="card-label" >{this.state.campusText.Student}: {this.state.transfer.student}</h6>
                  <h6 className="card-label" >{this.state.campusText.Office}:  {this.state.transfer.agency}</h6>
              </div>    
              </div>      
              <br></br>
                      <div className="form-group row">  
                          <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                           locale="en-US" 
                           minFractionDigits={2}
                          style={{ width: 190, height:33, backgroundColor:'#e3e4e5' }}
                          id="commmission"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symbolCommissionCurrency}             
                          />
                          <label htmlFor="commission">{this.state.campusText.AgencyCommission}</label>
                      </span>
                      </div>   

                      <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                           locale="en-US" 
                           minFractionDigits={2}
                          style={{ width: 190, height:33, backgroundColor:'#e3e4e5' }}
                          id="toTransfer"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symboltoTransferCurrency}             
                          />
                          <label htmlFor="toTransfer">{this.state.campusText.ToTransfer}</label>
                      </span>
                      </div>   

                      <div className="col-lg-3">
                          <span className="p-float-label">      
                          <InputText
                           locale="en-US" 
                           minFractionDigits={2}
                          style={{ width: 190, height:33, backgroundColor:'#e3e4e5' }}
                          id="balance"
                          readOnly="true"
                          variant="outlined"
                          margin="dense"
                          value={this.state.transfer.symbolBalanceTransferCurrency}             
                          />
                          <label htmlFor="balance">{this.state.campusText.Balance}</label>
                      </span>
                      </div>   
              </div>
              {/* Shudele Date*/}
           <div className="form-group row">
               {/* Schedule Amount*/}
               <div className="col-lg-4">
                  <span className="p-float-label">      
                  <InputNumber
                  locale="en-US" 
                  minFractionDigits={2}   
                  min="0"              
                  inputStyle={{ width: 190, height:33 }}
                  id="scheduleAmount"
                  variant="outlined"
                  margin="dense"
                  value={this.state.paymentTransfer.paymentEquivalentAmount}             
                  onValueChange={(e) => this.onScheduleAmountChange(e)}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentTransfer.paymentEquivalentAmount})}
                  /> {this.state.transfer.code}
                  <label htmlFor="scheduleAmount">{this.state.campusText.Amount}</label>
              </span>
              <small id="transferTotal-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPaymentEquivalentAmount(this.state)}</small>
              </div>   
           <div className="col-lg-3">
              <span className="p-float-label">  
                <Calendar 
                style={{ width: 190, height:33 }}
                showIcon
                margin="dense"
                variant="outlined"
                readOnlyInput="true" 
                dateFormat="dd/mm/yy" 
                id="cheduleDate" 
                value={this.state.paymentTransfer.scheduleDate || this.state.campusText.ScheduleDate} 
                onChange={(e)  => this.onScheduleDateChange(e,'scheduleDate')} 
                required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.paymentTransfer.scheduleDate})}
                />
              <label htmlFor="scheduleDate">{this.state.campusText.scheduleDate}</label>
              </span>
              <small id="scheduleDate-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxScheduleDate(this.state)}</small>
              </div>
              </div>
           </Dialog>
          </div>

        </div>  
         
    )
  }
}

const mapStateToProps = state => ({
  invoice: state.auth.invoiceId,
  user: state.auth.user,
});

export default connect(mapStateToProps,auth.actions)(TransfersTable);









