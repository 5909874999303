import React from "react";
import {useSubheader} from "../../_metronic/_core";
import Course from "../modulesAdmin/Course"
import {useSelector} from "react-redux";
import { useIntl } from "react-intl";

export const CoursePage = () => {
    const suhbeader = useSubheader();
    const dataCourse = useSelector(state => state.auth.dataCourse);
    const int1 = useIntl();

    suhbeader.setTitle(`${int1.formatMessage({id: "LEARNING_GENERAL.COURSE"})} - ${dataCourse.course}`);
    return (<Course/>)
    
}