import React from "react";
//import {useSubheader} from "../../_metronic/_core";

import Analytics from "../modulesAccounting/Analytics/Analytics"

export const AnalyticsPage = () => {
  /*const suhbeader = useSubheader();
  suhbeader.setTitle("Anals");*/

  return (<><Analytics/></>);
};