import React from "react";
import {
    AdvanceTablesWidget6,
} from "../_partials/widgets";
export default function ReportsV1() {
    return (<>
            <div className="row">
                {/*
                <div className="col-lg-4">
                    <MixedWidget14 className="card-stretch gutter-b" />
                </div>
                */}
                <div className="col-lg-12">
                    <AdvanceTablesWidget6 className="card-stretch gutter-b" />
                </div>
            </div>
    </>);
}
