// ScriptEdvisor
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApolloClient, InMemoryCache, HttpLink, gql, ApolloProvider, useQuery } from '@apollo/client';
// import { ApolloProvider } from 'react-apollo';
import axios from 'axios'
import ServerURL from '../../../ServerURL'

import { LegacyInvoices } from './LegacyInvoices';



// import { gql } from 'apollo-boost';
// import { Query } from 'react-apollo';

import "./styles.css";
import { Button, Chips, InputText } from 'primereact';
let api_keyEdvisor

const url = ServerURL();

export const InvoicesQuery = ({ result }) => {

    // const {externalIds} = props

    console.log("result: ", result);

    // query($yesterday:DateOnly) {
    // created: {gte:$yesterday},

    // const externalIds = props.externalIds.toString()
    // if (externalIds !== '') {
    // console.log('EXTERNAL IDS: ', externalIds);

    // let GET_DATA = gql
    //     `
    //             query RootQueryType {
    //             invoiceList(
    //             pagination: { limit: 100, offset:0 }, 
    //             filter: {
    //                 agencyIds: [6452],

    //             }) {
    //                 count
    //                 data {
    //                     invoiceId
    //                     externalId
    //                     createdByUserId
    //                     depositDateDue
    //                     lastUpdatedByUserId
    //                     created
    //                     modified

    //                     invoiceType {
    //                         invoiceTypeId
    //                         codeName
    //                     }
    //                     invoiceStatus {
    //                         invoiceStatusId
    //                         codeName
    //                     }

    //                     paymentCurrency {
    //                         currencyId
    //                         code
    //                         symbol
    //                         oneUsd
    //                     }
    //                     totalAmount

    //                     student{
    //                         studentId
    //                         firstname
    //                         lastname
    //                         preferredName
    //                         email
    //                         agencyId
    //                         agency {
    //                         agencyId
    //                         agencyCompanyId
    //                         name
    //                         email
    //                             agencyCompany{
    //                             agencyCompanyId
    //                             name
    //                             email
    //                             websiteUrl
    //                             } 
    //                         }
    //                         owner{
    //                             userId
    //                             firstname
    //                             lastname
    //                             email 
    //                         }
    //                     }

    //                     rawItems {
    //                         invoiceItemId
    //                         invoiceId
    //                         invoiceItemType{
    //                             invoiceItemTypeId
    //                             codeName   
    //                             displayOrder      
    //                         }
    //                         school
    //                         program
    //                         isAgencyItem
    //                         isGlobalFee
    //                         position
    //                         durationAmount
    //                         durationTypeId
    //                         startDate
    //                         endDate
    //                         amount
    //                         isAmountPerDuration
    //                         serviceFeeMultiplier
    //                         currency{
    //                             currencyId
    //                             code
    //                             symbol
    //                             oneUsd
    //                         }
    //                         snapshotExtensionDurationTypeId
    //                         snapshotFreeDurationAmount
    //                         snapshotBonusDurationAmount
    //                         snapshotPromotionApplicableTypeId
    //                         snapshotPromotionSecondaryTypeId
    //                         snapshotPromotionPercentage
    //                         offering{
    //                             school{
    //                                 name
    //                             schoolCompany{
    //                                 name
    //                             }
    //                             country{
    //                                 code
    //                                 continent
    //                             }
    //                         }
    //                     }
    //                     notes

    //                     created
    //                     modified
    //                 }
    //                 invoiceFiles {
    //                 fileId
    //                 uploaderUserId
    //                 mimeType
    //                 fileExtension
    //                 name
    //                 path
    //                 url
    //                 created
    //                 modified
    //                 }

    //                 } 
    //             }

    //             }
    //     `

    const [loading, setLoading] = useState(false)
    const [externalId, setExternalId] = useState(null)

    const getInvoices = () => {
        setLoading(true)

        // externalIds.toString()
        // console.log('EXTERNAL IDS: ', externalIds.toString());

        setTimeout(() => {
            setLoading(false)
        }, 10000)
    }



    // console.log("GET_DATE: ", GET_DATA);
    // const { data, loading, error } = useQuery(GET_DATA)

    // console.log("DATA: ", data, loading, error);

    // const { data, error, loading } = useQuery(GET_DATA)

    // console.log('DATA: ', data);

    // if (error) return <span>{error}</span>

    return (
        <div>
            <div className='card'>
                {/* <div className='p-fluid grid'>
                    <h2> Legacy script edvisor: </h2>
                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={externalId} onChange={(e) => setExternalId(e.target.value)} />
                            <label htmlFor="inputtext">InputText</label>
                        </span>
                        <Button label='Submit' icon='pi pi-pi-check' loading={loading} onClick={getInvoices} />
                    </div>
                    <InputText placeholder='External Id from Edvisor' keyfilter='int' value={externalId} onChange={(e) => setExternalId(e.target.value)} separator=',' />
                    {
                        externalId ?
                            loading ?
                                <LegacyInvoices externalIds={externalId.toString()} />
                                :
                                <></>
                            :
                            <></>
                    }
                </div> */}
                <h3>Legacy script edvisor</h3>
                <h5>Download your invoices directly from Edvisor </h5>
                <div className="grid p-fluid justify-content-center">
                    <div className="col-6 md:col-4">
                        <div className="p-inputgroup">
                            <InputText placeholder='External ID from Edvisor' id="inputtext" value={externalId} onChange={(e) => setExternalId(e.target.value)} />
                            <Button icon="pi pi-download" className="p-button-warning" loading={loading} onClick={getInvoices} />
                        </div>
                    </div>

                    {
                        externalId ?
                            loading ?
                                <div className='col-12 md:col-4'>
                                    <LegacyInvoices externalIds={externalId.toString()} />
                                </div>
                                :
                                <></>
                            :
                            <></>
                    }
                </div>

            </div>
        </div>

        // <Query query={GET_DATA}>

        //     {({ loading, error, data }) => {
        //         if (loading) return <p>Loading...</p>;
        //         if (error) return <p>Error!</p>;

        //         let options = {
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             }
        //         }

        //         let requestOne = axios.post(`${url}/api/auth/invoice`, data.invoiceList.data, options);
        //         let requestTwo = axios.post(`${url}/api/auth/invoiceItem`, data.invoiceList.data, options)
        //         let requestThree = axios.post(`${url}/api/auth/net_invoice`, data.invoiceList.data, options)
        //         let requestFour = axios.post(`${url}/api/auth/invoiceNetItems`, data.invoiceList.data, options);



        //         // let requestFour = axios.post(`${url}/api/public/students-edvisor`, data.invoiceList.data, options);

        //         console.log("requestThree: ", requestThree);

        //         axios
        //             .all([
        //                 requestOne,
        //                 requestTwo,
        //                 requestThree,
        //                 requestFour
        //             ])
        //             .then(
        //                 axios.spread((...responses) => {
        //                     const invoice = responses[0].data;
        //                     const invoiceItems = responses[1].data
        //                     const net_invoice = responses[2].data;
        //                     const invoiceNetItems = responses[3].data
        //                     // const student = responses[0].data  

        //                     console.log(invoice)
        //                     console.log(net_invoice)
        //                     console.log(invoiceItems);
        //                     console.log(invoiceNetItems);
        //                     // console.log(student);

        //                 }))
        //             .catch(errors => {
        //                 console.error(errors);
        //             });

        //         // console.log("DATA:", externalIds)


        //         return (
        //             <div>
        //                 Information loaded!
        //             </div>

        //         )
        //     }}
        // </Query>

        // <span>{props.externalIds}</span>
    )
        // }


        // for ( let i in props.externalIds) {
        //     console.log("EXTERNAL IDS: ", props.externalIds[i]);
        // }


        ;
};


function InvoicesTable() {

    // console.log("EXTERNAL IDS: ", externalIds);

    return (
        <div className="App">
            <InvoicesQuery />
        </div>
    );
}

export default () => {

    const [apiKey, setApiKey] = useState(null);
    const userData = useSelector((state) => state.auth.user)

    // console.log(externalIds);


    // console.log(userData);


    React.useEffect(() => {
        axios.get(`${url}/api/auth/enterprise/${userData.fk_id_enterprise}`).then((response) => {
            setApiKey(response.data.api_key);
        });
    }, [userData.fk_id_enterprise]);

    // console.log(apiKey);

    api_keyEdvisor = apiKey;

    const cache = new InMemoryCache();
    const client = new ApolloClient({
        connectToDevTools: true,
        cache,
        link: new HttpLink({
            uri: 'https://api.edvisor.io/graphql',
            headers: {
                authorization: `Bearer ${api_keyEdvisor}`
            },
        }),
    })
    return (
        <ApolloProvider client={client}> {/* en "client" debe ir la configuración de apollo */}
            <InvoicesTable />
        </ApolloProvider>
    )
};


