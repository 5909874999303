import React from "react";
import {useSubheader} from "../../_metronic/_core";
import CoursesTable from "../modulesAdmin/AdminEnterprises/AdminPrograms/CoursesTable"

export const CoursesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Courses");

  return (<><CoursesTable/></>);

};