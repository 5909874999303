import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";

// import axios from 'axios';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
// import ServerURL from '../../../ServerURL';
import { connect } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { FormattedMessage } from 'react-intl';

// HOME DASHBOARD
import { AnalyticsPage } from '../../pagesAccounting/AnalyticsPage';
import { DashboardPage } from "../../pagesSchool/DashboardPage";
import { DashboardPageWiyu } from "../../pagesWiyu/DashboardPage";



// import { useSelector } from "react-redux";


// const url = ServerURL();
// const user = state.auth.user
// const { user } = useSelector(state => state.auth);


class Inicio extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };

    this.id_agente = 64;
  }

  render() {

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <div className="dataview mb-5">
          <div>
            <div className="row pt-6" style={{ justifyContent: "center" }}>
              <h1 className="text-uppercase"><FormattedMessage id="GENERAL_MESSAGE.WELCOME_TO" /> WISSIFY</h1>
            </div>
            <div className="text-center">
              {/* <img alt="Logo Wissify" src={toAbsoluteUrl("/media/logos/wissify-dark.png")} style={{width:"100%"}}/> */}
              {
                this.props.user.accounting === 1 ?
                  <>
                    <h1> Accounting analytics</h1>
                    <AnalyticsPage sales />
                  </>
                  :
                  <></>
              }
              {
                this.props.user.learning === 1 ?
                  <>
                    <h1> Learning analytics</h1>
                    <DashboardPage />
                  </>
                  :
                  <></>
              }
              {
                this.props.user.wiyu === 1 ?
                  <>
                    <h1> Wiyu analytics</h1>
                    <DashboardPageWiyu />
                  </>
                  :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, auth.actions)(Inicio);