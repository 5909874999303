import React from "react";
import {useSubheader} from "../../_metronic/_core";
import Lesson from "../modulesAdmin/Lesson"
import {useSelector} from "react-redux";
import { useIntl } from "react-intl";

export const LessonPage = () => {
    const suhbeader = useSubheader();
    const dataLesson = useSelector(state => state.auth.dataLesson);
    const int1 = useIntl();

    suhbeader.setTitle(`${int1.formatMessage({id: "LEARNING_COURSE.LESSON"})} - ${dataLesson.name}`);
    return (<Lesson/>)
}
