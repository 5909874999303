import React, {Component} from 'react'
import classNames from 'classnames';
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { connect } from "react-redux";
//import { FileUpload } from 'primereact/fileupload';



import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import * as Validation from '../../../Validation';
import { Checkbox } from 'primereact/checkbox';


const url = ServerURL();




class ListAgents extends Component {
            

  
  emptyUser = {
  email: undefined,
  password: undefined,
  first_name: undefined,
  last_name: undefined,
  fullname:undefined,
  phone:undefined,
  fk_id_role: null,
  fk_id_enterprise: this.props.my_user.fk_id_enterprise,
  fk_id_office: this.props.my_user.fk_id_office,
  flphoto:null,
  owner:null,
  learning:0,
  accounting:0,
  accounting_office: '',
  fk_id_boss:null,
}

emptyBoss = {
  fk_id_role: null,
  fk_id_enterprise:null,
  fk_id_office: null
}


 

constructor(props) {
  super(props)


  this.state = { 
      /**
       * Aux messages
       */
      emailMessage: '',
      passwordMessage: '',
      firstNameMessage: '',
      lastNameMessage: '',
      phoneMessage: '',
      fkIdRoleMessage: '',
      learningMessage:'',
      accountingMessage:'',
      accounting_officeMessage:'',
      
      
    user: this.emptyUser,
    boss: this.emptyBoss,
    users:null,
    enterprises:null,
    roles:null,
    selectedRole: null,
    selectedEnterprise:null,
    selectedOffice: null,
    selectedBoss: null,
    CheckedOwner:false,
    CheckedLearning:true,
    CheckedAccounting:false,
    resetRole:null,
    resetEnterprise: null,
    resetOffice: null,
    globalFilter: null,
    selectedUsers: null,
    userDialog: false,
    disableUserDialog: false,
    enableUserDialog: false,
    disableUsersDialog: false,
    enableUsersDialog: false,
    assignUsersDialog: false,
    statusUser:1,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveUser = this.saveUser.bind(this);
  this.disableUser = this.disableUser.bind(this);
  this.enableUser = this.enableUser.bind(this);
  this.openNew = this.openNew.bind(this);
  this.userBodyTemplate = this.userBodyTemplate.bind(this);
  this.userBodyRoleTemplate = this.userBodyRoleTemplate.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editUser = this.editUser.bind(this);
  this.confirmDisableUser= this.confirmDisableUser.bind(this);
  this.confirmEnableUser= this.confirmEnableUser.bind(this);
  this.confirmDisableSelected = this.confirmDisableSelected.bind(this);
  this.confirmEnableSelected = this.confirmEnableSelected.bind(this);
  this.confirmAssignSelected = this.confirmAssignSelected.bind(this);
  this.disableSelectedUsers = this.disableSelectedUsers.bind(this);
  this.enableSelectedUsers = this.enableSelectedUsers.bind(this);
  this.assignSelectedUsers = this.assignSelectedUsers.bind(this);
  this.hideDisableUserDialog = this.hideDisableUserDialog.bind(this);
  this.hideEnableUserDialog = this.hideEnableUserDialog.bind(this);
  this.hideDisableUsersDialog = this.hideDisableUsersDialog.bind(this);
  this.hideEnableUsersDialog = this.hideEnableUsersDialog.bind(this);
  this.hideAssignUsersDialog = this.hideAssignUsersDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onRoleChange = this.onRoleChange.bind(this);
  this.onPhoto = this.onPhoto.bind(this);
  this.onBossChange = this.onBossChange.bind(this);
  this.onOfficeChange = this.onOfficeChange.bind(this);

  this.id_gerente = 62;  
  this.id_coordinador = 63;  
  this.id_agente = 64;  

  }
  
     

      componentDidMount() {
        this.getUsers()
        this.getRoles(11)
        this.getOffices()
      }

      getUsers = async () =>{
        try {
          await axios.get(`${url}/api/auth/usersa/${this.state.user.fk_id_enterprise}`)
          .then(res => {
            const users = res.data;
            console.log(users)
            this.setState({ users, statusUser:1 })
          });                     
          }catch(error){
        }
      }

      getUsersDisabled= async () =>{   
        try {
            let res = axios.get(`${url}/api/auth/usersDisabled`)
            .then(res => {
              const users = res.data;
              this.setState({ users, statusUser:0 })
            });                     
              console.log(res.data)
            }catch(error){

       }
      }

      getEnterprises(id){
        try {
           
          let res = axios.get(`${url}/api/auth/enterprises-byroletype/${id}`)
          .then(res => {
            const enterprises = res.data;
            this.setState({ enterprises })
            console.log(enterprises)
  
          });                     
            console.log(res.data)
          }catch(error){
  
      }
    }

      getRoles(id){
        try {

          let res = axios.get(`${url}/api/auth/roles-byroletype/${id}`)
          .then(res => {
            const roles = res.data;
            const newArrayRoles = []
            roles.forEach(rol => {
              if(rol.id !== 58 && rol.id !== this.props.my_user.fk_id_role){
                newArrayRoles.push(rol);
              }
            })
            this.setState({ roles: newArrayRoles })

          });                     
            console.log(res.data)
          }catch(error){

      }
    }

    async getBosses(boss){
      try {

        this.setState({boss})
        
        
        if(boss.fk_id_role!=null){ 

              await axios.get(`${url}/api/auth/bosses/${boss.fk_id_role}/${this.props.my_user.fk_id_enterprise}`)
              .then(res => {
                console.log(res.data)   
                const bosses = res.data;
                this.setState({ bosses })
              });                  
          } 
        }catch(error){

      }
    }

    getOffices = async () => {
      try {
        let res = axios.get(`${url}/api/auth/offices`)
        .then(res => {
          const offices = res.data
          this.setState({ offices })
        })
        console.log("RES: ", res.data);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }

    getRol(role){
      let Message = null;
      switch (role) {
        case this.id_gerente:
          Message = "MANAGER";
          break;
        case this.id_coordinador:
            Message = "COORDINATOR";
          break;
        case this.id_agente:
          Message = "AGENT";    
          break;
        default:
          Message = "AGENT";
          break;
      }
      return Message;
    }

    isValid(){

      const { email, password, first_name, last_name, phone, fk_id_role, fk_id_enterprise } = this.state.user
      const { CheckedAccounting } = this.state;
  

      const emailMessage = Validation.validateEmail(this.state)
      this.setState({ emailMessage })
      const passwordMessage = Validation.validatePassword(this.state)
      this.setState({ passwordMessage })
      const firstNameMessage = Validation.validateFirstName(this.state)
      this.setState({ firstNameMessage })
      const lastNameMessage = Validation.validateLastName(this.state)
      this.setState({ lastNameMessage })
      const phoneMessage = Validation.validatePhone(this.state)
      this.setState({ phoneMessage })
      const fkIdRoleMessage = Validation.validateRole(this.state)
      this.setState({ fkIdRoleMessage })

      // let accounting_officeMessage = '';
      // if(CheckedAccounting){
      //   accounting_officeMessage = Validation.validateAccountingOffice(this.state)
      //   this.setState({ accounting_officeMessage })
      // }
      // console.log(accounting_officeMessage)
      if(email && password && first_name && last_name && phone && (fk_id_role || this.props.my_user.fk_id_role === this.id_coordinador) && fk_id_enterprise && (CheckedAccounting === false || (CheckedAccounting === true))){
        if(!emailMessage && !passwordMessage && !firstNameMessage && !lastNameMessage && !phoneMessage && (!fkIdRoleMessage || this.props.my_user.fk_id_role === this.id_coordinador)){
          return true;
        }else{
          return false;
        }
      }
      return false;
    }

    isValidBoss(){

      const { selectedBoss } = this.state;

      const bossMessage = Validation.validateField(selectedBoss)
      this.setState({ bossMessage })

      if(selectedBoss){
        if(!bossMessage){
          return true;
        }else{
          return false;
        }
      }
      return false;
    } 

      // deleteUser(){
      //   let data = {...this.state.user};

      //   try {
      //   let res = axios.delete(`${url}/api/auth/user/${data.id}`)
      //     .then(res => {
      //     this.getUsers()
      //     console.log(res)
      //   })
      //   if (!res.error) {
      //     this.setState({
      //       data,
      //       deleteUserDialog: false,
      //       user: this.emptyUser
      //   });
      //   this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: 'User Deleted', life: 3000 });
      //   }
      //     }catch(error){
      //     } 
      // }      

     
      async disableUser(){
        let data = {...this.state.user};

        try {
        let res = await axios.put(`${url}/api/auth/disable_user/${data.id}`)
          .then(res => {
          this.getUsers()
        })
        console.log(res)
        //if (!res.error) {
          this.setState({
            data,
            disableUserDialog: false,
            user: this.emptyUser,

        });
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_DISABLED'}), life: 3000 });

        //}
          }catch(error){
          }           
      }      

      async enableUser(){
        let data = {...this.state.user};

        try {
        let res = await axios.put(`${url}/api/auth/enable_user/${data.id}`)
          .then(res => {
          this.getUsersDisabled()
        })
        console.log(res)
        //if (!res.error) {
          this.setState({
            data,
            enableUserDialog: false,
            user: this.emptyUser,

        });
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_ENABLED'}), life: 3000 });

        //}
          }catch(error){
          }           
      } 

      saveUser() {

        let state = { submitted: true };

        const decision = this.isValid();

        // if (this.state.user.first_name.trim()) {       
        if (decision) {      
        
          let data = {...this.state.user};


          const formData = new FormData();
          formData.append('flphoto',this.uploadInput.files[0]);
          formData.append('first_name',data.first_name);
          formData.append('last_name',data.last_name);
          formData.append('email',data.email);
          formData.append('password',data.password);
          formData.append('phone',data.phone);
          if(this.props.my_user.fk_id_role !== this.id_coordinador){
            formData.append('fk_id_role', data.fk_id_role);      
          }else{
            formData.append('fk_id_role', this.id_agente);
          }
          formData.append('fk_id_enterprise',data.fk_id_enterprise);   
          formData.append('fk_id_office', data.fk_id_office);
          formData.append('fk_id_boss', this.props.my_user.id);
          formData.append('learning', this.state.CheckedLearning ? 1 : 0);     
          formData.append('accounting', this.state.CheckedAccounting ? 1 : 0);     

          if(data.accounting_office){
            formData.append('accounting_office',data.accounting_office);
          }

          const config = {
            headers: {
         
           'Content-Type':'multipart/form-data'}
           //,body:JSON.stringify(data)
          }
          
          // console.log(formData,config)

      
          



        if (this.state.user.id) {
            try {
              let res = axios.put(`${url}/api/auth/user/${data.id}`,formData,config)
              .then(res =>{

              this.getUsers()  
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_UPDATED'}), life: 3000 });
              }
            }catch(error){          
          }         

        }else{
          try {
          
            let res = axios.post(`${url}/api/auth/user/`,formData, config)
             .then(res => {
                 this.getUsers()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_CREATED'}), life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          userDialog: false,
          user: this.emptyUser,
          enterprises:null,
          selectedRole: null,
          selectedEnterprise: null,
          selectedOffice: null,
          resetRole:null, 
          resetEnterprise:null,
          resetOffice: null,      
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.setState({
            user: this.emptyUser,
            selectedRole: null,
            selectedEnterprise: null,
            selectedOffice: null,
            resetRole:"Role",
            submitted: false,
            userDialog: true,       
        })

      }

      confirmAssignSelected() {
        this.setState({
            
            selectedRole: null,
            selectedEnterprise: null,
            selectedRoleType: null, 
            selectedOffice: null,
            resetRole:"Role",
            resetEnterprise:"Enterprise",
            resetOffice: 'Office',
            submitted: false,
            assignUsersDialog: true,      
        })


      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let user = {...this.state.user};
        user[`${name}`] = val;

        this.setState({ selectedRole: e.value,selectedEnterprise: e.value,user });
      }

      onRoleChange(e) {
        let user = {...this.state.user};
        let boss = {...this.state.boss};

        boss['fk_id_role'] = e.value;
        user['fk_id_role'] = e.value;

        this.setState({ selectedRole: e.value, user});

        this.getBosses(boss)
      }

      onOfficeChange(e) {
        let user = {...this.state.user}
        let boss = {...this.state.boss}

        boss[`fk_id_office`] = e.value
        user['fk_id_office'] = e.value

        console.log("E: ", e.value);

        this.setState({ selectedOffice: e.value, user})

        this.getBosses(boss)
      }

      onBossChange(e) { 
        let user = {...this.state.user};
        user['fk_id_boss'] = e.value;

        this.setState({ selectedBoss: e.value, user});

      }

      hideDialog() {
          this.setState({
              submitted: false,
              userDialog: false,    
              enterprises:null, 
              emailMessage: '',
              passwordMessage: '',
              firstNameMessage: '',
              lastNameMessage: '',
              phoneMessage: '',
              fkIdRoleMessage: '',
              accounting_officeMessage: '',
              CheckedLearning: true,
              CheckedAccounting: false,
            })  
      }

      editUser(user) {
        console.log(user)
        this.setState({
          selectedRole: null,
          selectedEnterprise: null,
          resetRole:null, 
          resetEnterprise:null,            
          user: { ...user,
            accounting_office: user.accounting_office === "null" ? "" : user.accounting_office
          },
          CheckedLearning: user.learning === 1 ? true : false, 
          CheckedAccounting: user.accounting === 1 ? true : false, 
          userDialog: true
        })
      }

      confirmEnableUser(user) {
        this.setState({
            user,
            enableUserDialog: true
        })
      }

      confirmDisableSelected() {
        this.setState({ disableUsersDialog: true })
      }



      confirmEnableSelected() {
        this.setState({ enableUsersDialog: true })
      }

      confirmDisableUser(user) {
        this.setState({
            user,
            disableUserDialog: true
        })
      }

      disableSelectedUsers() {
        let users = this.state.users.filter(val => !this.state.selectedUsers.includes(val));
        let data = this.state.users.filter(val => this.state.selectedUsers.includes(val));

        console.log("DATA:",data)
   
        try {
        
        let res = axios.put(`${url}/api/auth/disable_users`,{data})
          .then(res => {
          this.getUsers()
        })
        if (!res.error) {
          this.setState({
            users,
            disableUsersDialog: false,
            selectedUsers: null
        });
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_DISABLED'}), life: 3000 });
        }
          }catch(error){
          } 
      }

      enableSelectedUsers() {
        let users = this.state.users.filter(val => !this.state.selectedUsers.includes(val));
        let data = this.state.users.filter(val => this.state.selectedUsers.includes(val));

   
        try {
        
        let res = axios.put(`${url}/api/auth/enable_users`,{data})
          .then(res => {
          this.getUsersDisabled()
        })
        if (!res.error) {
          this.setState({
            users,
            enableUsersDialog: false,
            selectedUsers: null
        });
        this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USER_ENABLED'}), life: 3000 });
        }
          }catch(error){
          } 
      }

      assignSelectedUsers() {

        let data = this.state.users.filter(val => this.state.selectedUsers.includes(val));
        
        for (let i = 0; i < data.length; i++) {
          data[i].fk_id_boss=this.state.selectedBoss;
        }
        if(this.isValidBoss()){
          try {
            let res = axios.put(`${url}/api/auth/assign_users`,{data})
              .then(res => {
                this.getUsers()
                this.setState({ selectedBoss: null})
              })
              if (!res.error) {
                this.setState({
                  assignUsersDialog: false,
                  selectedUsers: null,
                  selectedBoss: null,
              });
              this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USERS_ASSIGNED'}), life: 3000 });
            }
          }catch(error){
          } 
        }
      }

      hideDisableUserDialog() {
        this.setState({ disableUserDialog: false })
      }

      hideEnableUserDialog() {
        this.setState({ enableUserDialog: false })
      }
        
      hideDisableUsersDialog() {
        this.setState({ disableUsersDialog: false });
      }

      hideEnableUsersDialog() {
        this.setState({ enableUsersDialog: false });
      }

      hideAssignUsersDialog() {
        this.setState({ assignUsersDialog: false, selectedBoss: null });
      } 
      
        exportCSV() {
          this.dt.exportCSV();
      }

      onPhoto() {
        this.toast.show({severity: 'info', summary: this.props.intl.formatMessage({id: 'GENERAL.SUCCESSFUL'}), detail: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.FILE_UPDATED'})});
    }
      
      userBodyTemplate(rowData) {
          return (
              <>
                  <Avatar alt={rowData.first_name} src={`${rowData.photo}`} width={32} style={{verticalAlign: 'middle',   margin: "10px", width: "50px", height: "50px",}} />
              </>
          )
      }

      userBodyRoleTemplate(rowData) {
        const role = this.getRol(rowData.fk_id_role);
        return (
          <FormattedMessage id={`GENERAL_ROLES.${role}`}/>
        )
      }
    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editUser(rowData)} />
                {
                  this.props.my_user.fk_id_role !== this.id_agente && (
                    this.buttonED(rowData)
                  )
                }
            </>
        )
      }

      buttonED=(rowData)=>{


        let EDView=[]
    
          if( this.state.statusUser===1){
            EDView.push(
              <Button icon="pi pi-times" className="p-button-danger"  style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDisableUser(rowData)} />
              )
            }else{
             EDView.push(
              <Button icon="pi pi-check" className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmEnableUser(rowData)} />
             )
             }
    
             return EDView
      }
      
      enabledNdisabled=()=>{


        let EDView=[]
    
          if( this.state.statusUser===1){
            EDView.push(
              <FormattedMessage id="OPTION.DISABLED_USER">
              {(message) => <p><Button className="p-button-outlined p-button-secondary" type="button" label={message}  onClick={this.getUsersDisabled} variant='contained'/></p>}
              </FormattedMessage>)
            }else{
             EDView.push(
              <FormattedMessage id="OPTION.ENABLED_USER">
              {(message) => <p><Button className="p-button-outlined p-button-secondary"  type="button" label={message}  onClick={this.getUsers} variant='contained'/></p>}
              </FormattedMessage>)
             }
    
             return EDView
      }

      toolbarButtonED=()=>{


        let EDView=[]
    
          if( this.state.statusUser===1){
            EDView.push(
              <FormattedMessage id="GENERAL.DISABLE">
                {(message) => <Button icon="pi pi-times" label={message}  className="p-button-danger" onClick={this.confirmDisableSelected} disabled={!this.state.selectedUsers || !this.state.selectedUsers.length} />}
              </FormattedMessage>)
            }else{
             EDView.push(
              <FormattedMessage id="GENERAL.ENABLE">
                {(message) => <Button label={message} icon="pi pi-check" className="p-button-danger" onClick={this.confirmEnableSelected} disabled={!this.state.selectedUsers || !this.state.selectedUsers.length} />}
              </FormattedMessage>)
             }
    
             return EDView
      }




      render(){
        const disableUserDialogFooter = (
          <>
            <FormattedMessage id="GENERAL.NO">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDisableUserDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.YES">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.disableUser} />}
            </FormattedMessage>
          </>
        );

        const enableUserDialogFooter = (
          <>
            <FormattedMessage id="GENERAL.NO">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableUserDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.YES">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.enableUser} />}
            </FormattedMessage>
          </>
        );

        const userDialogFooter = (
          <>
            
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveUser} />}
            </FormattedMessage>
          </>
        );

        const disableUsersDialogFooter = (
          <>
            <FormattedMessage id="GENERAL.NO">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDisableUsersDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.YES">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.disableSelectedUsers} />}
            </FormattedMessage>
          </> 
        );

        const enableUsersDialogFooter = (
          <>
            <FormattedMessage id="GENERAL.NO">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableUsersDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.YES">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.enableSelectedUsers} />}
            </FormattedMessage>
          </> 
        );

        const assignUsersDialogFooter = (
          <>  
              <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideAssignUsersDialog} />}
              </FormattedMessage>
              <FormattedMessage id="GENERAL.SAVE">
              {(message) =><Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.assignSelectedUsers} />}
              </FormattedMessage>
          </> 
        );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
            <FormattedMessage id="LEARNING_AGENT_LIST.TITLE"/>
				    <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="LEARNING_AGENT_LIST.SUB_TITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    {
                      this.props.my_user.fk_id_role !== this.id_agente && (
                        
                        <>
                        <div style={{ marginRight: 10}}>
                          {this.toolbarButtonED()}
                        </div>

                        {
                          (this.props.my_user.fk_id_role !== this.id_coordinador || this.props.my_user.owner) ? (
                            <div style={{ marginRight: 10}}>
                              <FormattedMessage id="GENERAL.ASSIGN">
                                {(message) => <Button label={message} icon="pi pi-check" className="p-button-help" onClick={this.confirmAssignSelected} disabled={!this.state.selectedUsers || !this.state.selectedUsers.length} />}
                              </FormattedMessage>
                            </div>
                          )
                          :
                          <></>
                        }

                        <div style={{ marginRight: 10}}>
                          <FormattedMessage id="OPTION.NEW_USER">
                            {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                          </FormattedMessage> 
                        </div>

                        <div style={{ marginRight: 10}}>
                          {this.enabledNdisabled()}
                        </div>
                        </>
                      )
                    }


                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>

          
          <div>
           {/*SHOW USER */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.users} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.USERS_NOT_FOUND'})} loading={this.state.loading}
                        selection={this.state.selectedUsers} onSelectionChange={e => this.setState({ selectedUsers: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="photo" body={this.userBodyTemplate}></Column>
                        <Column field="first_name" header={this.state.campusText.FirstName}   sortable></Column>
                        <Column field="last_name" header={this.state.campusText.LastName}   sortable></Column>
                        <Column field="email" header={this.state.campusText.Email} sortable></Column>
                        <Column field="phone" header={this.state.campusText.Phone}></Column>
                        {/* <Column field="role_type" header='Role Type'></Column>
                        <Column field="enterprise_name" header='Enterprise'></Column> */}
                        <Column field='enterprise_name' header="Enterprise name"></Column>
                        <Column field='accounting_office' header="Office"></Column>
                        <Column field="role" header={this.state.campusText.Role} body={this.userBodyRoleTemplate}  sortable></Column>
                        <Column field="boss" header={this.state.campusText.Boss}   sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         
          <div> 
          {/*DISABLE USER */}
          <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
            {(message) => (
              <Dialog visible={this.state.disableUserDialog} style={{ width: '450px' }} header={message} modal footer={disableUserDialogFooter} onHide={this.hideDisableUserDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {this.state.user && <span> <FormattedMessage id="GENERAL_MESSAGE.USER_DISABLED_MESSAGE"/> <b>{this.state.user.fullname}</b>?</span>}
                </div>
              </Dialog>
            )}
          </FormattedMessage>
          </div>

          <div> 
          {/*ENABLE USER */}
          <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
            {(message) => (
              <Dialog visible={this.state.enableUserDialog} style={{ width: '450px' }} header={message} modal footer={enableUserDialogFooter} onHide={this.hideEnableUserDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {this.state.user && <span> <FormattedMessage id="GENERAL_MESSAGE.USER_ENABLED_MESSAGE"/> <b>{this.state.user.fullname}</b>?</span>}
                </div>
              </Dialog>
            )}
          </FormattedMessage>
          </div>

          {/*DISABLE WITH TOOL BAR*/}
          <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
            {(message) => (
              <Dialog visible={this.state.disableUsersDialog} style={{ width: '450px' }} header={message} modal footer={disableUsersDialogFooter} onHide={this.hideDisableUsersDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {this.state.user && <span><FormattedMessage id="GENERAL_MESSAGE.USERS_DISABLED_MESSAGE"/></span>}
                </div>
              </Dialog>
            )}
          </FormattedMessage>

          {/*ENABLE WITH TOOL BAR*/}
          <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
            {(message) => (
              <Dialog visible={this.state.enableUsersDialog} style={{ width: '450px' }} header={message} modal footer={enableUsersDialogFooter} onHide={this.hideEnableUsersDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {this.state.user && <span><FormattedMessage id="GENERAL_MESSAGE.USERS_ENABLED_MESSAGE"/></span>}
                </div>
              </Dialog>
            )}
          </FormattedMessage>

          {/*ASSIGN WITH TOOL BAR*/}
          <Dialog visible={this.state.assignUsersDialog} contentStyle={{maxHeight:"800px", width:"500px", height:"300px", overflow:"auto"}} header="Assign Users" modal footer={assignUsersDialogFooter} onHide={this.hideAssignUsersDialog}>
              {/* Role*/}
              <br></br>
              <div className="form-group row">
              <div className="col-lg-6">
              <span className="p-float-label">   
              <Dropdown
                  style={{ width: 190, height:33 }}
                  id="role"
                  variant="outlined"
                  placeholder= { this.state.resetRole || this.state.user.role}
                  margin="dense"
                  value={this.state.selectedRole || "role"}   
                  options ={this.state.roles} 
                  onChange={this.onRoleChange} 
                  optionLabel="role" 
                  optionValue="id"
                  />
              <label htmlFor="role"><FormattedMessage id="ADMIN_GENERAL.ROLE"/></label>
              </span>
              {
                this.state.fkIdRoleMessage && (
                  <small id="role-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.fkIdRoleMessage}/></small>
                )
              }
              </div>  
              <div className="col-lg-6">
              <span className="p-float-label">   
              <Dropdown
                  style={{ width: 190, height:33 }}
                  id="boss"
                  variant="outlined"
                  placeholder= { this.state.resetBoss || this.state.user.fullname}
                  margin="dense"
                  value={this.state.selectedBoss || "fullname"}   
                  options ={this.state.bosses} 
                  onChange={this.onBossChange} 
                  optionLabel="fullname" 
                  optionValue="id"
                  />
              <label htmlFor="boss"><FormattedMessage id="GENERAL_MESSAGE.BOSS"/></label>
              </span>
              {
                this.state.bossMessage && (
                  <small id="boss-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.bossMessage}/></small>
                )
              }
              </div>  
              </div>  
          </Dialog>

          <div>
          {/*UPDATE USER & CREATE USER*/}
          <Dialog visible={this.state.userDialog} contentStyle={{maxHeight:"800px", width:"500px", height:"550px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_USER"/>} modal footer={userDialogFooter} onHide={this.hideDialog}>
          <br></br>
          
          <div className="form-group row">
          <div className="col-lg-6">  
          
          <Avatar  
              size="small" 
              shape="circle"/>
              <input 
              type="file" 
              accept="image/x-png,image/jpeg"
              name="avatar"
              ref={(ref) => { this.uploadInput = ref; }}
              multiple= {false} />
          </div>
          </div>
            {/* Email */}
            <br></br>
              <div className="form-group row">
              <div className="col-lg-6">  
              <span className="p-float-label">      
                <InputText
                  style={{ width: 190, height:33 }}
                  id="email"
                  variant="outlined"
                  type="email"
                  label="Email"
                  margin="dense"
                  value={this.state.user.email}             
                  onChange={(e) => this.onInputChange(e, 'email')}
                  keyfilter="email"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.email  })}
                  />
                  <label htmlFor="email"><FormattedMessage id="AUTH.INPUT.EMAIL"/></label>
              </span>
              {
                this.state.emailMessage && (
                  <small id="email-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.emailMessage}/></small>
                )
              }
              </div>
              {/* Password */}
              <div className="col-lg-6">
              <span className="p-float-label">      
                  <InputText
                  style={{ width: 190, height:33 }}
                  id="password"
                  variant="outlined"
                  type="password"
                  margin="dense"
                  value={this.state.user.password}             
                  onChange={(e) => this.onInputChange(e, 'password')}
                  keyfilter={/[^\s]/}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.password  })}
                  />
                  <label htmlFor="password"><FormattedMessage id="AUTH.INPUT.PASSWORD"/></label>
              </span>
              {
                this.state.passwordMessage && (
                  <small id="password-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.passwordMessage}/></small>
                )
              }
              </div>
              </div>
              {/* FirstName */}
              <br></br>
              <div className="form-group row">
              <div className="col-lg-6">
              <span className="p-float-label">      
                <InputText
                  style={{ width: 190, height:33 }}
                  id="first_name"
                  variant="outlined"
                  margin="dense"
                  value={this.state.user.first_name}             
                  onChange={(e) => this.onInputChange(e, 'first_name')}
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.first_name  })}
                  />
                  <label htmlFor="first_name"><FormattedMessage id="AUTH.INPUT.FIRST_NAME"/></label>
              </span>
              {
                this.state.firstNameMessage && (
                  <small id="first_name-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.firstNameMessage}/></small>
                )
              }
              </div>
                  {/* Last Name*/}
                  <br></br>
                  <div className="col-lg-6">
                  <span className="p-float-label">      
                  <InputText
                  style={{ width: 190, height:33 }}
                  id="last_name"
                  variant="outlined"
                  margin="dense"
                  value={this.state.user.last_name}             
                  onChange={(e) => this.onInputChange(e, 'last_name')}
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.last_name  })}
                  />
                  <label htmlFor="last_name"><FormattedMessage id="AUTH.INPUT.LAST_NAME"/></label>
              </span>
              {
                this.state.lastNameMessage && (
                  <small id="last_name-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.lastNameMessage}/></small>
                )
              }
              </div>          
              </div>
                {/* Phone*/}
                <br></br>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <span className="p-float-label">      
                      <InputText
                      style={{ width: 190, height:33 }}
                      id="phone"
                      type="phone"
                      variant="outlined"
                      margin="dense"
                      value={this.state.user.phone}             
                      onChange={(e) => this.onInputChange(e, 'phone')}
                      keyfilter="pnum"
                      required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.phone  })}
                      />
                      <label htmlFor="phone"><FormattedMessage id="ADMIN_GENERAL.PHONE"/></label>
                    </span>
                    {
                      this.state.phoneMessage && (
                        <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.phoneMessage}/></small>
                      )
                    }
                  </div>
                  {/* Role*/}
                  {
                    this.props.my_user.fk_id_role !== this.id_coordinador && (
                      <div className="col-lg-6">
                        <span className="p-float-label">   
                          <Dropdown
                            style={{ width: 190, height:33 }}
                            id="role"
                            variant="outlined"
                            placeholder= { this.state.resetRole || this.state.user.role}
                            margin="dense"
                            value={this.state.selectedRole || "role"}   
                            options ={this.state.roles} 
                            onChange={this.onRoleChange} 
                            optionLabel="role" 
                            optionValue="id"
                            
                            />
                            <label htmlFor="role"><FormattedMessage id="ADMIN_GENERAL.ROLE"/></label>
                        </span>
                        {
                          this.state.fkIdRoleMessage && (
                            <small id="role-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.fkIdRoleMessage}/></small>
                          )
                        }
                      </div>
                    )
                  }
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 d-flex">
                    <Checkbox inputId="learning" checked={this.state.CheckedLearning} onChange={e => this.setState({ CheckedLearning: e.checked })} />
                    <label className="ml-2" htmlFor="learning"><FormattedMessage id="LEARNING_GENERAL.LEARNING"/></label>
                  </div>
                  <div className="col-lg-6 d-flex">
                    <Checkbox inputId="accounting" checked={this.state.CheckedAccounting} onChange={e => this.setState({ CheckedAccounting: e.checked })} />
                    <label className="ml-2" htmlFor="accounting"><FormattedMessage id="ACCOUNTING_GENEREAL.ACCOUNTING"/></label>
                  </div>
                </div>
                {
                  this.state.CheckedAccounting === true && (
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <span className="p-float-label">
                          <Dropdown
                            style={{ width: 190, height: 33}}
                            id='office'
                            variant='outlined'
                            placeholder={this.state.resetOffice || this.state.user.accounting_office}
                            margin='dense'
                            value={this.state.selectedOffice || ' office'}
                            options={this.state.offices}
                            onChange={this.onOfficeChange}
                            optionLabel='office'
                            optionValue='id'
                          />
                          <label htmlFor="first_name"><FormattedMessage id="GENERAL_MESSAGE.OFFICE_ACCOUNTING"/></label>
                        </span>
                        {/* {
                          this.state.accounting_officeMessage && (
                            <small id="accounting-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.accounting_officeMessage}/></small>
                          )
                        } */}
                      </div>
                    </div>
                  )
                }
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}

function mapStateToProps(state) {
  return {
    my_user: state.auth.user
  };
}

export default injectIntl(
  connect(mapStateToProps)(ListAgents)
)