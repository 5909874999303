import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ListGroupAgencies from "../modulesSchool/ListGroupAgencies"
import { useIntl } from "react-intl";

export const GroupAgenciesPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(`${int1.formatMessage({id: "LEARNING_GENERAL.AGENCY_GROUP_LIST"})}`);
    return (<ListGroupAgencies/>)
}
