function getSelectLang(){
  let lang
  try {
    const { selectedLang } = JSON.parse(localStorage.i18nConfig);
    lang = selectedLang
  } catch (error) {
    lang = 'en'
  }
  return lang
}

export function getTextCampusTranslate(){
  let GrobalSearch, Id, Invoices, DismissedInvoices, Date,Enterprise,Account,Currency, ProgramType, Country, Code,Symbol, Course, Agency, Office, Boss, RoleType, Role, Contact, Website, Description, FirstName, LastName, Email, Phone, 
  SearchByEnterprise,  SearchByCountry, SearchByCode, SearchByCampus, SearchByProgamType, SearchByCourse, SearchByAgency, SearchByOffice, SearchByRoleType, SearchByRole, SearchByFirstName, SearchByLastName, 
  SearchByContact, SearchByEmail, SearchByPhone, SearchByWebsite, SearchByDescription, SearchByAccount, SearchByCurrency,No,Yes,AccountName,NewAccount,PaymentDate,Amount,
  InvoiceId,Total,Balance,Equivalent,AgencyCommission,Sale,CommissionType,Rate,InvoiceName,Student,Agent,TransferTotal,PaymentMethod,AmountPayable,Suggested,NewItem,
  File,Note,Invoice,Created,CreatedBy,CreatedTo,School,Price,StartDate,EndDate,Add,TotalCourse,TotalCourses,Provider,Accommodation,TotalAccommodation,TotalAccommodations,Service,TotalService,TotalServices,additional,
  TotalAdditional,TotalAdditionals,discount,TotalDiscount,TotalDiscounts,ProgramTotal,AgencyTotal,ItemName,EditInvoice,Payments,NewPayment,Pendding,FullyPaid,PaymentFile,PaymentApproved,ExchangeOffice,ToTransfer, TransferBalance,
  AgentCommission,Commission,PaymentCommission,BaseExchangeRates,Transfers,ScheduleTransferPayments,TransferPayments,TransfersFullyPaid,DismissedTransfers,Sales,AgencyItems,
  StudentBalance,ScheduleDate,Cancel,Save,Confirm,Warning,Method,Commissions,ScheduleCommissionPayment,CommissionPayments,CommissionsFullyPaid,Dismissed,Name,Type,
  AgentCommissionBalance,ExchangeRateType,ScheduleTransfer,PaymentTransfer,SchedulePayment,CommissionPayment,CommissionAgency, DismissedCommissiones,ApprovedPayments, PenddingPayments, DateRange,
  ItemTypes, Utility, PositiveBalance, NewExchangeOffice, AdditionalPercentage, InvoicesFullyPaid,InvoiceNet,InvoiceGross, CommissionItemName, CommissionPercentage,IsAgencyOrSchool, User, AssignCourse, AssignAgents,FirstTerm,SecondTerm,Pending,Taken,DateCourse,CoursesAmount

  const selectedLang = getSelectLang()
  switch (selectedLang) {
    case "en":
      GrobalSearch = "Global Search"
      Id="Id"
      User = "User"
      AssignCourse = "Assign courses to agencies"
      AssignAgents = "Assign courses to agents"
      Date="Date"
      Name="Name"
      Sales="Sales"
      Type="Type"
      ItemTypes="Item Types"
      AgencyItems="Agency Items"
      NewItem= "New Item"
      DateRange = "Date Range"
      Enterprise = "Enterprise"
      Invoices= "Invoices"
      DismissedInvoices = "Dismissed Invoices"
      Country="Country"
      Code="Code"
      Currency="Currency"
      Account="Account"
      Symbol="Symbol"
      ProgramType = "Program Type"
      Course = "Course"
      Agency = "Agency"
      Office = "Office"
      Boss = "Boss"
      RoleType = "Role Type"
      Role = "Role"
      FirstName = "First Name"
      LastName = "Last Name"
      Contact = "Contact"
      Email = "Email"
      Phone = "Phone"
      Website = "Website"
      Description = "Description"
      No="No"
      Yes="Yes"
      Rate="Rate"
      Pendding="Pendding"
      FullyPaid="Fully paid"
      PaymentApproved="Payment Approved"
      AccountName="Account name"
      NewAccount="New account"
      PaymentDate="Payment date"
      ApprovedPayments = "Approved Payments"
      PenddingPayments = "Pendding Payments"
      Amount="Amount"
      InvoiceId="Invoice id"
      Total="Total"
      Balance="Balance"
      Equivalent="Equivalent"
      AgencyCommission="Agency commission"
      Utility="Net profit"
      Sale="Sale"
      CommissionType="Commission type"
      DismissedCommissiones="Dismissed Commissions"
      Rate="Rate"
      InvoiceName="Invoice name"
      Student="Student"
      Agent="Agent"
      PositiveBalance ="Positive Balance"
      TransferTotal="Transfer total"
      TransferBalance="Transfer balance"
      PaymentMethod="Payment method"
      AmountPayable="Amount payable"
      Suggested="Suggested"
      File="File"
      PaymentFile="Payment File"
      Note="Note"
      Invoice="Invoice"
      Created="Created"
      CreatedBy="Created by"
      CreatedTo="Created to"
      School="School"
      Price="Price"
      StartDate="Start date"
      EndDate="End date"
      Add="Add"
      TotalCourse="Total course"
      TotalCourses="Total courses"
      Provider="Provider"
      Accommodation="Accommodation"
      TotalAccommodation="Total accommodation"
      TotalAccommodations="Total accommodations"
      Service="Service"
      TotalService="Total service"
      TotalServices="Total services"
      additional="Additional"
      TotalAdditional="Total additional"
      TotalAdditionals="Total additionals"
      discount="Discount"
      TotalDiscount="Total discount"
      TotalDiscounts="Total discounts"
      ProgramTotal="Total program"
      AgencyTotal="Agency Total"
      ItemName="Item name"
      EditInvoice="Edit invoice"
      Payments="Payments"
      NewPayment="New payment"
      ExchangeOffice="Exchange Office"
      NewExchangeOffice = "New Exchange Office"
      ToTransfer="To transfer"
      AgentCommission="Agent commission"
      Commission="Commission"
      PaymentCommission="Payment Commission"
      BaseExchangeRates="Base Exchange Rates"
      Transfers = "Transfers"
      ScheduleTransferPayments = "Scheduled transfer payments"
      TransferPayments = "Transfer payments"
      TransfersFullyPaid = "Transfers fully paid"
      DismissedTransfers = "Dismissed Transfers"
      StudentBalance = "Student Balance"
      ScheduleDate = "ScheduleDate"
      Cancel = "Cancel"
      Save = "Save"
      Confirm = "Confirm"
      Warning = "Warning"
      SchedulePayment = "Schedule Payment"
      Method = "Method"
      Commissions = "Commissions"
      ScheduleCommissionPayment = "Schedule Commission Payment"
      CommissionPayments = "Commission Payments"
      CommissionsFullyPaid = "Commission Fully Paid"
      Dismissed = "Dismissed"
      AgentCommissionBalance = "Agent Commission Balance"
      ExchangeRateType = "Exchange Rate Type"
      ScheduleTransfer = "Schedule transfer"
      PaymentTransfer = "Payment transfer"
      CommissionPayment = "Commission Payment"
      CommissionAgency = "Commission Agency"
      SearchByEnterprise = "Search by enterprise"
      SearchByCountry = "Search by country"
      SearchByCode = "Search by code"
      SearchByCampus = "Search by campus"
      SearchByProgamType = "Search by program type"
      SearchByCourse = "Search by course"
      SearchByAgency = "Search by agency"
      SearchByOffice = "Search by office"
      SearchByRoleType = "Search by role type"
      SearchByRole = "Search by role"
      SearchByFirstName = "Search by first name"
      SearchByLastName = "Search by last name"
      SearchByContact = "Search by contact"
      SearchByEmail = "Search by email"
      SearchByPhone = "Search by phone"
      SearchByWebsite = "Search by website"
      SearchByDescription = "Search by description"
      SearchByAccount = "Search by account"
      SearchByCurrency = "Search by currency"
      AdditionalPercentage= "Additional Percentage"
      InvoicesFullyPaid = "Invoices Fully Paid"
      InvoiceNet="Invoice net"
      InvoiceGross="Invoice gross"
      CommissionItemName = "Commission item name"
      CommissionPercentage = "Commission percentage"
      IsAgencyOrSchool = "Is agency or school?"
      FirstTerm = "First term"
      SecondTerm = "Second term"
      Pending = "Pending"
      Taken = "Taken"
      DateCourse="Creation date"
      CoursesAmount="Courses amount"


      break
    case "es":
      Id="Id"
      Date="Fecha"
      User = "Usuario"
      AssignCourse = "Asignar cursos a agencias"
      AssignAgents = "Asignar cursos a agentes"
      Name="Nombre"
      Sales="Ventas"
      Type="Tipo"
      NewItem="Nuevo concepto"  
      ItemTypes="Tipo de concepto"
      AgencyItems = "Conceptos de la agencia"
      DateRange="Rango de fechas"
      GrobalSearch = "Búsqueda global"
      Enterprise = "Empresa"
      Country="País"
      Code="Codigo"
      Currency="Moneda"
      Account="Cuenta"
      Invoices = "Facturas"
      DismissedInvoices = "Facturas Descartadas"
      Symbol="Símbolo"
      ProgramType = "Tipo de programa"
      Course = "Curso"
      Agency = "Agencia"
      Office = "Oficina"
      Boss = "Jefe"
      RoleType = "Tipo de rol"
      Role = "Rol"
      FirstName = "Primer nombre"
      LastName = "Apellido"
      Contact = "Contacto"
      Email = "Email"
      Phone = "Telefono"
      Website = "Sitio web"
      Description = "Descripción"
      No="No"
      Yes="Sí"
      Commission="Comisión"
      Rate="Tarifa"
      Pendding="Pendiente"
      FullyPaid="Pagado"
      PaymentApproved="Pago Aprobado"
      PaymentFile="Archivo de pago"
      AccountName="Nombre de cuenta"
      NewAccount="Nueva cuenta"
      PaymentDate="Fecha de pago"
      ApprovedPayments = "Pagos aprobados"
      PenddingPayments = "Pagos pendientes"
      Amount="Monto"
      InvoiceId="Factura id"
      Total="Total"
      Balance="Balance"
      Equivalent="Equivalente"
      AgencyCommission="Comisión de agencia"
      Sale="Venta"
      CommissionType="Tipo de comisión"
      Rate="Tarifa"
      InvoiceName="Nombre de factura"
      Student="Estudiante"
      Agent="Agente"
      TransferTotal="Total de la transferencia"
      PaymentMethod="Metodo de pago"
      AmountPayable="Monto pagable"
      Suggested="Sugerido"
      File="Archivo"
      Note="Nota"
      Invoice="Factura"
      Created="Creado"
      CreatedBy="Creado por"
      CreatedTo="Creado para"
      School="Escuela"
      Price="Precio"
      StartDate="Fecha de inicio"
      EndDate="Fecha de regreso"
      Add="Agregar"
      TotalCourse="Total del curso"
      TotalCourses="Total de los cursos"
      Provider="Proveedor"
      Accommodation="Alojamiento"
      TotalAccommodation="Total de alojamiento"
      TotalAccommodations="Total de alojamientos"
      Service="Servicio"
      TotalService="Total de servicio"
      TotalServices="Total de servicios"
      additional="Adicional"
      TotalAdditional="Total de adicionales"
      TotalAdditionals="Total de adicionales"
      discount="Descuento"
      TotalDiscount="Total de descuento"
      TotalDiscounts="Total de descuentos"
      ProgramTotal="Total de programa"
      AgencyTotal="Total de la agencia"
      ItemName="Nombre del concepto"
      EditInvoice="Editar factura"
      Payments="Pagos"
      PositiveBalance="Saldo a favor"
      NewPayment="Nuevo pago"
      NewExchangeOffice= "Nueva casa de cambio"
      ExchangeOffice="Casa de cambio"
      ToTransfer="Por transferir"
      TransferBalance="Balance de transferencia"
      AgentCommission="Comisión del agente"
      Utility="Utilidad"
      PaymentCommission="Pago de comisión"
      BaseExchangeRates = "Tipos de cambio base"
      Transfers = "Transferencias"
      ScheduleTransferPayments = "Pagos de transferencia programados"
      TransferPayments = "Pagos de transferencia"
      TransfersFullyPaid = "Transferencias completamente pagadas"
      DismissedTransfers = "Transferencias descartadas"
      StudentBalance = "Balance del estudiante"
      ScheduleDate = "Fecha de programación"
      Cancel = "Cancelar"
      Save = "Guardar"
      Confirm = "Confirmar"
      Warning = "Aviso"
      SchedulePayment = "Programar Pago"
      Method = "Metodo"
      Commissions = "Comisiones"
      ScheduleCommissionPayment = "Programar Pagos de Comisión "
      CommissionPayments = "Pagos de Comisión"
      DismissedCommissiones ="Commissiones Descartadas"
      CommissionsFullyPaid = "Comisiones Completamente Pagadas"
      Dismissed = "Descartadas"
      AgentCommissionBalance = "Balance de la Comisión del Agente"
      ExchangeRateType = "Tipo de Tarifa"
      ScheduleTransfer = "Programar transferecia"
      PaymentTransfer = "Pago de transferencia"
      CommissionPayment = "Pago de comisión"
      CommissionAgency = "Comisión de la ggencia"
      SearchByEnterprise = "Buscar por empresa"
      SearchByCountry = "Buscar por país"
      SearchByCode = "Buscar por codigo"
      SearchByCampus = "Buscar por campus"
      SearchByProgamType = "Buscar por tipo de programa"
      SearchByCourse = "Buscar por curso"
      SearchByAgency = "Buscar por agencia"
      SearchByOffice = "Buscar por oficina"
      SearchByRoleType = "Buscar por tipo de rol"
      SearchByRole = "Buscar por rol"
      SearchByFirstName = "Buscar por nombre"
      SearchByLastName = "Buscar por apellido"
      SearchByContact = "Buscar por contacto"
      SearchByEmail = "Buscar por email"
      SearchByPhone = "Buscar por teléfono"
      SearchByWebsite = "Buscar por website"
      SearchByDescription = "Buscar por descripción"
      SearchByAccount = "Buscar por cuenta"
      SearchByCurrency = "Buscar por moneda"
      AdditionalPercentage = "Porcentaje adicional"
      InvoicesFullyPaid = "Factura completamente pagada"
      InvoiceNet="Invoice net"
      InvoiceGross="Invoice gross"
      CommissionItemName = "Nombre de la comisión"
      CommissionPercentage = "Porcentaje"
      IsAgencyOrSchool = "¿Es agencia o escuela?"
      FirstTerm = "Primer semestre"
      SecondTerm = "Segundo semestre"
      Pending = "Pendiente"
      Taken = "Tomado"
      DateCourse="Fecha de creaicón"
      CoursesAmount="Cantidad de cursos"
      
      break
    case "fr":
      Id="Id"
      Date="Date"
      GrobalSearch = "Recherche globale"
      Enterprise = "Entreprise"
      Country="Pays"
      Code="Le Code"
      Symbol="Symbole"
      ProgramType = "Type de programme"
      Course = "Cours"
      Agency = "Agence"
      Office = "Bureau"
      Boss = "Boss"
      RoleType = "Type de rôle"
      Role = "Rôle"
      FirstName = "Prénom"
      LastName = "Nom de famille"
      Contact = "Contact"
      Email = "E-Mail"
      Phone = "Téléphone"
      Website = "Site Internet"
      Description = "Description"
      SearchByEnterprise = "Recherche par enterprise"
      SearchByCountry = "Recherche par pays"
      SearchByCode = "Recherche par code"
      SearchByCampus = "Recherche par campus"
      SearchByProgamType = "Recherche par type de programme"
      SearchByCourse = "Recherche par cours"
      SearchByAgency = "Recherche par agence"
      SearchByOffice = "Recherche par bureau"
      SearchByRoleType = "Recherche par type de rôle"
      SearchByRole = "Recherche par Rôle"
      SearchByFirstName = "Recherche par prénom"
      SearchByLastName = "Recherche par nom"
      SearchByContact = "Recherche par contact"
      SearchByEmail = "Recherche par e-mail"
      SearchByPhone = "Recherche par téléphone"
      SearchByWebsite = "Recherche par site Internet"
      SearchByDescription = "Recherche par description"
      break
    case "de":
      Id="Id"
      Date="Datum"
      GrobalSearch = "Globale Suche"
      Enterprise = "Unternehmen"
      Country="Land"
      Code="Der Code"
      Symbol="Symbol"
      ProgramType = "Programmtyp"
      Course = "Kurs"
      Agency = "Agentur"
      Office = "Büro"
      Boss = "Boss"
      RoleType = "Rollentyp"
      Role = "Rolle"
      FirstName = "Vorname"
      LastName = "Nachname"
      Contact = "Kontakt"
      Email = "E-Mail"
      Phone = "Telefon"
      Website = "Webseite"
      Description = "Beschreibung"
      SearchByEnterprise = "Suche per Unternehmen"
      SearchByCountry = "Suche per land"
      SearchByCode = "Suche per code"
      SearchByCampus = "Suche per campus"
      SearchByProgamType = "Suche per programmtyp"
      SearchByCourse = "Suche per kurs"
      SearchByAgency = "Suche per agentur"
      SearchByOffice = "Suche per büro"
      SearchByRoleType = "Suche per rollentyp"
      SearchByRole = "Suche per rolle"
      SearchByFirstName = "Suche nach Vorname"
      SearchByLastName = "Suche nach Nachnamen"
      SearchByContact = "Suche per kontakt"
      SearchByEmail = "Suche per E-Mail"
      SearchByPhone = "Suche per Telefon"
      SearchByWebsite = "Suche per website"
      SearchByDescription = "Suche per beschreibung"
      break
    default:
      GrobalSearch = "Global Search"
      Id="Id"
      Date="Date"
      Enterprise = "Enterprise"
      Country="Country"
      Code="Code"
      Currency="Currency"
      Account="Account"
      Symbol="Symbol"
      ProgramType = "Program Type"
      Course = "Course"
      Agency = "Agency"
      Office = "Office"
      Boss = "Boss"
      RoleType = "Role Type"
      Role = "Role"
      FirstName = "First Name"
      LastName = "Last Name"
      Contact = "Contact"
      Email = "Email"
      Phone = "Phone"
      Website = "Website"
      Description = "Description"
      
      SearchByEnterprise = "Search by enterprise"
      SearchByCountry = "Search by country"
      SearchByCode = "Search by code"
      SearchByAccount = "Search by account"
      SearchByCurrency = "Search by currency"
      SearchByCampus = "Search by campus"
      SearchByProgamType = "Search by program type"
      SearchByCourse = "Search by course"
      SearchByAgency = "Search by agency"
      SearchByOffice = "Search by office"
      SearchByRoleType = "Search by role type"
      SearchByRole = "Search by role"
      SearchByFirstName = "Search by first name"
      SearchByLastName = "Search by last name"
      SearchByContact = "Search by contact"
      SearchByEmail = "Search by email"
      SearchByPhone = "Search by phone"
      SearchByWebsite = "Search by website"
      SearchByDescription = "Search by description"
    }
  return { GrobalSearch, Id, Date,Enterprise, Invoices, DismissedInvoices, Country,Code,Symbol, Account, Currency, ProgramType, Course, Agency, Office, Boss, RoleType, Role, Contact, Website, Description, FirstName, LastName, Email, Phone, 
    SearchByEnterprise,SearchByCountry,SearchByCode, SearchByCampus, SearchByProgamType, SearchByCourse, SearchByAgency, SearchByOffice, SearchByRoleType, SearchByRole, SearchByFirstName, SearchByLastName,
    SearchByContact, SearchByEmail, SearchByPhone, SearchByWebsite, SearchByDescription, SearchByAccount, SearchByCurrency,CommissionItemName,CommissionPercentage,
    No,Yes,AccountName,NewAccount,PaymentDate,Amount,Sales,NewItem,ItemTypes,AgencyItems,Utility,PositiveBalance,NewExchangeOffice,
    InvoiceId,Total,Balance,Equivalent,AgencyCommission,Sale,CommissionType,Rate,InvoiceName,Student,Agent,TransferTotal,PaymentMethod,AmountPayable,Suggested,
    File,Note,Invoice,Created,CreatedBy,CreatedTo,School,Price,StartDate,EndDate,Add,TotalCourse,TotalCourses,Provider,Accommodation,TotalAccommodation,TotalAccommodations,Service,TotalService,TotalServices,additional,
    TotalAdditional,TotalAdditionals,discount,TotalDiscount,TotalDiscounts,ProgramTotal,AgencyTotal,ItemName,EditInvoice,Payments,NewPayment,Pendding,FullyPaid, PaymentFile, PaymentApproved,ExchangeOffice, ToTransfer,TransferBalance,
    AgentCommission, Commission,PaymentCommission, BaseExchangeRates,Transfers,ScheduleTransferPayments,TransferPayments,TransfersFullyPaid,DismissedTransfers,
    StudentBalance,ScheduleDate,Cancel,Save,Confirm,Warning,Method,Commissions,ScheduleCommissionPayment,CommissionPayments,CommissionsFullyPaid,Dismissed,Name,Type,IsAgencyOrSchool,User,AssignCourse,
    AgentCommissionBalance,ExchangeRateType,ScheduleTransfer,PaymentTransfer,SchedulePayment,CommissionPayment,CommissionAgency,DismissedCommissiones,ApprovedPayments,PenddingPayments, DateRange,AdditionalPercentage,InvoicesFullyPaid,InvoiceNet,InvoiceGross,AssignAgents,FirstTerm,SecondTerm,Pending,Taken,DateCourse,CoursesAmount
  }
}

export function getGeneralTextTranslate() {
  let Id, Date, Enterprise,Country,Code,Account,Currency,ProgramType, Course, Agency, Office, RoleType, Role, Contact, Phone, Website, Description, Password
  const selectedLang = getSelectLang()
  switch (selectedLang) {
    case "en":
      Id="Id"
      Date="Date"
      Enterprise = "Enterprise"
      Country="Country"
      Code="Code"
      Account="Account"
      Currency="Currency"
      Code="Symbol"
      ProgramType = "Program Type"
      Course = "Course"
      Agency = "Agency"
      Office = "Office"
      RoleType = "Role Type"
      Role = "Role"
      Contact = "Contact"
      Phone = "Phone"
      Website = "Website"
      Description = "Description"
      Password = "Password"
      break
    case "es":
      Id="Id"
      Date="Fecha"
      Enterprise = "Empresa"
      Country="País"
      Code="Codigo"
      Account="Cuenta"
      Currency="Moneda"
      ProgramType = "Tipo de programa"
      Course = "Curso"
      Agency = "Agencia"
      Office = "Oficina"
      RoleType = "Tipo de rol"
      Role = "Rol"
      Contact = "Contacto"
      Phone = "Telefono"
      Website = "Sitio web"
      Description = "Descripción"
      Password = "Contraseña"
      break
    case "fr":
      Id="Id"
      Date="Date"
      Enterprise = "Enterprise"
      Country="Pays"
      Code="Le Code"
      ProgramType = "Type de programme"
      Course = "Cours"
      Agency = "Agence"
      Office = "Bureau"
      RoleType = "Type de rôle"
      Role = "Rôle"
      Contact = "Contact"
      Phone = "Téléphone"
      Website = "Site Internet"
      Description = "Description"
      Password = "Mot de passe"
      break
    case "de":
      Id="Id"
      Date="Datum"
      Enterprise = "Unternehmen"
      Country="Land"
      Code="Der Code"
      ProgramType = "Programmtyp"
      Course = "Kurs"
      Agency = "Agentur"
      Office = "Büro"
      RoleType = "Rollentyp"
      Role = "Rolle"
      Contact = "Kontakt"
      Phone = "Telefon"
      Website = "Webseite"
      Description = "Beschreibung"
      Password = "Passwort"
      break
    default:
      Id="Id"
      Date="Date"
      Enterprise = "Enterprise"
      ProgramType = "Program Type"
      Course = "Course"
      Agency = "Agency"
      Office = "Office"
      RoleType = "Role Type"
      Role = "Role"
      Contact = "Contact"
      Phone = "Phone"
      Website = "Website"
      Description = "Description"
      Password = "Password"
    }
  return { Id, Date,Enterprise, Country,Account,Currency, Code,ProgramType, Course, Agency, Office, RoleType, Role, Contact, Phone, Website, Description, Password }
}

export function getTextPaginatorTranslate(){
  let currentPageReportTemplate
  const selectedLang = getSelectLang()
  switch (selectedLang) {
    case "en":
      currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
      break
    case "es":
      currentPageReportTemplate = "Mostrando {first} a {last} de {totalRecords}"
      break
    case "fr":
      currentPageReportTemplate = "Affiche {first} à {last} de {totalRecords}"
      break
    case "de":
      currentPageReportTemplate = "Zeige {first} bis {last} von {totalRecords}"
      break
    default:
      currentPageReportTemplate = "Showing {first} to {last} of {totalRecords}"
    }
  return currentPageReportTemplate
}

export function getLabelRowsPerPage(){
  let text1
  const selectedLang = getSelectLang()
  switch (selectedLang) {
    case "en":
      text1 = 'Rows per page:'
      break
    case "es":
      text1 = 'Filas por página:'
      break
    case "fr":
      text1 = 'Lignes par page:'
      break
    case "de":
      text1 = 'Zeilen pro Seite:'
      break
    default:
      text1 = 'Rows per page:'
    }
  return text1
}

export const getLabelDisplayedRows = (sPage, sRPP, total) => () => {
  let text1
  const selectedLang = getSelectLang()
  switch (selectedLang) {
    case "en":
      text1 = `${sPage}-${sRPP} of ${total}`
      break
    case "es":
      text1 = `${sPage}-${sRPP} de ${total}`
      break
    case "fr":
      text1 = `${sPage}-${sRPP} sur ${total}`
      break
    case "de":
      text1 = `${sPage}-${sRPP} von ${total}`
      break
    default:
      text1 = `${sPage}-${sRPP} of ${total}`
    }
  return text1
}