import React from "react";
// import { useSelector } from "react-redux";
import {useSubheader} from "../../_metronic/_core";
import Program from "../modulesWiyu/Program"
// import { useIntl } from "react-intl";

export const ProgramPage = () => {
    const suhbeader = useSubheader();
    // const dataCourse = useSelector(state => state.auth.dataCourse );
    // const int1 = useIntl();

    // if(props.location.aboutProps){
    //     suhbeader.setTitle(`Curso - ${props.location.aboutProps.data.course}`);
    //     return (<Course data={props.location.aboutProps.data}/>)
    // }else{
    //     suhbeader.setTitle(`Curso - ${props.location.state.course}`);
    //     return (<Course data={props.location.state}/>)
    // }

    suhbeader.setTitle(`Program`);
    return (<Program/>)
}