import React, { Component } from 'react'
import axios from 'axios'

//http://localhost:3333
//https://api.labsreact.com
//https://api.devsreact.com

//console.log("http://localhost:3333");

class GetTasks extends Component {

    state = {
        tasks: []
    }    

    componentDidMount() {
        this.getTasks()
    }

    getTasks(){
      axios.get('http://localhost:3333/api/tasks')
      .then(res => {
        const tasks = res.data;
        //console.log(tasks);
        this.setState({ tasks })
      })
    }

    deleteTask(id){
      axios.delete(`http://localhost:3333/api/task/${id}`)
      .then(
        res => {
          this.getTasks()
      })
      .catch(
        e => console.log(e)
      )       
    }    
      
    render() {
      const imprime_tareas = this.state.tasks.map((task, i) => {
        return (
          <div className="col-md-4 p-2" key={task.id}>
            <div className="card text-center">
              <div className="card-header bg-dark text-white d-flex justify-content-between align-items-center">
                # {task.id}
                <button className="btn btn-danger" onClick={() => this.deleteTask(task.id)}>
                  <i className="far fa-trash-alt" />
                </button>
              </div>
              <div className="card-body">
                  <p>{task.task_name}</p>
              </div>                            
            </div>
          </div>
        )
      })

      return (        
        <div className="row">            
            { imprime_tareas }
        </div>
      )
    }
}

export default GetTasks