import React from "react";
import {useSubheader} from "../../_metronic/_core";

import AccountsTable from "../modulesAccounting/Accounts/AccountsTable"

export const AccountsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Accounts");

  return (<><AccountsTable /></>);
};