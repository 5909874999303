import React from "react";
import {useSubheader} from "../../_metronic/_core";

import ViewOptions from "../modulesAccounting/Invoices/ViewOptions"

export const ViewOptionsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("View Options");

  return (<><ViewOptions/></>);
};

