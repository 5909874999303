import React, {Component} from 'react'
import classNames from 'classnames';
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';



import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { connect } from 'react-redux';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";




const url = ServerURL();




class AccountsTable extends Component {
            

  
  emptyAccount = {
  account_name: '',
  fk_id_currency: '',  
  description:'',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    account_name: '',
    fk_id_currency: '',  
    description:'',

     /**
       * Aux messages
       */
      fk_id_currencyMessage: '',
      account_nameMessage: '', 
      descriptionMessage: '', 
     

    account: this.emptyAccount,
    accounts:null,
    globalFilter: null,
    selectedAccounts: null,
    accountDialog: false,
    deleteAccountDialog: false,
    deleteAccountsDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveAccount = this.saveAccount.bind(this);
  this.deleteAccount = this.deleteAccount.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editAccount = this.editAccount.bind(this);
  this.confirmDeleteAccount = this.confirmDeleteAccount.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedAccounts = this.deleteSelectedAccounts.bind(this);
  this.hideDeleteAccountDialog = this.hideDeleteAccountDialog.bind(this);
  this.hideDeleteAccountsDialog = this.hideDeleteAccountsDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onCurrencyChange = this.onCurrencyChange.bind(this);
  }


    
      componentDidMount() {
        this.getInitialData()
      }

      isValid(){
        const { fk_id_currency,account_name, description } = this.state.account
              const fk_id_currencyMessage = this.validateFK_Id_Currency()
              const account_nameMessage = this.validateAccount()
              const descriptionMessage = this.validateDescription()

        
              if(fk_id_currency && account_name && description ){
                if(!fk_id_currencyMessage && !account_nameMessage && !descriptionMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
              validateFK_Id_Currency() {
              const account = this.state.account
              
              let fk_id_currencyMessage;
              if (!account.fk_id_currency) {
                fk_id_currencyMessage = 'Requerido';
              } else {
                fk_id_currencyMessage = ''
              }
              this.setState({ fk_id_currencyMessage })
              return fk_id_currencyMessage
            }

            
              validateAccount() {
              const account = this.state.account
              
              let account_nameMessage;
              if (!account.account_name) {
                account_nameMessage = 'Requerido';
              } else {
                account_nameMessage = ''
              }
              this.setState({ account_nameMessage })
              return account_nameMessage
            }



              validateDescription() {
              const account = this.state.account
              
              let descriptionMessage;
              if (!account.description) {
                descriptionMessage = 'Requerido';
              } else {
                descriptionMessage = ''
              }
              this.setState({ descriptionMessage })
              return descriptionMessage
            }



        getTextAuxFK_Id_Currency(){
              const message = this.state.fk_id_currencyMessage
              return message
            }
        
            getTextAuxAccount(){
              const message = this.state.account_nameMessage
               return message
             }
      
            getTextAuxDescription(){
             const message = this.state.descriptionMessage
              return message
            }

     
      getInitialData= async (e) =>{
         
        let requestOne = axios.get(`${url}/api/auth/accounts`)
        let requestTwo = axios.get(`${url}/api/auth/enterprise/${this.props.user.fk_id_enterprise}`);
        let requestThree = axios.get(`${url}/api/auth/currencies`);

        axios
        .all([requestOne, requestTwo, requestThree])
        .then(
              axios.spread((...responses) => {
                const accounts = responses[0].data;
                const enterprises = responses[1].data;
                const currencies = responses[2].data;

                let currenciesShow=[];
                let aux=0;
                  
                            this.codeSymbol(accounts)
                            enterprises.fk_id_currencies = enterprises.fk_id_currencies.split(',')    
                            enterprises.fk_id_currencies = enterprises.fk_id_currencies.map(Number);    

                            console.log(enterprises, accounts,currencies)              
                            for(let i=0; i<Object.keys(currencies).length; i++){ 
                                for(let j=1; j<Object.keys(enterprises.fk_id_currencies).length; j++){
                                  if(currencies[i].id===enterprises.fk_id_currencies[j]){
                                      currenciesShow[aux]=currencies[i]  
                                      aux++                                    
                                  }
                                }
                            }     
                      console.log("CURRENCIES SHOW",currenciesShow)
                      this.setState({accounts,currencies,currenciesShow})
                    })
                  )
                  .catch(errors => {
                    // react on errors.
                     console.error(errors);
                  });                  
               }   
      
               codeSymbol(e){
             
             
                e.map((account) => {
                //console.log("E:",agreement.updated_at)
        
                account.codeSymbol= account.code+' '+ account.symbol;
                
                 return account
        
               });
              }

    
      



      


      deleteAccount(){
        let data = {...this.state.account};

        try {
        let res = axios.delete(`${url}/api/auth/account/${data.id}`)
          .then(res => {
            this.getInitialData()
          })
        if (!res.error) {
          this.setState({
            data,
            deleteAccountDialog: false,
            account: this.emptyAccount
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveAccount() {
        let state = { submitted: true };

        const decision = this.isValid();

        if (decision) {

          let data = {...this.state.account};
          console.log("DATA:",data)

        if (this.state.account.id) {

            try {
              let res = axios.put(`${url}/api/auth/account/${data.id}`,data)
              .then(res =>{
                this.getInitialData()
                //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Account Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/account`,data)
            
             .then(res => {
              this.getInitialData()
            })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Account Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          accountDialog: false,
          account: this.emptyAccount,
          selectedCurrency: null,
          resetCurrency:null,
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.setState({
            account: this.emptyAccount,
            submitted: false,
            accountDialog: true,
            selectedCurrency: null,
            resetCurrency:"Currency",
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let account = {...this.state.account};
        account[`${name}`] = val;
        this.setState({ account });
      }

   
      onCurrencyChange(e) {
        let account = {...this.state.account};
        account['fk_id_currency'] = e.value;
        this.setState({ selectedCurrency: e.value, account});      
      }   


      hideDialog() {
          this.setState({
              submitted: false,
              accountDialog: false,
              fk_id_currencyMessage: '',
              account_nameMessage:'',
              descriptionMessage: '',
        })  
      } 


      editAccount(account) {

        console.log("ACCOUNT",account)
          this.setState({
              account: { ...account },
              selectedCurrency: null,
              resetCurrency:null,  
              accountDialog: true
          })
      }

      confirmDeleteAccount(account) {
        this.setState({
            account,
            deleteAccountDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteAccountsDialog: true })
      }

      deleteSelectedAccounts() {
        let accounts = this.state.accounts.filter(val => !this.state.selectedAccounts.includes(val));
        let data = this.state.accounts.filter(val => this.state.selectedAccounts.includes(val));

        try {
        let res = axios.delete(`${url}/api/auth/accounts`,{data})
          .then(res => {
          this.getInitialData()
          })
        if (!res.error) {
          this.setState({
            accounts,
            deleteAccountsDialog: false,
            selectedAccounts: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Accounts Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteAccountDialog() {
        this.setState({ deleteAccountDialog: false })
      }
        
      hideDeleteAccountsDialog() {
        this.setState({ deleteAccountsDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
      

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editAccount(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteAccount(rowData)} />
            </>
        )
      }
      
      




      render(){
       const deleteAccountDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAccountDialog} />
            <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteAccount} />
        </>
       );

       const accountDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveAccount} />}
            </FormattedMessage>
        </>
       );

       const deleteAccountsDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAccountsDialog} />
            <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedAccounts} />
        </>
    );


       return(       
        <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		      <FormattedMessage id="ACCOUNTING_ACCOUNT.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm">
            <FormattedMessage id="ACCOUNTING_ACCOUNT.DESCRIPTION"/>
            </span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedAccounts || !this.state.selectedAccounts.length} />}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_ACCOUNT">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div>  

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>
          
          <div>
           {/*SHOW ROLE */}
           <div className= "card-header flex-wrap border-0 pt-6 pb-0">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.accounts} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage={<FormattedMessage id="ACCOUNTING_ACCOUNT.NO_DATA"/>} loading={this.state.loading}
                        selection={this.state.selectedAccounts} onSelectionChange={e => this.setState({ selectedAccounts: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="codeSymbol" header={this.state.campusText.Currency} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Currency} filterMatchMode="contains"></Column>
                        <Column field="account_name" header={this.state.campusText.Account} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Account} filterMatchMode="contains"></Column>
                        <Column field="description" header={this.state.campusText.Description} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Description} filterMatchMode="contains"></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE ACCOUNT */}
          <Dialog visible={this.state.deleteAccountDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAccountDialogFooter} onHide={this.hideDeleteAccountDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.account && <span> {<FormattedMessage id="ACCOUNTING_ACCOUNT.DIALOG_DELETE"/>} <b>{this.state.account.account_name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteAccountsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAccountsDialogFooter} onHide={this.hideDeleteAccountsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.account && <span>{<FormattedMessage id="ACCOUNTING_ACCOUNT.DIALOG_DELETE_ALL"/>}</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE ROLE & CREATE ROLE*/}
          <Dialog visible={this.state.accountDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"270px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_ACCOUNT"/>} modal footer={accountDialogFooter} onHide={this.hideDialog}>
              <br></br>
              <div className="form-group row">
               {/* Currency */}
              <div className="col-lg-6">      
              <span className="p-float-label">          
              <Dropdown
                  style={{ width: 250, height:33 }}
                  id="currency"
                  variant="outlined"
                  placeholder= { this.state.resetCurrency || this.state.account.code}
                  margin="dense"
                  value={this.state.selectedCurrency || "currency"}   
                  options ={this.state.currenciesShow} 
                  onChange={this.onCurrencyChange} 
                  optionLabel="code" 
                  optionValue="id"
                  />
                 <label htmlFor="currency">{this.state.campusText.Currency}</label>
              </span>    
             <small id="currency-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFK_Id_Currency()}</small>
              </div>
               {/* Account */}
               <div className="col-lg-6">
              <span className="p-float-label">
                  <InputText
                  id="account"
                  variant="outlined"
                  type="account"
                  margin="dense"
                  value={this.state.account.account_name}             
                  onChange={(e) => this.onInputChange(e, 'account_name')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.account.account_name  })}
                  />
                  <label htmlFor="account_name">{this.state.campusText.AccountName}</label>
              </span>
              <small id="account_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAccount(this.state)}</small>    
              </div>
              </div>
              <br></br>
              <div className="form-group row">
              {/* Description */}
              <div className="col-lg-6">
              <span className="p-float-label">   
                <InputText
                  id="description"
                  variant="outlined"
                  margin="dense"
                  value={this.state.account.description}             
                  onChange={(e) => this.onInputChange(e, 'description')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.description  })}
                  />
                  <label htmlFor="description">{this.state.campusText.Description}</label>
                  </span>
                  <small id="description-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription(this.state)}</small>
              </div>
                  </div>
          </Dialog>
        </div>


        <Tooltip title={this.state.campusText.NewAccount} aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, auth.actions)(AccountsTable);

