/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
//import { useIntl } from "react-intl";

export function AsideMenuListS({ layoutProps }) {
  //const int1 = useIntl();
  const location = useLocation();
  const { user } = useSelector(state => state.auth);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

          {/*begin::1 Level*/}
          {
            user.tutorials === 0 ? 
            <li
              className={`menu-item ${getMenuItemActive("/inicio", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/inicio">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                </span>
                <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.HOME"/></span>
              </NavLink>
            </li>
            :
            <li
              className={`menu-item ${getMenuItemActive("/tutorial", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/tutorial">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}/>
                </span>
                <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.TUTORIALS"/></span>
              </NavLink>
            </li>
          }
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")}/>
              </span>
              <span className="menu-text"><FormattedMessage id="MENU.DASHBOARD"/></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/* Usuarios */}
          {/* begin::section */}
          <li className="menu-section">
            <h4 className="menu-text"><FormattedMessage id="LEARNING_GENERAL.USERS"/></h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/get-marketer", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-marketers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.MARKETER_LIST"/></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/get-group-agencies", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-group-agencies">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.AGENCY_GROUP_LIST"/></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/* Cursos */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text"><FormattedMessage id="LEARNING_GENERAL.COURSES"/></h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/get-courses", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link menu-toggle" to="/get-courses">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.COURSE_LIST"/></span>
            </NavLink>
          </li>
          {/* <li
              className={`menu-item ${getMenuItemActive("/get-lessons", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-lessons">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}/>
            </span>
              <span className="menu-text">Lista de lecciones</span>
            </NavLink>
          </li> */}
          <li
              className={`menu-item ${getMenuItemActive("/get-evaluations", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-evaluations">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Double-check.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.ASSESSMENT_LIST"/></span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/get-course-assignment", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-course-assignment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-to-right.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.COURSE_ASSIGMENT"/></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
        </ul>
        {/* end::Menu Nav */}
      </>
  );
}
