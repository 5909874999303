/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
  const int1 = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD"})}</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}



          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_SCHOOLS"})}</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_SCHOOLS"})}</span>
                </span>
                </li>
                {/* Escuelas */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-schools", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-schools">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.SCHOOL"})}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/* Sedes */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-campuses", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="get-campuses">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.CAMPUS"})}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/* Programas */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS"})}</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                {/* Programas */}
                {/*begin::3 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-programs", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-programs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS.PROGRAMS"})}</span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}
                {/* Cursos */}
                {/*begin::3 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-courses", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-courses">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS.COURSES"})}</span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}                      
                    </ul>
                  </div>
                </li>
                {/*end::2 Level*/}
                
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}



          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_AGENCIES"})}</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_AGENCIES"})}</span>
                </span>
                </li>
                {/* Agencias */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-agencies", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-agencies">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.AGENCIES"})}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/* Oficinas */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-offices", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-offices">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.OFFICES"})}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ROLES_USERS"})}</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ROLES_USERS"})}</span>
                </span>
                </li>
           {/* Agencias */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-roles", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-roles">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ROLES"})}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                {/* Oficinas */}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-users", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.USERS"})}</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}
                </ul>
            </div>
          </li>
          
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
