import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router'
import axios from 'axios'
import ServerURL from '../../../ServerURL';
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from 'react-intl';
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from "../../../_metronic/i18n/TextTranslate";

import { DataTable } from "primereact/datatable";
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const url = ServerURL()

class EventsTypesTable extends Component {

  emptyEventType = {
    event_type: '',
    description: '',
  }

  constructor(props) {
    super(props)

    this.state = {
      name: '',
      description: '',

      event_typeMessage: '',
      descriptionMessage: '',

      event_type: this.emptyEventType,
      event_types: null,
      ids: null,
      globalFilter: null,
      selectedEventTypes: null,
      event_typeDialog: false,
      deleteEventTypeDialog: false,
      deleteEventTypesDialog: false,
      submitted: false,
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate()
    }
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.saveEventType = this.saveEventType.bind(this);
    this.deleteEventType = this.deleteEventType.bind(this);
    this.openNew = this.openNew.bind(this);
    this.editEventType = this.editEventType.bind(this);
    this.confirmDeleteEventType = this.confirmDeleteEventType.bind(this);
    this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
    this.deleteSelectedEventTypes = this.deleteSelectedEventTypes.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.hideDeleteEventTypeDialog = this.hideDeleteEventTypeDialog.bind(this);
    this.hideDeleteEventTypesDialog = this.hideDeleteEventTypesDialog.bind(this);

  }

  componentDidMount() {
    this.getEventsTypes()
  }

  isValid() {
    const { event_type, description } = this.state.event_type
    const event_typeMessage = this.validateEventType()
    const descriptionMessage = this.validateDescription()

    if (event_type && description) {
      if (!event_typeMessage && !descriptionMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  validateEventType() {
    const event_type = this.state.event_type

    let event_typeMessage;
    if (!event_type.event_type) {
      event_typeMessage = 'Requerido'
    } else {
      event_typeMessage = ''
    }
    this.setState({ event_typeMessage})
    return event_typeMessage
  } 

  validateDescription() {
    const event_type = this.state.event_type

    let descriptionMessage
    if (!event_type.description) {
      descriptionMessage = ' Requerido'
    } else {
      descriptionMessage = ''
    }
    this.setState({ descriptionMessage })
    return descriptionMessage
  }

  getTextAuxEventType() {
    const message = this.state.event_typeMessage
    return message
  }

  getTextAuxDescription() {
    const message = this.state.descriptionMessage
    return message
  }

  getEventsTypes = async () => {
    try {
      let res = axios.get(`${url}/api/auth/event_types`)
      .then(res => {
        const event_types = res.data;
        this.setState({ event_types})
      });
      console.log(res.data)
    } catch (error) {
      
    }
  }

  deleteEventType(){
    let data = {...this.state.event_type};

    try {
    let res = axios.delete(`${url}/api/auth/event_types/${data.id}`)
      .then(res => {
      this.getEventsTypes()
    })
    if (!res.error) {
      this.setState({
        data,
        deleteEventTypeDialog: false,
        event_type: this.emptyEventType
    });
    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Event Type Deleted', life: 3000 });
    }
      }catch(error){
      } 
  }

  saveEventType() {
    let state = { submitted: true };
    const decision = this.isValid();

    if (decision) {
      let data = {...this.state.event_type};
    if (this.state.event_type.id) {
        try {
          let res = axios.put(`${url}/api/auth/event_types/${data.id}`,data)
          .then(res =>{
          this.getEventsTypes()  
          //console.log(res);
          })
          if (!res.error) {     
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Type Updated', life: 3000 });
          }
        }catch(error){          
      }         

    }else{
      try {
        let res = axios.post(`${url}/api/auth/event_types`,data)
        
        .then(res => {
            this.getEventsTypes()
          })
          if (!res.error) {
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Type Created', life: 3000 });
          }
            }catch(error){
          }   
    }
    state = {
      ...state,
      data,
      event_typeDialog: false,
      event_type: this.emptyEventType
    }; 
  }

  this.setState(state);
}

openNew() {
  this.setState({
      event_type: this.emptyEventType,
      submitted: false,
      event_typeDialog: true
  })
}


hideDialog() {
  this.setState({
  submitted: false,
  event_typeDialog: false,
  event_typeMessage: '',
  descriptionMessage: '',
  })
}

onInputChange(e, name) {
  const val = (e.target && e.target.value) || '';
  let event_type = {...this.state.event_type};
  event_type[`${name}`] = val;
  
  this.setState({ event_type });
}

editEventType(event_type) {
  this.setState({
      event_type: { ...event_type },
      event_typeDialog: true
  })

}

confirmDeleteEventType(event_type) {
  this.setState({
    event_type,
    deleteEventTypeDialog: true
  })
}

confirmDeleteSelected() {
this.setState({ deleteEventTypesDialog: true})
}

deleteSelectedEventTypes() {
  let event_types = this.state.event_types.filter(val => !this.state.selectedEventTypes.includes(val));
  let data = this.state.event_types.filter(val => this.state.selectedEventTypes.includes(val));

  console.log("DATA: ", data);
  try {
    let res = axios.delete(`${url}/api/public/events_types`, {data})
    .then (res => {
      this.getEventsTypes()
    })
    if (!res.error) {
      this.setState({
        event_types,
        deleteEventTypesDialog: false,
        selectedEventTypes: null
      });
      this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Event types deleted', life: 3000 });
    }
  } catch (error) {
    console.log(error);
  }
}


hideDeleteEventTypeDialog() {
  this.setState({ deleteEventTypeDialog: false })
}

hideDeleteEventTypesDialog() {
  this.setState({ deleteEventTypesDialog: false })
}


  actionBodyTemplate(rowData) {
    return (
      <>
       <Button icon="pi pi-pencil" color="primary" style={{verticalAlign: 'middle', margin: '10px', width:"30px", height: "30px"}} onClick={() => this.editEventType(rowData)}/>
       <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteEventType(rowData)}/>
      </>
    )
  }



  render (){

    const deleteEventTypeDialogFooter = (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEventTypeDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteEventType} />
      </>
    );

    const EventTypeDialogFooter = (
      <>
          <FormattedMessage id="GENERAL.CANCEL">
            {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
          </FormattedMessage>
          <FormattedMessage id="GENERAL.SAVE">
            {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveEventType} />}
          </FormattedMessage>
      </>
     );

     const deleteEventTypesDialogFooter = (
      <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEventTypesDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedEventTypes} />
      </>
  );

    return(
      <div>
        <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
            notifications types
				  <span className="d-block text-muted pt-2 font-size-sm">List of notifications types</span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 
                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedEventTypes || !this.state.selectedEventTypes.length} />}
                      </FormattedMessage>
                    </div>
                    

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_ROLE_TYPE">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div> 

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                    </span></p>
                    </div>  
                    
            </div>
          </div>

          <div>
           {/*SHOW ROLE TYPES */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.event_types} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Role Type found." loading={this.state.loading}
                        selection={this.state.selectedEventTypes} onSelectionChange={e => this.setState({ selectedEventTypes: e.value })} dataKey="id">
                        <Column selectionMode='multiple'></Column>
                        <Column field="event_type" header="Notification type"  filter filterPlaceholder={this.state.campusText.SearchByEventType} sortable></Column>
                        <Column field="description" header={this.state.campusText.Description}  filter filterPlaceholder={this.state.campusText.SearchByDescription} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
          </div>

          <div> 
          {/*DELETE ROLE TYPE */}
          <Dialog visible={this.state.deleteEventTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEventTypeDialogFooter} onHide={this.hideDeleteEventTypeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.event_type && <span> Are you sure you want to delete <b>{this.state.event_type.name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          
          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteEventTypesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEventTypesDialogFooter} onHide={this.hideDeleteEventTypesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.event_type && <span>Are you sure you want to delete the selected role types?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE ROLE TYPE & CREATE ROLE TYPE*/}
          <Dialog visible={this.state.event_typeDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"170", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_ROLE_TYPE"/>} modal footer={EventTypeDialogFooter} onHide={this.hideDialog}>
          <br></br>
          <div className="form-group row">
              {/* EventType */}
              <div className="col-lg-6"> 
              <span className="p-float-label">                      
                <InputText
                  style={{ width: 250, height:33 }}
                  id="event_type"
                  variant="outlined"
                  margin="dense"
                  value={this.state.event_type.event_type}             
                  onChange={(e) => this.onInputChange(e, 'event_type')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.event_type.event_type})}
                  />
              <label htmlFor="event_type">Event Type</label>    
              </span>
              <small id="event_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEventType(this.state)}</small>
              </div>
              {/* Description */}
              <div className="col-lg-6">
              <span className="p-float-label">                      
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="description"
                  variant="outlined"  
                  type="description"
                  margin="dense"
                  value={this.state.event_type.description}             
                  onChange={(e) => this.onInputChange(e, 'description')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.event_type.description })}
                  />
              <label htmlFor="description">Description</label>    
              </span>    
              <small id="description-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription(this.state)}</small>
              </div>
              </div>
          </Dialog>
        </div>

      </div>
    )
  }
}

export default withRouter(EventsTypesTable)