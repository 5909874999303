/*import React from "react";
import {Dashboard} from "../modulesAgency/_partials/dashboards/Dashboard";
export function DashboardPage() {
  return <Dashboard />;
}*/

import React from "react";
import DashboardGraphs from "../modulesAgency/Dashboard/DashboardGraphs";

export const DashboardPage = () => {
  return (<><DashboardGraphs/></>);
};
