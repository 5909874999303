/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
// import SVG from "react-inlinesvg";
// import {toAbsoluteUrl} from "../../../_helpers";
// import { useIntl } from "react-intl";
import axios from 'axios'
import ServerURL from "../../../../../ServerURL";
import { Avatar } from "@material-ui/core";
// import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

const url = ServerURL();

// const id_director = 58;
const id_gerente = 62;
const id_coordinador = 63;
const id_agente = 64;

export function AdvanceTablesWidget7({ className }) {
  // const int1 = useIntl();
  // const my_user = useSelector(state => state.auth.user);


  const [users, setUsers] = useState(null);
  const [groupAverage, setGroupAverage] = useState(null);

  useEffect(() => {
    if(!users){
      const getTeam = async () =>{
        try {
          await axios.get(`${url}/api/auth/team`)
          .then(res => {
            const users = res.data
            
            console.log(users)
    
            let sumAverageCourses = 0;
            users.forEach(user => {
              sumAverageCourses += user.averageCourses;
            })
    
            const groupAverage = sumAverageCourses/users.length
    
            setUsers(users);
            setGroupAverage(groupAverage)
          });
          }catch(error){
        }
      }

      getTeam();
    }
  }, [users]);

  const getRol = (role) => {
    let Message = null;
    switch (role) {
      case id_gerente:
        Message = "MANAGER";
        break;
      case id_coordinador:
          Message = "COORDINATOR";
        break;
      case id_agente:
        Message = "AGENT";    
        break;
      default:
        Message = "DIRECTOR";
        break;
    }
    return Message;
  }

  const coursesInProgressBodyTemplate = (data) => {
    let coursesInProgress = 0;
    if(data.avances){
      data.avances.forEach(course => {
        if(course.avance !== 0 && course.avance !== 100){
          coursesInProgress++;
        }
      })
    }
    return <span>{coursesInProgress}</span>
  }

  const coursesCompletedBodyTemplate = (data) => {
    let coursesCompleted = 0;
    if(data.avances){
      data.avances.forEach(course => {
        if(course.avance !== 0 && course.avance === 100){
          coursesCompleted++;
        }
      })
    }
    return <span>{coursesCompleted}</span>
  }

  const renderRow = (data) => {
    const role = getRol(data.fk_id_role);

    return (
      <tr key={data.id}>
        <td className="pl-0 py-8">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50 symbol-light mr-4">
                <span>
                  <span className="svg-icon h-75 align-self-end">
                    <Avatar alt={data.fullname} src={`${data.photo}`} style={{width: "50px", height: "50px"}} />
                  </span>
                </span>
            </div>
            <div>
              {/* <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{data.fullname}</a> */}
              <span className="text-dark-75 font-weight-bolder font-size-lg">{data.fullname}</span>
              <span className="text-muted font-weight-bold d-block"><FormattedMessage id={`GENERAL_ROLES.${role}`}/></span>
            </div>
          </div>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {data.coursesPersonalAssigned}
            </span>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {coursesInProgressBodyTemplate(data)}
            </span>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {coursesCompletedBodyTemplate(data)}
            </span>
        </td>
        <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
              {
                data.averageCourses ?
                Number.parseFloat(data.averageCourses).toFixed(2)
                :
                "0"
              }
            </span>
        </td>
      </tr>
    )
  }

  let boolView = true;

  if(users && users.length === 0){
    boolView = false;
  }

  let footer = null;
  if(groupAverage === 0 || groupAverage){
    footer = ": " + groupAverage.toFixed(2);
  }else{
    footer = ": 0";
  }

  return (
    <>
      {
        boolView ?
          (users && users.length !== 0) ?
            <div className={`card card-custom ${className}`}>
              {/* Head */}
              <div className="card-header border-0 py-5">
                <span className="font-weight-bolder text-dark" style={{fontSize:"1.7em"}}><FormattedMessage id="LEARNING_GENERAL.MY_TEAM"/></span>
              </div>
              {/* Body */}
              <div className="card-body pt-0 pb-3">
                <div className="tab-content">
                  <div className="table-responsive">
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                      <thead>
                      <tr className="text-left text-uppercase">
                        <th className="pl-7" style={{minWidth: "250px"}}><span className="text-dark-75"><FormattedMessage id="GENERAL_MESSAGE.MEMBERS"/></span></th>
                        <th className="text-center" style={{minWidth: "100px"}}><FormattedMessage id="GENERAL_MESSAGE.ASSIGNED_COURSES"/></th>
                        <th className="text-center" style={{minWidth: "100px"}}><FormattedMessage id="LEARNING_COURSE_LIST.COURSES_IN_PROGRESS"/></th>
                        <th className="text-center" style={{minWidth: "100px"}}><FormattedMessage id="GENERAL_MESSAGE.COMPLETED_COURSES"/></th>
                        <th className="text-center" style={{minWidth: "100px"}}><FormattedMessage id="GENERAL_MESSAGE.AVERAGE_MARK"/></th>
                      </tr>
                      </thead>
                      {
                        users ?
                          <tbody>
                            {users.map((user) => renderRow(user))}
                          </tbody>
                        :
                          <></>
                      }
                    </table>
                    <div className="text-dark-75 font-weight-bolder mb-2 h3 text-center">
                      <span><FormattedMessage id="GENERAL_MESSAGE.GROUP_AVERAGE"/>{footer}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          :
            <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
        :
          <div style={{display:"grid", justifyItems:"center", marginTop:"15vh", marginBottom:"15vh"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <span style={{marginTop:"1em", fontSize:"2em"}}><FormattedMessage id="GENERAL_MESSAGE.NO_USERS"/></span>
          </div>
      }
    </>
  );
}
