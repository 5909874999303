import React, { Component } from 'react';
import axios from 'axios'
import { connect } from 'react-redux';
import ServerURL from '../../../ServerURL'
import SVG from "react-inlinesvg";
import { Toast } from 'primereact/toast';
import "primeflex/primeflex.css";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { FormattedMessage, injectIntl } from "react-intl";
import { ProgressSpinner } from 'primereact/progressspinner';

const url = ServerURL();

class DataAgency extends Component {

  constructor(props) {
    super(props);
    this.state = {
      enterprise_data: null,
      enterprise_data_view: null,
      enterpriseWebsiteEdit: false,
      enterpriseEmailEdit: false,
      imgOficial: null,
      imgPreview: null,
      selectedCountry: null,
      countries: null
    };

    // this.onChangeTypeView = this.onChangeTypeView.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.updateEnterpriseImage = this.updateEnterpriseImage.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.resetSocialNetwork = this.resetSocialNetwork.bind(this);
    this.renderSocialNetwork = this.renderSocialNetwork.bind(this);

  }

  componentDidMount() {
    this.getData()
  }

  changeImage(e){
    this.setState({imgPreview: URL.createObjectURL(e.target.files[0]), imgFile: e.target.files[0]})
  }


  async onCountryChange(e) {
    let fk_id_country = 0;
    if(e.value){
      fk_id_country = e.value.id;
    }
    console.log(e.value)
    const enterprise_data = {
      id: this.state.enterprise_data.id,
      fk_id_country
    }
    try {
      await axios.post(`${url}/api/auth/enterprise_country_update`, enterprise_data)
      .then(res => {
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.UPDATED_COUNTRY'}), life: 6000});
        this.setState({ selectedCountry: e.value });
      });                     
    }catch(error){
      console.log(error)
    }
  }

  selectedCountryTemplate(option, props) {
    if (option) {
        return (
            <div className="country-item country-item-value">
                <div>{option.country_name}</div>
            </div>
        );
    }

    return (
        <span>
            {props.placeholder}
        </span>
    );
  }

  countryOptionTemplate(option) {
    return (
        <div className="country-item">
            <div>{option.country_name}</div>
        </div>
    );
  }

  getData = async () =>{
    try {
      await axios.get(`${url}/api/auth/enterprise/${this.props.user.fk_id_enterprise}`)
      .then(res => {
        const enterprise_data = res.data;
        this.setState({ enterprise_data, enterprise_data_view: enterprise_data, imgOficial: enterprise_data.logo, imgPreview: enterprise_data.logo, selectedCountry: enterprise_data.fk_id_country })
        console.log("enterprise:",enterprise_data)
        this.getCountries()
      });                     
    }catch(error){
    }
  }

  getCountries = async () =>{
    try {
      await axios.get(`${url}/api/auth/countries`)
      .then(res => {
        const countries = res.data;
        // console.log(countries);

        let data_country = null;
        const fk_id_country = this.state.selectedCountry;
        countries.forEach(country => {
          if(country.id === fk_id_country){
            data_country = country; 
          }
        })

        this.setState({ countries, selectedCountry: data_country })
      });
      }catch(error){
    }
  }

  saveData = async () =>{
    try {
      let res = axios.post(`${url}/api/auth/enterprise_data_update`, this.state.enterprise_data)
      .then(res => {
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.UPDATED_AGENCY_DATA'}), life: 6000});
        this.setState({
          enterpriseWebsiteEdit: false,
          enterpriseEmailEdit: false,
          enterprisePhoneEdit: false,
        })
      });                     
        console.log(res.data)
    }catch(error){
    }
  }

  saveSocialNetwork = async () =>{
    const { enterprise_data, enterprise_data_view } = this.state

    const new_enterprise_data = {
      ...enterprise_data,
      facebook: enterprise_data_view.facebook,
      twitter: enterprise_data_view.twitter,
      youtube: enterprise_data_view.youtube,
      whatsapp: enterprise_data_view.whatsapp,
    }

    try {
      let res = axios.post(`${url}/api/auth/enterprise_data_update`, new_enterprise_data)
      .then(res => {
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_GENERAL.UPDATED_SOCIAL_NETWORKS'}), life: 6000});
        this.setState({
          socialNetworkEdit: false,
          enterprise_data: new_enterprise_data
        })
      });                     
        console.log(res.data)
    }catch(error){
    }
  }
  
  async updateEnterpriseImage(){
    this.setState({loadImage: true});
    const formData = new FormData();
    formData.append('file',this.state.imgFile);
    let config = {
      'Content-Type': 'multipart/form-data'
    }
    
    try {
      await axios.put(`${url}/api/auth/enterprise_img/${this.state.enterprise_data.id}`, formData, config)
      .then(res => {
        const newImg = this.state.imgPreview
        const originalmg = this.state.imgOficial
        const message_type = res.data.message_type
        const message = res.data.message
        console.log("IMG subido")
        let type = null;
        if(message_type === "success"){
          
          this.setState({imgOficial: newImg, imgFile: null, loadImage: false})
          type = "GENERAL.SUCCESSFUL"
        }else{
          type = "GENERAL_MESSAGE.ERROR"
          this.setState({imgPreview: originalmg, imgFile: null, loadImage: false})
        }
        this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 6000 });
      });
      }catch(error){
        console.log(error)
    }
  }

  resetSocialNetwork(){
    const { enterprise_data } = this.state;

    let newEnterpriseData = {
      facebook: enterprise_data.facebook,
      twitter: enterprise_data.twitter,
      youtube: enterprise_data.youtube,
      whatsapp: enterprise_data.whatsapp
    }

    this.setState({enterprise_data_view: newEnterpriseData, socialNetworkEdit: false});
  }

  renderImageOption(){
    let imgOptionView
    if(!this.state.imgFile){
      imgOptionView = (
        <FormattedMessage id="GENERAL_MESSAGE.CHANGE_IMAGE">
          {
            message => (
              <input type="button" className="btn btn-secondary mx-auto d-block mt-1" value={message} onClick={() => document.getElementById('fileInput').click()} />
            )
          }
        </FormattedMessage>
      )
    }else{
      if(!this.state.loadImage){
        imgOptionView = (
        <div className="d-flex mt-2 justify-content-around">
          <button className="btn btn-danger" onClick={() => this.setState({imgFile: null, imgPreview: this.state.imgOficial})}><FormattedMessage id="GENERAL.DISCARD_CHANGES"/></button>
          <button className="btn btn-primary" onClick={this.updateEnterpriseImage}><FormattedMessage id="GENERAL.SAVE"/></button>
        </div>
        )
      }else{
        imgOptionView = (
        <div className="mt-2 text-center">
          <ProgressSpinner style={{height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
        </div>
        )
      }
    }
    return imgOptionView
  }

  renderWebsite(){

    let websiteView

    if (this.state.enterpriseWebsiteEdit){
      websiteView = (
        <div className="mb-10">
          <InputText style={{width:"100%", fontSize:"1.75rem", fontWeight:500, border:"none", padding:0, textAlign:"center"}} className="p-inputtext-lg p-d-block" id="title" value={this.state.enterprise_data.website} onChange={(e) => this.setState({enterprise_data: {...this.state.enterprise_data, website: e.target.value}})} autoFocus/>
          <FormattedMessage id="GENERAL.SAVE_CHANGES">
            {(message) => <Button label={message} style={{verticalAlign: 'middle',   marginTop: "10px"}} onClick={this.saveData}></Button>}
          </FormattedMessage>
        </div>
      )
    }else if(this.state.enterprise_data.website){
      websiteView = (
        <div className="mb-10 row" style={{alignSelf:"center"}}>
          <h2 className="mr-2" style={{alignSelf:"center"}}>{this.state.enterprise_data.website}</h2>
          <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   marginBottom: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({enterpriseWebsiteEdit: true})}></Button>
        </div>
      )
    }else{
      websiteView = (
        <div className="mb-10 row" style={{alignSelf:"center"}}>
          <h2 className="mr-2" style={{alignSelf:"center", color:"darkgrey"}}><FormattedMessage id="LEARNING_GENERAL.WRITE_COMPANY_WEBSITE"/></h2>
          <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   marginBottom: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({enterpriseWebsiteEdit: true})}></Button>
        </div>
      )
    }

    return websiteView
  }

  renderEmail(){

    let emailView

    if (this.state.enterpriseEmailEdit){
      emailView = (
        <div className="mb-10">
          <InputText style={{width:"100%", fontSize:"1.75rem", fontWeight:500, border:"none", padding:0, textAlign:"center"}} className="p-inputtext-lg p-d-block" id="title" value={this.state.enterprise_data.email} onChange={(e) => this.setState({enterprise_data: {...this.state.enterprise_data, email: e.target.value}})} autoFocus/>
          <FormattedMessage id="GENERAL.SAVE_CHANGES">
            {(message) => <Button label={message} style={{verticalAlign: 'middle',   marginTop: "10px"}} onClick={this.saveData}></Button>}
          </FormattedMessage>
        </div>
      )
    }else if(this.state.enterprise_data.email){
      emailView = (
        <div className="mb-10 row" style={{alignSelf:"center"}}>
          <h2 className="mr-2" style={{alignSelf:"center"}}>{this.state.enterprise_data.email}</h2>
          <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   marginBottom: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({enterpriseEmailEdit: true})}></Button>
        </div>
      )
    }else{
      emailView = (
        <div className="mb-10 row" style={{alignSelf:"center"}}>
          <h2 className="mr-2" style={{alignSelf:"center", color:"darkgrey"}}><FormattedMessage id="LEARNING_GENERAL.WRITE_COMPANY_EMAIL"/></h2>
          <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   marginBottom: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({enterpriseEmailEdit: true})}></Button>
        </div>
      )
    }

    return emailView
  }

  renderPhone(){

    let phoneView

    if (this.state.enterprisePhoneEdit){
      phoneView = (
        <div className="mb-10">
          <InputText keyfilter="int" style={{width:"100%", fontSize:"1.75rem", fontWeight:500, border:"none", padding:0, textAlign:"center"}} className="p-inputtext-lg p-d-block" id="title" value={this.state.enterprise_data.phone} onChange={(e) => this.setState({enterprise_data: {...this.state.enterprise_data, phone: e.target.value}})} autoFocus/>
          <FormattedMessage id="GENERAL.SAVE_CHANGES">
            {(message) => <Button label={message} style={{verticalAlign: 'middle',   marginTop: "10px"}} onClick={this.saveData}></Button>}
          </FormattedMessage>
        </div>
      )
    }else if(this.state.enterprise_data.phone){
      phoneView = (
        <div className="mb-10 row" style={{alignSelf:"center"}}>
          <h2 className="mr-2" style={{alignSelf:"center"}}>{this.state.enterprise_data.phone}</h2>
          <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   marginBottom: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({enterprisePhoneEdit: true})}></Button>
        </div>
      )
    }else{
      phoneView = (
        <div className="mb-10 row" style={{alignSelf:"center"}}>
          <h2 className="mr-2" style={{alignSelf:"center", color:"darkgrey"}}><FormattedMessage id="LEARNING_GENERAL.WRITE_COMPANY_PHONE"/></h2>
          <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{verticalAlign: 'middle',   marginBottom: "10px", width: "30px", height: "30px",}} onClick={() => this.setState({enterprisePhoneEdit: true})}></Button>
        </div>
      )
    }

    return phoneView
  }

  renderSocialNetwork(enterprise_data, socialNetworkEdit){
    let socialNetworkView
    if (socialNetworkEdit){
      socialNetworkView = (
        <section className="p-col-12">
          <div className="container border p-4">
            <div className="row align-items-center">
              <div className="col text-center">
                <h2><FormattedMessage id="ADMIN_GENERAL.SOCIAL_MEDIA"/></h2>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.FACEBOOK">
                  {message => <SVG width="auto" height={60} title={message} src={toAbsoluteUrl("/media/svg/logos/facebook-3.svg")}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.TWITTER">
                  {message => <SVG width="auto" height={60} title={message} src={toAbsoluteUrl("/media/svg/logos/twitter.svg")}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.YOUTUBE">
                  {message => <SVG width="auto" height={80} title={message} src={toAbsoluteUrl("/media/svg/logos/youtube-3.svg")}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.WHATSAPP">
                  {message => <WhatsAppIcon titleAccess={message} style={{color:"green", fontSize: 60}}/>}
                </FormattedMessage>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.FACEBOOK">
                  {message => <InputText placeholder={message} style={{width:"80%", fontSize:"13px", padding:0, textAlign:"center", marginLeft:"auto", marginRight:"auto"}}  className="p-inputtext-lg p-d-block" value={this.state.enterprise_data_view.facebook} onChange={(e) => this.setState({enterprise_data_view: {...this.state.enterprise_data_view, facebook: e.target.value}})}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.TWITTER">
                  {message => <InputText placeholder={message} style={{width:"80%", fontSize:"13px", padding:0, textAlign:"center", marginLeft:"auto", marginRight:"auto"}}  className="p-inputtext-lg p-d-block" value={this.state.enterprise_data_view.twitter} onChange={(e) => this.setState({enterprise_data_view: {...this.state.enterprise_data_view, twitter: e.target.value}})}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.YOUTUBE">
                  {message => <InputText placeholder={message} style={{width:"80%", fontSize:"13px", padding:0, textAlign:"center", marginLeft:"auto", marginRight:"auto"}}  className="p-inputtext-lg p-d-block" value={this.state.enterprise_data_view.youtube} onChange={(e) => this.setState({enterprise_data_view: {...this.state.enterprise_data_view, youtube: e.target.value}})}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.WHATSAPP">
                  {message => <InputText keyfilter="int" placeholder={message} style={{width:"80%", fontSize:"13px", padding:0, textAlign:"center", marginLeft:"auto", marginRight:"auto"}}  className="p-inputtext-lg p-d-block" value={this.state.enterprise_data_view.whatsapp} onChange={(e) => this.setState({enterprise_data_view: {...this.state.enterprise_data_view, whatsapp: e.target.value}})}/>}
                </FormattedMessage>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col text-center" style={{marginTop: "10px"}}>
                <FormattedMessage id="GENERAL.SAVE_CHANGES">
                  {(message) => <Button label={message} style={{verticalAlign: 'middle'}} onClick={() => this.saveSocialNetwork()}></Button>}
                </FormattedMessage>
                <Button icon="pi pi-times" className="p-button-danger p-button-plain" style={{verticalAlign: 'middle', marginLeft:"5px", height:"34px"}} onClick={() => this.resetSocialNetwork()}></Button>
              </div>
            </div>
          </div>
        </section>
      )
    }else{
      socialNetworkView = (
        <section className="p-col-12">
          <div className="container border p-4">
            <div className="row align-items-center">
              <div className="col-7 text-right">
                <h2><FormattedMessage id="ADMIN_GENERAL.SOCIAL_MEDIA"/></h2>
              </div>
              <div className="col-5 text-right">
                <Button icon="pi pi-pencil" className="p-button-text p-button-plain" style={{height: "30px", fontSize:12}} onClick={() => this.setState({socialNetworkEdit: true})}></Button>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.FACEBOOK">
                  {message => <SVG width="auto" height={60} title={message} src={toAbsoluteUrl("/media/svg/logos/facebook-3.svg")}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.TWITTER">
                  {message => <SVG width="auto" height={60} title={message} src={toAbsoluteUrl("/media/svg/logos/twitter.svg")}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.YOUTUBE">
                  {message => <SVG width="auto" height={80} title={message} src={toAbsoluteUrl("/media/svg/logos/youtube-3.svg")}/>}
                </FormattedMessage>
              </div>
              <div className="col text-center">
                <FormattedMessage id="DATA_ENTERPRISE.SOCIAL_MEDIA.WHATSAPP">
                  {message => <WhatsAppIcon titleAccess={message} style={{color:"green", fontSize: 60}}/>}
                </FormattedMessage>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col text-center">
                {
                  enterprise_data.facebook ?
                  <span>{enterprise_data.facebook}</span>
                  :
                  <span><FormattedMessage id="GENERAL.NONE"/></span>
                }
              </div>
              <div className="col text-center">
                {
                  enterprise_data.twitter ?
                  <span>{enterprise_data.twitter}</span>
                  :
                  <span><FormattedMessage id="GENERAL.NONE"/></span>
                }
              </div>
              <div className="col text-center">
                {
                  enterprise_data.youtube ?
                  <span>{enterprise_data.youtube}</span>
                  :
                  <span><FormattedMessage id="GENERAL.NONE"/></span>
                }
              </div>
              <div className="col text-center">
                {
                  enterprise_data.whatsapp ?
                  <span>{enterprise_data.whatsapp}</span>
                  :
                  <span><FormattedMessage id="GENERAL.NONE"/></span>
                }
              </div>
            </div>
          </div>
        </section>
      )
    }

    return socialNetworkView;
  }

  

  render() {
    const {enterprise_data, enterprise_data_view, imgPreview, countries, socialNetworkEdit} = this.state
    console.log(enterprise_data)
    let src_logo = null;
    if(enterprise_data && imgPreview){
      src_logo = imgPreview
    }else{
      src_logo = "https://wissify.nyc3.digitaloceanspaces.com/prueba/imagen_prueba.jpg"
    }
    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <div className="card p-6">
          {
            (enterprise_data && countries) ?
            <div className="p-grid pt-6">
              <div className="p-col-6">
                <h1 className="mb-8 text-center">{enterprise_data.enterprise_name}</h1>
                <div className="card p-4 text-center">
                  <h2 className="mb-4"><FormattedMessage id="ADMIN_GENERAL.WEBSITE"/>:</h2>
                  {this.renderWebsite()}
                  <h2 className="mb-4"><FormattedMessage id="ADMIN_GENERAL.EMAIL"/>:</h2>
                  {this.renderEmail()}
                  <h2 className="mb-4"><FormattedMessage id="ADMIN_GENERAL.PHONE"/>:</h2>
                  {this.renderPhone()}
                </div>
              </div>
              <div className="p-col-6" style={{marginTop:"auto", marginBottom:"auto", textAlign:"center"}}>
                <h3><FormattedMessage id="DATA_ENTERPRISE.LOCATION.COMPANY_IMAGE"/></h3>
                <img className="img-fluid mx-auto d-block img-thumbnail" alt={enterprise_data.enterprise_name} style={{maxHeight:250}} src={src_logo}/>
                <input id="fileInput" type="file" accept="image/x-png,image/jpeg" style={{display:"none"}} onChange={ e => this.changeImage(e)}/>
                {
                  this.renderImageOption()
                }
              </div>
              <section className="p-col-12">
                <div className="container border p-4">
                  <div className="row align-items-center">
                    <div className="col text-center">
                      <h2><FormattedMessage id="ADMIN_GENERAL.LOCATION"/></h2>
                    </div>
                  </div>
                  <div className="row align-items-center mt-2">
                    <div className="col text-center">
                      <span><FormattedMessage id="DATA_ENTERPRISE.LOCATION.COUNTRY_ORIGIN"/></span>
                    </div>
                    <div className="col text-center">
                    <Dropdown value={this.state.selectedCountry} options={countries} onChange={this.onCountryChange} optionLabel="country_name" filter showClear filterBy="country_name" placeholder={this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SELECT_COUNTRY'})}
                      valueTemplate={this.selectedCountryTemplate} itemTemplate={this.countryOptionTemplate} />
                    </div>
                  </div>
                </div>
              </section>
              {
                this.renderSocialNetwork(enterprise_data_view, socialNetworkEdit)
              }
            </div>
            :
            <div style={{display:"flex", justifyContent: "center", padding: "4em"}}><i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i></div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default injectIntl(
  connect(mapStateToProps)(DataAgency)
)