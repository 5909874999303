import React, {Component} from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import axios from 'axios'
import ServerURL from '../../../ServerURL'
// import { Col } from 'react-bootstrap'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import * as auth from '../../modulesAdmin/Auth/_redux/authRedux'
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from "../../../_metronic/i18n/TextTranslate";



const url = ServerURL()

class AssignStudents extends Component {

    constructor(props) {
        super(props)
        this.state = {
            groups: null,
            students: null,
            students_groups: null,
            redirect: false,

            selectedStudents: null,
            assignStudentsDialog: null,

            campusText: getTextCampusTranslate(),
            paginatorText: getTextPaginatorTranslate(),
            textTranslate: getGeneralTextTranslate(),
        }

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this)
        this.changePage = this.changePage.bind(this)
        this.assignSelectedStudents = this.assignSelectedStudents.bind(this)

        this.confirmAssignStudents = this.confirmAssignStudents.bind(this)
        this.hideAssignStudents = this.hideAssignStudents.bind(this)
    }

    componentDidMount(){
        this.getStudentsGroups()
        this.getStudents()
    }
    
    changePage(dataGroup){
        this.setState({ redirect: true })
    }

    getGroups = async () => {
        try {
            axios.get(`${url}/api/public/wiyu_groups`)
            .then(res => {
                const groups = res.data;
                this.setState({ groups })
                console.log("groups: ", groups);
            })
        } catch (error) {
            
        }
    }

    getStudents = async () =>{
        try {
        await axios.get(`${url}/api/public/students`)
        .then(res => {
            const students = res.data;
            this.ProgramStartDate(students)
            this.ProgramEndDate(students)
            this.setState({ students   })
            });                     
            // console.log(res.data)
        } catch(error){
            console.log(error);
        }       
    }

    ProgramStartDate(e){
        e.map((student) => {
            var origin = student.program_start_date
            var date = moment.utc(origin).format('DD/MM/YYYY')

            if(student.program_start_date!==null){
            student.program_start_date = date
            }

            return student
        })
    }

    ProgramEndDate(e){
        e.map((student) => {
            var origin = student.program_end_date
            var date = moment.utc(origin).format('DD/MM/YYYY')

            if(student.program_end_date!==null){
            student.program_end_date = date
            }

            return student
        })
    }

    getStudentsGroups = async () =>{
        try {
            await axios.get(`${url}/api/public/assign_students_groups/${this.props.dataGroup.id}`)
            .then(res =>{
                const students_groups = res.data
                this.setState({ students_groups })
                console.log("Students: ", res.data);
                // console.log("Students assigned: ", students_customer);
            })
        } catch (error) {
            console.log(error);
        }
    }

    assignSelectedStudents() {
        let data = this.state.students.filter(val => this.state.selectedStudents.includes(val))
        let id_group = this.props.dataGroup.id

        // for (let i = 0; i < data.length; i++){
        //     data[i].id_cs = this.state.selectedCustomer
        // }

        try {
            console.log("Data: ", data);
            axios.put(`${url}/api/public/assign_students_groups`, {data, id_group})
            .then(res => {
                console.log(res.data.message);
                let  { message, message_type } = res.data
                
                if(message_type === 'success') {
                    console.log("Entro");
                    this.getStudentsGroups()
                    this.setState({
                        assignStudentsDialog: false,
                        selectedStudents: null,
                    })
                } else if(message_type === 'warn'){
                    this.getStudentsGroups()
                    this.setState({
                        assignStudentsDialog: false,
                        selectedStudents: null
                    })
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error',detail: 'Error en el sistema, verificar con el administrador' })
                }
                this.toast.show({ severity: message_type, summary: 'Summary', detail: message, life: 3000})

            })

        } catch (error) {
            console.log('Error: ', error);
        }
    }

    confirmAssignStudents() {
        this.setState({ assignStudentsDialog: true })
    }

    hideAssignStudents() {
        this.setState({ assignStudentsDialog: false})
    }

    actionBodyTemplate(rowData){
        return(
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.changePage(rowData)} />
                {/* <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteStudent(rowData)} /> */}
            </>
        )
    }

    render(){

        if(this.state.redirect){
            return <Redirect to={{
                pathname: '/wiyu-groups'
            }} />
        }

        const assignDialogFooter = (
            <>
                <FormattedMessage id="GENERAL.NO">
                    {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideAssignStudents} />}
                </FormattedMessage>
                <FormattedMessage id="GENERAL.YES">
                    {(message) => <Button disabled={this.state.btnDisable} label={message} icon="pi pi-check" className="p-button-text" onClick={this.assignSelectedStudents} />}
                </FormattedMessage>
            </>
        );


        return(
            <div>
                <Toast ref={(el) => this.toast = el} />  

                <Dialog visible={this.state.assignStudentsDialog } style={{ width: '450px'}} header="Confirm" modal footer={ assignDialogFooter } onHide={this.hideAssignStudents} >
                    <div className="confirmation-content" >
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {this.state.students && <span> Are you sure you want delete the selected students?</span>}
                    </div>
                </Dialog>

                <h1> Group name - {this.props.dataGroup.group_name} </h1>
                <div className='d-flex justify-content-between mb-2'>
                    <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" icon="pi pi-arrow-left" onClick={this.changePage} variant='contained'/>
                </div>

                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <h3 className='card-label'>
                        Assign students to group
                        <span className='d-block text-muted pt-2 font-size-sm'>Assignment students to groups</span>
                    </h3>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <div style={{ marginRight: 10}}>
                            <Button type='button' label='Assign students' icon='pi pi-plus' variant='contained' color='primary' onClick={this.confirmAssignStudents} disabled={!this.state.selectedStudents || !this.state.selectedStudents.length} />
                        </div>
                    </div>

                </div>

                <div>
                    <div className='card'>
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.students} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={6} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No students found." loading={this.state.loading}
                        selection={this.state.selectedStudents} onSelectionChange={e => this.setState({ selectedStudents: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="fullname" header="Full name" sortable filter filterPlaceholder='Search by full name'></Column>
                        <Column field="email" header="Email" sortable filter filterPlaceholder='Search by email'></Column>
                        <Column field="phone" header="Phone" sortable filter filterPlaceholder='Search by phone'></Column>
                        <Column field="program_name" header="Program name" sortable filter filterPlaceholder='Search by school'></Column>
                        <Column field='school_origin' header="School"></Column>

                    </DataTable>
                    </div>
                </div>

                <br/>
    
                <div className="card-header flex-wrap border-0 pt-6 pb-0">
                    <h3 className="card-label">
                        List of students assigned
                        {/* <span className="d-block text-muted pt-2 font-size-m">Students assigned</span> */}
                    </h3>

                    <div>
                        <div className="card">
                            <DataTable
                            ef={(el) => this.dt = el} value={this.state.students_groups} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                            globalFilter={this.state.globalFilter} emptyMessage="No students found." loading={this.state.loading}
                            selection={this.state.selectedStudents} onSelectionChange={e => this.setState({ selectedStudents: e.value })} dataKey="id">
                                <Column field="fullname" header="Full name" filter filterPlaceholder="Search by full name" sortable></Column>
                                <Column field="email" header="Email" filter filterPlaceholder={this.state.campusText.SearchByEmail} sortable></Column>
                                <Column field="phone" header="Phone" filter filterPlaceholder={this.state.campusText.SearchByPhone} sortable></Column>
                                <Column field='group_name' header="Group" sortable></Column>
                                <Column field="program_name" header="Program" filter filterPlaceholder="Search by program" sortable></Column>                                
                            </DataTable>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataGroup: state.auth.dataGroup
})

export default injectIntl(
    connect(mapStateToProps, auth.actions)(AssignStudents)
)