import React from "react";
import {useSubheader} from "../../_metronic/_core";
import StudentsTable from "../modulesWiyu/Students/StudentsTable";

export const StudentsPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Students");

  return (<><StudentsTable/></>);

};