import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';



import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const url = ServerURL();



class OfficesTable extends Component {
            

  
  emptyOffice = {
    fk_id_enterprise:'',
    office: '',
    contact: '',
    email: '',
    phone:'',
    website: '',
}

 

constructor(props) {
  super(props);  

  this.state = { 
    fk_id_enterprise:'',
    name: '',
    contact: '',
    email: '',
    phone:'',
    website: '',

     /**
       * Aux messages
       */
      fk_id_enterpriseMessage: '',
      officeMessage: '', 
      contactMessage: '',
      emailMessage: '',
      phoneMessage: '',
      websiteMessage: '', 

    office: this.emptyOffice,
    offices:null,
    selectedAgency: null,
    resetAgency:null,
    ids:null,
    globalFilter: null,
    selectedOffices: null,
    officeDialog: false,
    deleteOfficeDialog: false,
    deleteOfficesDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveOffice = this.saveOffice.bind(this);
  this.deleteOffice = this.deleteOffice.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editOffice = this.editOffice.bind(this);
  this.confirmDeleteOffice= this.confirmDeleteOffice.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedOffices = this.deleteSelectedOffices.bind(this);
  this.hideDeleteOfficeDialog = this.hideDeleteOfficeDialog.bind(this);
  this.hideDeleteOfficesDialog = this.hideDeleteOfficesDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  this.onAgencyChange = this.onAgencyChange.bind(this);

  }


    

      componentDidMount() {
        this.getOffices()

      }

   

      isValid(){
        const { fk_id_enterprise,office, contact, email, phone, website } = this.state.office
              const fk_id_enterpriseMessage = this.validateAgency()
              const officeMessage = this.validateOffice()
              const contactMessage = this.validateContact()
              const emailMessage = this.validateEmail()
              const phoneMessage = this.validatePhone()
              const websiteMessage = this.validateWebsite()

        
              if(fk_id_enterprise && office && contact && email && phone && website ){
                if(!fk_id_enterpriseMessage && !officeMessage && !contactMessage && !emailMessage && !phoneMessage && !websiteMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
            validateAgency() {
              const office = this.state.office
              
              let fk_id_enterpriseMessage;
              if (!office.fk_id_enterprise) {
                fk_id_enterpriseMessage = 'Requerido';
              } else {
                fk_id_enterpriseMessage = ''
              }
              this.setState({ fk_id_enterpriseMessage })
              return fk_id_enterpriseMessage
            }

            validateOffice() {
              const office = this.state.office
              
              let officeMessage;
              if (!office.office) {
                officeMessage = 'Requerido';
              } else {
                officeMessage = ''
              }
              this.setState({ officeMessage })
              return officeMessage
            }

            validateContact() {
              const office= this.state.office
              
              let contactMessage;
              if (!office.contact) {
                contactMessage = 'Requerido';
              } else {
                contactMessage = ''
              }
              this.setState({ contactMessage })
              return contactMessage
            }


            

            validateEmail() {
                    const office = this.state.office
                    
                    let emailMessage;
                    if (!office.email) {
                      emailMessage = 'Requerido';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(office.email)) {
                      emailMessage = 'Correo electronico invalido';
                    } else {
                      emailMessage = ''
                    }
                    this.setState({ emailMessage })
                    return emailMessage
                  }

            validatePhone() {
              const office = this.state.office
              
              let phoneMessage;
              if (!office.phone) {
                phoneMessage = 'Requerido';
              } else if (!/^[A-Z0-9._%+-]{6,12}$/i.test(office.phone)) {
                phoneMessage = 'Telefono invalido';
              } else {
                phoneMessage = ''
              }
              this.setState({ phoneMessage })
              return phoneMessage
            }

            validateWebsite() {
              const office = this.state.office
              
              let websiteMessage;
              if (!office.website) {
                websiteMessage = 'Requerido';
              } else if (!/^[A-Z0-9._%+-]+\.[A-Z]{2,4}$/i.test(office.website)) {
                websiteMessage = 'Website invalido';
              } else {
                websiteMessage = ''
              }
              this.setState({ websiteMessage })
              return websiteMessage
            }

            getTextAuxAgency(){
              const message = this.state.fk_id_agencyMessage
              return message
            }

            getTextAuxOffice(){
              const message = this.state.officeMessage
              return message
            }
        
            getTextAuxContact(){
              const message = this.state.contactMessage
               return message
             }
      
            getTextAuxEmail(){
             const message = this.state.emailMessage
              return message
            }

            getTextAuxPhone(){
              const message = this.state.phoneMessage
               return message
             }

             getTextAuxWebsite(){
              const message = this.state.websiteMessage
               return message
             }


            




      getAgencies = async () =>{
         
        try {
           
            let res = axios.get(`${url}/api/auth/agencies`)
            .then(res => {
              const agencies= res.data;
              this.setState({ agencies })
            });                     
              console.log(res.data)
            }catch(error){
        }
      }

    


      getOffices = async () =>{
         
          try {
             
              let res = axios.get(`${url}/api/auth/offices`)
              .then(res => {
                const offices = res.data;            
                this.setState({ offices })
              });                     
                console.log(res.data)
              }catch(error){
         }
      }

      getEnterprises = async () => {
        try {
          let res = axios.get(`${url}/api/auth/enterprises`)
          .then( res => {
            const enterprises = res.data
            this.setState({enterprises})
          })

          console.log(res.data);
        } catch (error) {
          console.log("ERROR: ", error);
        }
      }

      deleteOffice(){
        let data = {...this.state.office};

        try {
        let res = axios.delete(`${url}/api/auth/office/${data.id}`)
          .then(res => {
          this.getOffices()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteOfficeDialog: false,
            office: this.emptyOffice
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Office Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveOffice() {
        let state = { submitted: true };

        //if (this.state.office.office.trim()) {   
          const decision = this.isValid();

        if (decision) {    
          let data = {...this.state.office};
          console.log("DATA: ", data);


        if (this.state.office.id) {

            try {
              let res = axios.put(`${url}/api/auth/office/${data.id}`,data)
              .then(res =>{
              this.getOffices()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Office Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/office`,data)
            
             .then(res => {
                 this.getOffices()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Office Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          officeDialog: false,
          office: this.emptyOffice,
          selectedAgency: null,
          resetAgency:null,
          
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.getEnterprises()
        this.setState({
            office: this.emptyOffice,
            submitted: false,
            officeDialog: true,
            selectedAgency: null,
            resetAgency:"Agency",
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let office = {...this.state.office};
        office[`${name}`] = val;

        this.setState({ office});
      }

      onAgencyChange(e) {
        let office = {...this.state.office};
        office['fk_id_enterprise'] = e.value;
        this.setState({ selectedAgency: e.value, office });      
      }      

      hideDialog() {
          this.setState({
              submitted: false,
              officeDialog: false,
              fk_id_agencyMessage: '',
              officeMessage: '',
              contactMessage: '',
              emailMessage: '',
              phoneMessage: '',
              websiteMessage: ''

        })  
      } 


      editOffice(office) {
        this.getAgencies()
          this.setState({
              office: { ...office },
              selectedAgency: null,
              resetAgency:null,  
              officeDialog: true
          })

      }

      confirmDeleteOffice(office) {
        this.setState({
            office,
            deleteOfficeDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteOfficeDialog: true })
      }

      deleteSelectedOffices() {
        let offices = this.state.offices.filter(val => !this.state.selectedOffices.includes(val));
        let ids = this.state.offices.filter(val => this.state.selectedOffices.includes(val));
        console.log(ids)

        try {
        let res = axios.delete(`${url}/api/auth/office/${ids.id}`)
          .then(res => {
          this.getOffices()
        })
        if (!res.error) {
          this.setState({
            offices,
            deleteOfficesDialog: false,
            selectedOffices: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Offices Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteOfficeDialog() {
        this.setState({ deleteOfficeDialog: false })
      }
        
      hideDeleteOfficesDialog() {
        this.setState({ deleteOfficesDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
     

    
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editOffice(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteOffice(rowData)} />
            </>
        )
      }
      
      




      render(){
       const deleteOfficeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteOfficeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteOffice} />
        </>
       );

       const OfficeDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveOffice} />}
            </FormattedMessage>
        </>
       );

       const deleteOfficesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteOfficesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedOffices} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
            <FormattedMessage id="ADMIN_OFFICE.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_OFFICE.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedOffices || !this.state.selectedOffices.length} />}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_OFFICE">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div>

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>

          
          <div>
           {/*SHOW OFFICE */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.offices} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Campus found." loading={this.state.loading}
                        selection={this.state.selectedOffices} onSelectionChange={e => this.setState({ selectedOffices: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="enterprise_name" header={this.state.campusText.Agency}  filter filterPlaceholder={this.state.campusText.SearchByAgency} sortable></Column>
                        <Column field="office" header={this.state.campusText.Office}  filter filterPlaceholder={this.state.campusText.SearchByOffice} sortable></Column>
                        <Column field="contact" header={this.state.campusText.Contact}  filter filterPlaceholder={this.state.campusText.SearchByOffice} sortable></Column>
                        <Column field="email" header={this.state.campusText.Email}  filter filterPlaceholder={this.state.campusText.SearchByEmail} sortable></Column>
                        <Column field="phone" header={this.state.campusText.Phone}  filter filterPlaceholder={this.state.campusText.SearchByPhone} sortable></Column>
                        <Column field="website" header={this.state.campusText.Website}  filter filterPlaceholder={this.state.campusText.SearchByWebsite} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE CAMPUS */}
          <Dialog visible={this.state.deleteOfficeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteOfficeDialogFooter} onHide={this.hideDeleteOfficeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.office && <span> Are you sure you want to delete <b>{this.state.office.name}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteOfficesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteOfficesDialogFooter} onHide={this.hideDeleteOfficesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.office && <span>Are you sure you want to delete the selected offices?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE CAMPUS & CREATE CAMPUS*/}
          <Dialog visible={this.state.officeDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"310px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_OFFICE"/>} modal footer={OfficeDialogFooter} onHide={this.hideDialog}>
          <div className="form-group row">
              {/* Agency */}
              <br></br>
              
              <div className="col-lg-6">      
              <br></br>
      
              <span className="p-float-label">              
                <Dropdown
                  style={{ width: 250, height:33 }}
                  id="agency"
                  variant="outlined"
                  placeholder= { this.state.resetAgency || this.state.office.agency}
                  margin="dense"
                  value={this.state.selectedAgency || "agency"}   
                  options ={this.state.enterprises} 
                  onChange={this.onAgencyChange} 
                  optionLabel="enterprise_name" 
                  optionValue="id"
                  />
              <label htmlFor="agency">Agency</label>
              </span>
              <small id="agency-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxAgency()}</small>
              </div>
              {/* Office */}
              <div className="col-lg-6">
              <br></br>

              <span className="p-float-label">              
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="office"
                  variant="outlined"
                  margin="dense"
                  value={this.state.office.office}             
                  onChange={(e) => this.onInputChange(e, 'office')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.office.office })}
                  />
              <label htmlFor="office">Office</label>
              </span>
              <small id="office-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxOffice()}</small>
              </div>
              </div>
              {/* Contact*/}
              <br></br>
              <div className="form-group row">
              <div className="col-lg-6">
              <span className="p-float-label">               
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="contact"
                  variant="outlined"
                  margin="dense"
                  value={this.state.office.contact}             
                  onChange={(e) => this.onInputChange(e, 'contact')} 
                  keyfilter="alpha"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.office.contact  })}
                  />
              <label htmlFor="contact">Contact</label>
              </span>    
              <small id="contact-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxContact()}</small>
              </div>
              {/* Email*/}
              <div className="col-lg-6">
              <span className="p-float-label">               
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="email"
                  type="email"
                  variant="outlined"
                  margin="dense"
                  value={this.state.office.email}             
                  onChange={(e) => this.onInputChange(e, 'email')} 
                  keyfilter="email"
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.office.email  })}
                  />
              <label htmlFor="email">Email</label>
              </span>
              <small id="role_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEmail()}</small>
              </div>
              </div>
              {/* Phone*/}
              <br></br>
              <div className="form-group row">
              <div className="col-lg-6">
              <span className="p-float-label">               
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="phone"
                  type="phone"
                  variant="outlined"
                  margin="dense"
                  value={this.state.office.phone}             
                  onChange={(e) => this.onInputChange(e, 'phone')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.office.phone  })}
                  />
              <label htmlFor="phone">Phone</label>  
              </span>  
              <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxPhone()}</small>
              </div>
              {/* Website*/}
              <div className="col-lg-6">
              <span className="p-float-label">               
                  <InputText
                  style={{ width: 250, height:33 }}
                  id="website"
                  type="website"
                  variant="outlined"
                  margin="dense"
                  value={this.state.office.website}             
                  onChange={(e) => this.onInputChange(e, 'website')} 
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.office.website  })}
                  />
              <label htmlFor="website">Website</label>     
              </span> 
              <small id="website-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxWebsite(this.state)}</small>
              </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(OfficesTable);