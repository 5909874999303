import React from "react";
import {useSubheader} from "../../_metronic/_core";
import Evaluation from "../modulesAdmin/Evaluation"
import { useIntl } from "react-intl";

export const EvaluationPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();
    
    suhbeader.setTitle(int1.formatMessage({id: "LEARNING_GENERAL.ASSESSMENT_LIST"}));
    return (<Evaluation/>)
}