/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
//import { useIntl } from "react-intl";

export function AsideMenuListD({ layoutProps }) {
  //const int1 = useIntl();
  const location = useLocation();
  const { user } = useSelector(state => state.auth);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/google-material", true)}`}
          aria-haspopup='true'
          data-menu-toggle='hover'
        >

          <NavLink className='menu-link menu-toggle' to='/google-material'>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>

            <span className="menu-text text-uppercase">School Admin</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/*begin::1 Level*/}
              {
                user.tutorials === 0 ?
                  <li
                    className={`menu-item ${getMenuItemActive("/inicio", false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/inicio">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                      </span>
                      <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.HOME" /></span>
                    </NavLink>
                  </li>
                  :
                  <>
                    <li className="menu-section">
                      <h4 className="menu-text text-white-alpha-90 text-base">START</h4>
                      <i className="menu-icon flaticon-more-v2"></i>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive("/tutorial", false)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/tutorial">
                        <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                        </span>
                        <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.TUTORIALS" /></span>
                      </NavLink>
                    </li>
                  </>

              }
              <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base">ANALYTICS</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/analytics", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/analytics">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
                  </span>
                  <span className="menu-text">Dashboard</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              {/* TEAM */}
              {/* begin::section */}
              {/* <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base">TEAM</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li> */}
              {/* end:: section */}

              {/*begin::1 Level*/}
              {/* <li
                className={`menu-item ${getMenuItemActive("/get-marketer", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-marketers">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.MARKETER_LIST" /></span>
                </NavLink>
              </li> */}
              {/*end::1 Level*/}

              {/*begin::1 Level*/}
              {/* <li
                className={`menu-item ${getMenuItemActive("/get-group-agencies", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-group-agencies">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.AGENCY_GROUP_LIST" /></span>
                </NavLink>
              </li> */}
              {/*end::1 Level*/}

              {/* Cursos */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base">COURSES</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/get-courses", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to="/get-courses">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.COURSE_LIST" /></span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/get-evaluations", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-evaluations">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Double-check.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.ASSESSMENT_LIST" /></span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/get-course-assignment", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-course-assignment">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-to-right.svg")} />
                  </span>
                  <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.COURSE_ASSIGMENT" /></span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              {/* Configuración */}
              {/* begin::section */}
              {/* <li className="menu-section ">
            <h4 className="menu-text"><FormattedMessage id="LEARNING_GENERAL.SETTING"/></h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
              {/* end:: section */}

              {/*begin::1 Level*/}
              {/* <li
              className={`menu-item ${getMenuItemActive("/get-school", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/get-school">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.SCHOOL_DATA"/></span>
            </NavLink>
          </li> */}
              {/*end::1 Level*/}



              {/*
          begin::1 Level
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_SCHOOLS"})}</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_SCHOOLS"})}</span>
                </span>
                </li>
                Escuelas
                begin::2 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-schools", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-schools">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.SCHOOL"})}</span>
                  </NavLink>
                </li>
                end::2 Level
                Sedes
                begin::2 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-campuses", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="get-campuses">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.CAMPUS"})}</span>
                  </NavLink>
                </li>

                
                end::2 Level
                Programas
                begin::2 Level
                
                
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS"})}</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav">
                Programas
                begin::3 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-programs", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-programs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS.PROGRAMS"})}</span>
                  </NavLink>
                </li>
                end::3 Level
                 Cursos
                begin::3 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-courses", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-courses">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ADMIN_PROGRAMS.COURSES"})}</span>
                  </NavLink>
                </li>
                end::3 Level
                    </ul>
                  </div>
                </li>
                end::2 Level
                
              </ul>
            </div>
          </li>
          */}
              {/*end::1 Level*/}


              {/* 
          begin::1 Level
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_AGENCIES"})}</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ADMIN_AGENCIES"})}</span>
                </span>
                </li>
                Agencias
                begin::2 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-agencies", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-agencies">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.AGENCIES"})}</span>
                  </NavLink>
                </li>
                end::2 Level
                Oficinas
                begin::2 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-offices", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-offices">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.OFFICES"})}</span>
                  </NavLink>
                </li>
                end::2 Level
              </ul>
            </div>
          </li>
          */}
              {/*end::1 Level*/}
              {/*
          begin::1 Level
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ROLES_USERS"})}</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.LIST.ROLES_USERS"})}</span>
                </span>
                </li>
           Agencias
                begin::2 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-roles", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-roles">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.ROLES"})}</span>
                  </NavLink>
                </li>
                end::2 Level
                Oficinas
                begin::2 Level
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/get-users", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/get-users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">{int1.formatMessage({id: "MENU.DASHBOARD.USERS"})}</span>
                  </NavLink>
                </li>
                end::2 Level
                </ul>
            </div>
          </li>
          */}

              {/*end::1 Level*/}
            </ul>
          </div>

        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
