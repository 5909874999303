import React from "react";
import {useSubheader} from "../../_metronic/_core";
import ListGroupStudents from "../modulesWiyu/ListGroupStudents"
// import { ListGroupStudents2 } from "../modulesWiyu/ListGroupStudents2"
// import { useIntl } from "react-intl";

export const GroupStudentsPage = () => {
    const suhbeader = useSubheader();
    // const int1 = useIntl();

    suhbeader.setTitle("Lista de grupos de estudiantes");
    return (<ListGroupStudents/>)
}
