import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import ServerURL from "../../../../ServerURL";
import axios from "axios";
import moment from "moment";
import { useIntl } from "react-intl";
import { Card } from 'primereact/card'

import 'primeflex/primeflex.css'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// import { DataTable } from 'primereact/datatable'
// import {Column } from 'primereact/column'

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

const url = ServerURL()

function getText(result){
  let lang
  try {
    const { selectedLang } = JSON.parse(localStorage.i18nConfig);
    lang = selectedLang
  } catch (error) {
    lang = 'en'
  }
  switch (result) {
    case "WrongEmail":
      switch (lang) {
        case "en":
          return "Wrong email format"
        case "es":
          return "Formato de email incorrecto"
        case "de":
          return "Falsches E-Mail-Format"
        case "fr":
          return "Mauvais format d'e-mail"
        default:
          return "Wrong email format"
      }
    case "Min3Sy":
      switch (lang) {
        case "en":
          return "Minimum 3 symbols"
        case "es":
          return "Minimo 3 simbolos"
        case "de":
          return "Mindestens 3 Symbole"
        case "fr":
          return "Minimum 3 symboles"
        default:
          return "Minimum 3 symbols"
      }
    case "Max50Sy":
      switch (lang) {
        case "en":
          return "Maximum 50 symbols"
        case "es":
          return "Maximo 50 simbolos"
        case "de":
          return "Maximal 50 Symbole"
        case "fr":
          return "50 symboles maximum"
        default:
          return "Maximum 50 symbols"
      }
    default:
      return "Invalid"
  }
}

function Login(props) {
  const version = "v 1.9.0";

  const int1 = useIntl();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [exchange, setExchangeRates ] = useState([])
  const dateNow = moment().format("DD/MM/YYYY")
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(getText("WrongEmail"))
      .min(3, getText("Min3Sy"))
      .max(50, getText("Max50Sy"))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, getText("Min3Sy"))
      .max(50, getText("Max50Sy"))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
      axios.get(`${url}/api/public/getExchangeRatesPublic/95`).then((response) => {
        setExchangeRates(response.data)
      })
  }, [])

  // console.log("EXCHANGE: ", exchange);

  const renderTable = () => {
    return exchange.map(exchange => {
      return (

        // <div class="card">
        // <div class="flex flex-row flex-wrap card-container blue-container">
            <div class="flex align-items-center justify-content-center w-10rem h-10rem font-bold border-round m-2 text-2xl">{exchange.code} {exchange.symbol}{exchange.rate}</div>
            // <div class="flex align-items-center justify-content-center w-4rem h-4rem bg-blue-500 font-bold text-white border-round m-2">2</div>
            // <div class="flex align-items-center justify-content-center w-4rem h-4rem bg-blue-500 font-bold text-white border-round m-2">3</div>
        // </div>
      // </div>
        

        // <div className="grid">
          // <span style={{ width: 100}}>
            // <Card style={{ width: '5em', height: '7rem', fontSize: '20px'}}>{exchange.code} {exchange.symbol}{exchange.rate}</Card> */}
            
            // <table>
            //   <tr>
            //     <td>{exchange.code}{exchange.symbol}</td>
            //   </tr>
            //   <tr>
            //     <td>{exchange.rate}</td>
            //   </tr>
            // </table>
            // <span>{exchange.rate}</span> */}
          // </span> */}
        //   {/* <Card className="p-mr-2"> {exchange.symbol}{exchange.rate} </Card> */}
        //   {/* <div className="p-mr-2"> {exchange.rate} </div> */}
        // </div>
      )
    })
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      doSubmit(values, { setStatus, setSubmitting });
      // setTimeout(() => {
      //   login(values.email, values.password)
      //     .then(({ data }) => {
      //       console.log(data)
      //       // disableLoading();
      //       // props.login(accessToken);
      //     })
      //     .catch(() => {
      //       disableLoading();
      //       setSubmitting(false);
      //       setStatus(
      //         intl.formatMessage({
      //           id: "AUTH.VALIDATION.INVALID_LOGIN",
      //         })
      //       );
      //     });
      // }, 1000);
    },
  });

  const doSubmit = async (values, { setStatus, setSubmitting }) => {
    enableLoading();
    try {
      let res = (await login(values.email, values.password)).data;
      console.log(res);
      if (res.error) {
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          })
        );
      }
      else {
        props.login(res.data.token);
        console.log(props.login(res.data.token));
      }
    }
    catch (error) {
      setStatus(
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_LOGIN",
        })
      );
    }
    setSubmitting(false);
    disableLoading();
  }
  
  let DEVELOP_MODE = '';
  if (process.env.NODE_ENV === 'development'){
    DEVELOP_MODE = (
      <div style={{textAlign: "center"}}>
        <p><small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small></p>
        <p><small>ENTORNO: {process.env.REACT_APP_ENTORNO}<b> SERVER_URL: {process.env.REACT_APP_SERVER_URL}</b></small></p>
      </div>
    );      
  } else {
    // DEVELOP_MODE = (
    //   <div style={{textAlign: "center"}}>        
    //     <p><small>{version}</small></p>
    //   </div>
    // );      
  }

  return (
    <div>
      <div style={{ margin: 0}} id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Wissify {version}
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
        style={{ paddingLeft:400, paddingRight: 400}}
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
            <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
              <div className="alert-text ">
              {int1.formatMessage({id: "AUTH.LOGIN.SUBTITLE"})}
            </div>
            </div>
          )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={int1.formatMessage({id: "AUTH.INPUT.EMAIL"})}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={int1.formatMessage({id: "AUTH.INPUT.PASSWORD"})}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>{int1.formatMessage({id: "AUTH.LOGIN.BUTTON"})}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>


      <div className="text-center mt-10 mt-lg-10 mb-5 mb-lg-15">
        <h3 className="font-size-h1">
          Tipos de cambio {dateNow}
        </h3>
      </div>

      {/*end::Form*/}

      </div>

      <div className="card">
        {/* <h3></h3> */}
        <div class="flex flex-row flex-wrap card-container">
          {renderTable()}
        </div>
      </div>

      {DEVELOP_MODE}
    </div>

  );
}

export default injectIntl(connect(null, auth.actions)(Login));
