import React from "react";
import {useSubheader} from "../../_metronic/_core";

export const DataCampusesPage = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Datos de los campus");
    return (
        <div>
            <h1>Pagina para los datos de los campus</h1>
        </div>
    )
}