// import RoleTypes from './RoleTypes';
// import Roles from './Roles';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, useTheme, withStyles } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Dropdown } from 'primereact/dropdown';
import { NavLink } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { toAbsoluteUrl } from '../../../_metronic/_helpers';
// import SVG from "react-inlinesvg";

const useStyles = makeStyles((theme) => ({
  description: {
    display: "block",
    fontSize: "14px",
    marginBottom: "12px",
    paddingLeft: "12.5px",
    paddingRight: "12.5px",
  },
  box: {
    background: "#DCDCDC",
    height: "150px",
    border: "1px solid black",
    borderRadius: "7px",
  },
  headBox: {
    fontSize:"16px",
    background: "dimgrey",
    color:"white",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px",
    textTransform: "uppercase"
  },
  salaryBox: {
    display: "flex",
    background: "#DCDCDC",
    height: "100px",
    border: "1px solid black",
    borderRadius: "7px",
  },
  titleSalaryBox: {
    fontSize: "24px"
  },
  addSalary: {
    color: "cornflowerblue",
    cursor: "pointer"
  },
  salaryMoney: {
    display: "flex",
    color: "dodgerblue"
  },
  simbolMoney: {
    fontSize: "20px",
    marginTop: "16px"
  },
  money: {
    fontSize: "37px",
    display: "flex"
  },
  contentBox: {
    fontSize: "14px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px",
  },
  contentMoney: {
    fontSize: "38px",
    fontWeight: 500,
  },
  downBox: {
    position: "absolute",
    right: "24px",
    bottom: "8px",
    fontSize: "16px",
    textAlign: "right"
  },
  buttons: {
    background: "grey",
    color: "white",
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    background: "#DCDCDC",
    width: "100%",
    padding: "10px"
  },
  tabs: {
    display:"flex",
    textTransform:"uppercase"
  },
  tabEnabled: {
    background:"#DCDCDC",
    padding: "10px 14px",
    borderTopLeftRadius:"7px",
    borderTopRightRadius:"7px",
    fontWeight: 500
  },
  tabDesabled: {
    padding: "10px 14px",
    "&:hover": {
      fontWeight: 500,
      cursor: "pointer"
    }
  },
  filterSection: {
    display:"flex",
    background:"#DCDCDC",
    paddingTop:"16px",
    paddingLeft:"26px"
  }
}));

const StyledTableCell = withStyles(() => ({
  head: {
    textTransform: "uppercase"
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}


function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Cupcake', 305, 3.7, 305, 3.7),
  createData('Donut', 452, 25.0, 452, 25.0),
  createData('Eclair', 262, 16.0, 159, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 159, 6.0),
  createData('Gingerbread', 356, 16.0, 159, 6.0),
  createData('Honeycomb', 408, 3.2, 159, 6.0),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Jelly Bean', 375, 0.0, 159, 6.0),
  createData('KitKat', 518, 26.0, 159, 6.0),
  createData('Lollipop', 392, 0.2, 159, 6.0),
  createData('Marshmallow', 318, 0, 408, 3.2),
  createData('Nougat', 360, 19.0, 408, 3.2),
  createData('Oreo', 437, 18.0, 408, 3.2),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const citySelectItems = [
  {label: 'New York', value: 'NY'},
  {label: 'Rome', value: 'RM'},
  {label: 'London', value: 'LDN'},
  {label: 'Istanbul', value: 'IST'},
  {label: 'Paris', value: 'PRS'}
];


export default function MovementsAndInvoices() {
  const classes = useStyles();
  /*
  const { user } = useSelector(state => state.auth);
  */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [city, setCity] = useState(null);
  const [optionTab, setOptionTab] = useState(true);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{paddingLeft:"5em", paddingRight:"5em"}}>
      <div className="container">
        <div className="row">
          <div className={classes.description}>
            <span>Desde aquí podras pagar compras y renovaciones, informar pagos, gestionar el saldo de tu cuenta, descargar facturas, revisar tus movimientos y mucho más...</span>
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-12 col-sm-6">
            <div className={classes.box}>
              <div className={classes.headBox} style={{marginBottom: "10px"}}>
              Pagos de compras y renovaciones
              </div>
              <div className={classes.contentBox}>
              Realiza el pago de tus servicioes con multiples medios de pagos
              </div>
              <div className={classes.downBox}>
                <NavLink to="/movements-and-invoices/report-payment">
                  <button type="button" className="btn" style={{marginRight:"4px", background: "grey", color: "white"}}>Informar un pago</button>
                </NavLink>
                <NavLink to="/movements-and-invoices/to-pay">
                  <button type="button" className="btn" style={{background: "grey", color: "white"}}>Ir a pagar</button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className={classes.box}>
              <div className={classes.headBox} style={{marginBottom: "10px"}}>
              Cuentas fiscales
              </div>
              <div className={classes.contentBox}>
              Puedes además modificar tus cuentas fiscales. <strong style={{color: "dodgerblue", cursor: "pointer"}}>Gestionar</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-12">
            <div className={classes.salaryBox}>
              <div className="col-auto mr-auto align-self-center">
                <div className={classes.titleSalaryBox}>
                  Saldo en tu cuenta
                </div>
                <div className={classes.addSalary}>
                  Agregar fondos
                </div>
              </div>
              <div className="col-auto align-self-center" style={{display:"flex", color:"dodgerblue"}}>
                <div className={classes.simbolMoney}>
                  $
                </div>
                <div className={classes.money}>
                  100.00
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{display:"block", paddingLeft: "12.5px", paddingRight: "12.5px"}}>
          {
            optionTab ?
            <div className={classes.tabs}>
              <div className={classes.tabEnabled}>
                Movimientos
              </div>
              <div className={classes.tabDesabled} onClick={e => setOptionTab(false)}>
                Facturas
              </div>
            </div>
            :
            <div className={classes.tabs}>
              <div className={classes.tabDesabled} onClick={e => setOptionTab(true)}>
                Movimientos
              </div>
              <div className={classes.tabEnabled}>
                Facturas
              </div>
            </div>
          }
          {
            optionTab ?
              <div>
                <div className={classes.filterSection}>
                  <div style={{alignSelf: "center", marginRight:"6px"}}>
                    Filtar por:
                  </div>
                  <div>
                    <Dropdown value={city} options={citySelectItems} onChange={(e) => setCity(e.value)} placeholder="Selecciona un filtro"/>
                  </div>
                </div>
                <div className={classes.table}>
                  <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Fecha</StyledTableCell>
                        <StyledTableCell align="left">Tipo</StyledTableCell>
                        <StyledTableCell align="left">Descropcion</StyledTableCell>
                        <StyledTableCell align="right">Movimientos</StyledTableCell>
                        <StyledTableCell align="right">Saldo</StyledTableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : rows
                        ).map((row) => (
                          <TableRow key={row.name}>
                            <TableCell style={{ width: 160, borderBottom: "1px solid #9E9E9E" }} component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell style={{ width: 160, borderBottom: "1px solid #9E9E9E" }} align="left">
                              {row.calories}
                            </TableCell>
                            <TableCell style={{ borderBottom: "1px solid #9E9E9E" }} align="left">
                              {row.fat}
                            </TableCell>
                            <TableCell style={{ width: 160, borderBottom: "1px solid #9E9E9E" }} align="right">
                              {row.calories}
                            </TableCell>
                            <TableCell style={{ width: 160, borderBottom: "1px solid #9E9E9E" }} align="right">
                              {row.fat}
                            </TableCell>
                          </TableRow>
                        ))}

                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            :
            <div>
              <div className={classes.table}>
                <TableContainer>
                  <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Fecha</StyledTableCell>
                      <StyledTableCell align="left">Descropcion</StyledTableCell>
                      <StyledTableCell align="center">Servicio</StyledTableCell>
                      <StyledTableCell align="center">Descarga</StyledTableCell>
                      <StyledTableCell align="right">Importe</StyledTableCell>
                    </TableRow>
                  </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                      ).map((row) => (
                        <TableRow key={row.name}>
                          <TableCell style={{ width: 160, borderBottom: "1px solid #9E9E9E" }} component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell style={{ width: 160, borderBottom: "1px solid #9E9E9E" }} align="left">
                            {row.calories}
                          </TableCell>
                          <TableCell style={{ borderBottom: "1px solid #9E9E9E" }} align="center">
                            {row.fat}
                          </TableCell>
                          <TableCell style={{ width: 160, borderBottom: "1px solid #9E9E9E" }} align="center">
                            {row.calories}
                          </TableCell>
                          <TableCell style={{ width: 160, borderBottom: "1px solid #9E9E9E" }} align="right">
                            {row.fat}
                          </TableCell>
                        </TableRow>
                      ))}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}