import React, { Component } from 'react';

import axios from 'axios'
import { connect } from 'react-redux';
//import { Column } from 'primereact/column';
//import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import ServerURL from '../../../ServerURL'
//import { Toast } from 'primereact/toast';
import { Chart } from 'primereact/chart';
//import { Calendar } from 'primereact/calendar';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { getTextCampusTranslate } from '../../../_metronic/i18n/TextTranslate'






const url = ServerURL();






class DashboardGraphs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campusText: getTextCampusTranslate(),
            graphOne:null,
            graphTwo:null,
            //graphThree:null,
            graphFour:null,
            textPeriod:null,
            isSelectedFirstYear:0,
            selectedFirstYear:'',
            isSelectedSecondYear:0,
            selectedSecondYear:'',
            selectedPeriod:'',

            currentMonth:null,
            

        }
        this.period = [
            { period: this.state.campusText.FirstTerm, id:1 },
            { period: this.state.campusText.SecondTerm, id:2 },
          ];
        this.years = [
            { year:2020 },
            { year:2021 },
            { year:2022 },
          ];
        
          this.onPeriodChange = this.onPeriodChange.bind(this);
          this.onFirstYearChange = this.onFirstYearChange.bind(this);
          this.onSecondYearChange = this.onSecondYearChange.bind(this);


    }

    

    componentDidMount() {
        this.getInitialData()
      }

  

      getInitialData= async () =>{
      
        let requestOne = axios.get(`${url}/api/auth/getAnalyticAgency/${1}`)
        let requestTwo = axios.get(`${url}/api/auth/getAnalyticAgency/${2}`)
        //let requestThree = axios.get(`${url}/api/auth/getAnalyticSchool/${3}`)
        let requestFour = axios.get(`${url}/api/auth/getAnalyticAgency/${4}`)

        
  
        axios
        .all([requestOne,requestTwo,requestFour])
        .then(
              axios.spread((...responses) => {  
               const graphOne = responses[0].data;
                const graphTwo= responses[1].data;
                //const graphThree = responses[2].data;   
                const graphFour = responses[2].data;   
                let currentMonth =  this.currentMonthOneDigit()
                console.log("SI OBTIENE MES ACTUAL?:",currentMonth)
                let selectedFirstYear = this.currentYear()
                let selectedSecondYear = selectedFirstYear - 1
                let selectedPeriod
                let textPeriod = this.state.textPeriod
                

                if(currentMonth===1 ||currentMonth===2 || currentMonth===3 || currentMonth===4 ||currentMonth===5 || currentMonth===6){
                    selectedPeriod = 1
                    textPeriod = [this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_JAN"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_FEB"}) , this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_MAR"}), this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_APR"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_MAY"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_JUN"})]
                }else if(currentMonth===7 ||currentMonth===8 || currentMonth===9 || currentMonth===10 ||currentMonth===11 || currentMonth===12){
                    selectedPeriod = 2
                    textPeriod = [this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_JUL"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_AUG"}) , this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_SEP"}), this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_OCT"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_NOV"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_DEC"})]
                }

                console.log("VARIALES INI:",currentMonth, selectedFirstYear,selectedSecondYear,selectedPeriod,textPeriod)
 


        
                      this.setState({graphOne,graphTwo,graphFour,currentMonth,selectedFirstYear,selectedPeriod,selectedSecondYear,textPeriod})
                    })
                    
                  )
                  .catch(errors => {
                    // react on errors.
                     console.error(errors);
                  });  
               } 

               getCourses(){
                let firstYear = this.state.selectedFirstYear
                let secondYear = this.state.selectedSecondYear
                let request={
                    graph:1,
                    firstYear,
                    secondYear
                }
                try {
                    axios.post(`${url}/api/auth/getAnalyticAgency/`,request)
                   .then(res => {
                     const graphOne = res.data;
                     
                     //const graphThree = res.data;
                    
                     this.setState({graphOne/*,graphThree*/})
                   });     
                }catch(error){
                }
               }

               onFirstYearChange(e) {   
                let isSelectedFirstYear = this.state.isSelectedFirstYear
                let selectedFirstYear = this.state.selectedFirstYear

                if(e.value!==null){
                    isSelectedFirstYear=1
                    selectedFirstYear=e.value
                }

                this.setState({isSelectedFirstYear,selectedFirstYear})
              }

              onSecondYearChange(e) {   
                let isSelectedSecondYear = this.state.isSelectedSecondYear
                let selectedSecondYear = this.state.selectedSecondYear

                if(e.value!==null){
                    isSelectedSecondYear =1
                    selectedSecondYear=e.value
                }

                this.setState({isSelectedSecondYear,selectedSecondYear})
              }
    
              
               onPeriodChange(e) {   
                let textPeriod = this.state.textPeriod
                let selectedPeriod = this.state.selectedPeriod
                if(e.value!==null){
                    selectedPeriod = e.value
                    if(selectedPeriod===1){
                        textPeriod = [this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_JAN"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_FEB"}) , this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_MAR"}), this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_APR"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_MAY"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_JUN"})]
                    }else if(selectedPeriod===2){
                        textPeriod = [this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_JUL"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_AUG"}) , this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_SEP"}), this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_OCT"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_NOV"}),this.props.intl.formatMessage({id:"GENERAL.MONTH.SHORT_DEC"})]
                    }
                }
                
                this.setState({selectedPeriod, textPeriod})
                this.getCourses()
              }


            getIndexPeriodOne(e){
                let date = new Date(e)
                let month = date.getMonth()+1;         
                if (month < 10) {
                  month = '0' + month;
                }     
                month=month -1
                return month
            }

            getIndexPeriodTwo(e){
                let date = new Date(e)
                let month = date.getMonth()+1;         
                if (month < 10) {
                  month = '0' + month;
                }     
                month=month -7
                return month
            }

            getMonth(e){
                let date = new Date(e)
                let month = date.getMonth()+1;         
                if (month < 10) {
                  month = '0' + month;
                }       
                return month
            }
            getYear(e){
                let date = new Date(e)
                let year = date.getFullYear();     
                return year
            }
          


            currentYear(){
                let date = new Date()
                let year = date.getFullYear();          
                return year
            }
            currentMonth(){
                let date = new Date()
                let month = date.getMonth()+1;
              
                if (month < 10) {
                  month = '0' + month;
                }
                return month
            }

            currentMonthOneDigit(){
                let date = new Date()
                let month = date.getMonth()+1;
                return month
              }



          renderSecondYear=()=>{  
            let selectedFirstYear=this.state.selectedFirstYear
            let showInfo=[]

            if(selectedFirstYear!==null){
            showInfo.push(<>
                <div style={{ marginRight: 10}}>
                <span className="p-float-label">   
                    <Dropdown 
                    style={{ width: 190, height:33 }}
                    value={this.state.selectedSecondYear || "year"} 
                    options={this.years} 
                    onChange={this.onSecondYearChange} 
                    optionLabel="year" 
                    optionValue="year"
                    />
                <label htmlFor="year">{this.state.campusText.SecondYear}</label>
                </span>
                </div>
              </>)
            }

            return showInfo
          }

          renderPeriod=()=>{  
            let selectedSecondYear=this.state.selectedSecondYear
            let showInfo=[]

            if(selectedSecondYear!==null){
            showInfo.push(<>
                <div style={{ marginRight: 10}}>
                <span className="p-float-label">   
                <Dropdown
                        style={{ width: 190, height:33 }}
                        id="period"
                        variant="outlined"
                        margin="dense"
                        value={this.state.selectedPeriod || "period"} 
                        options={this.period} 
                        onChange={this.onPeriodChange}  
                        optionLabel="period" 
                        optionValue="id"
                />
                <label htmlFor="year">{this.state.campusText.Period}</label>
                </span>
                </div>
              </>)
            }
            
            return showInfo
          }
    
    render (){
    let textPeriod = this.state.textPeriod
    let selectedPeriod = this.state.selectedPeriod
    let selectedFirstYear= this.state.selectedFirstYear
    let selectedSecondYear= this.state.selectedSecondYear


    

    let graphOne = {...this.state.graphOne}
    console.log("COURSES:", graphOne)
    let graphTwo = {...this.state.graphTwo}
    //console.log("GRAPHTWO:", graphTwo.coursesPendingTotal,graphTwo.coursesInProgressTotal,graphTwo.coursesCompletedTotal)
    //let graphThree = {...this.state.graphThree}
    //console.log("COURSES:", graphThree)
    let graphFour = {...this.state.graphFour}
    console.log("COURSES:", graphFour)

    
   let firstYearPending = [0,0,0,0,0,0]
   let firstYearTaken = [0,0,0,0,0,0]
   let secondYearPending = [0,0,0,0,0,0]
   let secondYearTaken = [0,0,0,0,0,0]
   
    // GRAPH ONE SPACE    
        for(let i in graphOne.ArrayUsersDetailed){
            if(selectedFirstYear === this.getYear(graphOne.ArrayUsersDetailed[i].updated_at)){
                        if(selectedPeriod===1){

                            for(let j=0; j<6; j++){ 
                                if(this.getIndexPeriodOne(graphOne.ArrayUsersDetailed[i].updated_at)===j){
                                    if(graphOne.ArrayUsersDetailed[i].status === 2 || graphOne.ArrayUsersDetailed[i].status === 3){
                                        //console.log("ARRAY TAKEN, YEAR ONE, PERIOD 1:", graphOne.ArrayUsersDetailed[i])
                                        firstYearTaken[j]=  firstYearTaken[j] + 1
                                    }else if(graphOne.ArrayUsersDetailed[i].status === 1){
                                        //console.log("ARRAY PENDING, YER ONE, PERIOD 1:", graphOne.ArrayUsersDetailed[i])
                                        firstYearPending[j]= firstYearPending[j] + 1
                                    }
                                }
                            
                            }
                        }else if(selectedPeriod===2){
                            for(let j=0; j<6; j++){ 
                                if(this.getIndexPeriodTwo(graphOne.ArrayUsersDetailed[i].updated_at)===j){
                                    if(graphOne.ArrayUsersDetailed[i].status === 2 || graphOne.ArrayUsersDetailed[i].status === 3){
                                        //console.log("ARRAY TAKEN, YEAR ONE, PERIOD 1:", graphOne.ArrayUsersDetailed[i])
                                        firstYearTaken[j] =  firstYearTaken[j] + 1
                                    }else if(graphOne.ArrayUsersDetailed[i].status === 1){
                                        //console.log("ARRAY PENDING, YER ONE, PERIOD 1:", graphOne.ArrayUsersDetailed[i])
                                        firstYearPending[j]= firstYearPending[j] + 1
                                    }
                                }
                            
                            }
                        }
                }else if(selectedSecondYear === this.getYear(graphOne.ArrayUsersDetailed[i].updated_at)){

                    if(selectedPeriod===1){
                        for(let j=0; j<6; j++){ 
                            if(this.getIndexPeriodOne(graphOne.ArrayUsersDetailed[i].updated_at)===j){
                                if(graphOne.ArrayUsersDetailed[i].status === 2 || graphOne.ArrayUsersDetailed[i].status === 3){
                                    secondYearTaken[j] =  secondYearTaken[j] + 1
                                }else if(graphOne.ArrayUsersDetailed[i].status === 1){
                                    secondYearPending[j]=  secondYearPending[j] + 1
                                }
                            }
                        
                        }
                    }else if(selectedPeriod===2){
                        for(let j=0; j<6; j++){ 
                            if(this.getIndexPeriodTwo(graphOne.ArrayUsersDetailed[i].updated_at)===j){
                                if(graphOne.ArrayUsersDetailed[i].status === 2 || graphOne.ArrayUsersDetailed[i].status === 3){
                                    secondYearTaken[j] =  secondYearTaken[j] + 1
                                }else if(graphOne.ArrayUsersDetailed[i].status === 1){
                                    secondYearPending[j]=  secondYearPending[j] + 1
                                }
                            }
                        
                        }
                    }
                }
        }


   
   
    // GRAPH THREE SPACE    

   let firstYearProm= [0,0,0,0,0,0]
   let secondYearProm= [0,0,0,0,0,0]


                for(let j=0; j<6; j++){ 
                        let numPromOneY1=0
                        let numPromTwoY1=0

                    for(let i in graphOne.ArrayUsersDetailed){

                            if(selectedFirstYear === this.getYear(graphOne.ArrayUsersDetailed[i].updated_at)){


                            
                                if(selectedPeriod===1){
                                
                                        if(this.getIndexPeriodOne(graphOne.ArrayUsersDetailed[i].updated_at)===j){
                                            if(graphOne.ArrayUsersDetailed[i].status === 2 || graphOne.ArrayUsersDetailed[i].status === 3){
                                            // console.log("ARRAY TAKEN, YEAR ONE, PERIOD 1:", graphOne.ArrayUsersDetailed[i])
                                            firstYearProm[j]=  firstYearProm[j] + graphOne.ArrayUsersDetailed[i].promedios[0].promedio
                                            numPromOneY1++
                                            }
                                        }
                                }else if(selectedPeriod===2){
                                        if(this.getIndexPeriodTwo(graphOne.ArrayUsersDetailed[i].updated_at)===j){
                                            if(graphOne.ArrayUsersDetailed[i].status === 2 || graphOne.ArrayUsersDetailed[i].status === 3){
                                            // console.log("ARRAY TAKEN, YEAR ONE, PERIOD 1:", graphOne.ArrayUsersDetailed[i])
                                            firstYearProm[j] = firstYearProm[j] + graphOne.ArrayUsersDetailed[i].promedios[0].promedio
                                            numPromTwoY1++
                                            console.log("PROMEDIOS:",graphOne.ArrayUsersDetailed[i].promedios[0].promedio)
                                            console.log("#:",numPromTwoY1)

                                            }
                                        }
                                }
                            }
                    }
                    if(selectedPeriod===1){
                        firstYearProm[j] = firstYearProm[j]/numPromOneY1
                        
                    }else if(selectedPeriod===2){
                        console.log("TOTAL PROMS:",firstYearProm[j])
                        console.log("TOTAL #:",numPromTwoY1)

                        firstYearProm[j] = firstYearProm[j]/numPromTwoY1
                    }

                    let numPromOneY2=0
                    let numPromTwoY2=0
                    for(let i in graphOne.ArrayUsersDetailed){

                        if(selectedSecondYear === this.getYear(graphOne.ArrayUsersDetailed[i].updated_at)){


                        
                            if(selectedPeriod===1){
                            
                                    if(this.getIndexPeriodOne(graphOne.ArrayUsersDetailed[i].updated_at)===j){
                                        if(graphOne.ArrayUsersDetailed[i].status === 2 || graphOne.ArrayUsersDetailed[i].status === 3){
                                        // console.log("ARRAY TAKEN, YEAR ONE, PERIOD 1:", graphOne.ArrayUsersDetailed[i])
                                        secondYearProm[j]=  secondYearProm[j] + graphOne.ArrayUsersDetailed[i].promedios[0].promedio
                                        numPromOneY2++
                                        }
                                    }
                            }else if(selectedPeriod===2){
                                    if(this.getIndexPeriodTwo(graphOne.ArrayUsersDetailed[i].updated_at)===j){
                                        if(graphOne.ArrayUsersDetailed[i].status === 2 || graphOne.ArrayUsersDetailed[i].status === 3){
                                        // console.log("ARRAY TAKEN, YEAR ONE, PERIOD 1:", graphOne.ArrayUsersDetailed[i])
                                        secondYearProm[j] = secondYearProm[j] + graphOne.ArrayUsersDetailed[i].promedios[0].promedio
                                        numPromTwoY2++
                                        }
                                    }
                            }
                        }
                    }  
                    if(selectedPeriod===1){
                        secondYearProm[j] = secondYearProm[j]/numPromOneY2
                    }else if(selectedPeriod===2){
                        secondYearProm[j] = secondYearProm[j]/numPromTwoY2
                    }                    
                }



        // GRAPH FOUR SPACE
        
        let PendingG4 = [0,0,0,0,0,0]
        let ProcessG4 = [0,0,0,0,0,0]
        let CompletedG4 = [0,0,0,0,0,0]
    


        for(let i in graphFour.ArrayUsersDetailed){
                        if(selectedPeriod===1){

                            for(let j=0; j<6; j++){ 
                                if(this.getIndexPeriodOne(graphFour.ArrayUsersDetailed[i].updated_at)===j){
                                    if(graphFour.ArrayUsersDetailed[i].status === 3){
                                        CompletedG4[j]= CompletedG4[j] + 1
                                    }else if(graphFour.ArrayUsersDetailed[i].status === 2){
                                        ProcessG4[j]= ProcessG4[j] + 1
                                    }else if(graphFour.ArrayUsersDetailed[i].status === 1){
                                        PendingG4[j]= PendingG4[j] + 1
                                    }
                                }
                            
                            }
                        }else if(selectedPeriod===2){
                            for(let j=0; j<6; j++){ 
                                
                                if(this.getIndexPeriodTwo(graphFour.ArrayUsersDetailed[i].updated_at)===j){
                                    if(graphFour.ArrayUsersDetailed[i].status === 3){
                                        CompletedG4[j] = CompletedG4[j] + 1
                                    }else if(graphFour.ArrayUsersDetailed[i].status === 2){
                                        ProcessG4[j] = ProcessG4[j] + 1
                                    }else if(graphFour.ArrayUsersDetailed[i].status === 1){
                                        PendingG4[j]= PendingG4[j] + 1
                                    }
                                }
                            
                            }
                        }
        }


    const PiechartData =  {
        labels:[this.props.intl.formatMessage({id:"LEARNING_ANALYTICS.PENDING"}),this.props.intl.formatMessage({id:"LEARNING_ANALYTICS.IN_PROGRESS"}),this.props.intl.formatMessage({id:"LEARNING_ANALYTICS.COMPLETE"})],
        datasets: [
            {
                data: [graphTwo.coursesPendingTotal,graphTwo.coursesInProgressTotal,graphTwo.coursesCompletedTotal],
                backgroundColor: [
                    "#FFA726",
                    "#42A5F5",
                    "#66BB6A"   
                ],
                hoverBackgroundColor: [
                    "#FFB74D",
                    "#64B5F6",
                    "#81C784"
                ]
            }
        ]
    };

    const PielightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }
    };

    const basicData = {
        
        labels: textPeriod,
        datasets: [{
            type: 'bar',
            label:`${selectedFirstYear} ${this.state.campusText.Pending}`,
            backgroundColor: '#EC407A',
            data: firstYearPending
        }, 
        {
            type: 'bar',
            label: `${selectedFirstYear} ${this.state.campusText.Taken}`,
            backgroundColor: '#AB47BC',
            data: firstYearTaken
        },
        {
            type: 'bar',
            label: `${selectedSecondYear} ${this.state.campusText.Pending}`,
            backgroundColor: '#42A5F5',
            data: secondYearPending
        },
        {
            type: 'bar',
            label: `${selectedSecondYear} ${this.state.campusText.Taken}`,
            backgroundColor: '#7E57C2',
            data:secondYearTaken
        }]
    };

    const LinearbasicData = {
        labels: textPeriod,
        datasets: [
            {
                label: selectedFirstYear,
                data: firstYearProm,
                fill: false,
                borderColor: '#42A5F5'
            },
            {
                label: selectedSecondYear,
                data: secondYearProm,
                fill: false,
                borderColor: '#565656'
            }
        ]
    };    

    const stackedData = {
        
        labels: textPeriod,
        datasets: [{
            type: 'bar',
            label: this.props.intl.formatMessage({id:"LEARNING_ANALYTICS.PENDING"}),
            backgroundColor: '#FFA726',
            data: PendingG4
        },
        {
            type: 'bar',
            label: this.props.intl.formatMessage({id:"LEARNING_ANALYTICS.IN_PROGRESS"}),
            backgroundColor: '#42A5F5',
            data: ProcessG4
        },
        {
            type: 'bar',
            label: this.props.intl.formatMessage({id:"LEARNING_ANALYTICS.COMPLETE"}),
            backgroundColor: '#66BB6A',
            data: CompletedG4
        }]
    };

    const getLightTheme = () => {
        let basicOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }]
            },
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            }
        };

        let LinearbasicOptions = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                }],
                yAxes: [{
                    ticks: { fontColor: '#495057', beginAtZero: true }
                }]
            }
        };        

        let stackedOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }]
            },
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            }
        };       

        return {
            basicOptions,
            stackedOptions,
            LinearbasicOptions
        }
    }

    const { basicOptions, stackedOptions,LinearbasicOptions } = getLightTheme();

    let firstYear = this.state.selectedFirstYear
    let secondYear = this.state.selectedSecondYear

        return (
            
            <div className="container">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
                <div style={{ marginRight: 10}}>
                <span className="p-float-label">   
                    <Dropdown 
                    style={{ width: 190, height:33 }}
                    value={this.state.selectedFirstYear || "year"} 
                    options={this.years} 
                    onChange={this.onFirstYearChange} 
                    optionLabel="year" 
                    optionValue="year"
                    />
                <label htmlFor="year">{this.state.campusText.FirstYear}</label>
                </span>
                </div> 
                {this.renderSecondYear()}
                {this.renderPeriod()}
                </div>
                <div className="row">
                    <div className="card col m-4">
                    <h3>{<FormattedMessage id="LEARNING_ANALYTICS.COURSES_TAKEN"/>} - {firstYear} vs {secondYear}</h3>
                    <Chart type="bar" data={basicData} options={basicOptions} />
                    </div>
                    <div className="card col m-4">
                    <h3>{<FormattedMessage id="LEARNING_ANALYTICS.TOTAL_COURSES"/>} - { /*<FormattedMessage id="GENERAL.MONTH.MAR"/>*/} {this.currentMonth()}/{this.currentYear()}</h3>
                    <Chart type="pie" data={PiechartData} options={PielightOptions} />
                    </div>
                </div>
                <div className="row" >
                    <div className="card col m-4">
                    <h3>{<FormattedMessage id="LEARNING_ANALYTICS.AVERAGE"/>} - {firstYear} vs {secondYear} </h3>
                    <Chart type="line" data={LinearbasicData} options={LinearbasicOptions} />
                    </div>
                    <div className="card col m-4">
                    <h3>{<FormattedMessage id="LEARNING_ANALYTICS.TOTAL_COURSES_HISTORICALLY"/>} - {this.currentYear()} </h3>    
                    <Chart type="bar" data={stackedData} options={stackedOptions} />
                    </div>
                </div>                  
            </div>
        )
     }
    }

    const mapStateToProps = state => ({
        user: state.auth.user
      });
      
      export default  injectIntl( 
          connect(mapStateToProps, auth.actions)(DashboardGraphs)
        )