import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";


//Traduccion
import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

//Componentes
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { DataTable  } from "primereact/datatable";

//Conexion con API
import axios from 'axios'
import ServerURL from '../../../ServerURL'

// Redux
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux"

//Funciones extras
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

//PrimeReact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';

const url = ServerURL();

class ParentsInfo extends Component {

    emptyParent = {
        fullname_parents: '',
        email_parents: '',
        phone_parents: '',
        empresa: '',
        parentezco: '',
    }

    constructor(props) {
        super(props)
        this.state ={
            name: "",
            first_name: this.props.dataStudent.first_name,
            last_name: this.props.dataStudent.last_name,
            email: this.props.dataStudent.email,

            parentEdit: this.emptyParent,

            parents: null,
            parentsEdit: null,
            ids: null,
            globalFilter: null,

            selectedParents: null,
            deleteParentsDialog: false,
            deleteSelectedDialog: false,
            deleteParentDialog: false,

            campusText: getTextCampusTranslate(),
            paginatorText: getTextPaginatorTranslate(),
            textTranslate: getGeneralTextTranslate(),


            redirect: false,
            submitted: false,
            section_1: true,
            dialog: null,
            dialogParent: null,

            selectedParentesco: null,
            resetParentesco: null,

            idParentSelect: null,
        }

        this.parentescos = [
            {parentesco: "Padre" },
            {parentesco: "Madre"},
            {parentesco: "Tutor legal"}

        ]

        this.updateStudent = this.updateStudent.bind(this)
        this.updateParent = this.updateParent.bind(this)
        this.changePage = this.changePage.bind(this)
        this.onInputChange = this.onInputChange.bind(this)

        this.onOpen = this.onOpen.bind(this)
        this.onEdit = this.onEdit.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onHideParent = this.onHideParent.bind(this)

        // this.onParentescoChange = this.onParentescoChange.bind(this)

        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.deleteParent = this.deleteParent.bind(this)
        // this.deleteSelected = this.deleteSelected.bind(this);
        this.editParent = this.editParent.bind(this)
        this.confirmDeleteParent = this.confirmDeleteParent.bind(this);
        // this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        // this.hideDeleteSelectedDialog = this.hideDeleteSelectedDialog.bind(this);
    }

    componentDidMount() {
        this.getParents()
    }

    getParents = async () => {
        let id_student = this.props.dataStudent.id
        console.log(id_student);
        try {
            let res = await axios.get(`${url}/api/public/studentparents/${id_student}`)
            .then(res => {
                const parents = res.data;
                this.setState({ parents })
                console.log(parents);
            })
            console.log(res);
        } catch (error) {
            
        }
    } 
    

    changePage(){
        this.setState({redirect: true})
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let parentEdit = {...this.state.parentEdit}
        parentEdit[`${name}`] = val
        this.setState({ parentEdit })
    }

    // onParentescoChange(e) {
    //     let parent = {...this.state.parentezco}
    //     parent['parentezco'] = e.value
    //     this.setState({ selectedParentesco: e.value, parent})
    // }

    onOpen() {
        this.setState({
            dialog: true,
            name: '',
            last_name_parents: '',
            email_parents: '',
            telefono: '',
            empresa: '',
            parentezco: ''
        })
    }

    onEdit() {
        this.setState({
            submitted: false,
            parentEdit: this.emptyParent,
            dialog: true
        })
        // console.log();
    }

    onHide(){
        this.setState({
            submitted: false,
            dialog: false,
        })
    }

    editParent(parentEdit) {
        this.setState({
            parentEdit: {...parentEdit},
            dialogParent: true,
        })
        console.log(parentEdit);
    }

    onHideParent() {
        this.setState({
            submitted: false,
            dialogParent: false
        })
    }


    async updateStudent() {
        let state ={ submitted: true }
        let data = {
            id_student: this.props.dataStudent.id,
            // name: this.state.first_name_parents,
            // last_name_parents: this.state.last_name_parents,
            fullname_parents: this.state.fullname_parents,
            email_parents: this.state.email_parents,
            phone_parents: this.state.phone_parents,
            empresa: this.state.empresa,
            parentezco: this.state.parentezco
        }
        console.log("Data :", data);

        try{
            await axios.post(`${url}/api/public/students-parents`, data)
            .then(res => {
                const dataStudent = {
                    ...this.props.dataStudent,
                    // assignment_student: data.assignment_student,
                    // first_name_parents: data.name,
                    // last_name_parents: data.last_name_parents,
                    full_name_parents: data.fullname_parents,
                    email_parents: data.email_parents,
                    phone_parents: data.phone_parents,
                    empresa: data.empresa,
                    parentezco: data.parentezco
                };
                this.props.updatedStudent(dataStudent)
                console.log(res);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Student updated', life: 3000})
            })
        } catch (error){
            console.log(error);
        }
        console.log("State: ", state);
        console.log("Data: ", data);
        this.getParents()
        this.setState({
            // ...state, 
            data,
            // first_name_parents: '',
            // last_name_parents: '',
            fullname_parents: '',
            email_parents: '',
            phone_parents: '',
            // empresa: '',
            // parentezco: '',
            dialog: false
        })
    }
    
    async updateParent(parents) {
        let state = { submitted: true }
        let data ={...this.state.parentEdit}

        console.log("Data sent :", data);

        try {
            await axios.put(`${url}/api/public/students-parents/${data.id_parents}`, data)
            .then(res => {
                this.getParents()
                // this.props.updateStudent(dataStudent)
                console.log(res);
                if(!res.error)
                {
                    this.toast.show({ severity: 'success', summary: 'Successful', detail: "Parents info updated", life: 3000})
                }
            })
        } catch (error) {
            
        }

        state = {
            ...state,
            data,
            dialogParent: false,
            parentEdit: this.emptyParent
        }

        this.setState(state)
    }

    // deleteParent(){
    //     let data = {...this.state.parentEdit}

    //     console.log("Data sent: ", data);

    //     try{
    //         let res = axios.delete(`${url}/api/public/students-parents/${data.id_parents}`)
    //         .then(res => {
    //             this.getParents()
    //             console.log(res);
    //         })
    //         if(!res.error){
    //             this.setState({
    //                 data,
    //                 deleteParentDialog: false,
    //                 parentEdit: this.emptyParent
    //             })
    //         this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Parent deleted', life: 3000});
    //         }
    //     } catch (error) {

    //     }
    // }

    deleteParent = async () => {
        // let { parents, idParentSelect } =  this.state;
        let data = {...this.state.parentEdit}
        let idParentSelect = data.id_parents
        try {
            await axios.delete(`${url}/api/public/students-parents/${idParentSelect}`)
            .then(res => {
                try {
                    this.getParents()
                // const newParentEdit = Array.from(parents);
                // let index_selected = null;
                // newParentEdit.forEach((parent, index) => {
                //     if(parent.id_parents === idParentSelect){
                //         index_selected = index
                //     }
                // })
                // newParentEdit.splice(index_selected, 1);
                this.setState({ deleteParentDialog: false})
                // this.toast.show({ severity: 'success', summary: this.props.intl.formatMessage({id: 'GENERAL.UPDATED'}), detail: 'Parent removed successfully', life: 3000 });
                // this.updateParent(newParentEdit )
                } catch (error) {
                    console.error(error);
                }

            })
            console.log("ID Select: " + idParentSelect );
        } catch (error) {
            
        }
    }

    confirmDeleteParent(parentEdit){
        console.log(parentEdit);
        this.setState({
            parentEdit,
            deleteParentDialog: true
        })
    }

    hideDeleteDialog(){
        this.setState({ deleteParentDialog: false })
    }




    actionBodyTemplate(rowData){
        return(
            <>
                <Button icon='pi pi-pencil' color='primary' style={{ verticalAlign: 'middle', margin: '10px', width: "30px", height: '30px'}} onClick={() => this.editParent(rowData)} />
                <Button icon='pi pi-trash' className='p-button-danger' style={{verticalAlign: 'middle', margin: '10px', width: '30px', height: '30px'}} onClick={() => this.confirmDeleteParent(rowData)}/>
            </>
        )
    }

    render(){

        if(this.state.redirect){
            return <Redirect to={{
                pathname:'/wiyu-students'
            }} />
        }

        const footerParentNew = (
            <div>
                <Button label="Cancel" icon='pi pi-times' onClick={this.onHide} />
                <Button label="Save" icon='pi pi-check' onClick={this.updateStudent} />
            </div>
        )

        const footerParentEdit = (
            <div>
                <Button label="Cancel" icon='pi pi-times' onClick={this.onHideParent} />
                <Button label="Save" icon='pi pi-check' onClick={this.updateParent} />
            </div>
        )

        const deleteParentFooter = (
            <>
                <Button label="No" icon="pi pi-times" className='p-button-text' onClick={this.hideDeleteDialog} />
                <Button label="Yes" icon="pi pi-check" className='p-button-danger' onClick={this.deleteParent} />
            </>
        )

        return(
            <div>
                
                <Toast ref={(el) => this.toast = el} />

                <div className='d-flex justify-content-between mb-2'>
                    <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" icon="pi pi-arrow-left" onClick={this.changePage} variant='contained'/>
                </div>

                <div className='card-header flex-wrap border-0 pt-6 pb-0'>
                    <h5 className='card-label'> Parents info
                        <span className="d-block text-muted pt-2 font-size-sm" >Update parents info</span>
                    </h5>
                </div>

                <div>
                <div className='card p-6'>
                    {/* Dialog new parent */}
                    <Dialog header='Add new parent' footer={footerParentNew} visible={this.state.dialog} onHide={this.onHide} style={{width: "50%"}}>
                    <div className='form-group row'>
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-user'></i>
                                </span>
                                {/* First name */}
                                <span className='p-float-label'>
                                    <InputText
                                        // placeholder='First name'
                                        style={{width: 190, height: 33}}
                                        id="fullname_parents"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.fullname_parents}
                                        onChange={(e) => this.setState({fullname_parents: e.target.value} )}
                                        required autoFocus
                                        className={classNames({'p-invalid': this.state.submitted && !this.state.fullname_parents})}
                                    />
                                    <label htmlFor='fullname_parents'>Full name parents</label>
                                </span>
                                {/* {
                                    this.state.first_name_parents && (
                                        <small id="first_name_parents-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.first_name_parents}/></small>
                                    )
                                } */}
                            </div>
                            <small className='p-d-block' id='first_name_parents-help'>Enter first name and last name of the parent</small>
                        </div>
                        </div>

                        <div className='form-group row'>
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-envelope'></i>
                                </span>
                                {/* EMAIL */}
                                <span className='p-float-label'>
                                    <InputText 
                                        // placeholder='Email'
                                        style={{width: 190, height: 33}}
                                        id="email_parents"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.email_parents}
                                        onChange={(e) => this.setState({email_parents: e.target.value} )}
                                        required autoFocus
                                        className={classNames({'p-invalid': this.state.submitted && !this.state.email_parents})}
                                    />  
                                    <label htmlFor='email_parents'>Email</label>
                                </span>
                                {/* {
                                    this.state.email_parents && (
                                        <small id="email_parents-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.email_parents}/></small>
                                    )
                                } */}
                            </div>
                            <small className='p-d-block' id='whatsapp-help'>Enter parent email. Ex. email@email.com</small>
                        </div>

                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-phone'></i>
                                </span>
                                {/* Phone */}
                                <span className='p-float-label'>
                                    <InputText 
                                        // placeholder='Phone'
                                        style={{width: 190, height: 33}}
                                        id="phone_parents"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.phone_parents}
                                        onChange={(e) => this.setState({phone_parents: e.target.value} )}
                                        required autoFocus
                                        className={classNames({'p-invalid': this.state.submitted && !this.state.phone_parents})}
                                    />
                                    <label htmlFor='phone_parents'>Phone</label>
                                </span>
                                {/* {
                                    this.state.phoneMessage && (
                                        <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.phoneMessage}/></small>
                                    )
                                } */}
                            </div>
                            <small className='p-d-block' id='phone-help'>Enter phone parent. Ex. (000) 000-0000</small>
                        </div>
                        </div>

                        {/* <div className='form-group row'>
                        <div className='col-lg-6'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-user'></i>
                                    </span>
                                    {/* Parentesco */}
                                    {/* <span className='p-float-label'>
                                        <InputText 
                                            // placeholder='Parentesco'
                                            style={{width: 190, height: 33}}
                                            id="parentezco"
                                            variant="outlined"
                                            margin="dense"
                                            value={this.state.parentezco}
                                            onChange={(e) => this.setState({parentezco: e.target.value} )}
                                            required autoFocus
                                            className={classNames({'p-invalid': this.state.submitted && !this.state.parentezco})}
                                        />
                                        <label htmlFor='parentezco'>Relationship</label>
                                    </span> */}
                                    {/* {this.state.whatsappMessage &&(
                                        <small id='whatsapp-help' className={classNames({'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.whatsappMessage}></FormattedMessage></small>
                                    )} */}
                                {/* </div>
                                <small className='p-d-block' id='phone-help'>Enter relationship parent. Ex. Father, Mother</small>
                            </div> */}

                            {/* <div className='col-lg-6'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-phone'></i>
                                    </span>
                                    {/* Empresa */}
                                    {/* <span className='p-float-label'>
                                        <InputText 
                                            // placeholder='Empresa'
                                            style={{width: 190, height: 33}}
                                            id="empresa"
                                            variant="outlined"
                                            margin="dense"
                                            value={this.state.empresa}
                                            onChange={(e) => this.setState({empresa: e.target.value} )}
                                            required autoFocus
                                            className={classNames({'p-invalid': this.state.submitted && !this.state.empresa})}
                                        />
                                        <label htmlFor='empresa'>Occupation</label>
                                    </span> */}
                                    {/* {this.state.whatsappMessage &&(
                                        <small id='whatsapp-help' className={classNames({'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.whatsappMessage}></FormattedMessage></small>
                                    )} */}
                                {/* </div>
                                <small className='p-d-block' id='empresa-help'>Enter occupation of the parent. Ex. Employee, Engineer, Teacher, Doctor </small> */}
                            {/* </div>
                        </div> */}
                    </Dialog>

                    {/* Dialog edit parent */}
                    <Dialog header='Edit parent info' footer={footerParentEdit} visible={this.state.dialogParent} onHide={this.onHideParent} style={{width: "50%"}}>
                    <div className='form-group row'>
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-user'></i>
                                </span>
                                {/* First name */}
                                <span className='p-float-label'>
                                    <InputText
                                        placeholder={this.state.parentEdit.fullname_parents || 'First name'}
                                        style={{width: 190, height: 33}}
                                        id="fullname_parents"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.parentEdit.fullname_parents}
                                        onChange={(e) => this.onInputChange(e, 'fullname_parents')}
                                        required autoFocus
                                        className={classNames({'p-invalid': this.state.submitted && !this.state.fullname_parents})}
                                    />
                                    <label htmlFor='fullname_parents'>Full name parents</label>
                                </span>
                                {/* {
                                    this.state.first_name_parents && (
                                        <small id="first_name_parents-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.first_name_parents}/></small>
                                    )
                                } */}
                            </div>
                            <small className='p-d-block' id='fullname_parents-help'>Enter Parent first name</small>
                        </div>
                        </div>

                        <div className='form-group row'>
                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-envelope'></i>
                                </span>
                                {/* EMAIL */}
                                <span className='p-float-label'>
                                    <InputText 
                                        placeholder={this.state.parentEdit.email_parents  || 'Email'}
                                        style={{width: 190, height: 33}}
                                        id="email_parents"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.parentEdit.email_parents}
                                        onChange={(e) => this.onInputChange(e, 'email_parents')}
                                        required autoFocus
                                        className={classNames({'p-invalid': this.state.submitted && !this.state.email_parents})}
                                    />
                                    <label htmlFor='email_parents'>Email</label>
                                </span>
                                {/* {
                                    this.state.email_parents && (
                                        <small id="email_parents-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.email_parents}/></small>
                                    )
                                } */}
                            </div>
                            <small className='p-d-block' id='whatsapp-help'>Enter parent email. Ex. email@email.com</small>
                        </div>

                        <div className='col-lg-6'>
                            <div className='p-inputgroup'>
                                <span className='p-inputgroup-addon'>
                                    <i className='pi pi-phone'></i>
                                </span>
                                {/* Phone */}
                                <span className='p-float-label'>
                                    <InputText 
                                        placeholder={this.state.parentEdit.phone_parents  || 'Phone'}
                                        style={{width: 190, height: 33}}
                                        id="phone_parents"
                                        variant="outlined"
                                        margin="dense"
                                        value={this.state.parentEdit.phone_parents}
                                        onChange={(e) => this.onInputChange(e, 'phone_parents')}
                                        required autoFocus
                                        className={classNames({'p-invalid': this.state.submitted && !this.state.phone_parents})}
                                    />
                                    <label htmlFor='phone_parents'>Phone</label>
                                </span>
                                {/* {
                                    this.state.phoneMessage && (
                                        <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.phoneMessage}/></small>
                                    )
                                } */}
                            </div>
                            <small className='p-d-block' id='phone-help'>Enter phone parent. Ex. (000) 000-0000</small>
                        </div>
                        </div>

                        {/* <div className='form-group row'>
                            <div className='col-lg-6'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-user'></i>
                                    </span>
                                    <span className='p-float-label'>
                                        <InputText 
                                            // placeholder={this.state.parentEdit.parentezco || 'Parentesco'}
                                            style={{width: 190, height: 33}}
                                            id="parentezco"
                                            variant="outlined"
                                            margin="dense"
                                            value={this.state.parentEdit.parentezco}
                                            onChange={(e) => this.onInputChange(e, 'parentezco')}
                                            required autoFocus
                                            className={classNames({'p-invalid': this.state.submitted && !this.state.parentezco})}
                                        />
                                        <label htmlFor='parentezco'>Relationship</label>
                                    </span>
                                    {this.state.whatsappMessage &&(
                                        <small id='whatsapp-help' className={classNames({'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.whatsappMessage}></FormattedMessage></small>
                                    )}
                                </div>
                                <small className='p-d-block' id='phone-help'>Enter relationship parent. Ex. Father, Mother</small>
                            </div>

                            <div className='col-lg-6'>
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-phone'></i>
                                    </span>
                                    <span className='p-float-label'>
                                        <InputText 
                                            // placeholder={this.state.parentEdit.empresa  || 'Empresa'}
                                            style={{width: 190, height: 33}}
                                            id="empresa"
                                            variant="outlined"
                                            margin="dense"
                                            value={this.state.parentEdit.empresa}
                                            onChange={(e) => this.onInputChange(e, 'empresa')}
                                            required autoFocus
                                            className={classNames({'p-invalid': this.state.submitted && !this.state.empresa})}
                                        />
                                    <label htmlFor='empresa'>Occupation</label>
                                    </span>
                                    {this.state.whatsappMessage &&(
                                        <small id='whatsapp-help' className={classNames({'p-invalid': this.state.submitted})}><FormattedMessage id={this.state.whatsappMessage}></FormattedMessage></small>
                                    )}
                                </div>
                                <small className='p-d-block' id='empresa-help'>Enter occupation of the parent. Ex. Employee, Engineer, Teacher, Doctor </small>
                            </div>
                        </div> */}
                    </Dialog>
                    
                    {/* Dialog delete parent */}
                    <div>
                        <Dialog visible={this.state.deleteParentDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteParentFooter} onHide={this.hideDeleteDialog} >
                            <div className='confirmation-content'>
                                <i className='pi pi-exclamation-triangle p-mr-3' style={{ fontSize: '2rem'}} />
                                {this.state.parentEdit && <span>Are you sure you want to delete <b>{this.state.parentEdit.first_name_parents}</b></span>}
                            </div>
                        </Dialog>
                    </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingBottom: 10, marginRight: 5}} >
                    <div className='mb-6' style={{ marginLeft: 10}}>
                    <Button className='p-button-rounded p-button-outlined' type="button" label='Add new parent' icon="pi pi-plus" onClick={this.onOpen} variant='contained'/>
                    </div>
                </div>

                <div className='card'>
                        <DataTable
                        ef={(el) => this.dt = el} value={this.state.parents} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No parents found." loading={this.state.loading}
                        selection={this.state.selectedParents} onSelectionChange={e => this.setState({ selectedParents: e.value })} dataKey="id">
                            <Column field='fullname_parents' header="Full name" sortable></Column>
                            <Column field='email_parents' header="Email" sortable></Column>
                            <Column field='phone_parents' header="Telefono" sortable></Column>
                            {/* <Column field='empresa' header="Empresa de trabajo" sortable></Column>
                            <Column field="parentezco" header="Parentesco" sortable></Column> */}
                            <Column body={this.actionBodyTemplate}></Column>
                        </DataTable>
                </div>

                </div>
                </div>
                <br/>

                <br/>

                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end',
                    paddingBottom: 10,
                    marginRight: 5}}>

                    {/* Boton cancelar */}
                    <div style={{marginLeft: 10}}>
                        <Button className='p-button-rounded p-button-danger p-button-outlined' type="button" label='Cancelar' icon="pi pi-times" onClick={this.changePage} variant='contained'/>
                    </div>

                    {/* Boton guardar */}
                    {/* <div style={{marginLeft: 10}}>
                        <Button className='p-button-rounded p-button-help p-button-outlined' type="button" label='Guardar' icon="pi pi-check" onClick={this.updateStudent} variant='contained'/>
                    </div> */}

                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataStudent: state.auth.dataStudent
});

export default injectIntl(
    connect(mapStateToProps, auth.actions)(ParentsInfo)
)