/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
//import { useIntl } from "react-intl";

export function AsideMenuListDefault({ layoutProps }) {

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        </ul>
        {/* end::Menu Nav */}
      </>
  );
}
