import React from "react";
import {useSubheader} from "../../_metronic/_core";
import TutorialSection from "../modulesGeneral/TutorialSection"
import { useIntl } from "react-intl";

export const TutorialsSectionPage = () => {
    const suhbeader = useSubheader();
    const int1 = useIntl();

    suhbeader.setTitle(int1.formatMessage({id: "LEARNING_GENERAL.TUTORIALS"}));
    return (<TutorialSection/>)
}
