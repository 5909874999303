// Events - Wiyu
import React, {Component} from 'react'
import classNames from 'classnames';
// import { withRouter } from "react-router";

import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
// import { Editor} from 'primereact/editor';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';

import * as auth from '../../modulesAdmin/Auth/_redux/authRedux';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const url = ServerURL();

class EventsTable extends Component {

  emptyEvent = {
    id_event_type: '',
    id_date_type: '',
    id_language: '',
    event: '',
    description: '',
    guia: ' ',
    rango: '',
  }


  constructor (props) {
    super(props);

    this.state= {
      id_event_type: '',
      id_date_type: '',
      id_language: '',      
      name: '',
      description: '',
      guia: '',
      rango: 0,

      
      id_event_typeMessage: '',
      id_date_typeMessage: '',
      id_languageMessage: '',
      eventMessage: '',
      descriptionMessage: '',
      guiaMessage: '',
      rangoMessage: '',

      event: this.emptyEvent,
      events: null,

      ids: null,
      globalfilter: null,

      selectedEvents: null,
      eventDialog: null,

      selectedEventType: null,
      resetEventType: null,

      deleteEventDialog: false,
      deleteEventsDialog: false,
      submitted: false,

      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTranslate: getGeneralTextTranslate(),

      selectedLanguage: null,
      resetLanguage: null,

      redirectEdit: false,
      redirectNew: false,
      changeToTemplate: false,

      options: '',
      
      selectedDateType: null,
      resetDateType: null,

      idEventSelect: false,
    }

    this.languages = [
      {language: 'Español'},
      {language: 'English'},
      {language: 'Français'},
      {language: 'Deutsch'}
    ]

    // this.dates = [
    //   {name: 'Accommodation start date', value: 1},
    //   {name: 'Accommodation end date', value: 2},
    //   {name: 'Insurance start date', value: 3},
    //   {name: 'Insurance end date', value: 4},
    //   {name: 'Program start date', value: 5},
    //   {name: 'Program end date', value: 6},
    //   {name: 'Flight start date', value: 7},
    //   {name: 'Flight end date', value: 8},
    // ]

    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.saveEvent = this.saveEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.openNew = this.openNew.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.confirmDeleteEvent = this.confirmDeleteEvent.bind(this);

    this.confirmDeleteEventSelected = this.confirmDeleteEventSelected.bind(this);
    this.deleteSelectedEvents = this.deleteSelectedEvents.bind(this);
    this.hideDeleteEventDialog = this.hideDeleteEventDialog.bind(this);

    this.hideDeleteEventsDialog = this.hideDeleteEventsDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onEventTypeChange = this.onEventTypeChange.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onDateTypeChange = this.onDateTypeChange.bind(this);
    this.editPage = this.editPage.bind(this);
    this.newPage = this.newPage.bind(this);

    // this.onItemChange = this.onItemChange.bind(this);

    // this.onLazyItemChange = this.onLazyItemChange.bind(this);
    // this.onLazyLoad = this.onLazyLoad.bind(this);
  }

  componentDidMount() {
    this.getEvents()
  }

  editPage(dataEvent) {
    try {
        console.log(this.props);
        console.log(dataEvent);
        this.props.updatedEvent(dataEvent)
        this.setState({ redirectEdit: true })
        // this.setState({redirect: true })
    } catch (error) {
      this.toast.show({ severity: 'error', summary: 'Error', detail: 'Error'})
      console.error('Error');
      console.log(error);
    }
    // this.setState({redirect: true })
  }

  newPage(){
    this.setState({ redirectNew: true })
  }

  onClick(name, idEventSelect){
    let state = {
      [`${name}`]: true,
      idEventSelect
    };
    this.setState(state)
  }

  onButtonChange(e, name, f) {
    let event = {...this.state.event}
    event['description'] = e + ' ' + [`[${name}]`]
    event['guia'] =  [`${name}`]  + ',' + event['guia']

    this.setState({ event })
  }

  onClearGuia(e){
    let event = {...this.state.event}
    event['guia'] = e
    this.setState({ event })
  }

  onClearDescription(e){
    let event = {...this.state.event}
    event['description'] = e
    this.setState({ event })
  }

  isValid(){
    const { id_event_type,  event, description, id_date_type, id_language, rango  } = this.state.event

      const id_event_typeMessage = this.validateEventType()
      const id_date_typeMessage = this.validateDateType()
      const id_languageMessage = this.validateLanguage()
      const eventMessage = this.validateEvent()
      const descriptionMessage = this.validateDescription()
      const rangoMessage = this.validateRango()

      if(id_event_type && event && description && id_language && id_date_type && rango  ){
        if(!rangoMessage && !id_event_typeMessage && !eventMessage && !descriptionMessage && !id_languageMessage && !id_date_typeMessage ){
          return true;
        }else{
          return false;
        }
      }
      return false;
  }

  validateLanguage() {
    const event = this.state.event
    let languageMessage;
    if (!event.language) {
      languageMessage = 'Requerido';
    } else {
      languageMessage = ''
    }
    this.setState({ languageMessage })
    return languageMessage
  }

  validateDateType(){
    const event = this.state.event

    let id_date_typeMessage;
    if(!event.id_date_type){
      id_date_typeMessage = 'Requerido'
    } else {
      id_date_typeMessage = ''
    }
    this.setState({ id_date_typeMessage })
    return id_date_typeMessage

  }

  validateEventType() {
    const event = this.state.event
    
    let id_event_typeMessage;
    if (!event.id_event_type) {
      id_event_typeMessage = 'Requerido';
    } else {
      id_event_typeMessage = ''
    }
    this.setState({ id_event_typeMessage })
    return id_event_typeMessage
  }

  validateEvent() {
    const event= this.state.event
    
    let eventMessage;
    if (!event.event) {
      eventMessage = 'Requerido';
    } else {
      eventMessage = ''
    }
    this.setState({ eventMessage })
    return eventMessage
  }

validateDescription() {
    const event = this.state.event
    
    let descriptionMessage;
    if (!event.description) {
      descriptionMessage = 'Requerido';
    } else {
      descriptionMessage = ''
    }
    this.setState({ descriptionMessage })
    return descriptionMessage
  }

  validateRango() {
    const event = this.state.event
    
    let rangoMessage;
    if(!event.rango){
      rangoMessage = 'Requerido'
    } else {
      rangoMessage = ''
    }
    this.setState({ rangoMessage })
    return rangoMessage
  }

  getTextAuxEventType(){
    const message = this.state.id_event_typeMessage
    return message
  }

  getTextAuxDateType() {
    const message = this.state.id_date_typeMessage
    return message
  }

  getTextAuxLanguage() {
    const message = this.state.id_languageMessage
    return message
  }

  getTextAuxEvent(){
    const message = this.state.eventMessage
    return message
  }

  getTextAuxDescription(){
    const message = this.state.descriptionMessage
      return message
  }

  getTextAuxRango() {
    const message = this.state.rangoMessage
    return message
  }

  getTextAuxGuia(){
    const message = this.state.guiaMessage
    return message
  }

  getEvents = async () => {
    try {
        let res = axios.get(`${url}/api/auth/events`)
        .then(res => {
          const events  = res.data;            
          this.setState({ events })
        });                     
          console.log(res.data)
        }catch(error){
          
        }
}

getEventTypes = async () =>{  
  try { 
      axios.get(`${url}/api/auth/event_types`)
      .then(res => {
        const event_types= res.data;
        this.setState({ event_types })
        console.log(res.data);
      });                     
      }catch(error){
  }
}

getDateTypes = async () => {
  try {
    await axios.get(`${url}/api/public/date_types`)
    .then(res => {
      const date_types = res.data
      this.setState({ date_types })
      console.log(res.data);
    });
    // console.log(res.data);
  } catch (error) {
    console.log(error);
  }
}

getLanguages = async () => {
  try {
    await axios.get(`${url}/api/public/languages`)
    .then(res => {
      const languages = res.data
      this.setState({ languages })
      console.log(res.data);
    })
  } catch (error) {
    console.log(error);
  }

}

deleteEvent(){
  let data = {...this.state.event};

  try {
  let res = axios.delete(`${url}/api/auth/event/${data.id}`)
    .then(res => {
    this.getEvents()
  })
  if (!res.error) {
    this.setState({
      data,
      deleteEventDialog: false,
      event: this.emptyEvent
  });
  this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Event Deleted', life: 3000 });
  }
    }catch(error){
    } 
}

saveEvent() {
  let state = { submitted: true };
  // const decision = this.isValid();
  
  // if (decision) {
 // if (this.state.role.role.trim()) {       
    let data = {...this.state.event};
    console.log("Data: ", data);
  if (this.state.event.id) {

      try {
        let res = axios.put(`${url}/api/public/event/${data.id}`, data)
        // let res = axios.post(`${url}/api/public/event`, data)
        .then(res =>{
        this.getEvents()  
        console.log(res);
        })
        if (!res.error) {     
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Updated', life: 3000 });
        }
      }catch(error){   
        console.log(error);       
    }         

  }else{

    try {
      let res = axios.post(`${url}/api/public/event`, data)
      .then(res => {
        this.getEvents()
      })
      if (!res.error) {
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Role Created', life: 3000 });
      }
    } catch(error){
        
        }   
  }
  state = {
    ...state,
    data,
    eventDialog: false,
    event: this.emptyEvent,
    selectedEventType: null,
    selectedLanguage: null,
    selectedDateType: null,
    resetEventType:null,
    resetLanguage: null,
    resetDateType: null
  }; 
// }
this.setState(state);
}

openNew() {
  this.getEventTypes()
  this.getDateTypes()
  this.getLanguages()
  this.setState({
      event: this.emptyEvent,
      submitted: false,
      eventDialog: true,
      selectedEventType: null,
      selectedLanguage: null,
      selectedDateType: null,
      resetEventType:"Event Type",
      resetLanguage: "Language",
      resetDateType: 'Reference date'
  })
}

onInputChange(e, name) {
  const val = (e.target && e.target.value) || '';
  let event = {...this.state.event};
  event[`${name}`] = val;

  this.setState({ event });
}

onEventTypeChange(e) {
  let event = {...this.state.event};
  event['id_event_type'] = e.value;
  this.setState({ selectedEventType: e.value, event});      
}

onLanguageChange(e) {
  let event = {...this.state.event};
  event['id_language'] = e.value;
  this.setState({ selectedLanguage: e.value, event})
}

onDateTypeChange(e) {
  let event = {...this.state.event}
  event['id_date_type'] = e.value;
  this.setState({ selectedDateType: e.value, event})
}

// onItemChange(e) {
//   this.setState({
    
//   })
// }

// onLazyItemChange() {

// }

// onLazyLoad() {

// }



  hideDialog() {
    this.setState({
        submitted: false,
        eventDialog: false,
        event_typeMessage: '',
        eventMessage:'',
        descriptionMessage: '',
        languageMessage: ''
  })  
  }

  // editEvent(event) {
  //   console.log(event)
  //   this.getEventTypes()
  //   this.getDateTypes()
  //   this.getLanguages() 
  //   this.setState({
  //       event: { ...event },
  //       selectedEventType: null,
  //       selectedLanguage: null,
  //       selectedDateType: null,
  //       resetEventType:null, 
  //       resetLanguage: null,
  //       resetDateType: null,
  //       eventDialog: true,
  //   })
  // }

  editEvent(dataEvent) {
    console.log(this.props);
    console.log(dataEvent);
    this.props.updatedEvent(dataEvent)
    this.setState({ redirect: true })
  }

  confirmDeleteEvent(event) {
    this.setState({
        event,
        deleteEventDialog: true
    })
  }

  confirmDeleteEventSelected() {
    this.setState({ deleteEventsDialog: true })
  }

  deleteSelectedEvents() {
    let events = this.state.events.filter(val => !this.state.selectedEvents.includes(val));
    let data = this.state.events.filter(val => this.state.selectedEvents.includes(val));

    try {
      console.log("Events: ", events);
      console.log("Data: ", data);
      let res = axios.delete(`${url}/api/auth/events`, {data})
      .then(res => {
      this.getEvents()
    })
    if (!res.error) {
      this.setState({
        events,
        deleteEventsDialog: false,
        selectedEvents: null
    });
    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Events Deleted', life: 3000 });
    }
      } catch(error){
      } 
}

  hideDeleteEventDialog() {
    this.setState({ deleteEventDialog: false })
  }
    
  hideDeleteEventsDialog() {
    this.setState({ deleteEventsDialog: false });
  }

  actionBodyTemplate(rowData) {
    return (
        <>
            <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editPage(rowData)} />
            <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteEvent(rowData)} />
        </>
    )
  }

  renderHeader(){
    return (
        <span>
          <Button color='primary' style={{ width: "30px", height: "30px"}}>Nombre</Button>
        </span>
    )
  }

  render(){

    if(this.state.redirectEdit) {
      return <Redirect to={{
        pathname: '/wiyu-events-edit',
      }}/>;
    }

    if(this.state.redirectNew){
      return <Redirect to={{
        pathname: '/wiyu-events-new'
      }} />
    }

    // if(this.state.redirect){
    //   return <Redirect to={{
    //     pathname: '/wiyu-events-new'
    //   }} />
    // }

    const deleteEventDialogFooter = (
      <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEventDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteEvent} />
      </>
    );

    const eventDialogFooter = (
      <>
          <FormattedMessage id="GENERAL.CANCEL">
            {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
          </FormattedMessage>
          <FormattedMessage id="GENERAL.SAVE">
            {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveEvent} />}
          </FormattedMessage>
      </>
    );

    const deleteEventsDialogFooter = (
      <>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEventsDialog } />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedEvents} />
      </>
    );

    // const header = this.renderHeader()

    return(
      <div>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />
          <h3 className="card-label">
            Notification template
            <span className="d-block text-muted pt-2 font-size-sm">Add, update or delete template notifications</span>
          </h3>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            {/* TOOL BAR */ }

            <div style={{ marginRight: 10}}>
              <FormattedMessage id="GENERAL.DELETE">
              {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteEventSelected} disabled={!this.state.selectedEvents || !this.state.selectedEvents.length} />}
              </FormattedMessage>
            </div>

            <div style={{ marginRight: 10}} >
              <p><Button type='button' label="New Event" icon='pi pi-plus' onClick={this.newPage} variant='contained' color='primary' /></p>
            </div>

            <div style={{ marginRight: 10}}>
              <p>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GlobalSearch} />
                </span>
              </p>
            </div>

          </div>


          <div>
          {/* SHOW EVENTS */}
            <div className="card">
              <DataTable
                ef={(el) => this.dt = el} value={this.state.events} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                globalFilter={this.state.globalFilter} emptyMessage="No Events found." loading={this.state.loading}
                selection={this.state.selectedEvents} onSelectionChange={e => this.setState({ selectedEvents: e.value })} dataKey="id">
                  <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                  <Column header='Orden'></Column>
                  <Column field="event" header="Notification"  filter sortable></Column>
                  <Column field="event_type" header="Notification type"  filter sortable></Column>
                  <Column field="description" header={this.state.campusText.Description}  filter sortable></Column>
                  {/* <Column field="language" header="Language" filter sortable></Column> */}
                  <Column field='date_type' header="Reference date" filter sortable />
                  <Column field="rango" header="Range" filter sortable></Column>
                  <Column body={this.actionBodyTemplate}></Column>
              </DataTable>
            </div>
          </div>

          <div>
            {/* DELETE EVENT */}
            <Dialog visible={this.state.deleteEventDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEventDialogFooter} onHide={this.hideDeleteEventDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.event && <span> Are you sure you want to delete <b>{this.state.event.event}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/* DELETE WITH TOOL BAR */}
          <Dialog visible={this.state.deleteEventsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEventsDialogFooter} onHide={this.hideDeleteEventsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.event && <span>Are you sure you want to delete the selected events?</span>}
                    </div>
          </Dialog>

          {/* UPDATE EVENT & CREATE EVENT */}
          <div>
            <Dialog visible={this.state.eventDialog} contentStyle={{maxHeight:"1500px", width:"1250px", height:"550px", overflow:"auto"}} header="New event" modal footer={eventDialogFooter} onHide={this.hideDialog}>
              <br></br>
              <div className="form-group row">
               {/* Event Type */}
              <div className="col-lg-6">
                  <div className='p-inputgroup'>
                    <span className='p-inputgroup-addon'>
                      <i className='pi pi-tag'></i>
                    </span>      
                    <span className="p-float-label">          
                      <Dropdown
                          style={{ width: 250, height:33 }}
                          id="event_type"
                          variant="outlined"
                          placeholder= { this.state.resetEventType || this.state.event.event_type}
                          margin="dense"
                          value={this.state.selectedEventType || "event_type"}   
                          options ={this.state.event_types} 
                          onChange={this.onEventTypeChange} 
                          optionLabel="event_type" 
                          optionValue="id"
                          />
                        <label htmlFor="event_type">Tipo de notificación</label>
                      </span>
                  </div>
                  <small id='event_type-help' className='p-d-block'>Elige el tipo de notificación</small>    
                  <small id="event_type-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEventType()}</small>
                </div>
              {/* Event */}
                <div className="col-lg-6">
                  <div className='p-inputgroup'>
                    <span className='p-inputgroup-addon'>
                      <i className='pi pi-tag'></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                      id="event"
                      variant="outlined"
                      type="event"
                      margin="dense"
                      value={this.state.event.event}             
                      onChange={(e) => this.onInputChange(e, 'event')}
                      required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.event.event  })}
                      />
                      <label htmlFor="event">Notification</label>
                    </span>
                    <small id="event-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxEvent(this.state)}</small> 
                  </div>
                  <small id='event-help' className='p-d-block'>Ingresa el nombre de tu notificación</small>
                </div>
              </div>


              <br></br>
              <div className="form-group row">
                {/* Idioma */}
                {/* <div className="col-lg-6">      
                  <div className='p-inputgroup'>
                    <span className='p-inputgroup-addon'>
                      < i className='pi pi-globe'></i>
                    </span>
                    <span className="p-float-label">   
                        <Dropdown
                          style={{ width: 250, height: 33 }}
                          id='language'
                          variant='outlined'
                          placeholder={ this.state.resetLanguage || this.state.event.language}
                          margin='dense'
                          value={this.state.selectedLanguage || "language" }
                          options={this.state.languages}
                          onChange={this.onLanguageChange}
                          optionLabel='language'
                          optionValue='id'
                        />
                        <label htmlFor="language">Idioma</label>     
                    </span>
                  </div>
                  <small className='p-d-block'>Elige el lenguaje para tus notificaciones</small>
                  <small id="language-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxLanguage(this.state)}</small>
                </div> */}
              <br/>
              <br/>
              </div>

              <div className='form-group row'>
                {/* Fecha base */}
                <div className='col-lg-6'>
                  <div className='p-inputgroup'>
                    <span className='p-inputgroup-addon'>
                      <i className='pi pi-calendar'></i>
                    </span>
                    <span className='p-float-label'>
                      <Dropdown
                        style={{ width: 250, height: 33 }}
                        id='date_type'
                        variant='outlined'
                        placeholder={ this.state.resetDateType || this.state.event.date_type}
                        margin='dense'
                        value={this.state.selectedDateType || "date_type" }
                        options={this.state.date_types}
                        onChange={this.onDateTypeChange}
                        optionLabel='date_type'
                        optionValue='id'
                      />
                      <label htmlFor='date_type'>Fecha base para la notificación</label>
                    </span>
                  </div>
                  <small className='p-d-block'>Elige la fecha base para tu notificaciones.</small>
                </div>

                {/* Dias para ejecutar */}
                <div className='col-lg-6'>
                  <div className='p-inputgroup'>
                    {/* <span className='p-inputgroup-addon'>
                      <i className='pi pi-calendar'></i>
                    </span> */}
                    <span>
                      <InputNumber id="horizontal" value={this.state.event.rango} onValueChange={(e) => this.onInputChange(e, 'rango')} showButtons buttonLayout="horizontal"
                      decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal"/>
                    </span>
                  </div>
                  <small className='p-d-block'>Elige el numero de dias, antes o despues de la fecha base, para su ejecucion. </small>
                  <small className='p-d-block'>Numero positivo suma los dias, numero negativos resta los dias.</small>                
                </div>

                <div className='form-group row' style={{ paddingTop: 10, marginLeft: 5 }}>
                  
                  <div className='col-lg-10'>
                    <span>
                      <Button type='button' onClick={() => this.onButtonChange(this.state.event.description , 'nombre', this.onButtonChange(this.state.event.guia))}>[nombre]</Button>
                    </span>

                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onButtonChange(this.state.event.description , 'ciudad', this.onButtonChange(this.state.event.guia))}  >[ciudad]</Button>
                    </span>

                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onButtonChange(this.state.event.description , 'fecha', this.onButtonChange(this.state.event.guia))} >[fecha]</Button>
                    </span>

                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onButtonChange(this.state.event.description , 'programa', this.onButtonChange(this.state.event.guia))} >[programa]</Button>
                    </span>

                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onClearGuia('')} >Limpiar guia</Button>
                    </span>

                  </div>
                  
                  <br/>
                  <br/>

                  <div className='col-lg-7'>
                    <div className='p-inputgroup'>
                      <span className='p-float-label'>
                        <InputText
                        id='guia'
                        type='guia'
                        value={this.state.event.guia}
                        // onChange={(e) => this.onInputChange(e, 'guia')}
                        disabled
                        />
                      </span>
                    </div>
                  </div>

                </div>

                <div className='form-group row'>                  
                  <div className="col-lg-6">
                    <div className='p-inputgroup'>
                      <span className='p-float-label' >
                        <InputTextarea 
                        style={{ width: 1000, height: 80}}
                        id='description'
                        variant='outlined'
                        margin='dense'
                        value={this.state.event.description} 
                        onChange={(e) => this.onInputChange(e, 'description')}
                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.event.description})}
                        autoResize/>
                        <label htmlFor="description">Descripción</label>
                        <small id="description-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxDescription(this.state)}</small>    
                      </span>
                    </div>
                  </div>

                  <div className='col-lg-3'>
                    <span style={{ marginLeft: 25}}>
                      <Button type='button' onClick={() => this.onClearDescription('')} >Limpiar descripción</Button>
                    </span>
                  </div>
                </div>

                {/* <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="horizontal">Horizontal with Step</label>
                    <InputNumber id="horizontal" value={this.state.value18} onValueChange={(e) => this.setState({value18: e.value})} showButtons buttonLayout="horizontal"
                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal"/>
                </div> */}
                
              </div>
          </Dialog>
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
})

export default injectIntl(
  connect(mapStateToProps, auth.actions)(EventsTable)
)

