import React from "react";
import {useSubheader} from "../../_metronic/_core";
import AssignStudents from "../modulesWiyu/CustomerSuccess/AssignStudents";
import { useIntl } from "react-intl";

export const AssignmentStudentPage = () => {
    const suhbeader = useSubheader();
    // const int1 = useIntl();

    // suhbeader.setTitle("Assign students");
    return (<><AssignStudents/></>);
}