import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios';
import * as auth from '../../modulesAdmin/Auth/_redux/authRedux'
import ServerURL from '../../../ServerURL'
import { Redirect, Link } from "react-router-dom";

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage, injectIntl } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Calendar } from 'primereact/calendar'


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { connect } from 'react-redux';



const url = ServerURL();

export default class ListPrograms extends Component {

    emptyPrograms ={
        program: '',
        start_date: '',
        end_date: '',
        events: ''
      }
    
      constructor (props) {
        super(props);
    
        this.state = {
          program:"",
          start_date: '',
          end_date: '',
          events: '',
    
          programMessage: '',
          start_dateMessage: '',
          end_dateMessage: '',
    
          programD: this.emptyPrograms,
          programs: null,
          event: null,
          ids: null,

          globalFilter: null,
          selectPrograms: null,
          selectedEvents: null,
          programDialog: null,
          deleteProgramDialog: null,
          deleteProgramsDialog: null,
          submitted: false,

          dialog: false,
          btnDisable: false,
          displayConfirmation: false,
          changeToTemplate: false,
          idProgramSelect: false,
          redirect: false,
    
          layout: 'grid',
          sortKey: null,
          sortOrder: null,
          sortField: null,
          
          
          customerText: getTextCampusTranslate(),
          paginatorText: getTextPaginatorTranslate(),
          textTranslate: getGeneralTextTranslate(),
        }
    
        this.sortOptions =[
          {label: 'Por grupos', value: 'groups'}
        ]
    
    
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.saveProgram = this.saveProgram.bind(this);
        this.deleteProgram = this.deleteProgram.bind(this);
        this.openNew = this.openNew.bind(this);
        this.editProgram = this.editProgram.bind(this);
        this.confirmDeleteProgram = this.confirmDeleteProgram.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.hideDeleteProgramDialog = this.hideDeleteProgramDialog.bind(this);
        this.hideDeleteProgramsDialog = this.hideDeleteProgramsDialog.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);

        // this.onOpen = this.onOpen.bind(this);
        this.onClick = this.onClick.bind(this);
        // this.onChangeSearch = this.onChangeSearch.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        // this.changePage = this.changePage.bind(this);



      }
    
      componentDidMount() {
        try {
    
          console.log('Actualizado')
          this.getPrograms()
        } catch (error) {
          console.log(error)
        }
      }

      onClick(name, idProgramSelect){
        let state = {
          [`${name}`]: true,
          idProgramSelect
        };

        this.setState(state)
      }
      
      validateProgram(){
        const program = this.state.programD
    
        let programMessage;
    
        if(!program.name) {
          programMessage = 'Requerido'
        } else {
          programMessage = ''
        }
        this.setState({ programMessage })
        return programMessage
      }

      isValid(){
        const { program } = this.state.programD

        const programMessage =  this.validateProgram()

        if(program) {
          if(!programMessage) {
            return true
          } else {
            return false
          }
        }
        return false
      }
    
      getTextAuxProgram() {
        const message = this.state.programMessage
        return message
      }

      openNew() {
        // this.getEvents()
        this.setState({
          programD: this.emptyPrograms,
          submitted: false,
          programDialog: true
        })
      }
    
      onInputChange(e, name){
        const val = (e.target && e.target.value) || '';
        let program = {...this.state.programD}
        program[`${name}`] = val;
    
        this.setState({ program })
      }

      // onOpen(){
      //   this.setState({
      //     dialog: true
      //   })
      // }
    
      hideDialog() {
        this.setState({
          submitted: false,
          programDialog: false,
          programMessage: ''
        })
      }
    
      editProgram(program){
        console.log(program);
        this.getPrograms()
        this.setState({
          programD: {...program},
          programDialog: true
        })
      }
    
      confirmDeleteProgram(program) {
        console.log(program)
        this.setState({
          program,
          deleteProgramDialog: true
        })
      }
    
      confirmDeleteSelected() {
        this.setState({ deleteProgramsDialog: true })
      }
    
      hideDeleteProgramDialog() {
        this.setState({ deleteProgramDialog: false })
      }
    
      hideDeleteProgramsDialog() {
        this.setState({ deleteProgramsDialog: false})
      }

      textResponsive(text){
        if(text.length >=39){
          return(<div style={{fontSize:'1rem', fontWeight: 700}}>{text}</div>)
        } else {
          return(<div style={{fontSize:"autp", fontWeight: 700}}>{text}</div>)
        }
      }
    
      getPrograms() {
        try {
          axios.get(`${url}/api/public/groupRequest`)
          .then(res =>{
            const programs = res.data;
            this.Date(programs);
            this.setState({ programs })
            console.log(programs)
          });
        } catch (error) {
          console.log(error)
        }
      }
    
      getEvents() {
        try {
          let res = axios.get(`${url}/api/public/events`)
          .then(res => {
            const events = res.data;
            this.setState({ events })
          });
          console.log(res.data)
          return res
        } catch (error) {
          console.error(error);
        }
      }
    
      async saveProgram(){
        let data = {
          program: this.state.program
        }

        if(this.state.program){
          console.log(data);
          try{
            await axios.post(`${url}/api/public/groupRequest`, data)
            .then(res =>{
              const { message, message_type } = res.data;
              let type = null;

              if(message_type === 'success'){
                type = "GENERAL.SUCCESSFUL"
                this.setState({ dialog: false, program: ""})
                this.getPrograms()
              } else {
                type = "GENERAL_MESSAGE.ERROR"
              }

              this.toast.show({ severity: message_type, summary: this.props.intl.formatMessage({id: type}), detail: this.props.intl.formatMessage({id: message}), life: 3000});
            });
          } catch (error) {

          }
        } else {
          this.toast.show({ severity: 'info', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.ERROR'}), detail: this.props.intl.formatMessage({id: 'LEARNING_COURSE_LIST.MISSING_DATA'}), life: 3000 });
        }
      }
    
      deleteProgram() {
        let data = {...this.state.programD};
    
        try {
          let res = axios.delete(`${url}/api/public/groupRequest/${data.id}`)
          .then(res => {
            this.getPrograms()
          })
          if(!res.error){
            this.setState({
              data,
              deleteProgramDialog: false,
              programD: this.emptyPrograms
            })
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Customer Success deleted', life: 3000})
          }
        } catch (error) {
          console.error(error);
        }
      }
    
      deleteSelected() {
        let customers = this.state.customers.filter(val => !this.state.selectedCustomers.includes(val));
        let data = this.state.customers.filter(val => this.state.selectedCustomers.includes(val));
    
        try {
          let res = axios.delete(`${url}/api/public/wiyu_customer_success`, {data})
          .then(res => {
            this.getCustomers()
          })
          if(!res.error){
            this.setState({
              customers,
              deleteCustomerDialog: false,
              selectedCustomers: null
            })
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Customer Successes deleted', life: 3000 })
          }
        } catch (error) {
          
        }
      }
    
      saveSelected() {
        let customer = this.state.customer.filter(val => !this.state.selectedEvents.includes(val));
        let data = this.state.customer.filter(val => this.state.selectedEvents.includes(val));
    
        try {
          let res = axios.post(`${url}/api/public/events`, {data})
          .then(res => {
            this.getEvents()
          })
          if(!res.error){
            this.setState({
              customer,
              saveEventsDialog: false,
              selectedEvents: null
            })
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Customer Successes deleted', life: 3000 })
          }
        } catch (error) {
          
        }
      }
    
      onSortChange(event) {
        const value = event.value
    
        if (value.indexOf('!') === 0) {
          this.setState({
            sortOrder: -1,
            sortField: value.substring(1, value.length),
            sortKey: value
          })
        }
        else {
          this.setState({
            setOrder: 1,
            sortField: value,
            sortKey: value
          })
        }
      }
    
      Date(e){                 
        e.map((programs) => {
          console.log("Start_date:",programs.start_date)
          console.log(("Created_at:", programs.created_at));
          console.log((("End date: ", programs.end_date)));

          const dateStart = new Date(programs.start_date);
          const dateCreated = new Date(programs.created_at);
          const dateEnd = new Date(programs.end_date);
    
    
          let year = dateStart.getFullYear();
          let month = dateStart.getMonth()+1;
          let dt = dateStart.getDate();
    
          let year2 = dateCreated.getFullYear();
          let month2 = dateCreated.getMonth()+1;
          let dt2 = dateCreated.getDate();

          let year3 = dateEnd.getFullYear();
          let month3 = dateEnd.getMonth();
          let dt3 = dateEnd.getDate()
    
          if (dt < 10) {
            dt = '0' + dt;
          }
          if (month < 10) {
            month = '0' + month;
          }
    
          if (dt2 < 10) {
            dt2 = '0' + dt2;
          }
          if (month2 < 10) {
            month2 = '0' + month2;
          }

          if (dt3 < 10) {
            dt3 = '0' + dt3;
          }
          if (month3 < 10) {
            month3 = '0' + month3;
          }
    
          let new_date_start = dt +'/' + month + '/'+year
          let new_date_created = dt2 +'/' + month2 + '/' + year2
          let new_date_end = dt3 +'/' + month3 + '/' + year3

        //  console.log("New Data:",new_date)
    
          programs.start_date = new_date_start
          programs.created_at = new_date_created
          programs.end_date = new_date_end
          return programs
    
        });
      }
    
      actionBodyTemplate(rowData) {
        return (
          <>
            <Button icons="pi pi-pencil" color='primary' style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px"}} onClick={() => this.editCustomer(rowData)} />
            <Button icon='pi pi-trash' className="p-button-danger" style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px"}} onClick={() => this.confirmDeleteCustomer(rowData)} />
            <Button icon='pi pi-check' className="primary" style={{verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px"}} onClick={() => this.confirmDeleteCustomer(rowData)} />
          </>
        )
      }
    
      renderListProgram(data) {
        return (
          <div className='col-12'>
            <div className='course-list-item'>
              <img src={data.photo} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={`customer-${data.program}`}></img>
              <div className='course-list-detail'>
                <h4> {data.program} </h4>
                {/* {this.textResponsive("Programa: " + data.program)} */}
                <div>Escuela: {data.school_origin}</div>
                <div>Numero de estudiantes: {data.school_origin}</div>
                <div>Ciudad: {data.destination}</div>
                <div>Fecha de inicio: {data.start_date}</div>
                <div>Fecha fin del programa: {data.end_date}</div>
                <div>Duration: {data.duration}</div>

              </div>
              <div className='course-list-action'>
                <span className='course-price'>${data.budget}</span>
                <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)} />
                <span>Publicado en:  {data.created_at}</span>

    
              </div>
            </div>
          </div>
        )
      }
    
      renderGridProgram(data){

        const img ={
          width: '32px',
          height: '32px',
          textAlign: 'center',
          objectFit: 'cover'
        }

        return (
          <div className='p-col-12 p-sm-6  p-md-4 p-lg-3'>
            <div className='course-grid-item card' style={{borderColor: "darkgoldenrod"}}>
            {/* <div className="mb-3">
              <Avatar alt={`${data.enterprise_name} Logo`} src={data.logo} imgProps={{style:{ height:"40px", margin: 0 }}} />
            </div> */}
            <div className='course-grid-item-top'>
              <span> Posteado en: {data.created_at} </span>
            </div>


            <div className='course-grid-item-top'>
              {/* <span className=''></span> */}
            </div>
            <div className='course-grid-item-content'>
            <img src={data.photo} className="img-fluid" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.program}></img>
              <div className='course-name'>{data.program}</div>
              <div>Escuela: {data.school_origin}</div>
              <div>Numero de estudiantes: {data.number_students}</div>
              <div>Ciudad: {data.destination}</div>
              <div>Fecha de inicio: {data.start_date}</div>
              <div>Duration: {data.duration}</div>





            </div>
            <div className='course-grid-item-bottom'>
              <div className='course-price'>Precio: ${data.budget}</div>
              <Button icon="pi pi-search" color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px"}} onClick={() => this.changePage(data)} />

            </div>
            </div>
          </div>
        )
      }
    
      itemTemplate(program, layout){
        if (!this.getPrograms){
          return;
        }
    
        if(layout === 'list')
          return this.renderListProgram(program)
        else if (layout === 'grid')
          return this.renderGridProgram(program) 
      }
    
      renderHeader() {
        return(
          <div className='p-grid p-nogutter'>
            <div className='col-6' style={{textAlign: 'left'}}>
              <Dropdown options={this.sortOptions} value={this.state.sortKey} optionLabel='label' placeholder='Sort by Group' onChange={this.onSortChange} />
            </div>
            <div className='col-6' style={{textAlign: 'right'}}>
              <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value})} />
            </div>
          </div>
        )
      }
    
      render() {
    
        const programDialogFooter =(
          <div>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveProgram} />
            <Button label="Cancel" icon="pi pi-times" className="p-button-check" onClick={this.hideDialog} />
          </div>
        );
    
        // const deleteProgramDialogFooter = (
        //   <>
        //     <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteProgramDialog} />
        //     <Button label="Yes" icon="pi pi-check" className="p-button=text" onClick={this.deleteProgram} />
        //   </>
        // )
    
        // const deleteProgramsDialogFooter = (
        //   <>
        //     <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteProgramsDialog} />
        //     <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelected } />
        //   </>
        // )
    
        const headerProgram = this.renderHeader();
    
    
        return (
          <div>

            <Toast ref={(el) => this.toast = el} />
            <FormattedMessage id="GENERAL_MESSAGE.COMPLETE_INFORMATION">
              {
                (message) => (
                  <Dialog header={message} footer={programDialogFooter} visible={this.state.programDialog} onHide={this.hideDialog}  style={{width:"30%"}}   >
                    <div className='p-grid' style={{marginTop: "1em"}} >
                      <div className="p-col" >
                        <span className="p-float-label" >
                          <InputText id="program" value={this.state.program} maxLength="39" onChange={(e) => this.setState({program: e.target.value})} style={{width:"100%"}} />
                          <label htmlFor='program'>Nombra tu programa</label> 
                        </span>
                      </div>
                    </div>
                  </Dialog>
                )
              }
            </FormattedMessage>

            <div className='dataview mb-5'>
              <div className='card'>
                <DataView value={this.state.programs} layout={this.state.layout} header={headerProgram} 
                itemTemplate={this.itemTemplate} sortOrder={this.state.sortOrder} sortField={this.state.sortField} />
              </div>
            </div>
            <div className='dataview mb-5'>
              <button className="m-3 btn btn-primary" onClick={this.openNew}><AddIcon/> <FormattedMessage id="GENERAL_MESSAGE.ADD_COURSE"/></button>
            </div>

          </div>
        )
      }
}







