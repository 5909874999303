import React, { Suspense, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen } from '../_metronic/_core'
import { ContentRoute } from '../_metronic/layoutWiyu copy'

import { DataUserPage } from './pages/DataUserPage'
import { TutorialsSectionPage } from './pages/TutorialsSectionPage'
import { ReportPaymentPage } from './pages/ReportPaymentPage'

import { DataAgencyPage } from "./pagesAgency/DataAgencyPage";

import { useSelector } from 'react-redux'

const GoogleMaterialPage = lazy(() =>
    import("./modulesAdmin/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
    import("./modulesAdmin/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
    import("./modulesAdmin/ECommerce/pages/eCommercePage")
);

export default function BasePageSettings() {
    const { user } = useSelector(state => state.auth)
    const id_agente = 64

    const renderSwitchRedirect = (user) => {
        switch(user.role) {
            case 'Director':
                return <Redirect exact from='/' to='/inicio'/>
            default:
                return <Redirect exact from='/' to='/inicio'/>
        }
    }

    const renderSwitchRoute = (user) => {
        return (
            <Switch>
                {
                    {renderSwitchRedirect}
                }
                <ContentRoute path='/get-agency' component={DataAgencyPage}/>
            </Switch>
        )
    }

    return (
        <Suspense fallback={<LayoutSplashScreen/>} >
            {renderSwitchRoute(user )}
        </Suspense>
    )
}

