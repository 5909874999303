import React from "react";
import {useSubheader} from "../../_metronic/_core";
import OfficesTable from "../modulesAdmin/AdminAgencies/OfficesTable"

export const OfficesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Offices");

  return (<><OfficesTable/></>);

};