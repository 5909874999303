import React, { Component } from "react";
import * as auth from '../../modulesAdmin/Auth/_redux/authRedux'
import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import Axios from "axios";
import ServerURL from "../../../ServerURL";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from "../../../_metronic/i18n/TextTranslate";
import { Redirect } from "react-router-dom";

const url = ServerURL();



class AssignStudents extends Component{

    emptyStudent = {
        id_cs: ''
    }

    constructor (props){
        super(props)

        this.state = {
            id_cs: null,
            student : this.emptyStudent,
            students: null,
            students_customer: null,
            selectedStudents: null,
            selectedCustomer: null,
            resetCustomer: null,
            assignStudentsDialog: false,

            ids: null,
            globalFilter: null,


            campusText: getTextCampusTranslate(),
            paginatorText: getTextPaginatorTranslate(),
            textTranslate: getGeneralTextTranslate(),

            redirect: false,
        }

        this.confirmAssignSelected = this.confirmAssignSelected.bind(this)
        this.assignSelectedStudents = this.assignSelectedStudents.bind(this)
        this.hideAssignStudent = this.hideAssignStudent.bind(this)
        this.onCustomerChange = this.onCustomerChange.bind(this)
        this.changePage = this.changePage.bind(this)
    }

    componentDidMount(){
        this.getStudents()
        this.getStudentsCustomer()
    }

    getStudents = async () => {
        try {
            await Axios.get(`${url}/api/public/students`)
            .then(res => {
                const students = res.data
                this.setState({ students })
                console.log("Students: ", students);
            })
        } catch (error) {
            console.error(error);
        }
    }

    getStudentsCustomer = async () =>{
        try {
            await Axios.get(`${url}/api/public/customer-students-assign/${this.props.dataCustomerSuccess.id}`)
            .then(res =>{
                const students_customer = res.data
                this.setState({ students_customer })
                console.log("CS_name: ", res.data);
                // console.log("Students assigned: ", students_customer);
            })
        } catch (error) {
            console.log(error);
        }
    }

    getCustomer() {
        try {
            Axios.get(`${url}/api/public/wiyu_customer_success`)
            .then(res => {
                const customers = res.data
                this.setState({ customers })
                console.log("Customer success: ", customers);
            })
        } catch (error) {
            console.error(error);
        }
    }

    assignSelectedStudents() {
        let data = this.state.students.filter(val => this.state.selectedStudents.includes(val))
        let id_cs = this.props.dataCustomerSuccess.id

        // for (let i = 0; i < data.length; i++){
        //     data[i].id_cs = this.state.selectedCustomer
        // }

        try {
            console.log("Data: ", data);
            let res = Axios.put(`${url}/api/public/assign_students_customer`, {data, id_cs})
            .then(res => {
                this.getStudents()
                this.getStudentsCustomer()
            })
            if(!res.error){
                this.setState({
                    assignStudentsDialog: false,
                    selectedStudents: null,
                })
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Students assigned', life: 3000})
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    }

    changePage(){
        this.setState({ redirect: true})
    }

    onCustomerChange(e) {
        let student = {...this.state.student};
        student['id_cs'] = e.value

        this.setState({ selectedCustomer: e.value, student })
    }

    confirmAssignSelected() {
        this.setState({
            assignStudentsDialog: true,
            selectedCustomer: 'Selecciona tu customer success...',
            resetCustomer: null,
            submitted: false,
        })
        this.getCustomer()
    }

    hideAssignStudent() {
        this.setState({
            assignStudentsDialog: false,
            selectedCustomer: null,
            resetCustomer: null,
            submitted: false,
        })
    }



    render(){

        if(this.state.redirect){
            return <Redirect to={{
                pathname: '/wiyu-customer-success'
            }} />
        }

        const assignStudentsDialogFooter = (
            <>
                <Button label="Yes" onClick={this.assignSelectedStudents}/>
                <Button label="No" onClick={this.hideAssignStudent}/>
            </>
        )

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <Dialog visible={this.state.assignStudentsDialog} contentStyle={{ maxHeight:"800px", width:"500px", height:"300px", overflow:"auto" }} header='Confirm' modal footer={assignStudentsDialogFooter} onHide={this.hideAssignStudent} >
                    <br/>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2e,'}} />
                        {this.state.students && <span>Are you sure you want to assign the selected students?</span>}
                    </div>
                </Dialog>

                <h1>Customer success - {this.props.dataCustomerSuccess.cs_name}</h1>

                <div className='d-flex justify-content-between mb-2'>
                    <Button className='p-button-rounded p-button-danger p-button-outlined' type='button' icon='pi pi-arrow-left' onClick={this.changePage} variant='contained' />
                </div>

                <div className="card-header flex-wrap border-0 pt-6 pb-0" >
                    <h3 className="card-label">
                        Student assignment
                        <span className="d-block text-muted pt-2 font-size-sm">List of unassigned students</span>
                    </h3>
                    {/* Tool bar */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}} >
                        <div style={{marginRight: 10}}>
                            <p><Button type="button" label="Assign students" icon="pi pi-plus" variant='contained' color="primary" onClick={this.confirmAssignSelected} disabled={!this.state.selectedStudents || !this.state.selectedStudents.length} /></p>
                        </div>

                        <div style={{ marginRight: 10}}>
                            <p><span className='p-input-icon-left'>
                                <i className='pi pi-search' /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value})} placeholder={this.state.campusText.GlobalSearch} />
                            </span></p>
                        </div>
                    </div>

                    <div>
                        <div className="card">
                            <DataTable
                            ef={(el) => this.dt = el} value={this.state.students} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={this.state.paginatorText} rows={6} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                            globalFilter={this.state.globalFilter} emptyMessage="No students found." loading={this.state.loading}
                            selection={this.state.selectedStudents} onSelectionChange={e => this.setState({ selectedStudents: e.value })} dataKey="id">
                                <Column selectionMode="multiple" headerStyle={{ width: '2em' }}></Column>
                                <Column field="fullname" header="Full name" filter filterPlaceholder="Search by full name" sortable></Column>
                                <Column field="email" header="Email" filter filterPlaceholder={this.state.campusText.SearchByEmail} sortable></Column>
                                <Column field="phone" header="Phone" filter filterPlaceholder={this.state.campusText.SearchByPhone} sortable></Column>
                                {/* <Column field="cs_name" header="CS"></Column> */}
                                <Column field="program_name" header="Program" filter filterPlaceholder="Search by program" sortable></Column>
                                {/* {
                                    this.state.students.id_cs ?
                                    <Column field="No" header="Asignado"></Column>
                                    :
                                    <Column field="Si" header="Asignado"></Column>
                                }                                 */}
                            </DataTable>
                        </div>
                    </div>
                </div>

                <div className="card-header flex-wrap border-0 pt-6 pb-0">
                    <h3 className="card-label">
                        List of students assigned
                        {/* <span className="d-block text-muted pt-2 font-size-m">Students assigned</span> */}
                    </h3>

                    <div>
                        <div className="card">
                            <DataTable
                            ef={(el) => this.dt = el} value={this.state.students_customer} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                            globalFilter={this.state.globalFilter} emptyMessage="No students found." loading={this.state.loading}
                            selection={this.state.selectedStudents} onSelectionChange={e => this.setState({ selectedStudents: e.value })} dataKey="id">
                                <Column field="fullname" header="Full name" filter filterPlaceholder="Search by full name" sortable></Column>
                                <Column field="email" header="Email" filter filterPlaceholder={this.state.campusText.SearchByEmail} sortable></Column>
                                <Column field="phone" header="Phone" filter filterPlaceholder={this.state.campusText.SearchByPhone} sortable></Column>
                                <Column field='cs_name' header="Customer success" sortable></Column>
                                <Column field="program_name" header="Program" filter filterPlaceholder="Search by program" sortable></Column>                                
                            </DataTable>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

const mapStateToProps = state => ({
    user: state.auth.user,
    dataCustomerSuccess: state.auth.dataCustomerSuccess
});

export default injectIntl(
    connect(mapStateToProps, auth.actions)(AssignStudents)
)