import React from "react";
import {useSubheader} from "../../_metronic/_core";
import Course from "../modulesSchool/Course"
import {useSelector} from "react-redux";
import { useIntl } from "react-intl";

export const CoursePage = () => {
    const suhbeader = useSubheader();
    const dataCourse = useSelector(state => state.auth.dataCourse);
    const int1 = useIntl();

    // if(props.location.aboutProps){
    //     suhbeader.setTitle(`Curso - ${props.location.aboutProps.data.course}`);
    //     return (<Course data={props.location.aboutProps.data}/>)
    // }else{
    //     suhbeader.setTitle(`Curso - ${props.location.state.course}`);
    //     return (<Course data={props.location.state}/>)
    // }

    suhbeader.setTitle(`${int1.formatMessage({id: "LEARNING_GENERAL.COURSE"})} - ${dataCourse.course}`);
    return (<Course/>)
    
}