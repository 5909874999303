import React from "react";
import {useSubheader} from "../../_metronic/_core";
import AgenciesTable from "../modulesAdmin/AdminAgencies/AgenciesTable"

export const AgenciesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Agencies");

  return (<><AgenciesTable/></>);

};