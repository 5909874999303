import React from "react";
import {useSubheader} from "../../_metronic/_core";

export const CourseAssignmentPage = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Asignación de cursos");
    return (
        <div>
            <h1>Pagina para asignación de cursos</h1>
        </div>
    )
}