import React from "react";
import {useSubheader} from "../../_metronic/_core";

import GetTasks from "../../app/modulesAdmin/Tasks/GetTasks";

export const GetTasksPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Lista de tareas de LabsReact");

  return (<><GetTasks /></>);
};