import React, { Component } from 'react';
import KeyboardBackspaceSharpIcon from '@material-ui/icons/KeyboardBackspaceSharp';
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from 'primereact/dialog';
import { Link }  from "react-router-dom";
import "primeflex/primeflex.css";

import axios from 'axios';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";
import ServerURL from '../../../ServerURL';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { FormattedMessage, injectIntl } from 'react-intl';

const url = ServerURL();

class Evaluation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      evaluations: null,
      answers: null,
      originalAnswers: [],
      displayConfirmation: false,
      reasonFail: [],
      qualification: 0,
      reasonDialog: false
    };

    this.onHide = this.onHide.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.isEvaluationComplete = this.isEvaluationComplete.bind(this);
    this.showInfo = this.showInfo.bind(this);
  }

  componentDidMount() {
    this.getEvaluations()
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  onOpen(name){
    this.setState({
      [`${name}`]: true
    })
  }

  isEvaluationComplete(){
    let answersCorrectComplete = false;
    let existTrueInAnswer = false
    let correctsInQuestions = 0
    this.state.evaluations[0].answers.forEach(question => {
      question.forEach(answer => {
        if(answer.correct === true){
          existTrueInAnswer = true
        }
      })
      if(existTrueInAnswer){
        correctsInQuestions++;
      }
      existTrueInAnswer = false;
    });
    if(correctsInQuestions===5 || correctsInQuestions===10){
      answersCorrectComplete = true
    }

    const messages = []
    if(!answersCorrectComplete){
      const message = this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.QUESTION_WITHOUT_SELECTED_ANSWER'})
      messages.push(message)
    }
    this.showInfo(messages)
  }

  showInfo(messages) {
    const timeLife = 10000
    switch(messages.length){
      case 1:
        this.toast.show({severity:'info', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.INCOMPLETE_ASSESSMENT'}), detail: messages[0], life: timeLife});
        break;
      default:
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.FULL_ASSESSMENT'}), life: timeLife});
        this.saveEvaluation()
    }
  }

  getEvaluations = async () =>{
    try {
      await axios.get(`${url}/api/auth/evaluation/${this.props.dataLesson.id}`)
      .then(res => {
        const evaluations = res.data
        const newAnswers = []
        const originalAnswers = []
        if(evaluations.length){
          evaluations[0].answers.forEach(answers => {
            const arrayAnswers = []
            let index = 0
            answers.forEach(answer => {
              if(answer.correct){
                originalAnswers.push(index)
              }
              answer.correct = false
              arrayAnswers.push(answer)
              index++;
            })
            newAnswers.push(answers);
          })
          evaluations[0].answers = newAnswers
        }
        this.setState({ evaluations, originalAnswers })
        this.getAnswers();
      });
      }catch(error){
        console.log("error")
    }
  }

  getAnswers = async () =>{
    try {
      const data = {
        fk_id_lesson: this.props.dataLesson.id,
        fk_id_user: this.props.user.id
      }
      await axios.post(`${url}/api/auth/show_answers`, data)
      .then(res => {
        const answers = res.data
        const originalAnswers = this.state.originalAnswers
        let calificacion = 0
        if(answers.length !== 0){
          for (let index = 0; index < originalAnswers.length; index++) {
            if(originalAnswers[index] === answers[0].results[index]){
              calificacion++;
            }
          }
          calificacion = calificacion/originalAnswers.length
        }
        this.setState({ answers: res.data, qualification: calificacion*100 })
      });
      }catch(error){
        console.log("error")
    }
  }

  saveEvaluation = async () =>{
    const arrayResults = []
    const evaluation = this.state.evaluations[0]

    evaluation.answers.forEach(answers => {
      let index = 0
      answers.forEach(answer => {
        if(answer.correct){
          arrayResults.push(index);
        }
        index++;
      })
    })

    const data = {
      fk_id_lesson: this.props.dataLesson.id,
      fk_id_user: this.props.user.id,
      results: arrayResults
    }
    try {
      await axios.post(`${url}/api/auth/create_evaluation`, data)
      .then(res => {     
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.LOADED_SUCCESS'}), life: 6000});
        this.getEvaluations()
      })
      .catch(error => {
        this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.LOADED_ERROR'}), life: 6000});
      });
      }catch(error){
        this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SERVER_ERROR'}), life: 6000});
    }
  }

  deleteEvaluation = async () =>{
    try {
      console.log(this.state.evaluations[0].id)
      await axios.delete(`${url}/api/auth/evaluation/${this.state.evaluations[0].id}`)
      .then(res => {     
        this.toast.show({severity:'success', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.ASSESSMENT_REMOVED'}), life: 6000});
        this.getEvaluations()
      })
      .catch(error => {
        this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.DELETE_ERROR'}), life: 6000});
      });
      }catch(error){
        this.toast.show({severity:'error', summary: this.props.intl.formatMessage({id: 'GENERAL_MESSAGE.SERVER_ERROR'}), life: 6000});
    }
  }


  renderFooter(name) {
    return (
      <div>
        <FormattedMessage id="GENERAL.NO">
          {message => <Button label={message} icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" autoFocus/>}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.YES">
          {message => <Button label={message} icon="pi pi-check" onClick={() => this.deleteEvaluation()} />}
        </FormattedMessage>
      </div>
    );
  }

  changeQuestion(numQ,text){
    const evaluation = this.state.evaluations[0]
    if(this.props.dataLesson.dataCourse.published === 0){
      evaluation.questions[numQ].text = text
    }

    this.setState({evaluations: [{...evaluation}]})
  }

  changeAnswersCorrect(numQ,numQA){
    const evaluation = this.state.evaluations[0]
    for (let index = 0; index < evaluation.answers[numQ].length; index++) {
      if(index === numQA){
        evaluation.answers[numQ][index].correct = !evaluation.answers[numQ][numQA].correct
      }else{
        evaluation.answers[numQ][index].correct = false
      } 
    }

    this.setState({evaluations: [{...evaluation}]})
  }

  changeAnswersText(numQ,numQA,text){
    const evaluation = this.state.evaluations[0]
    if(this.props.dataLesson.dataCourse.published === 0){
      evaluation.answers[numQ][numQA].text = text
    }

    this.setState({evaluations: [{...evaluation}]})
  }

  renderAnswerQuestionCorrects(numQ,numQA){
    const evaluation = this.state.evaluations[0]
    let template = (
      <div key={`${numQ}-${numQA}`} className="p-col-6">
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon">
          <RadioButton checked={evaluation.answers[numQ][numQA].correct} onChange={(e) => this.changeAnswersCorrect(numQ,numQA)} />
          </span>
          <InputText disabled placeholder={`${this.props.intl.formatMessage({id: 'LEARNING_ASSESSMENT.ANSWER'})} ${numQA+1}...`} value={evaluation.answers[numQ][numQA].text} onChange={e => this.changeAnswersText(numQ,numQA,e.target.value)}/>
        </div>
      </div>
    )
    if(this.state.originalAnswers[numQ] === numQA){
      template = (
        <div key={`${numQ}-${numQA}`} className="p-col-6">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon" style={{borderColor: "green"}}>
            <RadioButton disabled checked={false} />
            </span>
            <InputText disabled value={evaluation.answers[numQ][numQA].text} style={{borderColor: "green"}} onChange={e => this.changeAnswersText(numQ,numQA,e.target.value)}/>
          </div>
        </div>
      )
    }
    if(this.state.originalAnswers[numQ] === numQA && this.state.answers[0].results[numQ] === this.state.originalAnswers[numQ]){
      template = (
        <div key={`${numQ}-${numQA}`} className="p-col-6">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon" style={{borderColor: "green"}}>
            <RadioButton disabled checked={true} />
            </span>
            <InputText disabled value={evaluation.answers[numQ][numQA].text} style={{borderColor: "green"}} onChange={e => this.changeAnswersText(numQ,numQA,e.target.value)}/>
          </div>
        </div>
      )
    }else if(numQA === this.state.answers[0].results[numQ]){
      template = (
        <div key={`${numQ}-${numQA}`} className="p-col-6">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon" style={{borderColor: "red"}}>
            <RadioButton disabled checked={true} />
            </span>
            <InputText disabled value={evaluation.answers[numQ][numQA].text} style={{borderColor: "red"}} onChange={e => this.changeAnswersText(numQ,numQA,e.target.value)}/>
          </div>
        </div>
      )
    }
    return template
  }

  renderAnswerQuestion(numQ,numQA){
    const evaluation = this.state.evaluations[0]
    return(
      <div key={`${numQ}-${numQA}`} className="p-col-6">
        <div className="p-inputgroup">
          <span className="p-inputgroup-addon">
          <RadioButton checked={evaluation.answers[numQ][numQA].correct} onChange={(e) => this.changeAnswersCorrect(numQ,numQA)} />
          </span>
          <FormattedMessage id="LEARNING_ASSESSMENT.ANSWER">
          {message => <InputText placeholder={`${message} ${numQA+1}...`} value={evaluation.answers[numQ][numQA].text} onChange={e => this.changeAnswersText(numQ,numQA,e.target.value)}/>}
          </FormattedMessage>
        </div>
      </div>
    )
  }

  renderQuestion(numQ){
    const evaluation = this.state.evaluations[0]
    return(
      <div key={numQ} className="mb-8">
        <h4><FormattedMessage id="LEARNING_ASSESSMENT.QUESTION"/> {numQ+1}:</h4>
        <div className="mb-2" style={{fontSize:"22px", width:"100%"}} >
          {evaluation.questions[numQ].text}
        </div>
        <div className="p-grid">
          {
            this.state.answers.length ?
            this.state.evaluations[0].answers[numQ].map((data, numQA) => this.renderAnswerQuestionCorrects(numQ, numQA))
            :
            this.state.evaluations[0].answers[numQ].map((data, numQA) => this.renderAnswerQuestion(numQ, numQA))
          }
        </div>
      </div>
    )
  }

  render() {
    let url_next = null
    if(this.props.dataLesson.dataCourse.fk_id_program_type !== 10){
      url_next = "/get-courses/course/lesson";
    }else{
      url_next = "/tutorial/course/lesson";
    }

    return (
      <div>
        <Link className="mb-5" to={{
            pathname: url_next
        }}>
        <button className="btn"><KeyboardBackspaceSharpIcon/></button>
        </Link>
        <Toast ref={(el) => this.toast = el} />
        <div className="card p-6">
          <FormattedMessage id="GENERAL_MESSAGE.CONFIRM">
            {message => (
              <Dialog header={message} visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter('displayConfirmation')} onHide={() => this.onHide('displayConfirmation')}>
                <div className="confirmation-content">
                    <span className="d-flex"><i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', alignSelf: "center" }}/><FormattedMessage id="LEARNING_ASSESSMENT.DELETE_ASSESSMENT"/></span>
                </div>
              </Dialog>
            )}
          </FormattedMessage>
          <div className="p-grid p-nogutter">
            <div className="font-weight-bold">
            <h1><FormattedMessage id="LEARNING_ASSESSMENT.TITLE"/>:&nbsp;&nbsp;{this.props.dataLesson.name}</h1>
            </div>
          </div>
          <hr className="pb-5"/>
          {
            this.state.evaluations && this.state.answers ?
              this.state.evaluations.length ?
                this.state.answers.length ?
                <>
                  <h2 className="mb-8" style={{textAlign:"center"}}><FormattedMessage id="LEARNING_ASSESSMENT.RESULTS_ASSESSMENT"/>:</h2>
                  {this.state.evaluations[0].questions.map((data, index) => this.renderQuestion(index))}
                  <h3 style={{background:"lightgray", width:"max-content", borderRadius:"10px", padding:"4px", alignSelf:"center"}}><FormattedMessage id="LEARNING_ASSESSMENT.QUALIFICATION"/>: {this.state.qualification}</h3>
                </>
                :
                <>
                  <h2 className="mb-8" style={{textAlign:"center"}}><FormattedMessage id="LEARNING_ASSESSMENT.ANSWER_ASSESSMENT"/></h2>
                  {this.state.evaluations[0].questions.map((data, index) => this.renderQuestion(index))}
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={this.isEvaluationComplete}><FormattedMessage id="LEARNING_ASSESSMENT.SAVE_RESPONSES"/></button>
                  </div>
                </>
              :
              <div style={{textAlign: "center"}}>
                <h2><FormattedMessage id="LEARNING_ASSESSMENT.NO_ASSESSMENT"/></h2>
              </div>
            :
            <div style={{textAlign: "center"}}>
              <h3><FormattedMessage id="LEARNING_ASSESSMENT.LOADING_ASSESSMENT"/>...</h3>
              <div style={{padding: "4em"}}>
                <i className="pi pi-spin pi-spinner" style={{fontSize: "6em"}}></i>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  dataLesson: state.auth.dataLesson
});

export default injectIntl(
  connect(mapStateToProps, auth.actions)(Evaluation)
)