import React, {Component} from 'react'
import classNames from 'classnames';
import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'
 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const url = ServerURL();



class CurrenciesTable extends Component {
            

  
  emptyCurrency = {
    code: '',
    symbol: '',
}

 

constructor(props) {
  super(props);  

  this.state = { 
       /**
       * Aux messages
       */
      fk_id_role_typeMessage: '',
      roleMessage: '', 
      descriptionMessage: '', 

      
    currency: this.emptyCurrency,
    currencies:null,
    globalFilter: null,
    currencyDialog: false,
    deleteCurrencyDialog: false,
    deleteCurrenciesDialog: false,
    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.saveCurrency = this.saveCurrency.bind(this);
  this.deleteCurrency = this.deleteCurrency.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.editCurrency = this.editCurrency.bind(this);
  this.confirmDeleteCurrency= this.confirmDeleteCurrency.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedCurrencies = this.deleteSelectedCurrencies.bind(this);
  this.hideDeleteCurrencyDialog = this.hideDeleteCurrencyDialog.bind(this);
  this.hideDeleteCurrenciesDialog = this.hideDeleteCurrenciesDialog.bind(this);
  this.hideDialog = this.hideDialog.bind(this);
  this.onInputChange = this.onInputChange.bind(this);
  }


    
      componentDidMount() {
        this.getCurrencies()

      }

      isValid(){
        const { code, symbol } = this.state.currency
              const codeMessage = this.validateCode()
              const symbolMessage = this.validateSymbol()

        
              if(code && symbol ){
                if(!codeMessage && !symbolMessage){
                  return true;
                }else{
                  return false;
                }
              }
              return false;
      }
      
                validateCode() {
              const currency= this.state.currency
              
              let codeMessage;
              if (!currency.code) {
                codeMessage = 'Requerido';
              } else {
                codeMessage = ''
              }
              this.setState({ codeMessage })
              return codeMessage
            }



       validateSymbol() {
              const currency = this.state.currency
              
              let symbolMessage;
              if (!currency.symbol) {
                symbolMessage = 'Requerido';
              } else {
                symbolMessage = ''
              }
              this.setState({ symbolMessage })
              return symbolMessage
            }



      
        
            getTextAuxCode(){
              const message = this.state.codeMessage
               return message
             }
      
        getTextAuxSymbol(){
             const message = this.state.symbolMessage
              return message
            }


     

      getCurrencies = async () =>{
         
          try {
             
              let res = axios.get(`${url}/api/auth/currencies`)
              .then(res => {
                const currencies = res.data;            
                this.setState({ currencies })
              });                     
                console.log(res.data)
              }catch(error){

         }
      }

      deleteCurrency(){
        let data = {...this.state.currency};

        try {
        let res = axios.delete(`${url}/api/auth/currency/${data.id}`)
          .then(res => {
          this.getCurrencies()
        })
        if (!res.error) {
          this.setState({
            data,
            deleteCurrencyDialog: false,
            currency: this.emptyCurrency
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Currency Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }      

      saveCurrency() {
        let state = { submitted: true };

        const decision = this.isValid();

        if (decision) {


       // if (this.state.currency.id.trim()) {       
          let data = {...this.state.currency};


        if (this.state.currency.id) {

            try {
              let res = axios.put(`${url}/api/auth/currency/${data.id}`,data)
              .then(res =>{
              this.getCurrencies()  
              //console.log(res);
              })
              if (!res.error) {     
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Currency Updated', life: 3000 });
              }
            }catch(error){          
          }         

        }else{

          try {
          
            let res = axios.post(`${url}/api/auth/currency`,data)
            
             .then(res => {
                 this.getCurrencies()
               })
               if (!res.error) {
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Currency Created', life: 3000 });
               }
                 }catch(error){
               }   
        }
        state = {
          ...state,
          data,
          currencyDialog: false,
          currency: this.emptyCurrency,
        }; 
      }
      this.setState(state);
    }
      
      openNew() {
        this.setState({
            currency: this.emptyCurrency,
            submitted: false,
            currencyDialog: true,
        })
      }

      onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let currency = {...this.state.currency};
        currency[`${name}`] = val;

        this.setState({ currency });
      }


      hideDialog() {
          this.setState({
              submitted: false,
              currencyDialog: false,
              codeMessage:'',
              symbolMessage: '',
        })  
      } 


      editCurrency(currency) {
          this.setState({
              currency: { ...currency },
              currencyDialog: true
          })

      }

      confirmDeleteCurrency(currency) {
        this.setState({
            currency,
            deleteCurrencyDialog: true
        })
      }

      confirmDeleteSelected() {
        this.setState({ deleteCurrenciesDialog: true })
      }

      deleteSelectedCurrencies() {
        let currencies = this.state.currencies.filter(val => !this.state.selectedCurrencies.includes(val));
        let data = this.state.currencies.filter(val => this.state.selectedCurrencies.includes(val));

        try {
        let res = axios.delete(`${url}/api/auth/currencies`,{data})
          .then(res => {
          this.getCurrencies()
        })
        if (!res.error) {
          this.setState({
            currencies,
            deleteCurrenciesDialog: false,
            selectedCurrencies: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Currencies Deleted', life: 3000 });
        }
          }catch(error){
          } 
      }

      hideDeleteCurrencyDialog() {
        this.setState({ deleteCurrencyDialog: false })
      }
        
      hideDeleteCurrenciesDialog() {
        this.setState({ deleteCurrenciesDialog: false });
    }
      
        exportCSV() {
          this.dt.exportCSV();
      }
      
      
      actionBodyTemplate(rowData) {
        return (
            <>
                <Button icon="pi pi-pencil"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.editCurrency(rowData)} />
                <Button icon="pi pi-trash"   className="p-button-danger" style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}} onClick={() => this.confirmDeleteCurrency(rowData)} />
            </>
        )
      }   
    

      render(){
       const deleteCurrencyDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCurrencyDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteCurrency} />
        </>
       );

       const currencyDialogFooter = (
        <>
            <FormattedMessage id="GENERAL.CANCEL">
              {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
            </FormattedMessage>
            <FormattedMessage id="GENERAL.SAVE">
              {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveCurrency} />}
            </FormattedMessage>
        </>
       );

       const deleteCurrenciesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCurrenciesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedCurrencies} />
        </>
    );


       return(       
          <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
		      	<FormattedMessage id="ADMIN_CURRENCY.TITLE"/>
				  <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_CURRENCY.SUBTITLE"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedCurrencies || !this.state.selectedCurrencies.length} />}
                      </FormattedMessage>
                    </div>

                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="OPTION.NEW_CURRENCY">
                        {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary'/></p>}
                      </FormattedMessage> 
                    </div>  

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                     </span></p>
                    </div>  
                    
            </div>
          </div>
          
          <div>
           {/*SHOW CURRENCY */}
                <div className="card">
                    <DataTable 
                        ef={(el) => this.dt = el} value={this.state.currencies} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                        globalFilter={this.state.globalFilter} emptyMessage="No Currency found." loading={this.state.loading}
                        selection={this.state.selectedCurrencies} onSelectionChange={e => this.setState({ selectedCurrencies: e.value })} dataKey="id">
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="code" header={this.state.campusText.Code} sortable></Column>
                        <Column field="symbol" header={this.state.campusText.Symbol} sortable></Column>
                        <Column body={this.actionBodyTemplate}></Column>
                    </DataTable>
                </div>
           </div>
         

          <div> 
          {/*DELETE CURRENCY */}
          <Dialog visible={this.state.deleteCurrencyDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCurrencyDialogFooter} onHide={this.hideDeleteCurrencyDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.currency && <span> Are you sure you want to delete <b>{this.state.currency.code}</b>?</span>}
                    </div>
          </Dialog>
          </div>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteCurrenciesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCurrenciesDialogFooter} onHide={this.hideDeleteCurrenciesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.currency && <span>Are you sure you want to delete the selected currencies?</span>}
                    </div>
          </Dialog>


          <div>
          {/*UPDATE CURRENCY & CREATE CURRENCY*/}
          <Dialog visible={this.state.currencyDialog} contentStyle={{maxHeight:"800px", width:"600px", height:"110px", overflow:"auto"}} header={<FormattedMessage id="OPTION.NEW_CURRENCY"/>} modal footer={currencyDialogFooter} onHide={this.hideDialog}>
              <br></br>
              <div className="form-group row">
               {/* Code */}
              <div className="col-lg-6">      
              <span className="p-float-label">          
              <InputText
                  id="code"
                  variant="outlined"
                  margin="dense"
                  value={this.state.currency.code}             
                  onChange={(e) => this.onInputChange(e, 'code')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.currency.code  })}
                  />
                 <label htmlFor="code">Code</label>
              </span>    
             {/*<small id="code-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxCode()}</small>*/}
              </div>
               {/* Symbol */}
               <div className="col-lg-6">
              <span className="p-float-label">
                  <InputText
                  id="symbol"
                  variant="outlined"
                  margin="dense"
                  value={this.state.currency.symbol}             
                  onChange={(e) => this.onInputChange(e, 'symbol')}
                  required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.currency.symbol  })}
                  />
                  <label htmlFor="symbol">Symbol</label>
              </span>
             {/*<small id="symbol-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxSymbol(this.state)}</small>*/}
              </div>
              </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Tooltip>
   </div>  
         
    )
  }
}
export default withRouter(CurrenciesTable);