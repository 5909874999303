/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from 'react-router'
import { NavLink } from "react-router-dom";
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
//import { useIntl } from "react-intl";

export function AsideMenuListDefault({ layoutProps }) {

  const { user } = useSelector(state => state.auth)
  const location = useLocation()
  const id_agente = 64

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  }

  console.log('USER ROLE TYPE: ', user.fk_id_role_type);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material', true)}`}
          aria-haspopup='true'
          data-menu-toggle='hover'>
          <NavLink className='menu-link menu-toggle' to='/google-material'>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Cap-2.svg')} />
            </span>
            <span className="menu-text text-uppercase">Settings</span>
            <i className="menu-arrow" />
          </NavLink>

          {/* Agency Data */}
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-sub-nav">
              {
                user.fk_id_role_type === 12 ?
                <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base">SCHOOL</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              :
              <li className="menu-section">
              <h4 className="menu-text text-white-alpha-90 text-base">AGENCY</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
              }

              {
                user.fk_id_role_type === 12 ?
                <li className={`menu-item ${getMenuItemActive("/get-agency", false)}`}
                aria-haspopup='true'>
                <NavLink className='menu-link' to='/get-agency'>
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Settings.svg')} />
                  </span>
                  <span className="menu-text">School Data</span>
                </NavLink>
              </li>
              :
              <li className={`menu-item ${getMenuItemActive("/get-agency", false)}`}
              aria-haspopup='true'>
              <NavLink className='menu-link' to='/get-agency'>
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Settings.svg')} />
                </span>
                <span className="menu-text"><FormattedMessage id='LEARNING_GENERAL.AGENCY_DATA' /></span>
              </NavLink>
            </li>
              }


              {
                user.fk_id_role_type === 12 ?
                <>
                  <li className="menu-section">
                    <h4 className="menu-text text-white-alpha-90 text-base">AGENCIES</h4>
                    <i className="menu-icon flaticon-more-v2"/>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive("/get-group-agencies", false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/get-group-agencies">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                      </span>
                      <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.AGENCY_GROUP_LIST" /></span>
                    </NavLink>
                  </li>
                </>
                  :
                  <></>
              }

              {/* Usuarios */}
              {/* begin::section */}
              {
                user.fk_id_role_type === 12 ?
                  <li className="menu-section">
                    <h4 className="menu-text text-white-alpha-90 text-base">MARKETER</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>

                  :

                  <li className="menu-section">
                    <h4 className="menu-text text-white-alpha-90 text-base">AGENTS</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                  </li>
              }

              {/* end:: section */}

              {/*begin::1 Level*/}
              {
                user.fk_id_role_type === 12 ?
                  <>
                    <li
                      className={`menu-item ${getMenuItemActive("/get-marketers", false)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/get-marketers">
                        <span className="svg-icon menu-icon">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                        </span>
                        <span className="menu-text">Marketer List</span>
                      </NavLink>
                    </li>
                  </>
                  :
                  <li
                    className={`menu-item ${getMenuItemActive("/get-agents", false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/get-agents">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                      </span>
                      <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.AGENT_LIST" /></span>
                    </NavLink>
                  </li>
              }
              {/* <li
                    className={`menu-item ${getMenuItemActive("/get-team", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-team">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
                  </span>
                    <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.MY_TEAM"/></span>
                  </NavLink>
                </li> */}
              {/* {
                  user.fk_id_role !== id_agente && (
                    <li
                        className={`menu-item ${getMenuItemActive("/get-reports", false)}`}
                        aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/get-reports">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg")}/>
                      </span>
                        <span className="menu-text"><FormattedMessage id="LEARNING_GENERAL.REPORTS"/></span>
                      </NavLink>
                    </li>
                  )
                } */}
              {/*end::1 Level*/}
            </ul>
          </div>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
